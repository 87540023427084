import React, { useState } from "react";
import bank from "../../../../assets/TeacherApp/assets/svgs/bank.svg";
import "./PlanModal.styles.scss";
import { LinearProgress, Rating } from "@mui/material";
import { toast } from "react-toastify";
import x from "../../../../assets/SharedApp/svgs/x.svg";
import ReactQuill from "react-quill";
import ArrowLeft from "../../../../assets/TeacherApp/assets/svgs/Arrow-left-blue.svg";

import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reviewTeacher } from "../../../../ReduxToolkit/Slices/studentSlice";
import { reviewCourse } from "../../../../ReduxToolkit/Slices/coursesSlice";

export default function PlanModal({ lesson, setShowReview, course }) {
  const [text, setText] = useState("");
  const [value, setValue] = useState(0);

  const { student } = useSelector((state) => state.student);

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (value === 0) {
      return toast.error("Please enter a rating");
    } else {
      if (!text || text.split(" ").length < 5) {
        return toast.error("You need to add at least 5 words");
      }
    }
    if (course) {
      dispatch(
        reviewCourse({
          studentId: student,
          review: {
            courseId: lesson.id,
            rating: value,
            comment: text,
          },
        })
      ).then((res) => {
        if (res.payload === "Student already reviewed this course") {
          return toast.error("You have already reviewed this course");
        }
        if (res.payload === "You did not buy the course yet") {
          return toast.error("You did not buy the course yet");
        }

        setValue(0);
        setText("");
        toast.success("Review sent, thank you!");
      });
    } else {
      dispatch(
        reviewTeacher({
          studentId: student,
          review: {
            teacherId: lesson.teacher.id,
            rating: value,
            comment: text,
          },
        })
      ).then((res) => {
        setShowReview(false);
        toast.success("Review sent, thank you!");
      });
    }
  };
  return (
    <div
      className={course ? "flex_center" : "plan__modal__container flex_center"}
    >
      <div
        className={
          course
            ? "plan__modal__review plan__modal__review__course"
            : "plan__modal__review"
        }
      >
        <div className="header">
          <h1>Course Review</h1>
          {!course && (
            <img
              src={x}
              alt=""
              onClick={() => setShowReview(false)}
              className="pointer"
            />
          )}
        </div>
        <p>
          Title: <span>{lesson.title}</span>
        </p>
        <div className="flex_column">
          <img
            alt=""
            src={lesson.teacher?.user?.profileImage?.url}
            className="tutor_image"
          />
          <p>
            <span>{lesson?.teacher?.user.name}</span>
            <span> {lesson?.teacher?.user.lastName}</span>
          </p>
        </div>

        <div className="is__bought__container">
          <div className="is__bought">
            <div className="rate__course">
              <Rating
                name="simple-controlled"
                size="large"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </div>
          </div>
        </div>
        <div className="inputs">
          <div className="wysiwyg">
            <span>Feedback</span>
            <textarea
              className=""
              placeholder="How was your experiece?"
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </div>
        <div className="">
          <button className="prev__btn" onClick={() => onSubmit()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
