import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import { SubjectRow } from "../../../Components/SubjectRow/SubjectRow";
import { options } from "../../Pages/SignupDetails/languages";
import { useDispatch, useSelector } from "react-redux";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";

import Button from "../../../Components/Button/Button";
import searchIcon from "../../../assets/TeacherApp/assets/svgs/search.svg";

const SignupStepSix = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uniqueOptions = Array.from(
    new Set(options.map((option) => option.value))
  ).map((value) => options.find((option) => option.value === value));
  const [search, setSearch] = useState("");
  const [languagesToMap, setLanguagesToMap] = useState(uniqueOptions);
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const [selectedLanguages, setSelectedLanguages] = useState(
    teacherToRegister?.languagesSpoken ?? []
  );

  const handleLanguageClick = (option) => {
    setSelectedLanguages((prevLanguages) => {
      const optionIndex = prevLanguages.findIndex(
        (lang) => lang.value === option.value
      );

      // If the option is already selected, deselect it
      if (optionIndex !== -1) {
        // Deny unselecting if only 1 option is selected
        return prevLanguages.filter((_, index) => index !== optionIndex);
      } else {
        // If the option is not selected, select it
        return [...prevLanguages, option];
      }
    });
  };
  const handleSubmit = () => {
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, languagesSpoken: selectedLanguages },
      })
    );
    navigate("/teacher-signup/step-seven");
  };

  useEffect(() => {
    if (search) {
      const filteredLanguages = uniqueOptions.filter((el) =>
        el.value?.toLowerCase().includes(search?.toLowerCase())
      );
      setLanguagesToMap(filteredLanguages);
    } else {
      setLanguagesToMap(uniqueOptions);
    }
  }, [search]);

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[]}
          subtitle={
            "Kindly choose the languages you speak. This may encourage students to want to sign up with you ads they might be looking for someone like you. You can choose multiple languages."
          }
          className={"register_note_2"}
        />
      </div>
      <div className="container_2">
        <h2>
          <span style={{ marginRight: "10px" }}>Languages</span>spoken
          (optional)
        </h2>
        <div className="search_field_container">
          <img src={searchIcon} alt="search" />
          <input
            type="search"
            placeholder={"Search languages..."}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="subjects_rows_container">
          <div className="subjects_rows">
            {selectedLanguages.map((el) => (
              <SubjectRow
                name={el?.label}
                key={el?.label}
                onClick={() => handleLanguageClick(el)}
                selected={selectedLanguages.some(
                  (lang) => lang.value === el.value
                )}
              />
            ))}
          </div>
        </div>
        <span className="most_taught languages_text">
          SELECT THE LANGUAGES THAT YOU ARE ABLE TO TEACH IN
        </span>

        <div className="subjects_rows_container" style={{ marginTop: "0" }}>
          <div className="subjects_rows">
            {languagesToMap.map((el) => (
              <SubjectRow
                name={el?.label}
                key={el?.label}
                onClick={() => handleLanguageClick(el)}
                // selected={selectedLanguages.some(
                //   (lang) => lang.value === el.value
                // )}
              />
            ))}
          </div>
        </div>
        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-five")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SignupStepSix;
