import React, { useState } from "react";
import RangeSlider from "react-range-slider-input";

export const CustomRangeSlider = ({
  className,
  onChange,
  name,
  name2,
  min = 0,
  max = 200,
  prefix = "",
  symbol = "",
  value,
  setValue,
  isFirstPointDisabled,
}) => {
  // const [customSliderValues, setCustomSliderValues] = useState([min, max]);
  return (
    <div className={`custom_filter_range_slider ${className ?? ""}`}>
      <div className="custom_slider_number">
        {!isFirstPointDisabled && (
          <span>
            {prefix && prefix}
            {value && value[0]}
            {symbol && symbol}
          </span>
        )}
        <RangeSlider
          min={min}
          max={max}
          value={value}
          onInput={setValue}
          onThumbDragEnd={() => {
            name && onChange(name, value[0]);
            name2 && onChange(name2, value[1]);
          }}
          className={`custom_range_slider ${
            isFirstPointDisabled && "single-thumb"
          }`}
          thumbsDisabled={[isFirstPointDisabled, false]}
          rangeSlideDisabled={isFirstPointDisabled}
        />
        <span>
          {prefix && prefix}
          {value && value[1]}
          {symbol}
        </span>
      </div>
    </div>
  );
};
