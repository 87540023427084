import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import "./EditCourse.styles.scss";
import "react-quill/dist/quill.snow.css";

// import "prismjs/components/prism-clike";
// import "prismjs/components/prism-javascript";
import AddExample from "../AddExample/AddExample";
// import Prism from "prismjs";
import EditPanel from "../../../Components/Courses/EditPanel/EditPanel";
import AddVideo from "../../../Components/Courses/AddVideo/AddVideo";
import AddText from "../../../Components/Courses/AddText/AddText";

import AddQuiz from "../../../Components/Courses/AddQuiz/AddQuiz";
import EditorPage from "../../../Components/Courses/EditorPage/EditorPage";
import AddFiles from "../../../Components/Courses/AddFiles/AddFiles";
import Shared from "./Shared";
import { handleAddLesson } from "../../../../ReduxToolkit/Slices/coursesSlice";

export default function EditCourse({ index }) {
  const lessonInitial = {
    title: "",
    videos: [
      {
        url: "",
        publicId: "",
        videoLength: 0,
      },
    ],
    description: "",
  };

  const [selected, setSelected] = useState("Add Video");
  const [lesson, setLesson] = useState(lessonInitial);

  const dispatch = useDispatch();
  const { chapters } = useSelector((state) => state.courses);

  const handleLessonChange = (e) => {
    const { name, value } = e.target;
    setLesson({ ...lesson, [name]: value });
  };

  const handleAddClick = () => {
    if (!lesson.title) {
      return toast.error("Please add a title to your lecture");
    }
    if (!lesson.videos[0].url && !lesson.description) {
      return toast.error("Please add a lecture materials");
    } else {
      selected === "Add Code Example"
        ? dispatch(
            handleAddLesson({
              name: "lessons",
              value: {
                ...lesson,
                description: `codeHereToBeAdded${lesson.description}`,
                videos: [{ publicId: "string", url: "string" }],
              },
              index,
            })
          )
        : selected === "Add Video" ||
          selected === "Add Image" ||
          selected === "Add File"
        ? dispatch(
            handleAddLesson({
              name: "lessons",
              value: lesson,
              index,
            })
          )
        : dispatch(
            handleAddLesson({
              name: "lessons",
              value: {
                ...lesson,
                videos: [{ publicId: "string", url: "string" }],
              },
              index,
            })
          );
      setLesson(lessonInitial);
    }
  };
  const handleSelected = (value) => {
    if (value !== selected) {
      setSelected(value);
      setLesson({ ...lessonInitial, title: lesson.title });
    }
  };

  return (
    <div className="Edit__courses__section">
      {/* <Routes>
        <Route path="" element={<Shared />}>
          <Route path="/" element={<div className="border" />} />
          <Route path="/addVideo" element={<AddVideo />} />
          <Route path="/addText" element={<AddText />} />
        </Route>
      </Routes> */}
      <EditPanel setSelected={handleSelected} selected={selected} />
      <div className="Editors">
        <div className="Custom_Inputs">
          <div className="input">
            <span>Lecture Title</span>
            <input
              type="text"
              placeholder="title"
              name="title"
              value={lesson.title}
              onChange={(e) => handleLessonChange(e)}
            />
          </div>
        </div>
        {selected === "Add Video" ? (
          <AddVideo
            lesson={lesson}
            setLesson={setLesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
          />
        ) : selected === "Add Notes" ? (
          <AddText lesson={lesson} setLesson={setLesson} />
        ) : // ) : selected === "Add Code Example" ? (
        //   <AddExample
        //     title={"Code example"}
        //     handleLessonChange={(e) => handleLessonChange(e)}
        //     lesson={lesson}
        //     subtitle="Use the code example editor to display a properly formatted code example in your lecture."
        //   />
        selected === "Add Image" ? (
          <AddVideo
            lesson={lesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
            image
          />
        ) : selected === "Add File" ? (
          <AddVideo
            lesson={lesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
            file
          />
        ) : null}
        {/* {selected === "Editor Page" && <EditorPage />} */}
        {/* {selected === "Add File" && <AddFiles />} */}
        {selected === "Add Quiz" && <AddQuiz />}

        <button
          className="btn_send flex_center"
          onClick={() => handleAddClick()}
        >
          {selected}
        </button>
      </div>
      {/* {selected === "Add Custom Code" && (
        <AddExample
          title={"Custom Code example"}
          subtitle="Use the code example editor to display a properly formatted custom code example in your lecture."
        />
      )} */}
    </div>
  );
}
