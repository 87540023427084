import React, { useState } from "react";
import tutor from "../../../assets/HomeApp/images/tutor.webp";
import "./SignInMethod.styles.scss";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import edit from "../../../assets/SharedApp/svgs/edit.svg";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import Select from "react-select";
import { Link } from "react-router-dom";
import noteImg from "../../../assets/HomeApp/svgs/authnote.svg";
import message from "../../../assets/HomeApp/svgs/message.svg";
import shield from "../../../assets/HomeApp/svgs/shield.svg";
import Note from "../../Components/Note/Note";
import ProfileDetails from "../../Components/ProfileDetails/ProfileDetails";
const SignInMethod = () => {
  const text =
    "Two-factor authentication adds an extra layer of security to your account. To log in, in you'll need to provide a 4 digit amazing code";
  return (
    <div className="signIn__method">
      <div className="settings__container">
        <div className="signIn__method__header">
          <h3>Sign-in Method</h3>
        </div>
        <div className="signin__rows">
          <div className="signin__row">
            <span>Email Address</span>
            <p>Enable Two Factor Authentication (BETA)</p>
          </div>
          <div className="signin__row">
            <span>Password</span>
            <p>*******************</p>
          </div>
        </div>
        <AuthNote text={text} link="#" btn />
      </div>
    </div>
  );
};
export default SignInMethod;
const AuthNote = ({ text, link, btn }) => {
  return (
    <div className="auth__note__container">
      <div className="note__col">
        <img src={noteImg} alt="" />
        <p>
          {text && text}
          {link && <Link to={`/${link}`}> Learn More</Link>}
        </p>
        {btn && <button className="btn__primary">Enable</button>}
      </div>
    </div>
  );
};
