import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./ManageTeachers.styles.scss";

import tutor from "../../../assets/SharedApp/images/tutor.webp";
import dots from "../../../assets/AdminApp/svgs/Dots.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTeacher,
  setAdminState,
} from "../../../ReduxToolkit/Slices/adminSlice";
import AlertModalDelete from "../AlertModalDelete/AlertModalDelete";
import { toast } from "react-toastify";
import { resendOTP } from "../../../ReduxToolkit/Slices/userSlice";
import { Tooltip } from "@mui/material";

export const DynamicImage = (src) => {
  // Transform the image URL to use auto format and quality
  return src.replace("/upload/", "/upload/f_auto,q_auto/");
};

const TeacherMenu = ({ id, status, setTeacher, onDelete, email }) => {
  const dispatch = useDispatch();

  const onResendCLick = () => {
    dispatch(resendOTP({ email })).then((Res) => {
      if (Res.payload.data === "An OTP was sent to your email address") {
        return toast.success("OTP is on it's way to the user");
      } else {
        if (typeof Res.payload === "string") {
          return toast.error(Res.payload);
        }
      }

      return toast.error("Error sending otp");
    });
  };
  return (
    <div className="teacher__menu">
      <Link
        to={
          status === "APPROVED"
            ? `/admin/teachers/${id}`
            : `/admin/teachers/${id}/request`
        }
      >
        View Profile
      </Link>
      <Link onClick={() => setTeacher()} to={`/admin/teachers/${id}/edit`}>
        Edit
      </Link>
      <Link to={`/admin/teachers/${id}/payment`}>Make payment</Link>
      <span onClick={() => onResendCLick()}>Resend OTP</span>
      <span onClick={() => onDelete()}>Delete</span>
    </div>
  );
};

function TeacherRow({ row, reAssign, onReAssign }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const handleDelete = () => {
    dispatch(deleteTeacher(row.id)).then((res) => {
      setModal(false);
      if (res.type.includes("fulfilled")) {
        toast.success("Teacher Deleted Successfully");
      } else {
        toast.error("Error Deleting Teacher");
      }
    });
  };
  const setTeacher = () =>
    dispatch(
      setAdminState({
        name: "teacher",
        value: row,
      })
    );

  const [showMenu, setShowMenu] = useState(false);

  const location = row?.lessons?.length
    ? Array.from(new Set(row.lessons.flatMap((lesson) => lesson.location)))
    : [];

  return (
    <>
      {modal && (
        <AlertModalDelete
          type={"Teacher"}
          handleDelete={handleDelete}
          setModal={setModal}
        />
      )}
      <tr>
        <td>
          <div className="student__cell">
            <img
              src={
                row.user.profileImage?.url ? row.user.profileImage.url : tutor
              }
              alt=""
            />
            <div className="name">
              <span>
                {row.user.name} {row.user.lastName}
              </span>
              <p>{row.user.address.country}</p>
            </div>
          </div>
        </td>
        <td>
          <div className="manage__std__lesson">
            <div className="lesson__date">
              {/* <span>{row.lessonTaught.student}</span> */}
              <span className="span">
                {row.lessons.map((lesson, i) => (
                  <span key={i}>{lesson?.subjects[i]?.name}</span>
                ))}
              </span>
            </div>
          </div>
        </td>
        <td>
          {/* <span>{row.lesonLoc.type}</span> */}
          <span className="span">
            {location?.length > 0
              ? location.map((loc, i) => <span key={i}>{loc} </span>)
              : "online"}
          </span>
        </td>
        {/* <td>
          <span>2000$</span>
          <p>paid</p>
        </td> */}
        <td>
          <button className={row.status.toLowerCase()}>{row.status}</button>
        </td>
        {/* <td>
                <img src={right} alt="" />
            </td> */}
        <td
          className="teacher__options"
          onMouseLeave={() => setShowMenu(false)}
          onMouseEnter={() => setShowMenu(true)}
        >
          {reAssign ? (
            <Tooltip title={"Click to choose this teacher"}>
              <img src={dots} alt="" onClick={() => onReAssign(row.id)} />
            </Tooltip>
          ) : (
            <img src={dots} alt="" />
          )}

          {showMenu && !reAssign && (
            <TeacherMenu
              status={row.status}
              id={row.id}
              email={row?.user?.email}
              setTeacher={setTeacher}
              onDelete={() => setModal(true)}
            />
          )}
        </td>
      </tr>
    </>
  );
}

export default TeacherRow;
