import React from "react";
import "./content.styles.scss";
export default function DataDelete() {
  const data = [
    {
      p1: "At Educify, we are committed to protecting the privacy and data of our users. This Data Usage Policy outlines how we collect, use, and protect your data when you use our platform and services. By using Educify, you consent to the terms and practices described in this policy.",
      p2: "Data Collection: a. Information Provided by Users: When you create an account or use our services, we collect the information you provide, such as your name, email address, date of birth, and any other information you choose to share.",
      p3: "Information Collected Automatically: We collect certain information automatically when you use our platform, including your IP address, device information, browser type, and operating system. We also collect data on your interactions with our services, such as likes, comments, and messages.",
      p4: "Cookies and Similar Technologies: We use cookies and similar technologies to track your usage patterns, personalize your experience, and improve our services. These technologies may collect information about your browsing behavior and preferences.",
    },
    {
      p1: "Communication: We may use your data to communicate with you, respond to your inquiries, and send you important notifications related to your account and our services.",
      p2: "Advertising and Marketing: We may use your data to display targeted ads, both on and off our platform. This includes using information about your interests, demographics, and usage patterns to deliver relevant advertisements.",
      p3: "Research and Analytics: We may use data for research purposes and to analyse trends, understand user behaviour, and improve our services. This data is anonymized and aggregated to protect your privacy.",
      p4: "Data Sharing: a. Sharing with Third Parties: We may share your data with third-party service providers, partners, and affiliates who assist us in delivering our services. These parties are bound by confidentiality obligations and are required to use your data only for authorized purposes.",
    },
    {
      p1: "Legal Compliance and Protection: We may share your data when necessary to comply with legal obligations, enforce our terms and policies, protect the rights, safety, and security of users, or investigate and prevent fraud or other illegal activities.",
      p2: "Business Transfers: In the event of a merger, acquisition, or sale of assets, your data may be transferred to the acquiring entity or parties involved.",
      p3: "Data Security: a. We employ industry-standard security measures to protect your data against unauthorized access, loss, or alteration. However, no method of transmission or storage can guarantee complete security.",
      p4: " It is important to maintain the security of your account by using strong and unique passwords, enabling two-factor authentication, and keeping your login credentials confidential.",
    },
    {
      p1: "Data Retention: a. We retain your data as long as necessary to provide our services and fulfill the purposes outlined in this policy. However, we may retain certain data for longer periods if required by law or for legitimate business purposes.",
      p2: "You have the right to request the deletion of your data, subject to legal obligations and our data retention policies.",
      p3: "Children's Privacy: a. Educify is intended for users aged 13 and above. If we become aware that we have collected data from individuals below this age, we will take steps to delete the information as soon as possible. Your Choices: a. You have the right to control and manage the data you share on Educify. You can access, update, and delete your data through your account settings.",
      p4: "You can manage your ad preferences, control the information used for ad targeting, and opt-out of certain data processing activities.",
    },
    {
      p1: "International Data Transfers: a. By using Educify, your data may be transferred to and stored in countries outside your own. We take measures to ensure that such transfers comply with applicable data protection laws.",
      p2: "This Data Usage Policy is subject to change, and any updates will be posted on our website. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your data. Your continued use of Educify signifies your acceptance of any modifications to this policy.",
      p3: "If you have any questions or concerns about our Data Usage Policy, please contact us through our support channels.",
    },
  ];
  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>
        Educify Data Usage Policy
      </h1>
      <p>Effective Date: [13th July, 2023]</p>
      {data.map((section) => (
        <div className="privacy_row">
          <h2>{section.title}</h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          <p>{section?.p4}</p>
        </div>
      ))}
    </div>
  );
}
