import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPromoCodeById } from "../../../ReduxToolkit/Slices/adminSlice";
import { getTeacherCouponBYID } from "../../../ReduxToolkit/Slices/teacherSlice";
import "./ViewPromo.styles.scss";
export default function ViewPromo({ teacher }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [promo, setPromo] = useState();
  useEffect(() => {
    teacher
      ? dispatch(getTeacherCouponBYID(id)).then((res) => setPromo(res.payload))
      : dispatch(getPromoCodeById(id)).then((res) => setPromo(res.payload));
  }, []);
  return (
    <div className="view__promo">
      <div className="header">
        {teacher ? <h1>Coupon Details</h1> : <h1>Promo Code details</h1>}
      </div>
      <div className="view__promo__container">
        {promo &&
          Object.keys(promo).map((el) => (
            <div className="promo__row">
              <span>{el}</span>
              <p>{promo[el]}</p>
            </div>
          ))}
      </div>

      <div className="view__text__container">
        <div className="view__promo__details">
          <span>Promo Code Details</span>
          <p>{promo && promo.details}</p>
        </div>
        <div className="view__promo__details">
          <span>Promo Code Terms</span>
          <p>{promo && promo.terms}</p>
        </div>
      </div>
    </div>
  );
}
