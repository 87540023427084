import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import YouTube from "react-youtube";

import "./TeacherSubmission.styles.scss";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import img from "../../../assets/AdminApp/svgs/pdf.svg";
import LessonAccordion from "../../Components/LessonAccordion/LessonAccordion";
import {
  approveTeachers,
  getTeachersById,
  rejectTeachers,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { getVideoIdFromUrl, updateCloudinaryUrl } from "../../../hooks";
import {
  getTeacherByIdAdmin,
  getTeacherPaymentInfo,
} from "../../../ReduxToolkit/Slices/teacherSlice";

export default function TeacherSubmission() {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const [day, setDay] = useState(null);
  const navigate = useNavigate();

  const [teacher, setTeacher] = useState({});
  const [availabilites, setAvailabilites] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState();
  useEffect(() => {
    dispatch(getTeacherByIdAdmin(id)).then((res) => {
      setPaymentInfo(res?.payload?.user?.paymentInfo);
    });
    dispatch(getTeachersById(id)).then((res) => {
      setTeacher(res.payload);
    });
  }, []);

  useEffect(() => {
    teacher?.availability?.length &&
      setAvailabilites(handleAvailability(teacher.availability[0].day));
  }, [teacher]);
  const formatDate = (date) => {
    return moment(date).toISOString();
  };

  const getAvMinutes = (time) => {
    let mins =
      new Date(time).getMinutes() === 0 ? "00" : new Date(time).getMinutes();
    return mins;
  };

  const getTime = (date) => {
    return `${new Date(date).getHours()}:${getAvMinutes(date)}`;
  };
  const getVideoId = (url) => {
    return url.split("youtube.com/watch?v=")[1];
  };
  // let lessonTime = `${lessonStartTime} - ${lessonEndTime}`

  const handleAvailability = (day) => {
    let av = teacher.availability.filter((Element) => {
      return Element.day === day;
    });
    return av[0]?.times;
  };

  const onDayChange = (value) => {
    setDay(value);
    setAvailabilites(handleAvailability(value));
    // setAvailabilites(teacher?.availability.filter(Element => {return Element.day === day}))
  };

  const approveTeacher = () => {
    dispatch(approveTeachers(teacher.id)).then((res) => {
      if (typeof res.payload === "string") {
        return toast.error(res.payload);
      }

      navigate("/admin/teachers");
    });
  };

  const rejectTeacher = () => {
    dispatch(rejectTeachers(teacher.id)).then(navigate("/admin/teachers"));
  };

  const videoUrl = teacher?.videos?.length ? teacher?.videos[0]?.url : null;
  const videoId = videoUrl ? videoUrl.split("v=")[1] : "";
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  const [receiveMethod, setReceiveMethod] = useState({});

  useEffect(() => {
    if (teacher?.id)
      dispatch(
        getTeacherPaymentInfo({
          userId: teacher.user.id,
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) setReceiveMethod(res?.payload[0]);
      });
  }, [teacher?.id]);

  function mapObjectToHTML(obj) {
    const keys = Object.keys(obj);
    return (
      <>
        {keys
          .filter((el) => obj[el] !== null)
          .map((key) => (
            <div className="sub__row" key={key}>
              <span>{key}</span>
              <p>{obj[key]}</p>
            </div>
          ))}
      </>
    );
  }

  const secureUrl = (url) => {
    if (url.startsWith("http:") && url.includes("cloudinary")) {
      return url.replace("http:", "https:");
    }
    return url;
  };
  return (
    teacher.user && (
      <div className="teacher__submission">
        <div className="submission__container">
          <div className="sub__buttons">
            <button className="approve" onClick={() => approveTeacher()}>
              Approve
            </button>
            <button className="reject" onClick={() => rejectTeacher()}>
              Reject
            </button>
          </div>

          <div className="submission__details">
            <h3>Account Details</h3>
            <div className="inner__details">
              <div className="sub__row">
                <span>Name</span>
                <p>
                  {teacher.user.name} {teacher.user.lastName}
                </p>
              </div>
              <div className="sub__row">
                <span>Email Address</span>
                <p>{teacher.user.email}</p>
              </div>
              <div className="sub__row">
                <span>By Social</span>
                <p>{teacher?.user?.bySocial ? "True" : "False"}</p>
              </div>
              <div className="sub__row">
                <span>Nationality</span>
                <p>{teacher.user.nationality}</p>
              </div>
              <div className="sub__row">
                <span>Contact Info</span>
                <p>{teacher.user.phone}</p>
              </div>
              <div className="sub__row">
                <span>Status</span>
                <p>{teacher?.status}</p>
              </div>

              <div className="sub__row">
                <span>Gender</span>
                <p>{teacher.user.gender}</p>
              </div>
              <div className="sub__row">
                <span>Created At</span>
                <p>{moment(teacher.createdAt).format("DD/MM/YYYY")}</p>
              </div>
              <div className="sub__row">
                <span>Teacher Status</span>
                <p>{teacher.status}</p>
              </div>
              <div className="sub__row">
                <span>Email address verified</span>
                <p>
                  {teacher.user.isVerified || teacher?.user?.bySocial
                    ? "Verified"
                    : "Not Verified"}
                </p>
              </div>
            </div>
          </div>
          {/* payment here */}

          <div className="submission__details">
            <h3>Payment Details</h3>
            <div className="inner__details">
              <div className="sub__row">
                <span>Receive Method</span>
                <p>{receiveMethod?.receiveMethod}</p>
              </div>
              {receiveMethod?.receiveMethod === "BANK_TRANSFER" ? (
                mapObjectToHTML(receiveMethod.bankDetails)
              ) : (
                <>
                  <div className="sub__row">
                    <span>Account Holder Name</span>
                    <p>{receiveMethod?.accountHolderName}</p>
                  </div>
                  <div className="sub__row">
                    <span>Paypal Email</span>
                    <p>{receiveMethod?.paypalGmail}</p>
                  </div>
                  <div className="sub__row">
                    <span>Currency</span>
                    <p>{receiveMethod?.currency}</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="submission__details">
            <h3>Profile Category</h3>
            <div className="inner__details">
              <div className="sub__row">
                <span>Profile Photo</span>
                <Tooltip title="Click to preview" arrow placement="top">
                  <a href={teacher.user.profileImage?.url} target="_blank">
                    <img src={teacher.user.profileImage?.url} alt="" />
                  </a>
                </Tooltip>
              </div>
              <div className="sub__row">
                <span>Profile Title</span>
                <p>{teacher.title}</p>
              </div>
              <div className="sub__row">
                <span>Languages</span>
                {teacher.languages.map((lang, i) => (
                  <div className="lang__row" key={lang}>
                    <span>{lang.name}</span>
                  </div>
                ))}
              </div>

              <div className="sub__row">
                <span>Description</span>
                <p>{teacher.description}</p>
              </div>
              <div className="sub__row">
                <span>Video Intro</span>
                {!teacher?.videos[0]?.url?.includes("youtube.com") ? (
                  <video width="220" height="140" controls>
                    <source
                      src={updateCloudinaryUrl(teacher?.videos[0]?.url)}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <iframe
                    width="320"
                    height="240"
                    src={`https://www.youtube.com/embed/${getVideoIdFromUrl(
                      teacher?.videos[0]?.url
                    )}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                )}
              </div>
              {teacher?.testRecord && (
                <div className="sub__row">
                  <span>Verification Video</span>
                  {!teacher?.testRecord.url?.includes("youtube.com") ? (
                    <video width="220" height="140" controls>
                      <source
                        src={updateCloudinaryUrl(teacher?.testRecord?.url)}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <iframe
                      width="320"
                      height="240"
                      src={`https://www.youtube.com/embed/${getVideoIdFromUrl(
                        teacher?.videos[0]?.url
                      )}`}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}
                </div>
              )}
              <div className="sub__row">
                <span>Profile Images</span>
                {teacher?.images?.map((image, i) => (
                  <Tooltip
                    title="Click to preview"
                    arrow
                    placement="top"
                    key={i}
                  >
                    <a href={image.url} target="_blank">
                      <img src={image.url} alt={`teacher profile-${i + 1}`} />
                    </a>
                  </Tooltip>
                ))}
              </div>
              <div className="sub__row">
                <span>Certificate</span>
                {/* <img src={img} alt="" /> */}
                <div className="teacher_certificates_sub">
                  {teacher &&
                    teacher?.experience?.certifications?.map((cert, i) => {
                      return cert.url && cert.url.includes(".pdf") ? (
                        <Tooltip
                          title="Click to preview"
                          arrow
                          placement="top"
                          key={i}
                        >
                          <a href={cert.url} target="_blank">
                            {cert.fileName}
                            <span className="a">.pdf</span>
                          </a>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Click to preview" arrow placement="top">
                          <a href={cert.url} target="_blank">
                            <img src={cert.url} alt="certificate" key={i} />
                          </a>
                        </Tooltip>
                      );
                    })}
                </div>
              </div>
              <div className="sub__row">
                <span>Pets</span>
                {/* <img src={img} alt="" /> */}
                <p>{teacher.pets ? "Yes" : "No"}</p>
              </div>
              {/* <div className="sub__row">
                <span>Certificate Description</span>
                <p>
                  Tell us about yourself and your experience. What you can offer
                  the students that is unique and the levels you can teach.
                  Please include your qualifications, Certification and
                  experience
                </p>
              </div> */}
            </div>
          </div>

          <div className="submission__details">
            <h3>Reviews</h3>
            <div className="inner__details">
              {teacher?.reviews?.length &&
                teacher?.reviews?.map((rev) => (
                  <div className="sub__row" key={rev.id}>
                    <span>
                      {`${rev?.student?.user?.name} ${rev?.student?.user?.lastName} (
                      ${rev?.rating} )`}
                    </span>
                    <p>{rev?.comment}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className="submission__details">
            <h3>Education</h3>
            <div className="inner__details">
              {teacher &&
                teacher?.educationLevel?.map((educ, i) => (
                  <div className="sub__row" key={i}>
                    <span>Education {i + 1}</span>
                    <div className="test__row">
                      <div className="educ">
                        <span>university name</span>
                        <p>{educ.university ? educ.university : "No input"}</p>
                      </div>
                      <div className="educ">
                        <span>Degree</span>
                        <p>{educ.degree ? educ.degree : "No Input"}</p>
                      </div>
                      <div className="educ">
                        <span>Degree Type</span>
                        <p>{educ.degreeType ? educ.degreeType : "No Input"}</p>
                      </div>
                      <div className="educ">
                        <span>Specialization</span>
                        <p>
                          {educ.specialization
                            ? educ.specialization
                            : "No Input"}
                        </p>
                      </div>
                      <div className="educ">
                        <span>Start Date</span>
                        <p>{educ.startDate ? educ.startDate : "No Input"}</p>
                      </div>
                      <div className="educ">
                        <span>End Date</span>
                        <p>{educ.endDate ? educ.endDate : "No Input"}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="submission__details">
            <h3>Availabilities</h3>
            <div className="inner__details">
              <div className="sub__row">
                <select
                  className="date"
                  onChange={(e) => onDayChange(e.target.value)}
                >
                  {teacher?.availability?.length > 0 &&
                    teacher?.availability?.map((av, i) => (
                      <option key={i} value={av.day}>
                        {av.day}
                      </option>
                    ))}
                </select>
                {/* <div className="control__btn">
                <button>{"<"}</button>
                <button>{">"}</button>
              </div>
              <div className="date">Oct 23 - 29, 2022</div> */}
              </div>
              <div className="sub__row">
                <div className="availabilities">
                  {availabilites?.length > 0 &&
                    availabilites?.map((av, i) => (
                      <div className="availability" key={i}>
                        <span>
                          {getTime(av?.startTime)} - {getTime(av?.endTime)}{" "}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="submission__details">
            <h3>Lessons</h3>
            <div className="inner__details">
              <div className="lesson__accordion__container">
                {teacher?.lessons?.length > 0 &&
                  teacher?.lessons?.map((lesson, i) => (
                    <LessonAccordion
                      lesson={lesson}
                      key={lesson.id}
                      number={i}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="submission__details">
            <h3>Background Check</h3>
            <div className="inner__details">
              <div className="sub__row sub__row__withImg">
                <p className="bgcheck__text">
                  Do you have any background, vulnerable sector or criminal
                  police check to share with us?
                  {/* <span> NO</span> */}
                </p>
                <div className="bgcheck__img">
                  {teacher?.background?.image?.url &&
                  teacher?.background?.image?.url.includes(".pdf") ? (
                    <Tooltip title="Click to preview" arrow placement="top">
                      <a
                        href={secureUrl(teacher?.background?.image.url)}
                        target="_blank"
                      >
                        {teacher?.background?.image?.fileName}
                        <span className="a">.pdf</span>
                      </a>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Click to preview" arrow placement="top">
                      <a
                        href={secureUrl(teacher?.background?.image?.url)}
                        target="_blank"
                      >
                        <img
                          src={secureUrl(teacher?.background?.image?.url)}
                          alt=""
                        />
                      </a>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
