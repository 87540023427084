import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";

const NoteForm = ({ onAdd }) => {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const handleSubmit = (e) => {
    onAdd({ title, note: message });
    setMessage("");
    setTitle("");
  };

  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [onAdd]);

  return (
    <div className="note-form" ref={ref}>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Write note title..."
        name="title"
        className="edc_input"
      />
      <ReactQuill
        className="note_editor"
        placeholder="Write a note..."
        value={message}
        onChange={(val) => setMessage(val)}
      />
      <button className="add-button" onClick={() => handleSubmit()}>
        Add
      </button>
    </div>
  );
};
// const NoteList = ({ notes, onDelete }) => {
//   return (
//     <div className="note-list">
//       {notes.map((note) => (
//         <NoteItem key={note.id} note={note} onDelete={onDelete} />
//       ))}
//     </div>
//   );
// };

const NotePage = ({ onAdd }) => {
  return (
    <div className="note-page">
      <h1 className="page-title">Add a Note</h1>
      <NoteForm onAdd={onAdd} />
      {/* <NoteList notes={notes} onDelete={handleDeleteNote} /> */}
    </div>
  );
};

export default NotePage;
