import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./MyClasses.styles.scss";

// import bg from "../../assets/bg.svg";
import MyLessons from "../../Components/MyLessons/MyLessons";
// import TodaysCourse from "../../Components/TodaysCourse/TodaysCourse";
import studentImage from "../../assets/student.png";
// import Recommendations from "../../Components/Recommendations/Recommendations";
import Classes from "../../Components/Classes/Classes";
import PlanModal from "../../../TeacherApp/Components/Courses/PlanModal/PlanModal";

import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import Note from "../../../TeacherApp/Components/Note/Note";
import { getStudentReviewCheck } from "../../../ReduxToolkit/Slices/lessonsSlice";
import StudentCalendar from "../StudentCalendar/StudentCalendar";

function MyClasses() {
  const [completed, setCompleted] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [showGuide, setShowGuide] = useState(true);
  const [unReviewed, setUnReviewed] = useState(null);

  const { user } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    completed.length > 0 &&
      completed.map((lesson) => {
        let reviewIds = lesson.lesson?.teacher?.reviews?.length
          ? lesson.lesson?.teacher?.reviews.map((review) => review?.studentId)
          : undefined;

        if (!reviewIds || !reviewIds?.includes(student)) {
          setShowReview(true);
          return setUnReviewed(lesson.lesson);
        }
      });
  }, [completed]);

  useEffect(() => {
    // 5absa
    dispatch(getStudentReviewCheck()).then((res) => {
      let bookingsArray = res?.payload?.data;
      bookingsArray &&
        bookingsArray.length &&
        setCompleted(
          bookingsArray.filter((booking) => {
            if (
              !booking?.lesson.teacher.reviews?.length ||
              !booking.lesson.teacher.reviews.some(
                (review) => review.id === student
              )
            ) {
              return booking;
            } else {
              return null;
            }
          })
        );
    });
  }, []);

  const steps = [
    {
      element: ".Classes_Container__container",
      title: "My Classes",
      intro:
        "Here, you can see classes you booked, you can also view lesson's name, date, time, status and action",
    },
    {
      title: "Filter Class",
      element: ".sort_guide_1",
      intro:
        "By clicking this button you can filter you classes by status: Pending, Accepted, Rejected, Completed.",
    },
    {
      title: "Select timezone",
      element: ".timezone-select",
      intro:
        "By clicking this button you can change the timezone of displayed time to your prefered timezone",
    },
  ];

  return (
    <div className="MyClasses_Container">
      <Note
        p1={"You can find all your classes under this section."}
        p2={
          "You can see the status of your classes here and also get the notification about them."
        }
        p3={
          "You can start your lessons through the My Classes component, and you also have the option to filter lessons."
        }
      />
      <div className="row2">
        {/* <Recommendations /> */}
        <Classes />
      </div>
      <p className="title">My classes</p>
      {showReview && (
        <PlanModal lesson={unReviewed} setShowReview={setShowReview} />
      )}
      {showGuide && pathname.includes("myclasses") && (
        <Steps
          steps={steps}
          enabled={user.helpGuide}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="MyClasses_SubContainer">
        <MyLessons />
        <div className="this">
          <div className="info">
            {/* <TodaysCourse /> */}
            <div className="student__img__container">
              <img alt="" src={studentImage} className="student_image" />
            </div>
          </div>
          <div className="widget-engage">
            <span className="enroll-for-our-curat">
              Enroll for our curated lessons today
            </span>
            <Link className="btn_enroll" to="/students/findClasses">
              Find Lessons
            </Link>
          </div>
        </div>
      </div>
      <div className="row2">
        {/* <Recommendations /> */}
        <Classes other />
      </div>
    </div>
  );
}

export default MyClasses;
