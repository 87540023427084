import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { uploadImage } from "../../ReduxToolkit/Slices/teacherSlice";
import edit from "../../assets/SharedApp/svgs/edit.svg";
import remove from "../../assets/SharedApp/svgs/remove.svg";
import volicity from "../../assets/TeacherApp/assets/svgs/volicity.svg";

export default function ImageUploader({ image, setNewImg }) {
  const [imageLoading, setImageLoading] = useState(false);
  const [preview, setPreview] = useState(image);

  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    setImageLoading(true);
    handleImageUpload(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  };

  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    Promise.resolve(dispatch(uploadImage(form))).then((value) => {
      setNewImg(value.payload);
      setImageLoading(false);
    });
  };
  return (
    <div>
      <div className="image__selector">
        <div className="upload">
          <div className="image">
            <div className="image-upload">
              <label htmlFor="file-input">
                {imageLoading ? (
                  <div className="spinner" />
                ) : !preview ? (
                  <img
                    src={image ? image : volicity}
                    alt=""
                    className={`${
                      image ? "img__btn__with__image" : "img__btn"
                    }`}
                  />
                ) : (
                  <img src={preview} alt="" className="coverImage" />
                )}
              </label>
              <input id="file-input" type="file" onChange={onSelectFile} />
            </div>
            <img src={remove} alt="" className="remove action" />
            <img src={edit} alt="" className="edit action" />
          </div>
          <p>Allowed file types: png, jpg, jpeg.</p>
        </div>
      </div>
    </div>
  );
}
