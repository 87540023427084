import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Note from "../../../Components/Note/Note";
import "./NewOnlineCourse.styles.scss";
import arrow from "../../../../assets/TeacherApp/assets/svgs/Arrow-right.svg";
import ArrowLeft from "../../../../assets/TeacherApp/assets/svgs/Arrow-left-blue.svg";
import Track from "../../../Components/Track/Track";
import UploadImageRow from "../../../Components/Courses/UploadImageRow/UploadImageRow";
import {
  createACourse,
  getCourseById,
  getCourseCategories,
  getCourseSubjectsByCategory,
  handleCourseChange,
  handleCoursesStateChange,
  updateCourse,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import SharedSingleImageUploader from "../../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

export default function NewOnlineCourse({ given }) {
  const { course } = useSelector((state) => state.courses);
  const courseId = useSelector((state) => state.courses.courseId);
  const [categories, setCategories] = useState([]);
  const { teacher } = useSelector((state) => state.teacher);
  const sendError = (message) => toast.error(message);
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let noTrack = location.pathname.includes("signup");
  let edit = location.pathname.includes("edit");

  const {
    title,
    description,
    price,
    categoryNames,
    profileImage,
    whatLearn,
    whoFor,
    announcements,
    subjects,
  } = course;

  const dispatchCourseChange = (name, value) => {
    dispatch(
      handleCourseChange({
        name,
        value,
      })
    );
  };

  useEffect(() => {
    dispatch(getCourseCategories()).then((res) => {
      setCategories(res.payload.data);
    });
  }, []);

  useEffect(() => {
    categoryNames.length &&
      dispatch(getCourseSubjectsByCategory(categoryNames[0])).then((res) => {
        res.payload !== "Course category not found" &&
          dispatchCourseChange("subjects", res.payload);
      });
    // dispatch(getCourseSubjects()).then((res) => {
    //   // setSubjects(res.payload.data);
    // });
  }, [categoryNames]);

  useEffect(() => {
    edit &&
      dispatch(getCourseById(id)).then((res) => {
        dispatch(
          handleCoursesStateChange({
            name: "course",
            value: {
              ...res.payload.data,
              whatLearn: JSON.parse(res.payload.data.whatLearn),
              whoFor: JSON.parse(res.payload.data.whoFor),
              categoryNames: res.payload.data.categories.map(
                (category) => category.name
              ),
              price: res.payload.data.price / 100,
              subjects: res.payload.data.subject,
              announcements: res.payload.data.announcements
                ? JSON.parse(res.payload.data.announcements)
                : [""],
            },
          })
        );
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "categoryNames") {
      return dispatchCourseChange(name, [value]);
    }
    dispatchCourseChange(name, value);
  };
  const handleSubmit = () => {
    if (
      !title ||
      !description ||
      !price ||
      !categoryNames ||
      !profileImage ||
      !whoFor.length > 0 ||
      !whatLearn
    ) {
      return sendError("Please fill out all fields before submiting");
    }
    if (courseId || id) {
      dispatch(
        updateCourse({
          id: id ? id : courseId,
          course: {
            title,
            announcements: JSON.stringify(announcements),
            whatLearn: JSON.stringify(whatLearn),
            whoFor: JSON.stringify(whoFor),
            description,
            price: Number(price) * 100,
            categoryNames: categoryNames,
            profileImage,
            subjects: subjects.length
              ? subjects.map((subject) => subject.name)
              : [],
          },
        })
      ).then((res) => {
        if (res.type === "updateCourse/fulfilled") {
          toast.success(
            "Your course's info was succesfully update, now you can update your chapters"
          );
          dispatch(
            handleCoursesStateChange({
              name: "chapters",
              value: res.payload.data.chapters,
            })
          );
          dispatch(
            handleCoursesStateChange({
              name: "courseId",
              value: res.payload.data.id,
            })
          );
          noTrack ? navigate("/teacher/signup/chapters") : navigate("2");
        } else {
          sendError("Error updating course");
        }
      });
    } else {
      dispatch(
        createACourse({
          teacherId: teacher.id,
          title,
          description,
          whatLearn: JSON.stringify(whatLearn),
          whoFor: JSON.stringify(whoFor),
          price: Number(price) * 100,
          announcements: JSON.stringify(announcements),
          categoryNames: categoryNames,
          profileImage: profileImage,
          subjects: subjects.length
            ? subjects.map((subject) => subject.name)
            : [],
        })
      ).then((res) => {
        if (res.type === "createACourse/fulfilled") {
          toast.success(
            "Your course was succesfully created, now you can add your chapters"
          );

          dispatch(
            handleCoursesStateChange({
              name: "courseId",
              value: res.payload.data.id,
            })
          );

          noTrack ? navigate("/teacher/signup/chapters") : navigate("2");
        } else {
          sendError("Error creating course");
        }
      });
    }
  };

  const handleWhatLearn = (e, i, item) => {
    const { name, value } = e.target;
    let newArray = () => {
      return [...item.slice(0, i), value, ...item.slice(i + 1)];
    };
    dispatchCourseChange(name, newArray());
  };

  const handleRemove = (i, item, name) => {
    let newArray = () => {
      return [...item.slice(0, i), ...item.slice(i + 1)];
    };
    dispatchCourseChange(name, newArray());
  };

  const handleWhatLearnAdd = (item, name) => {
    let newArray = [...item, ""];
    dispatchCourseChange(name, newArray);
  };

  const setCourseImage = (value) => {
    dispatchCourseChange("profileImage", value);
  };
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  return (
    <div className="create__online__course">
      {!noTrack && <Track step={1} givenData={given} />}
      <h1>Add Information</h1>
      <div className="cont">
        <Note
          title={"Take Note"}
          p1={
            "This course information is displayed in the course section. This is what the students see. Please use accurate information"
          }
          p2={
            "After creating your course only admins would be able to delete it"
          }
        />
        <div className="title">
          <h3>Course Settings</h3>
        </div>
        <div className="Custom_Inputs">
          <div className="image__upload__row">
            {<span>Upload Banner</span>}
            <SharedSingleImageUploader
              onSelectFile={setCourseImage}
              preview={course?.profileImage}
              // example
              // tip={`Image size must be  less than ${MAX_SIZE / (1024 * 1024)} MB.`}
              MAX_SIZE={MAX_SIZE}
            />
          </div>
          <div className="input">
            <span>Course Title</span>
            <input
              type="text"
              placeholder="title"
              name="title"
              value={course.title}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="input">
            <span>Course Description</span>
            <input
              type="text"
              placeholder="Course Description"
              name="description"
              value={course.description}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="input">
            <span>Course Price (USD)</span>
            <input
              type="text"
              placeholder="categories"
              name="price"
              value={course.price}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="input">
            <span>Course Category</span>
            <select
              name="categoryNames"
              value={
                categoryNames && course.categoryNames.length > 0
                  ? categoryNames[0]
                  : categories?.length && categories[0]
              }
              onChange={(e) => handleChange(e)}
            >
              <option value="" disabled selected hidden>
                Select a category
              </option>
              {categories.length > 0 &&
                categories.map((Cat, i) => (
                  <option value={Cat.name} key={i}>
                    {Cat.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="input">
            <span>Course Subject</span>
            <select
              name="subjectNames"
              value={subjects && subjects.length > 0 && subjects[0].name}
              onChange={(e) => handleChange(e)}
            >
              <option value="" disabled selected hidden>
                Select a subject
              </option>
              {subjects &&
                subjects.length > 0 &&
                subjects.map((Cat, i) => (
                  <option value={Cat.name} key={i}>
                    {Cat.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="input what_to_learn">
            <span>Learn Objectives</span>
            <div className="imput_with_buttons">
              {whatLearn.length > 0 &&
                whatLearn.map((item, i) => (
                  <div className="flex_center">
                    <input
                      key={i}
                      type="text"
                      placeholder="What to learn"
                      name="whatLearn"
                      value={item}
                      onChange={(e) => handleWhatLearn(e, i, whatLearn)}
                    />
                    {i !== 0 && (
                      <HighlightOffIcon
                        className="Red"
                        onClick={() => handleRemove(i, whatLearn, "whatLearn")}
                      />
                    )}
                  </div>
                ))}

              <div onClick={() => handleWhatLearnAdd(whatLearn, "whatLearn")}>
                <span>Add Learning Objectives</span>
                <button className="add_button">+</button>
              </div>
            </div>
          </div>
          <div className="input what_to_learn">
            <span>Who This Course is For</span>
            <div className="imput_with_buttons">
              {whoFor.length > 0 &&
                whoFor.map((item, i) => (
                  <div className="flex_center">
                    <input
                      key={i}
                      type="text"
                      placeholder="Who This Course is For"
                      name="whoFor"
                      value={item}
                      onChange={(e) => handleWhatLearn(e, i, whoFor)}
                    />
                    {i !== 0 && (
                      <HighlightOffIcon
                        className="Red"
                        onClick={() => handleRemove(i, whoFor, "whoFor")}
                      />
                    )}
                  </div>
                ))}

              <div onClick={() => handleWhatLearnAdd(whoFor, "whoFor")}>
                <span>Add New</span>
                <button className="add_button">+</button>
              </div>
            </div>
          </div>
          <div className="input what_to_learn">
            <span>Announcements</span>
            <div className="imput_with_buttons">
              {announcements &&
                announcements.length > 0 &&
                announcements.map((item, i) => (
                  <div className="flex_center">
                    <input
                      key={i}
                      type="text"
                      placeholder="Announcements"
                      name="announcements"
                      value={item}
                      onChange={(e) => handleWhatLearn(e, i, announcements)}
                    />
                    {i !== 0 && (
                      <HighlightOffIcon
                        className="Red"
                        onClick={() =>
                          handleRemove(i, announcements, "announcements")
                        }
                      />
                    )}
                  </div>
                ))}

              <div
                onClick={() =>
                  handleWhatLearnAdd(announcements, "announcements")
                }
              >
                <span>Add New</span>
                <button className="add_button">+</button>
              </div>
            </div>
          </div>
        </div>
        <div className="navigate__buttons">
          <button className="next__btn" onClick={() => handleSubmit()}>
            Save & Continue
            <img src={arrow} alt="" />
          </button>
          {!noTrack ? (
            <button className="prev__btn" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} alt="" />
              Previous
            </button>
          ) : (
            <button />
          )}
        </div>
      </div>
    </div>
  );
}
