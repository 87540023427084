import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import { Tooltip } from "@mui/material";
import "./Date.styles.scss";
import next from "../../../assets/StudentApp/svgs/Arrow-right.svg";
import moment from "moment";
import * as momentTz from "moment-timezone";

import { Calendar } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getTeacherAvailabilites } from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  bookALesson,
  checkBookings,
  getTeacherBookings,
  handleLessonChange,
  rescheduleLesson,
  selectLessonDate,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { handleUserChange } from "../../../ReduxToolkit/Slices/userSlice";
import {
  convertTimezone,
  convertTimezoneDateTime,
  convertTimezoneFromDate2,
} from "../../../SharedApp/Components/Timezone";
import Note from "../../../TeacherApp/Components/Note/Note";
import { getBookingsById } from "../../../ReduxToolkit/Slices/adminSlice";
import { LensBlurTwoTone } from "@mui/icons-material";
import { convertTimezoneFromDate } from "../../../SharedApp/Components/Timezone";
import { toast } from "react-toastify";

function convertTo24Hour(time) {
  let [hours, modifier] = time.split(":");
  let minutes = modifier.slice(0, 2);
  let ampm = modifier.slice(3);

  if (ampm === "PM" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  } else if (ampm === "AM" && hours === "12") {
    hours = 0; // Midnight case
  }

  return [hours, minutes];
}

const parseTimeStringToDate = (timeString) => {
  // Split the time string into components
  const [time, modifier] = timeString.split(" ");

  // Split the time into hours and minutes
  let [hours, minutes] = time.split(":");

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Adjust hours based on AM/PM
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  // Create a new Date object for the current day
  const now = new Date();
  const date = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes
  );

  return date;
};

export default function Dates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { bookingId } = params;
  const { teacher } = useSelector((state) => state.teacher);
  const { lessonLength, selectedPackage } = useSelector(
    (state) => state.student
  );

  const {
    lessonDetails,
    selectedLesson,
    FlexibleDates,
    bookingType,
    subscription,
  } = useSelector((state) => state.lessons);
  const timezone = useSelector((state) => state.user.timezone);
  const setTimezone = (value) =>
    dispatch(handleUserChange({ name: "timezone", value }));

  const allTimezones = moment.tz.names();

  let lessonDateInital = {
    year: undefined,
    month: undefined,
    day: undefined,
    hour: undefined,
    weekDay: undefined,
    minute: undefined,
  };

  const [availabilites, setAvailabilites] = useState([]);
  const [availabiltyIndex, setAvailabiltyIndex] = useState(0);
  const [lessonDate, setLessonDate] = useState(lessonDateInital);
  const [dailyAvailabilty, setDailyAvailabilty] = useState();
  const [selectedTime, setSelectedTime] = useState("");
  const [showTimings, setShowTimings] = useState(false);
  const [max, setMax] = useState(0);
  const [booked, setBooked] = useState(0);
  const [rescheduleLength, setRescheduleLength] = useState(0);
  const [groupSelected, setGroupSelected] = useState(false);
  const { selectedTeacher } = useSelector((state) => state.lessons);

  const setBookingType = (value) => {
    dispatch(handleLessonChange({ name: "bookingType", value }));
  };
  const convertTo12HourFormat = (time24) => {
    return moment(time24, "HH:mm").format("h:mm A");
  };

  useEffect(() => {
    if (selectedPackage.numOfLessons === 1 && bookingType === "FLEXIBLE") {
      setBookingType("WEEKLY");
    }
  }, [selectedPackage.numOfLessons]);
  useEffect(() => {
    if (bookingId && !teacher.id && location.pathname !== "date") {
      return navigate("/date");
    }
    dispatch(
      getTeacherAvailabilites(bookingId ? teacher.id : selectedTeacher.id)
    ).then((res) => {
      setAvailabilites(res.payload.data);
    });
  }, [location]);

  const newAvailabilty = (day) => {
    availabilites.length &&
      setDailyAvailabilty(
        availabilites.filter((element) => {
          return element.day.toLowerCase() === day.toLowerCase();
        })[0].times
      );
  };

  const newAvailabilites =
    dailyAvailabilty !== undefined && dailyAvailabilty.length > 0
      ? dailyAvailabilty
          .reduce(
            (uniqueAvails, av) => {
              const displayTime =
                av &&
                convertTimezone(
                  moment.utc(av.startTime).format("HH:mm"),
                  "UTC",
                  timezone
                );
              const time = av;

              // Check if the displayTime is already in the set
              if (!uniqueAvails.displayTimes.has(displayTime)) {
                uniqueAvails.displayTimes.add(displayTime);
                uniqueAvails.result.push({ displayTime, time });
              }

              return uniqueAvails;
            },
            { displayTimes: new Set(), result: [] }
          )
          .result.sort((a, b) =>
            moment(a.displayTime, "HH:mm").diff(moment(b.displayTime, "HH:mm"))
          )
      : [];
  const handleNextClick = () => {
    // if (subscription === null)
    //   return toast.warning("You need to choose a subscription type");
    if (bookingType === "FLEXIBLE") {
      if (
        !FlexibleDates ||
        FlexibleDates?.length !== selectedPackage.numOfLessons
      ) {
        return toast.error(
          `You have to choose ${selectedPackage.numOfLessons} lessons`
        );
      } else {
        return navigate("/method");
      }
    } else {
      const newDate = moment.utc(dailyAvailabilty[availabiltyIndex].startTime);
      const newDate2 = moment.utc(dailyAvailabilty[availabiltyIndex].startTime);
      const endTime = newDate
        .add(1, "hour")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      const endTime30 = newDate2
        .add(lessonLength, "minutes")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      if (
        lessonDate.day === null ||
        lessonDate.weekDay === null ||
        lessonDate.year === null ||
        lessonDate.month === null ||
        lessonDate.hour === null
      ) {
        return alert("Please select the required dates and hours");
      }
      if (lessonDetails.isGroup) {
        dispatch(
          selectLessonDate({
            day: lessonDate.weekDay,
            date: new Date(lessonDate.year, lessonDate.month, lessonDate.day),
            startTime: lessonDetails.groupLessonTime.startTime,
            endTime: lessonLength === 60 ? endTime : newDate,
            timeZone: "string",
          })
        );
      } else {
        let startHours = convertTimezone(
          moment
            .utc(dailyAvailabilty[availabiltyIndex].startTime)
            .format("HH:mm"),
          "UTC",
          timezone
        );

        let hours24 = new Date(lessonDate.hour).getHours();
        let minutes = new Date(lessonDate.minute).getMinutes();
        let newDate = new Date(
          lessonDate.year,
          lessonDate.month - 1, // Adjusting month since JavaScript months are 0-indexed
          lessonDate.day,
          hours24,
          minutes
        );

        let date = moment(newDate).format("YYYY-MM-DDTHH:mm:ss.sssZ");
        console.log("date", moment(date).format("DD/MM/YYYY"));
        dispatch(
          selectLessonDate({
            day: lessonDate.weekDay,
            date, // test here
            startTime: dailyAvailabilty[availabiltyIndex].startTime,
            endTime: lessonLength === 60 ? endTime : endTime30,
            timeZone: "string",
          })
        );
      }
      navigate("/method");
    }
  };

  const capitalize = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else return str;
  };

  const onAvailabilitySelect = (e) => {
    if (lessonDetails.isGroup) {
      setGroupSelected(false);

      if (lessonDetails.groupLessonTime.day === e.weekDay.name.toUpperCase()) {
        !showTimings && setShowTimings(true);
      } else {
        showTimings && setShowTimings(false);
      }
    }
    setSelectedTime("");
    setLessonDate((lesson) => ({
      ...lesson,
      year: e.year,
      month: e.month.number,
      day: e.day,
      hour: null,
      weekDay: e.weekDay.name.toUpperCase(),
    }));
    newAvailabilty(e.weekDay.name);
  };

  const onHourSelect = (time, i) => {
    let hours24 = new Date(time.time.startTime).getHours();
    let minutes = new Date(time.time.startTime).getMinutes();

    console.log("hours24", { hours24, time: time.time });

    setAvailabiltyIndex(i);
    setSelectedTime(time.displayTime);
    let newDate = new Date(time.time.startTime);

    setLessonDate((lesson) => ({
      ...lesson,
      hour: hours24,
      minute: minutes,
    }));
    if (bookingType === "FLEXIBLE") {
      handleAddFlexibleDate({ ...lessonDate, hour: hours24, minute: minutes });
    }
  };
  const onGroupSelect = () => {
    setGroupSelected(!groupSelected);
    let newDate = new Date(lessonDetails.groupLessonTime.startTime);
    setLessonDate((lesson) => ({ ...lesson, hour: newDate.getHours() }));
  };

  const handleUpdate = () => {
    if (rescheduleLength === 0) {
      return toast.error("Please select the new lesson duration");
    }
    if (
      lessonDate.year === undefined ||
      lessonDate.weekDay === undefined ||
      lessonDate.month === undefined ||
      lessonDate.day === undefined ||
      lessonDate.hour === undefined
    ) {
      return toast.error("Please enter all info to update the lesson");
    }
    const newDate = moment.utc(dailyAvailabilty[availabiltyIndex].startTime);
    const newDate2 = moment.utc(dailyAvailabilty[availabiltyIndex].startTime);
    const endTime = newDate.add(1, "hour").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const endTime30 = newDate2
      .add(parseInt(rescheduleLength), "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    let hours24 = new Date(lessonDate.hour).getHours();
    let minutes = new Date(lessonDate.minute).getMinutes();
    let newDAte = new Date(
      lessonDate.year,
      lessonDate.month - 1, // Adjusting month since JavaScript months are 0-indexed
      lessonDate.day,
      hours24,
      minutes
    );

    let date = moment(newDAte).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    dispatch(
      rescheduleLesson({
        appointmentId: bookingId,
        day: lessonDate.weekDay,
        date, // test here
        day: lessonDate.weekDay,
        startTime: dailyAvailabilty[availabiltyIndex].startTime,
        endTime: lessonLength === 60 ? endTime : endTime30,
      })
    ).then(() => {
      toast.success("Appointment updated, thank you!");
      return navigate("/teachers");
    });
  };

  const setFlexibleDates = (value) => {
    const exists = FlexibleDates.some((date) => {
      return (
        date.day === value.day &&
        date.date === value.date &&
        moment(date.startTime).format("HH:mm") ===
          moment(value.startTime).format("HH:mm")
      );
    });
    console.log("value", value);
    if (!exists) {
      if (FlexibleDates?.length < selectedPackage.numOfLessons) {
        dispatch(
          handleLessonChange({
            name: "FlexibleDates",
            value: [...FlexibleDates, value],
          })
        );
      } else {
        toast.error(
          `The package that you have selected includes ${selectedPackage.numOfLessons} lessons only`
        );
        setSelectedTime();
      }
    } else {
      toast.error("This date was already added");
      setSelectedTime();
    }
  };

  const onDeleteDate = (index) => {
    const updatedFlexibleDates = [...FlexibleDates];
    updatedFlexibleDates.splice(index, 1);
    dispatch(
      handleLessonChange({ name: "FlexibleDates", value: updatedFlexibleDates })
    );
  };
  const handleAddFlexibleDate = (givenDate) => {
    if (bookingType !== "FLEXIBLE") {
      return;
    }
    let newDAte = new Date(
      givenDate.year,
      givenDate.month - 1, // Adjusting month since JavaScript months are 0-indexed
      givenDate.day,
      givenDate.hour,
      givenDate.minute
    );
    const newDate2 = moment.utc(newDAte);

    const endTime = newDate2
      .add(lessonLength, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    let date = moment(newDAte).format("YYYY-MM-DDTHH:mm:ss.sssZ");

    setFlexibleDates({
      day: givenDate.weekDay,
      date, // test here
      startTime: newDAte,
      endTime: endTime,
    });
  };

  const isLessonDateToday = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    return (
      lessonDate?.year === currentYear &&
      lessonDate?.month === currentMonth + 1 &&
      lessonDate?.day === currentDay
    );
  };
  function isPastTime(time) {
    if (!isLessonDateToday()) {
      return false;
    }
    const now = new Date();
    const currentHour = now.getHours(); // This gets the current hour in 24-hour format
    const currentMinute = now.getMinutes();
    const timeParts = time.split(":");
    // Parse the hour from the input time string
    let inputHour = parseInt(time.split(":")[0], 10);
    const inputMinute = parseInt(timeParts[1], 10);

    // Adjust inputHour for PM times
    if (time.includes("PM") && inputHour < 12) {
      inputHour += 12;
    }

    const isPastOrSameHour =
      inputHour < currentHour ||
      (inputHour === currentHour && inputMinute <= currentMinute);

    return isPastOrSameHour; // Returns true if the input hour is less than the current hour
  }

  return (
    <div className="select__dates">
      <div className="select__date__container">
        {selectedPackage?.numOfLessons === 1
          ? null
          : !bookingId && (
              <>
                {bookingType === "WEEKLY" ? (
                  <Note
                    title={
                      "When selecting weekly lessons, choose your preferred day and time for the first lesson. "
                    }
                    p1="The system will automatically schedule the upcoming lessons on the same day and time, based on the number of lessons in your package. Enjoy hassle-free booking and consistent learning!"
                    marginTops
                  />
                ) : (
                  <Note
                    title="For Flexible Lessons"
                    p1="Select your desired dates and hours for each lesson. Your schedule will be based on the number of lessons in your package. Enjoy the freedom to book lessons that fit your availability!"
                    marginTops
                  />
                )}

                <div className="booking_switch">
                  <span
                    className={`${bookingType === "WEEKLY" ? "selected" : ""}`}
                    onClick={() => setBookingType("WEEKLY")}
                  >
                    Weekly
                  </span>
                  <span
                    className={`${
                      bookingType === "FLEXIBLE" ? "selected" : ""
                    }`}
                    onClick={() => setBookingType("FLEXIBLE")}
                  >
                    FLEXIBLE
                  </span>
                </div>
              </>
            )}

        {/* {!lessonDetails.isGroup && (
          <div className="booking_switch">
            <span
              className={`${subscription === true ? "selected" : ""}`}
              onClick={() =>
                dispatch(
                  handleLessonChange({ name: "subscription", value: true })
                )
              }
            >
              Subscribe
            </span>
            <span
              className={`${subscription === false ? "selected" : ""}`}
              onClick={() => {
                dispatch(
                  handleLessonChange({ name: "subscription", value: false })
                );
              }}
            >
              Do not Subscribe
            </span>
          </div>
        )} */}

        {bookingId && (
          <Note
            title={"The following dates are based on your availabilities"}
            a1="Click here if you would like to update your availabilities"
            link="/teachers/subjects/2"
            marginTops
          />
        )}
        <div className="pickers">
          <div className="lesson__date">
            <h1>Select a lesson date</h1>
            <p>Select available time and date to take this course</p>

            <Calendar
              className="custom__calendar"
              shadow={false}
              weekStartDayIndex={1}
              minDate={new Date()}
              onChange={(e) => onAvailabilitySelect(e)}
            />
          </div>

          <div className="lesson__time">
            <div className="flex_center">
              <p>Time zone: </p>
              <select
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
              >
                {allTimezones.map((tz, i) => (
                  <option key={i} value={tz}>
                    {tz}
                  </option>
                ))}
              </select>
            </div>
            {!bookingId ? (
              <h3>
                Dates to book (
                {selectedPackage.numOfLessons - FlexibleDates?.length})
              </h3>
            ) : (
              <h3>
                Dates to book
                {lessonDate.day}
              </h3>
            )}
            {bookingType === "FLEXIBLE" && FlexibleDates?.length ? (
              <div className="flexible_dates_container">
                {FlexibleDates &&
                  FlexibleDates.map((date, i) => (
                    <div key={i} className="flexible_dates">
                      <>
                        <span>{moment(date.date).format("DD/MM/YYYY")} </span>
                        <span className="hours">
                          ({moment(date.startTime).format("HH:mm")} -
                          {moment(date.endTime).format("HH:mm")})
                        </span>
                      </>
                      <button
                        className="deleteButton"
                        onClick={() => onDeleteDate(i)}
                      >
                        x
                      </button>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}

            {lessonDetails.isGroup ? (
              <>
                <h1>Group lesson time</h1>
              </>
            ) : (
              <>
                <div className="flex_center">
                  <h1>Select a lesson time</h1>{" "}
                  {/* {bookingType === "FLEXIBLE" && (
                    <div className="add_flexible_button">
                      <button onClick={() => handleAddFlexibleDate()}>
                        Add Date
                      </button>
                    </div>
                  )} */}
                  {bookingId && (
                    <div className="add_flexible_button">
                      <p>Select Duration</p>
                      <select
                        onChange={(e) => setRescheduleLength(e.target.value)}
                        defaultValue={rescheduleLength}
                        value={rescheduleLength}
                      >
                        <option value={0} disabled>
                          0
                        </option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                      </select>
                    </div>
                  )}
                </div>
              </>
            )}
            {lessonDetails.isGroup ? (
              <div className="times">
                {showTimings ? (
                  <>
                    <p>
                      Available spots: {max - booked} out of {max}
                    </p>
                    <span
                      className={`time ${groupSelected ? "selected" : ""}`}
                      onClick={() => onGroupSelect()}
                    >
                      {convertTimezone(
                        moment
                          .utc(lessonDetails.groupLessonTime.startTime)
                          .format("HH:mm"),
                        "UTC",
                        timezone
                      )}
                      -
                      {convertTimezone(
                        moment
                          .utc(lessonDetails.groupLessonTime.endTime)
                          .format("HH:mm"),
                        "UTC",
                        timezone
                      )}
                    </span>
                  </>
                ) : lessonDate.weekDay ? (
                  <span>
                    This group lesson not available on{" "}
                    {capitalize(lessonDate.weekDay)}
                  </span>
                ) : null}
              </div>
            ) : newAvailabilites.length ? (
              <>
                <div className="times">
                  {newAvailabilites.map((time, i) => (
                    <Tooltip
                      title={
                        isPastTime(time.displayTime)
                          ? "You cannot book a lesson in the past"
                          : ""
                      }
                      arrow
                      placement="top"
                    >
                      <div
                        key={i}
                        className={`time ${
                          selectedTime === time.displayTime ? "selected" : ""
                        } ${isPastTime(time.displayTime) ? "past" : ""}`}
                        onClick={() => {
                          !isPastTime(time.displayTime) &&
                            onHourSelect(time, i);
                        }}
                      >
                        <span>{time.displayTime}</span>
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </>
            ) : (
              <p> No availabilities at selected date</p>
            )}
          </div>
        </div>
        <div className="button__container">
          {bookingId ? (
            <button className="btn__primary" onClick={() => handleUpdate()}>
              Update <img src={next} alt="" />
            </button>
          ) : (
            <button className="btn__primary" onClick={() => handleNextClick()}>
              Next <img src={next} alt="" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
