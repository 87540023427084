import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import "./CourseInstructor.styles.scss";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import rating_star from "../../../../assets/TeacherApp/assets/svgs/rating-star.svg";
import youtube from "../../../../assets/TeacherApp/assets/svgs/youtube.svg";
import users from "../../../../assets/TeacherApp/assets/svgs/users.svg";
import award from "../../../../assets/TeacherApp/assets/svgs/award.svg";
import singlestar from "../../../../assets/TeacherApp/assets/svgs/singlestar.svg";
import img5 from "../../../../assets/TeacherApp/assets/images/course.webp";
import { getTeacherById } from "../../../../ReduxToolkit/Slices/teacherSlice";
import { getStudentCourses } from "../../../../ReduxToolkit/Slices/coursesSlice";

export default function CourseInstructor({ id, course, courses }) {
  const [showFullText, setShowFullText] = useState(false);
  const [teacher, setTeacher] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    id &&
      dispatch(getTeacherById(id)).then((res) => {
        setTeacher(res.payload);
      });
  }, [id]);

  const data = [
    { text: teacher.rating?.toFixed(1), image: rating_star },
    { text: teacher?.reviews?.length, image: award },
    // { text: "2,393 Students", image: users },
    { text: courses.length + 1 + " courses", image: youtube },
  ];

  function limitWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }

  return (
    <div className="course__description__container">
      <div className="title">
        <h1>Instructor</h1>
      </div>
      <div className="course__instructor">
        <div className="name">
          <Link to={`/courses/instructor/${course?.teacher?.id}`}>
            <span>{course?.teacher?.user.name}</span>
          </Link>
        </div>
        <div className="position">
          <p>Business Planning Expert</p>
        </div>
        <div className="instructor__info">
          <div className="image">
            <img src={course?.teacher?.user?.profileImage?.url} alt="" />
          </div>
          <div className="details">
            {data.map((el, i) => (
              <div className="row">
                <img src={el.image} alt="" />
                <span>{el.text}</span>
              </div>
            ))}
          </div>
        </div>
        {teacher.id && (
          <div className="description">
            <p>
              {showFullText
                ? teacher?.description
                : limitWords(teacher.description, 40)}
            </p>
            {!showFullText ? (
              <span className="showMore" onClick={() => setShowFullText(true)}>
                View more <ExpandMoreIcon />
              </span>
            ) : (
              <span className="showMore" onClick={() => setShowFullText(false)}>
                View less
                <ExpandLessIcon />
              </span>
            )}
            {/* <div className="showMore">
          <span>show more</span>
        </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
