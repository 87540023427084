import React, { useEffect, useState } from "react";
import moment from "moment";
import plus from "../../../assets/AdminApp/svgs/plus.svg";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import { Link } from "react-router-dom";
import Pagination from "../../../Components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import { getAllCourses } from "../../../ReduxToolkit/Slices/coursesSlice";
import notfound from "../../../assets/SharedApp/images/default.webp";

export default function DraftCourses({ title, isDraft, isDashboard }) {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const [numberOfPages, setNumberPages] = useState(null);
  const [total, setTotal] = useState(null);

  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCourses, setTotalCourses] = useState(0);

  useEffect(() => {
    dispatch(getAllCourses(`search=${search}&page=${currentPage}`)).then(
      (res) => {
        setCourses(res.payload.coursesWithPrice);
        setTotalCourses(res.payload.totalCourses);
        setNumberPages(res.payload.numOfPages);
        setTotal(res.payload.totalCourses);
      }
    );
  }, [currentPage, search]);

  return (
    <div className="manage__teachers__page manage__teachers">
      <div className="header">
        <div className="header__text">
          <span>Courses</span>
        </div>
        <div className="teachers__actions">
          <input
            type="text"
            placeholder="search courses"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {isDashboard && (
          <Link to={"/admin/courses"}>
            <button>View Courses</button>
          </Link>
        )}
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>COURSES</th>
              <th>Status</th>
              <th>CREATE AT</th>
              <th>UPDATED AT</th>
              <th>COURSE PLAN</th>
              <th>AUTHOR</th>
              <th>NO.STUDENTS</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {courses &&
              courses.length > 0 &&
              courses.map((course, i) => <Row key={i} course={course} />)}
          </tbody>
        </table>
      </div>
      <Pagination
        page={numberOfPages}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        dataRendered={courses?.length}
        totalData={totalCourses}
      />
    </div>
  );
}

const Row = ({ course }) => {
  return (
    <tr>
      <td>
        <div className="student__cell">
          <img
            src={
              course?.profileImage?.url ? course?.profileImage?.url : notfound
            }
            alt=""
          />
          <div className="name">
            <span>{course.title}</span>
          </div>
        </div>
      </td>
      <td>
        <button className={course.status.toLowerCase()}>{course.status}</button>
      </td>
      <td>
        <div className="manage__std__lesson">
          <div className="lesson__date">
            <span style={{ color: "#3699FF" }}>
              {moment(course.createdAt).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      </td>
      <td>
        <div className="manage__std__lesson">
          <div className="lesson__date">
            <span style={{ color: "#3699FF" }}>
              {moment(course.updatedAt).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span>${course.price / 100}</span>
      </td>
      <td>
        <span>{course.teacher.user.name}</span>
      </td>
      <td>
        <span>{course.students.length}</span>
        <p>students</p>
      </td>
      {/* <td>
        <select className="select__change__cat">
          <option value="featured">featured</option>
          <option value="popular">most Popular</option>
        </select>
      </td> */}
      <td>
        <div className="course__draft__actions">
          <Link to={`/admin/courses/viewCourse/${course.id}`}>
            <button className="accepted">view</button>
          </Link>
        </div>
      </td>
    </tr>
  );
};
