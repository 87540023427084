import React, { useState } from "react";
import { useDispatch } from "react-redux";

import CurriculumRow, {
  EditLecutre,
} from "../../../Components/Courses/CurriculumRow/CurriculumRow";

import editImage from "../../../../assets/SharedApp/svgs/Button Edit.svg";
import EditCourse from "../EditCourse/EditCourse";
import EditorPage from "../../../Components/Courses/EditorPage/EditorPage";
import del from "../../../../assets/SharedApp/svgs/Button Trash.svg";
import plus from "../../../../StudentApp/assets/plusBlue.svg";
import minus from "../../../../StudentApp/assets/minusGray.svg";

import {
  deleteChapterLesson,
  handleDeleteChapter,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import AlertModalDelete from "../../../../AdminApp/Components/AlertModalDelete/AlertModalDelete";
import { useParams } from "react-router-dom";

export default function Chapter({
  chapter,
  onEditTitle,
  i,
  editIndex,
  setEditIndex,
}) {
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = () => {
    dispatch(handleDeleteChapter({ index: i }));
    dispatch(deleteChapterLesson(id));
    setModal(false);
  };

  return (
    <div className="cont">
      {modal && (
        <AlertModalDelete
          type={"Chapter"}
          handleDelete={onDelete}
          setModal={setModal}
        />
      )}
      <div className="manage__curr">
        {!edit ? (
          <h2 className="flex_center">
            {chapter.title}{" "}
            <img
              alt=""
              src={editImage}
              onClick={() => setEdit(true)}
              className="pointer"
            />
            <img
              alt=""
              src={del}
              onClick={() => setModal(true)}
              className="pointer"
            />
          </h2>
        ) : (
          <EditLecutre
            value={chapter.title}
            // setValue={setValue}
            setValue={(value) => onEditTitle("title", i, value)}
            setEdit={() => setEdit(false)}
          />
        )}
        <p>Create your curriculum</p>
      </div>
      <EditCourse index={i} />
      <div className="rows">
        <EditorPage index={i} />
      </div>
    </div>
  );
}
