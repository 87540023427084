import React, { useEffect, useState } from "react";
import "./MyRecommendations.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherByIdWithoutSave } from "../../../ReduxToolkit/Slices/teacherSlice";
import { Recommendations } from "../../../SharedApp/Components/TeacherPageBody/BigContainer";
import RequestRecommendation from "../RequestRecommendation/RequestRecommendation";
const MyRecommendations = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.teacher.teacher);
  const [teacher, setTeacher] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getTeacherByIdWithoutSave(id)).then((res) => {
      setLoading(true);
      if (res.type.includes("fulfilled")) {
        setTeacher(res.payload);
        setLoading(false);
      }
    });
  }, []);
  return (
    <div className="my_recommendations">
      {/* <h2>My Recommendations</h2> */}
      {teacher?.recommendations?.length ? (
        <Recommendations teacher={teacher} />
      ) : (
        <>
          {!loading ? (
            <>
              <h2>You Don't have any recommendations yet</h2>
              <RequestRecommendation />
            </>
          ) : (
            <h3>Loading</h3>
          )}
        </>
      )}
    </div>
  );
};

export default MyRecommendations;
