import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { handleChapterChange } from "../../../../ReduxToolkit/Slices/coursesSlice";
import { uploadVideo } from "../../../../ReduxToolkit/Slices/teacherSlice";
import EditorPageRow from "../EditorPageRow/EditorPageRow";

import "../../../Pages/Courses/EditCourse/EditCourse.styles.scss";
import "./AddVideo.styles.scss";

import FilesUploader from "../FilesUploader/FilesUploader";
import VidRow from "../VidRow/VidRow";
// import edit from "../../../../assets/SharedApp/svgs/edit.svg";
// import volicity from "../../../../assets/TeacherApp/assets/svgs/volicity.svg";
import upload from "../../../../assets/TeacherApp/assets/svgs/upload.svg";
// import remove from "../../../../assets/SharedApp/svgs/remove.svg";
import EditorPage from "../EditorPage/EditorPage";
import UploadImageRow from "../UploadImageRow/UploadImageRow";
import FileUploadRow from "../UploadImageRow/FileUploadRow";
import VideoUploaderComponent from "../../VideoUploaderComponent/VideoUploaderComponent";
import SharedVideoUploader from "../../../../Components/SharedVideoUploader/SharedVideoUploader";
import SharedSingleImageUploader from "../../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import SharedSingleFileUploader from "../../../../Components/SharedSingleFileUploader/SharedSingleFileUploader";
import SharedFileUploader from "../../../../Components/SharedFileUploader/SharedFileUploader";
import SharedMultipleFileUploader from "../../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import CourseFileUploader from "../../../../Components/CourseFileUploader/CourseFileUploader";

export default function AddVideo({
  lesson,
  index,
  item,
  image,
  handleLessonChange,
  file,
  children,
}) {
  const handleItemChange = (value) => {
    handleLessonChange(value);
  };

  const handleImageChange = (value) => {
    handleLessonChange({
      target: {
        value: [{ ...value, videoLength: 0 }],
        name: "videos",
      },
    });
  };

  const handleVideoChange = (value, length) => {
    handleLessonChange({
      target: {
        value: [{ ...value, videoLength: length }],
        name: "videos",
      },
    });
  };

  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_DURATION = 360;

  return (
    <div className="course__video__uploader">
      {image ? (
        // <UploadImageRow
        //   setGetImage={handleImageChange}
        //   image={lesson?.videos[0].url}
        //   title={"Upload Image"}
        // />
        <>
          <h1>Image uploader</h1>
          <SharedSingleImageUploader
            onSelectFile={handleItemChange}
            preview={item}
            // tip={`Image size must be  less than ${MAX_SIZE / (1024 * 1024)} MB.`}
            MAX_SIZE={MAX_SIZE}
          />
        </>
      ) : file ? (
        <CourseFileUploader
          onSelectFile={handleItemChange}
          preview={lesson?.videos[0]}
          // tip={`Image size must be  less than ${MAX_SIZE / (1024 * 1024)} MB.`}
          MAX_SIZE={MAX_SIZE}
        />
      ) : (
        <>
          <div className="video_children_container">
            <h1>Video uploader</h1>
            {children}
          </div>
          <p>Use the video uploader to upload videos into your lecture.</p>
          {/* <VideoUploaderComponent
            videoLoading={videoLoading}
            setVideoLoading={setVideoLoading}
            onSelectVideo={onSelectVideo}
            image={lesson?.videos[0].url}
            index={index}
          /> */}
          <SharedVideoUploader
            index={"file-video"}
            onVideoChange={(value, length) => {
              handleItemChange(value);
            }}
            video={lesson ? lesson : [{ url: "", publicId: "" }]}
            MAX_VIDEO_SIZE={MAX_VIDEO_SIZE}
            MAX_VIDEO_DURATION={MAX_VIDEO_DURATION}
          />
        </>
      )}
    </div>
  );
}
