import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  deleteSubject,
  getSubjects,
} from "../../../ReduxToolkit/Slices/lessonsSlice";

import "./Subjects.styles.scss";

import plus from "../../../assets/AdminApp/svgs/plus.svg";
import view from "../../../assets/AdminApp/svgs/view.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";
import deleteico from "../../../assets/AdminApp/svgs/deleteico.svg";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import Pagination from "../../../Components/Pagination/Pagination";
import warning from "../../../assets/StudentApp/svgs/warning.svg";
import success from "../../../assets/StudentApp/svgs/success.svg";

import Alert from "../../Components/Alert/Alert";
import {
  deleteCourseSubjects,
  getCourseSubjects,
} from "../../../ReduxToolkit/Slices/coursesSlice";

export default function Subjects({ courses }) {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [filters, setFilters] = useState({
    sortField: "name",
    sortOrder: "asc",
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const handleDeleteSubject = (name) => {
    courses
      ? dispatch(deleteCourseSubjects(name)).then((res) => {
          if (res.type.includes("fulfilled")) setShowConfirmation(false);
          setShowSuccess(true);
        })
      : dispatch(deleteSubject(name)).then((res) => {
          if (res.type.includes("fulfilled")) setShowConfirmation(false);
          setShowSuccess(true);
        });
  };

  useEffect(() => {
    courses
      ? dispatch(getCourseSubjects()).then((res) => {
          setSubjects(res?.payload);
        })
      : dispatch(getSubjects({ ...filters, page: currentPage })).then((res) => {
          setSubjects(res?.payload?.data?.subjects);
          setPage(res?.payload?.data?.numOfPages);
          if (!fetched) {
            setFetched(true);
          }
        });
  }, [currentPage, filters, refresh, location]);
  return (
    <>
      <div className="manage__admin__subjects">
        <div className="header">
          <div className="header__text">
            <span>Subjects Lists</span>
            {/* <p>More than 400+ lesson Categories</p> */}
          </div>
          <div className="teachers__actions">
            {!courses && (
              <>
                <select
                  id="select"
                  onChange={(e) => {
                    if (e.target.value === "asc" || e.target.value === "desc") {
                      setFilters({
                        sortOrder: e.target.value,
                        sortField: "name",
                      });
                    } else if (
                      e.target.value === "ACTIVE" ||
                      e.target.value === "INACTIVE"
                    ) {
                      setFilters({
                        sortField: "name",
                        status: e.target.value,
                      });
                    } else {
                      setFilters({});
                    }
                  }}
                >
                  <option value="">Sort</option>
                  <option value="asc">asc</option>
                  <option value="desc">desc</option>
                  <option value="ACTIVE">active</option>
                  <option value="INACTIVE">inactive</option>{" "}
                </select>
                <div className="search__teachers">
                  <input
                    type="text"
                    placeholder="Search Subjects"
                    onChange={(e) =>
                      setFilters({
                        sortField: filters.sortField,
                        sortOrder: filters.sortOrder,
                        search: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
            <Link className="new__category" to={"new"}>
              <img src={plus} alt="" />
              <span> Add New Subject</span>
            </Link>
          </div>
        </div>
        <div className="students__table" style={{ marginTop: "30px" }}>
          <table>
            <tr>
              <th>
                <span className="th__span">TITLE</span>
              </th>
              <th>
                <span className="th__span">CREATED AT</span>
              </th>
              <th>
                <span className="th__span">STATUS</span>
              </th>
              <th></th>
            </tr>
            {subjects &&
              subjects.map((subject) => (
                <tr key={subject.id}>
                  <td>
                    <div className="manage__std__lesson">
                      <div className="lesson__title">
                        <img
                          src={
                            subject.images.length
                              ? subject.images[0].url
                              : tutor
                          }
                          alt=""
                        />
                        <span>{subject.name}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>
                      {subject.images && subject.images.length > 0
                        ? subject.images[0].updatedAt.split("T")[0]
                        : "no date"}
                    </span>
                    <p>Date</p>
                  </td>

                  <td>
                    <span className={subject.status.toLowerCase()}>
                      {subject.status.toLowerCase()}
                    </span>
                  </td>
                  <td>
                    <div className="category__actions">
                      <div className="actions">
                        <Link to={`${subject.name}`}>
                          <img src={view} alt="" />
                        </Link>
                        <Link to={`${subject.name}/edit`}>
                          <img src={edit} alt="" />
                        </Link>
                        <img
                          src={deleteico}
                          alt="delete subject"
                          onClick={() => {
                            setShowConfirmation(true);
                            setSelectedSubject(subject.name);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </table>
        </div>
        <Pagination
          page={page}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          dataRendered={subjects?.length}
        />
      </div>
      {showConfirmation && (
        <ConfirmDeleteModal
          onCancel={() => setShowConfirmation(false)}
          onConfirm={() => handleDeleteSubject(selectedSubject)}
        />
      )}
      {showSuccess && (
        <SuccessModal
          text={`${selectedSubject} was deleted successfully`}
          onConfirm={() => {
            setShowSuccess(false);
            setRefresh(!refresh);
          }}
        />
      )}
    </>
  );
}
const ConfirmDeleteModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="confirm__choice">
      <Alert
        text={"Are you sure you want to delete this payment method? "}
        btn1Text="Yes, delete"
        btn1Color={"F64E60"}
        img={warning}
        btn2
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </div>
  );
};
const SuccessModal = ({ text, onCancel, onConfirm }) => {
  return (
    <div className="confirm__choice">
      <Alert
        text={text}
        btn1Text="Close"
        btn1Color={"50cd89"}
        img={success}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </div>
  );
};
