import moment from "moment-timezone";

export function convertTimezoneTwentyFour(time, fromTimezone, toTimezone) {
  let date = moment().format("YYYY-MM-DD");
  let fromTime = moment.tz(`${date} ${time}`, "YYYY-MM-DD HH:mm", fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return toTime.format("HH:mm");
}
export function convertTimezone(time, fromTimezone, toTimezone) {
  let date = moment().format("YYYY-MM-DD");
  let fromTime = moment.tz(`${date} ${time}`, "YYYY-MM-DD HH:mm", fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return toTime.format("HH:mm A");
}

export function convertTimezoneDash(time, fromTimezone, toTimezone) {
  let date = moment().format("YYYY-MM-DD");
  let fromTime = moment.tz(`${date} ${time}`, "YYYY-MM-DD HH:mm", fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  // Remove space between time and period (AM/PM) for first time
  return toTime.format("h:mmA").replace(/(AM|PM)/, " $1");
}
export function convertTimezoneFromDate(time, fromTimezone, toTimezone) {
  let date = moment(time).format("YYYY-MM-DD HH:mm");
  let fromTime = moment.tz(`${date}`, "YYYY-MM-DD HH:mm", fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return `${toTime.format("DD/MM/YYYY HH:mm")}`;
}

export function convertTimezoneFromDate2(time, fromTimezone, toTimezone) {
  let fromTime = moment.tz(time, fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return toTime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
}
export function convertTimezoneDate(time, fromTimezone, toTimezone) {
  let date = moment().format("YYYY-MM-DD");
  let fromTime = moment.tz(`${date} ${time}`, fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return toTime.format("YYYY-MM-DDTHH:mm:ss.sssZ");
}
export function convertTimezoneFromDate3(time, fromTimezone, toTimezone) {
  let date = moment(time).format("YYYY-MM-DD HH:mm");
  let fromTime = moment.tz(`${date}`, "YYYY-MM-DD HH:mm", fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return `${toTime.format("DD/MM/YYYY")}`;
}
export function convertTimezoneWithDate(
  time,
  dategiven,
  fromTimezone,
  toTimezone
) {
  let date = moment(dategiven).format("YYYY-MM-DD");
  let fromTime = moment.tz(`${date} ${time}`, fromTimezone);
  let toTime = fromTime.clone().tz(toTimezone);
  return toTime.format("YYYY-MM-DDTHH:mm:ss.sssZ");
}

export function convertTimezoneDateTime(dateTime, fromTimezone, toTimezone) {
  const fromDateTime = moment.tz(dateTime, fromTimezone);
  const toDateTime = fromDateTime.clone().tz(toTimezone);

  return toDateTime.format();
}
