import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import { SubjectRow } from "./../../../Components/SubjectRow/SubjectRow";

import Button from "../../../Components/Button/Button";
import BgCheck from "../../Components/LessonInput/BgCheck";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";

export const SignupStepFive = () => {
  const navigate = useNavigate();
  const options = ["At your house", "Student place", "Online"];
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );

  const [selectedLocations, setSelectedLocations] = useState(
    teacherToRegister.selectedLocations
      ? teacherToRegister.selectedLocations
      : []
  );
  const [travelDistance, setTravelDistance] = useState(
    teacherToRegister.travelDistance ? teacherToRegister.travelDistance : 0
  );
  const [backgroundCheck, setBackgroundCheck] = useState(
    teacherToRegister.backgroundCheck
      ? teacherToRegister.backgroundCheck
      : {
          status: "UNCHECKED",
          image: { url: "", publicId: "" },
        }
  );

  const [geoLocations, setGeoLocations] = useState(
    teacherToRegister?.geoLocation
      ? teacherToRegister?.geoLocation
      : { latitude: 0, longitude: 0, address: "" }
  );
  const dispatch = useDispatch();
  const handleLocationClick = (location) => {
    setSelectedLocations((prevLocations) => {
      if (prevLocations.includes(location)) {
        // deny unselecting if only 1 option is selected
        if (prevLocations.length === 1) return prevLocations;
        return prevLocations.filter((loc) => loc !== location);
      } else {
        return [...prevLocations, location];
      }
    });
  };

  const handleSubmit = () => {
    if (
      selectedLocations.includes("Student place") ||
      selectedLocations.includes("At your house")
    ) {
      if (!backgroundCheck?.image?.url) {
        return toast.error(
          "You need to add a background check to teach physically"
        );
      }
      if (!geoLocations?.address) {
        return toast.error("You need to add your location to teach physically");
      }
    }
    if (selectedLocations.includes("Student place") && travelDistance === 0) {
      return toast.error(
        "You need to add your maximum travel distance to teach at student's place"
      );
    }
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: {
          ...teacherToRegister,
          selectedLocations,
          backgroundCheck,
          travelDistance,
          geoLocation: geoLocations,
        },
      })
    );
    navigate("/teacher-signup/step-six");
  };

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[
            "Your address will never appear on the site. It will only be shared with students to whom you have agreed to give lessons.",
            "At your location: You can give lessons at your home at the address indicated. Click on the address filed and find your location from the map.",
            "Student homes: Choose the max distance that you can travel to student's place. ",
          ]}
          className={"register_note_2"}
        />
      </div>
      <div className="container_2">
        <h2 className="no_max" style={{ margin: "20px 0" }}>
          <span>Location</span> of your lesson
        </h2>
        <div className="subjects_rows_container" style={{ overflow: "hidden" }}>
          <div className="subjects_rows">
            {options.map((el) => (
              <>
                <SubjectRow
                  name={el}
                  onClick={() => handleLocationClick(el)}
                  selected={selectedLocations.includes(el)}
                  key={el}
                />
              </>
            ))}
          </div>
        </div>
        {selectedLocations?.length > 0 &&
          (selectedLocations.includes("Student place") ||
            selectedLocations.includes("At your house")) && (
            <BgCheck
              signUp={true}
              backgroundCheck={backgroundCheck}
              setBackgroundCheck={setBackgroundCheck}
              geoLoc={geoLocations}
              setGeoLoc={setGeoLocations}
            />
          )}
        {selectedLocations?.length > 0 &&
          selectedLocations.includes("Student place") && (
            <>
              <h3 className="h3">
                What is your maximum travel distance in KM?
              </h3>
              <input
                type="number"
                value={travelDistance}
                className="maxTravel_input"
                onChange={(e) => setTravelDistance(e.target.value)}
                placeholder="Travel Distance in Km"
              />
            </>
          )}
        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-four")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
