import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import "./StudentContact.styles.scss";
import image from "../../../assets/StudentApp/images/image 45.png";
import phone from "../../../assets/StudentApp/svgs/Duotone.svg";
import locationMarker from "../../../assets/StudentApp/svgs/loc.svg";
import PackageFooter from "../../../SharedApp/Components/PackageFooter/PackageFooter";
import { sendEmailNotification } from "../../../ReduxToolkit/Slices/userSlice";

export default function StudentContact() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [message, setMessage] = useState({
    to: "",
    subject: "",
    text: "",
    otp: "",
    html: "",
  });

  const [fetching, setFetching] = useState(false);
  let text = `name: ${user.name} ${user.lastName}, email: ${user.email}`;

  const sendError = (message) => toast.error(message);
  const notify = (message) => toast.success(message);

  const handleMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage({
      ...message,
      [name]: value,
    });
  };

  const sendEmail = () => {
    if (!message.text || !message.subject) {
      return sendError("Please enter all inputs");
    }
    setFetching(true);
    dispatch(
      sendEmailNotification({
        ...message,
        text: `${text} message:${message.text}`,
      })
    ).then((res) => {
      setFetching(false);
      if (res.type === "sendEmail/fulfilled") {
        notify(`Email sent, thank you!`);
        setMessage({
          to: "support@educify.org",
          subject: "",
          text: "",
          otp: "",
          html: "",
        });
      } else {
        sendError("Error sending email");
        setMessage({
          to: "support@educify.org",
          subject: "",
          text: "",
          otp: "",
          html: "",
        });
      }
    });
  };
  return (
    <div className="student__contact">
      <div className="question__form">
        <div className="form">
          <h1>Send Us Email</h1>
          <div className="input">
            <span>Subject</span>
            <input
              type="text"
              name="subject"
              value={message.subject}
              onChange={(e) => handleMessageChange(e)}
            />
          </div>
          <div className="input">
            <span>Message</span>
            <textarea
              type="text"
              name="text"
              value={message.text}
              onChange={(e) => handleMessageChange(e)}
            />
          </div>
          <button
            onClick={() => sendEmail()}
            className={fetching ? "fetching" : ""}
          >
            Send
          </button>
        </div>
        <div className="office__location">
          <img src={image} alt="" />
        </div>
      </div>
      <div className="contact__details">
        <div className="detail">
          <img src={phone} alt="" />
          <h2>Let`s Speak</h2>
          <span>{"+ 888 252 9485"}</span>
        </div>
        <div className="detail">
          <img src={locationMarker} alt="" />
          <h2>Our Head Office</h2>
          <span>{"Churchill-laan 16 II, 1052 CD, Amsterdam"}</span>
        </div>
      </div>
      <div className="contact__footer">
        <PackageFooter />
      </div>
    </div>
  );
}
