import React from "react";
import Welcome from "./Welcome";
import InfoSection from "./InfoSection";
import WallPaper from "./WallPaper";
import WhyEducify from "./WhyEducify";
import HowItWorks from "./HowItWorks";
import Career from "./Career";
import WhoIsEducify from "./WhoIsEducify";
import HowToRegister from "./HowToRegister";
import TeacherSignupFaq from "./TeacherSignupFaq";
import Navbar from "../../../Sections/Navbar/Navbar";
import Footer from "../../../Sections/Footer/Footer";

import "./teacherLanding.styles.scss";

const TeacherLanding = () => {
  return (
    <>
      <Navbar />
      <div className="teacher_landing">
        <Welcome />
        <WallPaper />
        <InfoSection />
        <WhoIsEducify />
        <WhyEducify />
        <HowToRegister />
        <HowItWorks />
        <Career />
        <TeacherSignupFaq />
      </div>
      <Footer />
    </>
  );
};

export default TeacherLanding;
