import React, { useRef, useState } from "react";
import "./Complete.styles.scss";
import like from "../../../assets/TeacherApp/assets/images/like.webp";
import logo from "../../../assets/TeacherApp/assets/images/logo-dark.webp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import fb from "../../../assets/TeacherApp/assets/images/fb.webp";
import insta from "../../../assets/TeacherApp/assets/images/isnta.webp";
import twitter from "../../../assets/TeacherApp/assets/images/twitter.webp";
import google from "../../../assets/TeacherApp/assets/images/google.webp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logout } from "../../../ReduxToolkit/Slices/userSlice";
import SharedVideoUploader from "../../../Components/SharedVideoUploader/SharedVideoUploader";
import AddVideo from "../../Components/Courses/AddVideo/AddVideo";
import { teacherPostTestLesson } from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";
import VerifyTeacher from "./VerifyTeacher";
import { socials } from "../../../assets/HomeApp/data/footer";

export default function Complete({ custom, verifyAccount }) {
  const imgs = [
    { img: fb, link: "https://www.facebook.com/educifyinc/" },
    { img: insta, link: "https://www.instagram.com/educifyinc" },
    { img: google, link: "" },
    { img: twitter, link: "" },
  ];
  const dispatch = useDispatch();

  const { teacher } = useSelector((state) => state.teacher);
  const navigate = useNavigate();
  const procceed = () => {
    navigate("/otp");
  };
  const [showUploadVideo, setShowUploadVideo] = useState(false);

  // const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  // const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  // const MAX_VIDEO_DURATION = 360;
  const [video, setVideo] = useState([{ url: "", publicId: "" }]);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth", // Optional: Adds smooth scrolling animation
        // block: "start", // Optional: Scroll to the top of the element
      });
  }, [showUploadVideo]);

  const onSubmitLesson = () => {
    if (!video.url) {
      toast.error("Please record a video first!");
    }
    dispatch(teacherPostTestLesson(video)).then((res) => {
      if (res.type === "teacherPostTestLesson/fulfilled") {
        toast.success("Test lesson was submitted, thank you!");
        setShowUploadVideo(false);
      }
    });
  };
  return (
    <div className="complete__page">
      <div className="complete__container">
        <div className="logo">
          <Link to={"/"}>
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="done__img">
          <img src={like} alt="" />
        </div>
        <div className="complete__info">
          <h1>Hey {teacher?.user?.name}, thanks for signing up</h1>
          <p>
            Lots of Teachers didn’t make it to this level. In Educify we try ro
            provide the Teachers with the best quality experience
          </p>
          <div className="flex_center custom_verify">
            {!teacher?.user?.bySocial ? (
              <button to="/teachers" onClick={() => procceed()}>
                Verify Your Email <ArrowForwardIcon />
              </button>
            ) : (
              <button to="/teachers" onClick={() => navigate("/login")}>
                Login <ArrowForwardIcon />
              </button>
            )}

            <button
              className="verification"
              to="/teachers"
              onClick={() => setShowUploadVideo(true)}
            >
              Get a Verification Badge <ArrowForwardIcon />
            </button>
          </div>
        </div>
        <div ref={ref} className="scroll_to"></div>
        {showUploadVideo ? (
          <VerifyTeacher
            video={video}
            onSubmitLesson={onSubmitLesson}
            setVideo={setVideo}
          />
        ) : (
          ""
        )}

        <div className="next">
          {!custom && (
            <>
              <h1>What's next</h1>
              <div className="whats__next__container">
                <div className="row">
                  <div className="number">
                    <span>1</span>
                  </div>
                  <div className="step">
                    <h3>Verification</h3>
                    <p>
                      Our Team of experts will go through your submissions for
                      verification to be made before you can proceed with the
                      next step
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="number">
                    <span>2</span>
                  </div>
                  <div className="step">
                    <h3>Timeframe</h3>
                    <p>
                      Your profile will be verified within 1 week after
                      submission. If not <a href="#">Contact Us</a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="bot__section">
            <h4>It’s all about our users!</h4>
            <p>
              Call our customer care number: +1 888 252 9485 You may reach us at
              admin@educify.org
            </p>
            <div className="images">
              {socials.map((social, i) => (
                <a target="_blank" href={social.link} key={i}>
                  <img src={social.image} alt="socials" />
                </a>
              ))}
            </div>
            <p>©Copyright {new Date().getFullYear()} All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
