import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "usd",
  exchangeRates: [],
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setExchangeRates: (state, action) => {
      state.exchangeRates = action.payload;
    },
  },
});

export const { setCurrency, setExchangeRates } = currencySlice.actions;

export default currencySlice.reducer;
