import React from "react";
import "./content.styles.scss";
export default function TOS() {
  const data = [
    {
      title: "What is Educify?",
      p1: "Educify.org is the best way to connect proven and qualified teachers with respective students all over Canada, the United states and globally. Our tutors are the best in the chosen fields and have been background-checked to ensure the safety of our children and adult students. Started in 2020, Educify has come a long way into ensuring provision of accessible and affordable training and educational platforms. ",
      p2: "To make it easy for students to access our platform, we are present on both the web and mobile devices. You can find us on your phone, tablet, in the browser or the app stores. Getting that educational dream fulfilled is just a tap away. We do hope you enjoy our service and our contribution to educating and training you and your wards.",
      p3: "With some of us having musical training and educational background ourselves, we know firsthand what it takes to be successful working with us. We have gathered extensive teaching and training experience over the last decade and have interacted and worked with students having various needs in many areas of human endeavour. If you have any questions, feel free to email us at",
      mail: "support@educify.org",
    },
  ];

  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>About Educify</h1>
      {data.map((section) => (
        <div className="privacy_row">
          <h2>{section.title}</h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          {section.mail ? (
            <a href={`mailto:${section.mail}`}>{section.mail}</a>
          ) : (
            ""
          )}
          <p>{section?.p4}</p>
          <p>{section?.p5}</p>
          <p>{section?.p5}</p>
        </div>
      ))}
    </div>
  );
}
