import React, { useState } from "react";
import "./NewCategory.styles.scss";
import edit from "../../../assets/SharedApp/svgs/edit.svg";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import { useDispatch, useSelector } from "react-redux";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import {
  setUserProfilePicture,
  uploadImage,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { createCategory } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { createCourseCategory } from "../../../ReduxToolkit/Slices/coursesSlice";
import SingleImageUploader from "../../../TeacherApp/Components/SingleImageUploader/SingleImageUploader";

export default function NewCategory({ Edit }) {
  const [imageLoading, setImageLoading] = useState(false);
  const [preview, setPreview] = useState();
  const [newImg, setNewImg] = useState({});
  const [active, setActive] = useState(false);
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let courses = location.pathname.includes("courses");

  const onSelectFile = (e) => {
    setImageLoading(true);
    handleImageUpload(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  };
  const handleImageUpload = (file) => {
    let form = new FormData();

    form.append("file", file);
    Promise.resolve(dispatch(uploadImage(form)))
      .then((value) => {
        setNewImg(value.payload);
        setImageLoading(false);
      })
      .catch((err) => console.log("up err", err));
  };

  const handleCreateCategory = () => {
    if (!name || !newImg.url) {
      toast.error("You Need to fill all the fields");
    } else {
      let newCategory = {
        name: name,
        images: newImg.url ? [newImg] : [],
        status: active ? "ACTIVE" : "INACTIVE",
      };

      courses
        ? dispatch(
            createCourseCategory({
              name,
              image: newImg,
            })
          ).then((res) => {
            if (res.type.includes("fulfilled")) {
              toast.success("Category was created Successfully");
              navigate(-1);
            } else {
              toast.error("Something went wrong.");
            }
          })
        : dispatch(createCategory(newCategory)).then((res) => {
            if (res.type.includes("fulfilled")) {
              toast.success("Category was created Successfully");
              navigate(-1);
            } else {
              toast.error("Something went wrong.");
            }
          });
    }
  };
  return (
    <div className="create__category">
      <div className="header">
        <h1>Add Category</h1>
        <p>Setup lesson category</p>
      </div>
      <div className="create__category__row">
        <span>Category Image</span>
        {/* <div className="image__selector">
          <div className="upload">
            <div className="image">
              <div className="image-upload">
                <label htmlFor="file-input">
                  {imageLoading ? (
                    <div className="spinner" />
                  ) : !preview ? (
                    <img src={volicity} alt="" className="img__btn" />
                  ) : (
                    <img src={preview} alt="" className="coverImage" />
                  )}
                </label>
                <input id="file-input" type="file" onChange={onSelectFile} />
              </div>
              <img src={remove} alt="" className="remove action" />
              <img src={edit} alt="" className="edit action" />
            </div>
            <p>Allowed file types: png, jpg, jpeg.</p>
          </div>
        </div> */}
        <SingleImageUploader
          imageLoading={imageLoading}
          preview={preview}
          onSelectFile={onSelectFile}
        />
      </div>
      <div className="create__category__row">
        <span>Category Title</span>
        <input
          type="text"
          placeholder="Category Name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {!courses && (
        <div className="create__category__row">
          <span>Change Status</span>
          <div className="save__card">
            <input
              type="checkbox"
              id="switch"
              class="checkbox"
              defaultChecked={active}
              onChange={() => setActive(!active)}
            />
            <label for="switch" class="toggle"></label>
          </div>
        </div>
      )}
      <div className="create__cat__btns">
        <div className="cat__buttons">
          <button className="discard">Discard</button>
          <button className="save__btn" onClick={() => handleCreateCategory()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
