import React, { useState } from "react";
import { useSelector } from "react-redux";

import EditorPageRow from "../EditorPageRow/EditorPageRow";
import { Item } from "../EditorPageRow/Item";

export default function EditorPage({ index }) {
  const { chapters } = useSelector((state) => state.courses);

  return (
    <div className="course__editor__section">
      {chapters[index]?.lessons.length > 0 &&
        chapters[index].lessons.map((chapter, i) => (
          <EditorPageRow
            item={chapter}
            key={i}
            id={i}
            index={index}
            lessonId={chapter.id}
          />
        ))}
    </div>
  );
}
