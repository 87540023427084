import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import "./MyWallets.styles.scss";
import wallet from "../../assets/wallet.png";
import arrow from "../../assets/rightarrow.svg";
import menu from "../../assets/menuT.svg";
import StudentPaymentMethod from "../../Components/StudentPaymentsMethod/StudentPaymentMethods";
import { getUserTransactions } from "../../../ReduxToolkit/Slices/userSlice";
import { addToWallet } from "../../../ReduxToolkit/Slices/paymentSlice";
import { Steps } from "intro.js-react";
import { useLocation } from "react-router-dom";
import Note from "../../../TeacherApp/Components/Note/Note";

const Card = ({ amount, description, createdAt, status }) => {
  return (
    <div className="Card_Container">
      <img alt="" src={arrow} />
      <div className="cards">
        <div>
          <p className="redeem">{description}</p>
          {/* <p className="date">{moment(createdAt).format("DD/MM/YYYY")}</p> */}
          <p className="date">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</p>

          <span className={`${status}`}>{status}</span>
        </div>
        <span className="amount">{amount}</span>
      </div>
    </div>
  );
};

function MyWallets({ success }) {
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [walletAmount, setWallet] = useState(null);
  const [add, setAdd] = useState(false);
  const [method, setMethod] = useState({});
  const [busy, setBusy] = useState(false);
  const [amount, setAmount] = useState(0);

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    !add &&
      dispatch(getUserTransactions({ limit: 1, sortOder: "asc" })).then(
        (res) => {
          res.payload.wallet && setWallet(res.payload?.wallet.balance);
          setTransactions(res.payload.transactions);
        }
      );
  }, [add]);

  const [alert, setAlert] = useState(false);
  useEffect(() => {
    if (success && !alert) {
      setAlert(true);
      return toast.success("Added to wallet successfully");
    }
  }, []);

  const addWallet = () => {
    if (amount < 1) {
      return toast.error("Amount to be added missing");
    }
    setBusy(true);
    dispatch(
      addToWallet({
        currency: "USD",
        description: `Adding ${amount} to wallet`,
        type: "DEPOSIT",
        paymentMethod: method.paymentMethod,
        senderId: user.id,
        receiverId: "ADMIN",
        amount: Number(amount) * 100,
      })
    )
      .then((res) => {
        if (res.type === "addToWallet/fulfilled") {
          if (method.paymentMethod === "PAYPAL") {
            return window.open(res.payload);
          }
          toast.success("Added to wallet");
        } else {
          toast.error("Error adding to wallet");
        }
        setBusy(false);
        setAdd(!add);
      })
      .catch(() => {
        toast.error("Error adding to wallet");
        setBusy(false);
        setAdd(!add);
      });
  };
  const [isCreditFilled, setIsCreditFilled] = useState("");
  const [isPaypalFilled, setIsPaypalFilled] = useState({});
  const steps = [
    {
      element: ".MyWallets_Container",
      title: "Wallet",
      intro:
        "Here you can see the total credit you have in your account, credits are used to purchase lessons and courses.",
    },
    {
      element: "p.add",
      title: "Add Credits",
      intro:
        "To add more credits to your account you can click this button, you can choose eaither Credit card or Paypal",
    },
    {
      element: ".c2",
      title: "Transactions",
      intro: "Here, all transactions that you made will be shown here.",
    },
  ];
  const [showGuide, setShowGuide] = useState(true);

  const { pathname } = useLocation();
  return add ? (
    <div className="Add_To_Wallet">
      <StudentPaymentMethod
        paying
        setPaying={setMethod}
        wallets
        isCreditFilled={isCreditFilled}
        setIsCreditFilled={setIsCreditFilled}
        isPaypalFilled={isPaypalFilled}
        setIsPaypalFilled={setIsPaypalFilled}
      />
      <div className="inputs">
        <label>Amount (USD):</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div className="action_buttons">
        <button className="next__btn" onClick={() => setAdd(!add)}>
          Discard
        </button>
        <button
          className={busy ? "btn__primary fetching" : "btn__primary"}
          onClick={() => addWallet()}
        >
          Add
        </button>
      </div>
    </div>
  ) : (
    <>
      <Note
        a1={
          "If you need to request a request a refund, please go to the help center."
        }
        link2={"https://educifyinc.freshdesk.com"}
        title="Attention"
      />
      <div className="MyWallets_Container">
        {showGuide && pathname.includes("mywallet") && (
          <Steps
            enabled={user.helpGuide}
            steps={steps}
            initialStep={0}
            onExit={() => setShowGuide(false)}
            options={{ exitOnOverlayClick: false }}
          />
        )}

        <div className="c1">
          <div className="wallet_card_container">
            <div className="card">
              <p>Total Amount in Wallet</p>
              <span>
                <span className="dollar">$</span>
                {walletAmount ? walletAmount / 100 : "0.00"}
              </span>
              <p className="add" onClick={() => setAdd(!add)}>
                Add to wallet
              </p>
            </div>
          </div>
          <img alt="" src={wallet} />
        </div>
        <div className="c2">
          <div className="header">
            <h3>Transactions</h3>
            {/* <img alt="" src={menu} /> */}
          </div>
          {transactions?.length > 0 ? (
            transactions.map((tran, i) => (
              <Card
                key={i}
                amount={tran.amount / 100}
                createdAt={tran.createdAt}
                description={tran.description}
                status={tran.status}
              />
            ))
          ) : (
            <p>No transactions yet!</p>
          )}
        </div>
      </div>
    </>
  );
}

export default MyWallets;
