import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./MyLessons.styles.scss";

import equalizer from "../../assets/equalizer.svg";
import icon1 from "../../assets/icon1.svg";
import addUser from "../../assets/addUser.svg";
import compiling from "../../assets/compiling.svg";
import { getStudentDashboard } from "../../../ReduxToolkit/Slices/studentSlice";

const Cart = ({ title, text, icon }) => {
  return (
    <div className="cart">
      <img className="equalizer" src={icon} />
      <span className="hours-learned">{title}</span>
      <span className="num-822">{Math.abs(text)}</span>
    </div>
  );
};

const MyLessons = () => {
  const dispatch = useDispatch();
  const { student } = useSelector((state) => state.student);
  const [dashboard, setDashboard] = useState({
    acceptedLessons: 0,
    cancelledLessons: 0,
    completedLessons: 0,
    pendingLessons: 0,
    rejectedLessons: 0,
    totalHours: 0,
    totalLessons: 0,
  });

  useEffect(() => {
    student &&
      dispatch(getStudentDashboard(student)).then((res) => {
        setDashboard(res.payload);
      });
  }, [student]);

  const Info = [
    {
      title: "Hours Learned",
      text: dashboard?.totalHours,
      icon: equalizer,
    },
    {
      title: "Enrolled Lessons",
      text: dashboard?.totalLessons,
      icon: addUser,
    },

    {
      title: "Yet to Start",
      text: dashboard?.pendingLessons,
      icon: compiling,
    },

    {
      title: "Completed",
      text: dashboard?.completedLessons,
      icon: icon1,
    },
  ];
  return (
    <div className="MyLessons_Container">
      <span className="my-lessons">My Lessons</span>
      <span className="youve-4-lessons-to-c">
        You have {dashboard?.acceptedLessons} lessons to complete
      </span>
      <div className="Grid-container">
        {Info &&
          Info.map((item, i) => (
            <Cart
              key={i}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
      </div>
      <div className="background_white" />
    </div>
  );
};
export default MyLessons;
