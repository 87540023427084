import React, { useLayoutEffect } from "react";
import "./MobileNavbar.styles.scss";
import bell from "../../../assets/TeacherApp/assets/svgs/bell.svg";
import logo from "../../../assets/TeacherApp/assets/svgs/logo.svg";
import burger from "../../../assets/TeacherApp/assets/svgs/burger.svg";
import group1742 from "../../../assets/SVG/group1742.svg";
import teacherImg from "../../../assets/TeacherApp/assets/images/teacher.webp";
import close from "../../../assets/TeacherApp/assets/images/close.webp";
import search from "../../../assets/TeacherApp/assets/svgs/search.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import rectangle367 from "../../../assets/SVG/rectangle367.svg";
import stockholmIconsCom from "../../../assets/SVG/stockholmIconsCom.svg";
import icon from "../../../assets/SVG/icon.svg";
import sending from "../../../assets/SVG/sending.svg";
import mirror from "../../../assets/SVG/mirror.svg";
import creditCard from "../../../assets/SVG/creditCard.svg";
import settings from "../../../assets/SVG/settings.svg";
import groupChat from "../../../assets/SVG/groupChat.svg";

import categories from "../../../assets/AdminApp/svgs/panel/categories.svg";
import content from "../../../assets/AdminApp/svgs/panel/content.svg";
import faq from "../../../assets/AdminApp/svgs/panel/faq.svg";
import subjects from "../../../assets/AdminApp/svgs/panel/subjects.svg";
import User from "../../../assets/AdminApp/svgs/panel/User.svg";
import users from "../../../assets/AdminApp/svgs/panel/users.svg";
import notification from "../../../assets/AdminApp/svgs/panel/notification.svg";
import setting from "../../../assets/AdminApp/svgs/panel/settings.svg";
import DashboardPanel from "../DashboardPanel/DashboardPanel";
import { useSelector } from "react-redux";

export default function MobileNavbar() {
  const [showPanel, setShowPanel] = useState(false);

  return (
    <div className="mobile__nav">
      <NavPanel showPanel={showPanel} setShowPanel={setShowPanel} />
      <div className="nav__col">
        <Link to={"/"}>
          <img src={logo} alt="" />
        </Link>
        <img src={burger} alt="" onClick={() => setShowPanel(!showPanel)} />
      </div>
    </div>
  );
}

const NavPanel = ({ showPanel, setShowPanel }) => {
  return (
    <div className={`nav__bg ${showPanel ? "active" : ""}`}>
      <img
        src={close}
        alt=""
        className={`close__nav ${showPanel ? "active" : ""}`}
        onClick={() => setShowPanel(false)}
      />
      <div className={`nav__sidepanel ${showPanel ? "active" : ""}`}>
        <DashboardPanel mobile />
      </div>
    </div>
  );
};

// const DashboardPanel = ({ hasLogo, mobile }) => {
//   let params = useLocation();
//   let newparms = params.length ? params.pathname.split("/")[2] : "";

//   const itemsInitialState = [
//     {
//       title: "My Classes",
//       icon: mirror,
//       selected: true,
//       link: "myclasses",
//     },
//     {
//       title: "My Requests",
//       icon: stockholmIconsCom,
//       link: "myrequests",
//     },
//     {
//       title: "My Earnings",
//       link: "myearnings",
//       icon: creditCard,
//     },
//     {
//       title: "My Messages",
//       link: "mymessages",
//       icon: groupChat,
//     },
//     {
//       title: "Payment Method",
//       link: "payment",
//       icon: settings,
//     },
//     {
//       link: "subjects/1",
//       title: "Manage Subjects",
//       icon: sending,
//     },

//     {
//       title: "FAQ",
//       link: "faq",
//       icon: icon,
//     },
//   ];

//   const studentsInitial = [
//     {
//       title: "My Classes",
//       icon: mirror,
//       selected: true,
//       link: "myclasses",
//     },
//     {
//       title: "Find Classes",
//       icon: mirror,
//       selected: true,
//       link: "findClasses",
//     },
//     {
//       title: "My Wallet",
//       icon: creditCard,
//       link: "mywallet",
//     },
//     {
//       title: "My Messages",
//       link: "mymessages",
//       icon: groupChat,
//     },
//     {
//       title: "Payment Method",
//       link: "payment",
//       icon: settings,
//     },
//     {
//       link: "invite",
//       title: "Invite Friends",
//       icon: sending,
//     },

//     {
//       title: "FAQ",
//       link: "faq",
//       icon: icon,
//     },
//     {
//       title: "Help & Support",
//       link: "contact",
//       icon: settings,
//     },
//   ];

//   const AdminInitial = [
//     {
//       title: "Dashboard",
//       icon: mirror,
//       selected: true,
//       link: "",
//     },
//     {
//       title: "Teachers",
//       icon: User,
//       link: "teachers",
//     },
//     {
//       title: "Students",
//       link: "students",
//       icon: users,
//     },
//     {
//       title: "Bookings",
//       link: "booking",
//       icon: settings,
//     },
//     {
//       title: "Payments",
//       link: "payments",
//       icon: creditCard,
//     },

//     {
//       title: "Categories",
//       link: "categories",
//       icon: categories,
//     },
//     {
//       title: "Subjects",
//       link: "subjects",
//       icon: subjects,
//     },
//     {
//       title: "Promo Code",
//       link: "promo",
//       icon: settings,
//     },
//     {
//       title: "Content Pages",
//       link: "content",
//       icon: content,
//     },
//     {
//       title: "FAQ",
//       link: "faq",
//       icon: faq,
//     },
//     {
//       title: "Push Notification",
//       link: "notifications",
//       icon: notification,
//     },
//     {
//       title: "Settings",
//       link: "settings",
//       icon: setting,
//       options: [
//         {
//           name: "Admin List",
//           link: "/settings",
//         },
//         {
//           name: "Basic Settings",
//           link: "/settings/basic",
//         },
//         {
//           name: "Installation Settings",
//           link: "/settings/installation",
//         },
//         {
//           name: "Mail Templates",
//           link: "/settings/mail",
//         },
//         {
//           name: "SMS Templates",
//           link: "/settings/sms",
//         },
//       ],
//     },
//   ];
//   const [items, setItems] = useState(studentsInitial);
//   const [loaded, setLoaded] = useState(false);
//   let location = params.pathname.slice(1, params.pathname.length).split("/")[0];
//   useLayoutEffect(() => {
//     if (location === "admin") {
//       setItems(AdminInitial);
//       setLoaded(true);
//     } else if (location === "students") {
//       setItems(studentsInitial);
//       setLoaded(true);
//     } else if (location === "teachers") {
//       setItems(itemsInitialState);
//       setLoaded(true);
//     }
//   }, [params]);

//   const title = items.filter((element) => {
//     if (newparms.length > 0) {
//       return element.link === newparms;
//     } else return [];
//   });
//   let lastParam = params.pathname.split("/");
//   lastParam = lastParam[lastParam.length - 1];
//   let goodParms = params.pathname.split("/")[2];
//   return (
//     <div className="SharedLayout_Subcontainer SharedLayout_Subcontainer_show">
//       {hasLogo && (
//         <Link to="/" className="group-1742">
//           <img src={group1742} />
//         </Link>
//       )}
//       {mobile && (
//         <div className="nav__dash__menu">
//           <div className="nav__col">
//             <img src={search} alt="" />
//           </div>
//           <div className="nav__col">
//             <img src={bell} alt="" />
//           </div>
//           <div className="nav__acount">
//             <div className="name">
//               <p>Hello</p>
//               <span>Richard</span>
//             </div>
//             <img src={teacherImg} alt="" />
//           </div>
//         </div>
//       )}
//       {items &&
//         items.map((item, i) => (
//           <>
//             <Link
//               to={`/${location + "/" + item.link}`}
//               className={goodParms === item.link ? "item-1" : "flex-container"}
//               key={i}
//             >
//               {goodParms === item.link && (
//                 <img className="frame" src={rectangle367} />
//               )}
//               <img className="icons" src={item.icon} />
//               <a className="titles">{item.title}</a>
//             </Link>
//             {goodParms === "settings" && (
//               <div className="settings__list">
//                 {item.options &&
//                   item.options.map((el) => (
//                     <li
//                       className={`${
//                         el.link.split("/")[2] === lastParam
//                           ? "selected__list"
//                           : ""
//                       }`}
//                     >
//                       <Link to={`/admin${el.link}`}>-{el.name}</Link>
//                     </li>
//                   ))}
//               </div>
//             )}
//           </>
//         ))}
//     </div>
//   );
// };
