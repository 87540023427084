import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { customFetchToken } from "../customFetch/customFetch";
const refreshToken = localStorage.getItem("refresh_token");

const initialState = {
  lessonId: "00000",
  teacher: {},
  booking: {},
  packages: [],
  publicSetting: {},
};

export const getAdminDashboard = createAsyncThunk(
  "getAdminDashboard",
  async (accessToken, thunkApi) => {
    let url = `/admin/dashboard/`;
    try {
      const resp = await customFetchToken.get(url);
      const state = thunkApi.getState();
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (params, thunkApi) => {
    let url = `/admin/users`;
    try {
      const resp = await customFetchToken.get(url, { params: params });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
// export const deleteTeacher = createAsyncThunk(
//   "deleteTeacher",
//   async (id, thunkApi) => {
//     let url = `/teachers/${id}}/`;
//     try {
//       const resp = await customFetchToken.delete(url);
//       return resp.data;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error.response.data.message);
//     }
//   }
// );
export const getPackages = createAsyncThunk(
  "getPackages",
  async (filters, thunkApi) => {
    let url = `/admin/packages`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getTokenForTwillio = createAsyncThunk(
  "getTokenForTwillio",
  async (_, thunkApi) => {
    let url = `/admin/make-call`;
    try {
      const resp = await customFetchToken.post(url);
      console.log("resp token here", resp);
      return resp.data;
    } catch (error) {
      console.log("error token here", error);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deletePackages = createAsyncThunk(
  "deletePackages",
  async (id, thunkApi) => {
    let url = `/admin/packages/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getBlockedUsers = createAsyncThunk(
  "getBlockedUsers",
  async (filters, thunkApi) => {
    let url = `/users/blocked?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getDeletedUsers = createAsyncThunk(
  "getDeletedUsers",
  async (filters, thunkApi) => {
    let url = `/users/deleted?${filters}`;

    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudents = createAsyncThunk(
  "getStudents",
  async (filters, thunkApi) => {
    let url = `/admin/students?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteStudent = createAsyncThunk(
  "deleteStudent",
  async (id, thunkApi) => {
    let url = `/students/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const assignAdmin = createAsyncThunk(
  "assignAdmin",
  async (id, thunkApi) => {
    let url = `/admin/assignAdmin/${id}`;
    try {
      const resp = await customFetchToken.post(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const reassignStudent = createAsyncThunk(
  "reassignStudent",
  async (item, thunkApi) => {
    let url = `/admin/bookingS`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const resetTeacherPass = createAsyncThunk(
  "resetTeacherPass",
  async (item, thunkApi) => {
    let url = `/admin/teachers/${item.id}/resetPassword`;
    try {
      const resp = await customFetchToken.put(url, item.password);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteTeacher = createAsyncThunk(
  "deleteStudent",
  async (id, thunkApi) => {
    let url = `/teachers/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getAdminSettings = createAsyncThunk(
  "getAdminSettings",
  async (id, thunkApi) => {
    let url = `/admin/settings`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const setAdminSettings = createAsyncThunk(
  "getAdminSettings",
  async (settings, thunkApi) => {
    let url = `/admin/settings`;
    try {
      const resp = await customFetchToken.post(url, settings);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getReferalSettings = createAsyncThunk(
  "getReferalSettings",
  async (id, thunkApi) => {
    let url = `/admin/settings/referral`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getRegisterSettings = createAsyncThunk(
  "getReferalSettings",
  async (id, thunkApi) => {
    let url = `/admin/settings/register`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const payTeacher = createAsyncThunk(
  "payTeacher",
  async (item, thunkApi) => {
    let url = `/admin/payTeacher`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const payStudent = createAsyncThunk(
  "payStudent",
  async (item, thunkApi) => {
    let url = `/admin/payStudent`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getAdminList = createAsyncThunk(
  "getAdminList",
  async (_, thunkApi) => {
    let url = `/admin/adminList`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const unblockUser = createAsyncThunk(
  "unblockUser",
  async (id, thunkApi) => {
    let url = `/admin/users/unblockUser/${id}`;
    try {
      const resp = await customFetchToken.patch(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id, thunkApi) => {
    let url = `/users/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const verifyTeacher = createAsyncThunk(
  "verifyTeacher",
  async (id, thunkApi) => {
    let url = `/admin/teachers/${id}/approveBackground`;
    try {
      const resp = await customFetchToken.put(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getInvoices = createAsyncThunk(
  "getInvoices",
  async (filters, thunkApi) => {
    let url = `/admin/invoices?${filters}&limit=1000`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getAdminNotifications = createAsyncThunk(
  "getAdminNotifications",
  async (id, thunkApi) => {
    let url = `/notifications/requestpayment`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getAdminNotificationsNumber = createAsyncThunk(
  "getAdminNotificationsNumber",
  async (id, thunkApi) => {
    let url = `/notifications/number/admin`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherInvoices = createAsyncThunk(
  "getTeacherInvoices",
  async (teacherId, thunkApi) => {
    let url = `/admin/invoices?teacherId=${teacherId}&limit=200`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getTransactions = createAsyncThunk(
  "getTransactions",
  async (params, thunkApi) => {
    let url = `/admin/transactions`;
    try {
      const resp = await customFetchToken.get(url, { params });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getInoicesAnalytics = createAsyncThunk(
  "getInoicesAnalytics",
  async (year, thunkApi) => {
    let url = `/admin/invoices/monthly/${year}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getInoicesAnalyticsYearly = createAsyncThunk(
  "getInoicesAnalyticsYearly",
  async (year, thunkApi) => {
    let url = `/admin/invoices/yearly`;
    try {
      const resp = await customFetchToken.get(url, { year });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getInoicesAnalyticsYearlyPercentage = createAsyncThunk(
  "getInoicesAnalyticsYearlyPercentage",
  async (year, thunkApi) => {
    let url = `/admin/invoices/yearlyPercentage`;
    try {
      const resp = await customFetchToken.get(url, { year });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getTransactionsAnalytics = createAsyncThunk(
  "getTransactionsAnalytics",
  async (year, thunkApi) => {
    let url = `/admin/transactions/monthly/${year}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const setReferalSettings = createAsyncThunk(
  "setReferalSettings",
  async (item, thunkApi) => {
    let url = `/admin/settings/referral`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const setRegisterSettings = createAsyncThunk(
  "setReferalSettings",
  async (item, thunkApi) => {
    let url = `/admin/settings/register`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteFaq = createAsyncThunk(
  "deleteStudent",
  async (id, thunkApi) => {
    let url = `/admin/faqs/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getPromoCodes = createAsyncThunk(
  "getPromoCodes",
  async (filters, thunkApi) => {
    let url = `/admin/promoCodes?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const CreatePromoCodes = createAsyncThunk(
  "CreatePromoCodes",
  async (promo, thunkApi) => {
    let url = `/admin/promoCodes`;
    try {
      const resp = await customFetchToken.post(url, promo);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deletePromoCode = createAsyncThunk(
  "deletePromoCode",
  async (id, thunkApi) => {
    let url = `/admin/promoCodes/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getPromoCodeById = createAsyncThunk(
  "getPromoCodeById",
  async (id, thunkApi) => {
    let url = `/admin/promoCodes/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updatePromoCode = createAsyncThunk(
  "updatePromoCode",
  async (promo, thunkApi) => {
    let url = `/admin/promoCodes/${promo.id}`;
    try {
      const resp = await customFetchToken.patch(url, promo);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);
export const getTeachers = createAsyncThunk(
  "getTeachers",
  async (filters, thunkApi) => {
    let url = `/admin/teachers?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getFilteredTeachers = createAsyncThunk(
  "getFilteredTeachers",
  async (filters, thunkApi) => {
    let url = `/admin/teachers`;
    try {
      const resp = await customFetchToken.get(url, { params: filters });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getTeachersById = createAsyncThunk(
  "getTeachersById",
  async (id, thunkApi) => {
    let url = `/admin/teachers/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateLessonPrices = createAsyncThunk(
  "updateLessonPrices",
  async (item, thunkApi) => {
    let url = `/admin/teachers/${item.id}`;
    try {
      const resp = await customFetchToken.put(url, item.prices);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getTeacherDashboard = createAsyncThunk(
  "getTeacherDashboard",
  async (id, thunkApi) => {
    let url = `/admin/teachers/${id}/dashboard`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const checkMarkTeacher = createAsyncThunk(
  "checkMarkTeacher",
  async (id, thunkApi) => {
    let url = `/admin/teachers/${id}/checkMark`;
    try {
      const resp = await customFetchToken.put(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const approveTeachers = createAsyncThunk(
  "approveTeachers",
  async (id, thunkApi) => {
    let url = `/admin/teachers/${id}/approve`;
    try {
      const resp = await customFetchToken.put(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const rejectTeachers = createAsyncThunk(
  "rejectTeachers",
  async (id, thunkApi) => {
    let url = `/admin/teachers/${id}/reject`;
    try {
      const resp = await customFetchToken.put(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateTeacher = createAsyncThunk(
  "updateTeacher",
  async (item, thunkApi) => {
    let url = `/teachers/${item.id}/`;
    try {
      const resp = await customFetchToken.patch(url, item.teacher);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const getAllLessons = createAsyncThunk(
  "getAllLessons",
  async (filters, thunkApi) => {
    let url = `/admin/lessons?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getBookings = createAsyncThunk(
  "getAllLessons",
  async (page, thunkApi) => {
    let url = `/admin/bookings?page=${page}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getFilteredBookings = createAsyncThunk(
  "getFilteredBookings",
  async (filters, thunkApi) => {
    let url = `/admin/bookings?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getBookingsById = createAsyncThunk(
  "getBookingsById",
  async (id, thunkApi) => {
    let url = `/admin/bookings/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getFAQs = createAsyncThunk(
  "getFAQs",
  async (filters, thunkApi) => {
    let url = `/admin/faqs?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getFAQById = createAsyncThunk(
  "getFAQById",
  async (id, thunkApi) => {
    let url = `/admin/faqs/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateFAQ = createAsyncThunk(
  "updateFAQ",
  async (item, thunkApi) => {
    let url = `/admin/faqs/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.data);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const addFAQ = createAsyncThunk("addFAQ", async (item, thunkApi) => {
  let url = `/admin/faqs`;
  try {
    const resp = await customFetchToken.post(url, item);
    return resp.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.message);
  }
});
export const deleteFAQs = createAsyncThunk(
  "deleteFAQs",
  async (id, thunkApi) => {
    let url = `/admin/faqs/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const createPackage = createAsyncThunk(
  "createPackage",
  async (Package, thunkApi) => {
    let url = `/admin/packages`;
    try {
      const resp = await customFetchToken.post(url, Package);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updatePackage = createAsyncThunk(
  "updatePackage",
  async (Package, thunkApi) => {
    let url = `/admin/packages/${Package.name}`;
    try {
      const resp = await customFetchToken.patch(url, Package);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getAllSeos = createAsyncThunk(
  "getAllSeos",
  async (pageName, thunkApi) => {
    let url = `/admin/seo`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getSeoByPageName = createAsyncThunk(
  "getSeoByPageName",
  async (pageName, thunkApi) => {
    let url = `/admin/seo/${pageName}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteSeoByPageName = createAsyncThunk(
  "deleteSeoByPageName",
  async (pageName, thunkApi) => {
    let url = `/admin/seo/${pageName}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const createSeoMetaTag = createAsyncThunk(
  "createSeoMetaTag",
  async (metaTag, thunkApi) => {
    let url = `/admin/seo`;
    try {
      const resp = await customFetchToken.post(url, metaTag);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateSeoMetaTag = createAsyncThunk(
  "updateSeoMetaTag",
  async (metaTag, thunkApi) => {
    let url = `/admin/seo/${metaTag.pageName}`;
    delete metaTag.pageName;
    try {
      const resp = await customFetchToken.patch(url, metaTag);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
// blogs
export const createBlog = createAsyncThunk(
  "createBlog",
  async (blog, thunkApi) => {
    let url = `/blogs`;
    try {
      const resp = await customFetchToken.post(url, blog);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getAllBlogs = createAsyncThunk(
  "getAllBlogs",
  async (filters, thunkApi) => {
    let url = `/blogs`;
    try {
      const resp = await customFetchToken.get(url, { params: filters });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteBlogById = createAsyncThunk(
  "deleteBlogById",
  async (id, thunkApi) => {
    let url = `/blogs/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getBlogById = createAsyncThunk(
  "getBlogById",
  async (id, thunkApi) => {
    let url = `/blogs/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const updateBlogById = createAsyncThunk(
  "updateBlogById",
  async (blog, thunkApi) => {
    let url = `/blogs/${blog?.id}`;
    try {
      const resp = await customFetchToken.patch(url, blog);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminState: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});
export const { setAdminState } = adminSlice.actions;

export default adminSlice.reducer;
