import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import EditorPage from "../EditorPage/EditorPage";

export default function AddText({ setLesson, lesson, homework }) {
  const handle = (e) => {
    if (e === "<p><br></p>") {
      return;
    } else {
      if (homework) {
        return setLesson(e);
      }
      setLesson({ ...lesson, description: e });
    }
  };
  return (
    <div className="course__add__text">
      <h1>Add Notes</h1>
      <span>Add text to the lecture</span>
      <div className="text__edit">
        <ReactQuill
          className="rich__text__editor"
          theme="snow"
          name="answer"
          value={homework ? lesson : lesson.description}
          onChange={(e) => handle(e)}
          placeholder="Type message..."
        />
      </div>
      <div className="cp__buttons">
        {/* <button className="setup" onClick={() => onSubmit()}>
          Add Text
        </button> */}
        {/* <button className="discard__btn">Cancel</button> */}
      </div>
    </div>
  );
}
