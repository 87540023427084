import React, { useState } from "react";
import "./CreateAdmin.styles.scss";
import show from "../../../assets/AdminApp/svgs/show.svg";
export default function CreateAdmin() {
  const [showPwd, setShowPwd] = useState(true);
  return (
    <div className="create__admin">
      <div className="admin__container">
        <h1>Add Admin User</h1>
        <div className="inputs">
          <div className="input">
            <span>Name</span>
            <input type="text" />
            <p>Please enter your name</p>
          </div>
          <div className="input">
            <span>Email</span>
            <input type="text" />
            <p>Please enter your Email</p>
          </div>
          <div className="input">
            <span>Mobile No</span>
            <div className="phone__input">
              <select>
                <option value="">+234</option>
                <option value="">+234</option>
                <option value="">+234</option>
                <option value="">+234</option>
              </select>
              <input type="number" />
            </div>
            <p>Please enter phone number</p>
          </div>
          <div className="input__multiple">
            <div className="input">
              <span>Password</span>
              <div className="pwd__input">
                <input type={`${!showPwd ? "text" : "password"}`} />
                <img src={show} alt="" onClick={() => setShowPwd(!showPwd)} />
              </div>
              <p>Please enter your password</p>
            </div>
            <div className="input">
              <span>Role</span>
              <select>
                <option value="">Admin</option>
                <option value="">Admin</option>
                <option value="">Admin</option>
              </select>
              <p>Please enter your Email</p>
            </div>
          </div>
        </div>
        <div className="create__admin__btns">
          <button className="discard">Discard</button>
          <button className="save">Save</button>
        </div>
      </div>
    </div>
  );
}
