import React from "react";

import "./CourseContent.styles.scss";
import ContentAccordion from "../../../../SharedApp/Components/Courses/ContentAccordion/ContentAccordion";
import { formatTime } from "../../../../hooks";

export default function CourseContent({ courses, setShowModal }) {
  return (
    <div className="course__content__section">
      <div className="title">
        <span>Course content</span>
      </div>
      <div className="nb__sections">
        <ul className="ul__course">
          <li>
            <span>{courses.totalChapters}</span> <span>sections</span>
          </li>
          <li>
            <span>{courses.numLectures}</span> <span>lectures</span>
          </li>
          <li>
            <span>{formatTime(courses.totalLength)}</span>{" "}
            <span> total length</span>
          </li>
        </ul>
        {/* <span>Expand all sections</span> */}
      </div>
      {courses.chapters &&
        courses.chapters.length > 0 &&
        courses.chapters.map((acc, i) => (
          <ContentAccordion
            key={i}
            setShowModal={setShowModal}
            title={acc.title}
            nbLectures={acc.totalLessons}
            rows={acc.lessons}
            i={i}
          />
        ))}
    </div>
  );
}
