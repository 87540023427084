import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import "./requestRecommendation.styles.scss";
import { askForRecommendation } from "../../../ReduxToolkit/Slices/teacherSlice";

function RequestRecommendation() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [message, setMessage] = useState({
    to: "",
    subject: `${user.name} has invited you to join him on Educify and start you learning together!`,
    text: `You have been invited by ${user.name}!`,
    otp: "",
    html: "",
  });
  const [fetching, setFetching] = useState(false);

  //
  const notify = (message) => toast.success(message);
  const sendError = (message) => toast.error(message);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendEmail = () => {
    if (!validateEmail(message.to)) {
      return sendError("Please add a correct email address");
    }
    if (!message.to) {
      return sendError("Please add receiver's email address");
    }
    setFetching(true);
    dispatch(askForRecommendation({ email: message.to })).then((res) => {
      setFetching(false);
      notify(`Invitation sent to ${message.to}, thank you!`);
      setMessage({
        ...message,
        to: "",
      });
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendEmail();
    }
  };

  return (
    <div className="InviteFriends_Container">
      <div className="InviteFriends_Subcontainer">
        <h4>Request a recommendation</h4>
        <p className="link">
          Request endorsements to validate your skills, build a strong network,
          and open doors to new opportunities. Ready to boost your career?
          <br />
          <span className="colored">Request a recommendation now!</span>
        </p>

        <input
          type="text"
          placeholder="Type or paste email"
          className="message_input"
          value={message.to}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => setMessage({ ...message, to: e.target.value })}
        />
        <button
          className={fetching ? "fetching" : ""}
          onClick={() => sendEmail()}
        >
          Request
        </button>
      </div>
    </div>
  );
}

export default RequestRecommendation;
