import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../../ReduxToolkit/customFetch/customFetch";
import { resetStudentState } from "../../ReduxToolkit/Slices/studentSlice";
import { resetTeacherState } from "../../ReduxToolkit/Slices/teacherSlice";
import { logout, resetUserState } from "../../ReduxToolkit/Slices/userSlice";
import axios from "axios";

export default function SignOut(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    delete axios.defaults.headers.common["Authorization"];
    setAuthToken("delete");
  };

  const handleSignOut = () => {
    logoutUser();
    dispatch(resetStudentState());
    dispatch(resetTeacherState());
    dispatch(resetUserState());
    dispatch(logout());
    navigate("/");
  };
  return <div onClick={() => handleSignOut()}>{props.children}</div>;
}
