import React from "react";
import "./BreadCrumb.styles.scss";
export default function BreadCrumb({ title, name, name2 }) {
  return (
    <div className="breadcrumb">
      <div className="title">
        <span>{title}</span>
      </div>
      <div className="name">
        <span>{name} / </span>
        <p>{name2}</p>
      </div>
    </div>
  );
}
