export const PaypalInfo = ({
  handleKeyDown,
  handleMethodChange,
  onSubmit,
  method,
  setPaypalInfo,
}) => {
  return (
    <div className="accordion">
      <div className={`item`}>
        <div className="inputs">
          <div className="input">
            <h4>Account Holder Name</h4>
            <input
              type="text"
              placeholder="Enter your name"
              name="accountHolderName"
              value={method.accountHolderName}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleMethodChange(e)}
            />
          </div>
          <div className="input">
            <h4>Paypal Email Address</h4>
            <input
              type="text"
              placeholder="Enter your email address"
              name="paypalGmail"
              value={method.paypalGmail}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => {
                setPaypalInfo({ ...PaypalInfo, email: e.target.value });
                handleMethodChange(e);
              }}
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn__primary"
          onClick={() => onSubmit("")}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
