import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "firebase/compat/auth";
import MobileNavbar from "../../../TeacherApp/Components/MobileNavbar/MobileNavbar";
import DashboardDropDown from "./DashboardDropDown";
// import SignOut from "../../SignOut/SignOut";
import Toggle from "react-toggle";

import "./Menu.styles.scss";

// import num30022 from "../../../assets/SVG/num30022.png";
// import notfound from "../../../assets/SharedApp/images/default.webp";

// import searchsvg from "../../assets/HomeApp/svgs/searchicon.svg";
import book from "../../../assets/HomeApp/images/book.png";
import {
  setUser,
  updateUserHelpGuide,
} from "../../../ReduxToolkit/Slices/userSlice";
const Menu = ({ title }) => {
  const { user } = useSelector((state) => state.user);
  let tip = user?.helpGuide;
  const [showDropDown, setShowDropDown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearch] = useState("");
  const { cartItems } = useSelector((state) => state.student);
  useEffect(() => {
    const lessonWithImage = cartItems.filter(
      ({ lesson }) => lesson.images.length > 0
    );
    setCartImage(lessonWithImage[0]?.lesson?.images[0]?.url);
  }, [cartItems]);
  const [cartImage, setCartImage] = useState("");
  const dispatch = useDispatch();

  const handleChangeGuide = () => {
    dispatch(updateUserHelpGuide()).then((res) => {
      if (res.type.includes("fulfilled")) {
        dispatch(setUser({ ...user, helpGuide: res.payload.helpGuide }));
      }
    });
  };

  return (
    <>
      <div className="Menu_Container">
        <MobileNavbar />
        <div className="header">
          <div className="dashboard__nav__settings">
            <div className="col1">
              {user?.role === "STUDENT" && (
                <form
                  className="nav__search__mobile"
                  onSubmit={() => navigate(`/tutors/search/${searchValue}`)}
                >
                  {/* <img src={searchsvg} alt="" /> */}
                  <input
                    type="text"
                    placeholder={"search..."}
                    value={searchValue}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              )}
            </div>
            {/* <div
              className="col2"
              onMouseEnter={() => setShowDropDown(true)}
              onMouseLeave={() => setShowDropDown(false)}
            >
              <div
                className="name"
                onClick={(e) => setShowDropDown(!showDropDown)}
              >
                <p>Hello</p>
                <span>{user.name}</span>
              </div>
              <img
                onMouseEnter={() => setShowDropDown(true)}
                src={
                  user?.profileImage?.url !== "string" &&
                  user?.profileImage?.url
                    ? user.profileImage.url
                    : notfound
                }
                onClick={() => setShowDropDown(!showDropDown)}
                alt=""
              />
              {showDropDown && (
                <div className="users__dropdown__dash">
                  <div className="user__info">
                    <div className="image">
                      <img
                        src={
                          user?.profileImage?.url !== "string" &&
                          user?.profileImage?.url
                            ? user.profileImage.url
                            : notfound
                        }
                        alt=""
                      />
                    </div>
                    <div className="user__details">
                      <span>{user.name + " " + user.lastName}</span>
                      <p>{user.email}</p>
                    </div>
                  </div>
                  {user.role !== "ADMIN" && (
                    <div className="users__drop__links">
                      <span className="drop__link">
                        <Link
                          to={
                            user.role === "TEACHER"
                              ? "/teachers/settings"
                              : "/students/settings"
                          }
                          onClick={() => setShowDropDown(false)}
                        >
                          Account Settings
                        </Link>
                      </span>
                    </div>
                  )}
                  <SignOut>
                    <div className="users__logout ">
                      <span className="drop__link">Sign Out</span>
                    </div>
                  </SignOut>
                </div>
              )}
            </div> */}
            <DashboardDropDown
              setShowDropDown={setShowDropDown}
              showDropDown={showDropDown}
              user={user}
            />
          </div>
        </div>
        <div className="sunheader">
          <div className="background-1">
            {title && <span className="my-classes">{title}</span>}
            <span className="home-dashboard">{`${
              location.pathname.split("/")[1]
            } - ${
              location.pathname.split("/")[2]
                ? location.pathname.split("/")[2]
                : "Home"
            }`}</span>
            <div className="toggle_tooltip">
              {user?.helpGuide ? "Disable" : "Enable"} Guide
              <Toggle
                checked={tip}
                onChange={() => {
                  handleChangeGuide();
                }}
              />
            </div>
            {!!cartItems?.length && (
              <div className="abandont_cart_dash">
                <img
                  src={cartImage ?? book}
                  alt="lesson image"
                  className="custom_book"
                />
                <span className="my-classes">
                  {cartItems.length > 1
                    ? `${cartItems.length} lessons awaiting payment`
                    : `${cartItems.length} lesson awaiting payment`}
                </span>
                <Link to={"/cart"}>
                  <button className="home-dashboard">View all</button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
