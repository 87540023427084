import React from "react";
import Track from "../../Components/Track/Track";

function AddCourse({ children, step }) {
  return (
    <div
      className="flex_col"
      style={{ padding: "50px", background: "#F5F8FA" }}
    >
      <Track step={step} />
      <div style={{ width: "90% !important" }}>{children}</div>
    </div>
  );
}

export default AddCourse;
