import React from "react";
import "./CourseInstructorHeader.styles.scss";
// import tutor from "../../../../assets/SharedApp/images/tutor.webp";
import user from "../../../../assets/SharedApp/svgs/user.svg";
// import link2 from "../../../../assets/SharedApp/svgs/link-2.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState } from "react";
export default function CourseInstructorHeader({
  fname,
  lastName,
  image,
  description,
  nbStudents,
  nbReviews,
}) {
  function limitWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }

  const [showFullText, setShowFullText] = useState(false);

  return (
    <div className="Course__Instructor__description">
      <div className="row">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div className="instructor__details">
          <div className="name">
            <span>
              {fname} {lastName}
            </span>
          </div>
          <div className="position">
            <div className="col">
              <img src={user} alt="" /> <span>Instructor</span>
            </div>
            {/* <div className="col">
              <img src={link2} alt="" /> <span>Instructor</span>
            </div> */}
          </div>
          <div className="extra">
            <div className="totals">
              <span>{nbStudents}</span>
              <p>{nbStudents > 1 ? "Total Students" : "Student"}</p>
            </div>
            <div className="totals">
              <span>{nbReviews}</span>
              <p>
                <p>{nbReviews > 1 ? "Total Reviews" : "Review"}</p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="description">
        <p>{showFullText ? description : limitWords(description, 80)}</p>
        {!showFullText ? (
          <span className="showMore" onClick={() => setShowFullText(true)}>
            View more <ExpandMoreIcon />
          </span>
        ) : (
          <span className="showMore" onClick={() => setShowFullText(false)}>
            View less
            <ExpandLessIcon />
          </span>
        )}
        {/* <div className="showMore">
          <span>show more</span>
        </div> */}
      </div>
    </div>
  );
}
