import React from "react";
import "./Tutor.styles.scss";

import checked from "../../../assets/SharedApp/svgs/Checked.svg";
import { Link } from "react-router-dom";

export default function Tutor({ img, name, type, id }) {
  return (
    <div className="tutor__component">
      <img src={img} alt="tutor img" className="tutor__image" />
      <span>{name}</span>
      <span className="type">{type}</span>
      <Link to={`/tutor/${id}`}>
        {" "}
        <button className="btn__primary">
          <img
            src={checked}
            alt=""
            className="checked"
            width={"10px"}
            height="10px"
          />
          View Profile
        </button>
      </Link>
    </div>
  );
}
