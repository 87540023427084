import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./Landing.styles.scss";

import { HeaderData } from "../../assets/HomeApp/data/Landing2";
import searchIcon from "../../assets/HomeApp/svgs/search.svg";
import { courses } from "../../assets/HomeApp/data/LandingCourses";
import landingImg from "../../assets/HomeApp/images/landing_bg.webp";
import Course from "../../Components/Lesson/Lesson";
import {
  getSubjectsByCategory,
  getCategories,
} from "../../ReduxToolkit/Slices/lessonsSlice";
import Pagination from "../../Components/Pagination/Pagination";

export default function Landing() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { category } = useParams();

  const [selected, setSelected] = useState(category?.toLowerCase());
  const [subjects, setSubjects] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [searchValue, setSearch] = useState("");

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dispatch(getCategories()).then((res) => {
      setCategories(res?.payload?.data?.categories);
    });
  }, []);

  useEffect(() => {
    if (categories.length) {
      setSubjects([]);
      dispatch(getSubjectsByCategory(category)).then((res) => {
        if (res.type === "getSubjectsByCategory/fulfilled") {
          setSubjects(res.payload.data);
        } else {
          alert("Category does not exists");
          return navigate(-1);
        }
      });
    }
  }, [selected, categories, location.pathname]);

  return (
    <>
      <div className="landing__container">
        <div className="header">
          <div className="details">
            <div className="breadcrumb">
              <p> {category}</p>
              <p>
                <Link to={"/"}>
                  <span> Home </span>
                </Link>{" "}
                / <span> {category}</span>
              </p>
            </div>
            <h1>
              {HeaderData.header.phrase.split(HeaderData.header.highlight)[0]}{" "}
              <span className="highlight">{HeaderData.header.highlight}</span>
              {
                HeaderData.header.phrase.split(HeaderData.header.highlight)[1]
              }{" "}
            </h1>
            <p className="subtitle"> {HeaderData.paragraph.phrase}</p>
            <form
              className="landing__inputs"
              onSubmit={() => navigate(`/tutors/search/${searchValue}`)}
            >
              <input
                type="text"
                placeholder={HeaderData.placeholder}
                value={searchValue}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button type="submit">
                <img src={searchIcon} alt="search" />
              </button>
            </form>
          </div>
          <div className="img">
            <img src={landingImg} alt="" />
            <div className="landing__inputs">
              <input type="text" placeholder="What to learn ?" />{" "}
              <button>
                <img src={searchIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="categories__slider">
          <div className={`dropdown ${showDropDown ? "active" : ""}`}>
            {HeaderData.moreDetails.map((detail, i) => (
              <Link to={`/landing/${detail}`} key={i}>
                {" "}
                <span>{detail}</span>
              </Link>
            ))}
          </div>

          {categories.map((cat, i) => (
            <Link
              to={`/landing/${cat.name}`}
              key={i}
              onClick={() => setSelected(cat.name)}
            >
              <span
                className={`${
                  selected?.toLowerCase() === cat?.name?.toLowerCase()
                    ? "selected"
                    : ""
                } `}
              >
                {cat.name}
              </span>
            </Link>
          ))}
          <span
            className="more__details"
            onClick={() => setShowDropDown(!showDropDown)}
          >
            :
          </span>
        </div>
      </div>
      <LandingCategories category={category} subjects={subjects} />
    </>
  );
}

const LandingCategories = ({ category, subjects }) => {
  return (
    <div className="landing__category">
      <h2>{category}</h2>
      <div className="landing__lessons__container">
        {subjects.length > 0 &&
          subjects.map((course, i) => (
            <Course
              key={i}
              name={course.name}
              category={category}
              image={course.images[0]?.url}
              rating={5}
            />
          ))}
      </div>
      {/* <Pagination/> */}
    </div>
  );
};
