import React from "react";
import teacherPerson from "../../../assets/TeacherApp/assets/images/teacherPerson.png";
import { useNavigate } from "react-router-dom";
const Career = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/teacher-signup");
  };
  return (
    <div className="career_page">
      <div className="career_page_inner">
        <div className="col">
          <h1>Ready to take your tutoring career to new heights?</h1>
          <div className="text">
            <p>
              Sign up with Educify and unlock your full potential as an
              educator. Our platform offers flexible scheduling, global reach,
              and unlimited earning opportunities. Don't miss out on this chance
              to make a lasting impact. Sign up now and start tutoring today!
              <ol>
                <li>Schedule tutoring according to your week/month</li>
                <li>Offer private virtual or in person lessons</li>
                <li>Create a teacher student bond</li>
                <li>Set your own rates</li>
              </ol>
            </p>
          </div>
          <div className="button_container">
            <button onClick={handleNavigate} className="gold_button">
              Sign up as a Tutor
            </button>
            <span>(Get Free Profile Advert)</span>
          </div>
        </div>
        <div className="col image_col">
          <div className="teacher_img">
            <img src={teacherPerson} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
