import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSeos } from "../../../ReduxToolkit/Slices/adminSlice";
import moment from "moment";

import plus from "../../../assets/AdminApp/svgs/plus.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";
import viewRow from "../../../assets/AdminApp/svgs/view.svg";

import Pagination from "../../../Components/Pagination/Pagination";

export default function AdminSettings() {
  const dispatch = useDispatch();
  const [seo, setSeo] = useState([]);

  useEffect(() => {
    dispatch(getAllSeos()).then((res) => {
      if (res.type.includes("fulfilled")) setSeo(res.payload.data);
    });
  }, []);

  return (
    <div className="manage__admin__settings">
      <div className="header" style={{ marginBottom: "20px" }}>
        <div className="header__text">
          <span>SEO MetaTags</span>
        </div>
        <div className="teachers__actions">
          <Link className="new__category" to="/admin/seo/add">
            <img src={plus} alt="" />
            <span>Add New MetaTag</span>
          </Link>
        </div>
      </div>
      <div className="students__table">
        <table style={{ tableLayout: "auto" }}>
          <thead>
            <tr>
              <th className="short fitwidth">
                <span className="th__spa">NO</span>
              </th>
              <th>
                <span className="th__span">Page Name</span>
              </th>
              <th>
                <span className="th__span">Descriptions</span>
              </th>
              <th>
                <span className="th__span">Keywords</span>
              </th>
              <th>
                <span className="th__span">CREATED AT</span>
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {seo.length &&
              seo.map((row, i) => (
                <tr key={i}>
                  <td className="short fitwidth">{++i}</td>
                  <td>
                    <div className="fitwidth">
                      <div className="lesson__date">
                        <span>{row?.pageName}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>
                      {<span>{row?.descriptions?.length} Descriptions</span>}
                    </span>
                  </td>

                  <td>
                    <span>{row?.keyName?.length} Keynames</span>
                  </td>
                  <td>
                    <span>{moment(row?.createdAt).format("DD/MM/YYYY")}</span>
                  </td>
                  <td>
                    <div className="category__actions">
                      <div className="actions">
                        <Link to={`/admin/seo/${row.pageName}/edit`}>
                          <img src={edit} alt="" />
                        </Link>
                        <Link to={`/admin/seo/${row.pageName}`}>
                          <img src={viewRow} alt="" />
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
}
