import React, { useState } from "react";
import "./ViewCategory.styles.scss";
import edit from "../../../assets/SharedApp/svgs/edit.svg";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import notfound from "../../../assets/AdminApp/images/404img.webp";
import { useDispatch, useSelector } from "react-redux";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import {
  setUserProfilePicture,
  uploadImage,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { useEffect } from "react";
import customFetch from "../../../ReduxToolkit/customFetch/customFetch";
import { Link, useLocation, useParams } from "react-router-dom";
import { getCoursesCategoryByName } from "../../../ReduxToolkit/Slices/coursesSlice";
export default function ViewCategory() {
  const [category, setCategory] = useState({});
  const { id } = useParams();
  const location = useLocation();
  let courses = location.pathname.includes("courses");

  const dispatch = useDispatch();

  useEffect(() => {
    courses
      ? dispatch(getCoursesCategoryByName(id)).then((res) =>
          setCategory(res.payload)
        )
      : customFetch(`/categories/${id}`).then((res) => setCategory(res.data));
  }, []);

  return (
    <div className="edit__category">
      <div className="header">
        <h1>Add Category</h1>
        <p>Setup lesson category</p>
      </div>
      <div className="create__category__row">
        <span>Category Photo</span>
        <img
          src={
            courses
              ? category?.image?.url
              : category.images && category.images.length
              ? category.images[0].url
              : notfound
          }
          alt=""
        />
        <div className="edit__cat__btn">
          <Link
            to={
              courses
                ? `/admin/courses/categories/${id}/edit`
                : `/admin/categories/${id}/edit`
            }
          >
            <button>Edit</button>
          </Link>
        </div>
      </div>
      <div className="create__category__row">
        <span>Category Name</span>
        <p>{category && category.name}</p>
      </div>
      {!courses && (
        <div className="create__category__row">
          <span>Change Status</span>
          <div className="save__card">
            <input
              type="checkbox"
              id="switch"
              class="checkbox"
              checked={
                category.status && category.status === "ACTIVE" ? true : false
              }
            />
            <label for="switch" class="toggle"></label>
          </div>
        </div>
      )}
      {!courses && (
        <div className="create__category__row">
          <span>Last Updated</span>
          <p>
            {category.images && category.images.length
              ? category.images[0].updatedAt
              : "no date"}
          </p>
        </div>
      )}
    </div>
  );
}
