import React from "react";
import "./AdminHeader.styles.scss";
export default function AdminHeader({ onExportClick }) {
  return (
    <div className="admin__header">
      <div className="admin__header__container">
        <span>Welcome to Educify Admin Board</span>
        <p>Activity Management System</p>
        <button
          className=""
          onClick={() => {
            onExportClick();
          }}
        >
          Download User List
        </button>
      </div>
    </div>
  );
}
