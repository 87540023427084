import React, { useState } from "react";
import DangerNote from "../../Components/DangerNote/DangerNote";
import "./BasicSettings.styles.scss";
import BannerImage from "../../Components/BannerImage/BannerImage";
import SocialLinks from "../../Components/SocialLinks";
import NotificationSettings from "../../Components/NotificationSettings/NotificationSettings";
import Basic from "../../Components/BasicSettings/Basic";
import Referal from "../../Components/BasicSettings/Referal";
import Fees from "../../Components/BasicSettings/Fees";
export default function BasicSettings() {
  return (
    <div className="basic__settings__page">
      <DangerNote />
      <Fees />
      <Referal />
      <Referal register />
      {/* <Basic /> */}
      {/* <NotificationSettings /> */}
      {/* <SocialLinks />
      <BannerImage /> */}
    </div>
  );
}
