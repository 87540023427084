import React, { useEffect } from "react";
import CourseCard from "../../../../StudentApp/Components/Courses/CourseCard/CourseCard";
import BreadCrumb from "../../../Components/Courses/BreadCrumb/BreadCrumb";
// import PageHeader from "../../../Components/PageHeader/PageHeader";
// import tutor from "../../../../assets/SharedApp/images/tutor.webp";
// import user from "../../../../assets/SharedApp/svgs/user.svg";
// import link2 from "../../../../assets/SharedApp/svgs/link-2.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./InstructorProfile.styles.scss";
import CourseInstructorHeader from "../../../Components/Courses/CourseInstructorHeader/CourseInstructorHeader";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTeacherById } from "../../../../ReduxToolkit/Slices/teacherSlice";
import { useState } from "react";
import {
  getStudentCoursesById,
  getStudentCoursesByInstructor,
  getTeacherCourses,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
export default function InstructorProfile() {
  const { id } = useParams();
  const [instructor, setInstructor] = useState([]);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(0);
  const [showAllCourses, setShowAllCourses] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTeacherById(id)).then((res) => setInstructor(res.payload));

    dispatch(getStudentCoursesByInstructor(id)).then((res) =>
      setCourses(res.payload.courses)
    );
  }, []);
  const { name, lastName, profileImage } = instructor?.user || {};
  const totalStudents = courses.reduce(
    (acc, course) => acc + course.students.length,
    0
  );

  let maxCourses = showAllCourses ? courses.length : 4;
  return (
    <div className="courses__instructor__profile">
      {name && (
        <div className="profile__container">
          <BreadCrumb title={"Instructor"} name="Course" name2={"Instructor"} />
          <CourseInstructorHeader
            fname={name}
            lastName={lastName}
            image={profileImage.url}
            description={instructor.description}
            nbReviews={instructor.reviews.length}
            nbStudents={totalStudents}
          />
          <div className="instructor__courses">
            <h1>My Courses</h1>
            <div className="instructor__courses__container">
              {courses.slice(0, maxCourses).map((course) => (
                <CourseCard
                  key={course.id}
                  title={course.title}
                  price={course.price}
                  image={course.profileImage?.url}
                  rating={course.rating}
                  id={course.id}
                />
              ))}
            </div>
          </div>
          {courses.length > 5 && (
            <div className="more__courses">
              {!showAllCourses ? (
                <span
                  className="showMore"
                  onClick={() => setShowAllCourses(!showAllCourses)}
                >
                  View more <ExpandMoreIcon />
                </span>
              ) : (
                <span
                  className="showMore"
                  onClick={() => setShowAllCourses(!showAllCourses)}
                >
                  View less
                  <ExpandLessIcon />
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
