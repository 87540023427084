import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Toggle from "react-toggle";
import { Tooltip } from "@mui/material";
import * as momentTz from "moment-timezone";
import moment from "moment";

import { toast } from "react-toastify";
import GroupLesson from "./GroupLesson";
import { customStyles } from "./Costumssyles";
import { handleUserChange } from "../../../ReduxToolkit/Slices/userSlice";
import {
  getAllCategoriesWithSubjects,
  handleSetupLessonChange,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import SharedMultipleFileUploader from "../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import SharedVideoUploader from "../../../Components/SharedVideoUploader/SharedVideoUploader";
import Note from "../Note/Note";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function Lesson({ selected, index }) {
  const dispatch = useDispatch();
  const allTimezones = momentTz.tz.names();
  const { timezone } = useSelector((state) => state.user);
  const { lessonToSetup } = useSelector((state) => state.lessons);
  const lesson = lessonToSetup;
  const { groupLessonAppointment } = lessonToSetup;
  // const { lessonToSetup } = useSelector((state) => state.lessons);
  // const lesson = lessonToSetup;
  const [maxStudents, setMaxStudents] = useState(
    lesson.maxStudents ? lesson.maxStudents : 2
  );

  const setGroupLessonAppointment = (value) => {
    handleLessonChange("groupLessonAppointment", value);
  };
  const onMaxChange = (e) => {
    setMaxStudents(e.target.value);
    handleLessonChange("maxStudents", Number(e.target.value));
  };
  const handleGroupChange = (e) => {
    const { name, value } = e.target;
    setGroupLessonAppointmentAdd({
      ...groupLessonAppointmentAdd,
      [name]: value,
    });
  };
  const category = lesson?.categories?.length
    ? {
        index: 1,
        label: lesson.categories[0].name,
        value: lesson.categories[0].id,
      }
    : {};
  const subject = lesson?.subjects?.length
    ? {
        index: 1,
        label: lesson.subjects[0].name,
        value: lesson.subjects[0].id,
      }
    : {};
  const handleRemove = (i, name) => {
    const newArray = [
      ...lesson[name].slice(0, i),
      ...lesson[name].slice(i + 1),
    ];
    handleLessonChange(name, newArray);
  };
  const onClassAdd = () => {
    const { startTime, endDate, startDate, endTime, days } =
      groupLessonAppointmentAdd;
    if (!startTime) {
      return toast.error("Please select a start time first");
    }
    if (!endTime) {
      return toast.error("Please select a end time first");
    }
    if (!endDate) {
      return toast.error("Please select a end date first");
    }
    if (!startDate) {
      return toast.error("Please select a start date first");
    }
    if (!days.length) {
      return toast.error("Please select at least one day");
    }
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = moment(endTime, "HH:mm");
    const startDateMoment = moment(startDate, "YYYY-MM-DD");
    const endDateMoment = moment(endDate, "YYYY-MM-DD");

    if (endTimeMoment.isBefore(startTimeMoment)) {
      return toast.error("End time cannot be before start time");
    }

    if (endDateMoment.isBefore(startDateMoment)) {
      return toast.error("End date cannot be before start date");
    }

    setGroupLessonAppointment([
      ...groupLessonAppointment,
      groupLessonAppointmentAdd,
    ]);
    return setGroupLessonAppointmentAdd(groupLessonAppointmentInitial);
  };

  const options = [
    { value: "BEGINNER", label: "Beginner" },
    { value: "INTERMEDIATE", label: "Intermediate" },
    { value: "ADVANCED", label: "Advanced" },
  ];
  const fileTypes = ["JPEG", "PNG", "JPG"];

  const locationsCheckInitialState = {
    online:
      lesson.location && lesson?.location.includes("online") ? true : false,
    studio:
      lesson.location && lesson?.location.includes("teacherPlace")
        ? true
        : false,
    student:
      lesson.location && lesson?.location.includes("studentPlace")
        ? true
        : false,
  };

  const lessonVideo =
    lesson?.videos.length > 0 ? lesson.videos[0] : { url: "", publicId: "" };

  const [locationsCheck, setLocationsCheck] = useState(
    locationsCheckInitialState
  );
  const [categories, setCategories] = useState([]);

  function findCategoryById(categories, id) {
    return categories.find((category) => category.id === id);
  }

  const [selectedCategory, setSelectedCategory] = useState({});

  let yout = lessonVideo?.url === lessonVideo?.publicId ? true : false;
  const [youtube, setYoutube] = useState(yout);

  const handleLessonChange = (name, value) => {
    dispatch(handleSetupLessonChange({ name, value }));
  };
  const handleYoutube = (value) => {
    handleLessonChange("videos", [{ url: value, publicId: value }]);
  };
  const toggleYoutube = () => {
    setYoutube(!youtube);
    handleLessonChange("videos", [{ publicId: "", url: "" }]);
  };
  useEffect(() => {
    category.value && handleLessonChange("categoryIds", [category.value]);
  }, [category.value]);

  useEffect(() => {
    if (subject.value) {
      handleLessonChange("subjectIds", [subject.value]);
    }
  }, [subject.value]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(categories[e.index]);
    handleLessonChange("categories", [{ id: e.value, name: e.label }]);
  };

  const handleSubjectChange = (e) => {
    handleLessonChange("subjects", [{ id: e.value, name: e.label }]);
  };

  const handleCheckBox = (e, name, checked) => {
    if (lesson.isGroup) {
      return handleLessonChange("location", [e.target.value]);
    }
    if (!checked) {
      handleLessonChange("location", [...lesson.location, e.target.value]);
    } else {
      const newLocation = lesson.location.filter((element) => {
        return element !== e.target.value;
      });
      handleLessonChange("location", newLocation);
    }
    if (!lesson.isGroup) {
      setLocationsCheck({ ...locationsCheck, [name]: !locationsCheck[name] });
    }
  };

  useEffect(() => {
    setLocationsCheck(locationsCheckInitialState);
  }, [index]);

  console.log("selected", selectedCategory);

  useEffect(() => {
    !categories?.length &&
      dispatch(getAllCategoriesWithSubjects()).then((res) => {
        if (res.payload?.data?.categories) {
          setCategories(res.payload.data.categories);
          console.log("setting");
          setSelectedCategory(
            findCategoryById(
              res.payload.data.categories,
              lesson?.categories[0]?.id
            )
          );
        }
      });
  }, []);

  const categoryOptions = (array) =>
    array && array.length
      ? array.map((cat, i) => {
          return {
            value: cat.id,
            label: cat.name,
            index: i,
          };
        })
      : [];
  //here

  const valuedIndex = () => {
    const newIndex = options.filter((option, i) => {
      if (
        lesson.level &&
        option.value.toLowerCase() === lesson.level.toLowerCase()
      )
        return option;
    });

    return newIndex[0];
  };

  const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_DURATION = 120;

  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const groupLessonAppointmentInitial = {
    startTime: "",
    endTime: "",
    startDate: "",
    endDate: "",
    seatsLeft: 0,
    days: [],
  };

  const [groupLessonAppointmentAdd, setGroupLessonAppointmentAdd] = useState(
    groupLessonAppointmentInitial
  );

  const renderSelectedDays = (array) => {
    if (!array || !array.length) {
      return "No days selected";
    }

    return array
      .map((day, index) => {
        if (index === array.length - 1) {
          return day.toLowerCase();
        }
        return day.toLowerCase() + ", ";
      })
      .join("");
  };
  const handleWhatLearnAdd = (name) => {
    const newArray = [...lesson[name], ""];
    handleLessonChange(name, newArray);
  };

  const toggleDay = (day) => {
    if (groupLessonAppointmentAdd?.days?.includes(day)) {
      setGroupLessonAppointmentAdd({
        ...groupLessonAppointmentAdd?.days?.includes(day),
        days: groupLessonAppointmentAdd.days.filter((d) => d !== day),
      });
    } else {
      setGroupLessonAppointmentAdd({
        ...groupLessonAppointmentAdd,
        days: [...groupLessonAppointmentAdd.days, day],
      });
    }
  };
  const handleTimezone = (value) => {
    dispatch(
      handleUserChange({
        name: "timezone",
        value,
      })
    );
  };
  const handleGroupDelete = (indexToDelete) => {
    const newArray = [
      ...groupLessonAppointment.slice(0, indexToDelete),
      ...groupLessonAppointment.slice(indexToDelete + 1),
    ];
    setGroupLessonAppointment(newArray);
  };

  const handleWhatLearn = (e, i) => {
    const { name, value } = e.target;
    const newArray = [
      ...lesson[name].slice(0, i),
      value,
      ...lesson[name].slice(i + 1),
    ];
    handleLessonChange(name, newArray);
  };

  //
  return (
    <div className="lesson__input__inner">
      <div className="lesson__details">
        <div className="description">
          <span className="row__span">Lesson Title</span>
          <textarea
            placeholder="Lesson title"
            value={lesson.title}
            onChange={(e) => handleLessonChange("title", e.target.value)}
          />
        </div>
        {lesson?.isGroup && (
          <div className="description" style={{ margin: "0" }}>
            <span className="row__span">Lesson Description</span>
            <textarea
              placeholder="Lesson Description"
              value={lesson.description}
              onChange={(e) =>
                handleLessonChange("description", e.target.value)
              }
            />
          </div>
        )}
        <div className="locations_container">
          <div className="locations">
            <div className="label">
              <p>Lesson Discount (%)</p>
            </div>
            <div className="option radio_op">
              <div className="input n_radio_input">
                <input
                  type="number"
                  value={lesson.discount}
                  onChange={(e) =>
                    handleLessonChange("discount", e.target.value)
                  }
                  className="edc_input"
                />
              </div>
            </div>
          </div>
          <div className="locations">
            <div className="label">
              <p>Lesson Discount End Date </p>
            </div>
            <div className="option radio_op">
              <div className="input ">
                <input
                  type="date"
                  className="edc_input"
                  value={moment(lesson.discountEndDate).format("YYYY-MM-DD")}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(e) =>
                    handleLessonChange("discountEndDate", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          {/* <GroupLesson
            handleLessonChange={handleLessonChange}
            lesson={lesson}
          /> */}

          <div className="locations">
            <p>Please, select lesson location</p>
            <div className="row-radio">
              <div
                className={`option ${lesson.isGroup ? "radio_op" : "option"}`}
              >
                <input
                  type={lesson.isGroup ? "radio" : "checkbox"}
                  name="location"
                  id=""
                  value={"online"}
                  checked={lesson.location.includes("online")}
                  onChange={(e) =>
                    handleCheckBox(e, "online", locationsCheck.online)
                  }
                />{" "}
                <span>Online</span>
              </div>
              <div
                className={`option ${lesson.isGroup ? "radio_op" : "option"}`}
              >
                <input
                  type={lesson.isGroup ? "radio" : "checkbox"}
                  name="location"
                  id=""
                  value={"teacherPlace"}
                  checked={lesson.location.includes("teacherPlace")}
                  onChange={(e) =>
                    handleCheckBox(e, "studio", locationsCheck.studio)
                  }
                />
                <span>Your Studio</span>
              </div>
            </div>

            {!lesson.isGroup && (
              <div className={lesson.isGroup ? "radio_op" : "option"}>
                <input
                  type={lesson.isGroup ? "radio" : "checkbox"}
                  name="location"
                  id=""
                  checked={lesson.location.includes("studentPlace")}
                  value={"studentPlace"}
                  onChange={(e) =>
                    handleCheckBox(e, "student", locationsCheck.student)
                  }
                />
                <span>Students home</span>
              </div>
            )}
          </div>
        </div>
        {/* start paste */}
        <>
          {lesson.isGroup && (
            <div className="row">
              <Note
                title={"We need your attention!"}
                marginTops
                p1="The group lesson time selection is based on the availabilites earlier, if your prefarable time is not included please go back and update your availabilites."
              />
              <div className="" style={{ marginTop: "20px" }}>
                <span className="row__span">Classes</span>
              </div>
              <div className="lesson_times_outer_container">
                <div className="lesson_times_inner_container">
                  <div>
                    <h2>Select Days</h2>
                    <ul className="day-list">
                      {daysOfWeek.map((day) => (
                        <li key={day}>
                          <label>
                            <input
                              type="checkbox"
                              checked={groupLessonAppointmentAdd?.days?.includes(
                                day
                              )}
                              onChange={() => toggleDay(day)}
                            />
                            {day?.toLowerCase()}
                          </label>
                        </li>
                      ))}
                    </ul>
                    {/* <div>
                    Selected Days:{" "}
                    {renderSelectedDays(groupLessonAppointmentAdd?.days)}
                  </div> */}
                  </div>
                  <div className="row__inputs__group lesson_times_container">
                    <div className="input">
                      <p>Time zone</p>
                      <select
                        onChange={(e) => handleTimezone(e.target.value)}
                        value={timezone}
                        className="edu_input"
                      >
                        {allTimezones.map((tz, i) => (
                          <option key={i} value={tz}>
                            {tz}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input">
                      <p>Start Time</p>
                      <input
                        type="time"
                        value={groupLessonAppointmentAdd.startTime}
                        name="startTime"
                        className="edu_input"
                        onChange={(e) => handleGroupChange(e)}
                      />
                    </div>
                    <div className="input">
                      <p>End Time</p>
                      <input
                        type="time"
                        value={groupLessonAppointmentAdd.endTime}
                        name="endTime"
                        onChange={(e) => handleGroupChange(e)}
                        className="edu_input"
                      />
                    </div>
                    <div className="input">
                      <p>Start Date</p>
                      <input
                        className="edu_input"
                        type="date"
                        value={groupLessonAppointmentAdd.startDate}
                        name="startDate"
                        onChange={(e) => handleGroupChange(e)}
                      />
                    </div>
                    <div className="input">
                      <p>End Date</p>
                      <input
                        type="date"
                        value={groupLessonAppointmentAdd.endDate}
                        name="endDate"
                        onChange={(e) => handleGroupChange(e)}
                        className="edu_input"
                      />
                    </div>
                    <button className="add_class" onClick={() => onClassAdd()}>
                      {lesson?.groupLessonAppointment?.length > 1
                        ? "Add anoter class"
                        : "Add class"}
                    </button>
                  </div>
                </div>
                <div className="days_table_container">
                  {groupLessonAppointment && groupLessonAppointment.length
                    ? groupLessonAppointment.map((app, i) => (
                        <div className="days_table">
                          <div className="table_row">
                            <h5>Days:</h5>
                            <p key={i}>{renderSelectedDays(app?.days)}</p>
                          </div>
                          <div className="table_row">
                            <h5>Start time:</h5>
                            <p>{app.startTime}</p>
                          </div>
                          <div className="table_row">
                            <h5>End time:</h5>
                            <p>{app.endTime}</p>
                          </div>
                          <div className="table_row">
                            <h5>Start Date:</h5>
                            <p>{app.startDate}</p>
                          </div>
                          <div className="table_row">
                            <h5>End Date:</h5>
                            <p>{app.endDate}</p>
                          </div>
                          <div className="btn_cont">
                            <Tooltip
                              title="Click to remove"
                              arrow
                              placement="top"
                            >
                              <button
                                className="delete"
                                onClick={() => handleGroupDelete(i)}
                              >
                                Delete class
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      ))
                    : [
                        {
                          startTime: "-",
                          endTime: "-",
                          startDate: "-",
                          endDate: "-",
                          seatsLeft: 0,
                          days: ["-"],
                        },
                      ].map((app, i) => (
                        <div className="days_table">
                          <div className="table_row">
                            <h5>Days:</h5>
                            <p key={i}>{renderSelectedDays(app?.days)}</p>
                          </div>
                          <div className="table_row">
                            <h5>Start time:</h5>
                            <p>{app.startTime}</p>
                          </div>
                          <div className="table_row">
                            <h5>End time:</h5>
                            <p>{app.endTime}</p>
                          </div>
                          <div className="table_row">
                            <h5>Start Date:</h5>
                            <p>{app.startDate}</p>
                          </div>
                          <div className="table_row">
                            <h5>End Date:</h5>
                            <p>{app.endDate}</p>
                          </div>
                          <div className="btn_cont">
                            <Tooltip
                              title="Click to remove"
                              arrow
                              placement="top"
                            >
                              <button
                                className="delete"
                                onClick={() => handleGroupDelete(i)}
                              >
                                Delete class
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                </div>
              </div>

              {/* <div>
            {groupLessonAppointment &&
              groupLessonAppointment.length &&
              groupLessonAppointment.map((app, i) => (
                <div className="flex_space">
                  <div className="flex_center">
                    <h5>Days:</h5>
                    <p key={i}>{renderSelectedDays(app?.days)}</p>
                  </div>
                  <div className="flex_center">
                    <h5>Start time:</h5>
                    <p>{app.startTime}</p>
                  </div>
                  <div className="flex_center">
                    <h5>End time:</h5>
                    <p>{app.endTime}</p>
                  </div>
                  <div className="flex_center">
                    <h5>Start Date:</h5>
                    <p>{app.startDate}</p>
                  </div>
                  <div className="flex_center">
                    <h5>End Date:</h5>
                    <p>{app.endDate}</p>
                  </div>
                  <Tooltip title="Click to remove" arrow placement="top">
                    <button
                      className="delete flex_center"
                      onClick={() => handleGroupDelete(i)}
                    >
                      -
                    </button>
                  </Tooltip>
                </div>
              ))}
          </div> */}
              <div className="description">
                <span className="row__span">Time commitment</span>
                <textarea
                  placeholder={
                    "Describe how much time will be needed per week including time for homeworks."
                  }
                  value={lesson.timeCommitment}
                  onChange={(e) =>
                    handleLessonChange("timeCommitment", e.target.value)
                  }
                />
              </div>
              <div className="description">
                <span className="row__span">Lesson headline</span>
                <textarea
                  placeholder={
                    "Write a 1-2 sentence headline about the lesson."
                  }
                  value={lesson.headLine}
                  onChange={(e) =>
                    handleLessonChange("headLine", e.target.value)
                  }
                />
              </div>
              <div className="description">
                <span className="row__span">Lesson summary</span>
                <textarea
                  placeholder={"Write a 1-2 sentence summary about the lesson."}
                  value={lesson.summary}
                  onChange={(e) =>
                    handleLessonChange("summary", e.target.value)
                  }
                />
              </div>

              <div className="age_limit">
                <div className="input">
                  <p>Maximum students</p>
                  <input
                    type="number"
                    placeholder="Maximum number of students"
                    onChange={onMaxChange}
                    value={maxStudents}
                    className="edu_input"
                  />
                </div>
                <div className="input">
                  <p>Minimum Age</p>
                  <input
                    type="number"
                    placeholder="Student's minimum age"
                    onChange={(e) => {
                      handleLessonChange("fromAge", Number(e.target.value));
                    }}
                    value={lesson?.fromAge}
                    className="edu_input"
                  />
                </div>
                <div className="input">
                  <p>Maximum Age</p>
                  <input
                    type="number"
                    placeholder="Student's minimum age"
                    onChange={(e) => {
                      handleLessonChange("toAge", Number(e.target.value));
                    }}
                    value={lesson?.toAge}
                    className="edu_input"
                  />
                </div>
              </div>
              <div className="to_learn_container">
                <div className="input what_to_learn">
                  {/* <span>Learning Objectives</span> */}
                  <div
                    onClick={() => handleWhatLearnAdd("learningGoals")}
                    className="learning_header"
                  >
                    <span>Add Learning Objectives</span>
                    <button className="add_button">+</button>
                  </div>
                  <div className="imput_with_buttons">
                    {lesson?.learningGoals?.length > 0 &&
                      lesson.learningGoals.map((item, i) => (
                        <div className="learn_item">
                          <input
                            key={i}
                            type="text"
                            placeholder="What to learn"
                            name="learningGoals"
                            value={item}
                            className="edu_input"
                            onChange={(e) => handleWhatLearn(e, i)}
                          />
                          {i !== 0 && (
                            <HighlightOffIcon
                              className="Red"
                              onClick={() => handleRemove(i, "learningGoals")}
                            />
                          )}
                        </div>
                      ))}

                    {/* <div onClick={() => handleWhatLearnAdd("learningGoals")}>
                <span>Add Learning Objectives</span>
                <button className="add_button">+</button>
              </div> */}
                  </div>
                </div>
                <div className="input what_to_learn">
                  {/* <span>Learning Objectives</span> */}
                  <div
                    className="learning_header"
                    onClick={() => handleWhatLearnAdd("materialsNeeded")}
                  >
                    <span>Add Materials</span>
                    <button className="add_button">+</button>
                  </div>
                  <div className="imput_with_buttons">
                    {lesson?.materialsNeeded?.length > 0 &&
                      lesson.materialsNeeded.map((item, i) => (
                        <div className="learn_item">
                          <input
                            key={i}
                            type="text"
                            placeholder="Materials Needed"
                            className="edu_input"
                            name="materialsNeeded"
                            value={item}
                            onChange={(e) => handleWhatLearn(e, i)}
                          />
                          {i !== 0 && (
                            <HighlightOffIcon
                              className="Red"
                              onClick={() => handleRemove(i, "materialsNeeded")}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* <div className="input what_to_learn">
            <span>Materials Needed</span>
            <div className="imput_with_buttons">
              {lesson?.materialsNeeded?.length > 0 &&
                lesson.materialsNeeded.map((item, i) => (
                  <div className="flex_center">
                    <input
                      key={i}
                      type="text"
                      placeholder="Materials Needed"
                      className="edu_input"
                      name="materialsNeeded"
                      value={item}
                      onChange={(e) => handleWhatLearn(e, i)}
                    />
                    {i !== 0 && (
                      <HighlightOffIcon
                        className="Red"
                        onClick={() => handleRemove(i, "materialsNeeded")}
                      />
                    )}
                  </div>
                ))}

              <div onClick={() => handleWhatLearnAdd("materialsNeeded")}>
                <span>Add Materials</span>
                <button className="add_button">+</button>
              </div>
            </div>
          </div> */}
            </div>
          )}
        </>
        {/* end paste */}
        <h2>{selected}</h2>

        <div className="locations">
          {/* <p>Please, select lesson location</p> */}
          {/* <div className="checkbox_options">
            <div className="option checkbox_option">
              <input
                type={lesson.isGroup ? "radio" : "checkbox"}
                name="location"
                id=""
                value={"online"}
                checked={lesson.location.includes("online")}
                onChange={(e) =>
                  handleCheckBox(e, "online", locationsCheck.online)
                }
              />{" "}
              <span>Online</span>
            </div>
            <div className="option checkbox_option">
              <input
                type={lesson.isGroup ? "radio" : "checkbox"}
                name="location"
                id=""
                value={"teacherPlace"}
                checked={lesson.location.includes("teacherPlace")}
                onChange={(e) =>
                  handleCheckBox(e, "studio", locationsCheck.studio)
                }
              />
              <span>Your Studio</span>
            </div>
            {!lesson.isGroup && (
              <div className="option checkbox_option">
                <input
                  type={lesson.isGroup ? "radio" : "checkbox"}
                  name="location"
                  id=""
                  checked={lesson.location.includes("studentPlace")}
                  value={"studentPlace"}
                  onChange={(e) =>
                    handleCheckBox(e, "student", locationsCheck.student)
                  }
                />
                <span>Students home</span>
              </div>
            )}
          </div> */}
          {/* <div className="description">
            <span className="row__span">
              Lesson Description
              <span className="inner_word">(min 40 words)</span>
            </span>
            <textarea
              placeholder="Tell us about yourself and your experience. What you can offer the students that is unique and the levels you can teach. "
              value={lesson.description}
              onChange={(e) =>
                handleLessonChange("description", e.target.value)
              }
            />
          </div> */}
          <div className="level">
            <div className="select__input">
              <span className="row__span">Lesson Category</span>
              <Select
                name="colors"
                options={categoryOptions(categories)}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                onChange={handleCategoryChange}
                isSearchable={true}
                value={category}
              />
            </div>
            <div className="select__input">
              <span className="row__span">Subject</span>
              <Select
                name="colors"
                options={categoryOptions(selectedCategory?.subjects)}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                onChange={(e) => handleSubjectChange(e)}
                isSearchable={true}
                value={subject}
              />
            </div>
            {/* <p>Please select lesson level</p> */}
            <div className="select__input">
              <span className="row__span">Level</span>
              <Select
                name="colors"
                options={options}
                value={valuedIndex()}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                onChange={(e) =>
                  handleLessonChange("level", e.value.toUpperCase())
                }
                isSearchable={true}
              />
            </div>
          </div>
          {/* <div className="level">
            <div className="select__input">
              <span className="row__span">Level</span>
              <Select
                name="colors"
                options={options}
                value={valuedIndex()}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                onChange={(e) =>
                  handleLessonChange("level", e.value.toUpperCase())
                }
                isSearchable={true}
              />
            </div>
          </div> */}
          {lesson.isGroup && (
            <>
              <div className="row__video new__row__video">
                <span className="row__span">Location/Lesson Photos</span>
                <div className="image__selector">
                  <SharedMultipleFileUploader
                    fileTypes={fileTypes}
                    left
                    multiple={true}
                    images={lesson?.images ? lesson.images : []}
                    setImages={(value) => handleLessonChange("images", value)}
                  />
                </div>
              </div>
              <div className="row__video new__row__video">
                <span>Video Intro</span>
                <div>
                  <div className="flex_center">
                    <p>{!youtube ? "Upload a video" : "Add a youtube link"}</p>
                    <Toggle
                      defaultChecked={youtube} // initial state of the switch button
                      onChange={() => toggleYoutube()}
                      icons={false} // hide the default icons to use custom ones
                    />
                    <p>
                      {youtube ? "Or upload a video" : "Or add a youtube link"}
                    </p>
                  </div>
                  {!youtube ? (
                    <SharedVideoUploader
                      video={lessonVideo}
                      onVideoChange={(value) =>
                        handleLessonChange("videos", [value])
                      }
                      onVideoDelete={() => handleLessonChange("videos", [])}
                      specialDelete={true}
                      index={"lessonVideo"}
                      MAX_VIDEO_DURATION={MAX_VIDEO_DURATION}
                      MAX_VIDEO_SIZE={MAX_VIDEO_SIZE}
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Youtube video link"
                      onChange={(e) => handleYoutube(e.target.value)}
                      value={lessonVideo.url}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
