import React, { useState } from "react";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";

const SignupStepThree = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const [aboutLesson, setAboutLesson] = useState(
    teacherToRegister?.aboutLesson
  );
  const minWords = 40;
  const [wordsLeft, setWordsLeft] = useState(
    minWords - countWords(aboutLesson) < 0
      ? 0
      : minWords - countWords(aboutLesson)
  );

  function countWords(text) {
    return text?.trim()?.split(/\s+/)?.filter(Boolean).length; // Filters out empty strings in cases of multiple spaces
  }
  const handleSubmit = () => {
    if (!countWords(aboutLesson) || countWords(aboutLesson) < minWords) {
      return toast.error("insufficient words");
    }

    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, aboutLesson },
      })
    );
    navigate("/teacher-signup/step-four");
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const wordsCount = countWords(newText);
    setAboutLesson(newText);
    setWordsLeft(Math.max(minWords - wordsCount, 0));
  };

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[
            "- Your teaching method and techniques",
            "- A typical lesson plan",
            "- What sets you apart as a tutor",
            "- Who the lessons are for (degree, level, class, specificities, etc.)",
          ]}
          className={"register_note_2"}
          subtitle={
            "Explain your approach as a tutor and how you share your knowledge:"
          }
        />
      </div>
      <div className="container_2">
        <h2 className="no_max">
          About <span>you</span>{" "}
          <span className="optional">
            (
            {wordsLeft >= minWords
              ? `${minWords} minimum words`
              : `at least ${
                  isNaN(wordsLeft) ? minWords : wordsLeft
                } words left`}
            )
          </span>
        </h2>
        <div className="area_container lg">
          <textarea
            onChange={handleTextChange}
            value={aboutLesson}
            placeholder="It's time to convince your future students why they should take lessons with you!"
          />
        </div>

        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-two")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupStepThree;
