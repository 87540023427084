import React from "react";
import { Link } from "react-router-dom";
import "./navCategories.styles.scss";
export default function NavCategories({ categories, setOpenMenu,isCourses }) {
  let filtered = categories.filter((el) => el.subjects.length >= 1);

  //sort by most subjects
  function compareSubjects(a, b) {
    if (a.subjects.length > b.subjects.length) {
      return -1;
    }
    if (a.subjects.length < b.subjects.length) {
      return 1;
    }
    return 0;
  }

  return (
    <div className="nav__categories" onMouseLeave={() => setOpenMenu(false)}>
      {filtered.sort(compareSubjects).map((category) => (
        <div className="category">
          <Link
            className="category__title"
            to={isCourses? `/courses/categories/${category.name}`: `/landing/${category.name}`}
            onClick={() => setOpenMenu(false)}
          >
            {category.name}
          </Link>
          <div className="category__details">
            {category?.subjects.slice(0, 8).map((list) => (
              <li onClick={() => setOpenMenu(false)}>
                <Link to={isCourses? `/courses/search/${list.name}`: `/tutors/search/${list.name}/${category.name}/`}>
                  {list.name}
                </Link>
              </li>
            ))}
            <li onClick={() => setOpenMenu(false)} className="nav_viewmore">
              <Link to={isCourses? `/courses/categories/${category.name}`: `/landing/${category.name}`}>See All</Link>
            </li>
          </div>
        </div>
      ))}
    </div>
  );
}
