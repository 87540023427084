import React, { useEffect, useRef, useState } from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
const JitsiMeet = ({ name, roomName }) => {
  const containerRef = useRef(null);
  const api = useRef(null);
  const [showWhite, setShowWhite] = useState(false);

  useEffect(() => {
    const domain = "confrence.educify.org";

    let newRoomName = roomName ? roomName : "roomName";
    newRoomName = newRoomName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");

    // Remove whitespaces and disallowed characters
    newRoomName = newRoomName.trim().replace(/[^a-zA-Z0-9]/g, "");
    const options = {
      roomName: newRoomName.trim().replace(/[^a-zA-Z0-9]/g, ""),
      width: "100%",
      height: "100vh",
      parentNode: containerRef.current,
      userInfo: {
        displayName: name,
      },
      configOverwrite: {
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        prejoinPageEnabled: false,
      },
      interfaceConfigOverwrite: {
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          "chat",
          "recording",
          "livestreaming",
          "etherpad",
          "sharedvideo",
          "settings",
          "raisehand",
          "videoquality",
          "whiteboard",
          "filmstrip",
          "invite",
          "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "videobackgroundblur",
          "download",
          "help",
          "e2ee",
          "Extra",
        ],
      },
      parentNode: containerRef.current,
    };

    api.current = new window.JitsiMeetExternalAPI(domain, options);

    // Function to be called when window is closed
    // const handleWindowClose = () => {
    //   if (api.current) {
    //     api.current.dispose();
    //   }
    // };
    // window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      // window.removeEventListener("beforeunload", handleWindowClose);
      api.current.dispose();
    };
  }, [roomName]);

  return (
    <>
      <div ref={containerRef} />
    </>
  );
};

export default JitsiMeet;
