import React, { useEffect } from "react";
import { useState } from "react";
import "./analytics.styles.scss";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
  Legend,
  PieChart,
  Pie,
} from "recharts";
import { useDispatch } from "react-redux";
import {
  getInoicesAnalytics,
  getInoicesAnalyticsYearly,
  getInoicesAnalyticsYearlyPercentage,
  getTransactionsAnalytics,
} from "../../../ReduxToolkit/Slices/adminSlice";
export default function Analytics() {
  const [monthlyYear, setMonthlyYear] = useState(2023);
  const [monthlyYearTrans, setMonthlyYearTrans] = useState(2023);
  const [barData, setBarData] = useState([{ name: "", revenue: "", uv: "" }]);
  const [transData, setTransData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInoicesAnalytics(monthlyYear)).then((res) => {
      if (!res.payload || !res.payload.length) return;

      let data = res.payload.map((item) => {
        return {
          ...item,
          ["Teacher's Revenue"]: item.owed / 100,
          ["Admin's Revenue"]: (item.payed - item.owed) / 100,
          ["Student Payment"]: item.payed / 100,
          ["Total Discount"]:
            item.intial === 0 ? 0 : (item.intial - item.payed) / 100,
        };
      });

      setBarData(data);
    });
  }, [monthlyYear]);
  useEffect(() => {
    dispatch(getInoicesAnalyticsYearly()).then((res) => {
      if (!res.payload || !res.payload.length) return;
      let data = res.payload.map((item) => {
        return {
          revenue: item.totalPayed / 100,
          name: item.year,
        };
      });
      // setYearlyData([
      //   ...data,
      //   { revenue: 100, name: 2024 },
      //   { revenue: 50, name: 2025 },
      // ]);
      setYearlyData(data);
    });
    dispatch(getInoicesAnalyticsYearlyPercentage()).then((res) => {
      if (!res.payload) return;
      const data = [
        { name: "Courses", value: res.payload.coursePercentage },
        { name: "Group lessons", value: res.payload.groupLessonPercentage },
        { name: "Private", value: res.payload.privateLessonPercentage },
      ];
      setPieData(data);
    });
  }, []);
  useEffect(() => {
    dispatch(getTransactionsAnalytics(monthlyYearTrans)).then((res) => {
      if (!res.payload || !res.payload.length) return;
      let data = res.payload.map((item) => {
        return {
          deposits: item.deposits / 100,
          refunds: item.refunds / 100,
          payments: item.payments / 100,
        };
      });
      setTransData(data);
    });
  }, [monthlyYearTrans]);
  const data = [
    {
      name: "2020",
      pv: 2400,
      amt: 2400,
    },
    {
      name: "2021",
      pv: 1398,
      amt: 2210,
    },
    {
      name: "2022",
      pv: 9800,
      amt: 2290,
    },
    {
      name: "2023",
      pv: 3908,
      amt: 2000,
    },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%  (${name})`}
      </text>
    );
  };
  return (
    <div className="admin_analytics">
      <div className="chart_container">
        <div className="header">
          <h2>Yearly Revenue</h2>
        </div>
        <div className="chart">
          <ResponsiveContainer width={"100%"} height={300}>
            <AreaChart
              width={"100%"}
              height={300}
              data={yearlyData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorUv)"
              />
              <Area
                type="monotone"
                dataKey="revenue"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorPv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="multiple_charts">
        <div className="chart_container half">
          <div className="header">
            <h2>Monthly Revenue</h2>
          </div>
          <div className="header">
            <h2>Year</h2>
            <input
              type="number"
              className="tag_select"
              value={monthlyYear}
              placeholder="Enter year"
              onChange={(e) => setMonthlyYear(e.target.value)}
            />
          </div>
          <div className="multy_chart">
            <ResponsiveContainer width={"100%"} height={300}>
              <BarChart
                width={500}
                height={300}
                data={barData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Teacher's Revenue" fill="#8884d8" />
                <Bar dataKey="Student Payment" fill="#82ca9d" />
                <Bar dataKey="Total Discount" fill="#FFBB27" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="chart_container half">
          <div className="header">
            <h2>Users Revenue</h2>
          </div>
          <div className="">
            <PieChart width={480} height={300} compact={true}>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={true}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
      </div>
      <div className="multiple_charts">
        <div className="chart_container half">
          <div className="header">
            <h2>Monthly Payments</h2>
          </div>
          <div className="header">
            <h2>Year</h2>
            <input
              type="number"
              className="tag_select"
              value={monthlyYearTrans}
              placeholder="Enter year"
              onChange={(e) => setMonthlyYearTrans(e.target.value)}
            />
          </div>
          <div className="multy_chart">
            <ResponsiveContainer width={"100%"} height={300}>
              <BarChart
                width={500}
                height={300}
                data={transData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="payments" fill="#8884d8" />
                <Bar dataKey="deposits" fill="#82ca9d" />
                <Bar dataKey="refunds" fill="#FFBB27" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
