import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./CoursePreviewCard.styles.scss";

import heart from "../../../../assets/TeacherApp/assets/svgs/Shape.svg";
import redheart from "../../../../assets/TeacherApp/assets/svgs/redheart.svg";
import file from "../../../../assets/TeacherApp/assets/svgs/file.svg";
import img5 from "../../../../assets/TeacherApp/assets/images/course.webp";
import PreviewModal from "../PreviewModal/PreviewModal";
import {
  addCourseToCart,
  handleCoursesStateChange,
  FavouriteCourse,
  getStudentFavCourses,
  unFavouriteCourse,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import { toast } from "react-toastify";
import { redirectLink } from "../../../../ReduxToolkit/Slices/userSlice";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";

export default function CoursePreviewCard({
  course,
  setShowModal,
  showModal,
  id,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { logout, user } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { cartItems, refreshCart } = useSelector((state) => state.courses);

  const [fav, setfav] = useState(false);

  let copied =
    cartItems.length &&
    cartItems.filter((item) => item.course.id === course.id);

  useEffect(() => {
    !logout &&
      user.role === "STUDENT" &&
      dispatch(getStudentFavCourses(student)).then((res) => {
        const found = res.payload.find((crs) => crs.id === course.id);
        if (found) setfav(true);
        else setfav(false);
      });
  }, [fav, course]);

  const handleClick = () => {
    dispatch(
      handleCoursesStateChange({ name: "refreshCart", value: !refreshCart })
    );
  };
  const params = useParams();

  const addToCart = () => {
    if (logout) {
      dispatch(redirectLink(`/courses/${id}`));

      toast.error(
        "You have to be logged in as a Student to add course to cart."
      );
      return navigate("/login");
    }
    if (user.role !== "STUDENT") {
      toast.error(
        "You have to be logged in as a Student to add course to cart."
      );
    } else {
      !copied.length &&
        dispatch(
          addCourseToCart({
            studentId: student,
            courseId: course.id,
          })
        ).then((res) => {
          if (res.payload === "Course already bought") {
            return toast.error(res.payload);
          }
          handleClick();
        });
    }
  };

  const addToFav = () => {
    if (logout) {
      return navigate("/login");
    }
    dispatch(
      FavouriteCourse({
        studentId: student,
        courseId: course.id,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled")) {
        setfav(true);
        toast.success("Course Added To Favourites");
      } else {
        toast.error("Something Went Wrong.");
        setfav(false);
      }
    });
  };

  const onBuyNow = () => {
    if (logout) {
      return navigate("/login");
    } else {
      navigate(`/courses/checkout`);
    }
  };

  const unFavCourse = () => {
    dispatch(
      unFavouriteCourse({
        studentId: student,
        courseId: course.id,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled")) {
        setfav(false);
        toast.success("Course Removed From Favourites");
      } else {
        setfav(true);
        toast.error("Something Went Wrong.");
      }
    });
  };

  return (
    <>
      {showModal && (
        <PreviewModal setShowModal={setShowModal} course={course} />
      )}
      <div className="course__preview__card">
        <div
          className="preview__image"
          onClick={() => {
            window.scrollTo(0, 0);
            setShowModal(true);
          }}
        >
          <img src={course?.profileImage?.url} alt="" />
        </div>
        <div className="sub__container">
          <div className="price">
            <span>
              <ExchangeRateHook
                price={
                  course.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                }
              />
            </span>
          </div>
          <div className="card__buttons ">
            <div className="row1">
              <button
                onClick={() => addToCart()}
                className="animatedCopy flex_center"
              >
                {copied && copied.length > 0 ? (
                  <span>Added to cart</span>
                ) : (
                  <span>Add to cart</span>
                )}
                {copied && copied.length > 0 ? (
                  <div class="wrapper">
                    {" "}
                    <svg class="animated-check" viewBox="0 0 24 24">
                      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
                    </svg>
                  </div>
                ) : null}
              </button>
              <div
                className="fav__btn"
                onClick={() => {
                  if (fav) {
                    unFavCourse();
                    // setRefresh(!refresh);
                  } else {
                    setfav(true);
                    addToFav();
                    // setRefresh(!refresh);
                  }
                }}
              >
                <img src={fav ? redheart : heart} alt="" />
              </div>
            </div>
            {copied?.length > 0 && (
              <div className="row2">
                <button onClick={() => onBuyNow()}>Buy Now</button>
              </div>
            )}
          </div>
          <div className="refund">
            <p>30-Day Money-Back Guarantee</p>
          </div>
          {/* <div className="course__includes">
            <h3>This course includes:</h3>
            <div className="rows">
              {data &&
                data.map((el, i) => (
                  <div className="row" key={i}>
                    <img src={el.image} alt="" />
                    <span>{el.text}</span>
                  </div>
                ))}
            </div>
          </div> */}
          {/* <div className="action__btns">
            <button>Share</button>
            <button>Gift this course</button>
            <button>Apply Coupon</button>
          </div> */}
          {/* <div className="extra">
            <p>
              Get your team access to 19,000+ top Educify courses anytime,
              anywhere.
            </p>
          </div> */}

          {/* <Link className="seemore__btn" to="/courses">
            See More Educify Courses
          </Link> */}
        </div>
      </div>
    </>
  );
}
