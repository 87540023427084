import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./StudentChart.styles.scss";
import icon1 from "../../../assets/AdminApp/svgs/1.svg";
import icon2 from "../../../assets/AdminApp/svgs/2.svg";
import icon3 from "../../../assets/AdminApp/svgs/3.svg";
import icon4 from "../../../assets/AdminApp/svgs/4.svg";
export default function StudentChart({ dashboard, student }) {
  const COLORS = ["#8950FC", "#0BB783", "#3699FF", "#F64E60"];
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];

  const legend = [
    { value: dashboard.totalHours, text: "Hours Learned", icon: icon1 },
    { value: dashboard.totalLessons, text: "Enrolled", icon: icon3 },
    { value: dashboard.pendingLessons, text: "Awaiting Teacher", icon: icon2 },
    { value: dashboard.completedLessons, text: "Completed", icon: icon4 },
  ];
  return (
    <div className="student__chart">
      <div className="chart__container">
        <h1>
          <span>{student?.user?.name}</span>'s Lessons
        </h1>
        <p>
          Analysis of{" "}
          <span>
            {student?.user?.name} {student?.user?.lastName}
          </span>{" "}
          lessons
        </p>
        <div className="chart">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                labelLine={false}
                outerRadius={80}
                innerRadius={60}
                fill="#8884d8"
                dataKey="value"
                stroke="none"
                cornerRadius={10}
                paddingAngle={-15}
              >
                {data.map((entry, index) => (
                  <Cell fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <text
                x={"50%"}
                y={"50%"}
                textAnchor="middle"
                dominantBaseline="middle"
                className="chart__text"
                color="#464E5F"
              >
                {dashboard.totalLessons}
              </text>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="chart__legend">
          {legend.map((el) => (
            <div className="legend__item">
              <img src={el.icon} alt="" />
              <div className="text">
                <span>{el.value}</span>
                <p>{el.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
