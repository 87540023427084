import React from "react";
import Slider from "react-slick";
import SimilarTeacher from "../SimilarTeacher/SimilarTeacher";
import "./SimilarTeacherSlider.styles.scss";

export default function SimilarTeacherSlider({ data, title, recentlyViewed }) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data.length > 4 ? 5 : data.length - 1,
    slidesToScroll: 1,
  };

  const findLowestLessonPrice = (lessons) => {
    // Initialize lowestPrice with a very high value to ensure any real price will be lower
    let lowestPrice = Number.MAX_VALUE;

    // Iterate over each lesson
    lessons.forEach((lesson) => {
      // Destructure the prices from the lesson object
      const { onlinePrice, studentPlacePrice, teacherPlacePrice } = lesson;

      // Check each price, if it's more than 0 (assuming 0 means not available) and lower than the current lowestPrice
      if (onlinePrice > 0 && onlinePrice < lowestPrice) {
        lowestPrice = onlinePrice;
      }
      if (studentPlacePrice > 0 && studentPlacePrice < lowestPrice) {
        lowestPrice = studentPlacePrice;
      }
      if (teacherPlacePrice > 0 && teacherPlacePrice < lowestPrice) {
        lowestPrice = teacherPlacePrice;
      }
    });

    // Check if lowestPrice was updated, if not return 0 as a fallback
    return lowestPrice === Number.MAX_VALUE ? 0 : lowestPrice;
  };
  return (
    data.length > 0 && (
      <div className="similar__carousel">
        <h4>{title}</h4>
        {data.length > 3 ? (
          <Slider
            {...settings}
            className="similar__slider"
            infinite
            slidesToShow={data.length < 4 ? data.length : 4}
            draggable={true}
            focusOnSelect={true}
            touchMove={true}
            touchThreshold={10}
            responsive={[
              {
                breakpoint: 1380,
                settings: {
                  slidesToScroll: 1,
                  centerPadding: "350",
                },
              },
            ]}
          >
            {data.map((el, i) => (
              <SimilarTeacher
                key={i}
                name={el.user.name}
                price={findLowestLessonPrice(el.lessons)}
                element={el}
                rating={el.rating}
                location={el.location}
                id={el.id}
                image={el.user?.profileImage?.url}
              />
            ))}
          </Slider>
        ) : (
          <div className="similar__tutors__sec">
            {data.map((el, i) => (
              <SimilarTeacher
                key={i}
                name={el.user.name}
                price={1000}
                rating={el.rating}
                element={el}
                location={el.location}
                id={el.id}
                image={el.user?.profileImage?.url}
              />
            ))}
          </div>
        )}
      </div>
    )
  );
}
