import { useEffect, useRef, useState } from "react";
import { useAnimation } from "framer-motion";
import { motion } from "framer-motion";
const ScrollRevealWrapper = ({
  children,
  initialX,
  initialY,
  animateX,
  animateY,
  transition,
  delay,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();
  const wrapperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3, // Adjust this value to fine-tune when the reveal happens.
      }
    );

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start("animate");
    }
  }, [isVisible, controls]);

  return (
    <div ref={wrapperRef}
    className={className? className :""}
    >
      <motion.div
        animate={controls}
        initial="initial"
        className={className ? className :""}
        variants={{
          initial: { opacity: 0, x: initialX, y: initialY },
          animate: { opacity: 1, x: animateX, y: animateY },
        }}
        transition={{
          duration: transition ? transition : 0.9,
          delay: delay ? delay : 0,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};
export default ScrollRevealWrapper;
