import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStudentCourses } from "../../../../ReduxToolkit/Slices/coursesSlice";
import CourseContent from "../CourseContent/CourseContent";

import CourseFor from "../CourseFor/CourseFor";
import CourseInstructor from "../CourseInstructor/CourseInstructor";
import CoursePreviewCard from "../CoursePreviewCard/CoursePreviewCard";
import CourseRating from "../CourseRating/CourseRating";
// import FrequentlyBoughtTogether from "../FrequentlyBoughtTogether/FrequentlyBoughtTogether";
import MoreCoursesBySameInstructor from "../MoreCoursesBySameInstructor/MoreCoursesBySameInstructor";
import OverviewDescription from "../OverviewDescription/OverviewDescription";
import StudentsBought from "../StudentsBought/StudentsBought";
import WhatToLearn from "../WhatToLearn/WhatToLearn";

import singlestar from "../../../../assets/TeacherApp/assets/svgs/singlestar.svg";

export default function CourseDescription({ course, setShowModal, showModal }) {
  let objectives = course.whatLearn ? JSON.parse(course.whatLearn) : [];
  let target = course.whoFor ? JSON.parse(course.whoFor) : [];

  const [courses, setCourses] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    course.id &&
      dispatch(
        getStudentCourses(`teacherId=${course?.teacher?.id}&limit=1000`)
      ).then((res) => {
        setCourses(
          res.payload.courses.filter((item) => {
            return item.id !== course.id;
          })
        );
      });
  }, [course.id]);

  return (
    <>
      <div className="course__description__section">
        <div className="description__col">
          <CourseContent courses={course} setShowModal={setShowModal} />

          {objectives.length > 0 && <WhatToLearn objectives={objectives} />}
          <OverviewDescription description={course?.description} />
          {target.length > 0 && <CourseFor target={target} />}
          {/* <StudentsBought /> */}
          {/* <FrequentlyBoughtTogether /> */}
          <CourseInstructor
            id={course?.teacher?.id}
            course={course}
            courses={courses}
          />
          {course?.reviews?.length ? (
            <CourseRating course={course} />
          ) : (
            <div className="no__reviews">
              <div className="header-sec">
                <img src={singlestar} alt="" /> <span>Reviews</span>
              </div>
              <h1>No Reviews for this Course Yet</h1>
            </div>
          )}
        </div>
        <CoursePreviewCard
          course={course}
          setShowModal={setShowModal}
          showModal={showModal}
          id={course.id}
        />
      </div>
      <MoreCoursesBySameInstructor courses={courses} course={course} />
    </>
  );
}
