import React, { useEffect, useState } from "react";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { KeyboardArrowDown } from "@mui/icons-material";
import LessonRow from "../LessonRow/LessonRow";
import { useDispatch, useSelector } from "react-redux";
import { getWatched } from "../../../../ReduxToolkit/Slices/coursesSlice";
import { formatTime } from "../../../../hooks";
export default function LessonSection({
  title,
  sections,
  setSelected,
  watchChapter,
  selected,
  watchedLessons,
}) {
  const [show, setShow] = useState(false);

  const idExists = (id) => watchedLessons?.some((obj) => obj.id === id);
  const handleSecClick = (sec) => {
    if (sec?.videos.length && sec.videos[0].publicId.includes("videos")) {
      return;
    } else {
      watchChapter(sec.id);
    }
  };

  const firstVideos =
    sections && sections.length
      ? sections.map((section) => section.videos[0])
      : null;
  const totalVideoLength = firstVideos
    ? firstVideos.reduce((acc, curr) => acc + curr.videoLength, 0)
    : 0;

  const watchArray =
    sections && sections.length
      ? sections.filter((section) => idExists(section.id))
      : [];

  return (
    <div className="lesson__index__accordion">
      <div className="header" onClick={() => setShow(!show)}>
        <div className="title">
          <span>{title}</span>
          {show ? (
            <KeyboardArrowUp onClick={() => setShow(!show)} />
          ) : (
            <KeyboardArrowDown onClick={() => setShow(!show)} />
          )}
        </div>
        <div className="stamp">
          <span>
            <span>{watchArray.length}</span>
            <span>/</span>
            <span>{sections?.length}</span>
          </span>
          <span>|</span>
          <span>{formatTime(totalVideoLength)}</span>
        </div>
      </div>
      <div className={`lesson__rows ${show ? "active" : ""}`}>
        {sections &&
          sections.map((sec) => (
            <div key={sec.id} onClick={() => handleSecClick(sec)}>
              <LessonRow
                title={sec.title}
                selected={selected}
                sec={sec}
                watched={idExists(sec.id)}
                setSelected={setSelected}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
