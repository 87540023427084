import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ScrollAnimation from "../../SharedApp/Pages/ScrollAnimation/ScrollAnimation";

import "./newsletter.styles.scss";
import { text } from "../../assets/HomeApp/data/NewsLetter";
import { useDispatch } from "react-redux";
import { subscribeToNewsLetter } from "../../ReduxToolkit/Slices/userSlice";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const addSubscriber = (email) => {
    dispatch(subscribeToNewsLetter(email)).then((res) => {
      if (res.payload.error) {
        if (res.payload.error.includes("not a valid email address")) {
          return toast.error("Please enter a valid email address");
        }
        return toast.error("Error subscribing, please try again");
      }
      if (res.type === "subscribeToNewsLetter/fulfilled") {
        toast.success("You have successfully subscribed to our newsletter!");
      } else {
        toast.error("Error subscribing, please try again");
      }
    });
    setEmail("");
  };

  return (
    <ScrollAnimation
      initialY={50}
      animateY={0}
      initialX={0}
      animateX={0}
      transition={1}
      delay={0}
    >
      <div className="newsletter__container">
        <div className="header">
          <h2>
            {text.header}
            <span>{text.span}</span>
          </h2>
          <p>{text.p}</p>
        </div>
        <div className="newsletter__inputs">
          <input
            type="text"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="newsletter__subscribe"
            onClick={() => addSubscriber(email)}
          >
            {text.button}
          </button>
        </div>
      </div>
    </ScrollAnimation>
  );
}
