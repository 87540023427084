import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addLessons,
  deleteChapterLesson,
  handleChapterChange,
  handleDeleteLesson,
  handleEditLesson,
} from "../../../ReduxToolkit/Slices/studentSlice";
import { handleAddLesson } from "../../../ReduxToolkit/Slices/studentSlice";
import {
  ImageComponent,
  Video,
} from "../../../TeacherApp/Components/Courses/EditorPageRow/EditorPageRowComponents";
import { EditLecutre } from "../../../TeacherApp/Components/Courses/CurriculumRow/CurriculumRow";
import AddVideo from "../../../TeacherApp/Components/Courses/AddVideo/AddVideo";
import AddText from "../../../TeacherApp/Components/Courses/AddText/AddText";
import DOMPurify from "dompurify";

import note from "../../../assets/TeacherApp/assets/svgs/note.svg";
import Write from "../../../assets/TeacherApp/assets/svgs/Write.svg";
import Icons from "../../../assets/TeacherApp/assets/svgs/Icons.svg";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";
import dots from "../../../assets/AdminApp/svgs/Dots.svg";
import viewImage from "../../../assets/AdminApp/svgs/view.svg";
import hide from "../../../assets/TeacherApp/assets/svgs/hide.svg";
import editImage from "../../../assets/SharedApp/svgs/Button Edit.svg";
import arrow from "../../../assets/TeacherApp/assets/svgs/Arrow-right.svg";

export default function AddCurriculum({ given, edit }) {
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState(0);
  const { chapters, lessonId } = useSelector((state) => state.student);
  const navigate = useNavigate();
  const isNotEmpty = (chapter) => {
    return chapter.every((obj) => {
      let newObj = {
        title: obj.title,
      };
      return Object.values(newObj).every((val) => {
        if (Array.isArray(val)) {
          return val.length > 0;
        } else return val !== "";
      });
    });
  };

  async function addLesson(chapterId, lesson) {
    return dispatch(
      addLessons({
        chapterId,
        videos: lesson.videos,
        title: lesson.title,
        description: lesson.description,
      })
    );
  }
  async function updateLesson(chapterId, lesson) {
    return dispatch(
      updateChapterLesson({
        id: lesson.id,
        chapter: {
          chapterId,
          title: lesson.title,
          description: lesson.description,

          videos: lesson.videos,
        },
      })
    );
  }
  const onEndSubmit = () => {
    toast.success("Courses chapter has been submitted. Thank you!");
  };
  const handleSubmit = async () => {
    if (chapters.length < 2) {
      return toast.error(
        "You need to have at least 1 section in each homework"
      );
    }
    let array = [];
    chapters.map((chapter) => {
      if (!chapter.title) {
        return array.push(false);
      }
      array.push(isNotEmpty(chapter.lessons));
    });
    if (array.includes(false)) {
      return toast.error("You must fill in all values");
    } else {
      for (const chapter of chapters) {
        if (chapter.id) {
          const res = await dispatch(
            updateChapter({
              id: chapter.id,
              chapter: {
                title: chapter.title,
              },
            })
          );
          if (res.type === "updateChapter/fulfilled") {
            for (const lesson of chapter.lessons) {
              if (lesson.id) {
                await updateLesson(chapter.id, lesson);
              } else {
                await addLesson(res.payload.data.id, lesson);
              }
            }
          }
        } else {
          const res = await dispatch(
            createAChapter({
              lessonId,
              title: chapter.title,
            })
          );
          for (const lesson of chapter.lessons) {
            await addLesson(res.payload.data.id, lesson);
          }
        }
      }
      onEndSubmit();
    }
  };
  const onEditTitle = (name, index, value) => {
    dispatch(
      handleChapterChange({
        name,
        value,
        index,
      })
    );
  };
  return (
    <div className="add__course__curr">
      <h1 className="dash__header">Homework</h1>
      {chapters.map((chapter, i) => (
        <Chapter
          key={i}
          onEditTitle={onEditTitle}
          chapter={chapter}
          i={i}
          setEditIndex={setEditIndex}
          editIndex={editIndex}
        />
      ))}
      <div className="curric__btns" style={{ justifyContent: "flex-end" }}>
        <button className="next__btn" onClick={() => handleSubmit()}>
          Submit <img src={arrow} alt="" />
        </button>
      </div>
    </div>
  );
}

function Chapter({ chapter, onEditTitle, i, editIndex, setEditIndex }) {
  const [edit, setEdit] = useState(false);
  // const onDelete = () => {
  //   dispatch(handleDeleteChapter({ index: i }));
  //   dispatch(deleteChapterLesson(id));
  //   setModal(false);
  // };

  return (
    <div className="cont">
      <div className="manage__curr">
        {!edit ? (
          <h2 className="flex_center">
            {chapter.title}{" "}
            <img
              alt=""
              src={editImage}
              onClick={() => setEdit(true)}
              className="pointer"
            />
          </h2>
        ) : (
          <EditLecutre
            value={chapter.title}
            // setValue={setValue}
            setValue={(value) => onEditTitle("title", i, value)}
            setEdit={() => setEdit(false)}
          />
        )}
        <p>Add your Homework</p>
      </div>
      <EditCourse index={i} />
      <div className="rows">
        <EditorPage index={i} />
      </div>
    </div>
  );
}

function EditCourse({ index }) {
  const lessonInitial = {
    title: "",
    videos: [
      {
        url: "",
        publicId: "",
        videoLength: 0,
      },
    ],
    description: "",
  };

  const [selected, setSelected] = useState("Add Video");
  const [lesson, setLesson] = useState(lessonInitial);

  const dispatch = useDispatch();

  const handleLessonChange = (e) => {
    const { name, value } = e.target;
    setLesson({ ...lesson, [name]: value });
  };
  const handleAddClick = () => {
    if (!lesson.title) {
      return toast.error("Please add a title to your lecture");
    }
    if (!lesson.videos[0].url && !lesson.description) {
      return toast.error("Please add a lecture materials");
    } else {
      selected === "Add Code Example"
        ? dispatch(
            handleAddLesson({
              name: "lessons",
              value: {
                ...lesson,
                description: `codeHereToBeAdded${lesson.description}`,
                videos: [{ publicId: "string", url: "string" }],
              },
              index,
            })
          )
        : selected === "Add Video" ||
          selected === "Add Image" ||
          selected === "Add File"
        ? dispatch(
            handleAddLesson({
              name: "lessons",
              value: lesson,
              index,
            })
          )
        : dispatch(
            handleAddLesson({
              name: "lessons",
              value: {
                ...lesson,
                videos: [{ publicId: "string", url: "string" }],
              },
              index,
            })
          );
      setLesson(lessonInitial);
    }
  };
  const handleSelected = (value) => {
    if (value !== selected) {
      setSelected(value);
      setLesson({ ...lessonInitial, title: lesson.title });
    }
  };

  return (
    <div className="Edit__courses__section">
      <EditPanel setSelected={handleSelected} selected={selected} />
      <div className="Editors">
        <div className="Custom_Inputs">
          <div className="input">
            <span>Homework section title</span>
            <input
              type="text"
              placeholder="title"
              name="title"
              value={lesson.title}
              onChange={(e) => handleLessonChange(e)}
            />
          </div>
        </div>
        {selected === "Add Video" ? (
          <AddVideo
            lesson={lesson}
            setLesson={setLesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
          />
        ) : selected === "Add Notes" ? (
          <AddText lesson={lesson} setLesson={setLesson} />
        ) : selected === "Add Image" ? (
          <AddVideo
            lesson={lesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
            image
          />
        ) : selected === "Add File" ? (
          <AddVideo
            lesson={lesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
            file
          />
        ) : null}
        {/* {selected === "Editor Page" && <EditorPage />} */}
        {/* {selected === "Add File" && <AddFiles />} */}
        {selected === "Add Quiz" && <AddQuiz />}

        <button
          className="btn_send flex_center"
          onClick={() => handleAddClick()}
        >
          Add Lecture
        </button>
      </div>
    </div>
  );
}

function EditPanel({ selected, setSelected }) {
  const Data = [
    {
      name: "Add Video",
      icon: Icons,
      link: "addVideo",
    },

    {
      name: "Add Notes",
      icon: Write,
      link: "addText",
    },
    // {
    //   name: "Add Code Example",
    //   icon: Layout2,
    //   link: "addCode",
    // },
    {
      name: "Add Image",
      icon: note,
      link: "addImage",
    },
  ];
  const location = useLocation();
  const className = (el) => (el.name === selected ? "row selected" : "row");
  return (
    <div className="course__edit__panel">
      <h1>Add Lectures</h1>
      <div className="action__rows">
        {Data.map((el, i) => (
          <button
            className={className(el)}
            key={i}
            onClick={() => setSelected(el.name)}
          >
            <img src={el.icon} alt="" />
            <span>{el.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

function EditorPage({ index }) {
  const { chapters } = useSelector((state) => state.student);
  return (
    <div className="course__editor__section">
      {chapters[index]?.lessons.length > 0 &&
        chapters[index].lessons.map((chapter, i) => (
          <EditorPageRow
            item={chapter}
            key={i}
            id={i}
            index={index}
            lessonId={chapter.id}
          />
        ))}
    </div>
  );
}
function EditorPageRow({ lessonId, item, id, index }) {
  const dispatch = useDispatch();
  const [lesson, setLesson] = useState(item);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);

  let sanitizedContent = DOMPurify.sanitize(lesson.description);

  const onDelete = () => {
    dispatch(
      handleDeleteLesson({
        index,
        subIndex: id,
      })
    );
    dispatch(deleteChapterLesson(lessonId));
  };

  const onEdit = (value, name, code) => {
    setLesson({ ...lesson, [name]: value });
  };
  const onSubmit = (value, name) => {
    dispatch(
      handleEditLesson({
        value: {
          ...item,
          [name]: value,
        },
        index,
        subIndex: id,
      })
    );
  };
  // const newCode = (text) => text.split("codeHereToBeAdded")[1];

  let type =
    lesson?.videos.length && lesson.videos[0].url.includes("pdf")
      ? "file"
      : lesson?.videos.length && lesson.videos[0].publicId.includes("videos")
      ? "video"
      : lesson?.videos.length && lesson.videos[0].publicId.includes("images")
      ? "image"
      : lesson?.videos.length && lesson.videos[0].url.includes("pdf")
      ? "file"
      : lesson.description && !lesson.description.includes("codeHereToBeAdded")
      ? "description"
      : "code";

  return (
    <div className="editor__row__container">
      <h3>{type}</h3>
      <div className="editor__row">
        <div className="col">
          <img src={dots} alt="" />
          {!edit ? (
            <span className="title flex_center">
              <span>{item.title}</span>
              <img
                alt=""
                src={editImage}
                onClick={() => setEdit(true)}
                className="pointer"
              />
            </span>
          ) : (
            <EditLecutre
              value={item.title}
              setValue={(value) => onSubmit(value, "title")}
              setEdit={() => setEdit(false)}
            />
          )}
        </div>
        <div className="col">
          <div className="actions">
            <img
              src={view ? hide : viewImage}
              alt=""
              onClick={() => setView(!view)}
            />
            <img src={del} alt="" onClick={() => onDelete()} />
          </div>
        </div>
      </div>
      {view && (
        <div>
          {type === "video" ? (
            <Video item={lesson.videos[0].url} />
          ) : type === "image" ? (
            <ImageComponent image={lesson.videos[0].url} />
          ) : type === "description" ? (
            <div className="edit__text">
              <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
          ) : type === "file" ? (
            // <a href={lesson.videos[0].url} target="blank">
            //   link
            // </a>
            <h1>this is a file</h1>
          ) : null}
        </div>
      )}
    </div>
  );
}
