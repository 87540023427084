import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { FileUploader } from "react-drag-drop-files";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  createUpdateBGCHECK,
  handleTeacherChange,
  setTeacherBackground,
  setTeacherGeoLocation,
  // uploadImage,
  // uploadImages,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";

import remove from "../../../assets/SharedApp/svgs/remove.svg";
// import upload from "../../../assets/TeacherApp/assets/svgs/upload.svg";
// import { updateTeacher } from "../../../ReduxToolkit/Slices/adminSlice";
// import SharedFileUploader from "../../../Components/SharedFileUploader/SharedFileUploader";
// import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
// import SharedSingleFileUploader from "../../../Components/SharedSingleFileUploader/SharedSingleFileUploader";
// import SharedMultipleFileUploader from "../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import BgCheckUploader from "../../../Components/BgCheckUploader/BgCheckUploader";
import Note from "../Note/Note";
import { addGeoLocation } from "../../../ReduxToolkit/Slices/userSlice";

function BgCheck({
  setGeoLoc,
  geoLoc,
  signUp,
  backgroundCheck,
  setBackgroundCheck,
}) {
  const fileTypes = ["JPEG", "PNG", "JPG", "PDF"];
  const dispatch = useDispatch();

  const { teacher } = useSelector((state) => state.teacher);

  const { background } = teacher;
  console.log("background", background);
  const [check, setCheck] = useState(true);

  const handleUpload = (value) => {
    if (signUp) {
      setBackgroundCheck({ status: "UNCHECKED", image: value });
    } else {
      dispatch(setTeacherBackground({ status: "UNCHECKED", image: value }));
      dispatch(
        createUpdateBGCHECK({
          status: "UNCHECKED",
          image: value,
          userId: teacher.user.id,
        })
      ).then((res) => {});
    }
  };

  const handleDeleteBgCheck = () => {
    dispatch(
      setTeacherBackground({
        status: "UNCHECKED",
        image: { url: "", publicId: "" },
      })
    );
  };

  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const [showModal, setShowModal] = useState(false);
  const [geoLocation, setGeoLocation] = useState(
    signUp
      ? geoLoc
      : teacher?.user?.geoLocation
      ? teacher?.user?.geoLocation
      : { latitude: 0, longitude: 0, address: "" }
  );
  const [location, setLocation] = useState(geoLocation.address);

  useEffect(() => {
    if (!teacher?.user?.geoLocation) {
      // If geoLocation is not available in teacher object, fetch user's current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // Set the retrieved geolocation
            setGeoLocation({ latitude, longitude, address: "" });
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, []);

  const setGeoLocations = (value) => {
    if (signUp) {
      return setGeoLoc({
        latitude: value.latitude,
        longitude: value.longitude,
        address: value.address,
      });
    } else {
      setGeoLocation(value);
      dispatch(
        setTeacherGeoLocation({
          latitude: value.latitude,
          longitude: value.longitude,
          address: value.address,
        })
      );

      dispatch(
        addGeoLocation({
          latitude: value.latitude,
          longitude: value.longitude,
          userId: teacher.user.id,
          address: value.address,
        })
      ).then((res) => {});
    }
  };

  return (
    <div className="bg__check__container">
      {!signUp && (
        <Note
          p1="Inorder to teach at student's place/your studio you need to have a background check."
          p2="You can submit a background check later but your profile will not show to students before the admin verifies your background check"
          title="Please Note"
        />
      )}
      <br />
      <h3>Background Check</h3>
      <p>
        Do you have any background, vulnerable sector or criminal police check
        to share with us?
      </p>
      <div className="radio" style={{ display: "flex", gap: "10px" }}>
        <div style={{ display: "flex" }}>
          <input
            type="radio"
            name="bg_check"
            defaultChecked={true}
            onChange={(e) => {
              setCheck(e.target.checked);
            }}
          />
          <span>Yes</span>
        </div>
        <div style={{ display: "flex" }}>
          <input
            type="radio"
            name="bg_check"
            onChange={(e) => setCheck(!e.target.checked)}
          />
          <span>No</span>
        </div>
      </div>
      {!signUp && (
        <div className="important">
          <h2>Please note!</h2>
          <p>You must upload or use our link if you teach in person</p>
        </div>
      )}
      {check ? (
        <BgCheckUploader
          onSelectFile={handleUpload}
          preview={signUp ? backgroundCheck?.image : background.image}
        />
      ) : (
        <div className="no__bgchck">
          <span>Please, follow this link:</span>{" "}
          <a
            href="https://pages.sterlingbackcheck.ca/landing-pages/e/educify/"
            target="__blank"
          >
            https://pages.sterlingbackcheck.ca/landing-pages/e/educify/
          </a>
        </div>
      )}
      <div onClick={() => setShowModal(true)} className="map_location_view">
        <h3>Enter your location</h3>
        <input
          type="text"
          placeholder="Enter location..."
          value={location}
          className={signUp ? "maxTravel_input" : ""}
        />
      </div>

      {showModal && (
        <MapModal
          location={location}
          setLocation={setLocation}
          geoLocation={signUp ? geoLoc : geoLocation}
          setGeoLocation={signUp ? setGeoLoc : setGeoLocations}
          setShowMapModal={setShowModal}
        />
      )}
    </div>
  );
}

export default BgCheck;

export const MapModal = ({
  setShowMapModal,
  location,
  setLocation,
  geoLocation,
  setGeoLocation,
}) => {
  const [value, setValue] = useState("");
  const [marker, setMarker] = useState(null); // State to hold the marker instance
  const [showMarker, setShowMarker] = useState(true); // State to hold the marker instance
  const [zoom, setZoom] = useState(12); // Initial zoom level

  useEffect(() => {
    // Create the map.
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom,
      center: {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      },
      mapTypeId: "terrain",
    });
    const newMarker = new window.google.maps.Marker({
      position: {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      },
      map: map,
      title: "New Marker",
    });
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
    setMarker(newMarker);
    map.addListener("click", (event) => {
      const latLng = event.latLng;
      setGeoLocation({
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      });
      // setBaseGeo({ latitude: latLng.lat(), longitude: latLng.lng() });
      map.setCenter({
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      });
    });

    const StoreLocation = new window.google.maps.Marker({
      position: {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      },
      map: map,
      title: "Store Place",
    });
  }, [showMarker, location, geoLocation]);

  const getGeoByAddress = (e) => {
    setValue(e);
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (!isNaN(lat) && !isNaN(lng)) {
          // Check if lat and lng are valid numbers
          setGeoLocation({
            latitude: lat,
            longitude: lng,
            address: e.label,
          });

          setLocation(e.label);
        } else {
          console.error("Invalid latitude or longitude:", lat, lng);
          // Handle the case where lat or lng is not a valid number
        }
      })
      .catch((error) => {
        console.error("Error getting geolocation:", error);
      });
  };

  return (
    <div className="map_modal" style={{ zIndex: 999 }}>
      <div className="map_container">
        <div className="info">
          <div className="header">
            <h1>Please write your studio/home address</h1>
            {/* <span onClick={() => setShowMapModal(false)}>X</span> */}
            <img
              src={remove}
              alt="close"
              className="close_map"
              onClick={() => setShowMapModal(false)}
            />
          </div>
          <GooglePlacesAutocomplete
            apiKey={import.meta.env.VITE_GOOGLEPLACE}
            selectProps={{
              value,
              onChange: getGeoByAddress,
            }}
          />
          <div className="search_location"></div>
        </div>
        <div className="mini">
          <div id="map"></div>
          <div className="map_btn">
            <button
              className="btn_send"
              onClick={() => {
                if (!value.label) {
                  return toast.error(
                    "Please type in location as you would like to show it on your profile"
                  );
                }
                setLocation(value.label);
                value.label && getGeoByAddress(value);
                setShowMapModal(false);
              }}
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
