import React from "react";

import "./wheretolearn.styles.scss";

import learn from "../../assets/HomeApp/images/learn.webp";
import wheretotlearn from "../../assets/HomeApp/images/wheretotlearn.webp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ScrollAnimation from "../../SharedApp/Pages/ScrollAnimation/ScrollAnimation";

import { data, text } from "../../assets/HomeApp/data/wheretolearn-data";
import { useNavigate } from "react-router-dom";

export default function WhereToLearn() {
  const navigate = useNavigate();
  return (
    <div className="wheretolearn__container">
      <div className="col1">
        <h2>{text.header}</h2>
        <p>{text.p}</p>
        <img src={wheretotlearn} alt="" />
      </div>
      <div className="col2">
        {data &&
          data.map((feature, i) => (
            <ScrollAnimation
              initialY={0}
              animateY={0}
              initialX={i % 2 == 0 ? 100 : -100}
              animateX={0}
              transition={1}
              delay={0.2 * i}
            >
              <div className="feature" key={i}>
                <img src={feature.image} alt="learning feature" />
                <h3>{feature.name}</h3>
                <p>{feature.text}</p>
              </div>
            </ScrollAnimation>
          ))}
        <button onClick={() => navigate("/tutors")}>
          {text.button}
          <ArrowRightAltIcon />
        </button>
      </div>
    </div>
  );
}
