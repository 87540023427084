import React from "react";

import tutor from "../../../assets/SharedApp/images/tutor.webp";
import "./StudentFullInfo.styles.scss";
import { Link } from "react-router-dom";
import notfound from "../../../assets/SharedApp/images/default.webp";

export default function StudentFullInfo({ student }) {
  return (
    student && (
      <div className="student__full__info">
        <img
          src={
            student.user?.profileImage?.url
              ? student.user?.profileImage?.url
              : notfound
          }
          alt=""
        />
        <h2>
          {student.user.name} {student.user.lastName}
        </h2>
        <span>Student</span>
        <div className="info__table">
          <div className="info__row">
            <span>Contact Phone</span>
            <p>{student.user.phone}</p>
          </div>
          <div className="info__row">
            <span>Email Address</span>
            <p>{student.user.email}</p>
          </div>
          <div className="info__row">
            <span>Address</span>
            <p>
              {student.user.address.street} {student.user.address.city}{" "}
              {student.user.address.country} {student.user.address.state}
            </p>
          </div>
        </div>
      </div>
    )
  );
}
