export const HeaderData = {
  header: {
    phrase:
      "With exceptional team of Tutors we offer the best Lesson to Students",
    highlight: "best",
  },
  paragraph: {
    phrase: "What do you want to Learn?",
  },
  placeholder: "What do you want to learn ?",
  moreDetails: [
    "language",
    "Examination",
    "Culinary",
    "Driving",
    "Life & Skills",
    "Health",
    "business",
  ],
};
