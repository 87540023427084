import React, { useEffect, useState } from "react";
import "./Note.styles.scss";
import warning from "../../../assets/TeacherApp/assets/svgs/Warning.svg";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";

export default function Note({
  title,
  p1,
  p2,
  p3,
  p4,
  p5,
  marginTops,
  a1,
  link,
  link2,
  switches,
}) {
  return (
    <div
      className="note"
      style={marginTops ? { marginTop: "20px" } : { marginTop: "inherit" }}
    >
      <img src={warning} alt="" />
      <div className="text">
        <div className="title_text">{title}</div>
        <p>{p1}</p>
        <p className="flex_center">
          {p2}
          {switches && (
            <Toggle
              defaultChecked={true}
              // onChange={() => console.log()}
              icons={false} // hide the default icons to use custom ones
            />
          )}
        </p>
        <p>{p3}</p>
        <p>{p4}</p>
        <p>{p5}</p>
        {a1 && link ? (
          <Link to={link}>{a1}</Link>
        ) : a1 && link2 ? (
          <a href={link2} target="_blank">
            {a1}{" "}
          </a>
        ) : null}
      </div>
    </div>
  );
}
