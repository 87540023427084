import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteStudent } from "../../../ReduxToolkit/Slices/studentSlice";
import ConfirmPassword from "../ConfirmPassword/ConfirmPassword";
import Note from "../Note/Note";
import "./DeleteAccount.styles.scss";

export default function DeleteAccount({ modal, setModal }) {
  const dispatch = useDispatch();

  const { student } = useSelector((state) => state.student);

  const [confirm, setConfirm] = useState(false);

  return (
    <div className="delete_account">
      <div className="delete__container">
        <div className="header">
          <h3>Delete Account</h3>
        </div>
        <Note
          title="You Are Deactivating Your Account"
          p1={
            "For extra security, this requires you to confirm your email or phone number when you request to deactivate"
          }
        />
        <div className="confirm__delete">
          <input
            type="checkbox"
            value={confirm}
            onChange={(e) => setConfirm(!confirm)}
          />
          <span>I confirm my account deactivation</span>
        </div>
        <div className="delete__btns">
          <button
            onClick={() => {
              if (!confirm) {
                alert("Please confirm");
              } else {
                setModal(true);
              }
            }}
          >
            Deactivate Account
          </button>
        </div>
      </div>
    </div>
  );
}
