import React, { useState, useMemo, useEffect } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
const localizer = momentLocalizer(moment);
import "./calendar.styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link, useNavigate } from "react-router-dom";
import notfound from "../../../assets/SharedApp/images/default.webp";

import {
  // completeAppointment,
  getStudentAppointmentsByStatus,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { useDispatch, useSelector } from "react-redux";

export default function StudentCalendar() {
  const [lessons, setLessons] = useState([]);
  const [selectedType, setSelectedType] = useState("Calendar");
  const { student } = useSelector((state) => state.student);
  const [myEvents, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // New state to store selected event
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getStudentAppointmentsByStatus({
        studentId: student,
        filters: { limit: 35, status: "ACCEPTED" },
      })
    ).then((Res) => {
      const formatAppointments = Res?.payload?.data?.studentAppointments.map(
        (appt, i) => {
          const startDate = new Date(appt.date);
          const startTime = new Date(appt.startTime);
          const endTime = new Date(appt.endTime);

          return {
            id: i + 1,
            title: `${appt?.booking?.lesson?.title} lesson with ${appt?.teacher?.user?.name} ${appt?.teacher?.user?.lastName}`,
            start: new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              startTime.getHours(),
              startTime.getMinutes()
            ),
            end: new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              endTime.getHours(),
              endTime.getMinutes()
            ),
            // allDay: true,
            desc: `Status: ${appt.status}`,
            booking: appt?.booking,
            teacher: appt?.teacher,
            student: appt?.booking?.student,
          };
        }
      );

      setLessons(Res?.payload?.data?.studentAppointments);
      setEvents(formatAppointments);
    });
  }, []);
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const viewLocation = (geoLocation) => {
    if (geoLocation) {
      const { latitude, longitude } = geoLocation;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(googleMapsUrl, "_blank");
    }
  };
  const handleActionClick = (event) => {
    if (event.booking.location === "online") {
      // Open the lesson room for online events
      const lessonRoomUrl = `#/lessonRoom/${event.teacher.id}/${event.booking.studentId}/${event.booking.lesson.title}`;
      window.open(lessonRoomUrl, "blank");
    } else {
      // View the location on Google Maps for in-person events
      if (event.booking.location === "teacherPlace")
        viewLocation(event?.teacher?.user?.geoLocation);
      else {
        viewLocation(event?.student?.user?.geoLocation);
      }
    }
  };

  return (
    <div className="teacher_calendar_container_outer">
      <div className="calendar_header">
        <div style={{ display: "flex" }}>
          <h1>Schedule</h1>
          <div className="calendar_switch">
            {["Calendar"].map((el, i) => (
              <span
                onClick={() => setSelectedType(el)}
                key={i}
                className={`${selectedType === el ? "selected" : ""}`}
              >
                {el}
              </span>
            ))}
          </div>
        </div>

        {/* <EditAvailabilityToggle /> */}
      </div>
      <div className="teacher_calendar_container">
        <div className="calendar_cont">
          {/* <h1>hii</h1> */}
          {selectedType === "Calendar" ? (
            <div className="calendar_cont_inner">
              <Calendar
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                events={myEvents}
                className="teacher_calendar shared_big_calendar"
                views={["day", "week", "month"]}
                localizer={localizer}
                // onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                // selectable
                timeslots={1}
                onSelectEvent={handleSelectEvent} // Attach the handler to open the modal
                scrollToTime={scrollToTime}
              />
            </div>
          ) : (
            ""
            // <StudentsTable bookingsTodo={bookingsTodo} />
          )}
        </div>
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          event={selectedEvent}
          handleActionClick={handleActionClick}
        />
      </div>
    </div>
  );
}
const CustomModal = ({ isOpen, onRequestClose, event, handleActionClick }) => {
  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <div className={`student_calendar_modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <h2>Event Information</h2>
        {event && (
          <ul>
            <li>
              <strong>Title:</strong> {event.title}
            </li>
            <li>
              <strong>Lesson:</strong> {event?.booking?.lesson?.title}
            </li>
            <li>
              <strong>Start Time:</strong>{" "}
              {new Date(event.start).toLocaleTimeString()}
            </li>
            <li>
              <strong>End Time:</strong>{" "}
              {new Date(event.end).toLocaleTimeString()}
            </li>
            <li>
              <strong>Status:</strong> {event.desc.split(":")[1].trim()}
            </li>
            <li>
              <strong>Location:</strong> {event.booking.location}
            </li>
            <li>
              <strong>Start Date:</strong> {formatDate(event.start)}
            </li>
            <li>
              <strong>End Date:</strong> {formatDate(event.end)}
            </li>
          </ul>
        )}
        <div className="buttons_container">
          <button onClick={onRequestClose}>Close</button>
          <button onClick={() => handleActionClick(event)}>Go to lesson</button>
        </div>
      </div>
    </div>
  );
};
// const StudentsTable = ({ bookingsTodo }) => {
//
//   return (
//     <div>
//       {bookingsTodo.length > 0 &&
//         bookingsTodo.map((booking, index) => (
//           <div className={`manage__students`} key={index}>
//             <div className="header">
//               <div className="header__text">
//                 <span>{booking?.title}</span>
//               </div>
//             </div>
//             <div className="students__table">
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Students</th>
//                     <th>Date</th>
//                     <th>STATUS</th>
//                     <th>ACTION</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {booking?.bookings?.length > 0 &&
//                     booking.bookings.map((row, i) => (
//                       <StudentsRow
//                         row={row}
//                         key={i}
//                         i={i}
//                         lessonId={booking.id}
//                         assign={true}
//                         title={booking?.title}
//                       />
//                     ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         ))}
//     </div>
//   );
// };

// const StudentsRow = ({ i, row, title, lessonId }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const students = [
//     { name: "Adnan Sayed", status: "Attended" },
//     { name: "Melhem dirani", status: "Missing" },
//     { name: "soimethi nwel", status: "Sick" },
//   ];
//
//   return (
//     <tr>
//       {isModalOpen && (
//         <AttendanceModal
//           students={[row.student]}
//           student={row.student}
//           closeModal={closeModal}
//           Appointments={row.Appointments}
//           title={title}
//           lessonId={lessonId}
//         />
//       )}
//       <td>
//         <Link className="student__cell">
//           <img
//             src={
//               row?.student?.user?.profileImage?.url
//                 ? row?.student?.user?.profileImage?.url
//                 : notfound
//             }
//             alt=""
//           />
//           <div className="name">
//             <span>
//               {row?.student?.user?.name} {row?.student?.user?.lastName}
//             </span>
//             {/* <p>{i % 2 == 0 ? "Private" : "Group"}</p> */}
//           </div>
//         </Link>
//       </td>
//       <td>
//         <span>Email.com</span>
//       </td>
//       <td>
//         <span>{"Student"}</span>
//       </td>
//       {/* <td>
//         <div className="manage__std__lesson">
//           <div className="lesson__date">{5}</div>
//         </div>
//       </td> */}
//       {/* {!assign && row?.bookings && (
//         <td>
//           {row.bookings.length > 0 ? (
//             <button
//               className={row?.bookings[
//                 row.bookings.length - 1
//               ].status.toLowerCase()}
//             >
//               {row?.bookings[row?.bookings?.length - 1].status}
//             </button>
//           ) : (
//             <button></button>
//           )}
//         </td>
//       )} */}
//       <td>
//         <div className="">
//           <button className="accepted" onClick={openModal}>
//             Take Attendance
//           </button>
//           {/* <div className="students_attendance">
//             {students.map((el) => (
//               <div className="attendance_row">
//                 <span className="att_std_name">{el.name}</span>
//                 <div className="std_opions">
//                   <div className="att_op">
//                     <span>Attended</span>
//                     <input type="radio" name="std_att" id="" />
//                   </div>
//                   <div className="att_op">
//                     <span>Absent</span>
//                     <input type="radio" name="std_att" id="" />
//                   </div>
//                   <div className="att_op">
//                     <span>Seomthing else</span>
//                     <input type="radio" name="std_att" id="" />
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div> */}
//         </div>
//       </td>
//     </tr>
//   );
// };
// const AttendanceModal = ({
//   students,
//   closeModal,
//   Appointments,
//   student,
//   lessonId,
//   title,
// }) => {
//   const [newAppointments, setNewAppointments] = useState(Appointments);

//   const handleStatusChange = (index, newStatus, id) => {
//     setNewAppointments((prevList) => {
//       const newList = [...prevList];
//       newList[index].status = newStatus;
//       newList[index].id = id;
//       return newList;
//     });
//   };
//   const dispatch = useDispatch();

//   const handleSaveAttendance = () => {
//     // Handle saving attendance logic here
//     newAppointments &&
//       newAppointments.map((app) => {
//         dispatch(completeAppointment({ id: app.id, status: app.status })).then(
//           (res) =>
//         );
//       });
//     closeModal();
//   };

//   return (
//     <div className="attendance-modal">
//       <div className="modal-content">
//         <div className="modal-header">
//           <h2>Take Attendance</h2>
//           <button onClick={closeModal}>Close</button>
//         </div>
//         <div className="modal-body">
//           {/* <p>Lesson:</p>
//           <h4>{title}</h4> */}
//           <table>
//             <thead>
//               <tr>
//                 <th> Date</th>
//                 <th>Attendance Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {newAppointments &&
//                 newAppointments
//                   .sort((a, b) => new Date(a.date) - new Date(b.date))
//                   .map((app, index) => (
//                     <tr key={index}>
//                       <td>{moment(app.date).format("DD/MM/YYYY")}</td>
//                       <td>
//                         <div className="radio-buttons">
//                           <label>
//                             <input
//                               type="radio"
//                               value="COMPLETED"
//                               checked={app.status === "COMPLETED"}
//                               onChange={() =>
//                                 handleStatusChange(index, "COMPLETED", app.id)
//                               }
//                             />
//                             Present
//                           </label>
//                           <label>
//                             <input
//                               type="radio"
//                               value="REJECTED"
//                               checked={app.status === "REJECTED"}
//                               onChange={() =>
//                                 handleStatusChange(index, "REJECTED", app.id)
//                               }
//                             />
//                             Missing
//                           </label>
//                           <label>
//                             <input
//                               type="radio"
//                               value="CANCELLED"
//                               checked={app.status === "CANCELLED"}
//                               onChange={() =>
//                                 handleStatusChange(index, "CANCELLED", app.id)
//                               }
//                             />
//                             Cancelled
//                           </label>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//             </tbody>
//           </table>
//         </div>
//         <div className="modal-footer">
//           <button onClick={() => handleSaveAttendance()}>
//             Save Attendance
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
