import React, { useEffect } from "react";
import "./CategoryPage.styles.scss";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import HorizontalCourseCard from "../../../../StudentApp/Components/Courses/HorizontalCourseCard/HorizontalCourseCard";
import BreadCrumb from "../../../Components/Courses/BreadCrumb/BreadCrumb";

import closebtn from "../../../../assets/SharedApp/svgs/closepanel.svg";

import Pagination from "../../../../Components/Pagination/Pagination";
import CoursesSwitch from "../../../Components/Courses/CoursesSwitch/CoursesSwitch";
import AlsoLearn from "../../../Components/Courses/AlsoLearn/AlsoLearn";
import AsideItem from "../../../Components/Courses/AsideItem/AsideItem";
import CategoryTrack from "../../../Components/Courses/CategoryTrack/CategoryTrack";

import filterImg from "../../../../assets/SharedApp/svgs/filters.svg";

import stars from "../../../../assets/TeacherApp/assets/svgs/rating.svg";
import { useDispatch } from "react-redux";
import {
  getCourseCategories,
  getCourseSubjectsByCategory,
  getStudentCourses,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import Filter from "./Filter";

export default function CategoryPage({ searching, all }) {
  const { category, search } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pages, setPages] = useState(0);
  const [totalCourses, setTotalCourses] = useState(0);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [filters, setFilters] = useState({
    category: searching || all ? "" : category,
    rating: "",
    maxPrice: "",
    search: searching ? search : "",
    sortField: "",
    limit: 10,
    page: currentPage,
  });
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: currentPage,
    }));
  }, [currentPage]);

  useEffect(() => {
    dispatch(getCourseCategories()).then((res) => {
      setCategories(res.payload.data);
    });
  }, []);
  useEffect(() => {
    categories.length &&
      dispatch(
        getCourseSubjectsByCategory(
          filters.category ? filters.category : categories[0].name
        )
      ).then((res) => {
        res.payload !== "Course category not found" && setSubjects(res.payload);
      });
  }, [categories, filters.category]);
  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value;
      value = filters[keyName];
      if (keyName === "maxPrice") {
        value = filters[keyName] * 100;
        if (value <= 0) value = "";
      }

      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    dispatch(getStudentCourses(newFilters)).then((res) => {
      setCourses(res.payload.courses);
      setPages(res.payload.numOfPages);
      setTotalCourses(res.payload.totalCourses);
    });
  }, [filters]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "maxPrice") {
      setFilters({
        ...filters,
        [name]: value,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
      if (name === "category") {
        if (value === "")
          return setFilters({
            ...filters,
            [name]: value,
          });
        return navigate(`/courses/categories/${value}`);
      }
    }
  };

  const data = [
    {
      title: "Rating",
      type: "radio",
      name: "rating",
      value: filters.rating,
      image: stars,
      options: ["4.5", "4.0", "3.5", "3.0"],
    },
    {
      title: "Max Price",
      name: "maxPrice",
      value: filters.maxPrice,
      type: "number",
    },
    {
      title: "Search",
      name: "search",
      value: filters.search,
      type: "text",
    },
    {
      title: "Category",
      name: "category",
      value: filters.category,
      type: "select",
      options: categories,
    },
    {
      title: "Subject",
      name: "subject",
      value: filters.subject,
      type: "select",
      options: subjects,
    },
    // {
    //   title: "Video Duration",
    //   type: "checkbox",
    //   options: [
    //     "0-1 Hour (72)",
    //     "1-3 Hours (228)",
    //     "3-6 Hours (195)",
    //     "6-17 Hours (253)",
    //     "17+ Hours (171)",
    //   ],
    // },
    // {
    //   title: "Topic",
    //   type: "checkbox",
    //   options: ["HTML (30)", "CSS", "PHP", "JavaScript", "Web Design"],
    // },
    // {
    //   title: "Sub Category",
    //   type: "checkbox",
    //   options: [
    //     "Web Development",
    //     "Programming Language",
    //     "IT Certification (456)",
    //     "Digital Marketing (234)",
    //     "Career Development (765)",
    //   ],
    // },
    // {
    //   title: "Language",
    //   type: "checkbox",
    //   options: ["English", "Espanol", "French", "Portuguese"],
    // },
  ];
  // const categories = ["Most Popular", "New", "Beginner Favorite"];

  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <FiltersMobile
        data={data}
        handleChange={handleChange}
        courses={courses}
        customClass={showFilter ? "active" : ""}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <div className="cousre__category__page">
        <div className="page__container">
          <BreadCrumb title={category} name="course" name2={category} />
          <div className="course__category__container">
            <h1>
              <span>{category}</span> <span>Courses</span>{" "}
            </h1>
            {/* <CategoryTrack
            selected={selected}
            setSelected={setSelected}
            categories={categories}
            color={"blue"}
          /> */}
            {/* <CoursesSwitch />
          <AlsoLearn /> */}
            {/* <CoursesSwitch header={"Top courses in Web Development and Python"} />
          <CoursesSwitch header={"Top courses in Web Development and PHP"} /> */}
            <div className="filter__online__courses">
              <div className="filter-sm"></div>
              <div className="sort">
                <img
                  src={filterImg}
                  onClick={() => setShowFilter(!showFilter)}
                />
                {/* <span>Sort by:</span>
                <select>
                  <option value={"new"}>Newest</option>
                  <option value={"Most Popular"}>Most Popular</option>
                  <option value={"Highest Rated"}>Highest Rated</option>
                </select> */}
              </div>

              <Filter
                data={data}
                courses={courses}
                handleChange={handleChange}
              />
              <Pagination
                page={pages}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                dataRendered={courses?.length}
                totalData={totalCourses}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const FiltersMobile = ({
  data,
  courses,
  handleChange,
  customClass,
  showFilter,
  setShowFilter,
}) => {
  return (
    <div className={`mobile__filters ${customClass ? customClass : ""}`}>
      <img src={closebtn} alt="" onClick={() => setShowFilter(!showFilter)} />
      <Filter
        customClass={"filters__sm"}
        data={data}
        courses={courses}
        handleChange={handleChange}
      />
    </div>
  );
};
