import React from "react";
import teacherPill from "../../../assets/TeacherApp/assets/images/teacher-pill.png";
import teacherFile from "../../../assets/TeacherApp/assets/images/teacher-file.png";
import teacherHand from "../../../assets/TeacherApp/assets/images/teacher-handshake.png";
import teacherGroup from "../../../assets/TeacherApp/assets/images/teacher-group.png";
const WhyEducify = () => {
  const emblems = [
    {
      image: teacherFile,
      title: "High Earning Potential",
      text: "Connect with a global pool of students to monetize your expertise",
    },
    {
      image: teacherGroup,
      title: "Impactful Teaching",
      text: "Empower students through personalized learning sessions and training resources",
    },
    {
      image: teacherHand,
      title: "24/7 support",
      text: "We never leave you hanging. Our support team is only one call away",
    },
    {
      image: teacherPill,
      title: "Global Reach",
      text: "Expand your reach to diverse students worldwide through our online platform",
    },
    {
      image: teacherFile,
      title: "Professional Development",
      text: "Access training resources and networking opportunities for skill enhancement",
    },
    {
      image: teacherGroup,
      title: "Streamlined Administration",
      text: "Automate scheduling, billing, and communication processes for efficiency",
    },
    {
      image: teacherHand,
      title: "Community and Support",
      text: "Join a supportive community of tutors for collaboration and growth",
    },
  ];
  return (
    <div className="why_educify">
      <header>
        <h1>
          Why choose <span className="gold_text">Educify?</span>
        </h1>
      </header>
      <div className="emblems">
        {emblems.map((el, i) => (
          <Emblem {...el} key={i} />
        ))}
      </div>
    </div>
  );
};

const Emblem = ({ image, title, text }) => {
  return (
    <div className="teacher_emblem">
      <img src={image} alt="" />
      <h1>{title}</h1>
      <span>{text}</span>
    </div>
  );
};
export default WhyEducify;
