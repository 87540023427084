import React, { useEffect } from "react";
import { useState } from "react";
import "./TutorPage.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import img from "../../../assets/SharedApp/images/tutor.webp";

import Reviews from "../../Components/Reviews/Reviews";
import Newsletter from "../../../Sections/NewsLetter/Newsletter";

import SimilarTeacherSlider from "../../Components/SimilarTeacherSlider/SimilarTeacherSlider";
import TeacherMediaSection from "../../Components/TeacherMediaSection/TeacherMediaSection";
import BigContainer from "../../Components/TeacherPageBody/BigContainer";
import { getTeacherLessons } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { getTeacherByIdWithoutSave } from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  addRecentlyViewed,
  getLessonList,
  getRecentlyViewed,
  getSimilarTeachers,
} from "../../../ReduxToolkit/Slices/studentSlice";

export default function TeacherPage() {
  const { logout, user } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { id, subject } = useParams();
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [similarTeachers, setSimilarTeachers] = useState([]);
  useEffect(() => {
    dispatch(getTeacherByIdWithoutSave(id))
      .then((res) => {
        if (res.type.includes("fulfilled")) {
          setTeacher(res.payload);
          if (res.payload?.lessons?.length) {
            setLessons(res.payload.lessons);
          }
        }
      })
      .catch((Err) => console.log(Err));

    !logout &&
      user.role === "STUDENT" &&
      dispatch(getRecentlyViewed(student)).then((res) => {
        setRecentlyViewed(res.payload);
        //
      });
  }, [id]);

  useEffect(() => {
    !logout &&
      teacher?.id &&
      student &&
      dispatch(
        addRecentlyViewed({
          studentId: student,
          teacherId: teacher.id,
        })
      );
    teacher?.id &&
      dispatch(getSimilarTeachers(teacher.id)).then((res) =>
        setSimilarTeachers(res.payload)
      );
  }, [teacher]);

  useEffect(() => {
    if (teacher) {
      const email = user?.email || "guest@example.com"; // Replace with actual email logic
      const productID = teacher.id;

      // Add Criteo Product Tag
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `
        window.criteo_q = window.criteo_q || [];
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        window.criteo_q.push(
          { event: "setAccount", account: 112050 },
          { event: "setEmail", email: "${email}" },
          { event: "setSiteType", type: deviceType },
          { event: "viewItem", item: "${productID}" }
        );
      `;
      document.head.appendChild(script);
    }
  }, [teacher]);

  return (
    <div className="tutor__page">
      {teacher && (
        <div className="page__container">
          {similarTeachers.length > 0 && (
            <SimilarTeacherSlider
              data={similarTeachers}
              title="Similar teachers"
            />
          )}
          <BigContainer
            videos={teacher?.videos[0]}
            images={teacher?.images}
            teacher={teacher}
            lessons={lessons}
          />
          <div id="test">
            {!logout && recentlyViewed && recentlyViewed.length > 0 && (
              <SimilarTeacherSlider
                recentlyViewed
                data={recentlyViewed}
                title="Recently viewed"
              />
            )}
          </div>
        </div>
      )}

      {/* <Newsletter /> */}
    </div>
  );
}
