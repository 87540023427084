import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import edit from "../../../assets/SharedApp/svgs/edit.svg";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import { uploadImage } from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  createSubject,
  getCategories,
  getSubjectByName,
  updateSubject,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import {
  createCourseSubject,
  getCourseCategories,
  getCourseSubjects,
  getCourseSubjectsbyName,
  updateCourseSubject,
} from "../../../ReduxToolkit/Slices/coursesSlice";
import SingleImageUploader from "../../../TeacherApp/Components/SingleImageUploader/SingleImageUploader";

export default function NewSubject({ createNew, courses }) {
  const [imageLoading, setImageLoading] = useState(false);
  const [preview, setPreview] = useState();
  const [active, setActive] = useState(true);
  const [subject, setSubject] = useState({});
  const [categories, setCategories] = useState([]);
  const [newImg, setNewImg] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [name, setName] = useState(subject.name ? subject.name : "");
  const [courseImg, setCourseImg] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { id } = params;

  const onSelectFile = (e) => {
    setImageLoading(true);
    handleImageUpload(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    Promise.resolve(dispatch(uploadImage(form))).then((value) => {
      setNewImg(value.payload);
      setImageLoading(false);
    });
  };

  useEffect(() => {
    if (courses) setPreview(courseImg.url);
  }, [courseImg?.url]);

  useEffect(() => {
    if (courses) {
      if (!createNew) {
        dispatch(getCourseSubjectsbyName(id)).then((res) => {
          setCourseImg(res.payload.image);
          setSubject({ ...res.payload, images: [res.payload.image] });
          setName(res.payload.name);
          setNewImg(res.payload.image);
          setActive(res.payload.status === "ACTIVE");
          setSelectedCategory(res.payload.categories[0].name);
        });
      }
      dispatch(getCourseCategories()).then((res) => {
        setCategories(res.payload.data);
      });
    } else {
      if (!createNew) {
        dispatch(getSubjectByName(id))
          .then((res) => {
            setSubject(res.payload.data);
            setName(res.payload.data.name);
            setActive(res.payload.data.status === "ACTIVE");
            setSelectedCategory(res.payload.data.categories[0].name);
            setPreview(res.payload.data.images[0].url);
          })
          .catch((err) => console.log("error", err));
      }
      dispatch(getCategories({ limit: "1000" })).then((res) => {
        setCategories(res?.payload?.data?.categories);
      });
    }
  }, [params]);

  const createNewCourseSubject = () => {
    let newSubject = {
      name: name,
      status: active ? "ACTIVE" : "INACTIVE",
      image: newImg.url ? newImg : courseImg,
      categoryName: selectedCategory ? selectedCategory : "",
      featured: true,
    };
    dispatch(createCourseSubject(newSubject)).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Subject was created Successfully");
        navigate(-1);
      } else {
        toast.error("Something went wrong.");
      }
    });
  };

  const handleSubjectUpdate = () => {
    if (!selectedCategory) {
      toast.error("You need to select a category");
    } else {
      if (courses) {
        dispatch(
          updateCourseSubject({
            id,
            subject: {
              ...subject,
              name: name,
              image: newImg,
              categoryName: selectedCategory ? selectedCategory : "",
              status: active ? "ACTIVE" : "INACTIVE",
              featured: false,
            },
          })
        ).then((res) => {
          if (res.type.includes("fulfilled")) {
            toast.success(`${subject.name} was updated successfully`);
          } else {
            toast.error("Something went Wrong");
          }
        });
      } else {
        let newSubject = {
          ...subject,
          name: name,
          images: newImg?.url
            ? [{ ...newImg, id: subject.images[0].id }]
            : subject?.images,
          categoryName: selectedCategory ? selectedCategory : "",
          status: active ? "ACTIVE" : "INACTIVE",
        };
        dispatch(updateSubject({ id, subject: newSubject })).then((res) => {
          if (res.type.includes("fulfilled")) {
            toast.success(`${subject.name} was updated successfully`);
          } else {
            toast.error("Something went Wrong");
          }
        });
      }
    }
  };
  const handleCreateSubject = () => {
    if (!name || !selectedCategory || !newImg.url) {
      toast.error("You need to fill all the fields");
    } else {
      if (courses) {
        return createNewCourseSubject();
      } else {
        let newSubject = {
          ...subject,
          name: name,
          images: subject?.images?.length ? subject?.images : [newImg],
          categoryName: selectedCategory ? selectedCategory : "",
          status: active ? "ACTIVE" : "INACTIVE",
        };
        dispatch(createSubject(newSubject)).then((res) => {
          if (res.type.includes("rejected")) {
            toast.error("Error");
          } else {
            toast.success("Subject Created Successfully");
            navigate(-1);
          }
        });
      }
    }
  };

  return (
    <>
      <div className="create__subject">
        <div className="header">
          {createNew ? <h1>Create A Subject</h1> : <h1>Edit Subject</h1>}
          <p>Setup lesson subject</p>
        </div>
        <div className="create__category__row">
          <span>Upload Image</span>
          {/* <div className="image__selector">
            <div className="upload">
              <div className="image">
                <div className="image-upload">
                  <label htmlFor="file-input">
                    {imageLoading ? (
                      <div className="spinner" />
                    ) : !preview ? (
                      <img
                        src={
                          subject.images && subject.images.length
                            ? subject.images[0].url
                            : volicity
                        }
                        alt=""
                        className={`${
                          subject.images && subject.images.length
                            ? "img__btn__with__image"
                            : "img__btn"
                        }`}
                      />
                    ) : (
                      <img src={preview} alt="" className="coverImage" />
                    )}
                  </label>
                  <input id="file-input" type="file" onChange={onSelectFile} />
                </div>
                <img src={remove} alt="" className="remove action" />
                <img src={edit} alt="" className="edit action" />
              </div>
              <p>Allowed file types: png, jpg, jpeg.</p>
            </div>
          </div> */}
          <SingleImageUploader
            imageLoading={imageLoading}
            preview={preview}
            onSelectFile={onSelectFile}
          />
        </div>
        <div className="create__category__row">
          <span>Category</span>
          <select
            name="categories"
            onChange={(e) => setSelectedCategory(e.target.value)}
            defaultValue={selectedCategory}
            value={selectedCategory}
          >
            <option value={""}>Select A Category</option>
            {categories &&
              categories.map((cat) => (
                <option key={cat.id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
          </select>
        </div>
        <div className="create__category__row">
          <span>Name</span>
          <input
            type="text"
            placeholder="Category Name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {!createNew && (
          <div className="create__category__row">
            <span>Change Status</span>
            <div className="save__card">
              <input
                type="checkbox"
                id="switch"
                class="checkbox"
                // defaultChecked={active}
                checked={active}
                onChange={() => {
                  setActive(!active);
                }}
              />
              <label for="switch" class="toggle"></label>
            </div>
          </div>
        )}
        <div className="create__cat__btns">
          <div className="cat__buttons">
            <button className="discard" onClick={() => navigate(-1)}>
              Discard
            </button>
            <button
              className="save__btn"
              onClick={() =>
                createNew ? handleCreateSubject() : handleSubjectUpdate()
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {/* <ManageLessonPrices /> */}
    </>
  );
}
