import React from "react";

import HorizontalCourseCard from "../../../../StudentApp/Components/Courses/HorizontalCourseCard/HorizontalCourseCard";

export default function CoursesContainer({
  title,
  type,
  courses,
  refresh,
  setRefresh,
}) {
  return (
    <div className="bought__together">
      <span>{title}</span>
      <p>
        {courses.length} Courses in {type}
      </p>
      {courses.length > 0 &&
        courses.map((course, i) => (
          <CartItem
            course={course.course}
            key={i}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        ))}
    </div>
  );
}

const CartItem = ({ course, refresh, setRefresh }) => {
  return (
    course.id && (
      <div className="cart__courses__container">
        {course.id && (
          <HorizontalCourseCard
            isCart
            course={course}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </div>
    )
  );
};
