import React from "react";
import "./content.styles.scss";
export default function Privacy() {
  const data = [
    {
      title: "",
      p1: `Last updated: (06/03/2024)`,
      p2: `Educify ("us", "we", or "our") operates https://www.educify.org (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.`,
      p3: `We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. Besides, we abide by the Children’s Online Privacy Protection Act (COPPA).We advise parents of children under the age of 13 should be aware of our privacy policy before posting information about them that would make them recognisable. Together, this Privacy policy, Term of use and any other terms and policies that we present to you upon registration guide the use of our platform.
`,
    },
    {
      title: "Information Collection And Use",
      p1: `We value  and respect your privacy and would like you to enjoy your time and experience on our site. While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information"). Our website and apps may redirect you to other websites that have their own information collection practices. Once you leave our website and apps, make sure to read the Privacy policy and Terms of use of that website. We are not responsible for how the third-party websites use your information.`,
      p2: `We may, from time to time, use advertisements developed and managed by third-party softwares. Those third-party software may have their own privacy policies.`,
    },
    {
      title: "Use of Information",
      p1: `a. Provide Services: We use the collected information to deliver and improve our educational services, personalise user experience, and administer user accounts.`,
      p2: `b. Communication: We may use users' contact information to communicate with them about their account, courses, updates, promotions, and other relevant information.`,
      p3: `c. Analytics: We may analyze usage data to understand how users interact with our platform, improve our services, and develop new features.`,
      p4: `d. Payment Processing: We use payment information to process transactions and subscriptions securely through trusted payment processors.`,
    },

    {
      title: "Log Data",
      p1: `Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").
`,
      p2: `This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.`,

      p3: "In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this.",
    },
    {
      title: "Communications and Instant message system",
      p1: "We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that will not infringe on your right in any way.",
      p2: "We do have on our platform a messaging system between the teachers and the students that enable communication. Please , keep in mind that personal information you send through this system may be Processed by Educify in order to control the quality of conversation and monitor our services and improve our service provision.",
    },
    {
      title: "Occasional Email Marketing Using Third Party Services",
      p1: "We may send Email and mail occasionally on behalf of third-party to you for promotional purposes. We do not sell or in any way, transfer to third parties your email addresses. We respect the confidentiality of your email addresses and other data, except otherwise stated in this privacy policy.",
    },
    {
      title: "EDUCIFY LIVE CLASS Platform",
      p1: ` The Educify Live (www.educify.tv) is part of our platform that allows content creators to create content sessions in real time for students and audiences who might be interested in their knowledge, expertise or skills. This service is an add-on to the main Educify platform. The contents from the respective creator is their property and they are responsible for the quality of those contents.
`,
    },
    {
      title: "Cookies and their uses",
      p1: "Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.",
      p2: `Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.`,
    },
    {
      title: "Data Security",
      p1: `  a. We implement appropriate security measures to protect users' personal information against unauthorised access, alteration, disclosure, or destruction.`,
      p2: `  b. Users' sensitive information, such as payment details, is encrypted using secure socket layer technology (SSL) during transmission to ensure confidentiality.
`,
      p3: `c. The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
`,
    },
    {
      title: "Customer Surveys",
      p1: "We may from time to time send you customer surveys that are voluntary. These surveys may make use of third-party softwares for their execution. These surveys are sent to help us improve our services to you. We take all the information we collect during the survey and combine with other feedback from other users to further improve your user experience on our platform.",
    },
    {
      title: "Children Online Privacy",
      p1: "We would encourage parents to be aware of what their children are doing online and always instruct their children not to give out information that may make them identifiable online. These information include real names, email addresses, addresses and phone numbers without the express permission of the parents. Parents must be willing to monitor and see what interaction the children have while online.",
    },
    {
      title: "Changes To This Privacy Policy",
      p1: `
This Privacy Policy is effective as of 06/03/2024 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
`,
      p2: `We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
`,
      p3: `If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.`,
    },
    {
      title: "Contact Us",
      p1: "If you have any questions about this Privacy Policy, please contact us.",
      p2: `+1 888 252 9485`,
      p3: "support@educify.org",
      p4: "808 4th Avenue SW, Calgary T2P 0K7, Alberta, Canada",
    },
  ];
  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>
        EDUCIFY PRIVACY POLICY
      </h1>
      {data.map((section) => (
        <div className="privacy_row">
          <h2>{section.title}</h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          <p>{section?.p4}</p>
        </div>
      ))}
    </div>
  );
}
