import React, { useState } from "react";
import "./HorizontalCourseCard.styles.scss";
import courseimg from "../../../../assets/TeacherApp/assets/images/courseimg.webp";
import stars from "../../../../assets/TeacherApp/assets/svgs/rating.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applyCoupon,
  deleteCartItem,
  handleCoursesStateChange,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import { formatTime } from "../../../../hooks";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";

export default function HorizontalCourseCard({
  isCart,
  course,
  setRefresh,
  refresh,
}) {
  const dispatch = useDispatch();
  const { student } = useSelector((state) => state.student);
  const { refreshCart } = useSelector((state) => state.courses);

  const [coupon, setCoupon] = useState("");

  const handleDelete = () => {
    dispatch(deleteCartItem({ studentId: student, courseId: course.id })).then(
      () => {
        setRefresh(!refresh);
        dispatch(
          handleCoursesStateChange({ name: "refreshCart", value: !refreshCart })
        );
      }
    );
  };

  const ApplyCoupun = () => {
    dispatch(applyCoupon({ studentId: student, coupon })).then((res) => {
      setRefresh(!refresh);
    });
  };

  let discount =
    course.initialPriceAfterFeeAfterAdminDiscountAfterCoupon &&
    course?.initialPriceAfterFeeAfterAdminDiscountAfterCoupon !==
      course?.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon;

  return (
    <div className="horizontal__card">
      <div className="col">
        <Link className="image" to={`/courses/${course.id}`}>
          <img src={course?.profileImage?.url} alt="" />
        </Link>
        <div className="info">
          <Link to={`/courses/${course.id}`}>
            <div className="title">
              <span>{course.title}</span>
            </div>
            <div className="author">
              <p>{course?.teacher?.user?.name}</p>
            </div>
            <div className="rating">
              <span>{course.rating}</span>
              <img src={stars} alt="" />
              {/* <p>(115)</p> */}
            </div>
            <div className="material">
              {course.totalLength > 0 && (
                <span>{formatTime(course.totalLength)}</span>
              )}
              <span>
                <span>{course.numLectures}</span> <span>lectures</span>
              </span>
              <span>
                <span>{course.totalChapters}</span> <span>chapters</span>
              </span>
            </div>
          </Link>

          {isCart &&
            (course.initialPriceAfterFeeAfterAdminDiscountAfterCoupon !==
            course.initialPriceAfterFeeAfterAdminDiscountBeforeCoupon ? (
              <div className="coupon">
                <p>Coupon Added</p>
              </div>
            ) : (
              <div className="coupon">
                <p>Enter Coupon</p>
                <div className="input">
                  <input
                    type="text"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <button onClick={() => ApplyCoupun()}> Apply</button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="price">
        {discount ? (
          <div>
            <span className="oldprice">
              <ExchangeRateHook
                price={
                  course.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                }
              />
            </span>
            <span>
              <ExchangeRateHook
                price={course.initialPriceAfterFeeAfterAdminDiscountAfterCoupon}
              />
            </span>
          </div>
        ) : (
          <span>
            <ExchangeRateHook
              price={course.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon}
            />
          </span>
        )}

        {isCart && (
          <div className="actions">
            <button
              className="remove__cart rejected"
              onClick={() => handleDelete()}
            >
              Remove
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
