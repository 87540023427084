import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { SignupStepOne } from "./SignupStepOne";
import { SignupStepFive } from "./SignupStepFive";
import SignupStepTwo from "./SignupStepTwo";
import SignupStepThree from "./SignupStepThree";
import SignupStepFour from "./SignupStepFour";
import SignupStepSix from "./SignupStepSix";
import SignupStepSeven from "./SignupStepSeven";
import SignupStepTen from "./SignupStepTen";
import SignupStepNine from "./SignupStepNine";
import SignupNav from "../../Components/SignupNav/SignupNav";
import Complete from "../Complete/Complete";
import TutorSignup from "../TutorSignup/TutorSignup";
import Schedule from "../Schedule/Schedule";
import NotFound from "../../../SharedApp/Pages/404/NotFound";

import "./singnupContainer.styles.scss";
// import TeacherLanding from "../TeacherLanding/TeacherLanding";

const SignUpContainer = () => {
  return (
    <div>
      <SignupNav />
      <Routes>
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/" element={<TutorSignup />} /> */}
        <Route path="/" element={<TutorSignup />} />
        {/* <Route path="/teacher-info" element={<TutorSignup />} /> */}
        <Route path="step-one" element={<SignupStepOne />} />
        <Route path="step-two" element={<SignupStepTwo />} />
        <Route path="step-three" element={<SignupStepThree />} />
        <Route path="step-four" element={<SignupStepFour />} />
        <Route path="step-five" element={<SignupStepFive />} />
        <Route path="step-six" element={<SignupStepSix />} />
        <Route path="step-seven" element={<SignupStepSeven />} />
        <Route
          path="step-eight"
          element={<Schedule customLinks={false} handleNext={true} />}
        />
        <Route path="step-nine" element={<SignupStepNine />} />
        <Route path="step-ten" element={<SignupStepTen />} />
        <Route path="complete" element={<Complete />} />
      </Routes>
    </div>
  );
};
export default SignUpContainer;
