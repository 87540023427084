import React from "react";
import "./Review.styles.scss";
import Rating from "@mui/material/Rating";

export default function Review({
  lastName,
  description,
  image,
  name,
  position,
  rate,
  className,
}) {
  return (
    <div className={`user__review ${className ?? className}`}>
      <div className="rating">
        <Rating name="read-only" value={rate} readOnly />
      </div>
      <div className="description">{description}</div>
      <div className="author">
        {image && <img src={image} alt="author" />}
        <div className="author__details">
          <div className="name">
            {name} <span>{lastName}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
