import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import {
  getBookingsById,
  getFilteredBookings,
  getInvoices,
  payTeacher,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { getStudentById } from "../../../ReduxToolkit/Slices/studentSlice";
import { Link } from "react-router-dom";
export default function PaymentTable({
  userTeacherId,
  teacherId,
  teacherName,
}) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [filters, setFilters] = useState({});
  const [fetch, setFetch] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const sendToast = (text) => toast.error(text);
  const sendMessage = (text) => toast.success(text);

  const payment = (invoiceId) => {
    return {
      currency: "USD",
      description: "Paying teacher for lesson",
      type: "PAYMENT",
      senderId: user.id,
      receiverId: userTeacherId,
      invoiceId,
    };
  };

  useEffect(() => {
    let newFilters = "";

    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `teacherId=${teacherId}`;
    teacherId &&
      dispatch(getInvoices(`teacherId=${teacherId}`)).then((res) => {
        setInvoices(res.payload.invoices);
        setTotalAmount(
          res.payload?.amountOwedToTeacher?._sum?.amountOwed
            ? res.payload.amountOwedToTeacher._sum.amountOwed
            : 0
        );
      });
  }, [teacherId, fetch]);
  const pay = (invoiceId) => {
    // return;
    dispatch(payTeacher(payment(invoiceId)))
      .then((res) => {
        if (res.payload === "Response Status : 422") {
          return sendToast("Insuffecient funds");
        }
        if (typeof res.payload === "string") {
          return sendToast(res.payload);
        }
        if (res.type === "payTeacher/fulfilled") {
          sendMessage("Payment initiated successfully!");
          return setFetch(!fetch);
        }
        return sendToast("Error Paying Teacher");
      })
      .catch((Err) => console.error("errow tih ", Err));
  };

  return (
    <div className="teacher_payments">
      <div className="header">
        <h1>{teacherName}'s Payment</h1>
        {/*
         <div className="debt">
          <div className="text">
            <span>Total Debt: </span>{" "}
            <span className="amount">${totalAmount / 100}</span>
          </div>
           <button className="danger">Clear All Debt</button> 
        </div> 
        */}
      </div>
      <div className="payments__table">
        <div className="students__table">
          <table>
            <tr>
              <th>S.NO</th>
              <th>Invoice Status</th>
              <th>Booking Status</th>
              <th>Lesson</th>
              <th>CREATED AT</th>
              <th>AMOUNT</th>
              <th>Action</th>
            </tr>
            {invoices.length > 0 &&
              invoices.map((row, i) => (
                <InvoiceContainer key={i} i={i} row={row} pay={pay} />
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}

const InvoiceContainer = ({ i, row, pay }) => {
  const [show, setShow] = useState(false);
  const [student, setStudent] = useState({});
  const [booking, setBooking] = useState({});
  const dateChange = (createdAt) => moment(createdAt).format("DD/MM/YYYY");

  let studentId = row.studentId;
  const dispatch = useDispatch();

  useEffect(() => {
    studentId &&
      show &&
      dispatch(getStudentById(studentId)).then((res) =>
        setStudent(res.payload.data)
      );

    row.bookingId &&
      dispatch(getBookingsById(row.bookingId)).then((res) => {
        setBooking(res.payload);
      });
  }, [show]);

  return (
    <tbody className="BookingInfo_Container_TBODY">
      <tr>
        <td>{i}</td>
        <td>
          <div className="manage__std__lesson">
            <div className="lesson__date">
              <p>{row.status}</p>
            </div>
          </div>
        </td>
        <td>
          <div className="manage__std__lesson">
            <div className="lesson__date">
              <p>{booking?.status ? booking?.status : "NA"}</p>
            </div>
          </div>
        </td>
        <td>
          <p>{row?.lesson?.title}</p>
        </td>
        <td>
          <p>{dateChange(row.createdAt)}</p>
        </td>
        <td>
          <p className="amount">${Math.round(row.amountOwed) / 100}</p>
        </td>
        <td>
          {row.status !== "PAID" && row.status !== "PAYING" && (
            <button className="pay" onClick={() => pay(row.id)}>
              Pay
            </button>
          )}
          <button className="more" onClick={() => setShow(!show)}>
            Info
          </button>
        </td>
      </tr>
      {show && (
        <tbody>
          {booking.id && (
            <>
              <tr className="info_title">
                <td>Booking Info</td>
              </tr>
              <tr className="info_sub_title">
                <td>
                  <p>Status</p>
                </td>
                <td>
                  <p>Dates</p>
                </td>
                <td>
                  <p>Day</p>
                </td>
                <td>
                  <p>Start Time</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p
                    style={
                      booking.status !== "COMPLETED"
                        ? { color: "rgb(248, 103, 117)" }
                        : { color: "#50CC89" }
                    }
                  >
                    {booking.status}
                  </p>
                </td>
                <td>
                  <p>
                    {/* {booking.date.length > 0 &&
                      booking.date.map((date, i) => (
                        <p key={i}>{dateFormat(date)}</p>
                      ))} */}
                  </p>
                </td>
                <td>
                  <p>{booking.day}</p>
                </td>
                <td>
                  <p>{moment(booking.startTime).format("HH:mm")}</p>
                </td>
              </tr>
            </>
          )}
          <tr className="info_title">
            <td>
              <p>Student Info </p>
            </td>
          </tr>
          <tr className="info_sub_title">
            <td>
              <p>Name</p>
            </td>
            <td>
              <p>Email</p>
            </td>
            <td>
              <p>Phone Number</p>
            </td>
            <td>
              <p>Amount Payed</p>
            </td>
          </tr>
          <tr>
            <td>
              <Link to={`/admin/students/${student?.id}`}>
                {student?.user?.name}
              </Link>
            </td>
            <td>
              <p>{student?.user?.email}</p>
            </td>
            <td>
              <p>{student?.user?.phone}</p>
            </td>
            <td>
              <span className="pay">${row.amountPayed / 100}</span>
            </td>
          </tr>
          {row?.lesson?.id && (
            <>
              <tr className="info_title">
                <td>Lesson Info</td>
              </tr>
              <tr className="info_sub_title">
                <td>
                  <p>Location</p>
                </td>

                <td>
                  <p>Type</p>
                </td>
                <td>
                  <p>Location</p>
                </td>
                <td>{/* <p>Location</p> */}</td>
              </tr>
              <tr>
                <td>
                  <p>{row.lesson.location.join(", ")}</p>
                </td>
                <td>
                  <p>
                    {row.lesson.isGroup ? "Group Lesson" : "Private Lesson"}
                  </p>
                </td>
                <td>
                  <p>{row.lesson.location.join(", ")}</p>
                </td>
              </tr>
            </>
          )}
        </tbody>
      )}
    </tbody>
  );
};
