import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getLessonList } from "../../../ReduxToolkit/Slices/studentSlice";

import "./Classes.styles.scss";

import { convertTimezoneDash } from "../../../SharedApp/Components/Timezone";
import { TimezoneSelector } from "../../../hooks";
import { getStudentAppointmentsByStatus } from "../../../ReduxToolkit/Slices/lessonsSlice";
import Pagination from "../../../Components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";

const Classes = ({ other }) => {
  const dispatch = useDispatch();

  const { student } = useSelector((state) => state.student);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [lessons, setLessons] = useState([]);

  const [status, setStatus] = useState("ALL");

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(1);

  const { timezone } = useSelector((state) => state.user);
  const onLessonStart = (location, teacherId, teacherAddress, title) => {
    if (location === "online") {
      // navigate(`/lessonRoom/${teacherId}/${student}/${title}`);
      window.open(`#/lessonRoom/${teacherId}/${student}/${title}`, "_blank");
    } else {
      if (
        !teacherAddress ||
        !teacherAddress.latitude ||
        !teacherAddress.longitude
      ) {
        return;
      }
      const { latitude, longitude } = teacherAddress;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

      window.open(googleMapsUrl, "_blank");
    }
  };

  const navigate = useNavigate();

  const onUnSubscribeClick = () => {
    navigate("/students/subscriptions");
  };
  useEffect(() => {
    dispatch(
      getStudentAppointmentsByStatus({
        studentId: student,
        filters: {
          limit: 5,
          page: currentPage,
          status: other ? sort : "ACCEPTED",
          orderBy: "asc",
        },
      })
    ).then((Res) => {
      setLessons(Res?.payload?.data?.studentAppointments);
      setTotalData(Res?.payload?.data?.totalAppointments);
      setPage(Res?.payload?.data?.numOfPages);
    });
  }, [search, currentPage, sort]);

  const getLessonDuration = (date1, date2) => {
    return `${convertTimezoneDash(
      moment.utc(date1).format("HH:mm"),
      "UTC",
      timezone
    )} - ${convertTimezoneDash(
      moment.utc(date2).format("HH:mm"),
      "UTC",
      timezone
    )} (${timezone})`;
  };

  return (
    <div className="Classes_Container__container">
      <div className="Classes_Container">
        <div className="flex-container">
          <div className="flex-container-1">
            {other ? <span>All Classes</span> : <span>Upcoming Classes</span>}
            <span className="more-than-15-lessons">
              {lessons?.length > 0
                ? `More than ${totalData}+ Lessons`
                : "No Lessons Yet"}
            </span>
          </div>
          <div className="flex_col">
            <div className="sort_guide_1">
              <select
                className="sort-1 select"
                onChange={(e) => setSort(e.target.value)}
                defaultValue={"Sort"}
                value={sort}
              >
                <option value={""}>All</option>
                <option value={"ACCEPTED"}>Accepted</option>
                <option value={"PENDING"}>Pending</option>
                <option value={"REJECTED"}>Rejected</option>
                <option value={"COMPLETED"}>Completed</option>
              </select>
            </div>
            <TimezoneSelector />
          </div>
        </div>
        <div className="table__container">
          {lessons?.length > 0 ? (
            <table className="Tables_Containers">
              <thead className="header">
                <tr>
                  <th>Lessons </th>
                  <th>Date</th>
                  <th>Lesson time</th>
                  <th>Status</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {lessons?.length > 0 &&
                  lessons.map((item, index) => (
                    <tr
                      key={index}
                      className="Mapped_Rows"
                      style={{ cursor: "pointer" }}
                    >
                      <th className="topic_container">
                        {/* <img alt="" src={item.images[0]?.url} className="image" /> */}
                        <div className="subcontainer">
                          <p>{item?.booking?.lesson?.title}</p>
                        </div>
                      </th>
                      <th>
                        <div className="subcontainer">
                          <span className="date">
                            {moment(item.date).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </th>

                      <th>
                        <div className="subcontainer">
                          <span>
                            {getLessonDuration(item.startTime, item.endTime)}
                          </span>
                        </div>
                      </th>
                      <th>
                        <button
                          className={
                            item.status === "PENDING"
                              ? "btn_completed"
                              : item.condition === "COMPLETED"
                              ? "btn_completed"
                              : "btn_completed"
                          }
                        >
                          {item?.booking?.status === "PENDINGPAYMENT"
                            ? "PENDING PAYMENT"
                            : item.status}
                        </button>
                      </th>
                      {item.booking.location === "studentPlace" && (
                        <th>Student Place</th>
                      )}
                      {item?.booking?.location !== "studentPlace" &&
                        item?.status === "ACCEPTED" && (
                          <th className="" style={{ paddingLeft: "10px" }}>
                            <button
                              onClick={() =>
                                onLessonStart(
                                  item.booking.location,
                                  item?.teacher.id,
                                  item?.teacher?.user?.geoLocation,
                                  item?.booking?.lesson?.title
                                )
                              }
                              className="btn_completed"
                              style={{ marginRight: "10px" }}
                            >
                              {item?.booking?.location === "online"
                                ? "Start Lesson"
                                : "Directions"}
                            </button>
                            {item.booking.subscription && (
                              <button onClick={() => onUnSubscribeClick()}>
                                unsubscribe
                              </button>
                            )}
                          </th>
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p className="no_lessons_text"></p>
          )}
        </div>
        <Pagination
          page={page}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalData={totalData}
          dataRendered={lessons?.length}
        />
      </div>
    </div>
  );
};

export default Classes;
