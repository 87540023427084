import React from "react";
import moment from "moment";

import rightArrow from "../../../assets/TeacherApp/assets/svgs/Right-2.svg";
import { useSelector } from "react-redux";
import { convertTimezoneFromDate } from "../../../SharedApp/Components/Timezone";

export default function TeacherTransaction({
  lessonName,
  price,
  time,
  status,
}) {
  const { timezone } = useSelector((state) => state.user);

  return (
    <div className="transaction">
      <div className="image">
        <img src={rightArrow} alt="" />
      </div>
      <div className="details">
        <div className="row">
          <span>{lessonName}</span>
          <span className="price">+{(price / 100).toFixed(2)}</span>
        </div>
        {status && (
          <div className="row">
            <span>
              Status: <span className="price">{status}</span>
            </span>
          </div>
        )}
        <div className="row">
          <span className="time">
            {convertTimezoneFromDate(moment(time).format(), "UTC", timezone)}
          </span>
        </div>
      </div>
    </div>
  );
}
