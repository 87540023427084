import React from "react";
import SharedVideoUploader from "../../../Components/SharedVideoUploader/SharedVideoUploader";

const VerifyTeacher = ({ video, onSubmitLesson, setVideo }) => {
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_DURATION = 360;
  return (
    <div className="verify_acc_uploader">
      <h1>Upload/record a 5 min video by clicking on the button bellow</h1>
      <p>
        To ensure quality, teachers must submit a 5-minute video demonstrating a
        mock lesson. Here's the process:
      </p>
      <ol>
        <li>
          <strong>Record:</strong> Create a 5-minute teaching demo.
        </li>
        <li>
          <strong>Upload:</strong> Submit the video here.
        </li>
        <li>
          <strong>Review:</strong> Our admins evaluate your teaching skills.
        </li>
        <li>
          <strong>Decision:</strong> Approval grants full platform access.
        </li>
      </ol>
      <p>
        Maintain our teaching standards for a positive learning experience.
        Thank you!
      </p>
      <SharedVideoUploader
        index={"file-video2"}
        onVideoChange={(value, length) => {
          setVideo(value);
        }}
        video={video.url ? video : [{ url: "", publicId: "" }]}
        MAX_VIDEO_SIZE={MAX_VIDEO_SIZE}
        MAX_VIDEO_DURATION={MAX_VIDEO_DURATION}
        noToolTip
      />
      <button className="btn__primary" onClick={() => onSubmitLesson()}>
        Submit
      </button>
    </div>
  );
};

export default VerifyTeacher;
