import thumbnail from "../../../../assets/TeacherApp/assets/images/course.webp";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { updateCloudinaryUrl } from "../../../../hooks";

export const Video = ({ item, del, onDelete, viewOnly }) => {
  return (
    <div className="video__col__container">
      <div className="custom_video__col">
        {/* <span className="sm-title">Vocabulary Learning.mp4</span> */}
        <h4>
          Homework Video{" "}
          {!viewOnly && (
            <img
              src={del}
              alt=""
              onClick={() => onDelete()}
              className="delete_image"
            />
          )}
        </h4>
        <div className="vid__thumbnail">
          <video width="320" height="240" controls className="course__thumb">
            <source src={updateCloudinaryUrl(item)} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};
export const ImageComponent = ({ image, onDelete, del, viewOnly }) => {
  return (
    <div className="video__col__container">
      <h4 className="">
        Homework Image{" "}
        {!viewOnly && (
          <img
            src={del}
            alt=""
            onClick={() => onDelete()}
            className="delete_image"
          />
        )}
      </h4>

      <div className="video__col">
        <img className="image" src={image} alt="" />
      </div>
    </div>
  );
};
export const Code = ({ code, onEdit }) => {
  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split("\n")
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join("\n");

  return (
    <div className="code__editor__edit">
      <div className="lang__selector">
        {/* <span>Include a code example for your student</span> */}
      </div>
      <Editor
        value={code}
        highlight={(code) => hightlightWithLineNumbers(code, languages.js)}
        textareaId="codeArea"
        className="editor"
        readOnly
        padding={5}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 18,
          outline: 0,
        }}
      />
    </div>
  );
};
