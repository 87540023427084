import React, { useEffect } from "react";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import "./ManageStudentsPage.styles.scss";
import TeachersTable from "../../Components/TeachersTable/TeachersTable";
import ManageStudents from "../../Components/ManageStudents/ManageStudents";
import { useDispatch } from "react-redux";
import {
  getAdminDashboard,
  getAllUsers,
  getStudents,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { useState } from "react";
import { utils, write } from "xlsx";

export default function ManageStudentsPage() {
  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    limit: 10,
  });
  const [dashboard, setDashboard] = useState([
    {
      totalBookings: 0,
      totalLessons: 0,
      totalStudents: 0,
      totalTeachers: 0,
    },
  ]);
  useEffect(() => {
    dispatch(getAdminDashboard()).then((res) => setDashboard(res.payload));
  }, []);
  // useEffect(() => {
  //   dispatch(getStudents("")).then((res) => setStudents(res.payload.students));
  // }, []);
  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${current}&`;
    dispatch(getStudents(allFilters)).then((res) => {
      setStudents(res.payload.students);
      setPage(res.payload.numOfPages);
      setTotalStudents(res.payload.totalStudents);
    });
  }, [currentPage, filters, refresh, current]);

  const [totalStudents, setTotalStudents] = useState(0);

  const exportToExcelStudent = () => {
    dispatch(getAllUsers({ excel: "true", role: "STUDENT" })).then((res) => {
      const data = res.payload;

      if (!data?.length) {
        return toast.error("No users found");
      }
      // Create a new workbook and define the column headers
      const ws = utils.json_to_sheet(data, {
        header: [
          "id",
          "name",
          "lastName",
          "email",
          "phone",
          "createdAt",
          "role",
        ],
      });

      // Create a new workbook and add the worksheet
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate the Excel file as a data URL
      const excelDataUrl = write(wb, { bookType: "xlsx", type: "base64" });

      // Convert the data URL to a Blob
      const byteCharacters = atob(excelDataUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a link and trigger a click event to download the file
      const a = document.createElement("a");
      a.href = url;
      a.download = "students.xlsx";
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };
  return (
    <div className={"manage__students__page"}>
      <button onClick={() => exportToExcelStudent()} className="btn_send ">
        Download All Students
      </button>
      {/* {students.length > 0 && ( */}
      <ManageStudents
        className={"full__table"}
        students={students}
        pagination
        filters={filters}
        setFilters={setFilters}
        dashboard={dashboard.totalStudents}
        page={page}
        currentPagination={current}
        setCurrentPagination={setCurrent}
        totalData={totalStudents}
        dataRendered={students?.length}
      />
      {/* )} */}
    </div>
  );
}
