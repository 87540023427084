import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "./overview.styles.scss";

import OverViewHeader from "../../../Components/Courses/OverViewHeader/OverViewHeader";
import CourseDescription from "../../../Components/Courses/CourseDescription/CourseDescription";
import { useParams } from "react-router-dom";
import {
  getCourseById,
  getStudentCoursesById,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import PreviewModal from "../../../Components/Courses/PreviewModal/PreviewModal";

export default function Overview() {
  const dispatch = useDispatch();

  // let chapters = [
  //   { title: "1", lessons: "2" },
  //   { title: "2", lessons: "3" },
  // ];

  const { id } = useParams();

  const [course, setCourse] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getStudentCoursesById(id)).then((res) => {
      setCourse(res.payload);
    });
  }, [id]);

  return (
    <div className="course__overview__page">
      {course.id && showModal && (
        <PreviewModal setShowModal={setShowModal} course={course} />
      )}
      <OverViewHeader
        course={course}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <CourseDescription course={course} setShowModal={setShowModal} />
    </div>
  );
}
