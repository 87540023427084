import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./CartPanel.styles.scss";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";

export default function CartPanel({ total }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCheckout = () => {
    navigate("/courses/checkout");
  };

  return (
    <div className="cart__panel">
      <div className="name">
        <span>Total:</span>
      </div>
      <div className="price">
        <ExchangeRateHook price={total} />
      </div>
      <div className="checkout__btn" onClick={() => onCheckout()}>
        <button>Checkout</button>
      </div>
    </div>
  );
}
