import React from "react";
import { Link } from "react-router-dom";
import CourseCard from "../CourseCard/CourseCard";
import "./MoreCoursesBySameInstructor.styles.scss";
export default function MoreCoursesBySameInstructor({ courses, course }) {
  return (
    <>
      {courses.length ? (
        <div className="more__courses__section">
          <div className="title">
            <span>More Courses by </span>
            <Link to={"/courses/instructor/1"}>
              <span>{course?.teacher?.user?.name}</span>
            </Link>
          </div>

          <div className="courses">
            {courses.length > 0 &&
              courses.map((course, i) => (
                <CourseCard
                  key={i}
                  price={course.price}
                  title={course.title}
                  rating={course.rating}
                  image={course?.profileImage?.url}
                  id={course.id}
                  name={course?.teacher?.user?.name}
                />
              ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
