import React from "react";

import "./content.styles.scss";
export default function TOS() {
  const data = [
    {
      title: "1. Search",
      p1: "What do you want to learn? You can select from our wide range of subject offerings from top notch teachers. You get to meet them before you meet in person. We have many photos and videos of the teachers, so that you can get to have an idea of the teacher before meeting them.",
    },
    {
      title: "2. Choose",
      p1: "When do you want to learn? You can choose your preferred date and time of the lesson, not forgetting your location of choice. Just remember to cancel ahead if you would not be available as the teacher will be expecting you. Our cancellation policy is 10 hours notice before the planned lesson.",
    },
    {
      title: "3. Learn",
      p1: "Where do you want to learn? You can have your class either from your home, at a specific location like a studio or library or do it online (virtual class). We give you the option of learning at your convenience. You can learn from a diverse array of subjects and courses from Languages to IT and Artificial Intelligence.",
    },

    { title: "FREQUENTLY ASKED QUESTIONS", p1: "", isCenter: true },
    {
      title: "What are your Lesson goals?",
      p1: "What do you want to learn? How do you want it delivered to you by your teacher? Your lesson goals are up to you. We have teachers in different locations and online all over Canada and the United States who are not only highly qualified but are skilled at teaching.",
    },
    {
      title: "I do not have a computer, What do I do?",
      p1: "Our system is designed to be mobile friendly and we do have both ios and Android mobile apps for both teachers and students. Just  go to the App store or Google play and download the apps and off you go. Besides, our virtual technology is world class and plug-and-play. No installation is required. You just connect with your teacher when it is time for lessons.",
    },
    {
      title: "What is your Learning Preference?",
      p1: "With Educify, you decide the teacher and the location of lessons. You are on the driving seat and decide what you will like to learn and achieve with your learning. You can have a trial with a teacher and decide what is a good fit for you. Are you a busy mum who has little time to help your children after school? Homework-help on Educify will help you ensure your child’s education is taken care of.",
    },
    {
      title: "What is the length of Lesson to Choose?",
      p1: "We offer 30 mins, 60 mins and 90 mins lesson options. You can literally choose what time duration you prefer on our apps or our website and you can see the lesson packages of the teachers and you will be set. We do offer trial lessons in addition. This way you get to know more about the teacher before committing to lesson packages.",
    },
  ];

  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>How It Works</h1>
      <video controls>
        <source src={"https://educify.org/howitworks.mp4"} type="video/mp4" />
      </video>
      {data.map((section) => (
        <div className="privacy_row">
          <h2
            style={
              section.isCenter ? { textAlign: "center", margin: "50px 0" } : {}
            }
          >
            {section.title}
          </h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          <p>{section?.p4}</p>
          <p>{section?.p5}</p>
          <p>{section?.p5}</p>
        </div>
      ))}
    </div>
  );
}
