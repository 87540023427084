import React, { useState } from "react";
import "./header.styles.scss";
import kickstart from "../../assets/HomeApp/images/kickstart.webp";
import searchIcon from "../../assets/HomeApp/svgs/search.svg";
import { HeaderData } from "../../assets/HomeApp/data/header-data";
import header from "../../assets/HomeApp/images/header2.png";
import { useNavigate } from "react-router-dom";
import ScrollAnimation from "../../SharedApp/Pages/ScrollAnimation/ScrollAnimation";
export default function Header() {
  const [searchValue, setSearch] = useState("");
  const navigate = useNavigate();

  return (
    <div className="header__container">
      {/* <ScrollAnimation
        initialY={0}
        animateY={0}
        initialX={-100}
        animateX={0}
        transition={1}
        delay={0}
      > */}

      <div className="header__phrase">
        <div className="header__img">
          <img src={kickstart} alt="kickstart" className="kickstart" />
        </div>
        <h1 className="subtitle">
          {HeaderData.header.phrase.split(HeaderData.header.highlight)[0]}{" "}
          <span className="highlighted">{HeaderData.header.highlight}</span>
          {HeaderData.header.phrase.split(HeaderData.header.highlight)[1]}
        </h1>
        <p onClick={() => navigate("/teacher-signup")} className="pointer">
          <span> Click here to join </span>
          <span className="highlighted">Educify </span>
          <span>as a teacher</span>
        </p>

        <form
          className="header__inputs"
          onSubmit={() => navigate(`/tutors/search/${searchValue}`)}
        >
          <input
            type="text"
            placeholder={HeaderData.placeholder}
            value={searchValue}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button type="submit">
            <img src={searchIcon} alt="search" />
          </button>
        </form>
      </div>
      {/* </ScrollAnimation> */}

      <div className="header__bg__image">
        {/* <ScrollAnimation
        initialY={0}
        animateY={0}
        initialX={100}
        animateX={0}
        transition={1}
        delay={0}
      > */}
        <img src={header} alt="header" />
        {/* </ScrollAnimation> */}
      </div>
      <form
        className="header__inputs__mobile"
        onSubmit={() => navigate(`/tutors/search/${searchValue}`)}
      >
        <input
          type="text"
          placeholder={HeaderData.placeholder}
          value={searchValue}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit">
          <img src={searchIcon} alt="search" />
        </button>
      </form>
    </div>
  );
}
