import React, { useState } from "react";

import "./FAQSection.styles.scss";
import minus from "../../assets/minus.svg";
import plus from "../../assets/plus.svg";
import DOMPurify from "dompurify";

const FAQContainer = ({ title, text }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const sanitizedContent = (text) => DOMPurify.sanitize(text);

  return (
    <div className="FAQContainer">
      <img
        className="minus-square-light"
        src={showAnswer ? minus : plus}
        onClick={() => setShowAnswer(!showAnswer)}
      />
      <div className="question flex_col">
        <span className="ques">{title}</span>
        {showAnswer && (
          <span
            className="answer"
            dangerouslySetInnerHTML={{
              __html: sanitizedContent(text),
            }}
          ></span>
        )}
      </div>
    </div>
  );
};

function FAQSection({ title, question, answer }) {
  return (
    <div className="FAQSECTION_Container">
      <span className="title">{title}</span>
      <FAQContainer title={question} text={answer} />
    </div>
  );
}

export default FAQSection;
