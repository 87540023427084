import React, { useState, useEffect } from "react";
import moment from "moment";

import { Calendar } from "react-multi-date-picker";
import "./WhatsNext.styles.scss";
import { useDispatch } from "react-redux";
import { getAllLessons } from "../../../ReduxToolkit/Slices/adminSlice";
import { useParams } from "react-router-dom";

export default function WhatsNext() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showCalender, setShowCalendar] = useState(false);
  const [lessons, setLessons] = useState([]);
  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  useEffect(() => {
    let filter = moment(selectedDate).toISOString();
    if (!id) {
      let newfilter = `date=${filter}`;
      dispatch(getAllLessons(newfilter)).then((res) => {
        setLessons(res.payload.lessons);
      });
    } else {
      let newfilter = `date=${filter}&teacherId=${id}`;
      dispatch(getAllLessons(newfilter)).then((res) => {
        setLessons(res.payload.lessons);
      });
    }
  }, [selectedDate]);

  const onAvailabilitySelect = (e) => {
    let date = new Date(e.year, e.month - 1, e.day);
    let newDate = moment(date).format();
    setSelectedDate(newDate);
    setShowCalendar(!showCalender);
  };

  return (
    <div className="whats__next">
      <div className="header">
        <div className="text">
          <h1>What`s on the road?</h1>
          <span>Total {lessons?.length} lessons</span>
        </div>
        <p className="select" onClick={() => setShowCalendar(!showCalender)}>
          {moment(selectedDate).format("DD/MM/YYYY")}
        </p>
      </div>
      {showCalender && (
        <div className="calendar">
          <Calendar
            className="custom__calendar"
            shadow={false}
            weekStartDayIndex={1}
            onChange={(e) => onAvailabilitySelect(e)}
          />
        </div>
      )}
      {lessons?.length > 0 &&
        lessons?.slice(0, 3).map((item) => (
          <div className="date__row">
            <div className="text">
              <p>
                10:20 - 11:00 <span>AM</span>
              </p>
              <span>{item.title}</span>
              <span className="lead">
                Lead by{" "}
                <span>
                  {item.teacher.user.name} {item.teacher.user.lastName}
                </span>
              </span>
            </div>
            <button>View</button>
          </div>
        ))}
    </div>
  );
}

const Dates = () => {
  const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const dates = Array.from(Array(7).keys()).map((idx) => {
    const d = new Date();
    d.setDate(d.getDate() - d.getDay() + idx);
    return d.getDate();
  });

  const mergedDates = dates.map((day, i) => {
    return [day, days[i]];
  });
  const [selected, setSelected] = useState(mergedDates[0][0]);
  return (
    <div className="week__days">
      <div className="days">
        {mergedDates.map((day) => (
          <div
            className={`day__row ${selected === day[0] ? "selected" : ""}`}
            onClick={() => setSelected(day[0])}
          >
            <p>{day[0]}</p>
            <span>{day[1]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
