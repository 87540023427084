import React, { useState } from "react";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import searchIcon from "../../../assets/TeacherApp/assets/svgs/search.svg";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";

const SignupStepTwo = () => {
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const [lessonTitle, setLessonTitle] = useState(
    teacherToRegister?.lessonTitle
  );
  const [profileTitle, setProfileTitle] = useState(
    teacherToRegister?.profileTitle
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const minWords = 12;
  const [wordsLeft, setWordsLeft] = useState(
    minWords - countWords(lessonTitle) < 0
      ? 0
      : minWords - countWords(lessonTitle)
  );
  function countWords(text) {
    return text?.trim()?.split(/\s+/)?.filter(Boolean).length; // Filters out empty strings in cases of multiple spaces
  }
  const handleSubmit = () => {
    if (!countWords(profileTitle) || countWords(profileTitle) < minWords) {
      return toast.error("Profile title must be at least 12 words");
    }
    if (!lessonTitle) {
      return toast.error("Please enter lesson title");
    }
    if (countWords(lessonTitle) > 5) {
      return toast.error("Lesson title must be a maximum of 5 words");
    }
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, profileTitle, lessonTitle },
      })
    );
    navigate("/teacher-signup/step-three");
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    const wordsCount = countWords(newText);
    setProfileTitle(newText);
    setWordsLeft(Math.max(minWords - wordsCount, 0));
  };

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[
            "Your title is the key to your teaching listing! it should be unique, attract attention and contains at least  12 words. NB: No email addresses, websites or phone number or Whatapp number or links allowed.",
          ]}
          className={"register_note"}
        />
      </div>
      <div className="container_2">
        <h2 className="no_max">
          <span>Profile</span> title{" "}
          <span className="optional">
            {" "}
            (
            {wordsLeft >= minWords
              ? `${minWords} minimum words`
              : `at least ${
                  isNaN(wordsLeft) ? minWords : wordsLeft
                } words left`}
            )
          </span>
        </h2>
        <div className="area_container">
          <textarea
            onChange={handleTextChange}
            value={profileTitle}
            placeholder="ex: Graduate from the Conservatory, teaches singing and guitar for all levels. Personalized method to meet your individual needs!"
          />
        </div>
        <h2 className="no_max" style={{ marginTop: "30px" }}>
          <span>Title</span> of your lesson{" "}
        </h2>
        <div className="area_container">
          <textarea
            onChange={(e) => setLessonTitle(e.target.value)}
            value={lessonTitle}
            placeholder="ex: Mathematics lesson for beginners"
          />
        </div>

        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-one")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupStepTwo;
