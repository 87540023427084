import React, { useEffect, useState } from "react";
import "./TeacherRequests.styles.scss";
import * as momentTz from "moment-timezone";

import { useDispatch, useSelector } from "react-redux";
import { getTeacherBookingsByStatus } from "../../../ReduxToolkit/Slices/lessonsSlice";

import TeacherRequest from "../../Components/TeacherRequest/TeacherRequest";
import { handleUserChange } from "../../../ReduxToolkit/Slices/userSlice";

export default function TeacherRequests() {
  const { teacher } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();
  const { timezone } = useSelector((state) => state.user);

  const [teachBookings, setTeachBookings] = useState([]);

  const [fetch, setFetch] = useState(false);
  const [hasBookings, setHasBookings] = useState({});
  useEffect(() => {
    dispatch(
      getTeacherBookingsByStatus({
        teacherId: teacher.id,
        status: "PENDING",
      })
    )
      .then((res) => {
        let newBookings = res.payload.data;

        if (
          newBookings &&
          Array.isArray(newBookings) &&
          newBookings.length > 0
        ) {
          setTeachBookings(newBookings);
          setHasBookings(newBookings.find((el) => el.bookings.length > 0));
        }
      })
      .catch((er) => console.log("error bookings", er));
  }, [fetch]);

  const allTimezones = momentTz.tz.names();

  return (
    <div className="teacher__requests__page">
      <div className="request__container">
        <div className="sort">
          <select>
            <option>Sort By</option>
            <option>Asc</option>
            <option>Desc</option>
          </select>

          <select
            onChange={(e) =>
              dispatch(
                handleUserChange({ name: "timezone", value: e.target.value })
              )
            }
            value={timezone}
          >
            {allTimezones.map((tz, i) => (
              <option key={i} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
        {!hasBookings?.id ? <h2>No requests yet</h2> : ""}
        <div className="requests">
          {teachBookings &&
            teachBookings.length > 0 &&
            teachBookings.map((item) =>
              item.bookings.map((booking, i) => (
                <TeacherRequest
                  key={i}
                  location={booking.location}
                  fetch={fetch}
                  student={booking.student}
                  type={booking.type}
                  setFetch={setFetch}
                  Appointments={booking.Appointments}
                  id={booking.id}
                  title={item.title}
                  images={[item?.student?.user?.profileImage?.url]}
                />
              ))
            )}
        </div>
      </div>
    </div>
  );
}
