const calendarSteps = [
  {
    element: ".calendar_cont",
    title: "Calendar",
    intro: `Within the "Teacher Calendar" section, you have the ability to view all upcoming lessons with students who have registered with you. This feature ensures you stay organized and prepared by providing a comprehensive overview of your scheduled teaching sessions.`,
  },
  {
    element: ".edit_availablity",
    title: "Availability",
    intro: `Under "Availability," toggle your account status between available and not available. When not available, students cannot view or book your lessons.`,
  },
  {
    element: ".calendar_switch",
    title: "Calendar / Roster",
    intro: `Here, easily switch between calendar and roster views to manage your schedule and student list efficiently.`,
  },
  {
    element: ".roster_btn",
    title: "Roster",
    intro: `Here, easily switch to roster section to view or manage your schedule and student list efficiently.`,
  },
  {
    element: ".cont_2",
    title: "Attendance Table",
    intro: `Here, view your lesson students and mark each as present or absent.`,
  },
];

export default calendarSteps;
