import React from "react";
import hiw1 from "../../../assets/TeacherApp/assets/images/hiw1.png";
import hiw2 from "../../../assets/TeacherApp/assets/images/hiw2.png";
import hiw3 from "../../../assets/TeacherApp/assets/images/hiw3.png";
const HowItWorks = () => {
  const rows = [
    {
      image: hiw1,
      title: "Get matched with students",
      text: "You will be connected with students based on their needs and your expertise. You'll be able to message them and pitch yourself",
    },
    {
      image: hiw2,
      title: "Connect with students before the lesson",
      text: "With our messaging system you can ask students about their needs and show them how you'd uniquely help them",
    },
    {
      image: hiw3,
      title: "Log on and start teaching",
      text: "At the time of the lesson, you can log into your 1-on-1 virtual classroom via Jitsi",
    },
  ];
  return (
    <div className="teacher_how_it_works">
      <h1>How it Works</h1>
      <div className="image_rows">
        {rows.map((el, i) => (
          <ImageRow key={i} {...el} />
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;

const ImageRow = ({ title, image, text }) => {
  return (
    <div className="image_row">
      <div className="image_row_text">
        <h3>{title}</h3>
        <span>{text}</span>
      </div>
      <div className="image">
        <img src={image} alt="" />
      </div>
    </div>
  );
};
