import React, { useState, useEffect, useRef } from "react";
import "./TutorProfile.styles.scss";
import tutorType from "../../assets/HomeApp/svgs/tutor.svg";
import tutorLocation from "../../assets/HomeApp/svgs/tutor_location.svg";
import tutorEmail from "../../assets/HomeApp/svgs/tutor_email.svg";
import verified from "../../assets/HomeApp/svgs/verified.svg";
import { Link, useNavigate } from "react-router-dom";
import TutorPreview from "../TutorPreview/TutorPreview";
import { ExchangeRateHook } from "../../ExchangeRateHook";

export default function TutorProfile({
  image,
  name,
  isVerified,
  description,
  rating,
  minPrice,
  dontView,
  id,
  showPreview,
  index,
  setShowPreview,
  lesson,
  location,
  levels,
  teacher,
  discount,
}) {
  const navigate = useNavigate();
  let newImage =
    !dontView && image !== null && image.url !== "string" ? image.url : "";

  // const LimitWords = (descr, nb) => {
  //   !limit && descr.length > nb && setLimit(true);
  //   return description.split(" ").slice(0, nb).join(" ");
  // };
  const [showFullText, setShowFullText] = useState(false);
  function limitWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }

  return (
    <div className="Tutor_Container">
      <div
        className="tutor"
        onMouseEnter={() => {
          showPreview !== index && setShowPreview(index);
        }}
      >
        <div className="tutor__profile__container">
          <div className="main__info" onClick={() => navigate(`/tutor/${id}`)}>
            <div className="tutor__img">
              <img
                src={dontView ? image : newImage}
                alt="tutori img"
                className="profile_image"
              />
            </div>
            <div className="profile">
              <div className="title">
                <div className="tutor__name">
                  <span>{name}</span>
                  {teacher.checkMark && <img src={verified} alt="verified" />}
                </div>

                <div className="teacher__button__container">
                  <Link className="btn__primary" to={`/tutor/${id}`}>
                    View Profile
                  </Link>
                </div>
              </div>
              {teacher?.background?.status === "ACCEPTED" && (
                <span className="bg_check">Background Checked</span>
              )}
              <div className="propreties">
                {/* <span>
                  <img src={tutorType} />
                  {levels && levels[levels.length - 1]?.toLowerCase()}
                </span> */}
                <span>
                  {/* <img src={tutorLocation} /> */}
                  {lesson?.location?.length
                    ? lesson.location.map(
                        (loc, i) =>
                          loc && (
                            <>
                              {loc.toLowerCase() === "teacherplace"
                                ? ` Teacher Place${
                                    i === location.length - 1 ? "," : ""
                                  }`
                                : loc.toLowerCase() === "studentplace"
                                ? ` Student Place${
                                    i === location.length - 1 ? "," : ""
                                  }`
                                : loc}
                            </>
                          )
                      )
                    : ""}
                </span>
              </div>
              <div className="details">
                <div className="label">
                  {/* <p className="txt__xs">Pricing:</p> */}
                  <div className="pricing">
                    <p>Starting At</p>
                    <ExchangeRateHook price={minPrice} />

                    {/* {discount > 0 ? (
                      <div>
                        <ExchangeRateHook price={minPrice} strike />
                        <ExchangeRateHook
                          price={(minPrice * ((100 - discount) / 100)) / 100}
                        />
                      </div>
                    ) : (
                      <ExchangeRateHook price={minPrice} />
                    )} */}
                  </div>
                </div>
                <div className="label">
                  {/* <p className="txt__xs">Rating:</p> */}
                  <div className="rating">
                    <p>Rating</p>
                    <span>{rating}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="description">
          {/* {viewAll
              ? desc
              : width > 1050
              ? LimitWords(desc, 43)
              : width < 1050 && width >= 500
              ? LimitWords(desc, 20)
              : width <= 500
              ? LimitWords(desc, 15)
              : ""}
            {limit && (
              <span onClick={() => setViewAll(!viewAll)}>
                ...View {viewAll ? "less" : "all"}
              </span>
            )} */}
          <p>
            {showFullText ? description : limitWords(description, 30)}{" "}
            {!showFullText ? (
              <span className="" onClick={() => setShowFullText(true)}>
                View all
              </span>
            ) : (
              <span className="" onClick={() => setShowFullText(false)}>
                View less
              </span>
            )}
          </p>
        </div>
      </div>
      {showPreview === index && (
        <TutorPreview pixels={"0"} teacher={teacher} lesson={lesson} />
      )}
    </div>
  );
}
