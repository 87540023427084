import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import "./tutorPreview.styles.scss";
import tutor from "../../assets/SharedApp/images/tutor.webp";
import { convertTimezone } from "../../SharedApp/Components/Timezone";
import { useNavigate } from "react-router-dom";
import { getVideoIdFromUrl, updateCloudinaryUrl } from "../../hooks";

export default function TutorPreview({ pixels, teacher, lesson }) {
  const { timezone } = useSelector((state) => state.user);
  const navigate = useNavigate();

  let oldAv = teacher?.availability?.length
    ? teacher.availability.filter((av) => av?.times?.length > 0)
    : [];

  let availability = oldAv?.length ? oldAv.slice(0, 3) : [];

  const videoUrl = teacher?.videos[0]?.url;

  const videoId = getVideoIdFromUrl(videoUrl);
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  let video = teacher?.videos[0];
  return (
    <div className="preview__cont">
      <div
        className="tutor__preview__modal"
        style={{ transform: `translateY(${pixels}px)` }}
      >
        {teacher?.videos[0]?.url && (
          <>
            {!video?.url.includes("youtu.be") &&
            !video?.url.includes("youtube.com") ? (
              <video width="300" height="auto" controls>
                <source
                  src={updateCloudinaryUrl(teacher.videos[0].url)}
                  type="video/mp4"
                />
              </video>
            ) : (
              <iframe
                className="tall"
                src={embedUrl}
                title="YouTube Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </>
        )}
        <div className="schedule">
          {availability.length > 0 &&
            availability.map((el) => (
              <div className="schedule__row">
                {<span>{el.day}</span>}

                <div className="times">
                  {el.times.slice(0, 3).map((time) => (
                    <div className="schedule__time">
                      <span>
                        {convertTimezone(
                          moment.utc(time.startTime).format("HH:mm"),
                          "UTC",
                          timezone
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <button
          className="view__btn"
          onClick={() => navigate(`/tutor/${teacher.id}`)}
        >
          View Full Schedule
        </button>
      </div>
    </div>
    // )
  );
}
