import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  deleteCategory,
  getCategories,
} from "../../../ReduxToolkit/Slices/lessonsSlice";

import "./Categories.styles.scss";
import moment from "moment";

import plus from "../../../assets/AdminApp/svgs/plus.svg";
import view from "../../../assets/AdminApp/svgs/view.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";
import deleteico from "../../../assets/AdminApp/svgs/deleteico.svg";
import warning from "../../../assets/StudentApp/svgs/warning.svg";
import Pagination from "../../../Components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import Alert from "../../Components/Alert/Alert";
import {
  deleteCoursesCategory,
  getCourseCategories,
} from "../../../ReduxToolkit/Slices/coursesSlice";

export default function Categories() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
  });

  let courses = location.pathname.includes("courses");
  const onCategoryDelete = () => {
    courses
      ? dispatch(deleteCoursesCategory(selectedCategory)).then((res) => {
          setShowConfirmation(false);
          setRefresh(!refresh);
        })
      : dispatch(deleteCategory(selectedCategory)).then((res) => {
          setShowConfirmation(false);
          setRefresh(!refresh);
        });
  };

  useEffect(() => {
    courses
      ? dispatch(getCourseCategories()).then((res) => {
          setCategories(res?.payload?.data);
        })
      : dispatch(getCategories({ ...filters, page: currentPage })).then(
          (res) => {
            setCategories(res?.payload?.data?.categories);
            setPage(res?.payload?.data.numOfPages);
            if (!fetched) {
              setFetched(true);
            }
          }
        );
  }, [currentPage, filters, refresh, courses]);

  return (
    <>
      <div className="manage__admin__categories">
        <div className="header">
          <div className="header__text">
            <span>Category Lists</span>
            {/* <p>More than 400+ lesson Categories</p> */}
          </div>
          <div className="teachers__actions">
            <select
              id="select"
              onChange={(e) => {
                if (e.target.value === "asc" || e.target.value === "desc") {
                  setFilters({
                    ...filters,
                    sortOrder: e.target.value,
                    sortField: "name",
                  });
                } else if (
                  e.target.value === "ACTIVE" ||
                  e.target.value === "INACTIVE"
                ) {
                  setFilters({ ...filters, status: e.target.value });
                } else {
                  setFilters({});
                }
              }}
            >
              <option value="">Sort</option>
              <option value="asc">asc</option>
              <option value="desc">desc</option>
              <option value="ACTIVE">active</option>
              <option value="INACTIVE">inactive</option>
            </select>
            <div className="search__teachers">
              <input
                type="text"
                placeholder="Search Categories"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  });
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className="new__category">
              <img src={plus} alt="" />
              <Link
                to={
                  courses
                    ? "/admin/courses/categories/new"
                    : "/admin/categories/new"
                }
              >
                <span> Add New Category</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="students__table">
          <table>
            <tr>
              <th>
                <span className="th__span"> NO</span>
              </th>
              <th>
                <span className="th__span"> TITLE</span>
              </th>
              <th>
                <span className="th__span"> CREATED AT</span>
              </th>
              {!courses && (
                <th>
                  <span className="th__span"> STATUS</span>
                </th>
              )}
              <th></th>
            </tr>
            {categories &&
              categories.map((cat, i) => (
                <tr key={i}>
                  <td>
                    <div className="student__cell">
                      <span className="NO">{i + 1}</span>
                    </div>
                  </td>
                  <td>
                    <div className="manage__std__lesson">
                      <div className="lesson__date">
                        <span>{cat.name}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>
                      {cat.images && cat.images.length > 0
                        ? moment(cat.images[0].updatedAt).format("DD/MM/YYYY")
                        : "no date"}
                    </span>
                    <p>Date</p>
                  </td>

                  {!courses && cat.status && cat.status.length && (
                    <td>
                      <span className={cat?.status.toLowerCase()}>
                        {cat?.status.toLowerCase()}
                      </span>
                    </td>
                  )}
                  <td>
                    <div className="category__actions">
                      <div className="actions">
                        <Link
                          to={
                            courses
                              ? `/admin/courses/categories/${cat.name}`
                              : `/admin/categories/${cat.name}`
                          }
                        >
                          <img src={view} alt="" />
                        </Link>
                        <Link
                          to={
                            courses
                              ? `/admin/courses/categories/${cat.name}/edit`
                              : `/admin/categories/${cat.name}/edit`
                          }
                        >
                          <img src={edit} alt="" />
                        </Link>
                        <img
                          src={deleteico}
                          alt=""
                          onClick={() => {
                            setShowConfirmation(true);
                            setSelectedCategory(cat.name);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </table>
        </div>
        <Pagination
          page={page}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          dataRendered={categories?.length}
        />
      </div>
      {showConfirmation && (
        <ConfirmDeleteModal
          onCancel={() => setShowConfirmation(false)}
          onConfirm={() => onCategoryDelete()}
        />
      )}
    </>
  );
}

const ConfirmDeleteModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="confirm__choice">
      <Alert
        text={"Are you sure you want to delete this payment method? "}
        btn1Text="Yes, delete"
        btn1Color={"F64E60"}
        img={warning}
        btn2
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </div>
  );
};
