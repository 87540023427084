import React from "react";
import tutor from "../../../../assets/SharedApp/images/tutor.webp";

export default function Announcement({ ann, teacher }) {
  return (
    <div className="Announcement__row">
      <div className="col">
        <img src={teacher?.user?.profileImage?.url} alt="" />
      </div>
      <div className="col">
        <span className="instructor__name">{teacher.user.name}</span>
        {/* <p>posted an announcement · 4 years ago</p> */}
        <div className="instructor__newsletter">
          {/* <h3>Did you know that I have a monthly newsletter?</h3>
          <p>
            Hey there, Did you know that I have a monthly newsletter? Keeping up
            with the fast-paced web industry can be very difficult, which is why
            I do it for you ;) So I just wanted to let you know that once a
            month, I send out a list of 10 news, tutorials or articles on HTML,
            CSS, JavaScript and web design that I think will be useful for you!
            You can read it online here (including all past issues):{" "}
            <span className="link__text">
              Click here to view my monthly newsletters
            </span>{" "}
            (I'm sorry if you already knew about this, but I wanted to let all
            my new students know about the newsletter as well, because many of
            them will find it very helpful :) Have fun with your coding! Dr.
            Angela Yu
          </p> */}
          <p>{ann}</p>
        </div>
        {/* <div className="current__user">
          <div className="image__name">
            <span>NJ</span>
          </div>
          <input type="text" placeholder="Enter Comment" />
        </div>
        <span className="link__text">Show Comment(43)</span> */}
      </div>
    </div>
  );
}
