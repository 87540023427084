import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import { SubjectRow } from "../../../Components/SubjectRow/SubjectRow";

import Button from "../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";
import { getPublicSettings } from "../../../ReduxToolkit/Slices/userSlice";
import { TeacherFeeCalculator } from "../../../hooks";

const SignupStepSeven = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const selectedLocations = teacherToRegister?.selectedLocations;
  const [rate, setRate] = useState(teacherToRegister?.rate);
  const [adminSettings, setAdminSettings] = useState({});
  const handleSubmit = () => {
    let onlinePrice = teacherToRegister["Online"];
    let studentPlace = teacherToRegister["Student place"];
    let teacherPlace = teacherToRegister["At your house"];
    if (rate === undefined) return toast.warning("Rate is mandatory");

    if (onlinePrice <= 0 || studentPlace <= 0 || teacherPlace <= 0)
      return toast.warning("Invalid rate");
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, rate },
      })
    );
    navigate("/teacher-signup/step-eight");
  };
  const handleRateChange = (e, location) => {
    const newRate = e.target.value;
    if (!/^\d*$/.test(newRate)) {
      return toast.warning("Decimals are not allowed");
    }
    if (newRate > adminSettings?.priceLimitLessons) {
      return toast.warning(
        `Maximum lesson price is $${adminSettings?.priceLimitLessons}`
      );
    }
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, [location]: newRate },
      })
    );
    setRate({ ...rate, [location]: newRate });
  };

  useEffect(() => {
    dispatch(getPublicSettings()).then((res) => {
      if (res.type.includes("fulfilled")) {
        setAdminSettings(res?.payload?.adminSetting);
      }
    });
  }, []);

  const priceAfterStudentFee = (price) =>
    (price * adminSettings.teacherCommission) / 100;

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[
            "You can set your pricing in the fields to the right. Remember to set reasonable rate that can be competitive in the market. Also, remember that this is in USD.",
          ]}
          className={"register_note_2"}
        />
      </div>
      <div className="container_2">
        <h2 className="no_max" style={{ margin: "20px 0" }}>
          <span>Rate</span> per hour (USD)
        </h2>
        <div className="info_text">
          <h4>
            Info: <span>the maximum rate for 60 min lessons is </span>$
            {adminSettings?.priceLimitLessons}
          </h4>
        </div>
        <div className="multiple_locations">
          {selectedLocations?.map((el) => (
            <div className="prices">
              <h3>{el}</h3>
              <div className="currency_input">
                <input
                  type="number"
                  placeholder="your rate in $"
                  onChange={(e) => handleRateChange(e, el)}
                  value={teacherToRegister[el]}
                  step="1"
                />
                <span>$/h</span>
              </div>
              {teacherToRegister[el] && teacherToRegister[el] > 0 && (
                <span className="get">
                  You will get {priceAfterStudentFee(teacherToRegister[el])} $/h
                </span>
              )}
            </div>
          ))}
        </div>

        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-six")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupStepSeven;
