import React from "react";
import "./FeaturedTopics.styles.scss";
import { Link } from "react-router-dom";
export default function FeaturedTopics() {
  // const data = [
  //   {
  //     title: "Development",
  //     items: [
  //       {
  //         item_title: "Python",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Web Development",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Machine Learnings",
  //         nbStudent: "312,00 students",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Business",
  //     items: [
  //       {
  //         item_title: "Python",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Web Development",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Machine Learnings",
  //         nbStudent: "312,00 students",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Business",
  //     items: [
  //       {
  //         item_title: "Python",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Web Development",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Machine Learnings",
  //         nbStudent: "312,00 students",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Business",
  //     items: [
  //       {
  //         item_title: "Python",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Web Development",
  //         nbStudent: "312,00 students",
  //       },
  //       {
  //         item_title: "Machine Learnings",
  //         nbStudent: "312,00 students",
  //       },
  //     ],
  //   },
  // ];

  const data = [
    {
      title: "Management",
      items: [
        { item_title: "Product management", nbStudent: "312,00 students" },
        { item_title: "Leadership", nbStudent: "312,00 students" },
        { item_title: "Management Skills", nbStudent: "312,00 students" },
        { item_title: "ISO 9001", nbStudent: "312,00 students" },
      ],
    },
    {
      title: "Design",
      items: [
        { item_title: "NodeJS", nbStudent: "312,00 students" },
        { item_title: "ASP.NET", nbStudent: "312,00 students" },
        { item_title: "Angular", nbStudent: "312,00 students" },
        { item_title: "CSS", nbStudent: "312,00 students" },
      ],
    },
    {
      title: "Mobile Development",
      items: [
        { item_title: "Flutter", nbStudent: "312,00 students" },
        { item_title: "Ionic", nbStudent: "312,00 students" },
        { item_title: "Kotlin", nbStudent: "312,00 students" },
        { item_title: "Swift", nbStudent: "312,00 students" },
      ],
    },
    {
      title: "Entrepreneurship",
      items: [
        { item_title: "Business Fundamentals", nbStudent: "312,00 students" },
        { item_title: "ChatGPT Business", nbStudent: "312,00 students" },
        {
          item_title: "Entrepreneurship Fundamentals",
          nbStudent: "312,00 students",
        },
        { item_title: "Freelancing", nbStudent: "312,00 students" },
      ],
    },
  ];
  return (
    <div className="featured__topics">
      <h1>Featured topics by category</h1>
      <div className="cols">
        {data &&
          data.map((el, i) => (
            <div className="col" key={i}>
              <div className="topic__title">
                <h3>{el.title}</h3>
                <div className="topic__items">
                  {el.items.map((item, i) => (
                    <Link to={`/courses/search/${item.item_title}`}>
                      <div className="item" key={i}>
                        <span>{item.item_title}</span>
                        {/* <p>{item.nbStudent}</p> */}
                        <p>{` `}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
