import React, { useEffect, useRef, useState } from "react";
import image from "./shield.svg"
import './LoadingAnimation.styles.scss'
export default function LoadingAnimation() {
  
  return (
    <div className="loading-animation">
      <img src={image} alt="" />
    </div>
  );
};
