import React, { useEffect } from "react";
import { Routes, Route, Link, Outlet } from "react-router-dom";

import Navbar from "../../Sections/Navbar/Navbar";
import Footer from "../../Sections/Footer/Footer";
import { useDispatch } from "react-redux";
import DiscountBar from "../../AdminApp/Components/DiscountBar/DiscountBar";

const NavFooterShared = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};
export const NavFooterSharedCourses = () => {
  return (
    <div>
      <Navbar coursesPosition />
      <Outlet />
      <Footer />
    </div>
  );
};
export default NavFooterShared;
