import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, {
  customFetchToken,
  setAuthToken,
} from "../customFetch/customFetch";
const accessToken = localStorage.getItem("access_token");

const initialState = {
  student: "",
  selectedPackage: {},
  lessonId: null,
  lessonLocation: "",
  lessonLength: 30,
  cartItems: [],
  cartItemsRefresh: false,
  chapters: [
    {
      courseId: "",
      title: "Chapter Name",
      chapterId: "",
      lessons: [],
    },
  ],
};

export const registerStudent = createAsyncThunk(
  "register",
  async (student, thunkApi) => {
    let url = `/students`;
    try {
      const resp = await customFetch.post(url, student);
      if (resp.data !== "Please verify") {
        const { accessToken, refreshToken } = resp.data;
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", refreshToken);
        localStorage.setItem("user", JSON.stringify(resp?.data?.user));
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("studentId", JSON.stringify(resp.data.id));
        setAuthToken(accessToken);
      }
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateStudent = createAsyncThunk(
  "updateStudent",
  async (id, thunkApi) => {
    let url = `/students/${id.id}`;
    try {
      const resp = await customFetchToken.patch(url, id.student);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentsNotes = createAsyncThunk(
  "getStudentsNotes",
  async (_, thunkApi) => {
    let url = `/notes/students/`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteStudent = createAsyncThunk(
  "deleteStudent",
  async (id, thunkApi) => {
    let url = `/students/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const askAQuestion = createAsyncThunk(
  "askAQuestion",
  async (question, thunkApi) => {
    let url = `/students/askAQuestion`;
    try {
      const resp = await customFetchToken.post(url, question);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentFavTeachers = createAsyncThunk(
  "getStudentFavTeachers",
  async (id, thunkApi) => {
    let url = `/students/${id}/fav-teachers`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentFavCourses = createAsyncThunk(
  "getStudentFavCourses",
  async (id, thunkApi) => {
    let url = `/courses/${id}/favorite`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentBookings = createAsyncThunk(
  "getStudentBookings",
  async (id, thunkApi) => {
    let url = `/bookings/student/${id}`;
    try {
      const resp = await customFetch.get(url, id);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentDashboard = createAsyncThunk(
  "getStudentDashboard",
  async (id, thunkApi) => {
    let url = `/admin/students/${id}/dashboard`;
    try {
      const resp = await customFetchToken.get(url, id);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentLessons = createAsyncThunk(
  "getStudentLessons",
  async (id, thunkApi) => {
    let url = `/students/${id}/lessons`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentSubscribedLessons = createAsyncThunk(
  "getStudentSubscribedLessons",
  async (id, thunkApi) => {
    let url = `/students/lessons/subscribed`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const unsubscribeStudentLesson = createAsyncThunk(
  "unsubscribeStudentLesson",
  async (bookingId, thunkApi) => {
    let url = `/students/lessons/subscribed/${bookingId}`;
    try {
      const resp = await customFetchToken.put(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getLessonList = createAsyncThunk(
  "getLessonList",
  async (filters, thunkApi) => {
    let url = `/lessons/list?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getSimilarTeachers = createAsyncThunk(
  "getSimilarTeachers",
  async (id, thunkApi) => {
    let url = `/teachers/similar/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getStudentNotifications = createAsyncThunk(
  "getStudentNotifications",
  async (id, thunkApi) => {
    let url = `/notifications/student/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getStudentNotificationsNumber = createAsyncThunk(
  "getStudentNotificationsNumber",
  async (id, thunkApi) => {
    let url = `/notifications/number/student/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getRecentlyViewed = createAsyncThunk(
  "getRecentlyViewed",
  async (id, thunkApi) => {
    let url = `/students/${id}/recentlyViewed`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const addRecentlyViewed = createAsyncThunk(
  "addRecentlyViewed",
  async (item, thunkApi) => {
    let url = `/students/${item.studentId}/${item.teacherId}/recentlyViewed`;
    try {
      const resp = await customFetchToken.post(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getStudentById = createAsyncThunk(
  "getStudentById",
  async (id, thunkApi) => {
    let url = `/students/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getFavorites = createAsyncThunk(
  "getFavorites",
  async (id, thunkApi) => {
    let url = `/students/${id}/fav-teachers`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const addToFavorites = createAsyncThunk(
  "addToFavorites",
  async (id, thunkApi) => {
    let url = `/students/${id.student}/${id.teacher}/fav-teacher`;
    try {
      const resp = await customFetchToken.post(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addCourseToFavorites = createAsyncThunk(
  "addCourseToFavorites",
  async (id, thunkApi) => {
    let url = `/courses/${id.student}/favorite/${id.course}`;
    try {
      const resp = await customFetchToken.post(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const RemoveCourseFromFav = createAsyncThunk(
  "RemoveCourseFromFav",
  async (id, thunkApi) => {
    let url = `/courses/${id.student}/favorite/${id.course}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const removeFromFav = createAsyncThunk(
  "removeFromFav",
  async (id, thunkApi) => {
    let url = `/students/${id.student}/${id.teacher}/fav-teacher`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const reviewTeacher = createAsyncThunk(
  "reviewTeacher",
  async (item, thunkApi) => {
    let url = `/students/${item.studentId}/reviews/teachers`;
    try {
      const resp = await customFetchToken.post(url, item.review);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addLessons = createAsyncThunk(
  "addLessons",
  async (item, thunkApi) => {
    let url = `/chapters/chapterLesson`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const addAvandedCart = createAsyncThunk(
  "addAvandedCart",
  async (item, thunkApi) => {
    let url = `/avandedCart`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getAvandedCart = createAsyncThunk(
  "getAvandedCart",
  async (_, thunkApi) => {
    let url = `/avandedCart`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteAvandedCartItem = createAsyncThunk(
  "deleteAvandedCartItem",
  async (id, thunkApi) => {
    let url = `/avandedCart/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateChapterLesson = createAsyncThunk(
  "updateChapterLesson",
  async (item, thunkApi) => {
    let url = `/chapters/chapterLesson/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.chapter);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteChapterLesson = createAsyncThunk(
  "deleteChapterLesson",
  async (id, thunkApi) => {
    let url = `/chapters/chapterLesson/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentsHomeworks = createAsyncThunk(
  "getStudentsHomeworks",
  async (_, thunkApi) => {
    let url = `/homework/students`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const studentsPostHomeworks = createAsyncThunk(
  "studentsPostHomeworks",
  async (item, thunkApi) => {
    let url = `/homework/students`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getStudentHomeworkReview = createAsyncThunk(
  "getStudentHomeworkReview",
  async (subId, thunkApi) => {
    let url = `/homework/students/${subId}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
const studentSlice = createSlice({
  initialState,
  name: "students",
  reducers: {
    clearStudentData: (state, action) => {
      state.student = {};
    },
    setStudent: (state, action) => {
      state.student = action.payload;
    },
    setStudentCart: (state, action) => {
      state.cartItems = action.payload;
    },
    refreshStudentCart: (state, action) => {
      state.cartItemsRefresh = action.payload;
    },
    setStudentState: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    handleChapterChange: (state, action) => {
      const { name, value, index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          [name]: value,
        },
        ...state.chapters.slice(index + 1),
      ];
    },

    handleAddLesson: (state, action) => {
      const { name, value, index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [...state.chapters[index].lessons, value],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleEditLesson: (state, action) => {
      const { name, value, index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            value,
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },

    handleDeleteLesson: (state, action) => {
      const { index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },

    resetStudentState: (state) => {
      return initialState;
    },
  },
});

export const {
  clearStudentData,
  setStudent,
  setStudentState,
  handleChapterChange,
  handleEditLesson,
  resetStudentState,
  handleAddLesson,
  setStudentCart,
  handleDeleteLesson,
  refreshStudentCart,
} = studentSlice.actions;

export default studentSlice.reducer;
