import React, { useEffect, useState } from "react";
import "./AccountSettings.styles.scss";

import ProfileDetails from "../../Components/ProfileDetails/ProfileDetails";
import UpdateTeacher from "../../Components/UpdateTeacher/UpdateTeacher";
import SignInMethod from "../../Components/SignInMethod/SignInMethod";
import ConnectedDevices from "../../Components/ConnectedDevices/ConnectedDevices";
import DeleteAccount from "../../Components/DeleteAccount/DeleteAccount";
import CalendarSync from "../../Components/CalendarSync/CalendarSync";
import Notifications from "../../Components/Notifications/Notifications";
import ConfirmPassword from "../../Components/ConfirmPassword/ConfirmPassword";
import AuthMethod from "../../Components/AuthMethod/AuthMethod";
import AuthAccount from "../../Components/AuthAccount/AuthAccount";
import LoginMethod from "../../Components/LoginMethod/LoginMethod";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import verified from "../../../assets/SharedApp/svgs/verified.svg";

import {
  getTeacherById,
  teacherPostTestLesson,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import VerifyTeacher from "../Complete/VerifyTeacher";
import VerifyTeacherAccount from "../../Components/VerifyTeacherAccount/VerifyTeacherAccount";
import { addGeoLocation } from "../../../ReduxToolkit/Slices/userSlice";

export default function AccountSettings() {
  const { user } = useSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const { teacher } = useSelector((state) => state.teacher);

  return (
    <div className="settings__page">
      {modal ? (
        <ConfirmPassword setModal={setModal} />
      ) : (
        <>
          <ProfileDetails role={user.role} />
          {user?.role === "TEACHER" && (
            <VerifyTeacherAccount teacher={teacher} />
          )}
          {user.role === "TEACHER" && <UpdateTeacher />}
          {user.role === "STUDENT" && <MapModalContainer />}
          {!user.bySocial && <LoginMethod />}
          {/* <SignInMethod /> */}
          {/* <ConnectedDevices /> */}
          {/* <CalendarSync /> */}
          {/* <Notifications /> */}
          {user.role === "STUDENT" && (
            <DeleteAccount setModal={setModal} modal={modal} />
          )}
          {/* <AuthMethod /> */}
          {/* <AuthAccount /> */}
        </>
      )}
    </div>
  );
}

const MapModalContainer = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [geoLocation, setGeoLocation] = useState({
    latitude: user?.geoLocation?.latitude ?? 0,
    longitude: user?.geoLocation?.longitude ?? 0,
    address: user?.geoLocation?.address ?? "",
  });
  const [location, setLocation] = useState(geoLocation.address);

  const setGeoLocations = (value) => {
    setGeoLocation(value);
  };
  const handleUpdateLocation = () => {
    if (geoLocation.latitude && geoLocation.longitude && geoLocation.address) {
      dispatch(addGeoLocation({ ...geoLocation, userId: user.id })).then(
        (res) => {
          if (res.type.includes("fulfilled")) {
            toast.success("Updated location successfully!");
          } else {
            toast.error("Something Went Wrong!");
          }
        }
      );
    }
  };

  return (
    <div className="login__method">
      <div className="header">
        <h2>Student Location</h2>
      </div>
      <div className="login__method__row">
        <div className="login__emails">
          <div className="student_loc_header">
            <h3>Add or update your location</h3>
          </div>
        </div>
        <div
          style={{ marginBottom: "30px" }}
          onClick={() => setShowModal(true)}
          className="map_location_view"
        >
          <input type="text" placeholder="Enter location..." value={location} />
        </div>
        <div className="email__btns">
          <button
            onClick={() => {
              handleUpdateLocation();
            }}
            className={"UpdateEmail"}
          >
            Update Location
          </button>
        </div>
        {showModal && (
          <MapModal
            location={location}
            setLocation={setLocation}
            geoLocation={geoLocation}
            setGeoLocation={setGeoLocations}
            setShowMapModal={setShowModal}
          />
        )}
      </div>
      {/* <AuthNote text={text} link="#" /> */}
    </div>
  );
};
const MapModal = ({
  setShowMapModal,
  location,
  setLocation,
  geoLocation,
  setGeoLocation,
}) => {
  const [value, setValue] = useState("");
  const [marker, setMarker] = useState(null); // State to hold the marker instance
  const [showMarker, setShowMarker] = useState(true); // State to hold the marker instance
  const [zoom, setZoom] = useState(12); // Initial zoom level

  useEffect(() => {
    // Create the map.
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom,
      center: {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      },
      mapTypeId: "terrain",
    });
    const newMarker = new window.google.maps.Marker({
      position: {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      },
      map: map,
      title: "New Marker",
    });
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
    setMarker(newMarker);
    map.addListener("click", (event) => {
      const latLng = event.latLng;
      setGeoLocation({
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      });
      // setBaseGeo({ latitude: latLng.lat(), longitude: latLng.lng() });
      map.setCenter({
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      });
    });

    const StoreLocation = new window.google.maps.Marker({
      position: {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      },
      map: map,
      title: "Store Place",
    });
  }, [showMarker, location, geoLocation]);

  const getGeoByAddress = (e) => {
    setValue(e);
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setGeoLocation({
          latitude: lat,
          longitude: lng,
          address: e.label,
        });
        setLocation(e.label);
      })
      .catch((error) => {
        console.error("Error getting geolocation:", error);
      });
  };

  return (
    <div className="map_modal" style={{ zIndex: 999 }}>
      <div className="map_container">
        <div className="info">
          <div className="header">
            <h1>Please write your studio/home address</h1>
            {/* <span onClick={() => setShowMapModal(false)}>X</span> */}
            <img
              src={remove}
              alt="close"
              className="close_map"
              onClick={() => setShowMapModal(false)}
            />
          </div>
          <GooglePlacesAutocomplete
            apiKey={import.meta.env.VITE_GOOGLEPLACE}
            selectProps={{
              value,
              onChange: getGeoByAddress,
            }}
          />
          <div className="search_location"></div>
        </div>
        <div className="mini">
          <div id="map"></div>
          <div className="map_btn">
            <button
              className="btn_send"
              onClick={() => {
                if (!value.label) {
                  return toast.error(
                    "Please type in location as you would like to show it on your profile"
                  );
                }
                setLocation(value.label);
                value.label && getGeoByAddress(value);
                setShowMapModal(false);
              }}
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
