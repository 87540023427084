import React from "react";
import "./WhatToLearn.styles.scss";
import check from "../../../../assets/TeacherApp/assets/svgs/check.svg";

export default function WhatToLearn({ objectives }) {
  return (
    <div className="what__youll__learn course__description__container">
      <div className="title">
        <h1>What you'll learn</h1>
        <div className="learning__points">
          {objectives &&
            objectives.map((el, i) => (
              <div className="learn__row content" key={i}>
                <img src={check} alt="" />
                <p>{el}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
