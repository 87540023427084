import React, { useState } from "react";
import "./PageHeader.styles.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import verified from "../../../assets/SharedApp/svgs/verified.svg";
import Rating from "@mui/material/Rating";
import { Link, useNavigate, useParams } from "react-router-dom";
import AskAQuestion from "../AskAQuestion/AskAQuestion";
import { useDispatch, useSelector } from "react-redux";
import { selectLesson } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { useEffect } from "react";
import customFetch from "../../../ReduxToolkit/customFetch/customFetch";
import defaultImage from "../../../assets/SharedApp/images/default.webp";
import {
  addToFavorites,
  getFavorites,
  removeFromFav,
} from "../../../ReduxToolkit/Slices/studentSlice";
import moment from "moment";

export default function PageHeader({
  profilePicture,
  name,
  location,
  teacher,
  rating,
  totalReviews,
  lesson,
  lastName,
  onBookingClick,
  pet,
}) {
  const [selectedSection, setSelectedSection] = useState("description");
  const [showModal, setShowModal] = useState(false);
  const [favIds, setFavIds] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const currentDate = new Date();
  const endDate = new Date(lesson?.discountEndDate);
  const discount =
    lesson?.discount && lesson.discount > 0 && endDate > currentDate
      ? lesson.discount
      : 0;

  const { student } = useSelector((state) => state.student);

  const addTeacherToFav = () => {
    if (favIds.includes(id)) {
      dispatch(
        removeFromFav({
          teacher: id,
          student,
        })
      ).then(() => getStudentFav());
    } else {
      dispatch(
        addToFavorites({
          teacher: id,
          student,
        })
      ).then(() => getStudentFav());
    }
  };

  const getStudentFav = () => {
    dispatch(getFavorites(student)).then((res) => {
      setFavIds(res.payload.data.map((item) => item.id));
    });
  };
  useEffect(() => {
    student && getStudentFav();
  }, []);

  return (
    <div className="tutor__details">
      <div className="tutor__details__container">
        <div className="images">
          <div className="main__image">
            <img
              src={
                profilePicture && profilePicture.includes("cloud")
                  ? profilePicture
                  : defaultImage
              }
              alt=""
            />
          </div>
        </div>
        <div className="details">
          <div className="header">
            <div className="here">
              <div className="title">
                <div className="name">
                  <span>
                    {name && name} {lastName}
                  </span>
                  {teacher.checkMark && <img src={verified} alt="" />}
                </div>
                <div className="propreties">
                  <div>
                    {location &&
                      location.map((loc, i) => (
                        <span>
                          {loc === "online"
                            ? `Online${i !== location.length - 1 ? "," : ""}`
                            : loc === "teacherPlace"
                            ? `Teacher Place${
                                i !== location.length - 1 ? "," : ""
                              }`
                            : loc === "studentPlace"
                            ? `Student Place${
                                i !== location.length - 1 ? "," : ""
                              }`
                            : ""}
                        </span>
                      ))}
                  </div>
                </div>
                {student && (
                  <div
                    className="fav flex_center"
                    onClick={() => addTeacherToFav()}
                  >
                    {!favIds.includes(id) ? (
                      <>
                        <span> Add to favorites</span>
                        <FavoriteBorderIcon
                          style={{
                            fill: "#FFC442",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <span>Added to favorites</span>
                        <FavoriteIcon
                          style={{
                            fill: "#FFC442",
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
                <div className="pet">
                  <span>Have a Pet? </span>
                  <span className="answer">{pet ? " Yes" : " No"}</span>
                </div>
                {discount > 0 && (
                  <div className="pet">
                    <span style={{ color: "#00A2FF" }}>{discount}%</span>
                    <span>
                      {" "}
                      discount till{" "}
                      {moment(lesson.discountEndDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
              </div>
              <button
                className="teacher__prof__btn"
                onClick={() => onBookingClick()}
              >
                <a>Book a Trial</a>
              </button>
            </div>

            <div className="rating">
              <button onClick={() => setShowModal(true)}>Ask Question</button>
              <div className="rate__reviews">
                <h1>{rating}</h1>
                <div className="rate__nb">
                  <div className="stars__nb">
                    <Rating value={rating} readOnly />
                    <span className="nb__rev">{totalReviews} Reviews</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AskAQuestion setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
}
