import React, { useState } from "react";
import close from "../../../assets/SharedApp/svgs/x.svg";
import { useDispatch, useSelector } from "react-redux";
import { askAQuestion } from "../../../ReduxToolkit/Slices/studentSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function AskAQuestion({ setShowModal, showModal }) {
  const { student } = useSelector((state) => state.student);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { id } = useParams();
  const questionInitial = {
    subject: "",
    question: "",
    studentId: student,
    teacherId: id,
  };

  const [question, setQuestion] = useState(questionInitial);
  const [busy, setBusy] = useState(false);

  const onSubmitQuestion = () => {
    if (!question.subject || !question.question) {
      return toast.error("Please enter both fields");
    }
    if (user?.role !== "STUDENT") {
      return toast.error("You need to be a student to ask a questions");
    }
    setBusy(true);
    dispatch(askAQuestion(question))
      .then((res) => {
        setBusy(false);
        if (res.payload === "Question asked, thank you!") {
          setQuestion(questionInitial);
          return toast.success(res.payload);
        } else {
          return toast.error(res.payload);
        }
      })
      .catch((res) => {
        setBusy(false);
      });
  };

  return (
    <div
      className={`slide-in ask__question__container ${showModal ? "show" : ""}`}
    >
      <div className="ask__container">
        <div className="header">
          <h1>Have a Question?</h1>
          <img src={close} onClick={() => setShowModal(false)} />
        </div>
        <div className="input">
          <span>Subject</span>
          <input
            type="text"
            value={question.subject}
            onChange={(e) =>
              setQuestion({ ...question, subject: e.target.value })
            }
          />
        </div>
        <div className="input">
          <span>Question</span>
          <textarea
            value={question.question}
            onChange={(e) =>
              setQuestion({ ...question, question: e.target.value })
            }
          />
        </div>
        <button
          className={busy ? "fetching" : ""}
          onClick={() => onSubmitQuestion()}
        >
          Send
        </button>
      </div>
    </div>
  );
}
