import React from "react";

import videoIntro from "./packages&combo.mp4";
import "./content.styles.scss";
export default function Packages() {
  const data = [
    {
      title: "1. Trial Lesson",
      p1: "Do you want to get to know the teacher? Our trial lessons give you the opportunity to get to do exactly that . With Trial lessons, you pay for a lesson of either 30 mins or 60 mins and have a session with the teacher, you can decide to continue after the trial. PLEASE NOTE: You can only book one trial class at any time with a teacher",
    },
    {
      title: "2. 5-lesson Package",
      p1: "If you do not have lots of money to spend but want to book lessons according to your budget, 5- lesson Combo is your choice. This is an economic solution for a small budget. In this case, you can book every 5 lesson cycle.",
    },
    {
      title: "3. 10 Lesson Package (The Popular Choice)",
      p1: "Ten lesson packages are our popular choice because of the cost savings it offers. With a 10- lesson package, you typically save as much as 15% per lesson.  This also offers the relief of payment once every 2.5 months. All you do is focus on the lessons and your learning goals.",
    },
    {
      title: "4. 15-lesson Package",
      p1: "This is very attractive not only because of the cost savings and its attractiveness for extended time relief. This package is ideal for people who want serious cost-savings as you can save at least 20% off regular lesson fees.",
    },
  ];

  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>Packages & Combos</h1>
      <video controls>
        <source src={videoIntro} type="video/mp4" />
      </video>
      {data.map((section) => (
        <div className="privacy_row">
          <h2
            style={
              section.isCenter ? { textAlign: "center", margin: "50px 0" } : {}
            }
          >
            {section.title}
          </h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          <p>{section?.p4}</p>
          <p>{section?.p5}</p>
          <p>{section?.p5}</p>
        </div>
      ))}
    </div>
  );
}
