import React from "react";
import { Link, useLocation } from "react-router-dom";
import Track from "../../Components/Track/Track";
import "./Review.styles.scss";
export default function Review({ customLinks, given, customClass }) {
  let location = useLocation();
  let step = location.pathname.split("/")[3];
  return (
    <div className={`review__details ${customClass ? customClass : ""}`}>
      <Track step={step} givenData={given} />
      <div className="container">
        <h1>Review Your Details</h1>
        <p>
          Please, carefully review all the details provided before submitting.
          Thanks
        </p>
        <Link
          to={
            customLinks ? "/teachers/subjects/1" : "/teacher/signup/completed"
          }
        >
          <button className="btn__primary">Submit</button>
        </Link>
      </div>
    </div>
  );
}
