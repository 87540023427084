import React from "react";
import imagesGroup from "../../../assets/TeacherApp/assets/images/imagesGroup.png";
import teachersImages from "../../../assets/TeacherApp/assets/images/teachersGroup.png";
import starGroup from "../../../assets/TeacherApp/assets/images/star group.png";
import greenStar from "../../../assets/TeacherApp/assets/images/greenstar.png";
import { useNavigate } from "react-router-dom";
// import landingbg from "../../../assets/TeacherApp/assets/images/landingbg.png";

const Welcome = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/teacher-signup");
  };
  return (
    <div className="teacher_landing_inner">
      <div className="landing_bg_container">
        <header className="teacher_landing_welcome">
          <div className="column">
            <h1>
              Make your <span>Global Impact</span> as a Tutor Today
            </h1>
            <p>
              Educify is an online educational technology platform for tutors
              looking to make real impact. Our aim is to revolutionize education
              by providing our tutors with resources they need to make a
              positive impact in the lives of students around the globe
            </p>
            <div className="teachers_group">
              <img src={teachersImages} alt="" />
              <span>Join 1k+ Tutors</span>
            </div>
            <div className="landing_images_sm">
              <img src={imagesGroup} alt="" />
            </div>
            <div className="button_container">
              <button onClick={handleNavigate} className="gold_button">
                Sign up <span>(Get Free Profile Advert)</span>
              </button>
            </div>
            <div className="landing_reviews">
              <div className="review_col">
                Excellent
                <img src={starGroup} alt="" />
              </div>
              <div className="review_col">
                <span className="bold">436</span>
                reviews on
                <img src={greenStar} alt="" width={17} />
                <span className="bold">Trustpilot</span>
              </div>
            </div>
          </div>
          <div className="landing_images">
            <img src={imagesGroup} alt="" />
          </div>
        </header>
      </div>
    </div>
  );
};

export default Welcome;
