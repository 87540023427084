import { useNavigate } from "react-router-dom";

const InfoSection = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/teacher-signup");
  };
  return (
    <div className="teacher_info_section">
      <div className="text_container">
        <h1>
          By using Educify web learning platform to redefine your educational
          journey for <span className="gold_text">success</span>, we can help
          you with personalized tutoring for test preparation, streamline your
          learning processes, ensure precise management of tutors and{" "}
          <span className="gold_text">educational resources</span>. Say goodbye
          to uncertainty and hello to academic success with us.
        </h1>
        <button onClick={handleNavigate} className="gold_button">
          Sign up
        </button>
      </div>
    </div>
  );
};
export default InfoSection;
