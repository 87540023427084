import React, { useEffect, useState } from "react";

import "./AdminPage.styles.scss";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminAnalytics from "../../Components/AdminAnalytics/AdminAnalytics";
import ManageStudents from "../../Components/ManageStudents/ManageStudents";
import ManageTeachers from "../../Components/ManageTeachers/ManageTeachers";
import StudentInfo from "../../Components/StudentInfo/StudentInfo";
import SyllableList from "../../Components/SyllableList/SyllableList";
import WhatsNext from "../../Components/WhatsNext/WhatsNext";
import Courses from "../../Pages/Courses/Courses";

import { useDispatch, useSelector } from "react-redux";
import {
  getAdminDashboard,
  getAllUsers,
  getStudents,
  getTeachers,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { utils, write } from "xlsx";
import { toast } from "react-toastify";

export default function AdminPage() {
  const [dashboard, setDashboard] = useState([
    {
      totalBookings: 0,
      totalLessons: 0,
      totalStudents: 0,
      totalTeachers: 0,
    },
  ]);

  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentIndex, setStudentIndex] = useState(0);
  const [studentFilters, setStudentFilters] = useState({
    search: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let newFilters = "";
    Object.keys(studentFilters).map((keyName, i) => {
      let value = studentFilters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFiltersStudent = newFilters + "limit=5";
    dispatch(getStudents(allFiltersStudent)).then((res) =>
      setStudents(res.payload.students)
    );
  }, [studentFilters]);

  useEffect(() => {
    dispatch(getAdminDashboard()).then((res) => setDashboard(res.payload));
    dispatch(getTeachers("limit=5&status=PENDING")).then((res) =>
      setTeachers(res.payload.teachers)
    );
  }, []);

  const exportToExcel = () => {
    dispatch(getAllUsers({ excel: "true" })).then((res) => {
      const data = res.payload;

      if (!data?.length) {
        return toast.error("No users found");
      }
      // Create a new workbook and define the column headers
      const ws = utils.json_to_sheet(data, {
        header: [
          "id",
          "name",
          "lastName",
          "email",
          "phone",
          "createdAt",
          "role",
        ],
      });

      // Create a new workbook and add the worksheet
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate the Excel file as a data URL
      const excelDataUrl = write(wb, { bookType: "xlsx", type: "base64" });

      // Convert the data URL to a Blob
      const byteCharacters = atob(excelDataUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a link and trigger a click event to download the file
      const a = document.createElement("a");
      a.href = url;
      a.download = "users.xlsx";
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };

  return (
    <div className="admin__dash">
      <div className="admin__dash__container">
        <AdminHeader onExportClick={exportToExcel} />
        <AdminAnalytics dashboard={dashboard} />
        <ManageStudents
          students={students}
          setFilters={setStudentFilters}
          viewAll
          filters={studentFilters}
          dashboard={dashboard.totalStudents}
          pagination
        />
        {/* <div className="admin__dash__row">
          <ManageStudents
            students={students}
            setStudentIndex={setStudentIndex}
            studentIndex={studentIndex}
            setFilters={setStudentFilters}
            viewAll
            filters={studentFilters}
            pagination
          />{" "}
          <StudentInfo student={students[studentIndex]} />
        </div> */}
        <ManageTeachers
          teachers={teachers}
          teachersCount={dashboard.totalTeachers}
        />
        <Courses isDashboard />
        <div className="admin__dash__row">
          <WhatsNext />
          <SyllableList />
        </div>
      </div>
    </div>
  );
}
