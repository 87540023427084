import React from "react";
import "./SmsTemplate.styles.scss";
export default function SmsTemplate() {
  return (
    <div className="sms__template">
      <h1>Sms Templates</h1>
      <div className="inputs">
        <div className="input">
          <span>SMS Unique Title</span>
          <select name="" id="">
            <option value="">Class_Reminder</option>
            <option value="">Class_Reminder</option>
            <option value="">Class_Reminder</option>
            <option value="">Class_Reminder</option>
          </select>
        </div>
        <div className="input">
          <span>Select SMS Unique Title</span>
          <input type="text" placeholder="text..." />
        </div>
      </div>
      <div className="sms__btns">
        <button className="discard">Discard</button>
        <button className="save">Save</button>
      </div>
    </div>
  );
}
