import React from "react";
// import coursesheader from "../../../../assets/SharedApp/images/coursesheader.webp";
import courseheader from "../../../../assets/SharedApp/images/coursesheader.webp";
import HeaderPhraseComponent from "../HeaderPhraseComponent/HeaderPhraseComponent";

export default function CoursesPageHeader() {
  return (
    <div className="courses__home__header">
      <div className="courses__header__container">
        <HeaderPhraseComponent isHeader />
        <div className="image">
          <img src={courseheader} alt="" />
        </div>
      </div>
    </div>
  );
}
