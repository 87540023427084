import React, { useState, useEffect } from "react";
import "./Login.styles.scss";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Welcome } from "../../Components/Welcome/Welcome";
import { Link } from "react-router-dom";

import { terms } from "../../../assets/SharedApp/data/Auth";
import { Login_text } from "../../../assets/SharedApp/data/Auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  loginUser,
  logout,
  redirectLink,
  resetUserState,
  setUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import {
  getTeacherById,
  resetTeacherState,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  getStudentById,
  resetStudentState,
  setStudent,
} from "../../../ReduxToolkit/Slices/studentSlice";

import login_bg from "../../../assets/HomeApp/images/login_bg.webp";
import google from "../../../assets/HomeApp/svgs/googe-icon-white.svg";

import facebook from "../../../assets/HomeApp/svgs/facebook.svg";
import stripeIcon from "../.../../../../assets/payment-icons/stripe.svg";
import masterCardIcon from "../.../../../../assets/payment-icons/mastercard.svg";
import paypal from "../.../../../../assets/payment-icons/paypal.svg";
import visa from "../.../../../../assets/payment-icons/visa.svg";
import showPassword from "../../../assets/SharedApp/svgs/show.svg";
import hidePassword from "../../../assets/SharedApp/svgs/hide.svg";

import { setAuthToken } from "../../../ReduxToolkit/customFetch/customFetch";

export default function Login({ expired }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (expired) {
      alert("Session is timed out, please log in again");
      dispatch(resetStudentState());
      dispatch(resetTeacherState());
      dispatch(resetUserState());
    }
  }, []);
  return (
    <div className="login__container">
      <Welcome text={Login_text.Welcome} img={login_bg} />
      <LoginContainer />
    </div>
  );
}

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentImages = [paypal, visa, masterCardIcon, stripeIcon];
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [passType, setPassType] = useState(false);
  const sendToast = (text) => toast.error(text);

  const redirect = useSelector((state) => state.user.redirectLink);

  const user = { email: email, password: password };

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const auth = firebase.auth();

  const loginWithFacebook = async () => {
    try {
      const res = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider());

      const user = res.user;
      const firebaseToken = await user.getIdToken();
      const facebookUser = {
        email: res.additionalUserInfo.profile.email,
        firebaseToken,
        bySocial: true,
      };

      dispatch(login(facebookUser)).then((res) => {
        if (typeof res?.payload?.data === "string") {
          let message = res?.payload?.data;
          if (message === "Invalid credentials") {
            toast.error("Kindly create an account before logging in");
            return navigate("/signup");
          } else return toast.error(message);
        }

        if (res.type.includes("fulfilled")) {
          handleLoginSuccess(res);
        }
      });
    } catch (error) {
      if (error.message === "Invalid credentials") {
        toast.error("Kindly create an account before logging in");
        return navigate("/signup");
      } else return toast.error(error.message);
    }
  };

  useEffect(() => {
    const credentials = JSON.parse(localStorage.getItem("credentials"));
    if (credentials?.email && credentials?.password) {
      setEmail(credentials.email);
      setPassword(credentials.password);
    }
  }, []);

  const logoutUser = () => {
    localStorage.clear();
    setAuthToken("delete");
  };

  const handleSignOut = () => {
    logoutUser();
    dispatch(logout());
    navigate("/");
  };

  const handleLoginSuccess = async (res) => {
    if (res.type === "login/fulfilled") {
      if (res.payload.user.role === "ADMIN") {
        dispatch(loginUser());
        dispatch(setUser(res.payload.user));
        setBusy(false);
        return navigate("/admin", { replace: true });
      }
      if (res.payload.user.role === "BLOGADMIN") {
        dispatch(loginUser());
        dispatch(setUser(res.payload.user));
        setBusy(false);
        return navigate("/blogadmin", { replace: true });
      }
      if (res.payload.user.role === "STUDENT") {
        dispatch(setStudent(res.payload.user.student.id));
        dispatch(setUser(res.payload.user));
        dispatch(loginUser());
        if (redirect) {
          dispatch(redirectLink(""));
          setBusy(false);
          return navigate(redirect, { replace: true });
        } else {
          navigate("/students", { replace: true });
        }
      } else if (res.payload.user.role === "TEACHER") {
        if (!res.payload.user.teacher?.id) {
          handleSignOut();
          return sendToast("Your account has been suspended");
        }
        dispatch(setUser(res.payload.user));
        dispatch(loginUser());

        dispatch(getTeacherById(res.payload.user.teacher?.id))
          .then((res) => {
            setBusy(false);
            navigate("/teachers", { replace: true });
          })
          .catch((err) => {});
      }
    }
  };

  const handleLoginResponse = (res, user) => {
    let users = user ? user : res.payload.user;
    if (res.payload.data === "Please authenticate") {
      dispatch(setUser(users));
      toast.success("Please enter the code that you've received by mail");
      return navigate("/authenticate");
    }
    if (
      res.payload.data === "Please verify your email address before logging in."
    ) {
      toast.error(res.payload.data);
      setBusy(false);
      dispatch(setUser(users));
      return navigate("/otp");
    }
    if (
      res?.payload?.data?.message === "Your account has been temporarly blocked"
    ) {
      setBusy(false);
      return toast.error("Your account has been temporarly blocked");
    }
    if (res?.payload?.data?.message === "User is not verified") {
      toast.error("Your email address is not verified yet");
      dispatch(setUser({ email: users.email }));
      setBusy(false);
      return navigate("/otp");
    }
    if (res.type.includes("reject")) {
      setBusy(false);
      if (typeof res?.payload?.data === "string") {
        return toast.error(res.payload.data);
      }
      return toast.error("Invalid credentials");
    }
    if (res.type.includes("fulfilled")) {
      handleLoginSuccess(res);
    }
  };
  const loginDispatch = (users) => {
    setError("");
    setBusy(true);
    dispatch(login(users)).then((res) => {
      handleLoginResponse(res, users);
      //  else sendToast(res.payload);
    });
  };
  const loginWithGoogle = async () => {
    try {
      const res = await auth.signInWithPopup(googleProvider);
      const user = res.user;
      const firebaseToken = await user.getIdToken();
      const googleUser = {
        email: res.additionalUserInfo.profile.email,
        firebaseToken,
        bySocial: true,
      };

      dispatch(login(googleUser)).then((res) => {
        if (typeof res?.payload?.data === "string") {
          let message = res?.payload?.data;
          if (message === "Invalid credentials") {
            toast.error("Kindly create an account before logging in");
            return navigate("/signup");
          } else return toast.error(message);
        }

        if (res.type.includes("fulfilled")) {
          handleLoginSuccess(res);
        }
      });
    } catch (error) {
      if (error.message === "Invalid credentials") {
        toast.error("Kindly create an account before logging in");
        return navigate("/signup");
      } else return toast.error(error.message);
    }
  };
  const handleLogin = async () => {
    if (!email && !password) {
      toast.warning("You need to enter a valid email and password");
    } else if (!email) {
      toast.warning("You need to enter a valid email");
    } else if (!password) {
      toast.warning("You need to enter a valid password");
    } else if (email && password) {
      loginDispatch(user);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="inner__login">
      <h1>{Login_text.header}</h1>
      <p>
        {Login_text.subHeader[0]}
        <span>
          <Link style={{ color: "inherit" }} to={"/signup"}>
            {" "}
            {Login_text.subHeader[1]}
          </Link>
        </span>
      </p>

      <div className="inputs" onSubmit={() => handleLogin()}>
        <div className="email">
          <h4>{Login_text.email}</h4>
          <input
            type="text"
            name="email"
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
            value={email}
          />
        </div>
        <div className="password">
          <div className="header">
            <h4>{Login_text.password}</h4>
            <span>
              <Link to="/forget">{Login_text.forgot_password}</Link>
            </span>
          </div>
          <div className="input_with_icon">
            <input
              type={passType ? "text" : "password"}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
              value={password}
            />
            <img
              src={passType ? showPassword : hidePassword}
              alt=""
              onClick={() => setPassType(!passType)}
            />
          </div>
        </div>
        <button
          className={busy ? "signin__btn fetching" : "signin__btn"}
          to="/students"
          type="submit"
          onClick={() => handleLogin()}
        >
          {Login_text.button}
        </button>
        <div className="signin__with" onClick={() => loginWithGoogle()}>
          <img src={google} alt="" />
          <span>{Login_text.google_btn}</span>
        </div>
        <p className="login__error">{error}</p>
      </div>

      <div className="terms">
        <span>
          <Link target="blank" to={"/terms"}>
            {terms.terms}
          </Link>
        </span>
        <span>
          <Link target="blank" to={"/packages&Combos"}>
            {terms.Plans}
          </Link>
        </span>
        <span>
          <Link target="blank" to={"/about-educify"}>
            {terms.ContactUs}
          </Link>
        </span>
      </div>
    </div>
  );
};
