import React, { useEffect, useRef, useState } from "react";
import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "./viewHomework.styles.scss";

import notfound from "../../../assets/SharedApp/images/default.webp";
import AddStudentHomework from "../../../StudentApp/Pages/AddStudentHomework/AddStudentHomework";
import { useDispatch } from "react-redux";
import {
  getStudentHomeworkReview,
  getStudentsHomeworks,
} from "../../../ReduxToolkit/Slices/studentSlice";
import moment from "moment";
import HomeWorks, { HomeworkDisplayComponent } from "../HomeWorks/HomeWorks";
import DOMPurify from "dompurify";
export default function ViewStudentsHomeworks() {
  const [showReview, setShowReview] = useState(false);
  const [showCreateHomework, setShowCreateHomework] = useState(false);
  const [homeworks, setHomeworks] = useState([]);
  const [index, setIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudentsHomeworks()).then((res) => {
      {
        if (res?.payload?.homeworks) {
          setHomeworks(res?.payload?.homeworks);
          setIndex(0);
        }
      }
    });
  }, [refresh]);

  const onShowReview = (value, i) => {
    i && setIndex(i);
    setShowReview(value);
  };
  const [selectedSubmission, setSelectedSubmission] = useState("");
  useEffect(() => {
    if (selectedSubmission)
      dispatch(getStudentHomeworkReview(selectedSubmission)).then((res) => {
        setSubmissionReview(res?.payload);
      });
  }, [selectedSubmission]);
  const [SubmissionReview, setSubmissionReview] = useState({});
  return (
    <div className="view_homeworks">
      <StudentsTable
        setShowCreateHomework={setShowCreateHomework}
        setShowReview={onShowReview}
        homeworks={homeworks}
        refresh={refresh}
        setRefresh={setRefresh}
        setSelectedSubmission={setSelectedSubmission}
      />
      {!!showCreateHomework && (
        <HomeWorks
          lessonTitle={homeworks[index]?.lesson?.title}
          student
          teacherHomeWork={homeworks[index]}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {!!showReview && (
        <StudentHomeworkView
          homework={homeworks[index]}
          index={index}
          key={index}
        />
      )}
      {selectedSubmission && SubmissionReview?.id && (
        <StudentHomeworkView TeacherReview={SubmissionReview} />
      )}
    </div>
  );
}

const StudentsTable = ({
  setShowReview,
  setShowCreateHomework,
  showReview,
  showCreateHomework,
  homeworks,
  refresh,
  setRefresh,
  setSelectedSubmission,
}) => {
  return (
    <div className={`manage__students`}>
      <div className="header">
        <div className="header__text">
          <span>Homeworks</span>
        </div>
        <div className="students__actions">
          {/* <Link className="select flex_center" to="/admin/students">
            View All
          </Link> */}
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>Students</th>
              <th>HomeWork</th>
              <th>Lesson</th>
              <th>Added at</th>
              {/* <th>Status</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {homeworks?.length ? (
              homeworks.map((row, i) => (
                <StudentsRow
                  setShowCreateHomework={setShowCreateHomework}
                  setShowReview={setShowReview}
                  row={row}
                  key={i}
                  i={i}
                  assign={true}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setSelectedSubmission={setSelectedSubmission}
                />
              ))
            ) : (
              <p>No homeworks yet!</p>
            )}
          </tbody>
        </table>
      </div>
      {/* <h1>hi</h1> */}
    </div>
  );
};

const StudentsRow = ({
  assign = true,
  i,
  setShowCreateHomework,
  setShowReview,
  row,
  refresh,
  setRefresh,
  setSelectedSubmission,
  s,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <tr>
      <td>
        <Link className="student__cell">
          <img src={row.teacher.user.profileImage.url} alt="" />
          <div className="name">
            <span>
              {row.teacher.user.name} {row.teacher.user.lastName}
            </span>
            {/* <p>English for kids</p> */}
          </div>
        </Link>
      </td>

      <td>
        <span>{row?.title}</span>
      </td>
      <td>
        <span>{row.lesson.title}</span>
      </td>
      <td>
        <span>{moment(row.updatedAt).format("DD/MM/YYY")}</span>
      </td>
      {/* {row.rated ? (
        <td>
          <button className="approved">reviewed</button>
        </td>
      ) : (
        <td>
          <button className="approved">not reviewed yet</button>
        </td>
      )} */}
      <td>
        <div className="">
          {!row?.submissions?.length ? (
            <button
              className="inProgress"
              onClick={() => {
                setShowReview(false, i);
                setShowCreateHomework((prev) => true);
                setRefresh((prev) => !prev);
              }}
            >
              Submit homework
            </button>
          ) : (
            <button
              className="approved"
              onClick={() => {
                // setShowReview(false, i);
                // setShowCreateHomework((prev) => true);
                // setRefresh((prev) => !prev);
              }}
            >
              Submitted
            </button>
          )}
        </div>
      </td>
      <td>
        {!row?.submissions.length ? (
          <button
            className="accepted"
            onClick={() => {
              setShowReview(true, i);
              setShowCreateHomework(false);
              setSelectedSubmission("");
            }}
          >
            View homework
          </button>
        ) : (
          <button
            className="accepted"
            onClick={() => {
              setShowReview(false, i);
              setShowCreateHomework(false);
              setSelectedSubmission(row.submissions[0].id);
            }}
          >
            View Review
          </button>
        )}
      </td>
    </tr>
  );
};

const StudentHomeworkView = ({ homework, index, TeacherReview }) => {
  // const sanitizedContent = (text) => DOMPurify.sanitize(text);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [index, homework, TeacherReview]);
  const sanitizedContent = (text) => DOMPurify.sanitize(text);

  const isRated =
    TeacherReview?.feedback ||
    TeacherReview?.rated ||
    TeacherReview?.rating > 0;

  return (
    <div className="student-homework-view">
      <h2>Homework</h2>
      <div className="cont">
        <div className="due">
          <span>Due at </span>
          <span className="time">
            {moment(homework?.dueDate).format("DD/MM/YYYY, h:mm a")}
          </span>
        </div>
        {!TeacherReview?.id && (
          <HomeworkDisplayComponent
            homework={homework}
            i={index}
            viewOnly
            onHomeworkChange={(e) => {}}
          />
        )}
      </div>
      <div className="homework-submission"></div>
      {!!TeacherReview?.id && isRated ? (
        <div className="teacher-review">
          <div className="due">
            <span>Review Added: </span>
            <span className="time">
              {moment(TeacherReview?.createdAt).format("DD/MM/YYYY, h:mm a")}
            </span>
          </div>
          <h3>Teacher's Review:</h3>
          <Rating
            name="teacher-review"
            value={TeacherReview?.rating}
            precision={0.5}
            readOnly
          />
          <div className="teacher-comment">
            <p>
              <strong>Teacher's Comment:</strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizedContent(TeacherReview?.note),
                }}
              />
            </p>
          </div>
        </div>
      ) : (
        <h2>Homework not rated by teacher yet!</h2>
      )}
      <div className="" ref={ref} />
    </div>
  );
};
