import React from "react";
import howToRegister from "../../../assets/TeacherApp/assets/images/howtoregister.png";
import howToRegisterSmall from "../../../assets/TeacherApp/assets/images/howtoregistersmall.png";
const HowToRegister = () => {
  return (
    <div className="how_to_register">
      <div className="inner_max">
        <h1>
          How to <span className="gold_text">Register</span>
        </h1>
        <img src={howToRegister} alt="" className="htr_lg" />
        <img src={howToRegisterSmall} alt="" className="htr_sm" />
      </div>
    </div>
  );
};

export default HowToRegister;
