import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../Components/Pagination/Pagination";
import {
  getCourseById,
  getStudentCourses,
  getStudentOwnedCourses,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import NewOnlineCourse from "../../../../TeacherApp/Pages/Courses/NewOnlineCourse/NewOnlineCourse";
import CourseCard from "../../../Components/Courses/CourseCard/CourseCard";
import "./Courses.styles.scss";
import { useLocation } from "react-router-dom";
import { Steps } from "intro.js-react";
export default function CoursesLanding({ mylist }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [courses, setCourses] = useState([]);
  const { student } = useSelector((state) => state.student);
  const [search, setSearch] = useState("");
  const [searchLocal, setSearchLocal] = useState("");
  useEffect(() => {
    mylist
      ? dispatch(getStudentOwnedCourses(student)).then((res) => {
          if (res.payload === "No courses available") {
            return;
          }
          setCourses(res.payload);
        })
      : dispatch(getStudentCourses(`limit=100&search=${search}`)).then(
          (res) => {
            if (res.payload.courses === "No courses available") {
              return;
            }
            setCourses(res.payload.courses);
          }
        );
  }, [mylist, search]);
  const { pathname } = useLocation();
  const [showGuide, setShowGuide] = useState(true);
  const steps = [
    {
      element: ".std_list1",
      title: "List",
      intro: "By clicking this item, you can switch to view all courses",
    },
    {
      element: ".std_list2",
      title: "List",
      intro: "By clicking this item, you can switch to view courses you bought",
    },
    {
      element: ".courses_srch_inp",
      title: "Search Courses",
      intro: "by typing here, you can search for your favorite courses",
    },
  ];
  return (
    <div className="student__courses__landing">
      {showGuide && pathname.includes("courses") && (
        <Steps
          enabled={user.helpGuide}
          steps={steps}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="cont">
        <div className="search">
          <input
            type="text"
            placeholder="Search..."
            className="courses_srch_inp"
            value={!mylist ? search : searchLocal}
            onChange={(e) =>
              !mylist
                ? setSearch(e.target.value.toLowerCase())
                : setSearchLocal(e.target.value.toLowerCase())
            }
          />
          {courses.length > 0 ? (
            <div className="courses__cont">
              {!search && !mylist
                ? courses.map((course, i) => (
                    <CoursesContainer course={course} key={i} mylist={mylist} />
                  ))
                : courses
                    .filter((el) =>
                      el.title.toLowerCase().includes(searchLocal)
                    )
                    .map((course, i) => (
                      <CoursesContainer
                        course={course}
                        key={i}
                        mylist={mylist}
                      />
                    ))}
              {/* change props to get different style card */}
            </div>
          ) : (
            <p>No courses at the moment</p>
          )}
        </div>
      </div>
      {/* <Pagination page={3} dataRendered={7} totalData={30} /> */}
    </div>
  );
}

const CoursesContainer = ({ course, mylist }) => {
  return (
    course.id && (
      <CourseCard
        isBought
        editable
        mylist={mylist}
        image={course?.profileImage?.url}
        title={course.title}
        name={course?.teacher?.user?.name}
        id={course.id}
        percentage={course.percentageWatched}
        price={course.price}
        rating={course.rating}
      />
    )
  );
};
