import React, { useEffect, useState } from "react";
import "./viewBlogs.styles.scss";
import { useDispatch } from "react-redux";
import {
  deleteBlogById,
  getAllBlogs,
} from "../../../ReduxToolkit/Slices/adminSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../../../Components/Pagination/Pagination";
const ViewBlogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getAllBlogs({ search, page: currentPage, limit: "10" })).then(
      (res) => {
        if (res.type.includes("fulfilled")) {
          setBlogs(res?.payload?.data);
        }
      }
    );
  }, [dispatch, search, refresh, currentPage]);

  const handleDelete = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (confirm) {
      dispatch(deleteBlogById(id)).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Blog deleted successfully!");
          setRefresh(!refresh);
        }
      });
    }
  };
  const handleClick = (id) => {
    navigate(`/admin/blogs/${id}`);
  };
  const handlePreview = (id) => {
    navigate(`/blogs/${id}`);
  };
  return (
    <div className="all_blogs">
      {blogs?.blogs?.length ? (
        <>
          <h1>All blogs</h1>

          <BlogTable
            data={blogs?.blogs}
            onDelete={handleDelete}
            onClick={handleClick}
            setSearch={setSearch}
            onPreview={handlePreview}
          >
            <Pagination
              page={blogs?.numOfPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              dataRendered={blogs?.blogs?.length}
              totalData={blogs?.totalBlogs}
            />
          </BlogTable>
        </>
      ) : (
        <h4>No blogs yet</h4>
      )}
    </div>
  );
};
const BlogTable = ({
  data,
  onDelete,
  onClick,
  onPreview,
  setSearch,
  children,
}) => {
  return (
    <div className="manage__teachers__page">
      <div className="header">
        <div className="header__text">
          <span>All blog posts</span>
          <p>More than {data?.length} teachers</p>
        </div>
        <div className="teachers__actions">
          {/* <select id="select">
            <option value="">Sort</option>
          </select> */}
          <div className="search__teachers">
            <input
              type="text"
              placeholder="Search blogs..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="students__table blogs_table">
        <table>
          <tr>
            <th>Title</th>
            {/* <th>Content</th> */}
            <th>STATUS</th>
            <th>Created at</th>
            <th>Actions</th>
          </tr>
          {data.map((row) => (
            <tr>
              <td>
                <div className="student__cell">
                  <img src={row?.image?.url} alt="" />
                  <div className="name blog_content">
                    <span>{row.title}</span>
                  </div>
                </div>
              </td>

              <td>
                <button className={row?.published ? "accepted" : "pending"}>
                  {row?.published ? "Published" : "Not published"}
                </button>
              </td>
              <td>
                <span>{moment(row?.createdAt).format("DD/MM/YYYY")}</span>
                <p>Date</p>
              </td>
              <td className="actions">
                <div className="action_buttons">
                  {onPreview && (
                    <button
                      className="approved"
                      onClick={() => onPreview(row?.title)}
                    >
                      Preview
                    </button>
                  )}
                  <button className="accepted" onClick={() => onClick(row?.id)}>
                    Edit
                  </button>
                  <button
                    className="rejected"
                    onClick={() => onDelete(row?.id)}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </table>
        {children && children}
      </div>
    </div>
  );
};
export default ViewBlogs;
