import React from "react";
import "./CourseRating.styles.scss";
import CourseReview from "../CourseReview/CourseReview";
import singlestar from "../../../../assets/TeacherApp/assets/svgs/singlestar.svg";
import Pagination from "../../../../Components/Pagination/Pagination";
export default function CourseRating({ course }) {
  return (
    <div className="course__rating__section">
      <div className="title">
        <div className="star">
          <img src={singlestar} alt="" />
        </div>
        <div className="avg__rating">
          <span>{course?.rating?.toFixed(1)} course rating</span>
        </div>
        <div className="nb__rating">
          <li>
            <span>
              {course?.reviews?.length}
              {course?.reviews?.length === 1 ? " rating" : " ratings"}
            </span>
          </li>
        </div>
      </div>
      <div className="reviews__container">
        {course &&
          course.reviews &&
          course.reviews.map((review) => (
            <>
              <CourseReview
                comment={review.comment}
                name={review.student.user.name}
                lastName={review.student.user.lastName}
                rating={review.rating}
              />
            </>
          ))}
      </div>
    </div>
  );
}
