import React, { useState } from "react";
import "./EditCategory.styles.scss";
import edit from "../../../assets/SharedApp/svgs/edit.svg";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import { useDispatch, useSelector } from "react-redux";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import {
  setUserProfilePicture,
  uploadImage,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { useEffect } from "react";
import {
  getCateoryByName,
  updateCategory,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ImageUploader from "../../../Components/ImageUploader/ImageUploader";
import {
  getCoursesCategoryByName,
  updateCourseCategory,
} from "../../../ReduxToolkit/Slices/coursesSlice";

export default function NewCategory() {
  const [newImg, setNewImg] = useState("");
  const [category, setCategory] = useState({});
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(null);

  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  let courses = location.pathname.includes("courses");

  useEffect(() => {
    courses
      ? dispatch(getCoursesCategoryByName(id)).then((res) => {
          setCategory(res.payload);
          setTitle(res.payload.name);
          setNewImg(res.payload.image);
        })
      : dispatch(getCateoryByName(id)).then((res) => {
          setCategory(res.payload.data);
          setActive(res.payload.data.status === "ACTIVE" ? true : false);
          setTitle(res.payload.data.name);
          // setActive(category.status == "ACTIVE" ? true : false);
        });
  }, []);

  const handleUpdate = () => {
    let newCategory = {
      name: title,
      // images: newImg.url
      //   ? [{ ...newImg, id: category.images[0].id }]
      //   : category.images,
      images: newImg.url ? [newImg] : category.images,
      status: active ? "ACTIVE" : "INACTIVE",
    };

    courses
      ? dispatch(
          updateCourseCategory({
            name: id,
            category: { ...newCategory, image: newImg },
          })
        ).then(() => toast.success("Category was updated"))
      : dispatch(updateCategory({ id, category: newCategory })).then((res) => {
          if (res.type.includes("rejected")) {
            toast.error("Something went wrong");
          } else {
            toast.success("Category was updated");
            navigate("/admin/categories");
          }
        });
  };
  return (
    <div className="create__category">
      <div className="header">
        <h1>Edit Category</h1>
        <p>Setup lesson category</p>
      </div>
      <div className="create__category__row">
        <span>Category Image</span>
        <ImageUploader
          setNewImg={setNewImg}
          image={
            courses
              ? category?.image?.url
              : category?.images && category.images.length
              ? category.images[0].url
              : false
          }
        />
      </div>
      <div className="create__category__row">
        <span>Title</span>
        <input
          type="text"
          placeholder="Category Name"
          defaultValue={category.name && category.name}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      {!courses && (
        <div className="create__category__row">
          <span>Change Status</span>
          <div className="save__card">
            <input
              type="checkbox"
              id="switch"
              class="checkbox"
              defaultChecked={active}
              onClick={() => {
                setActive(!active);
              }}
            />
            <label for="switch" class="toggle"></label>
          </div>
        </div>
      )}
      <div className="create__cat__btns">
        <div className="cat__buttons">
          <button
            className="discard"
            onClick={() => navigate("/admin/categories")}
          >
            Discard
          </button>
          <button className="save__btn" onClick={() => handleUpdate()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
