import React, { useEffect, useState } from "react";
import "./CreatePromo.styles.scss";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { CreatePromoCodes } from "../../../ReduxToolkit/Slices/adminSlice";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { addTeacherCoupons } from "../../../ReduxToolkit/Slices/teacherSlice";
import { getTeacherCourses } from "../../../ReduxToolkit/Slices/coursesSlice";
import { data, teacherData } from "./PromoData";

export default function CreatePromo({ teacher }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [promo, setPromo] = useState({
    title: "",
    percentage: 0,
    numOfUsers: 0,
    maxUsage: 0,
    type: "GENERAL",
    status: "INACTIVE",
    startDate: "",
    endDate: "",
    details: "",
    terms: "",
  });

  const teacherId = useSelector((state) => state.teacher.teacher.id);
  useEffect(() => {
    teacherId &&
      teacher &&
      dispatch(getTeacherCourses(`teacherId=${teacherId}&limit=${100}`)).then(
        (res) => {
          setCourses(res.payload.coursesWithPrice);
        }
      );
  }, [teacherId, teacher]);

  const dataTeachers = [
    ...teacherData,
    {
      name: "course",
      type: "select",
      objName: "courseId",
      options:
        courses &&
        courses.length &&
        courses.map((course) => {
          return { label: course.title, value: course.id };
        }),
    },
  ];

  const handleCreatePromo = (promo) => {
    if (promo.percentage >= 100) {
      toast.error("Invalid Percentage");
      return;
    }

    if (
      promo.title &&
      promo.percentage &&
      promo.numOfUsers &&
      promo.type &&
      promo.status &&
      promo.startDate &&
      promo.endDate &&
      promo.details &&
      promo.terms
    ) {
      teacher
        ? createCoupon()
        : dispatch(CreatePromoCodes(promo)).then((res) => {
            if (res.type.includes("fulfilled")) {
              toast.success("Promo Added Successfully!");
              return navigate(-1);
            } else {
              toast.error(res.payload);
            }
          });
    } else {
      toast.warning("Missing Field");
    }
  };

  const createCoupon = () => {
    dispatch(
      addTeacherCoupons({
        title: promo.title,
        percentage: promo.percentage,
        numOfUsers: promo.numOfUsers,
        maxUsage: promo.maxUsage,
        status: promo.status,
        startDate: promo.startDate,
        endDate: promo.endDate,
        details: promo.details,
        terms: promo.terms,
        courseId,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Promo Added Successfully!");
        return navigate(-1);
      } else {
        toast.error(res.payload);
      }
    });
  };

  return (
    <div className="create__promo">
      {teacher ? <h1>Setup Your Coupon</h1> : <h1>Setup Your Promo Code</h1>}
      <div className="promo__inputs__container">
        {teacher
          ? dataTeachers.map((el) => (
              <PromoInput
                text={el.name}
                type={el.type}
                setPromo={setPromo}
                promo={promo}
                name={el.objName}
                options={el.options}
                setCourseId={setCourseId}
              />
            ))
          : data.map((el) => (
              <PromoInput
                text={el.name}
                type={el.type}
                setPromo={setPromo}
                promo={promo}
                name={el.objName}
                options={el.options}
                setCourseId={setCourseId}
              />
            ))}
      </div>
      <div className="promo__text">
        <div className="textarea">
          {teacher ? <span>Coupon Terms</span> : <span>Promo Code Terms</span>}
          <textarea
            onChange={(e) =>
              setPromo({
                ...promo,
                terms: e.target.value,
              })
            }
          />
          {teacher ? (
            <p>Please enter coupon terms</p>
          ) : (
            <p>Please enter promo code terms</p>
          )}
        </div>
        <div className="textarea">
          {teacher ? (
            <span>Coupon Details</span>
          ) : (
            <span>Promo Code Details</span>
          )}
          <textarea
            onChange={(e) =>
              setPromo({
                ...promo,
                details: e.target.value,
              })
            }
          />
          {teacher ? (
            <p>Please enter coupon details</p>
          ) : (
            <p>Please enter promo code details</p>
          )}
        </div>
      </div>
      <div className="promo__btns">
        <Link to={teacher ? "/teachers/coupon" : "/admin/promo"}>
          <button className="discard">Discard</button>
        </Link>
        <button className="save" onClick={() => handleCreatePromo(promo)}>
          Save
        </button>
      </div>
    </div>
  );
}

const PromoInput = ({
  text,
  type,
  setPromo,
  promo,
  name,
  options,
  setCourseId,
  teacher,
}) => {
  const formatDate = (date) => {
    return moment(date).toISOString();
  };

  return (
    <div className="input">
      <span>{text}</span>
      {type === "select" ? (
        <select
          onChange={(e) => {
            name === "courseId"
              ? setCourseId(e.target.value)
              : setPromo({
                  ...promo,
                  [name]: e.target.value,
                });
          }}
          defaultValue="Please select"
        >
          <option value={"Please select"} disabled>
            Please select
          </option>
          {!options
            ? null
            : name === "courseId"
            ? options.map((op) => (
                <option value={op.value}>{op.label.toLowerCase()}</option>
              ))
            : options.map((op) => (
                <option value={op}>{op.toLowerCase()}</option>
              ))}
        </select>
      ) : (
        <input
          type={type}
          placeholder={text}
          onChange={(e) => {
            if (name === "endDate" || name === "startDate") {
              setPromo({
                ...promo,
                [name]: formatDate(e.target.value),
              });
            } else if (type === "number") {
              setPromo({
                ...promo,
                [name]: parseInt(e.target.value),
              });
            } else
              setPromo({
                ...promo,
                [name]: e.target.value,
              });
          }}
        />
      )}
      {options ? <p>Please select {text}</p> : <p>Please enter {text}</p>}
    </div>
  );
};
