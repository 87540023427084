import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SharedSingleImageUploader, {
  GoodToKnow,
} from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSliceChange,
  registerTeacher,
} from "../../../ReduxToolkit/Slices/teacherSlice";

import Button from "../../../Components/Button/Button";
import { toast } from "react-toastify";
import Note from "../../Components/Note/Note";

const SignupStepNine = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );

  const teacherStudioImages = useSelector(
    (state) => state?.teacher?.teacherToRegister?.studioImages
  );

  const [image, setImage] = useState(teacherToRegister?.profileImage);
  const [studioImages, setStudioImages] = useState(teacherStudioImages ?? []);

  const onSelectFile = (value) => {
    setImage(value);
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, profileImage: value },
      })
    );
  };
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  const handleSubmit = () => {
    if (image?.url) {
      navigate("/teacher-signup/step-ten");
    } else {
      toast.error("Profile image is required");
    }
  };

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          // images={[]}
          notes={[
            "Photos of your cat or dog or dark photos without a clear face will not be accepted.",
            "You can take picture directly through the webcam or upload a picture. ",
            "A very good photo must be: - Clear and directly face front, - clear background or blurred background, - not too big and not more than 300mB",
          ]}
          subtitle={
            "Your profile photo is the highlight of your teaching profile. it is important that it is bright and clear and follows our requirements. "
          }
          className={"self_start"}
        />
      </div>
      <div className="container_2">
        <h2 className="no_max" style={{ margin: "20px 0" }}>
          <span>Profile Photo</span>
        </h2>
        <div className="signup_profile_image_uploader">
          {/* <Note
            title={"Your Profile Photo"}
            p1={"This photo will appear in your public profile!"}
          /> */}
          <SharedSingleImageUploader
            onSelectFile={onSelectFile}
            detectFace={false}
            preview={image}
            MAX_SIZE={MAX_SIZE}
          />
        </div>

        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-eight")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={() => handleSubmit()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupStepNine;
