import React, { useState } from "react";
import "./LessonDescription.styles.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function PageDescription({ img, description, name, lesson }) {
  return (
    <div className="tutor__page__description" id="about-lesson-section">
      <h4>About Lesson</h4>
      {/* <h1>Hya</h1> */}
      {!!lesson.isGroup && lesson.headLine && (
        <div className="description">
          <div className="header">
            <h3>{lesson?.title} headLine</h3>
          </div>
          <LimitedText texts={lesson?.headLine} />
        </div>
      )}
      <div className="description">
        <div className="header">
          <h3>{lesson?.title} Lesson</h3>
        </div>
        <LimitedText
          texts={lesson?.isGroup ? lesson?.summary : lesson?.description}
        />
      </div>
      {lesson?.isGroup && (
        <>
          <div className="description">
            <div className="header">
              <h3>Time commitment</h3>
            </div>
            <LimitedText texts={lesson?.timeCommitment} />
          </div>
          <div className="description">
            <div className="header">
              <h3>Material Needed </h3>
            </div>
            {lesson?.materialsNeeded && (
              <ul>
                {lesson.materialsNeeded.map((material, i) => (
                  <li key={i}>{material}</li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const LimitedText = ({ texts }) => {
  const [showFullText, setShowFullText] = useState(false);
  function limitWords(text, limit) {
    const words = text ? text?.split(" ") : "";
    if (!text) {
      return "undefined text";
    }
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }
  return (
    <div className="description__text">
      {showFullText ? texts : limitWords(texts, 30)}
      {!showFullText ? (
        <span className="showMore" onClick={() => setShowFullText(true)}>
          View more <ExpandMoreIcon />
        </span>
      ) : (
        <span className="showMore" onClick={() => setShowFullText(false)}>
          View less
          <ExpandLessIcon />
        </span>
      )}
    </div>
  );
};
