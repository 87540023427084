import React from "react";
import "./content.styles.scss";
export default function FAQ() {
  const data = [
    {
      header: "Parents",
      title: "What is your rate for lessons?",
      p1: "Answer: We, to a great extent, do not have a fixed price for the lessons as this is a platform for freelancer teachers who dictate their rates. You can choose any category of your choice and your preferred location, your nearest teacher would appear and you can book a trial.",
    },
    {
      title: "How do I book a lesson?",
      p1: "Booking lessons is very easy using our website; www.educify.org  and our android and ios apps. All you need to do is click on your preferred subject; choose your preferred location for your lessons and read the profile of the teacher. You can then book a trial lesson with the teacher.",
    },
    {
      title: "What is the duration of lessons?",
      p1: "Lesson duration is usually determined by the age of the student and the subject of discussion. For music lessons, we offer durations of 30 minutes and 60 minutes. 1 hour is , however, the default for some subjects like Mathematics, English, IT- coding and Sports.",
    },
    {
      title: "I cannot find teachers in my Area, what do I do?",
      p1: "Educify is a growing platform and we are constantly updating the teachers' locations and availability. You can consider our virtual platform in the meantime. It is plug and play and all you need is a web-browser and you are good to go.",
    },
    {
      title: "How are the Lessons held?",
      p1: "The lessons are held online and in-person. We strive to make sure you get a choice of teachers you can choose from. We have the bio, the reviews, the photos of their studios and the videos of the instructors for you to get familiar.",
    },
    {
      title: "Do you offer lesson Packages",
      p1: "We do have packages for all our lessons. You can choose 5 lessons, 10 lessons, 15 lessons or 20 lessons. We, in addition, offer promotional and discounts, ",
    },
    {
      title: "How do I pay for Lessons? ",
      p1: "You pay for lessons through our online portal using a credit card or PayPal. Please bear in mind that classes are valid for 6 month from purchase time and no refund will be available after the expiration of the 6 months.",
    },
    {
      title: "Is there anything I need to have the lessons?",
      p1: "Since we have both in-person and virtual lessons, you need to be aware of the teacher’s studio upon registration or input your address for in-home lessons. The requirement for virtual lessons is a bit different. Here are a few points to take note of:",
      p2: `
    1) Set your camera angle such that the teacher can see your hands on your instrument and your pen and book. 
    2) Test your connection and ensure that your audio and video settings are up to date and working properly.
    3) Use google chrome or Opera web browser and make sure Adblocker is disabled.`,
    },
    {
      title: "What happens if I cancel a class?",
      p1: "We implore you to make sure you cancel lessons at least 10 hours before the agreed time. Last minute cancellation will attract fees and may lead to forfeiture of your paid fees. Remember that the instructors’ time is valuable and must be respected.",
    },
    {
      title: "What is your Cancellation Policy?",
      p1: "Our cancellation policy is 10 hours notice before agreed lesson time. Please note that if you miss class at short notice and do not give appropriate notice, we deserve the right to be compensated.",
    },
    {
      header: "TEACHERS",
      title: "How do I use Educify?",
      p1: "Educify is a platform designed for both in-person and virtual lessons in many subject areas. All you need to do is register and fill out your information, fill out your availability and do the background check, which is through our third-party provider. After your registration is done, the admin will either approve or reject your application.",
    },
    {
      title: "Am I Guaranteed of Students once I register?",
      p1: "No, You are not guaranteed of students as we cannot predict or guarantee students. Be sure, though, that we are doing our utmost to ensure that you get as many students as you can handle. Just be patient and work with us. Many factors, such as population, location, the type of subject, geographic region, pricing and other factors determine whether you get a student or not.",
    },
    {
      title: "How do Students reach out to me?",
      p1: "There are many ways by which students can reach you. Primarily when they book with you and send you a message in the app. When it comes to attracting students, we recommend that you activate trial lessons in your package to attract more parents and students to book with you. You should also be aware that your pricing would affect your attractiveness as a teacher.",
    },
    {
      title: "How does the pricing work?",
      p1: "Educify inc spends money on marketing, management and many other aspects to make sure we promote each teacher’s profile. Hence, the amount we offer per lesson is a combination of what the teacher agreed with us per hour and a differential that takes care of our administrative expenses. This amount also has to be reasonable and competitive in the market.",
    },
    {
      title: "How do I get paid?",
      p1: "Once you register on our platform, you get to put in your bank account details and/or your PayPal account details. Please note that your earnings are subject to local Canadian and US applicable taxes. Your earnings will be sent to your registered account every 2 weeks or one month after accruing a minimum threshold.",
    },
    {
      title: "What do I need to set up for lessons?",
      p1: `Setting up lessons depends on whether you are offering lessons in-person (In-home or in studio) or Virtual (Online). For using your studio, you need to make sure you have a studio location whether in your house or at a location and have all the necessary equipment or tools needed for teaching. Please note that background check is mandatory for any instructor carrying out in-person lessons.
For in-home( Student home) lessons, you must be mobile and be ready to get notification using your mobile app. You will be responsible for ensuring you have your lesson plans and lessons notes in proper order. For virtual lessons, you need to be sure you have a good internet connection, you have a tripod to hold your device, you set up your video device at a location that is optimal for the lesson. Please checkout our ‘Virtual lessons guide’ for more information.
`,
    },
    {
      title: "I am a student now, but would like to register as a teacher",
      p1: " The registration process for teachers and students are very different. If you as a student have the requirements to teach a certain subject, you can contact us ( Check the footer for information). You need to create a teacher profile and pass the background check to be approved on the platform. Your credentials will also be checked and ascertained.",
    },
    {
      title: "How do I unsubscribe as a teacher?",
      p1: "If you do not wish to continue as a teacher on Educify, you can just use your teacher account area, and choose “ I do not wish to receive new students”. To permanently leave the platform, notify the admin and delete your teacher profile. The admin, in the meantime, can deactivate it for you.",
    },
    {
      title: "What if a student is repeatedly absent?",
      p1: "Please notify us immediately so that we can take this issue up with the erring student. This happens in reality and we need to know the reason for their cancellations. It is your responsibility to escalate the issue to the administration of Educify.",
    },
  ];

  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>
        Frequently Asked Questions
      </h1>
      {data.map((section) => (
        <div className="privacy_row">
          {section.header && (
            <h1 style={{ textAlign: "center" }}>{section.header}</h1>
          )}
          <h2>{section.title}</h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          {section.mail ? (
            <a href={`mailto:${section.mail}`}>{section.mail}</a>
          ) : (
            ""
          )}
          <p>{section?.p4}</p>
          <p>{section?.p5}</p>
          <p>{section?.p5}</p>
        </div>
      ))}
    </div>
  );
}
