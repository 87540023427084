import React, { useEffect } from "react";
import "./ViewTeacher.styles.scss";
import "react-circular-progressbar/dist/styles.css";
import WhatsNext from "../../Components/WhatsNext/WhatsNext";
import TeacherPersonalInfo from "../../Components/TeacherPersonalInfo/TeacherPersonalInfo";
import TeacherDetails from "../../Components/TeacherDetails/TeacherDetails";
import TeacherStudents from "../../Components/TeacherStudents/TeacherStudents";
import Participation from "../../Components/Participation/Participation";
import CompletedLessons from "../../Components/CompletedLessons/CompletedLessons";
import { useDispatch } from "react-redux";
import {
  getTeacherDashboard,
  getTeachersById,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { getContacts } from "../../../ReduxToolkit/Slices/userSlice";
import {
  getTeacherAppointmentsByStatus,
  getTeacherPrevBookings,
} from "../../../ReduxToolkit/Slices/lessonsSlice";

export default function ViewTeacher() {
  const dispatch = useDispatch();
  const params = useParams();

  const [teacher, setTeacher] = useState();
  const [dashboard, setDashboard] = useState({
    numOfStudents: 0,
    teacherEarning: 0,
    teacherHours: 0,
    teacherStudents: [],
  });
  const [fetched, setFetched] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [prevBookings, setPrevBookings] = useState([]);

  const { id } = params;

  useEffect(() => {
    dispatch(getTeachersById(id)).then((res) => {
      setTeacher(res.payload);
      setFetched(true);
    });
    dispatch(getTeacherDashboard(id)).then((res) => {
      setDashboard(res.payload);
    });
    dispatch(
      getContacts({
        role: "teacherContacts",
        id,
      })
    ).then((res) => {
      setContacts(res.payload);
    });

    dispatch(getTeacherPrevBookings(id)).then((res) => {
      setPrevBookings(res?.payload?.data?.teacherAppointments);
    });
    dispatch(
      getTeacherAppointmentsByStatus({ teacherId: id, status: "COMPLETED" })
    ).then((res) => {
      setPrevBookings(res?.payload?.data?.teacherAppointments);
    });
  }, []);
  return (
    fetched && (
      <div className="view__teacher__page">
        <TeacherDetails dashboard={dashboard} />
        <div className="view__teacher__row">
          <TeacherPersonalInfo teacher={teacher} />
          <WhatsNext />
        </div>
        <div className="view__teacher__row">
          <TeacherStudents contacts={contacts} />
          {/* <Participation /> */}
          <CompletedLessons prevBookings={prevBookings} />
        </div>
      </div>
    )
  );
}
