import React from "react";
import moment from "moment";

export const BlogCard = ({ title, createdAt, image, onClick, id }) => {
  return (
    <div className="article-card" onClick={() => onClick(title)}>
      <img
        src={image?.url}
        alt="Friends of Medium, Friend Links to My Sample Boosted Stories for Non-Members, ILLUMINATION - Digitalmehmet.com"
        className="article-card__image"
      />
      <div className="article-card__content">
        <h2 className="article-card__title">{title}</h2>
        {/* <p className="article-card__description">
              Thank you for reading and sharing these unique stories on health and
              wellness that appeal to a broad audience
            </p> */}
        <div className="article-card__info">
          <span className="article-card__date">
            {moment(createdAt).format("MMM, D YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
};
