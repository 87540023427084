import React, { useEffect, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Pagination.styles.scss";

export default function Pagination({
  custom,
  page,
  setCurrentPage,
  currentPage,
  dataRendered,
  totalData,
}) {
  const [current, setCurrent] = useState(currentPage);

  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  const handleCurrentClick = (i) => {
    setCurrent(i);
    setCurrentPage(i);
  };

  const paginationRange = () => {
    const pages = [];
    let start = Math.max(2, current - 2);
    let end = Math.min(page - 1, current + 2);

    if (current - 1 <= 3) {
      end = 5;
    }
    if (page - current <= 3) {
      start = page - 4;
    }

    if (page > 1) {
      pages.push(1);
    }
    if (start > 2) {
      pages.push("...");
    }
    for (let i = start; i <= end; i++) {
      if (i < page && i > 1) {
        pages.push(i);
      }
    }
    if (page - end > 1) {
      pages.push("...");
    }
    if (page > 1) {
      pages.push(page);
    }

    return pages;
  };

  return (
    <>
      {page && page > 1 ? (
        <div className={`landing__pagination ${custom ? custom : ""}`}>
          {dataRendered > 0 && totalData > 0 ? (
            <span>{`Showing 1 to ${dataRendered} of ${totalData} entries`}</span>
          ) : (
            <span className="nodata" style={{ display: "none" }}>
              no data
            </span>
          )}
          {!totalData && dataRendered ? (
            <span>{`Showing 1 to ${dataRendered} entries`}</span>
          ) : (
            ""
          )}
          <div className="pages">
            <ArrowBackIosIcon
              onClick={() => {
                if (current > 1) {
                  setCurrent(current - 1);
                  setCurrentPage(currentPage - 1);
                }
              }}
            />
            {paginationRange().map((item, index) => (
              <span
                key={index}
                className={currentPage === item ? "current__page" : "black"}
                onClick={() =>
                  typeof item === "number" && handleCurrentClick(item)
                }
              >
                {item}
              </span>
            ))}
            <ArrowForwardIosIcon
              onClick={() => {
                if (current < page) {
                  setCurrent(current + 1);
                  setCurrentPage(currentPage + 1);
                }
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
