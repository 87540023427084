import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "./Teachers.styles.scss";
import warning from "../../../assets/StudentApp/svgs/warning.svg";
import success from "../../../assets/StudentApp/svgs/success.svg";
import error from "../../../assets/StudentApp/svgs/error.svg";
import TeachersTable from "../../Components/TeachersTable/TeachersTable";
import Alert from "../../Components/Alert/Alert";
import {
  getAdminDashboard,
  getAllUsers,
  getTeachers,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { utils, write } from "xlsx";

export default function Teachers() {
  const dispatch = useDispatch();
  const [teachersPending, setTeachersPending] = useState([]);
  const [teachersAccepted, setTeachersAccepted] = useState([]);
  const [teachersRejected, setTeachersRejected] = useState([]);
  // for pending
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
  });
  const [dashboard, setDashboard] = useState([
    {
      totalBookings: 0,
      totalLessons: 0,
      totalStudents: 0,
      totalTeachers: 0,
    },
  ]);
  useEffect(() => {
    dispatch(getAdminDashboard()).then((res) => setDashboard(res.payload));
  }, []);

  const onSearchPending = (e) => {
    setFilters({ ...filters, search: e.target.value });
  };
  const onSearchAccepted = (e) => {
    setFiltersApproved({ ...filters, search: e.target.value });
  };
  const onSearchRejected = (e) => {
    setFiltersRejected({ ...filters, search: e.target.value });
  };
  // for approved

  const [pageApproved, setPageApproved] = useState(1);
  const [currentPageApproved, setCurrentPageApproved] = useState(1);
  const [filtersApproved, setFiltersApproved] = useState({
    search: "",
  });

  // for Rejected

  const [pageRejected, setPageRejected] = useState(1);
  const [totalRejected, setTotalRejected] = useState(1);
  const [totalPending, setTotalPending] = useState(1);
  const [totalApproved, setTotalApproved] = useState(1);
  const [currentPageRejected, setCurrentPageRejected] = useState(1);
  const [filtersRejected, setFiltersRejected] = useState({
    search: "",
  });

  useEffect(() => {
    // dispatch(getTeachers("limit=100&status=PENDING")).then((res) =>
    //   setTeachersPending(res.payload.teachers)
    // );
    // dispatch(getTeachers("limit=5&status=APPROVED")).then((res) =>
    //   setTeachersAccepted(res.payload.teachers)
    // );
    // dispatch(getTeachers("limit=5&status=REJECTED")).then((res) =>
    //   setTeachersRejected(res.payload.teachers)
    // );
  }, []);

  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${currentPage}&`;
    dispatch(getTeachers(`${allFilters}&limit=5&status=PENDING`)).then(
      (res) => {
        setTeachersPending(res.payload.teachers);
        setPage(res.payload.numOfPages);
        setTotalPending(res.payload.totalTeachers);
      }
    );
  }, [currentPage, filters]);

  useEffect(() => {
    let newFilters = "";
    Object.keys(filtersRejected).map((keyName, i) => {
      let value = filtersRejected[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${currentPageRejected}&`;
    dispatch(getTeachers(`${allFilters}&limit=5&status=REJECTED`)).then(
      (res) => {
        setTeachersRejected(res.payload.teachers);
        setTotalRejected(res.payload.totalTeachers);
        setPageRejected(res.payload.numOfPages);
      }
    );
  }, [currentPageRejected, filtersRejected]);

  useEffect(() => {
    let newFilters = "";
    Object.keys(filtersApproved).map((keyName, i) => {
      let value = filtersApproved[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${currentPageApproved}&`;
    dispatch(getTeachers(`${allFilters}&limit=5&status=APPROVED`)).then(
      (res) => {
        setTeachersAccepted(res.payload.teachers);
        setPageApproved(res.payload.numOfPages);
        setTotalApproved(res.payload.totalTeachers);
      }
    );
  }, [currentPageApproved, filtersApproved]);

  const exportToExcelTeacher = () => {
    dispatch(getAllUsers({ excel: "true", role: "TEACHER" })).then((res) => {
      const data = res.payload;

      if (!data?.length) {
        return toast.error("No users found");
      }
      // Create a new workbook and define the column headers
      const ws = utils.json_to_sheet(data, {
        header: [
          "id",
          "name",
          "lastName",
          "email",
          "phone",
          "createdAt",
          "role",
        ],
      });

      // Create a new workbook and add the worksheet
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate the Excel file as a data URL
      const excelDataUrl = write(wb, { bookType: "xlsx", type: "base64" });

      // Convert the data URL to a Blob
      const byteCharacters = atob(excelDataUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a link and trigger a click event to download the file
      const a = document.createElement("a");
      a.href = url;
      a.download = "teachers.xlsx";
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };

  const getSortValue = (value) => {
    if (value === "name2") {
      return {
        sortField: "name",
        sortOrder: "desc",
      };
    }
    if (value === "name") {
      return {
        sortField: "name",
        sortOrder: "asc",
      };
    }
    if (value === "date") {
      return {
        sortField: "date",
        sortOrder: "desc",
      };
    }
    if (value === "date2") {
      return {
        sortField: "date",
        sortOrder: "asc",
      };
    }
  };
  return (
    <div className="teachers__page">
      <button onClick={() => exportToExcelTeacher()} className="btn_send ">
        Download All Teachers
      </button>
      <TeachersTable
        type={"Teachers Pending"}
        teachers={teachersPending}
        currentPage={currentPage}
        page={page}
        onSortChange={(value) => {
          setFilters({ ...filters, ...getSortValue(value) });
        }}
        setCurrentPage={setCurrentPage}
        onSearch={onSearchPending}
        dashboard={{ ...dashboard, totalTeachers: totalPending }}
        nbRows={3}
      />

      <TeachersTable
        type={"Teachers Accepted"}
        teachers={teachersAccepted}
        currentPage={currentPageApproved}
        page={pageApproved}
        setCurrentPage={setCurrentPageApproved}
        onSearch={onSearchAccepted}
        onSortChange={(value) => {
          setFiltersApproved({ ...filtersApproved, ...getSortValue(value) });
        }}
        dashboard={{ ...dashboard, totalTeachers: totalApproved }}
      />
      <TeachersTable
        type={"Teachers Rejected"}
        teachers={teachersRejected}
        currentPage={currentPageRejected}
        page={pageRejected}
        setCurrentPage={setCurrentPageRejected}
        onSearch={onSearchRejected}
        onSortChange={(value) => {
          setFiltersRejected({ ...filtersRejected, ...getSortValue(value) });
        }}
        dashboard={{ ...dashboard, totalTeachers: totalRejected }}
      />
    </div>
  );
}

const Alerts = () => {
  return (
    <div className="alerts">
      <Alert
        text={"Are you sure you want to delete this payment method? "}
        btn1Text="Yes, delete"
        btn1Color={"F64E60"}
        img={warning}
        btn2
      />

      <Alert
        text={"You have deleted this Payment Method "}
        btn1Text="Ok, got it!"
        btn1Color={"00A3FF"}
        img={success}
      />

      <Alert
        text={
          "Sorry, looks like there are some error detected, please try again."
        }
        btn1Text="Ok, got it!"
        btn1Color={"00A3FF"}
        img={error}
      />
    </div>
  );
};
