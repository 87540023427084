import React from "react";
import DeleteAccount from "../../../TeacherApp/Components/DeleteAccount/DeleteAccount";
import ProfileDetails from "../../../TeacherApp/Components/ProfileDetails/ProfileDetails";
import SignInMethod from "../../../TeacherApp/Components/SignInMethod/SignInMethod";
import "./Settings.styles.scss";
export default function Settings() {
  return (
    <div className="admin__settings">
      <ProfileDetails />
      <SignInMethod />
      <DeleteAccount />
    </div>
  );
}
