import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./404.styles.scss";
export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="notfound">
      <span> 404 Page Not Found</span>
      <button onClick={() => navigate(-1)}>Take Me Back</button>
    </div>
  );
}
export function ServerOffline() {
  return (
    <div className="notfound flex_center">
      <span>
        Oops! It seems that our server is currently offline. Please try again
        later.
      </span>
    </div>
  );
}
export function UserOffline() {
  return (
    <div className="notfound flex_center">
      <span>
        Sorry, you are currently offline. Please check your internet connection
        and try again.
      </span>
    </div>
  );
}
