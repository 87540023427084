import React from "react";

export default function AddQuiz() {
  return (
    <div className="add__quiz">
      <h1>Quiz</h1>
      <p>
        Use the quiz creator to create a multiple choice quiz. You may only have
        1 quiz per lecture.
      </p>
      <div className="inputs">
        <div className="input">
          <span>Type question here</span>
          <input type="text" />
        </div>
        <div className="input">
          <span>Answer Choice</span>
          <div className="with_check">
            <div className="input_check">
              <input type="checkbox" />
            </div>
            <input type="text" />
          </div>
        </div>
        <div className="input">
          <span>Answer Choice</span>
          <div className="with_check">
            <div className="input_check">
              <input type="checkbox" />
            </div>
            <input type="text" />
          </div>
        </div>
        <div className="input">
          <span>Answer Choice</span>
          <div className="with_check">
            <div className="input_check">
              <input type="checkbox" />
            </div>
            <input type="text" />
          </div>
        </div>
        <div className="add__btn">
          <span>Add Question</span>
        </div>
      </div>
      <div className="question__example">
        <h1>What is a Noun</h1>
        <ol>
          <li>
            Use the quiz creator to create a multiple choice quiz. You may only
            have 1 quiz per lecture.
          </li>
          <li>
            Use the quiz creator to create a multiple choice quiz. You may only
            have 1 quiz per lecture
          </li>
          <li>
            Use the quiz creator to create a multiple choice quiz. You may only
            have 1 quiz per lecture.
          </li>
        </ol>
      </div>
    </div>
  );
}
