import React, { useEffect, useState } from "react";
import "./Favorites.styles.scss";
import Course from "../../../Components/Lesson/Lesson";
import { courses } from "../../../assets/HomeApp/data/LandingCourses";
import { SimilarTutorsData } from "../../../assets/SharedApp/data/Tutors";
import Tutor from "../../../SharedApp/Components/Tutor/Tutor";
import Pagination from "../../../Components/Pagination/Pagination";
import Newsletter from "../../../Sections/NewsLetter/Newsletter";
import search from "../../../assets/StudentApp/svgs/Search.svg";
import customFetch from "../../../ReduxToolkit/customFetch/customFetch";
import { useSelector, useDispatch } from "react-redux";
import {
  getStudentFavTeachers,
  getStudentFavCourses,
} from "../../../ReduxToolkit/Slices/studentSlice";

import { useNavigate } from "react-router-dom";
import CourseCard from "../../Components/Courses/CourseCard/CourseCard";
export default function Favorites() {
  const [selectedLesson, setSelectedLesson] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(false);
  const student = useSelector((state) => state.user);
  const [favTeacher, setFavTeachers] = useState([]);
  const [favCourses, setFavCourses] = useState([]);
  const dispatch = useDispatch();
  const disptach = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (student.user.name) {
      dispatch(getStudentFavTeachers(student.user.student.id)).then((res) =>
        setFavTeachers(res.payload)
      );
      dispatch(getStudentFavCourses(student.user.student.id)).then((res) => {
        setFavCourses(res.payload);
      });
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {student.user.name ? (
        <div className="favorites__page">
          <div className="top">
            <div className="fav__container">
              <div className="header">
                <div className="title">
                  <h3>My Learning</h3>
                </div>
                <div className="search__courses">
                  <h2>Favorites</h2>
                  <div className="search">
                    <img src={search} alt="" />
                    <input type="text" placeholder="Search my lessons" />
                  </div>
                </div>
              </div>
              <div className="switch__container">
                <div className="fav__switch">
                  <div className="fav__buttons">
                    <button
                      className={`${selectedLesson ? "selected" : ""}`}
                      onClick={() => {
                        setSelectedLesson(true);
                        setSelectedTeacher(false);
                      }}
                    >
                      my courses
                    </button>
                    <button
                      className={`${selectedTeacher ? "selected" : ""}`}
                      onClick={() => {
                        setSelectedLesson(false);
                        setSelectedTeacher(true);
                      }}
                    >
                      Teachers
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lessons__container">
            <h2>{selectedLesson ? "Courses" : "Teachers"}</h2>
            <div className="fav__tutors">
              {favTeacher.length && selectedTeacher
                ? favTeacher.map((tutor) => (
                    <Tutor
                      name={tutor?.user?.name}
                      img={tutor?.user?.profileImage?.url}
                      id={tutor.id}
                      type="Teacher"
                    />
                  ))
                : favCourses.length && selectedLesson
                ? favCourses.map((course) => (
                    <CourseCard
                      key={course.id}
                      image={course?.profileImage?.url}
                      title={course.title}
                      price={course.price}
                      rating={course.rating}
                      id={course.id}
                      noShadow
                      favorited
                    />
                  ))
                : "Favorite Some Teachers First!"}
            </div>
            {/* <Pagination /> */}
          </div>
          <div className="lesson__newsletter">
            <Newsletter />
          </div>
        </div>
      ) : (
        "You need to login"
      )}
    </>
  );
}
