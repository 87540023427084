import React, { useEffect, useState } from "react";

import "./TeacherQuestions.styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  answerQuestion,
  getQuestionById,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";

function TeacherQuestions() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [question, setQuestion] = useState({ subject: "", question: "" });
  const [answer, setAnswer] = useState("");
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    dispatch(getQuestionById(id))
      .then((Res) => {
        if (typeof Res?.payload?.response?.data?.message === "string") {
          return toast.error(Res?.payload?.response?.data?.message);
        }
        Res.payload.data && setQuestion(Res.payload.data);
      })
      .catch((err) => console.log("error here", err));
  }, [id]);

  const onSubmit = () => {
    setBusy(true);
    dispatch(answerQuestion({ id, answer })).then((Res) => {
      setBusy(false);

      if (Res.payload.data === "Question was sent to the student") {
        toast.success(Res.payload.data);
      } else {
        toast.error(Res.payload.data);
      }
      return navigate("/teachers");
    });
  };

  return (
    <div className="TeacherQuestions_Container">
      <h4>
        <span>Question:</span>
        <span> {question.question}</span>
      </h4>
      <h4>
        <span>Subject:</span>
        <span> {question.subject}</span>
      </h4>
      <input
        type="text"
        placeholder="answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <button
        onClick={() => onSubmit()}
        className={busy ? "fetching blue" : "blue"}
      >
        Submit
      </button>
    </div>
  );
}

export default TeacherQuestions;
