import { Tooltip } from "@mui/material";
import { useRef, useState } from "react";

import volicity from "../../assets/TeacherApp/assets/svgs/volicity.svg";
import example_img from "../../assets/HomeApp/images/tutor.webp";
import remove from "../../assets/SharedApp/svgs/remove.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { uploadImage } from "../../ReduxToolkit/Slices/teacherSlice";
import { deleteImage } from "../../ReduxToolkit/Slices/userSlice";

export default function CourseFileUploader({
  preview,
  onSelectFile,
  example,
  tip,
  MAX_SIZE,
}) {
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const deleteImages = () => {
    dispatch(deleteImage(preview.publicId));
    onSelectFile({
      url: "",
      publicId: "",
    });
  };

  const sendToast = (m) => toast.error(m);

  const [imageLoading, setImageLoading] = useState(false);

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        if (file.size > MAX_SIZE) {
          // Alert user that the image is too large
          sendToast(
            `Image size must be within ${MAX_SIZE / (1024 * 1024)} MB.`
          );
          return;
        }
        setImageLoading(true);
        handleImageUpload(file);
        const objectUrl = URL.createObjectURL(file);
        return () => URL.revokeObjectURL(objectUrl);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    Promise.resolve(dispatch(uploadImage(form))).then((value) => {
      if (!value.type === "uploadImage/fulfilled") {
        setImageLoading(false);
        return sendToast("Error uploading image, please try again");
      }
      setImageLoading(false);
      onSelectFile(value.payload);
    });
  };

  return (
    <div className="image__selector">
      <div className="upload">
        <div className="image">
          <Tooltip title={tip ? tip : ""} arrow placement="top">
            <div className="image-upload ">
              <label htmlFor="file-input" ref={imgRef}>
                {imageLoading ? (
                  <div className="spinner" />
                ) : !preview.fileName ? (
                  <img src={volicity} alt="" className="img__btn" />
                ) : (
                  <p> {preview.fileName}</p>
                )}
              </label>

              <input
                id="file-input"
                type="file"
                accept="application/pdf"
                onChange={handleChange}
              />
            </div>
          </Tooltip>
          {/* <img
            src={edit}
            alt=""
            className="edit action"
            onClick={() => imgRef.current.click()}
          /> */}
        </div>
        <p>Allowed file types: pdf</p>
      </div>
    </div>
  );
}
