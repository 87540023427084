import React, { useEffect } from "react";

import "./Package.styles.scss";

import available from "../../../assets/SharedApp/svgs/Done.svg";
import notAvailable from "../../../assets/SharedApp/svgs/Error.svg";
import axios from "axios";
import { ExchangeRateHook } from "../../../ExchangeRateHook";
import { toast } from "react-toastify";

export default function Package({
  type,
  nbLessons,
  price,
  features,
  openModal,
  scrollToLesson,
  index,
  oldPrice,
  hourly,
  currentCurrency,
  selectedLocation,
  selectedTime,
}) {
  return (
    <div className="package">
      <div className="pack_container">
        <div className="title">
          {type}
          <hr />
        </div>
        <div className="nb__lessons">
          <span>{nbLessons}</span>
          <p>Lessons</p>
        </div>
        <div className="old__price">
          <span>
            <ExchangeRateHook
              price={Number(price.studentTotalAfterFeeBeforePackageDiscount)}
            />
          </span>
        </div>
        <div className="price">
          <span>
            <ExchangeRateHook
              price={Number(price.studentTotalAfterFeeAfterPackageDiscount)}
            />
          </span>
        </div>
        <div className="features">
          {features.map((feat, i) => (
            <span key={i}>
              {feat.name}{" "}
              <img src={feat.available ? available : notAvailable} />
            </span>
          ))}
        </div>
      </div>
      <button
        className="btn__primary"
        onClick={() => {
          if (!selectedLocation) {
            scrollToLesson();
            return toast.error("Select location first");
          }
          if (!selectedTime) return toast.error("Select lesson duration");
          openModal(index);
        }}
      >
        Select
      </button>
    </div>
  );
}
