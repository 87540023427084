import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import "./Promo.styles.scss";
import plus from "../../../assets/AdminApp/svgs/plus.svg";
import view from "../../../assets/AdminApp/svgs/view.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";

import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";

import deleteico from "../../../assets/AdminApp/svgs/deleteico.svg";
import Pagination from "../../../Components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePromoCode,
  getPromoCodes,
} from "../../../ReduxToolkit/Slices/adminSlice";
import moment from "moment";
import {
  deleteTeacherCouponBYID,
  getTeacherCoupons,
} from "../../../ReduxToolkit/Slices/teacherSlice";

export default function Promo({ teacher }) {
  const [promoCodes, setPromoCodes] = useState([]);
  const [showGuide, setShowGuide] = useState(true);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
  });
  const handleCurrentClick = (i) => {
    setCurrentPage(i);
  };
  const { user } = useSelector((state) => state.user);

  const dateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getPromoCodes()).then((res) =>
  //     setPromoCodes(res.payload.promoCodes)
  //   );
  // }, []);

  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${currentPage}&`;
    !teacher
      ? dispatch(getPromoCodes(allFilters)).then((res) => {
          setPromoCodes(res.payload.promoCodes);
          if (!fetched) {
            setPage(res.payload.numOfPages);
            setFetched(true);
          }
        })
      : dispatch(getTeacherCoupons(filters.search)).then((res) => {
          setPromoCodes(res.payload.coupons);
          if (!fetched) {
            setPage(res.payload.numOfPages);
            setFetched(true);
          }
        });
  }, [currentPage, filters, filters.search, refresh]);

  const handleDeletePromo = (id) => {
    teacher
      ? dispatch(deleteTeacherCouponBYID(id)).then((res) => {
          if (res.type.includes("fulfilled")) {
            toast.success("Promo Deleted Succesfully");
            setRefresh(!refresh);
          } else toast.error(res.payload);
        })
      : dispatch(deletePromoCode(id)).then((res) => {
          if (res.type.includes("fulfilled")) {
            toast.success("Promo Deleted Succesfully");
            setRefresh(!refresh);
          } else toast.error(res.payload);
        });
  };
  const steps = [
    {
      element: ".manage_teacher_coupons",
      title: "Coupon Table",
      intro: `This is the Coupon Table, where you can easily access and view the coupons you've created.`,
    },
    {
      element: ".search__teachers",
      title: "Coupon Search",
      intro: `Here you can search for a specific coupon`,
    },
    {
      element: ".create_new_coupon",
      title: "Create a new coupon",
      intro: `Here you can create a new coupon`,
    },
  ];

  return (
    <div className="manage_teacher_coupons">
      {showGuide && (
        <Steps
          enabled={user.helpGuide}
          steps={steps}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="header">
        <div className="header__text">
          {teacher ? <span>Coupon List</span> : <span>Promo Code Lists</span>}
          {/* <p>More than 400+ promo code generated</p> */}
        </div>
        <div className="teachers__actions">
          <div className="search__teachers">
            <input
              type="text"
              placeholder={!teacher ? "Search Promo codes" : "Search Coupons"}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
              className="coupon_list_search"
            />
          </div>
          <div className="create_new_coupon">
            <Link to={"new"} className="new__category">
              <img src={plus} alt="" />

              {teacher ? (
                <span>Add New Coupon</span>
              ) : (
                <span> Add New Promo</span>
              )}
            </Link>
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>
              <span className="th__span">NO</span>
            </th>
            <th>
              <span className="th__span">NAME</span>
            </th>

            <th>
              <span className="th__span">START</span>
            </th>
            <th>
              <span className="th__span">END</span>
            </th>
            <th>
              <span className="th__span">STATUS</span>
            </th>
            <th></th>
          </tr>
          {promoCodes &&
            promoCodes.map((promo, i) => (
              <tr key={i}>
                <td>
                  <div className="manage__std__lesson">
                    <div className="lesson__title">
                      <span>{i + 1}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{promo.title}</span>
                </td>

                <td>
                  <span>{dateFormat(promo.startDate)}</span>
                </td>
                <td>
                  <span>{dateFormat(promo.endDate)}</span>
                </td>
                <td>
                  <span className={promo.status.toLowerCase()}>
                    {promo.status.toLowerCase()}
                  </span>
                </td>

                <td>
                  <div className="category__actions">
                    <div className="actions">
                      <Link
                        to={
                          teacher
                            ? `/teachers/coupon/${promo.id}`
                            : `/admin/promo/${promo.id}`
                        }
                      >
                        <img src={view} alt="" />
                      </Link>
                      <Link
                        to={
                          teacher
                            ? `/teachers/coupon/${promo.id}/edit`
                            : `/admin/promo/${promo.id}/edit`
                        }
                      >
                        <img src={edit} alt="" />
                      </Link>
                      <img
                        src={deleteico}
                        alt=""
                        onClick={() => handleDeletePromo(promo.id)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        dataRendered={promoCodes?.length}
        totalData={promoCodes?.length}
      />
    </div>
  );
}
