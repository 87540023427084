import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "./EditFaq.styles.scss";
import {
  addFAQ,
  getFAQById,
  updateFAQ,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function EditFaq() {
  const [data, setData] = useState({
    answer: "",
    question: "",
    userType: "STUDENT",
  });

  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  let id = params?.id ? params.id : null;

  let addNew = location.pathname.includes("addNew");

  useEffect(() => {
    if (id) {
      dispatch(getFAQById(id)).then((res) => {
        setData(res.payload);
      });
    }
  }, []);

  const onFAQChange = (value, name) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const onSubmit = () => {
    if (addNew) {
      return dispatch(addFAQ(data))
        .then((res) => {
          if (res.type === "addFAQ/fulfilled") {
            alert("A new faq was added, thank you!");
            navigate(-1);
          }
        })
        .catch((err) => {
          alert("Error updating FAQ!");
        });
    } else {
      dispatch(updateFAQ({ id, data }))
        .then((res) => {
          if (res.type === "updateFAQ/fulfilled") {
            alert("FAQ updated, thank you!");
            navigate(-1);
          } else {
            alert("Error updating FAQ!");
          }
        })
        .catch((err) => {
          alert("Error updating FAQ!");
        });
    }
  };
  return (
    (data.answer.length || addNew) && (
      <div className="edit__faq">
        <div className="multi__input">
          <div className="faq__title">
            <span>Title</span>
            <input
              type="text"
              placeholder="Question"
              value={data.question}
              name="question"
              onChange={(e) => onFAQChange(e.target.value, "question")}
            />
          </div>
          <div className="faq__user__type">
            <span>User Type</span>
            <select
              name="userType"
              value={data.userType}
              onChange={(e) => onFAQChange(e.target.value, "userType")}
            >
              <option value="STUDENT">Student</option>
              <option value="TEACHER">Teacher</option>
            </select>
          </div>
        </div>
        <div className="text__actions">
          <ReactQuill
            className="rich__text"
            theme="snow"
            value={data.answer}
            name="answer"
            onChange={(e) => onFAQChange(e, "answer")}
          />
        </div>
        <div className="content__btn">
          <button onClick={() => onSubmit()}>
            {addNew ? "Add" : "Update"}
          </button>
        </div>
      </div>
    )
  );
}
