import React from "react";
import "./Track.styles.scss";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Track({ step, givenData }) {
  const { teachLessons, teachCourses } = useSelector((state) => state.teacher);
  const location = useLocation();

  const locationIncludes = (item) => {
    if (location.pathname.includes("%20")) {
      let newLocation = location.pathname.split("%20").join(" ");
      return newLocation.toLowerCase().includes(item.toLowerCase());
    } else return location.pathname.toLowerCase().includes(item.toLowerCase());
  };

  const data0 = [
    {
      step: 1,
      title: "Add Account",
      subtitle: "Create Custom Account",
    },
    {
      step: 2,
      title: "Your Details",
      subtitle: "Setup Your Details",
    },
    {
      step: 3,
      title: "Profile",
      subtitle: "Setup Your Profile",
    },
  ];
  const data2 = [
    {
      step: 1,
      title: "Add Account",
      subtitle: "Create Custom Account",
    },
    {
      step: 2,
      title: "Your Details",
      subtitle: "Setup Your Details",
    },
    {
      step: 3,
      title: "Profile",
      subtitle: "Setup Your Profile",
    },
    {
      step: 4,
      title: "Course",
      subtitle: "Create a course",
    },
    {
      step: 5,
      title: "Chapters",
      subtitle: "Add Chapters",
    },
    {
      step: 6,
      title: "Completed",
      subtitle: "Review and Submit",
    },
  ];
  const data1 = [
    {
      step: 1,
      title: "Add Account",
      subtitle: "Create Custom Account", // saves user
    },
    {
      step: 2,
      title: "Profile",
      subtitle: "Setup Your Profile", // saves teacher
    },
    {
      step: 3,
      title: "Availability",
      subtitle: "Select Your Availability", // updates availability
    },
    {
      step: 4,
      title: "Pricing",
      subtitle: "Setup Your Lessons' Prices", // adds lesson
    },
    {
      step: 5,
      title: "Completed",
      subtitle: "Review and Submit", // otp here
    },
  ];
  let data = teachLessons ? data1 : teachCourses ? data2 : data0;

  return (
    <div className="track__container flex_center">
      {!givenData
        ? data.map((num, i) => (
            <div
              className={`step ${
                locationIncludes(num.title) ? "selected" : ""
              }`}
              key={i}
            >
              <div className="number">{num.step}</div>
              <div className="text">
                <span>{num.title}</span>
                <p>{num.subtitle}</p>
              </div>
            </div>
          ))
        : givenData.map((num, i) => (
            <div
              className={`step ${num.step == step ? "selected" : ""}`}
              key={i}
            >
              <div className="number">{num.step}</div>
              <div className="text">
                <span>{num.title}</span>
                <p>{num.subtitle}</p>
              </div>
            </div>
          ))}
    </div>
  );
}
