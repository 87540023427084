import React, { useEffect, useState } from "react";
import combo from "../../../../assets/SharedApp/svgs/combp.svg";
import tutor from "../../../../assets/SharedApp/images/tutor.webp";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getTeacherById } from "../../../../ReduxToolkit/Slices/teacherSlice";
import { Link } from "react-router-dom";

export default function AboutCourse({ course }) {
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState([]);

  useEffect(() => {
    course?.teacher?.id &&
      dispatch(getTeacherById(course.teacher.id)).then((res) =>
        setTeacher(res.payload)
      );
  }, [course?.teacher?.id]);

  let objectives = course.whatLearn ? JSON.parse(course.whatLearn) : [];
  let target = course.whoFor ? JSON.parse(course.whoFor) : [];
  let announcements = course.announcements
    ? JSON.parse(course.announcements)
    : [];
  return (
    <div className="about__online__course">
      <h2>About this course</h2>
      <div className="course__stats">
        <div className="row">
          <div className="col">
            <p>Title</p>
          </div>
          <div className="col">
            <div className="info">
              <p>{course.title}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Description</p>
          </div>
          <div className="col">
            <div className="info">
              <p>{course.description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Who is this course for</p>
          </div>
          <div className="col">
            {target.length > 0 &&
              target.map((item, i) => (
                <div className="info" key={i}>
                  <p>{item}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Learning objectives</p>
          </div>
          <div className="col">
            {objectives.length > 0 &&
              objectives.map((item, i) => (
                <div className="info" key={i}>
                  <p>{item}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Announcements</p>
          </div>
          <div className="col">
            {announcements.length > 0 &&
              announcements.map((item, i) => (
                <div className="info" key={i}>
                  <p>{item}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Categories</p>
          </div>
          <div className="col">
            {course?.categories?.length > 0 &&
              course.categories.map((item, i) => (
                <div className="info" key={i}>
                  <p>{item.name}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Price</p>
          </div>
          <div className="col">
            <p>{course.price / 100} USD</p>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col">
            <p>Instructor</p>
          </div>
          <div className="col">
            <div className="current__instructor">
              <img src={teacher?.user?.profileImage?.url} alt="" />
              <div className="instructor__details">
                <span>
                  {teacher?.user?.name} {teacher?.user?.lastName}
                </span>
                <p>{teacher?.title}</p>
              </div>
            </div>
            <p className="big__text" style={{ marginTop: "15px" }}>
              {teacher.description}
            </p>
            {/* <Link className="showMore" to={`/admin/teachers/${teacher?.id}`}>
              <span style={{ color: "#00A3FF" }}>Show More</span>
              <KeyboardArrowDown />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
