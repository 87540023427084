import React from "react";
import "./Alert.styles.scss";
export default function Alert({
  img,
  text,
  btn1Text,
  btn1Color,
  btn2,
  onCancel,
  onConfirm,
  onBlur,
}) {
  return (
    <div className="alert__container">
      <img src={img} alt="" />
      <p>{text} </p>
      <div className="alert__btns">
        <button
          onClick={onConfirm}
          style={{ backgroundColor: `#${btn1Color}` }}
        >
          {btn1Text}
        </button>
        {btn2 && (
          <button onClick={onCancel} className="cancel__btn">
            No, Cancel
          </button>
        )}
      </div>
    </div>
  );
}
