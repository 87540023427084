import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "./AdminSettings.styles.scss";

import plus from "../../../assets/AdminApp/svgs/plus.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";
import deleteico from "../../../assets/AdminApp/svgs/deleteico.svg";
import Pagination from "../../../Components/Pagination/Pagination";
import {
  getAdminList,
  deleteUser,
} from "../../../ReduxToolkit/Slices/adminSlice";

export default function AdminSettings() {
  const dispatch = useDispatch();
  const [admins, setAdmins] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [refresh, setRefersh] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAdminList()).then((res) => setAdmins(res.payload));
  }, [refresh]);

  const onDelete = (id) => {
    dispatch(deleteUser(id)).then((res) => {
      if (res.type === "deleteUser/fulfilled") {
        alert("Account deleted");
        setRefersh(!refresh);
      }
    });
  };

  const onEdit = (id) => {
    navigate(`assign/${id}`);
  };

  return (
    <div className="manage__admin__settings">
      <div className="header">
        <div className="header__text">
          <span>Admins List</span>
          <p>
            {admins.length} {admins.length > 1 ? "Admins" : "Admin"}
          </p>
        </div>
        <div className="teachers__actions">
          {/* <div className="search__teachers">
            <input type="text" placeholder="Search Admins" />
          </div> */}
          {!user.limited && (
            <Link className="new__category" to="assign">
              <img src={plus} alt="" />
              <span>Add New Admin</span>
            </Link>
          )}
        </div>
      </div>
      <div className="students__table">
        <table style={{ tableLayout: "auto" }}>
          <thead>
            <tr>
              <th className="short fitwidth">
                <span className="th__spa">NO</span>
              </th>
              <th>
                <span className="th__span">NAME</span>
              </th>
              <th>
                <span className="th__span">EMAIL</span>
              </th>
              <th>
                <span className="th__span">ROLE </span>
              </th>
              <th>
                <span className="th__span">PHONE </span>
              </th>

              <th>
                <span className="th__span">ACTION</span>
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {admins.length &&
              admins.map((row, i) => (
                <tr key={i}>
                  <td className="short fitwidth">{i}</td>
                  <td>
                    <div className="fitwidth">
                      <div className="lesson__date">
                        <span>
                          {row.name} {row.lastName}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{row.email}</span>
                  </td>
                  <td>
                    <span>
                      {row.limited && row.role === "ADMIN"
                        ? "MANAGER"
                        : row.role}
                    </span>
                  </td>

                  <td>
                    <span>{row.phone}</span>
                  </td>
                  {row.id !== user.id && !user.limited && (
                    <>
                      <td className="No_PADDING">
                        <div className="category__actions">
                          <div
                            className="actions"
                            onClick={() => onDelete(row.id)}
                          >
                            <img src={deleteico} alt="" />
                          </div>
                        </div>
                        <div className="category__actions">
                          <div
                            className="actions"
                            onClick={() => onEdit(row.id)}
                          >
                            <img src={edit} alt="" />
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
}
