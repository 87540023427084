export const options = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
  { value: "Arabic", label: "Arabic" },
  { value: "Chinese", label: "Chinese" },
  { value: "Hindi", label: "Hindi" },
  { value: "French", label: "French" },
  { value: "Russian", label: "Russian" },
  { value: "Bengali", label: "Bengali" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Urdu", label: "Urdu" },
  { value: "German", label: "German" },
  { value: "Japanese", label: "Japanese" },
  { value: "Swahili", label: "Swahili" },
  { value: "Telugu", label: "Telugu" },
  { value: "Marathi", label: "Marathi" },
  { value: "Yoruba", label: "Yoruba" },
  { value: "Igbo", label: "Igbo" },
  { value: "Hausa", label: "Hausa" },
  { value: "Itsekiri", label: "Itsekiri" },
  { value: "Fulani", label: "Fulani" },
  { value: "Swahili", label: "Swahili" },
  { value: "Amharic", label: "Amharic" },
  { value: "Zulu", label: "Zulu" },
  { value: "Afrikaans", label: "Afrikaans" },
  { value: "Sesotho", label: "Sesotho" },
  { value: "Turkish", label: "Turkish" },
  { value: "Somali", label: "Somali" },
  { value: "Akan", label: "Akan" },
  { value: "Lingala", label: "Lingala" },
  { value: "Shona", label: "Shona" },
  { value: "Oromo", label: "Oromo" },
  { value: "Tamil", label: "Tamil" },
  { value: "Korean", label: "Korean" },
  { value: "Italian", label: "Italian" },
  { value: "Dutch", label: "Dutch" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Polish", label: "Polish" },
  { value: "Ukrainian", label: "Ukrainian" },
  { value: "Persian", label: "Persian" },
  { value: "Romanian", label: "Romanian" },
  { value: "Thai", label: "Thai" },
  { value: "Greek", label: "Greek" },
  { value: "Czech", label: "Czech" },
  { value: "Slovak", label: "Slovak" },
  { value: "Hungarian", label: "Hungarian" },
  { value: "Finnish", label: "Finnish" },
  { value: "Danish", label: "Danish" },
  { value: "Norwegian", label: "Norwegian" },
  { value: "Swedish", label: "Swedish" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Armenian", label: "Armenian" },
  { value: "Bulgarian", label: "Bulgarian" },
  { value: "Georgian", label: "Georgian" },
  { value: "Malay", label: "Malay" },
  { value: "Filipino", label: "Filipino" },
  { value: "Serbian", label: "Serbian" },
  { value: "Croatian", label: "Croatian" },
  { value: "Slovenian", label: "Slovenian" },
  { value: "Lithuanian", label: "Lithuanian" },
  { value: "Latvian", label: "Latvian" },
  { value: "Estonian", label: "Estonian" },
  { value: "Icelandic", label: "Icelandic" },
  { value: "Maltese", label: "Maltese" },
  { value: "Albanian", label: "Albanian" },
  { value: "Macedonian", label: "Macedonian" },
  { value: "Bosnian", label: "Bosnian" },
  { value: "Kurdish", label: "Kurdish" },
  { value: "Sinhala", label: "Sinhala" },
  { value: "Nepali", label: "Nepali" },
  { value: "Amharic", label: "Amharic" },
  { value: "Luxembourgish", label: "Luxembourgish" },
  { value: "Chichewa", label: "Chichewa" },
  { value: "Kazakh", label: "Kazakh" },
  { value: "Zulu", label: "Zulu" },
  { value: "Azerbaijani", label: "Azerbaijani" },
  { value: "Uzbek", label: "Uzbek" },
  { value: "Kannada", label: "Kannada" },
  { value: "Pashto", label: "Pashto" },
  { value: "Kyrgyz", label: "Kyrgyz" },
  { value: "Haitian Creole", label: "Haitian Creole" },
  { value: "Swazi", label: "Swazi" },
  { value: "Yoruba", label: "Yoruba" },
  { value: "Galician", label: "Galician" },
  { value: "Fijian", label: "Fijian" },
  { value: "Burmese", label: "Burmese" },
  { value: "Uighur", label: "Uighur" },
  { value: "Dzongkha", label: "Dzongkha" },
  { value: "Tigrinya", label: "Tigrinya" },
  { value: "Kashmiri", label: "Kashmiri" },
  { value: "Lao", label: "Lao" },
  { value: "Malagasy", label: "Malagasy" },
  { value: "Tajik", label: "Tajik" },
  { value: "Kinyarwanda", label: "Kinyarwanda" },
  { value: "Fula", label: "Fula" },
  { value: "Tonga", label: "Tonga" },
  { value: "Mongolian", label: "Mongolian" },
  { value: "Shona", label: "Shona" },
  { value: "Tetum", label: "Tetum" },
  { value: "Sesotho", label: "Sesotho" },
  { value: "Kachin", label: "Kachin" },
  { value: "Creole", label: "Creole" },
  { value: "Chewa", label: "Chewa" },
  { value: "Tumbuka", label: "Tumbuka" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Assamese", label: "Assamese" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Rajasthani", label: "Rajasthani" },
  { value: "Maithili", label: "Maithili" },
  { value: "Korean Sign Language", label: "Korean Sign Language" },
  { value: "Bulgarian Sign Language", label: "Bulgarian Sign Language" },
  { value: "Finnish Sign Language", label: "Finnish Sign Language" },
].sort((a, b) => a.label.localeCompare(b.label));
