import React, { useState } from "react";
import DOMPurify from "dompurify";

import dwnd from "../../../../assets/TeacherApp/assets/svgs/downloadlecture.svg";
import hide from "../../../../assets/TeacherApp/assets/svgs/hide.svg";
import viewImage from "../../../../assets/AdminApp/svgs/view.svg";
import editImage from "../../../../assets/SharedApp/svgs/Button Edit.svg";
import dots from "../../../../assets/TeacherApp/assets/svgs/manyDots.svg";
import del from "../../../../assets/SharedApp/svgs/Button Trash.svg";
import { Code, ImageComponent, Video } from "./EditorPageRowComponents";
import { Item } from "./Item";
import { useDispatch } from "react-redux";
import {
  deleteChapterLesson,
  handleDeleteLesson,
  handleEditLesson,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import { EditLecutre } from "../CurriculumRow/CurriculumRow";

export default function EditorPageRow({ lessonId, item, id, index }) {
  const dispatch = useDispatch();

  const [lesson, setLesson] = useState(item);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);

  let sanitizedContent = DOMPurify.sanitize(lesson.description);

  const onDelete = () => {
    dispatch(
      handleDeleteLesson({
        index,
        subIndex: id,
      })
    );
    dispatch(deleteChapterLesson(lessonId));
  };

  const onEdit = (value, name, code) => {
    setLesson({ ...lesson, [name]: value });
  };
  const onSubmit = (value, name) => {
    dispatch(
      handleEditLesson({
        value: {
          ...item,
          [name]: value,
        },
        index,
        subIndex: id,
      })
    );
  };
  const newCode = (text) => text.split("codeHereToBeAdded")[1];

  let type =
    lesson?.videos.length && lesson.videos[0].url.includes("pdf")
      ? "file"
      : lesson?.videos.length && lesson.videos[0].publicId.includes("videos")
      ? "video"
      : lesson?.videos.length && lesson.videos[0].publicId.includes("images")
      ? "image"
      : lesson?.videos.length && lesson.videos[0].url.includes("pdf")
      ? "file"
      : lesson.description && !lesson.description.includes("codeHereToBeAdded")
      ? "description"
      : "code";

  return (
    <div className="editor__row__container">
      {/* <Item ref={setNodeRef} style={style} {...attributes} {...listeners}>
        Drag
      </Item> */}

      <div className="editor__row">
        <div className="col">
          <img src={dots} alt="" />
          {!edit ? (
            <span className="title flex_center">
              <span>{item.title}</span>
              <img
                alt=""
                src={editImage}
                onClick={() => setEdit(true)}
                className="pointer"
              />
            </span>
          ) : (
            <EditLecutre
              value={item.title}
              setValue={(value) => onSubmit(value, "title")}
              setEdit={() => setEdit(false)}
            />
          )}
        </div>
        <div className="col">
          <div className="actions">
            <img
              src={view ? hide : viewImage}
              alt=""
              onClick={() => setView(!view)}
            />
            <img src={del} alt="" onClick={() => onDelete()} />
          </div>
        </div>
      </div>

      {view && (
        <div>
          {type === "video" ? (
            <Video item={lesson.videos[0].url} />
          ) : type === "image" ? (
            <ImageComponent image={lesson.videos[0].url} />
          ) : type === "code" ? (
            <Code code={newCode(lesson?.description)} />
          ) : type === "description" ? (
            <div className="edit__text">
              <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </div>
          ) : type === "file" ? (
            // <a href={lesson.videos[0].url} target="blank">
            //   link
            // </a>
            <h1>this is a file</h1>
          ) : null}
        </div>
      )}
    </div>
  );
}
