import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";

import "./TeacherEarnings.styles.scss";
import Note from "../../Components/Note/Note";

import earnings from "../../../assets/TeacherApp/assets/images/earnings.webp";
import points from "../../../assets/TeacherApp/assets/svgs/points.svg";
import TeacherTransaction from "../../Components/TeacherTransaction/TeacherTransaction";
import { getUserTransactions } from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import { teacherRequestPayments } from "../../../ReduxToolkit/Slices/teacherSlice";
export default function TeacherEarnings() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [showGuide, setShowGuide] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [wallet, setWallet] = useState(0);
  useEffect(() => {
    dispatch(getUserTransactions())
      .then((res) => {
        setTransactions(res.payload.transactions);
        setInvoices(res.payload.invoices);
        setWallet(res.payload.wallet.balance);
      })
      .catch((err) => console.log("error trans", err));
  }, []);

  const steps = [
    {
      element: ".outlet",
      title: "Transcations",
      intro: `Here you can view the total money you earned on educify.`,
    },
    {
      element: ".transac_col",
      title: "Transcations",
      intro: `Here you can view the transactions you done to your account`,
    },
  ];

  const invoiceExist = () => {
    if (!invoices || !invoices?.length) {
      return false;
    }
    return invoices.some((invoice) => invoice.status === "PENDING");
  };
  const onRequestPayment = () => {
    if (!invoiceExist()) {
      return toast.error("You don't have any pending invoices");
    }
    dispatch(teacherRequestPayments()).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Payment Requested!");
      } else toast.error("You have already requested a payment");
    });
  };

  return (
    <div className="teacher__earnings__page__container">
      <Note
        p1={
          user.role === "TEACHER"
            ? "You can see your earnings below invoices and your past payments under transactions"
            : "If you need to request a request a refund, please go to the help center"
        }
        title="Take Note"
      />
      <div className="teacher__earnings__page">
        {showGuide && (
          <Steps
            steps={steps}
            enabled={user.helpGuide}
            initialStep={0}
            onExit={() => setShowGuide(false)}
            options={{ exitOnOverlayClick: false }}
          />
        )}
        <div className="earnings__container">
          <div className="aaa">
            <div className="col earning_col">
              <div className="total__amount">
                <span>Total Amount in Wallet</span>
                <p>
                  <span>$</span>
                  {(Number(wallet) / 100).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="image">
              <img src={earnings} alt="earnings" />
            </div>
          </div>
          <div className="col transac_col">
            {invoiceExist() && (
              <button
                className="requestPayment"
                onClick={() => onRequestPayment()}
              >
                Request Payment
              </button>
            )}
            <div className="transactions">
              <div className="header">
                <h2>Transactions</h2>
                {/* <div className="more">
                <img src={points} alt="" />
              </div> */}
              </div>
              <div className="list">
                {transactions.length ? (
                  transactions.map((trans, i) => {
                    return (
                      trans.status === "SUCCESS" && (
                        <TeacherTransaction
                          key={i}
                          lessonName={trans.description}
                          price={trans.amount}
                          time={trans.createdAt}
                        />
                      )
                    );
                  })
                ) : (
                  <p>No Transactions yet!</p>
                )}
              </div>
              <div className="header">
                <h2>Invoices</h2>
                {/* <div className="more">
                <img src={points} alt="" />
              </div> */}
              </div>
              <div className="list">
                {invoices?.length ? (
                  invoices.map((trans, i) => {
                    let newStatus = trans.addedToWallet
                      ? "Money added to wallet and pending payment from admin"
                      : trans.status === "PENDING"
                      ? "Pending lesson completion/acceptance"
                      : trans.status;
                    return (
                      <TeacherTransaction
                        key={i}
                        lessonName={
                          trans?.lesson?.title
                            ? `Lesson title: ${trans?.lesson?.title}`
                            : `Course title ${trans?.course?.title}`
                        }
                        price={trans.amountOwed}
                        time={trans.createdAt}
                        status={newStatus}
                      />
                    );
                  })
                ) : (
                  <p>No Invoices yet!</p>
                )}
              </div>
            </div>
            <div className="image img_sm">
              <img src={earnings} alt="earnings" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
