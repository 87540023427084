import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import Button from "../../../Components/Button/Button";
import { SubjectRow } from "../../../Components/SubjectRow/SubjectRow";
import searchIcon from "../../../assets/TeacherApp/assets/svgs/search.svg";
import { countryList } from "../../../assets/SharedApp/data/CountryList";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";

const SignupStepFour = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const [search, setSearch] = useState("");
  const [nationalities, setNationalities] = useState(countryList);
  const [selectedNationalities, setSelectedNationalities] = useState(
    teacherToRegister?.nationalities ?? []
  );

  const goodToKnowNote =
    "Kindly select your nationality from the list to the right. You can select multiple options.";

  const handleSelect = (nat) => {
    if (selectedNationalities.includes(nat)) {
      // Remove from selectedNationalities
      const newSelected = selectedNationalities.filter((el) => el !== nat);
      setSelectedNationalities(newSelected);
      // Add back to nationalities
      setNationalities((prev) => [...prev, nat]);
    } else {
      // Add to selectedNationalities
      setSelectedNationalities((prev) => [...prev, nat]);
      // Remove from nationalities
      const newNationalities = nationalities.filter((el) => el !== nat);
      setNationalities(newNationalities);
    }
  };
  const handleSubmit = () => {
    if (!selectedNationalities.length) {
      return toast.error("Please select your nationality");
    }
    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, nationalities: selectedNationalities },
      })
    );
    // }
    navigate("/teacher-signup/step-five");
  };
  useEffect(() => {
    const filtered = countryList.filter(
      (el) =>
        el.toLowerCase().includes(search.toLowerCase()) &&
        !selectedNationalities.includes(el)
    );
    setNationalities(filtered);
  }, [search, selectedNationalities]);

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[goodToKnowNote]}
          className={"register_note"}
        />
      </div>
      <div className="container_2">
        <h2 style={{ maxWidth: "450px" }}>
          Which <span>Nationality</span> do you have?
        </h2>
        <div className="search_field_container">
          <img src={searchIcon} alt="search" />
          <input
            type="search"
            placeholder="Search countries..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div
          className="subjects_rows_container"
          style={{ gap: "10px", display: "flex", flexDirection: "column" }}
        >
          {selectedNationalities.map((el) => (
            <SubjectRow
              key={el}
              name={el}
              selected
              onClick={() => handleSelect(el)}
            />
          ))}
        </div>
        <div className="subjects_rows_container">
          <div className="subjects_rows">
            {nationalities.map((el) => (
              <SubjectRow key={el} name={el} onClick={() => handleSelect(el)} />
            ))}
          </div>
        </div>
        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup/step-three")}
          >
            Go back
          </Button>
          <Button variant={"primary"} onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupStepFour;
