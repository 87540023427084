import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleUserChange } from "../../../ReduxToolkit/Slices/userSlice";

function DiscountBar({ discount, lesson }) {
  return (
    false &&
    discount &&
    discount > 0 && (
      <div className="DiscountBar_Container flex_col">
        <p>
          Get <span>{discount}%</span> discount on all{" "}
          {lesson ? "lessons" : "online courses"}
        </p>
        {/* <h4>Offer ends in 5h 56m 17s.</h4> */}
      </div>
    )
  );
}
export function BetaBar() {
  const { showBeta } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const onHideClick = () => {
    dispatch(handleUserChange({ name: "showBeta", value: false }));
  };
  return (
    showBeta &&
    false && (
      <div className="BetaBar_Container flex_col">
        {/* <p>
          Welcome to our beta version, designed for initial testing and teacher
          onboarding.
          <button onClick={() => onHideClick()}>x</button>
        </p> */}
        <p>
          Coming soon! We're putting the final, exciting touches on our
          educational platform. Get ready to wait for us—we're about to bring
          something incredible your way!
          <button onClick={() => onHideClick()}>x</button>
        </p>
      </div>
    )
  );
}

export default DiscountBar;
