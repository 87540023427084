import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import Toggle from "react-toggle";
import PropTypes from "prop-types";
import icon from "../../../assets/AdminApp/images/block-user.png";

import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
const localizer = momentLocalizer(moment);
import "./calendar.styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link, useNavigate } from "react-router-dom";

// import tutor from "../../../assets/SharedApp/images/tutor.webp";
// import close from "../../../assets/SharedApp/svgs/Close.svg";
// import down from "../../../assets/SharedApp/svgs/Button.svg";
// import unlock from "../../../assets/SharedApp/svgs/unlock.svg";
// import edit from "../../../assets/SharedApp/svgs/Button Edit.svg";
// import del from "../../../assets/SharedApp/svgs/Button Trash.svg";

import notfound from "../../../assets/SharedApp/images/default.webp";
import {
  completeAppointment,
  getTeacherAppointmentsByStatus,
  getTeacherBookingsByStatus,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  handleTeacherChange,
  toggleAvailable,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import calendarSteps from "./calendarSteps";
import { Steps } from "intro.js-react";

export default function TeacherCalendar({ roster }) {
  const { user } = useSelector((state) => state.user);
  const [showGuide, setShowGuide] = useState(user.helpGuide);

  const [myEvents, setEvents] = useState([]);
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );
  const [selectedType, setSelectedType] = useState(
    roster ? "Roster" : "Calendar"
  );
  const [appointments, setAppointments] = useState([]);
  const [page, setPage] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();
  const getTEachers = () => {
    setBusy(true);
    dispatch(
      getTeacherAppointmentsByStatus({
        teacherId: teacher.id,
        status: "ACCEPTED",
        filters: { limit: 5, page },
      })
    ).then((Res) => {
      if (Res?.payload?.data?.teacherAppointments?.length) {
        setAppointments(Res.payload.data.teacherAppointments);
        setPage(Res.payload.data.numOfPages);
      }
    });
    setBusy(false);
  };

  const { teacher } = useSelector((state) => state.teacher);

  useEffect(() => {
    const formatAppointments = appointments.map((appointment) => {
      const startDate = new Date(appointment.date);
      const startTime = new Date(appointment.startTime);
      const endTime = new Date(appointment.endTime);

      return {
        id: appointment.id,
        title: appointment?.booking?.lesson?.title, // or any other title you wish to use
        start: new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes()
        ),
        end: new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          endTime.getHours(),
          endTime.getMinutes()
        ),
      };
    });

    setEvents(formatAppointments);
  }, [appointments]);
  const [bookingsTodo, setTodoBookings] = useState([]);
  const getTEachersTodo = () => {
    setBusy(true);
    dispatch(
      getTeacherBookingsByStatus({
        teacherId: teacher.id,
        status: "ACCEPTED",
      })
    ).then((Res) => {
      setTodoBookings(Res?.payload?.data);
    });
    setBusy(false);
  };

  useEffect(() => {
    if (teacher.id) {
      getTEachers();
      getTEachersTodo();
    }
  }, [page, refresh]);

  return (
    <div className="teacher_calendar_container_outer">
      <Steps
        enabled={user.helpGuide}
        steps={calendarSteps}
        initialStep={0}
        onExit={() => setShowGuide(false)}
        options={{ exitOnOverlayClick: false }}
      />
      <div className="calendar_header">
        <div style={{ display: "flex" }}>
          <h1>Schedule</h1>
          <div className="calendar_switch">
            {["Calendar", "Roster"].map((el, i) => (
              <span
                onClick={() => setSelectedType(el)}
                key={i}
                className={`${selectedType === el ? "selected" : ""} ${
                  el === "Roster" && "roster_btn"
                }`}
              >
                {el}
              </span>
            ))}
          </div>
        </div>

        <EditAvailabilityToggle />
      </div>
      <div className="teacher_calendar_container">
        <div
          className={`calendar_cont ${
            selectedType === "Calendar" ? "cont_1" : "cont_2"
          }`}
        >
          {selectedType === "Calendar" ? (
            <div className="calendar_cont_inner">
              <Calendar
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                events={myEvents}
                className="teacher_calendar shared_big_calendar"
                views={["week", "month"]}
                localizer={localizer}
                // onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                // selectable
                scrollToTime={scrollToTime}
              />
            </div>
          ) : (
            <StudentsTable
              bookingsTodo={bookingsTodo}
              refreshAttendance={() => setRefresh(!refresh)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const EditAvailabilityToggle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teacher } = useSelector((state) => state.teacher);

  const handleToggle = () => {
    dispatch(toggleAvailable()).then((res) => {
      if (res.type === "toggleAvailable/fulfilled") {
        dispatch(
          handleTeacherChange({ name: "available", value: !teacher.available })
        );
      }
    });
  };

  return (
    <div className="edit_availablity">
      <div className="edit_header">
        <div className="icon-text">
          <img src={icon} alt="available icon" />
          <label>Availability</label>
        </div>
        <Toggle checked={teacher.available} onChange={handleToggle} />
      </div>
      <div className="available_text">
        <p>Available for private lessons</p>
        <button onClick={() => navigate("/teachers/subjects/2")}>
          Edit Availability
        </button>
      </div>
    </div>
  );
};

const StudentsTable = ({ bookingsTodo, refreshAttendance }) => {
  return (
    <div>
      {bookingsTodo.length > 0 &&
        bookingsTodo.map((booking, index) => (
          <div className={`manage__students`} key={index}>
            <div className="header">
              <div className="header__text">
                <span>{booking?.title}</span>
              </div>
            </div>
            <div className="students__table">
              <table>
                <thead>
                  <tr>
                    <th>Students</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {booking?.bookings?.length > 0 ? (
                    booking.bookings.map((row, i) => (
                      <StudentsRow
                        row={row}
                        key={i}
                        i={i}
                        lessonId={booking.id}
                        assign={true}
                        title={booking?.title}
                        refreshAttendance={refreshAttendance}
                      />
                    ))
                  ) : (
                    <span>No students for this lesson yet...</span>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </div>
  );
};

const StudentsRow = ({ i, row, title, lessonId, refreshAttendance }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <tr>
      {isModalOpen && (
        <AttendanceModal
          students={[row.student]}
          student={row.student}
          closeModal={closeModal}
          Appointments={row.Appointments}
          title={title}
          refreshAttendance={refreshAttendance}
          lessonId={lessonId}
        />
      )}
      <td>
        <Link className="student__cell">
          <img
            src={
              row?.student?.user?.profileImage?.url
                ? row?.student?.user?.profileImage?.url
                : notfound
            }
            alt=""
          />
          <div className="name">
            <span>
              {row?.student?.user?.name} {row?.student?.user?.lastName}
            </span>
            {/* <p>{i % 2 == 0 ? "Private" : "Group"}</p> */}
          </div>
        </Link>
      </td>

      {/* <td>
          <div className="manage__std__lesson">
            <div className="lesson__date">{5}</div>
          </div>
        </td> */}
      {/* {!assign && row?.bookings && (
          <td>
            {row.bookings.length > 0 ? (
              <button
                className={row?.bookings[
                  row.bookings.length - 1
                ].status.toLowerCase()}
              >
                {row?.bookings[row?.bookings?.length - 1].status}
              </button>
            ) : (
              <button></button>
            )}
          </td>
        )} */}
      <td>
        <div className="">
          <button className="accepted" onClick={openModal}>
            Take Attendance
          </button>
          {/* <div className="students_attendance">
              {students.map((el) => (
                <div className="attendance_row">
                  <span className="att_std_name">{el.name}</span>
                  <div className="std_opions">
                    <div className="att_op">
                      <span>Attended</span>
                      <input type="radio" name="std_att" id="" />
                    </div>
                    <div className="att_op">
                      <span>Absent</span>
                      <input type="radio" name="std_att" id="" />
                    </div>
                    <div className="att_op">
                      <span>Seomthing else</span>
                      <input type="radio" name="std_att" id="" />
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
        </div>
      </td>
    </tr>
  );
};
const AttendanceModal = ({
  students,
  closeModal,
  Appointments,
  refreshAttendance,
  student,
  lessonId,
  title,
}) => {
  const [newAppointments, setNewAppointments] = useState(
    Appointments
    // Appointments.filter((app) => new Date(app.date) <= new Date()) // Filter for past and today's appointments
  );
  const [saving, setSaving] = useState(false);

  const handleStatusChange = (index, newStatus, id) => {
    setNewAppointments((prevList) => {
      const newList = [...prevList];
      newList[index].status = newStatus;
      newList[index].id = id;
      return newList;
    });
  };
  const dispatch = useDispatch();

  function isPastOrToday(dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    // Remove the time part of the currentDate to compare only the dates
    currentDate.setHours(0, 0, 0, 0);

    // Check if the given date is before or the same as the current date
    return givenDate.setHours(0, 0, 0, 0) <= currentDate.getTime();
  }

  const handleSaveAttendance = async () => {
    setSaving(true);

    if (newAppointments && newAppointments.length > 0) {
      for (const app of newAppointments) {
        if (!app?.date || app.status === "ACCEPTED") {
        } else if (isPastOrToday(app?.date)) {
          try {
            // Make sure to await the dispatch call if it returns a Promise
            const res = await dispatch(
              completeAppointment({ id: app.id, status: app.status })
            );

            // Optionally, call refreshAttendance or any other operation here
          } catch (error) {
            console.error("Error saving appointment", error);
          }
        }
      }

      // Use setTimeout to simulate asynchronous delay or wait for an operation
      setTimeout(() => {
        setSaving(false);
        closeModal();
        refreshAttendance();
      }, 2000);
    }
  };
  return (
    <div className="attendance-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Take Attendance</h2>
          <button onClick={closeModal}>Close</button>
        </div>
        <div className="modal-body">
          {/* <p>Lesson:</p>
            <h4>{title}</h4> */}
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Attendance Status</th>
              </tr>
            </thead>
            <tbody>
              {newAppointments &&
                newAppointments
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((app, index) => (
                    <tr key={index}>
                      <td>{moment(app.date).format("DD/MM/YYYY")}</td>
                      <td>
                        <div className="radio-buttons">
                          <label>
                            <input
                              type="radio"
                              value="COMPLETED"
                              disabled={!isPastOrToday(app?.date)}
                              checked={app.status === "COMPLETED"}
                              onChange={() =>
                                handleStatusChange(index, "COMPLETED", app.id)
                              }
                            />
                            Present
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="REJECTED"
                              disabled={!isPastOrToday(app?.date)}
                              checked={app.status === "REJECTED"}
                              onChange={() =>
                                handleStatusChange(index, "REJECTED", app.id)
                              }
                            />
                            Missing
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="CANCELLED"
                              disabled={!isPastOrToday(app?.date)}
                              checked={app.status === "CANCELLED"}
                              onChange={() =>
                                handleStatusChange(index, "CANCELLED", app.id)
                              }
                            />
                            Cancelled
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            onClick={() => handleSaveAttendance()}
            className={saving ? "flex_center saving" : "flex_center"}
          >
            Save Attendance
            {saving && <div className="spinner" />}
          </button>
        </div>
      </div>
    </div>
  );
};
