import React from "react";
import Pagination from "../../../Components/Pagination/Pagination";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";
import view from "../../../assets/AdminApp/svgs/view.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";
export default function AllPayments() {
  return (
    <div className="teacher_payments">
      <div className="header">
        <h1>Pricing Plan</h1>
        <div className="debt">
          {/* <button className="danger">Clear All Debt</button> */}
        </div>
      </div>
      <div className="payments__table">
        <div className="students__table">
          <table>
            <tr>
              <th>S.NO</th>
              <th>ID</th>
              <th>NAME</th>
              <th>LESSON</th>
              <th>AMOUNT</th>
              <th>DATE</th>
              <th></th>
            </tr>
            {[1, 2, 3, 4, 5, 6].map((row, i) => (
              <tr key={i}>
                <td>{i}</td>
                <td>678935899</td>
                <td>
                  <div className="manage__std__lesson">
                    <div className="lesson__date">
                      <p>Jeff jeffry</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p>English</p>
                </td>
                <td>
                  <p style={{ color: "#50CD89" }}>$400</p>
                </td>
                <td>
                  <p>1/1/2002</p>
                </td>
                <td>
                  {/* <img src={del} alt="" /> */}
                  <div
                    className="actions"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <img src={view} alt="" />
                    <img src={edit} alt="" />
                    <img src={del} alt="" />
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <Pagination page={3} totalData="3" dataRendered={4} />
    </div>
  );
}
