import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { convertTimezone } from "../Timezone";
import "./TeacherAvailability.styles.scss";
import { handleUserChange } from "../../../ReduxToolkit/Slices/userSlice";
import { ExchangeRateHook } from "../../../ExchangeRateHook";
// import { LessonPriceCalculator, StudentFeeCalculator } from "../../../hooks";

export default function TeacherAvailability({
  index,
  locations,
  subjects,
  setIndex,
  lesson,
  lessons,
  availability,
  onBookingClick,
}) {
  const dispatch = useDispatch();
  const { publicSetting } = useSelector((state) => state.admin);
  const { timezone } = useSelector((state) => state.user);
  const setTimezone = (value) => {
    dispatch(
      handleUserChange({
        name: "timezone",
        value,
      })
    );
  };
  const capitialize = (str) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";

  const newPrice = (price) => Math.ceil(price / 100);
  const LessonPriceCalculator = (price) => {
    if (!publicSetting.studentFee) return price;
    let priceAfterStudentFee = price + (price * publicSetting.studentFee) / 100;
    return priceAfterStudentFee;
  };
  const pricesArray = {
    online: lesson?.onlinePrice
      ? LessonPriceCalculator(lesson?.onlinePrice)
      : 0,
    teacherPlace: lesson?.teacherPlacePrice
      ? LessonPriceCalculator(lesson?.teacherPlacePrice)
      : 0,
    studentPlace: lesson?.studentPlacePrice
      ? LessonPriceCalculator(lesson?.studentPlacePrice)
      : 0,
  };

  const locationPrice = {
    online: "Online Price",
    teacherPlace: "Teacher Place Price",
    studentPlace: "Student Place Price",
  };

  const currentDate = new Date();
  const endDate = new Date(lesson?.discountEndDate);
  const discount =
    lesson?.discount && lesson.discount > 0 && endDate > currentDate
      ? lesson.discount
      : 0;
  return (
    <div className="TeacherAvailability">
      <div className="TeacherAvailability__container">
        <h3>Instructor Details</h3>
        <div className="change__sub">
          <span>Change Subjects</span>
          <select
            name=""
            id=""
            onChange={(e) => setIndex(e.target.value)}
            value={index}
          >
            {subjects.map((sub, i) => (
              <option value={i}>{sub}</option>
            ))}
          </select>
        </div>
        <p>
          Teaches {lessons.length} subject
          {lessons.length > 1 &&
            "s. Different subject may have different prices"}
          .
        </p>
        <div className="location">
          <h3>Lesson Location</h3>
          {locations &&
            locations.map((loc, i) => (
              <span key={i}>
                <span>-</span>
                {loc === "teacherPlace"
                  ? "teacher's place"
                  : loc === "studentPlace"
                  ? "your place"
                  : loc}
              </span>
            ))}
        </div>
        <div className="location">
          <h3>Lesson Type</h3>
          {lesson?.isGroup ? (
            <span>Group lesson</span>
          ) : (
            <span>Private Lesson</span>
          )}
        </div>
        {lesson?.isGroup && (
          <div className="location">
            <h3>Group lesson time</h3>

            <span>
              {capitialize(lesson?.groupLessonTime.day)}{" "}
              {convertTimezone(
                moment.utc(lesson?.groupLessonTime.startTime).format("HH:mm"),
                "UTC",
                timezone
              )}{" "}
              -{" "}
              {convertTimezone(
                moment.utc(lesson?.groupLessonTime.endTime).format("HH:mm"),
                "UTC",
                timezone
              )}{" "}
              ({timezone})
            </span>
          </div>
        )}
        <Availabilities
          availability={availability}
          timezone={timezone}
          setTimezone={setTimezone}
        />
        <div className="pricing">
          {locations &&
            locations.map((loc, i) =>
              discount ? (
                <div key={i}>
                  <h3>{locationPrice[loc]}</h3>
                  <div className="price__row">
                    <span>30 minutes</span>
                    <div>
                      <ExchangeRateHook
                        strike
                        price={newPrice(pricesArray[loc]) * 100}
                      />
                      <ExchangeRateHook
                        price={
                          (newPrice(pricesArray[loc]) *
                            100 *
                            (100 - discount)) /
                          100
                        }
                      />
                    </div>
                  </div>
                  <div className="price__row">
                    <span>45 minutes</span>
                    <div>
                      <ExchangeRateHook
                        strike
                        price={newPrice(pricesArray[loc]) * 100 * 1.5}
                      />
                      <ExchangeRateHook
                        price={
                          newPrice(pricesArray[loc]) * 1.5 * (100 - discount)
                        }
                      />
                    </div>
                  </div>
                  <div className="price__row">
                    <span>60 minutes</span>
                    <div>
                      <ExchangeRateHook
                        strike
                        price={newPrice(pricesArray[loc]) * 100 * 2}
                      />
                      <ExchangeRateHook
                        price={
                          newPrice(pricesArray[loc]) * 2 * (100 - discount)
                        }
                      />
                    </div>
                  </div>
                  <div className="price__row">
                    <span>90 minutes</span>
                    <div>
                      <ExchangeRateHook
                        strike
                        price={newPrice(pricesArray[loc]) * 100 * 3}
                      />
                      <ExchangeRateHook
                        price={
                          newPrice(pricesArray[loc]) * 3 * (100 - discount)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div key={i}>
                  <h3>{locationPrice[loc]}</h3>
                  <div className="price__row">
                    <span>30 minutes</span>
                    <ExchangeRateHook
                      price={newPrice(pricesArray[loc]) * 100}
                    />
                  </div>
                  <div className="price__row">
                    <span>45 minutes</span>
                    <ExchangeRateHook
                      price={newPrice(pricesArray[loc]) * 100 * 1.5}
                    />
                  </div>
                  <div className="price__row">
                    <span>60 minutes</span>
                    <ExchangeRateHook
                      price={newPrice(pricesArray[loc]) * 100 * 2}
                    />
                  </div>
                  <div className="price__row">
                    <span>90 minutes</span>
                    <ExchangeRateHook
                      price={newPrice(pricesArray[loc]) * 100 * 3}
                    />
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      <div className="start">
        <h1>Start Learning Today</h1>
        {/* <span>
          Over 500+ already <br /> enrolled
        </span> */}
        <button onClick={() => onBookingClick()}>Book a Trial</button>
      </div>
    </div>
  );
}

const Availabilities = ({ availability, timezone, setTimezone }) => {
  const [selectedDay, setSelectedDay] = useState("MONDAY");
  const [selectedTime, setSelectedTime] = useState("");

  let abb = moment.tz.zone(timezone).abbr(Date.now());

  const allTimezones = moment.tz.names();

  const found = availability.find((date) => date.day === selectedDay);

  return (
    <div className="Availability">
      <h3>Availability</h3>
      <div className="availabity__sectionn">
        <div className="days">
          {availability.map((day) => (
            <span
              className={`${day.day === selectedDay ? "selected" : ""}`}
              onClick={() => {
                setSelectedDay(day.day);
              }}
            >
              {day.day.slice(0, 2)}
            </span>
          ))}
        </div>
        <div className="availablities">
          {found?.times &&
            found?.times.length > 0 &&
            found.times.map((day) => (
              <div className="availablity__item">
                <span
                  onClick={() => setSelectedTime(day.id)}
                  className={`${day.id === selectedTime ? "selectedTime" : ""}`}
                >
                  {convertTimezone(
                    moment.utc(day.startTime).format("HH:mm"),
                    "UTC",
                    timezone
                  )}{" "}
                  -{" "}
                  {convertTimezone(
                    moment.utc(day.endTime).format("HH:mm"),
                    "UTC",
                    timezone
                  )}
                </span>
              </div>
            ))}
        </div>
        <div className="timezone__note">
          <p>
            Times are shown in your local timezone {timezone} ({abb})
          </p>
          <p>Select new time zone:</p>
          <select
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          >
            {allTimezones.map((tz, i) => (
              <option key={i} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
