export const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#F3F5F9",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "none" : "none",
    border: "none",
    borderRadius: "9px",
    boxShadow: state.isFocused ? null : null,
    padding: "0px",
    "&:hover": {
      borderColor: state.isFocused ? "red" : "blue",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "#deebff",
    color: "black",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValue: (base) => ({
    ...base,
    // kill the white space on first and last option
    width: "95px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#464E5F",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#3599fe", // Specify the color you want here
    };
  },
};
