import React, { useState } from "react";
import checkfalse from "../../../../assets/TeacherApp/assets/svgs/check-false.svg";
import checkTrue from "../../../../assets/TeacherApp/assets/svgs/check-true.svg";
import dots from "../../../../assets/TeacherApp/assets/svgs/manyDots.svg";
import twdots from "../../../../assets/TeacherApp/assets/svgs/twodots.svg";

import "./CurriculumRow.styles.scss";
export default function CurriculumRow({ free, type }) {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("New Lecture");

  let lecutureType = type.includes("videos")
    ? "video"
    : type.includes("image")
    ? "image"
    : type;
  return (
    <div className="curriculum__row">
      <div className="col">
        <img src={dots} alt="" />
        <input type="checkbox" />
        {edit ? (
          <EditLecutre value={value} setValue={setValue} setEdit={setEdit} />
        ) : (
          <>
            <span className="lecture__name">New Lecture</span>
            <span className="published">{lecutureType}</span>
            {free && <span className="free">Free Preview</span>}
          </>
        )}
      </div>
      <div className="col">
        <button className="edit__field" onClick={() => setEdit(true)}>
          Edit Lecture
        </button>
        <img src={twdots} alt="" />
      </div>
    </div>
  );
}

export const EditLecutre = ({ setValue, setEdit, value }) => {
  return (
    <div className="flex_center EditLecutre_container">
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <img
        src={checkTrue}
        alt=""
        onClick={() => setEdit(false)}
        className="pointer"
      />
      <img
        src={checkfalse}
        alt=""
        onClick={() => setEdit(false)}
        className="pointer"
      />
    </div>
  );
};
