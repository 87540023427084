import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBlogById } from "../../../ReduxToolkit/Slices/adminSlice";
import moment from "moment";

import "./ViewSharedBlog.styles.scss";
import "quill/dist/quill.snow.css"; // Adjust depending on the Quill theme used

const ViewSharedBlog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [blog, setBlog] = useState({});
  useEffect(() => {
    dispatch(getBlogById(id)).then((res) => {
      if (res.type.includes("fulfilled")) {
        setBlog(res?.payload?.data);
      }
    });
  }, [id, dispatch]);

  return (
    <div className="blog_page_container">
      <div className="blog_container">
        <div className="blog_image_container">
          <img src={blog?.image?.url} alt="Blog" className="blog_image" />
        </div>
        <div className="blog_date">
          <span className="on">Published on </span>{" "}
          <span> {moment(blog?.createdAt).format("MMM, D YYYY")}</span>
        </div>
        <h1 className="blog_title">{blog?.title}</h1>
        <p className="blog_created_date">{blog?.createdDate}</p>
        <div
          className="blog_content ql-editor"
          dangerouslySetInnerHTML={{ __html: blog?.content }}
        />
      </div>
    </div>
  );
};

export default ViewSharedBlog;
