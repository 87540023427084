import React, { useState } from "react";
import "./signup.styles.scss";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import signup from "../../../assets/HomeApp/images/signup.webp";
import facebook from "../../../assets/HomeApp/svgs/facebook.svg";
import google from "../../../assets/HomeApp/svgs/googe-icon-white.svg";

import { Welcome } from "../../Components/Welcome/Welcome";

import { Link, useNavigate } from "react-router-dom";
import { Signup_text } from "../../../assets/SharedApp/data/Auth";
import { terms } from "../../../assets/SharedApp/data/Auth";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  redirectLink,
  setUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import axios from "axios";
import {
  getStudentById,
  registerStudent,
  setStudent,
} from "../../../ReduxToolkit/Slices/studentSlice";
import { toast } from "react-toastify";
import { validatePassword } from "../../../hooks";

import showPassword from "../../../assets/SharedApp/svgs/show.svg";
import hidePassword from "../../../assets/SharedApp/svgs/hide.svg";

export default function Signup() {
  return (
    <div className="signup__container">
      <Welcome text={Signup_text.Welcome} img={signup} />
      <LoginContainer />
    </div>
  );
}

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  const auth = firebase.auth();

  const redirect = useSelector((state) => state.user.redirectLink);

  const userInitial = {
    email: "",
    name: "",
    lastName: "",
    role: "STUDENT",
    password: "",
    address: {
      street: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
    phone: "",
    profileImage: {
      publicId: "",
      url: "",
    },
    gender: "MALE",
    bySocial: false,
    nationality: "",
  };

  const [error, setError] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [referralCode, setReferalCode] = useState("");
  const [user, setUserState] = useState(userInitial);
  const [passType, setPassType] = useState(false);

  const { name, email, password } = user;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserState({ ...user, [name]: value });
  };

  const loginWithGoogle = async () => {
    try {
      const res = await auth.signInWithPopup(googleProvider);
      const googleUser = res.user;
      const firebaseToken = await googleUser.getIdToken();
      const value = {
        name: res.additionalUserInfo.profile.name,
        email: res.additionalUserInfo.profile.email,
        profileImage: {
          url: res?.additionalUserInfo?.profile?.picture
            ? res?.additionalUserInfo?.profile?.picture
            : "none",
          publicId: "none",
          fileName: "none",
        },
        phone: res.user.phoneNumber ? res.user.phoneNumber : user.phone,
        firebaseToken,
        nationality: user.nationality,
        gender: user.gender ? user.gender : "UNSPECIFIED",
        role: "STUDENT",
        bySocial: true,
        address: user.address,
      };
      setUserState(value);
    } catch (error) {
      console.error(error);
    }
  };
  const loginWithFacebook = async () => {
    try {
      const res = await auth.signInWithPopup(facebookProvider);

      const googleUser = res.user;
      const firebaseToken = await googleUser.getIdToken();
      const value = {
        name: res.additionalUserInfo.profile.name,
        email: res.additionalUserInfo.profile.email,
        profileImage: {
          url: res?.additionalUserInfo?.profile?.picture?.data?.url
            ? res?.additionalUserInfo?.profile?.picture?.data?.url
            : "none",
          publicId: "none",
          fileName: "none",
        },
        phone: res.user.phoneNumber ? res.user.phoneNumber : user.phone,
        firebaseToken,
        nationality: user.nationality,
        gender: user.gender ? user.gender : "UNSPECIFIED",
        role: "STUDENT",
        bySocial: true,
        address: user.address,
      };
      setUserState(value);
    } catch (error) {
      console.error(error);
    }
  };

  const registerFunction = (userToRegister) => {
    // if (!validatePassword(password)) {
    //   return toast.error(
    //     "Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one symbol (e.g. !@#$%^&*)"
    //   );
    // }
    dispatch(
      registerStudent({
        user: userToRegister,
        paymentMethod: "CREDIT_CARD",
        referralCode,
      })
    )
      .then((res) => {
        if (res.payload === "Please verify") {
          dispatch(
            setUser({
              email: userToRegister.email,
            })
          );
          toast.success("Please verify your email address to procceed");
          return navigate("/otp");
        }
        if (res.type === "register/rejected") {
          return setError(res.payload);
        } else if (redirect) {
          dispatch(redirectLink(""));
          dispatch(setStudent(res.payload.id));
          dispatch(setUser(res.payload.user));
          return navigate(redirect);
        } else {
          dispatch(setStudent(res.payload.id));
          dispatch(setUser(res.payload.user));
          dispatch(loginUser());
          navigate("/students");
        }
      })
      .catch((error) => console.log("error registiring api", error));
  };

  const handleRegister = async () => {
    if (password !== confirmPwd && !user.bySocial) {
      return setError("Passwords don't match");
    }
    if ((!password || !confirmPwd) && !user.bySocial) {
      return setError("You need to fill all the fields");
    }
    if (!email || !name) {
      return setError("You need to fill all the fields");
    }
    if (!agreement && email && password && confirmPwd && name) {
      return setError("You need to Accept the Terms & Conditions");
    }
    if (isPossiblePhoneNumber(user.phone)) {
      // If the input is a valid phone number, update the state.
      registerFunction(user);
    } else {
      setError("Please enter a valid phone number with a country code.");
    }
  };

  return (
    <div className="inner__login">
      <h1>{Signup_text.header}</h1>
      <p>
        {Signup_text.subHeader[0]}{" "}
        <span>
          <Link style={{ color: "inherit" }} to="/login">
            {" "}
            {Signup_text.subHeader[1]}
          </Link>
        </span>
      </p>
      <p>
        Are you a teacher?
        <span>
          <Link style={{ color: "inherit" }} to="/teacher-signup">
            {" "}
            Sign up here
          </Link>
        </span>
      </p>
      <div className="inputs">
        <div className="signin__with" onClick={() => loginWithGoogle()}>
          <img src={google} alt="" />
          <span>Sign up with Google</span>
        </div>
        {/* <div className="signin__with" onClick={() => loginWithFacebook()}>
          <img src={facebook} alt="" />
          <span>Sign up with Facebook</span>
        </div> */}
      </div>
      <div className="inputs">
        <div className="email">
          <h4>First Name</h4>
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="name"
            value={user.name}
          />
        </div>
        <div className="email">
          <h4>Last Name</h4>
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="lastName"
            value={user.lastName}
          />
        </div>
        <div className="email">
          <h4>{Signup_text.email}</h4>
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            name="email"
            disabled={user.bySocial}
            value={user.email}
          />
        </div>
        <div className="email">
          <h4>Phone Number</h4>
          <input
            type="phone"
            onChange={(e) => handleChange(e)}
            name="phone"
            value={user.phone}
          />
        </div>
        {!user.bySocial && (
          <>
            <div className="password">
              <div className="header">
                {" "}
                <h4>{Signup_text.password}</h4>
              </div>
              <div className="input_with_icon">
                <input
                  type={passType ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => handleChange(e)}
                />
                <img
                  src={passType ? showPassword : hidePassword}
                  alt=""
                  onClick={() => setPassType(!passType)}
                />
              </div>
            </div>
            <div className="password">
              <div className="header">
                <h4>{Signup_text.confirm}</h4>
              </div>
              <input
                type={passType ? "text" : "password"}
                onChange={(e) => setConfirmPwd(e.target.value)}
              />
            </div>
          </>
        )}
        <div className="password">
          <div className="header">
            {" "}
            <h4>Referal Code (optional)</h4>
          </div>
          <input
            type="text"
            name="referralCode"
            value={referralCode}
            onChange={(e) => setReferalCode(e.target.value)}
          />
        </div>
        <div className="agree__condition">
          <input type="checkbox" onChange={() => setAgreement(!agreement)} />
          <span>
            {Signup_text.agreement[0]}
            <Link target="_blank" to={"/terms/students"}>
              <span>{Signup_text.agreement[1]}</span>
            </Link>
          </span>
        </div>
        <div className="signin__btn">
          <button onClick={handleRegister}>{Signup_text.button}</button>
        </div>
        <p className="login__error">{error}</p>
      </div>
      <div className="terms">
        <Link to={"/terms/students"}>
          <span>{terms.terms}</span>
        </Link>
        <span>{terms.Plans}</span>
        <span>{terms.ContactUs}</span>
      </div>
    </div>
  );
};
