import React, { useState } from "react";
import arrow from "../../../assets/AdminApp/svgs/arrow.svg";

export default function InstallationAccordion({ section }) {
  const [height, setHeight] = useState(false);

  return (
    <div
      className="installation__section"
      style={height ? { maxHeight: "200px" } : { maxHeight: "30px" }}
    >
      <div className="section__header">
        <div className="col1">
          <img src={section.icon} alt="" />
          <h2>{section.title}</h2>
        </div>

        <img
          className={height ? "arrow" : "arrow__reverse"}
          onClick={() => setHeight(!height)}
          src={arrow}
          alt=""
        />
      </div>
      <div className="inputs">
        <div className="input">
          <span>{section.input1Ttitle}</span>
          <input type="text" />
        </div>
        <div className="input">
          <span>{section.input2Ttitle}</span>
          <input type="text" />
        </div>
        {section.input3Ttitle && (
          <div className="input">
            <span>{section.input3Ttitle}</span>
            <input type="text" />
          </div>
        )}
      </div>
      <div className="install__btn">
        <button>Submit</button>
      </div>
    </div>
  );
}
