import React from "react";
import { Link } from "react-router-dom";
import Track from "../../../Components/Track/Track";
import "./ReviewAndSubmit.styles.scss";
export default function ReviewAndSubmit({ given }) {
  return (
    <div className="reviewAndSubmit">
      <Track step={4} givenData={given} />
      <div className="cont">
        <h1>Review Your Details</h1>
        <p>
          Please, carefully review all the details provided before publishing.
          Thanks
        </p>

        <div className="review__btns">
          <Link to={"/teachers/courses/create/5"}>
            <button className="setup">Publish</button>
          </Link>
          {/* <button className="setup">Publish</button> */}
        </div>
      </div>
    </div>
  );
}
