import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
import "./viewHomework.styles.scss";

import notfound from "../../../assets/SharedApp/images/default.webp";
import {
  deleteHomework,
  getTeacherHomeworks,
  reviewHomeworkById,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { useDispatch } from "react-redux";
import moment from "moment";

import edit from "../../../assets/SharedApp/svgs/Button Edit.svg";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";
import defaultImage from "../../../assets/AdminApp/images/defautImage.jpg";
import DOMPurify from "dompurify";
import HomeworkDisplay from "../HomeWorks/HomeworkDisplay";
import { HomeworkDisplayComponent } from "../HomeWorks/HomeWorks";
import { toast } from "react-toastify";
export default function ViewStudentsHomeworks() {
  const dispatch = useDispatch();

  const [homeworks, setHomeworks] = useState([]);
  const [homework, setHomework] = useState({});
  const [submission, setSubmission] = useState({});

  useEffect(() => {
    dispatch(getTeacherHomeworks()).then((res) => {
      setHomeworks(res.payload);
    });
  }, []);

  const [showReview, setShowReview] = useState(false);
  const [showStudents, setShowStudents] = useState(false);

  const onShowReview = (i) => {
    setShowReview(true);
    setHomework(homeworks[i]);
  };
  const onShowStudents = (value, i) => {
    setShowStudents(value);
    homework.submissions.length && setSubmission(homework?.submissions[i]);
  };
  return (
    <div className="view_homeworks">
      <LessonHomeworks
        showReview={showReview}
        setShowReview={onShowReview}
        homeworks={homeworks}
      />
      {!!showReview && (
        <StudentsTable
          showStudents={showStudents}
          setShowStudents={onShowStudents}
          homeworks={homeworks}
          homework={homework}
        />
      )}

      {!!showStudents && (
        <HomeworkReview
          showReview={showReview}
          setShowReview={setShowReview}
          submission={submission}
          homework={homework}
        />
      )}
    </div>
  );
}

const LessonHomeworks = ({ showReview, setShowReview, homeworks }) => {
  return (
    <div className={`manage__students homeworks_table`}>
      <div className="header">
        <div className="header__text">
          <span>Lessons</span>
        </div>
        {/* <div className="filter_select">
          <select className="select_cs" defaultValue={"none"}>
            <option value="none" disabled>
              Filter
            </option>
            <option value="reviewed">Reviewed</option>
            <option value="reviewed">Not reviewed</option>
            <option value="reviewed">Start date</option>
            <option value="reviewed">Due date</option>
            <option value="reviewed">Submit date</option>
          </select>
        </div> */}
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>HomeWork</th>
              <th>Lesson</th>
              {/* <th>Submitted</th> */}
              <th>Start Date</th>
              <th>Due Date</th>
              {/* <th>Status</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {homeworks?.length ? (
              homeworks.map((row, i) => (
                <StudentsHomeworkRow
                  setShowReview={setShowReview}
                  showReview={showReview}
                  row={row}
                  key={i}
                  i={i}
                  assign={true}
                />
              ))
            ) : (
              <p>No homeworks yet!</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StudentsHomeworkRow = ({ row, setShowReview, assign = true, i }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const onDelete = () => {
    dispatch(deleteHomework(row.id)).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Homework deleted successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  return (
    !isModalOpen && (
      <tr key={i}>
        <td>
          <span>{row.title}</span>
        </td>
        <td>
          <span>{row?.lesson?.title}</span>
        </td>
        {/* <td>
        <span>{moment(row.updatedAt).format("DD/MM/YYYY")}</span>
      </td> */}
        <td>
          <span>{moment(row.updatedAt).format("DD/MM/YYYY")}</span>
        </td>

        <td>
          <span>{moment(row.dueDate).format("DD/MM/YYYY")}</span>
        </td>
        {/* {row.rated ? (
          <td>
            <button className="approved">reviewed</button>
          </td>
        ) : (
          <td>
            <button className="approved">not reviewed</button>
          </td>
        )} */}
        <td>
          <div className="">
            <button className="accepted" onClick={() => setShowReview(i)}>
              Select Homework
            </button>
          </div>
        </td>
        <td>
          <div className="action__btns">
            {/* <Link to={`/teachers/homework/edit/${row.id}`}>
              <img src={edit} alt="" />
            </Link> */}
            <img
              src={del}
              alt=""
              onClick={() => {
                let confirm = window.confirm(
                  "Are you sure you want to delete this homework?"
                );
                if (confirm) {
                  onDelete();
                  setIsModalOpen(true);
                }
              }}
            />
          </div>
        </td>
      </tr>
    )
  );
};

const StudentsTable = ({
  showStudents,
  setShowStudents,
  homeworks,
  homework,
}) => {
  const sanitizedContent = (text) => DOMPurify.sanitize(text);

  return (
    <div className={`manage__students homeworks_table`}>
      <div>
        <h3>{homework.lesson.title}</h3>
        <h5>{homework.title}</h5>
        <p>Due date: {moment(homework.dueDate).format("DD/MM/YYYY")}</p>
        <div className="cont">
          <div className="custom_teacher_hmw">
            <HomeworkDisplayComponent
              homework={homework}
              i={0}
              viewOnly
              onHomeworkChange={(e) => {}}
            />
          </div>
        </div>
      </div>
      {homework?.submissions.length ? (
        <>
          <div className="header">
            <div className="header__text">
              <span>Submissions</span>
            </div>
            {/* <div className="filter_select">
          <select className="select_cs" defaultValue={"none"}>
            <option value="none" disabled>
              Filter
            </option>
            <option value="reviewed">Reviewed</option>
            <option value="reviewed">Not reviewed</option>
            <option value="reviewed">Start date</option>
            <option value="reviewed">Submit date</option>
          </select>
        </div> */}
          </div>

          <div className="students__table">
            <table>
              <thead>
                <tr>
                  <th>Student</th>
                  <th>Submission Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {homework?.submissions &&
                  homework?.submissions.map((row, i) => (
                    <StudentsRow
                      showStudents={showStudents}
                      setShowStudents={setShowStudents}
                      // showReview={showReview}
                      row={row}
                      key={i}
                      i={i}
                      assign={true}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <h4>No submissions yet</h4>
      )}
    </div>
  );
};

const StudentsRow = ({ row, setShowStudents, i }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <tr>
      <td>
        {/* <span>{row.lesson.title}</span> */}
        <div className="student__cell">
          <img
            src={
              row?.student?.user?.profileImage?.url
                ? row?.student?.user?.profileImage?.url
                : defaultImage
            }
            alt=""
          />
          <div className="name">
            <span>
              {row?.student?.user?.name} {row?.student?.user?.lastName}
            </span>
            {/* <p>English for kids</p> */}
          </div>
        </div>
      </td>

      <td>
        <span>{moment(row.updatedAt).format("DD/MM/YYYY")}</span>
      </td>

      {row.rated ? (
        <td>
          <button className="approved">reviewed</button>
        </td>
      ) : (
        <td>
          <button className="approved">not reviewed</button>
        </td>
      )}
      <td>
        <div className="">
          <button className="accepted" onClick={() => setShowStudents(true, i)}>
            {row.rated ? "View " : "Review"}
          </button>
        </div>
      </td>
      {/* <td>
        <div className="action__btns">
          <Link to={"/teachers/homework/edit"}>
            <img src={edit} alt="" />
          </Link>
          <img src={del} alt="" />
        </div>
      </td> */}
    </tr>
  );
};

// HomeworkReview.js

const HomeworkReview = ({
  showReview,
  setShowReview,
  homework,
  submission,
}) => {
  const [selectedRating, setSelectedRating] = useState(0);
  const [feedbackInput, setFeedbackInput] = useState("");
  const dispatch = useDispatch();
  const handleMarkHomework = () => {
    if (selectedRating === 0) {
      return toast.warning("Please Rate the student's work");
    }
    if (selectedRating > 0) {
      // Add logic for marking homework
      dispatch(
        reviewHomeworkById({
          id: submission.id,
          review: {
            rating: selectedRating,
            feedback: feedbackInput,
            id: submission.id,
          },
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Homework reviewed!");
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      // Show a message or handle the case where no rating is selected
      toast.error("Please select a rating before submitting.");
    }
  };

  const handleRatingChange = (event, newRating) => {
    setSelectedRating(newRating);
  };

  const handleFeedbackInputChange = (e) => {
    setFeedbackInput(e.target.value);
  };

  return (
    <div className="homework-review">
      <h2>Homework Review</h2>
      <div className="homework-submission">
        <h3>Submitted Materials:</h3>
        <HomeworkDisplayComponent
          homework={submission}
          i={0}
          viewOnly
          onHomeworkChange={(e) => {}}
        />
        {/* <div className="material-list">
          {homework.images.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 1}`} />
          ))}
          <div dangerouslySetInnerHTML={{ __html: homework.notes }} />
          <ul>
            {homework.files.map((file, index) => (
              <li key={index}>{file}</li>
            ))}
          </ul>
        </div> */}
        <div className="rating-container">
          <div className="rating-label">Rating</div>
          <Rating
            name="homework-rating"
            value={selectedRating}
            onChange={handleRatingChange}
            precision={0.5}
          />
          {selectedRating > 0 && (
            <div className="selected-rating">
              Selected Rating: {selectedRating}
            </div>
          )}
        </div>
        <div className="feedback-container">
          <div className="feedback-label">
            <h3>Teacher's Feedback:</h3>
          </div>

          <textarea
            rows="4"
            value={feedbackInput}
            onChange={handleFeedbackInputChange}
            className="feedback-input"
          />
        </div>
        <div className="teacher-feedback">
          <div dangerouslySetInnerHTML={{ __html: homework.teacherNote }} />
        </div>

        <button className="submit-button" onClick={() => handleMarkHomework()}>
          Review Homework
        </button>
      </div>
      {homework.rated && (
        <div className="teacher-feedback">
          <h3>Final Feedback:</h3>
          <div dangerouslySetInnerHTML={{ __html: homework.teacherNote }} />
          <p>Rating: {homework.rating}/5</p>
        </div>
      )}
    </div>
  );
};
