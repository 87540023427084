import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

import "./StartCourse.styles.scss";

import CategoryTrack from "../../../Components/Courses/CategoryTrack/CategoryTrack";
import VideoPanel from "../../../Components/Courses/VideoPanel/VideoPanel";
import AboutCourse from "../../../Components/Courses/AboutCourse/AboutCourse";
import OnlineCourseReviews from "../../../Components/Courses/OnlineCourseReviews/OnlineCourseReviews";
import CourseAnnouncements from "../../../Components/Courses/CourseAnnouncements/CourseAnnouncements";
import CourseNotes from "../../../Components/Courses/CourseNotes/CourseNotes";
import {
  ApproveCourse,
  getCourseById,
  getStudentCoursesById,
  getWatched,
  RejectCourse,
  studentWatchedChapter,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import { Code } from "../../../../TeacherApp/Components/Courses/EditorPageRow/EditorPageRowComponents";
import PlanModal from "../../../../TeacherApp/Components/Courses/PlanModal/PlanModal";
import { toast } from "react-toastify";
import { getStudentOwnedCourses } from "../../../../ReduxToolkit/Slices/coursesSlice";
import { updateCloudinaryUrl } from "../../../../hooks";

export default function StartCourse({ teacher }) {
  const [selectedCategory, setSelectedCategory] = useState("Overview");
  const [course, setCourse] = useState({});
  const [selectedVid, setSelectedVid] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [refreshNew, setRefreshNew] = useState(true);
  const [refreshWatch, setRefreshWatch] = useState(false);
  const [busy, setBusy] = useState(false);
  const [watchedLessons, setWatchedLessons] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  const { student } = useSelector((state) => state.student);
  const { user } = useSelector((state) => state.user);

  const sanitizedContent = (text) => DOMPurify.sanitize(text);
  const [studentOwnedCourses, setStudentOwnedCourses] = useState([]);
  const [isAllowedToView, setIsAllowedToView] = useState(null);

  useEffect(() => {
    if (student) {
      dispatch(getStudentOwnedCourses(student)).then((res) =>
        setStudentOwnedCourses(res.payload)
      );
    }
  }, [user]);
  useEffect(() => {
    setBusy(true);
    dispatch(getCourseById(id))
      .then((res) => {
        setBusy(false);
        setCourse(res.payload.data);
      })
      .catch(() => setBusy(false));
  }, []);

  useEffect(() => {
    user.role === "STUDENT" &&
      dispatch(getWatched(student)).then((res) => {
        setWatchedLessons(res.payload.watchedChapterLessons);
      });
  }, [refreshWatch]);

  const idExists = (id) => watchedLessons.some((obj) => obj.id === id);

  const Approve = () => {
    dispatch(ApproveCourse(id)).then((res) => {
      setRefresh(!refresh);
      if (res.type.includes("fulfilled")) {
        toast.success("Course Was Approved");
        setRefreshNew(!refresh);
      } else {
        toast.error("Error Approving Course");
        setRefresh(!refresh);
      }
    });
    setRefresh(!refresh);
  };

  const Reject = () => {
    dispatch(RejectCourse(id)).then((res) => {
      setRefresh(!refresh);
      if (res.type.includes("fulfilled")) {
        toast.success("Course Was Rejected Successfully");
      } else {
        toast.error("Error Rejecting course");
      }
    });
  };

  useEffect(() => {
    if (user.role === "STUDENT") {
      const foundCourse = studentOwnedCourses.find(
        (course) => course.id === id
      );
      if (foundCourse?.id) {
        setIsAllowedToView(true);
      }
    }
    if (user.role === "ADMIN") setIsAllowedToView(true);
    if (user.role === "TEACHER" && user?.teacher?.id === course?.teacher?.id)
      setIsAllowedToView(true);
  }, [id, course, studentOwnedCourses, user]);

  useEffect(() => {
    if (user.role === "STUDENT") {
      dispatch(getStudentCoursesById(id)).then((res) => {
        setCourse(res.payload);
      });
    }
  }, [id]);

  let type =
    selectedVid.videos &&
    selectedVid?.videos.length &&
    selectedVid.videos[0].url.includes("pdf")
      ? "file"
      : selectedVid.videos &&
        selectedVid?.videos.length &&
        selectedVid.videos[0].publicId.includes("videos")
      ? "video"
      : selectedVid.videos &&
        selectedVid?.videos.length &&
        selectedVid.videos[0].publicId.includes("images")
      ? "image"
      : selectedVid?.description &&
        selectedVid.description.includes("codeHereToBeAdded")
      ? "code"
      : "description";

  const newCode = (text) => text.split("codeHereToBeAdded")[1];

  let categories =
    location.pathname.includes("viewCourse") || teacher
      ? ["Overview", "Announcements", "Reviews", "Course Content"]
      : [
          "Overview",
          "Announcements",
          "Reviews",
          "Review Course",
          "Course Content",
        ];

  if (user.role === "TEACHER") {
    categories = ["Overview", "Announcements", "Reviews", "Course Content"];
  }
  const watchChapter = (LessonId) => {
    if (!idExists(LessonId) && user.role === "STUDENT") {
      dispatch(
        studentWatchedChapter({
          studentId: student,
          LessonId,
        })
      ).then(() => setRefreshWatch(!refreshWatch));
    }
  };

  return isAllowedToView === null ? (
    <div className="flex_center spinner_container_startCourse">
      <div className="spinner" />
    </div>
  ) : (
    <>
      {isAllowedToView ? (
        <div
          className={
            teacher
              ? "start__course__page start__course__page__full "
              : "start__course__page"
          }
        >
          <div className="watch__course">
            {selectedVid.chapterId && (
              <div className="Course_Content">
                {type === "video" ? (
                  <div className="watch__video">
                    <p className="code__title">{selectedVid.title}</p>
                    <video
                      src={updateCloudinaryUrl(selectedVid.videos[0].url)}
                      controls
                      onEnded={() => watchChapter(selectedVid.id)}
                      controlsList="nodownload"
                    ></video>
                  </div>
                ) : type === "code" ? (
                  <div className="code__container">
                    <p className="code__title">{selectedVid.title}</p>
                    <Code code={newCode(selectedVid.description)} />
                  </div>
                ) : type === "description" ? (
                  <div className="edit__text">
                    <p className="code__title">{selectedVid.title}</p>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizedContent(selectedVid.description),
                      }}
                    />
                  </div>
                ) : type === "image" ? (
                  <>
                    <div className="image__container">
                      <p className="code__title">{selectedVid.title}</p>

                      <img alt="" src={selectedVid.videos[0].url} />
                    </div>
                  </>
                ) : type === "file" ? (
                  <div className="image__container">
                    <p className="code__title">{selectedVid.title}</p>

                    <a href={selectedVid.videos[0].url} target="_blank">
                      Link
                    </a>
                  </div>
                ) : null}
              </div>
            )}
            {location.pathname.includes("viewCourse") && (
              <div className="admin__actions">
                {course.status === "APPROVED" ? (
                  <h3>This course is approved, would you like to reject it?</h3>
                ) : (
                  <h3>Approve this course</h3>
                )}
                <div className="btn__cont">
                  {course.status === "APPROVED" ? (
                    <button
                      className={busy ? "reject fetching" : "reject"}
                      onClick={() => Reject()}
                    >
                      Reject
                    </button>
                  ) : (
                    <button
                      className={busy ? "fetching approve" : "approve"}
                      onClick={() => Approve()}
                    >
                      Approve
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="course__desc">
              <CategoryTrack
                selected={selectedCategory}
                setSelected={setSelectedCategory}
                categories={categories}
                color={"blue"}
              />
              {selectedCategory === "Overview" ? (
                <AboutCourse course={course} />
              ) : selectedCategory === "Announcements" ? (
                <CourseAnnouncements course={course} />
              ) : selectedCategory === "Reviews" ? (
                <OnlineCourseReviews course={course} />
              ) : selectedCategory === "Course Content" ? (
                <VideoPanel
                  course={course}
                  selectedVid={selectedVid}
                  setSelectedVid={setSelectedVid}
                  customClass="videopanel__sm"
                />
              ) : (
                selectedCategory === "Review Course" && (
                  <PlanModal
                    lesson={course}
                    setShowReview={() => {
                      return;
                    }}
                    course
                  />
                )
              )}
              {/* {selectedCategory === "Notes" && <CourseNotes />} */}
            </div>
          </div>
          <VideoPanel
            course={course}
            selectedVid={selectedVid}
            setSelectedVid={setSelectedVid}
            customClass="videopanel__lg"
            watchChapter={watchChapter}
            watchedLessons={watchedLessons}
          />
        </div>
      ) : (
        <h1 style={{ padding: "190px 50px", margin: "0" }}>
          You need to buy this course first
        </h1>
      )}
    </>
  );
}
