import React, { useEffect } from "react";
import "./TeacherPayments.styles.scss";
import right from "../../../assets/SharedApp/svgs/right.svg";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import PaymentTable from "../../Components/PaymentTable/PaymentTable";
import TransactionTable from "../../Components/TransactionTable/TransactionTable";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getTeacherById,
  getTeacherPaymentInfo,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { useState } from "react";
import {
  getTeacherInvoices,
  getTransactions,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { getStudentById } from "../../../ReduxToolkit/Slices/studentSlice";
export default function TeacherPayments() {
  const params = useParams();

  const { id } = params;
  const dispatch = useDispatch();
  const [userTeacherId, setUserTeacherId] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [student, setStudent] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [bookings, setBookings] = useState([]);

  const location = useLocation();

  let teacher = location.pathname.includes("teachers");
  useEffect(() => {
    teacher &&
      dispatch(getTeacherById(id)).then((res) => {
        setTeacherName(res?.payload?.user?.name);
        setUserTeacherId(res?.payload?.user?.id);
      });

    !teacher &&
      dispatch(getStudentById(id)).then((res) => {
        setStudent(res?.payload?.data);
        setTeacherName(res?.payload?.data?.user?.name);
      });
  }, []);

  useEffect(() => {
    !teacher &&
      student?.user &&
      dispatch(
        getTransactions({
          userId: student.user.id,
          limit: 200,
          studentId: student.id,
        })
      ).then((res) => {
        setTransactions(res.payload.transactions);
        setBookings(res.payload.bookingPayments);
      });
  }, [student]);

  useEffect(() => {
    teacher &&
      userTeacherId &&
      dispatch(
        getTransactions({
          userId: userTeacherId,
          limit: 200,
        })
      ).then((res) => {
        setTransactions(res.payload.transactions);
      });
    teacher && dispatch(getTeacherInvoices(id));
  }, [userTeacherId]);
  const [receiveMethod, setReceiveMethod] = useState({});

  useEffect(() => {
    userTeacherId &&
      dispatch(
        getTeacherPaymentInfo({
          userId: userTeacherId,
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) setReceiveMethod(res?.payload[0]);
      });
  }, [userTeacherId]);

  function mapObjectToHTML(obj) {
    const keys = Object.keys(obj);
    return (
      <>
        {keys
          .filter((el) => obj[el] !== null)
          .map((key) => (
            <div className="sub__row" key={key}>
              <span>{key}</span>
              <p>{obj[key]}</p>
            </div>
          ))}
      </>
    );
  }

  return (
    <div className="teacher__payment__page">
      <div className="payment_submission__details">
        <h3>Payment Details</h3>
        <div className="inner__details">
          <div className="sub__row">
            <span>Receive Method</span>
            <p>{receiveMethod?.receiveMethod}</p>
          </div>
          {receiveMethod?.receiveMethod === "BANK_TRANSFER" ? (
            mapObjectToHTML(receiveMethod.bankDetails)
          ) : (
            <>
              <div className="sub__row">
                <span>Account Holder Name</span>
                <p>{receiveMethod?.accountHolderName}</p>
              </div>
              <div className="sub__row">
                <span>Paypal Email</span>
                <p>{receiveMethod?.paypalGmail}</p>
              </div>
              <div className="sub__row">
                <span>Currency</span>
                <p>{receiveMethod?.currency}</p>
              </div>
            </>
          )}
        </div>
      </div>
      {teacher && (
        <PaymentTable
          teacherId={id}
          userTeacherId={userTeacherId}
          teacherName={teacherName}
        />
      )}
      <TransactionTable
        data={transactions}
        name={teacherName}
        teacher={teacher}
        studentUserId={student?.user?.id}
        studentId={student?.id}
      />
      {/* {bookings && bookings.length > 0 && !teacher && (
        <TransactionTable
          data={bookings}
          bookings
          name={teacherName}
          teacher={teacher}
          studentUserId={student?.user?.id}
          studentId={student?.id}
        />
      )} */}
    </div>
  );
}
