import React, { useEffect, useRef, useState } from "react";

import playvid from "../../../../assets/SharedApp/svgs/playvid.svg";
import "./PreviewModal.styles.scss";
import DOMPurify from "dompurify";
// import thumbnail from "../../../../assets/SharedApp/images/thumbnail.webp";
import fileIcon from "../../../../assets/SharedApp/images/file.png";

import { formatTime, updateCloudinaryUrl } from "../../../../hooks";
import { Code } from "../../../../TeacherApp/Components/Courses/EditorPageRow/EditorPageRowComponents";
export default function PreviewModal({ setShowModal, course }) {
  const [selected, setSelected] = useState(0);
  const type = (lesson) =>
    !lesson
      ? null
      : lesson.videos &&
        lesson?.videos.length &&
        lesson.videos[0].publicId.includes("videos")
      ? "video"
      : lesson?.videos.length && lesson.videos[0].publicId.includes("images")
      ? "image"
      : lesson?.description && lesson.description.includes("codeHereToBeAdded")
      ? "code"
      : "description";

  let selectedLesson = course?.chapters.length
    ? course.chapters[0].lessons[selected]
    : null;
  const newCode = (text) => text.split("codeHereToBeAdded")[1];
  const sanitizedContent = (text) => DOMPurify.sanitize(text);

  return (
    <div className="course__preview__modal">
      <div className="prev__modal__container">
        <div className="header">
          <div className="subtitle">
            <span>Course Preview</span>
            <span onClick={() => setShowModal(false)} className="pointer">
              X
            </span>
          </div>
          <h3>{selectedLesson && selectedLesson.title}</h3>
        </div>
        {type(selectedLesson) === "video" ? (
          <div className="course__preview__video">
            <video
              src={updateCloudinaryUrl(selectedLesson.videos[0].url)}
              controlsList="nodownload"
              controls
            />
          </div>
        ) : type(selectedLesson) === "code" ? (
          <>
            <p className="code__title">Title here</p>
            <Code code={newCode(selectedLesson.description)} />
          </>
        ) : type(selectedLesson) === "description" ? (
          <div className="edit__text">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedContent(selectedLesson.description),
              }}
            />
          </div>
        ) : type(selectedLesson) === "image" ? (
          <img
            alt=""
            src={selectedLesson.videos[0].url}
            className="previewImage"
          />
        ) : null}
        <div className="more__preview__videos">
          <span>Free Sample Videos:</span>
          <div className="video__rows">
            {course?.chapters.length > 0 &&
              course.chapters[0].lessons.map((lesson, i) => (
                <PreviewVideoRow
                  setSelected={setSelected}
                  i={i}
                  selected={selected}
                  lesson={lesson}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const PreviewVideoRow = ({ setSelected, i, selected, lesson }) => {
  return (
    <div
      className={`preview__vid__row ${selected === i ? "vid__selected" : ""}`}
      onClick={() => setSelected(i)}
    >
      {/* {i} */}
      {/* {selected} */}
      <div className="col">
        {/* <img src={thumbnail} alt="" /> */}
        <div className="vid__title">
          <img
            src={
              lesson?.videos[0]?.publicId?.includes("videos")
                ? playvid
                : fileIcon
            }
            alt=""
          />
          <span>{lesson?.title}</span>
        </div>
      </div>
      <div className="time">
        <p>{formatTime(lesson?.videos[0]?.videoLength)}</p>
      </div>
    </div>
  );
};
