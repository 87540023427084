import React from "react";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import right from "../../../assets/SharedApp/svgs/right.svg";
import plus from "../../../assets/AdminApp/svgs/plus.svg";
import view from "../../../assets/AdminApp/svgs/view.svg";
import edit from "../../../assets/AdminApp/svgs/edit.svg";
import deleteico from "../../../assets/AdminApp/svgs/deleteico.svg";
import Pagination from "../../../Components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { deleteFAQs, getFAQs } from "../../../ReduxToolkit/Slices/adminSlice";
import { useState } from "react";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function Faq() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [message, setMessage] = useState({
  //   to: "",
  //   subject: `${user.name} has invited you to join him on Educify and start you learning together!`,
  //   text: `You have been invited by ${user.name}!`,
  //   otp: "",
  //   html: "",
  // });

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
  });
  // useEffect(() => {
  //   dispatch(getFAQs()).then((res) => {
  //     setData(res.payload.faqs);
  //   });
  // }, []);
  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${currentPage}&`;
    dispatch(getFAQs(allFilters)).then((res) => {
      setData(res.payload.faqs);
      if (!fetched) {
        setPage(res.payload.numOfPages);
        setFetched(true);
      }
    });
  }, [currentPage, filters, refresh]);
  const onDelete = (id) => {
    dispatch(deleteFAQs(id));
    setRefresh(!refresh);
    toast.success("Faq Deleted!");
  };

  const onEdit = (id) => {
    navigate(`/admin/faq/${id}/edit`);
  };

  return (
    <div className="manage__admin__categories">
      <div className="header">
        <div className="header__text">
          <span>FAQ Lists</span>
          {/* <p>10 Lists</p> */}
        </div>
        <div className="teachers__actions">
          {/* <div className="search__teachers">
            <input type="text" placeholder="Search FAQ" />
          </div> */}
          <div className="new__category" onClick={() => navigate("addNew")}>
            <img src={plus} alt="" />
            <span> Add New FAQ</span>
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>
              <span className="th__span">NO</span>
            </th>
            <th>
              <span className="th__span">TITLE</span>
            </th>
            <th>
              <span className="th__span">USER TYPE</span>
            </th>
            <th>
              <span className="th__span">CREATED AT</span>
            </th>
            <th></th>
          </tr>
          {data.length > 0
            ? data.map((row, i) => (
                <tr key={i}>
                  <td>
                    <div className="student__cell">
                      <span className="NO">{i + 1}</span>
                    </div>
                  </td>
                  <td>
                    <div className="manage__std__lesson">
                      <div className="lesson__date">
                        <span>{row.question}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{row.userType}</span>
                  </td>

                  <td>
                    <span>{moment(row.createdAt).format("DD/MM/YYYY")}</span>
                  </td>
                  <td>
                    <div className="category__actions">
                      <div className="actions">
                        <img src={edit} alt="" onClick={() => onEdit(row.id)} />
                        <img
                          src={deleteico}
                          alt=""
                          onClick={() => onDelete(row.id)}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
}
