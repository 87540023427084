import React from "react";
import pcgroup from "../../../assets/TeacherApp/assets/images/pcgrp.png";

const WallPaper = () => {
  return (
    <div className="wallpaper">
      <img src={pcgroup} alt="" />
    </div>
  );
};

export default WallPaper;
