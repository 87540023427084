import React, { useState, useEffect, useRef } from "react";
import "./Packages.styles.scss";

import Package from "../../Components/Package/Package";
import PackageFooter from "../../Components/PackageFooter/PackageFooter";
// import { packages30 } from "../../../assets/SharedApp/data/Packages";
// import { packages60 } from "../../../assets/SharedApp/data/Packages";

// import EnrollModal from "../../Components/EnrollModal/EnrollModal";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getLessonPackages } from "../../../ReduxToolkit/Slices/paymentSlice";
import {
  addGeoLocation,
  redirectLink,
} from "../../../ReduxToolkit/Slices/userSlice";
import {
  addAvandedCart,
  refreshStudentCart,
  setStudentState,
} from "../../../ReduxToolkit/Slices/studentSlice";
import {
  getLessonById,
  handleLessonChange,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
// import ProfileDetails from "../../../TeacherApp/Components/ProfileDetails/ProfileDetails";
// import axios from "axios";
import Note from "../../../TeacherApp/Components/Note/Note";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
// import close from "../../assets/Close.svg";
// import close from "../../../assets/TeacherApp/assets/images/close.webp";
import remove from "../../../assets/SharedApp/svgs/remove.svg";

import Slider from "react-slick";
import { toast } from "react-toastify";

function Packages() {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const { location } = useSelector((state) => state.lessons);
  const { lessonLocation } = useSelector((state) => state.student);
  const { cartItems } = useSelector((state) => state.student);
  const [hourly, setHourly] = useState(0);
  const [groupLesson, setGroup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState(30);
  const [packages, setPackages] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(lessonLocation);
  const [exchangeRate, setExchangeRate] = useState(0);

  const [showMapModal, setShowMapModal] = useState(false);
  const [studentCanConitnue, setStudentCanConitnue] = useState(false);
  const [geo, setGeo] = useState({ lat: 0, lng: 0 });
  const [studentGeo, setStudentGeo] = useState({
    lat: 0,
    lng: 0,
  });
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
  };
  // let missingAddress = true;
  // selectedPlace === "studentPlace" &&
  // (!user.address.city ||
  //   !user.address.country ||
  //   !user.address.state ||
  //   !user.address.street ||
  //   !user.address.zipCode);

  const handleDistanceChange = (value) => {
    dispatch(
      handleLessonChange({
        name: "studentDistance",
        value,
      })
    );
  };

  const [lessonPackage, setLessonPackage] = useState();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getLessonPackages({ selectedPlace, id })).then((res) => {
      setPackages(res.payload);
    });
  }, [selectedPlace]);
  useEffect(() => {
    dispatch(getLessonById(id)).then((res) => {
      setLessonPackage(res.payload.data);
      setGeo({
        lng: res.payload?.data?.teacher?.user?.geoLocation?.longitude,
        lat: res.payload?.data?.teacher?.user?.geoLocation?.latitude,
      });
      setGroup(res.payload.data.isGroup);
      setHourly(res.payload.data.price);
    });
  }, [refresh]);

  let newPackages =
    packages?.length > 0
      ? packages.filter((element) => {
          return element.duration === selected && element.status === "ACTIVE";
        })
      : [];

  const { cartItemsRefresh } = useSelector((state) => state.student);
  const onModalOpen = (i) => {
    const lessonId = lessonPackage.id;
    const isLessonInCart = cartItems?.some(
      (item) => item.lesson.id === lessonId
    );
    dispatch(
      addAvandedCart({
        lessonId: lessonPackage.id,
        packageId: newPackages[i].id,
        location: selectedPlace,
        lessonLength: selected,
      })
    ).then(() => {
      dispatch(refreshStudentCart(!cartItemsRefresh));
    });

    dispatch(
      setStudentState({
        name: "selectedPackage",
        value: newPackages[i],
      })
    );
    dispatch(
      setStudentState({
        name: "lessonLocation",
        value: selectedPlace,
      })
    );
    dispatch(
      setStudentState({
        name: "lessonLength",
        value: selected,
      })
    );
    dispatch(
      handleLessonChange({
        name: "selectedLesson",
        value: lessonPackage,
      })
    );
    if (!user.name) {
      dispatch(redirectLink("/date"));
      navigate("/login");
    } else {
      if (user.role !== "STUDENT") {
        toast.error("You have to be a student to access this route");
        return navigate(-1);
      }
      navigate("/date");
    }
  };

  useEffect(() => {
    if (studentCanConitnue) {
      if (selectedPlace === "studentPlace") {
        setShowMapModal(true);
      }
    }
  }, [location]);

  const lessonRef = useRef(null);

  const scrollToLesson = () => {
    lessonRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="packages__page" id="lesson_location" ref={lessonRef}>
      {/* {missingAddress && (
        <div className="profile_details">
          <ProfileDetails missing setRefresh={setRefresh} refresh={refresh} />
        </div>
      )} */}

      <div className="packages__container">
        {selectedPlace === "online" ? (
          <Note
            title={
              "To participate in online lessons, you must either use a desktop computer or install the Jitsi mobile app"
            }
            a1="Click here to install Jitsi on your mobile"
            link2="https://jitsi.org/downloads/"
            marginTops
          />
        ) : null}
        <h1>Choose Lesson Packages</h1>
        <div className="flex_center">
          {!groupLesson && (
            <div className="flex_center">
              <span className="select">Select Lesson Location</span>
              <div className="place__switch">
                {location.length > 0 &&
                  location.map((place, i) => (
                    <span
                      className={`${
                        selectedPlace !== "studentPlace" &&
                        selectedPlace === place
                          ? "selected"
                          : ""
                      }
                      ${
                        selectedPlace === "studentPlace" &&
                        studentCanConitnue &&
                        selectedPlace === place &&
                        "selected"
                      }
                        `}
                      onClick={() => {
                        if (place === "studentPlace") setShowMapModal(true);
                        else setSelectedPlace(place);
                      }}
                      value={selectedPlace}
                      key={i}
                    >
                      {place === "teacherPlace"
                        ? "Teacher's studio"
                        : place === "studentPlace"
                        ? "Your Place"
                        : "Online"}
                    </span>
                  ))}
              </div>
            </div>
          )}
          <div className="flex_center">
            <span className="select">Select Lesson Duration</span>
            <div className="switch">
              <span
                className={`${selected === 30 ? "selected" : ""}`}
                onClick={() => setSelected(30)}
              >
                30mins
              </span>
              <span
                className={`${selected === 45 ? "selected" : ""}`}
                onClick={() => setSelected(45)}
              >
                45mins
              </span>
              <span
                className={`${selected === 60 ? "selected" : ""}`}
                onClick={() => setSelected(60)}
              >
                60mins
              </span>
              <span
                className={`${selected === 90 ? "selected" : ""}`}
                onClick={() => setSelected(90)}
              >
                90mins
              </span>
            </div>
          </div>
        </div>
        {((selectedPlace === "studentPlace" && studentCanConitnue) ||
          selectedPlace !== "studentPlace") && (
          <>
            {!newPackages?.length ? null : newPackages.length >= 4 ||
              windowWidth <= 700 ? (
              <div className="packages">
                <Slider
                  {...settings}
                  responsive={[
                    {
                      breakpoint: 1250,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 975,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 700,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                  className="packages_slider"
                >
                  {newPackages &&
                    newPackages.length > 0 &&
                    newPackages
                      .sort((a, b) => a.numOfLessons - b.numOfLessons)
                      .map((pack, i) => (
                        <Package
                          key={i}
                          index={i}
                          type={pack.name}
                          hourly={hourly}
                          price={pack.studentTotalPrice}
                          nbLessons={pack.numOfLessons}
                          scrollToLesson={scrollToLesson}
                          features={pack.offers}
                          openModal={onModalOpen}
                          selectedLocation={selectedPlace}
                          selectedTime={selected}
                        />
                      ))}
                </Slider>
              </div>
            ) : (
              <>
                {(selectedPlace !== "studentPlace" || studentCanConitnue) && (
                  <>
                    <div className="flex_packages">
                      {newPackages &&
                        newPackages.length > 0 &&
                        newPackages
                          .sort((a, b) => a.numOfLessons - b.numOfLessons)
                          .map((pack, i) => (
                            <Package
                              key={i}
                              scrollToLesson={scrollToLesson}
                              index={i}
                              type={pack.name}
                              hourly={hourly}
                              price={pack.studentTotalPrice}
                              nbLessons={pack.numOfLessons}
                              features={pack.offers}
                              openModal={onModalOpen}
                              selectedLocation={selectedPlace}
                              selectedTime={selected}
                            />
                          ))}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}

        {/* <PackageFooter /> */}
      </div>
      {showMapModal && (
        <MapModal
          geo={geo}
          userId={user.id}
          setGeo={setGeo}
          handleDistanceChange={handleDistanceChange}
          setShowMapModal={setShowMapModal}
          lesson={lessonPackage}
          studentCanConitnue={studentCanConitnue}
          setStudentCanConitnue={setStudentCanConitnue}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
      )}
    </div>
  );
}

export default Packages;

export const MapModal = ({
  geo,
  setGeo,
  userId,
  setShowMapModal,
  lesson,
  handleDistanceChange,
  isSettings,
  setStudentCanConitnue,
  studentCanConitnue,
  setSelectedPlace,
  selectedPlace,
}) => {
  const [value, setValue] = useState("");
  const [result, setResult] = useState("");
  // const [teacherRadius, setTeacherRadius] = useState(1);
  const [marker, setMarker] = useState(null); // State to hold the marker instance
  const [showMarker, setShowMarker] = useState(false); // State to hold the marker instance

  const [studentGeo, setStudentGeo] = useState({
    lat: 0,
    lng: 0,
  });
  const citymap = {
    LEBANON: {
      center: geo,
    },
  };

  useEffect(() => {
    // Create the map.
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 12,
      center: geo,
      mapTypeId: "terrain",
    });

    const initialMarker = new window.google.maps.Marker({
      position: geo,
      map: map,
      title: "Marker Title", // You can customize this
    });

    setMarker(initialMarker); // Store the marker instance in state
    if (showMarker) {
      const customMarker = new window.google.maps.Marker({
        position: studentGeo,
        map: map,
        title: "Student's Location",
        // icon: {
        //   url: "path/to/custom_marker_icon.png", // Specify the path to your custom marker icon
        //   scaledSize: new window.google.maps.Size(40, 40), // Customize the size of the icon
        // },
        label: {
          text: "S", // Customize the label text
          color: "white", // Customize the label text color
          fontWeight: "300", // Customize the label font weight
        },
        zIndex: 100, // Increase the zIndex to make it appear on top of other markers
      });
      setMarker(customMarker);
      const lineCoordinates = [studentGeo, geo];
      if (showMarker) {
        const line = new window.google.maps.Polyline({
          path: lineCoordinates,
          geodesic: true,
          strokeColor: "#36c0f4", // Color of the line
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: map,
        });
      }
    }

    for (const city in citymap) {
      const cityCircle = new window.google.maps.Circle({
        strokeColor: "#fff",
        strokeOpacity: 0,
        strokeWeight: 1,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        center: citymap[city].center,
        radius: lesson?.travelDistance * 1000,
      });
    }

    // map.addListener("click", handleMapClick); // Add click listener to the map

    if (map) {
      map.setCenter(geo);
    }

    const newMarker = new window.google.maps.Marker({
      position: geo,
      map: map,
      title: "New Marker", // You can customize this
    });

    setMarker(newMarker);
    if (studentGeo.lat != 0 && studentGeo.lng != 0)
      checkAddresses(studentGeo, geo, lesson?.travelDistance * 1000);
  }, [showMarker, geo, studentGeo.lat]);

  const getGeoByAddress = (e) => {
    setValue(e);
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setStudentGeo({ lat, lng });
        dispatch(
          addGeoLocation({
            latitude: lat,
            longitude: lng,
            userId,
          })
        );
      });
  };

  const dispatch = useDispatch();
  const checkAddresses = (studentGeo, geo, radius) => {
    setShowMarker(true);
    const radiusInKm = radius / 1000;
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        studentGeo,
        geo
      );
    const distanceInKm = distance / 1000; // Convert distance to kilometers
    const newDistance = distanceInKm - 1; // Corrected calculation
    handleDistanceChange(distanceInKm);

    if (distanceInKm < radiusInKm) {
      setResult(
        `Great! You are within the teacher's Travel zone ${distanceInKm.toFixed(
          2
        )} Kilometer`
      );
      // alert(`Inside within ${newDistance.toFixed(3)} Kilometers`);
    } else {
      setResult(`outside within ${newDistance.toFixed(2)} KM`);
      // alert(`outside within ${newDistance.toFixed(2)} KM`);
    }
  };

  return (
    <div className="map_modal">
      <div className="container">
        <img
          src={remove}
          alt=""
          className="close_mapModal"
          onClick={() => setShowMapModal(false)}
        />
        <div className="info">
          {!isSettings && (
            <h1>
              {`${lesson?.teacher?.user?.name} has a ${lesson?.travelDistance} KM
            travel area`}
            </h1>
          )}
          <p>
            {!isSettings
              ? "Let's make sure your lesson location is within range"
              : "Please setup your location"}
          </p>

          <div className="search_location">
            <GooglePlacesAutocomplete
              apiKey={import.meta.env.VITE_GOOGLEPLACE}
              selectProps={{
                value,
                onChange: getGeoByAddress,
                placeholder: "Please enter your address",
              }}
            />
          </div>
        </div>
        <div className="mini">
          <div id="map"></div>
          <div className="map_message">
            {result && result.includes("Great") && (
              <h3 className="success_msg">{result}</h3>
            )}
            {result && result.includes("outside") && (
              <h3 className="error_msg">
                OOPS! You are outside the teacher's travel zone.
              </h3>
            )}
          </div>
          {result && result.includes("Great") && (
            <button
              className="continue_btn"
              onClick={() => {
                setShowMapModal(false);
                setStudentCanConitnue(true);
                setSelectedPlace("studentPlace");
              }}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
