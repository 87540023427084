import React from "react";
import "./content.styles.scss";
export default function Copyright() {
  const data = [
    {
      title: "",
      p1: `Copyright & Disclaimers for Educify:`,
      p2: `1. Copyright Notice:
   - All content, materials, and resources provided on the Educify education platform, including courses, lessons, text, graphics, logos, images, videos, software, and course materials, are the intellectual property of Educify or its content suppliers and are protected by copyright laws.
   - Users are granted a limited, non-exclusive, non-transferable license to access and use the content for personal, non-commercial purposes only. Any unauthorized use, reproduction, distribution, or modification of the content is strictly prohibited and may result in legal action.
`,
      p3: `
2. Course Content:
   - Educify endeavors to ensure the accuracy, completeness, and relevance of the course content provided on the platform. However, we do not guarantee the accuracy, timeliness, or reliability of the content, and users acknowledge that the content may contain errors or inaccuracies.
   - Users are solely responsible for verifying the accuracy and applicability of the course content and individual lessons to their specific needs and circumstances.
`,
      p4: `3. Lesson Content:
   - The individual lessons within each course offered on the Educify platform are designed to provide educational content and guidance to users. While every effort is made to ensure the quality and accuracy of lesson content, Educify does not warrant or guarantee the completeness, accuracy, or suitability of individual lessons for users' specific purposes.
   - Users are encouraged to engage critically with lesson content and to seek additional resources or clarification as needed.
`,
      p5: `4. Third-Party Content and Links:
   - The Educify platform may contain links to third-party websites or resources, as well as content provided by third-party instructors or partners. These third-party materials are provided for informational purposes only, and Educify does not endorse, warrant, or guarantee the accuracy, reliability, or quality of such content.
   - Educify is not responsible for any content, products, services, or practices of third-party websites or resources linked to or from the Educify platform.
`,
      p6: `5. Disclaimer of Warranties:
   - The Educify platform, including courses and individual lessons, is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied.
   - Educify does not guarantee that the platform will be uninterrupted, error-free, or free from viruses or other harmful components.
   - We make no representations or warranties regarding the accuracy, reliability, completeness, or timeliness of the content or services provided on the platform.
`,
      p7: `6. Limitation of Liability:
   - In no event shall Educify or its affiliates, directors, officers, employees, agents, or licensors be liable for any indirect, consequential, special, incidental, or punitive damages arising out of or related to the use or inability to use the Educify platform or its content, including courses and individual lessons.
   - Educify's total liability for any claims arising under these terms shall not exceed the total amount paid by the user for the specific course or service giving rise to the claim.
`,
      p8: `
7. Indemnification:
   - Users agree to indemnify and hold harmless Educify and its affiliates, directors, officers, employees, agents, and licensors from any claims, damages, losses, liabilities, or expenses arising out of or related to their use of the Educify platform or violation of these terms.
`,
      p9: `8. Modification of Terms:
   - Educify reserves the right to modify or update these Copyright & Disclaimers at any time without prior notice. Changes will be effective immediately upon posting on the Educify platform.
   - Users are responsible for reviewing these terms periodically for updates.
`,
      p10: `By using the Educify education platform, including courses and individual lessons, users acknowledge that they have read, understood, and agree to be bound by these Copyright & Disclaimers. If users do not agree with any part of these terms, they must refrain from using the Educify platform.
`,
    },
  ];
  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>
        Educify Copyright & Disclaimers
      </h1>
      {data.map((section) => (
        <div className="privacy_row">
          <h2>{section.title}</h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          <p>{section?.p4}</p>
          <p>{section?.p5}</p>
          <p>{section?.p6}</p>
          <p>{section?.p7}</p>
          <p>{section?.p8}</p>
          <p>{section?.p9}</p>
          <p>{section?.p10}</p>
        </div>
      ))}
    </div>
  );
}
