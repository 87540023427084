import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as momentTz from "moment-timezone";
import { toast } from "react-toastify";

import "./EnrollModal.styles.scss";
import img from "../../../assets/SharedApp/images/tutor.webp";
import arrow_right from "../../../assets/SharedApp/svgs/arrow-right.svg";
import close from "../../../assets/SharedApp/svgs/Close.svg";

import { redirectLink } from "../../../ReduxToolkit/Slices/userSlice";
import { convertTimezone } from "../Timezone";
import {
  calculateTravelFees,
  checkPromo,
  handleLessonChange,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { ExchangeRateHook } from "../../../ExchangeRateHook";
import { LessonPriceCalculator, StudentFeeCalculator } from "../../../hooks";
import Note from "../../../TeacherApp/Components/Note/Note";

export default function EnrollModal({
  setModal,
  handleSubmit,
  bookingType,
  checkout,
  groupLessonPrice,
  courses,
  total,
  bookingBusy,
  newDate,
  dates,
  prices,
  className,
}) {
  const {
    selectedTeacher,
    lessonDate,
    studentDistance,
    selectedLesson,
    subscription,
  } = useSelector((state) => state.lessons);
  const { timezone } = useSelector((state) => state.user);
  const { selectedPackage, student, lessonLocation } = useSelector(
    (state) => state.student
  );
  const [travelFees, setTravelFees] = useState(0);
  useEffect(() => {
    lessonLocation === "studentPlace" &&
      dispatch(
        calculateTravelFees({
          studentId: student,
          lessonId: selectedLesson.id,
          teacherId: selectedLesson.teacher.id,
          packageId: selectedPackage.id,
        })
      ).then((res) => {
        setTravelFees(Math.ceil(res.payload.data / 100));
      });
  }, []);

  const { appointmentId } = useParams();

  const [discount, setDiscount] = useState(null);
  const [promoCode, setPromoCode] = useState([]);
  const [viewAll, setViewALl] = useState(false);
  const [busy, setBusy] = useState(false);

  const hour = (time) => new Date(time).getHours();
  const min = (time) => new Date(time).getMinutes();
  const sendToast = (text) => toast.error(text);
  const sendMessage = (text) => toast.success(text);

  const usdPrice = (price) => Math.round(price / 100);

  const dispatch = useDispatch();

  const checkPromoCode = () => {
    setBusy(true);
    dispatch(
      checkPromo({
        promo: {
          promoCode,
          studentId: student,
        },
      })
    )
      .then((res) => {
        setDiscount(res.payload.data);
        setBusy(false);
      })
      .catch((err) => {
        setBusy(false);
        return sendToast("Promo code not found");
      });
  };

  const courseDiscount = (course) =>
    course?.initialPriceAfterFeeAfterAdminDiscountAfterCoupon &&
    course?.initialPriceAfterFeeAfterAdminDiscountAfterCoupon !==
      course?.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon;

  const groupLessonPrices = LessonPriceCalculator(groupLessonPrice);

  const [agreed, setAgreed] = useState(false);
  const [subscribe, setSubscribe] = useState();
  // const { appointmentId } = useParams();

  useEffect(() => {
    // if (!appointmentId) setSubscribe(true);
  }, [appointmentId]);

  const { cartItems } = useSelector((state) => state.student);

  return (
    <div
      className={`${className ? className : ""} ${
        busy ? "package__modal fetching" : "package__modal"
      }`}
    >
      <div
        className={
          checkout
            ? "modal__container modal__container__course"
            : "modal__container"
        }
      >
        <div className="title">
          {checkout ? (
            <span>Complete Your Purchase</span>
          ) : (
            <span>Complete Your Enrollment</span>
          )}
          <span onClick={() => setModal(false)}>
            <img src={close} alt="" />
          </span>
        </div>
        <div className="summary">
          <span>Summary</span>
        </div>
        {!checkout ? (
          <div className="details">
            <div className={`item`}>
              <div className="row1">Teacher</div>
              <div className="row2 row2__with__img ">
                <div className="row__inner">
                  <img src={selectedTeacher.user?.profileImage?.url} alt="" />
                  {selectedTeacher?.user?.name}{" "}
                  {selectedTeacher?.user?.lastName}
                </div>
              </div>
            </div>
            <div className={`item `}>
              <div className="row1">Lesson Location</div>
              <div className="row2">{lessonLocation}</div>
            </div>
            {lessonLocation === "studentPlace" && (
              <div className={`item `}>
                <div className="row1">Travel Distance</div>
                <div className="row2">{Math.ceil(studentDistance)} Km</div>
              </div>
            )}
            {lessonLocation === "studentPlace" && (
              <div className={`item `}>
                <div className="row1">Travel Charge</div>
                <div className="row2">{travelFees} $</div>
              </div>
            )}
            <div className={`item `}>
              {<div className="row1">Lesson Dates </div>}
              {appointmentId ? (
                <div className="row2 column">
                  {selectedLesson?.days?.length &&
                    selectedLesson.days.map((day, i) => (
                      <span key={i}>
                        {day}
                        {i !== selectedLesson.days.length - 1 && ","}
                      </span>
                    ))}
                </div>
              ) : dates.length > 0 && dates.length > 1 ? (
                <div className="row2 column">
                  {dates.length > 2 ? (
                    viewAll ? (
                      dates.map((date) => (
                        <span
                          className="pointer"
                          onClick={() => setViewALl(!viewAll)}
                        >
                          {date}
                        </span>
                      ))
                    ) : (
                      <span onClick={() => setViewALl(!viewAll)}>
                        {dates[0]} {dates[1]}
                        <span className="pointer low"> View all..</span>
                      </span>
                    )
                  ) : (
                    <span>
                      {dates[0]} {dates[1]}
                    </span>
                  )}
                </div>
              ) : (
                <div className="row2 ">
                  <span> {newDate}</span>
                </div>
              )}
            </div>
            {bookingType === "WEEKLY" && (
              <div className={`item `}>
                <div className="row1">Lesson Timeframe</div>
                <div className="row2">
                  {convertTimezone(
                    moment.utc(lessonDate.startTime).format("HH:mm"),
                    "UTC",
                    timezone
                  )}{" "}
                  {convertTimezone(
                    moment.utc(lessonDate.endTime).format("HH:mm"),
                    "UTC",
                    timezone
                  )}{" "}
                  ({timezone})
                </div>
              </div>
            )}
            <div className={`item other`}>
              <div className="row1">Lesson Number</div>
              {selectedPackage.numOfLessons}
            </div>
            {/* subsc */}
            {/* {!appointmentId && (
              <div className={`item other`}>
                <div className="row1">Subscription</div>
                {subscription ? "Yes" : "No"}
              </div>
            )} */}
            <div className={`item other`}>
              <div className="row1">Payment Summary</div>
              <div className="row2">
                <ExchangeRateHook price={prices?.initial} strike />
                <ExchangeRateHook price={prices?.total} />
              </div>
            </div>
            <div className="item other">
              <div className="row1">Enter Promo Code</div>
              <div className="row2">
                <input
                  type="text"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button
                  onClick={() => checkPromoCode()}
                  style={
                    busy
                      ? {
                          background: "#F3F6F9",
                          color: "rgba(0,0,0,.7)",
                          cursor: "wait",
                        }
                      : {
                          margin: "inherit",
                        }
                  }
                >
                  <img src={arrow_right} alt="" />
                </button>
              </div>
            </div>

            {/* <hr /> */}

            {selectedPackage.numOfLessons > 1 && (
              <Note
                p1={
                  "By subscribing to this lesson, you agree to be charged upon the completion of all your bookings. The subscription will automatically rebook the lesson and charge your account."
                }
              />
            )}
            <div className="agreement">
              <input
                type="checkbox"
                onChange={(e) => setAgreed(!agreed)}
                value={agreed}
                checked={agreed}
              />
              <span>
                I Agree on the{" "}
                <a
                  href="#/terms"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  terms & conditions
                </a>
              </span>
            </div>

            {selectedPackage.numOfLessons > 1 &&
              !appointmentId &&
              bookingType === "WEEKLY" && (
                <>
                  <div className="agreement">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        dispatch(
                          handleLessonChange({
                            name: "subscription",
                            value: !subscription,
                          })
                        );
                      }}
                      value={subscription}
                      checked={subscription}
                    />
                    <span>Subscribe to the lesson</span>
                  </div>
                </>
              )}
          </div>
        ) : (
          <>
            <div className="details space">
              {courses.length > 0 &&
                courses.map((course, i) => (
                  <>
                    <div className={`item `}>
                      <div className="row1">Course</div>
                      <div className="row2">
                        <span key={i}>{course?.course?.title}</span>
                      </div>
                    </div>
                    <div className={`item borderBott`}>
                      <div className="row1">Price</div>
                      {courseDiscount(course?.course) ? (
                        <div className="row2">
                          <span key={i + 1} className="oldprice">
                            <ExchangeRateHook
                              price={
                                course?.course
                                  ?.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                              }
                            />
                          </span>
                          <span key={i}>
                            <ExchangeRateHook
                              price={
                                course?.course
                                  ?.initialPriceAfterFeeAfterAdminDiscountAfterCoupon
                              }
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="row2">
                          <span key={i}>
                            <ExchangeRateHook
                              price={
                                course?.course
                                  ?.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                              }
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              <div className={`item `}>
                <div className="row1">Total</div>
                <div className="row2">
                  <ExchangeRateHook price={total} />
                </div>
              </div>
              <div className={`item `}>
                <div className="row1">
                  <input
                    type="checkbox"
                    onChange={(e) => setAgreed(!agreed)}
                    value={agreed}
                    checked={agreed}
                  />
                  <span>
                    I Agree on the{" "}
                    <a
                      href="#/terms"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      terms & conditions
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="modal_btn_container">
          <button
            className={
              busy || bookingBusy
                ? "btn__primary modal__btn fetching"
                : "btn__primary modal__btn"
            }
            onClick={() => {
              if (agreed) handleSubmit(selectedPackage.id, lessonLocation);
              else toast.warning("You need to aggree to the terms");
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
