import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoodToKnow } from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

import Button from "../../../Components/Button/Button";

import searchIcon from "../../../assets/TeacherApp/assets/svgs/search.svg";
import { SubjectRow } from "./../../../Components/SubjectRow/SubjectRow";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoriesWithSubjects } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";

export const SignupStepOne = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teacherToRegister = useSelector(
    (state) => state?.teacher?.teacherToRegister
  );
  const [showAfterSelect, setShowAfterSelect] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [ogCategories, setOgCategories] = useState([]);
  const [ogSubjects, setOgSubjects] = useState(
    teacherToRegister?.selectedCategory?.subjects
  );
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(
    teacherToRegister?.selectedCategory
  );
  const [selectedSubject, setSelectedSubject] = useState(
    teacherToRegister?.selectedSubject
  );
  const goodToKnowNote = showAfterSelect
    ? "You can add up to 4 subjects to a single ad. Choose subjects that you teach simultaneously."
    : "On Educify, you can teach  over 1000 subjects! Use the search engine to the right first to find your category, and then the subject that you intend to teach. You can choose only one subject at the moment. You can set up the remaining 3 subjects in your dashboard.";

  useEffect(() => {
    dispatch(getAllCategoriesWithSubjects({})).then((res) => {
      if (res.type.includes("fulfilled")) {
        setCategories(res?.payload?.data?.categories);
        setOgCategories(res?.payload?.data?.categories);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (!selectedCategory?.id) return toast.error("missing category");
    if (!selectedSubject?.id) return toast.error("missing subject");

    dispatch(
      handleSliceChange({
        name: "teacherToRegister",
        value: { ...teacherToRegister, selectedCategory, selectedSubject },
      })
    );
    navigate("/teacher-signup/step-two");
  };

  useEffect(() => {
    if (!search) {
      setCategories(ogCategories);
      setSubjects(ogSubjects);
    }
    if (search && !selectedSubject?.id) {
      const filteredCategories = ogCategories.filter((el) =>
        el?.name?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setCategories(filteredCategories);
    }
    if (search && selectedCategory?.id) {
      const filteredSubjects = ogSubjects.filter((sub) =>
        sub?.name?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setSubjects(filteredSubjects);
    }
  }, [search]);

  return (
    <div className="stepone_container">
      <div className="container_1">
        <GoodToKnow
          images={[]}
          notes={[goodToKnowNote]}
          className={"register_note"}
        />
      </div>
      <div className="container_2">
        {selectedCategory?.name ? (
          <h2 className="no_max">
            Please select the subject for your first
            <span> {selectedCategory?.name}</span> lesson
          </h2>
        ) : (
          <h2>Please select the category for your first lesson</h2>
        )}
        <div className="search_field_container">
          <img src={searchIcon} alt="search" />
          <input
            type="search"
            placeholder={
              !selectedCategory?.id
                ? "Search categories..."
                : "Search subjects..."
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {selectedCategory?.name || selectedSubject?.name ? (
          <div className="subjects_rows_container">
            <div className="subjects_rows">
              {selectedCategory?.name && (
                <SubjectRow
                  // setShowAfterSelect={setShowAfterSelect}
                  name={selectedCategory?.name}
                  selected
                  onClick={() => {
                    setSelectedCategory({});
                    setSelectedSubject({});
                  }}
                />
              )}
              {selectedSubject?.name && (
                <SubjectRow
                  // setShowAfterSelect={setShowAfterSelect}
                  name={selectedSubject?.name}
                  selected
                  onClick={() => setSelectedSubject({})}
                />
              )}
            </div>
          </div>
        ) : (
          <></>
          // <div className="search_field_container">
          //   <img src={searchIcon} alt="search" />
          //   <input
          //     type="search"
          //     placeholder="Try 'Music'"
          //     onChange={(e) => setSearch(e.target.value)}
          //   />
          // </div>
        )}

        {!showAfterSelect && (
          <span className="most_taught">
            most taught {selectedCategory?.id ? "Subjects" : "Categories"}
          </span>
        )}
        {showAfterSelect && (
          <h2 className="no_max skill_h2">
            Add associated skills <span>(optional)</span>
          </h2>
        )}
        <div className="subjects_rows_container">
          <div className="subjects_rows">
            {selectedCategory?.name
              ? subjects?.map((el) => (
                  <SubjectRow
                    name={el?.name}
                    id={el?.id}
                    onClick={() => {
                      setSelectedSubject(el);
                      setSearch("");
                    }}
                  />
                ))
              : categories.map((el) => (
                  <SubjectRow
                    name={el?.name}
                    id={el?.id}
                    onClick={() => {
                      setSelectedCategory(el);
                      setSubjects(el?.subjects);
                      setOgSubjects(el?.subjects);
                      setSearch("");
                    }}
                  />
                ))}
          </div>
        </div>
        <div className="register_buttons">
          <Button
            variant={"cancel"}
            onClick={() => navigate("/teacher-signup")}
          >
            Go back
          </Button>
          <Button
            variant={"primary"}
            onClick={() => {
              handleSubmit();
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
