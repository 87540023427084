import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "./ViewPackage.styles.scss";
export default function ViewPackage() {
  // const data = [
  //   { header: "Trial Lessons Pricing", price30: "1$", price60: "1$" },
  //   { header: "5 Lessons Pricing", price30: "1$", price60: "1$" },
  //   { header: "10 Lessons Pricing", price30: "1$", price60: "1$" },
  //   { header: "15 Lessons Pricing", price30: "1$", price60: "1$" },
  //   { header: "15 Lessons Pricing", price30: "1$", price60: "1$" },
  // ];

  const { packages } = useSelector((state) => state.admin);
  const { name } = useParams();
  const [selectedPackage, setSelectedPackage] = useState({});
  useEffect(() => {
    setSelectedPackage(packages.find((pck) => pck.name === name));
  }, [name]);

  return (
    <div className="subject__details">
      <div className="header">
        <div className="col">
          <h1>{selectedPackage && selectedPackage.name}</h1>
          <p>Package pricing</p>
        </div>
        <Link
          to={`/admin/packages/${selectedPackage.name}/${selectedPackage.duration}/edit`}
        >
          <button>Edit</button>
        </Link>
      </div>
      <div className="package__details">
        <div className="input">
          <span>Package Name:</span>
          <p>{selectedPackage.name}</p>
        </div>
        <div className="input">
          <span>Package id:</span>
          <p>{selectedPackage.id}</p>
        </div>
        <div className="input">
          <span>Package Duration:</span>
          <p>{selectedPackage.duration}</p>
        </div>
        <div className="input">
          <span>Number of Lessons:</span>
          <p>{selectedPackage.numOfLessons}</p>
        </div>
        <div className="input">
          <span>Package Percentage:</span>
          <p>{selectedPackage.percentage}%</p>
        </div>
        <div className="input">
          <span>Package Status:</span>
          <p>{selectedPackage.status}</p>
        </div>
        <div className="input">
          <span>Created at:</span>
          <p>{selectedPackage.createdAt}</p>
        </div>
        <div className="input">
          <span>Updated at:</span>
          <p>{selectedPackage.updatedAt}</p>
        </div>
      </div>
      <div className="detail__rows">
        {selectedPackage.name &&
          selectedPackage.offers.map((el, i) => (
            <Offer
              name={el.name}
              key={i}
              i={i + 1}
              available={el.available}
              createdDate={el.createdAt}
              updatedat={el.updatedAt}
              packageId={el.packageId}
            />
          ))}
      </div>
    </div>
  );
}

const Offer = ({ name, i, available, createdDate, updatedat, packageId }) => {
  return (
    <div className="package__offer">
      {/* <h5>Offer {i}</h5> */}
      <div className="subject__detail__row">
        <h2>{name}</h2>
        <div className="offer__row">
          <div className="single__row">
            <span>Available:</span> <p>{available ? "Yes" : "No"}</p>
          </div>
          <div className="single__row">
            <span>Created At:</span> <p>{createdDate}</p>
          </div>
          <div className="single__row">
            <span>Created At:</span> <p>{updatedat}</p>
          </div>
          <div className="single__row">
            <span>Package Id:</span> <p>{packageId}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
