import React, { useState } from "react";
import x from "../../../../assets/SharedApp/svgs/x.svg";
import LessonSection from "../LessonSection/LessonSection";
export default function VideoPanel({
  course,
  selectedVid,
  setSelectedVid,
  customClass,
  watchChapter,
  watchedLessons,
}) {
  return (
    <div className={`course__video__panel ${customClass ? customClass : ""}`}>
      <div className="header">
        <span>Course Content</span>
      </div>
      {course.chapters &&
        course.chapters.length > 0 &&
        course.chapters.map((el, i) => (
          <LessonSection
            key={i}
            watchChapter={watchChapter}
            selected={selectedVid}
            setSelected={setSelectedVid}
            sections={el?.lessons}
            title={el?.title}
            watchedLessons={watchedLessons}
          />
        ))}
    </div>
  );
}
