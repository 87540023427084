import React from "react";
import NotesListingSection from "../../../StudentApp/Pages/StudentNotes/StudentNotes";

const ViewAllNotes = ({ setRefresh }) => {
  return (
    <div className="view_all_notes">
      <NotesListingSection setRefresh={setRefresh} />
    </div>
  );
};

export default ViewAllNotes;
