import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "@mui/material";

// import tutor from "../../../assets/SharedApp/images/tutor.webp";
// import close from "../../../assets/SharedApp/svgs/Close.svg";
// import down from "../../../assets/SharedApp/svgs/Button.svg";
// import unlock from "../../../assets/SharedApp/svgs/unlock.svg";
import edit from "../../../assets/SharedApp/svgs/Button Edit.svg";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";
import notfound from "../../../assets/SharedApp/images/default.webp";

import { useDispatch } from "react-redux";
import {
  assignAdmin,
  deleteStudent,
  deleteUser,
  unblockUser,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { toast } from "react-toastify";
import AlertModalDelete from "../AlertModalDelete/AlertModalDelete";
import { resendOTP } from "../../../ReduxToolkit/Slices/userSlice";

function StudentsRow({ row, i, assign, users, setRefresh, refresh, deleted }) {
  const [modal, setModal] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const [modalAssign, setModalAssign] = useState(false);

  let className = !row.bookings
    ? ""
    : row.bookings.length === 0
    ? ""
    : row.bookings[row.bookings.length - 1].status === "PENDING"
    ? "inProgress"
    : row.bookings[row.bookings.length - 1].status === "PENDING"
    ? "rejected"
    : "confirmed";

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteStudent(row.id)).then((res) => {
      setModal(false);
      if (res.type.includes("fulfilled")) {
        toast.success("Student Deleted Successfully");
        window.location.reload();
      } else {
        toast.error("Error Deleting Student");
      }
    });
  };

  const handleAssign = () => {
    dispatch(assignAdmin(row.user.id)).then((res) => {
      setModalAssign(false);
    });
  };

  const handleUnBlock = () => {
    deleted
      ? dispatch(deleteUser(row.user.id)).then((res) => {
          if (res.type === "deleteUser/fulfilled") {
            toast.success("Account deleted");
          }
        })
      : dispatch(unblockUser(row.user.id)).then((res) => {
          if (res.payload === "user unblocked") {
            setRefresh(!refresh);
            return toast.success(res.payload);
          } else toast.error(res.payload);
        });

    setModalBlock(false);
  };

  const onResendOTP = () => {
    dispatch(resendOTP({ email: row?.user?.email })).then((Res) => {
      if (
        Res.payload === "User already verified" ||
        Res.payload.data === "User already verified"
      ) {
        return toast.error("This user's email is already verified");
      }
      if (Res.payload.data === "An OTP was sent to your email address") {
        return toast.success("OTP is on it's way to the user");
      } else return toast.error("Error sending otp");
    });
  };
  return (
    <>
      {modal && (
        <AlertModalDelete
          type={users ? "User" : "Student"}
          handleDelete={handleDelete}
          setModal={setModal}
          users={users}
        />
      )}
      {modalAssign && (
        <AlertModalDelete
          assign={assign}
          name={row.user.name}
          type={"Student"}
          handleDelete={handleAssign}
          setModal={setModalAssign}
        />
      )}
      {modalBlock && (
        <AlertModalDelete
          assign={assign}
          name={row.user.name}
          type={"User"}
          text={
            deleted
              ? `Are you sure you want to delete this user?`
              : `Are you sure you want to unblock this user?`
          }
          handleDelete={handleUnBlock}
          setModal={setModalBlock}
        />
      )}
      <tr>
        <td>
          <Link to={`/admin/students/${row.id}/`} className="student__cell">
            <img
              src={
                row.user?.profileImage?.url
                  ? row.user.profileImage?.url
                  : row.user.profileImage?.url === ""
                  ? notfound
                  : notfound
              }
              alt=""
            />
            <div className="name">
              <span>
                {row?.user.name} {row.user.lastName}
              </span>
              <p>{row?.user?.address?.country}</p>
            </div>
          </Link>
        </td>
        {users && (
          <td>
            <span>{row.user.email}</span>
          </td>
        )}
        {users && (
          <td>
            <span>{row.user.role}</span>
          </td>
        )}
        {!assign && row.bookings && (
          <td>
            <div className="manage__std__lesson">
              <div className="lesson__date">{row?.bookings?.length}</div>
              {/* <img src={down} alt="" /> */}
            </div>
          </td>
        )}
        {!assign && row?.bookings && (
          <td>
            {row.bookings.length > 0 ? (
              <button
                className={row?.bookings[
                  row.bookings.length - 1
                ].status.toLowerCase()}
              >
                {row?.bookings[row?.bookings?.length - 1].status}
              </button>
            ) : (
              <button></button>
            )}
          </td>
        )}
        <td>
          <div className="action__btns">
            {!assign && !users && (
              <Link to={`/admin/students/${row.id}/edit`}>
                <img src={edit} alt="" />
              </Link>
            )}
            {!assign && !users && (
              <img src={del} alt="" onClick={() => setModal(true)} />
            )}
            {assign && !users && (
              <button onClick={() => setModalAssign(true)}>
                <img src={edit} alt="" />
              </button>
            )}
            {users &&
              (deleted ? (
                <Tooltip title="Remove Account" arrow>
                  <button onClick={() => setModalBlock(true)}>
                    <img src={del} alt="" />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Unblock user" arrow>
                  <button onClick={() => setModalBlock(true)}>
                    <img src={edit} alt="" />
                  </button>
                </Tooltip>
              ))}
            {!deleted && (
              <span className="resendOTP" onClick={() => onResendOTP()}>
                Resend OTP
              </span>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}

export default StudentsRow;

// const AlertModalDelete = ({ setModal, handleDelete }) => {
//   return (
//     <div className="alert___modal">
//       <Alert
//         text={"Are you sure you want to delete this Student? "}
//         btn1Text="Yes, delete"
//         btn1Color={"F64E60"}
//         img={warning}
//         btn2
//         onCancel={() => setModal(false)}
//         onConfirm={() => handleDelete()}
//       />
//     </div>
//   );
// };
