import React from "react";
import "./TeachersTable.styles.scss";
import TeacherRow from "../ManageTeachers/TeacherRow";
import Pagination from "../../../Components/Pagination/Pagination";

export default function TeachersTable({
  type,
  data,
  teachers,
  onReAssign,
  page,
  reAssign,
  setCurrentPage,
  currentPage,
  onSearch,
  dashboard,
  onSortChange,
}) {
  return (
    <div className="manage__teachers__page" key={`type-${currentPage}`}>
      <div className="header">
        <div className="header__text">
          <span>{type}</span>
          <p>
            {/* More than <span>{dashboard.totalTeachers}</span> teachers */}
          </p>
        </div>
        <div className="teachers__actions">
          <select id="select" onChange={(e) => onSortChange(e.target.value)}>
            <option value="">Sort</option>
            <option value="name">Name A-Z</option>
            <option value="name2">Name Z-A</option>
            <option value="date">Created At Desc</option>
            <option value="date2">Created At Asc</option>
          </select>
          <div className="search__teachers">
            <input
              type="text"
              placeholder={`Search ${type}`}
              onChange={(e) => onSearch(e)}
            />
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>{type}</th>
            <th>LESSONS TAUGHT</th>
            <th>LESSONS location</th>
            <th>Earning</th>
            <th>STATUS</th>
            <th></th>
          </tr>
          {teachers &&
            teachers.map((row, i) => (
              <TeacherRow
                key={row + i + currentPage}
                row={row}
                reAssign={reAssign}
                onReAssign={onReAssign}
              />
            ))}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        dataRendered={teachers?.length}
        totalData={dashboard?.totalTeachers}
      />
    </div>
  );
}
