import React from "react";
import Pagination from "../../../../Components/Pagination/Pagination";
import Announcement from "../Announcement/Announcement";

export default function CourseAnnouncements({ course }) {
  let announcements = course.announcements
    ? JSON.parse(course.announcements)
    : [];
  return (
    <div className="course__anouncement">
      <div className="announcements__container">
        {!announcements.length ? (
          <h2 className="no_announcement">
            No Annoucements published by instructor yet, check back again
          </h2>
        ) : (
          ""
        )}
        {announcements &&
          announcements.length > 0 &&
          announcements.map((ann, i) => (
            <Announcement key={i} ann={ann} teacher={course.teacher} />
          ))}
      </div>
      {/* <Pagination page={3} totalData={3} dataRendered={2} /> */}
    </div>
  );
}
