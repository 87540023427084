import React, { useEffect, useState } from "react";
import "./CoursesHomePage.styles.scss";
import { useDispatch } from "react-redux";

import HomepageCourseCarousel from "../../../Components/Courses/HomepageCourseCarousel/HomepageCourseCarousel";
import CourseCategorySwitcher from "../../../Components/Courses/CourseCategorySwitcher/CourseCategorySwitcher";
import FeaturedTopics from "../../../Components/Courses/FeaturedTopics/FeaturedTopics";
import ReviewCarousel from "../../../Components/Courses/ReviewCarousel/ReviewCarousel";
import LearnAnywhere from "../../../Components/Courses/LearnAnywhere/LearnAnywhere";
import HeaderPhraseComponent from "../../../Components/Courses/HeaderPhraseComponent/HeaderPhraseComponent";
import CoursesPageHeader from "../../../Components/Courses/CoursesPageHeader/CoursesPageHeader";
import {
  getStudentCourses,
  getCourseCategories,
  getAllCourses,
} from "../../../../ReduxToolkit/Slices/coursesSlice";

export default function CoursesHomePage() {
  const [selected, setSelected] = useState("");
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [OtherCategories, setOtherCategories] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseCategories()).then((res) => {
      setCategories(res.payload.data.slice(0, 6));
      setOtherCategories(res.payload.data.slice(6, 16));
      setSelected(res.payload.data[0].name);
    });
  }, []);

  useEffect(() => {
    selected &&
      dispatch(getStudentCourses(`category=${selected}`)).then((res) =>
        setCourses(res.payload.courses)
      );
  }, [selected]);

  return (
    <div className="courses__homepage">
      <CoursesPageHeader />
      <div className="sub__container">
        <CourseCategorySwitcher
          selected={selected}
          setSelected={setSelected}
          categories={categories}
          otherCategories={OtherCategories}
        />
        <HomepageCourseCarousel
          title={`${selected.toLowerCase()} courses`}
          selected={selected}
          courses={courses}
        />
        {/* <ReviewCarousel /> */}
        <HomepageCourseCarousel
          title={"Popular Courses"}
          courses={courses}
          selected={selected}
        />
        <HomepageCourseCarousel
          title={"Featured Courses"}
          courses={courses}
          selected={selected}
        />
        <FeaturedTopics />
        <LearnAnywhere />
      </div>
    </div>
  );
}
