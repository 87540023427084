import React from "react";
import { useParams } from "react-router-dom";
import HorizontalCourseCard from "../../../../StudentApp/Components/Courses/HorizontalCourseCard/HorizontalCourseCard";
import AsideItem from "../../../Components/Courses/AsideItem/AsideItem";

export default function Filter({ data, courses, handleChange, customClass }) {
  const { category } = useParams();
  const { search } = useParams();
  return (
    <div className={`filter__container ${customClass ? customClass : ""}`}>
      <aside>
        {data &&
          data.map((el, i) => (
            <AsideItem
              key={i}
              title={el.title}
              name={el.name}
              type={el.type}
              onChange={handleChange}
              options={el.options}
              value={el.value}
            />
          ))}
      </aside>
      <div className="filtered__courses">
        {!courses.length && (
          <div className="no__courses">
            <h1>
              No Courses for {search} {category} yet{" "}
            </h1>
          </div>
        )}
        {courses.length > 0 &&
          courses.map((course, i) => (
            <HorizontalCourseCard key={i} course={course} />
          ))}
      </div>
    </div>
  );
}
