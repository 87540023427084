import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Slices/userSlice";
import teacherReducer from "./Slices/teacherSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";
import lessonsSlice from "./Slices/lessonsSlice";
import studentSlice from "./Slices/studentSlice";
import paymentSlice from "./Slices/paymentSlice";
import adminSlice from "./Slices/adminSlice";
import coursesSlice from "./Slices/coursesSlice";
import currencyReducer from "./Slices/currencySlice";

const reducers = combineReducers({
  user: userReducer,
  teacher: teacherReducer,
  lessons: lessonsSlice,
  student: studentSlice,
  payment: paymentSlice,
  admin: adminSlice,
  courses: coursesSlice,
  currency: currencyReducer,
});

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: import.meta.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
