import { Tooltip } from "@mui/material";
import { useRef } from "react";

import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import example_img from "../../../assets/HomeApp/images/tutor.webp";
import edit from "../../../assets/SharedApp/svgs/edit.svg";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import { useDispatch } from "react-redux";
import { deleteImage } from "../../../ReduxToolkit/Slices/userSlice";

export default function SingleImageUploader({
  imageLoading,
  preview,
  onSelectFile,
  example,
  tip,
  id,
}) {
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const deleteImages = () => {
    dispatch(deleteImage(id));
  };

  return (
    <div className="image__selector">
      <div className="upload">
        <div className="image">
          <Tooltip title={tip ? tip : ""} arrow placement="top">
            <div className="image-upload">
              <label htmlFor="file-input" ref={imgRef}>
                {imageLoading ? (
                  <div className="spinner" />
                ) : !preview ? (
                  <img src={volicity} alt="" className="img__btn" />
                ) : (
                  <img src={preview} alt="" className="coverImage" />
                )}
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={onSelectFile}
              />
            </div>
          </Tooltip>
          <img src={remove} alt="" className="remove action" />
          {/* <img
            src={edit}
            alt=""
            className="edit action"
            onClick={() => imgRef.current.click()}
          /> */}
        </div>
        <p>Allowed file types: webp, jpg, jpeg.</p>
      </div>
      {example && (
        <div className="image__selector flex_col">
          <img alt="" src={example_img} className="example" />
          <p>Please upload images of clear background</p>
        </div>
      )}
    </div>
  );
}
