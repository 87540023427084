export const data = [
  {
    name: "Title",
    type: "text",
    objName: "title",
  },
  {
    name: "Percentage",
    type: "number",
    objName: "percentage",
  },
  {
    name: "Number of users",
    type: "number",
    objName: "numOfUsers",
  },
  {
    name: "Max usage",
    type: "number",
    objName: "maxUsage",
  },
  {
    name: "Type",
    type: "select",
    objName: "type",
    options: ["GENERAL", "REGISTRATION", "INVITATION"],
  },
  {
    name: "Status",
    type: "select",
    objName: "status",
    options: ["ACTIVE", "INACTIVE"],
  },
  {
    name: "Start Date",
    type: "date",
    objName: "startDate",
  },
  {
    name: "End Date",
    type: "date",
    objName: "endDate",
  },
];
export const teacherData = [
  {
    name: "Title",
    type: "text",
    objName: "title",
  },
  {
    name: "Percentage",
    type: "number",
    objName: "percentage",
  },
  {
    name: "Number of users",
    type: "number",
    objName: "numOfUsers",
  },
  {
    name: "Max usage",
    type: "number",
    objName: "maxUsage",
  },
  {
    name: "Status",
    type: "select",
    objName: "status",
    options: ["ACTIVE", "INACTIVE"],
  },
  {
    name: "Start Date",
    type: "date",
    objName: "startDate",
  },
  {
    name: "End Date",
    type: "date",
    objName: "endDate",
  },
];
