import React, { useEffect } from "react";

import "./TeacherRequest.styles.scss";
import tutor from "../../../assets/SharedApp/images/tutor.webp";

import pdfImage from "../../../assets/TeacherApp/assets/images/pdf.png";
import notfound from "../../../assets/SharedApp/images/default.webp";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  approveTeachers,
  getTeachersById,
  rejectTeachers,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { useState } from "react";
import { toast } from "react-toastify";

export default function TeacherRequest() {
  const params = useParams();

  const { id } = params;

  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState({});
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    dispatch(getTeachersById(id)).then((res) => {
      setTeacher(res.payload);
      setFetched(true);
    });
  }, []);
  const navigate = useNavigate();

  const approve = () => {
    dispatch(approveTeachers(id)).then((res) => {
      if (typeof res.payload === "string") {
        return toast.error(res.payload);
      }
      navigate("/admin/teachers");
    });
  };
  const reject = () => {
    dispatch(rejectTeachers(id)).then(navigate("/admin/teachers"));
  };
  return (
    fetched && (
      <div className="request__container">
        <Link to={`/admin/teachers/${id}/submission`} className="flex_center">
          View Submissions
        </Link>
        <div className="teacher__request">
          <div className="header">
            <div className="teacher__profile">
              <img
                src={
                  teacher?.user?.profileImage?.url?.includes("pdf")
                    ? pdfImage
                    : teacher?.user?.profileImage?.url
                    ? teacher?.user?.profileImage?.url
                    : notfound
                }
                alt=""
              />
              <span>
                {teacher.user?.name} {teacher.user?.lastName}
              </span>
              <p>Teacher</p>
            </div>
            <div className="teacher__desc">
              <p>{teacher.description}</p>
            </div>
          </div>

          <div className="teacher__rates">
            <div className="group">
              <div className="row">
                <div className="col">
                  <span>Pricing</span>
                  <p>
                    {teacher?.lessons.length > 0 &&
                      teacher?.lessons[0].price / 100}
                  </p>
                </div>
                <div className="col">
                  <span>Rating</span>
                  <p>{teacher.rating}</p>
                </div>
              </div>
              <div className="row__2">
                <div className="col second">
                  <span className="bg__check__span">Background Check</span>
                </div>
                <div className="col">
                  <span className="null">{teacher.background?.status}</span>
                </div>
              </div>
              {teacher.background?.image?.url && (
                <div className="flex">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(teacher.background?.image?.url, "_blank");
                      }}
                      src={
                        teacher.background?.image?.url.includes(".pdf")
                          ? pdfImage
                          : teacher.background?.image?.url
                          ? teacher.background?.image?.url
                          : notfound
                      }
                      alt="teacher bg"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="teacher__contact">
              <div className="row">
                <span>Contact Phone</span>
                <p>{teacher.user.phone}</p>
              </div>
              <div className="row">
                <span>Email Address</span>
                <p>{teacher.user.email}</p>
              </div>
              <div className="row">
                <span>Address</span>
                <p>{teacher.user.address.street}</p>
              </div>
              <div className="row">
                <span>Email Address Verified</span>
                <p>
                  {teacher.user.isVerified || teacher?.user?.bySocial
                    ? "True"
                    : "False"}
                </p>
              </div>
            </div>
          </div>
          <div className="teacher__btns">
            <button className="approve" onClick={() => approve()}>
              Approve
            </button>
            <button className="pause" onClick={() => reject()}>
              Reject
            </button>
          </div>
        </div>
      </div>
    )
  );
}
