import React from "react";
import { useNavigate } from "react-router-dom";

import tutor from "../../../../assets/SharedApp/images/tutor.webp";
import hide from "../../../../assets/TeacherApp/assets/svgs/hide.svg";
import edit from "../../../../assets/AdminApp/svgs/edit.svg";
import deleteico from "../../../../assets/AdminApp/svgs/deleteico.svg";
import { useState } from "react";

export default function DraftRow({
  status,
  price,
  title,
  description,
  id,
  onDelete,
  profileImage,
}) {
  function limitWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }
  const navigate = useNavigate();

  const [view, setView] = useState(false);
  return (
    <tr>
      <td>
        <div className="student__cell">
          <img src={profileImage && profileImage.url} alt="" />
          <div className="name">
            <span>{title}</span>
            <p> {limitWords(description, 4)}</p>
          </div>
        </div>
      </td>
      <td>
        <span>${price / 100}</span>
      </td>
      <td>
        <button className="pending">{status}</button>
      </td>

      <td>
        <div className="course__draft__actions">
          <div className="actions">
            {/* <img src={view} alt="" /> */}
            <img src={hide} alt="" onClick={() => navigate(id)} />
            <img
              src={edit}
              alt=""
              onClick={() => navigate(`/teachers/courses/edit/${id}`)}
            />
            <img src={deleteico} alt="" onClick={() => onDelete(id)} />
          </div>
        </div>
      </td>
    </tr>
  );
}
