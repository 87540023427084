import { Tooltip } from "@mui/material";
import { useRef, useState } from "react";

import volicity from "../../assets/TeacherApp/assets/svgs/volicity.svg";
import example_img from "../../assets/HomeApp/images/tutor.webp";
import remove from "../../assets/SharedApp/svgs/remove.svg";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  uploadDocument,
  uploadImage,
} from "../../ReduxToolkit/Slices/teacherSlice";
import { deleteImage } from "../../ReduxToolkit/Slices/userSlice";

export default function BgCheckUploader({
  preview,
  onSelectFile,
  example,
  tip,
  MAX_SIZE,
}) {
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const deleteImages = () => {
    onSelectFile({
      url: "",
      publicId: "",
    });
  };

  const sendToast = (m) => toast.error(m);

  const [imageLoading, setImageLoading] = useState(false);

  const handleChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        const img = new Image();
        img.onload = () => {
          if (file.size > MAX_SIZE) {
            // Alert user that the image is too large
            sendToast(
              `Image size must be within ${MAX_SIZE / (1024 * 1024)} MB.`
            );
            return;
          }
          setImageLoading(true);
          handleImageUpload(file);
          const objectUrl = URL.createObjectURL(file);
          return () => URL.revokeObjectURL(objectUrl);
        };
        img.src = event.target.result;
      } else if (file.type === "application/pdf") {
        if (file.size > MAX_SIZE) {
          // Alert user that the PDF is too large
          sendToast(`PDF size must be within ${MAX_SIZE / (1024 * 1024)} MB.`);
          return;
        }
        setImageLoading(true);

        handleImageUpload(file);
      }
    };

    reader.readAsDataURL(file);
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    Promise.resolve(dispatch(uploadDocument(form))).then((value) => {
      if (!value.type === "uploadImage/fulfilled") {
        setImageLoading(false);
        return sendToast("Error uploading image, please try again");
      }
      setImageLoading(false);
      onSelectFile(value.payload);
    });
  };

  return (
    <div className="image__selector">
      <div className="upload" style={{ flexDirection: "column", gap: "0px" }}>
        <div className="image">
          <Tooltip title={tip ? tip : ""} arrow placement="top">
            <div className="image-upload ">
              <label htmlFor="file-input" ref={imgRef}>
                {imageLoading ? (
                  <div className="spinner" />
                ) : !preview.url ? (
                  <img src={volicity} alt="" className="img__btn" />
                ) : preview.url.includes(".pdf") ? (
                  <p>{preview.fileName}</p>
                ) : (
                  <img src={preview.url} alt="" className="coverImage" />
                )}
              </label>

              <input
                id="file-input"
                type="file"
                accept="image/*, application/pdf"
                onChange={handleChange}
              />
            </div>
          </Tooltip>
          <img
            src={remove}
            alt=""
            className="remove action"
            onClick={() => deleteImages()}
          />
          {/* <img
            src={edit}
            alt=""
            className="edit action"
            onClick={() => imgRef.current.click()}
          /> */}
        </div>
        <p style={{ textAlign: "left", paddingTop: "10px", maxWidth: "160px" }}>
          Allowed file types: webp, jpg, jpeg, and pdf
        </p>
      </div>
      {example && (
        <div className="image__selector flex_col">
          <img alt="" src={example_img} className="example" />
          <p>Please upload images of clear background</p>
        </div>
      )}
    </div>
  );
}
