import React, { useState } from "react";
import "./ManageTeachers.styles.scss";
import { Link, useNavigate } from "react-router-dom";

import TeacherRow from "./TeacherRow";
import Pagination from "../../../Components/Pagination/Pagination";
import { deleteTeacher } from "../../../ReduxToolkit/Slices/adminSlice";

export default function ManageTeachers({ teachers, teachersCount }) {
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const handleCurrentClick = (i) => {
    setCurrent(i);
    setCurrentPage(i);
  };

  return (
    <div className="manage__teachers">
      <div className="header">
        <div className="header__text">
          <span>Teachers</span>
          <p>{/* More than <span>{teachersCount}</span> Teachers */}</p>
        </div>
        <Link to={"/admin/teachers"} style={{ marginBottom: "20px" }}>
          <button>View Teachers</button>
        </Link>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>Teachers</th>
            <th>LESSONS TAUGHT</th>
            <th>LESSONS location</th>
            {/* <th>Earning</th> */}
            <th>STATUS</th>
            <th></th>
          </tr>
          {teachers &&
            teachers.map((row, i) => <TeacherRow row={row} key={i} />)}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
}
