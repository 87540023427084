import React from "react";
import "./SimilarTeacher.styles.scss";
import Star from "../../../assets/TeacherApp/assets/svgs/Star.svg";
import img from "../../../assets/SharedApp/images/tutor.webp";
import { useNavigate } from "react-router-dom";
import { ExchangeRateHook } from "../../../ExchangeRateHook";

export default function SimilarTeacher({
  name,
  price,
  rating,
  location,
  id,
  image,
  element,
}) {
  let newLocation =
    location && location?.length
      ? location.map((loc) => {
          if (loc === "teacherPlace") {
            return "teacher's studio";
          }
          if (loc === "studentPlace") {
            return "student's place";
          } else return loc;
        })
      : [];

  const navigate = useNavigate();
  const minPrice = element?.lessons?.length
    ? Math.min(
        ...element?.lessons.map((lesson) => {
          return (
            lesson.onlinePrice,
            lesson.studentPlacePrice,
            lesson.teacherPlacePrice
          );
        })
      )
    : 0;

  const allLocations =
    element?.lessons && element?.lessons?.length
      ? element.lessons.reduce((locations, lesson) => {
          lesson.location.forEach((loc) => {
            if (!locations.includes(loc)) {
              locations.push(loc);
            }
          });
          return locations;
        }, [])
      : [];

  return (
    <div
      className="similar__container"
      onClick={() => navigate(`/tutor/${id}`)}
    >
      <div className="similar__info">
        <img src={image} alt="" />
        <div className="name">
          <span>{name}</span>
          {element.lessons && (
            <p>
              Teaches in:
              {length > 0 &&
                allLocations.map((loc, i) => {
                  return (
                    <span key={i}>
                      <br />- {loc}
                    </span>
                  );
                })}
            </p>
          )}
        </div>
      </div>
      <div className="other">
        <div className="col">
          <img src={Star} alt="" />
          <p style={{ marginLeft: "5px" }}>{rating.toFixed(2)}</p>
        </div>

        <div className="col">
          <p style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: "6px" }}> Starting </span>
            <ExchangeRateHook price={price} />
          </p>
          {/* <span>{"/30mins"}</span> */}
        </div>
      </div>
    </div>
  );
}
