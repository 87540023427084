import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./GroupLesson.styles.scss";
import image from "./py.jpeg";
import stars from "../../../assets/TeacherApp/assets/svgs/Star.svg";
// import notfave from "../../../assets/SharedApp/svgs/notfave.svg";
import emptyStar from "../../../assets/TeacherApp/assets/svgs/emptyStar.svg";
import heart from "../../../assets/TeacherApp/assets/svgs/Shape.svg";
// import LessonDescription from "../../Components/LessonDescription/LessonDescription";
import LessonDescription from "../../Components/LessonDescription/LessonDescription";
import {
  getLessonById,
  handleLessonChange,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { useDispatch, useSelector } from "react-redux";
import { redirectLink } from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import {
  getVideoIdFromUrl,
  LessonPriceCalculator,
  StudentFeeCalculator,
  updateCloudinaryUrl,
} from "../../../hooks";
import { ExchangeRateHook } from "../../../ExchangeRateHook";

export default function GroupLesson() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    dispatch(getLessonById(id)).then((res) => {
      res?.payload?.data?.id && setLesson(res?.payload?.data);
    });
  }, []);

  const onEnrollClick = (id, days, endDate) => {
    // Calculate the current date
    const currentDate = new Date();

    // Calculate the minimum allowed end date (1 week ahead)
    const minimumEndDate = new Date(currentDate);
    minimumEndDate.setDate(currentDate.getDate() + 7);

    // Parse the appointment's end date
    const parsedEndDate = new Date(endDate);

    // Check if the end date is at least 1 week ahead
    if (parsedEndDate < minimumEndDate && false) {
      // If the end date is not at least 1 week ahead, show an error message
      toast.error("The appointment end date must be at least 1 week ahead.");
    } else {
      // Proceed with the enrollment logic here
      let price = lesson.location.includes("online")
        ? lesson.onlinePrice
        : lesson.teacherPlacePrice;

      dispatch(
        handleLessonChange({
          name: "selectedLesson",
          value: { id: lesson.id, price, days, name: lesson.title },
        })
      );
      dispatch(
        handleLessonChange({
          name: "selectedTeacher",
          value: lesson?.teacher,
        })
      );
      if (!user.name) {
        dispatch(redirectLink(`/method/${id}`));
        navigate("/login");
      } else {
        if (user.role !== "STUDENT") {
          toast.error("You have to be a student to access this route");
          return;
        }
        navigate(`/method/${id}`);
      }
    }
  };

  return (
    <div className="lesson_page_container">
      <div className="group_lesson_page">
        <div className="lesson_container">
          <span className="category">{lesson.title}</span>
          <h1 className="group_title">{lesson.headLine}</h1>
        </div>
        <GroupLessonInfo lesson={lesson} />
        <div className="group_lesson_video">
          <div className="media__container__page" id="tutor-media">
            {!lesson?.videos?.length ? null : (
              <div className="media__container__inner">
                <h2>Lesson Video</h2>
                <div className="video_combo">
                  {lesson?.videos[0]?.url !== lesson?.videos[0]?.publicId ? (
                    <video
                      src={updateCloudinaryUrl(lesson?.videos[0]?.url)}
                      alt=""
                      className="tall"
                      controls
                    />
                  ) : (
                    <iframe
                      className="tall"
                      src={
                        lesson?.videos?.length &&
                        `https://www.youtube.com/embed/${getVideoIdFromUrl(
                          lesson?.videos[0]?.url
                        )}`
                      }
                      title="YouTube Video"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="table_outer_container">
          <div className="table_header">
            <h2>Available times</h2>
            <span>
              Eastern European Summer Time This teacher may live in a different
              time zone
            </span>
          </div>
          <DatesTable
            groupLessonAppointment={lesson?.groupLessonAppointment}
            onEnrollClick={onEnrollClick}
          />
        </div>
        <LessonDescription lesson={lesson} />
        {/* <GroupLessonCard  */}
      </div>
    </div>
  );
}

const GroupLessonInfo = ({ lesson }) => {
  const navigate = useNavigate();
  return (
    <div className="group_info_container">
      <div className="group__card">
        <div className="header">
          <div className="image">
            <img
              src={lesson?.images?.length && lesson?.images[0]?.url}
              alt=""
            />
          </div>
          <p>{lesson.summary}</p>
        </div>
        <div className="teacher">
          <h3>Teacher:</h3>{" "}
          <span
            onClick={() => navigate(`/tutor/${lesson?.teacher?.id}`)}
            className="pointer"
          >
            <span>{lesson?.teacher?.user?.name} </span>
            <span>{lesson?.teacher?.user?.lastName}</span>
          </span>
        </div>

        {/* <div className="buttons">
          <button>See Class Schedule</button>
        </div> */}
      </div>

      <GroupLessonPrices lesson={lesson} />
    </div>
  );
};

const GroupLessonPrices = ({ lesson }) => {
  const location =
    lesson.location === "teacherPlace" ? "teacher's place" : "online";

  const currentDate = new Date();
  const endDate = new Date(lesson.discountEndDate);
  const discount =
    lesson?.discount && lesson.discount > 0 && endDate > currentDate
      ? lesson.discount
      : 0;
  const price =
    lesson.location === "teacherPlace"
      ? LessonPriceCalculator(lesson.teacherPlacePrice)
      : LessonPriceCalculator(lesson.onlinePrice);
  return (
    lesson?.groupLessonAppointment && (
      <div className="group_lesson_prices">
        <div className="prices_header">
          <h2>
            1-
            {lesson.maxStudents}
          </h2>
          <span>learners per class</span>
        </div>
        <div className="prices_table">
          <div className="row1">
            <div className="col1">
              {discount > 0 ? (
                <div className="flex_col">
                  <ExchangeRateHook price={price} strike />
                  <ExchangeRateHook price={price * ((100 - discount) / 100)} />
                </div>
              ) : (
                <ExchangeRateHook price={price} />
              )}
            </div>
            <div className="col2">
              <span>Charged upfront</span>
              {discount > 0 ? (
                <span>
                  <ExchangeRateHook
                    price={
                      (price * ((100 - discount) / 100)) /
                      lesson?.groupLessonAppointment[0]?.days?.length
                    }
                  />{" "}
                  per class
                </span>
              ) : (
                <span>
                  $
                  <ExchangeRateHook
                    price={
                      price /
                      (100 * lesson?.groupLessonAppointment[0]?.days?.length)
                    }
                  />{" "}
                  per class
                </span>
              )}
            </div>
          </div>
          <div className="class_info">
            <span>
              Meets {lesson?.groupLessonAppointment[0]?.days?.length}x per week
            </span>
            <span>
              {" "}
              <span>Teaching location:</span> <span>{location}</span>
            </span>
            <span>
              <span>Age Range: </span>
              <span>
                From {lesson?.fromAge} to {lesson?.toAge}
              </span>
            </span>
          </div>
        </div>
      </div>
    )
  );
};

const DatesTable = ({ groupLessonAppointment, onEnrollClick }) => {
  return (
    <div className="lesson_dates_table">
      <div className="space"></div>
      <div className="dates_container">
        {groupLessonAppointment &&
          groupLessonAppointment.map((appointment, i) => (
            <GroupDateRow
              appointment={appointment}
              key={i}
              onEnrollClick={onEnrollClick}
            />
          ))}
      </div>
    </div>
  );
};

const GroupDateRow = ({ appointment, onEnrollClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);

  return (
    <div className="row">
      <div className="col1">
        <div className="days">
          {appointment.days.map((day, i) => (
            <span key={i}>
              {day.toLowerCase()}
              {appointment.days.length - 1 !== i && <span>,</span>}
            </span>
          ))}
        </div>
        <div className="times">
          <span>3:00 - 3:50 AM</span>
        </div>
      </div>
      <div className="group_dates">
        <span>
          <span style={{ fontWeight: "bold" }}>Starts:</span>{" "}
          {appointment.startDate}
        </span>
        <span>
          {" "}
          <span style={{ fontWeight: "bold" }}>Ends:</span>{" "}
          {appointment.endDate}
        </span>
      </div>
      {/* <div className="meetings">show all meetings</div> */}
      <div className="seats">
        {appointment.numberOfSeats - appointment?.booking?.length} Seats left
      </div>
      <div className="enroll">
        <button
          className="enroll_btn"
          onClick={() =>
            onEnrollClick(
              appointment.id,
              appointment?.days,
              appointment.endDate
            )
          }
        >
          Enroll
        </button>
      </div>
    </div>
  );
};

const GroupLessonCard = () => {
  const rating = 3;
  return (
    <div className="group_lesson_card_container">
      <div className="image">
        <img src={image} alt="" />
      </div>

      <div className="inner_cont">
        <div className="row">
          <div className="rating">
            {Array.from(Array(rating)).map((el) => (
              <img src={stars} />
            ))}
            {Array.from(Array(5 - rating)).map((el) => (
              <img src={emptyStar} />
            ))}
          </div>
          <div className="group_lesson_fav">
            <img src={heart} alt="" />
          </div>
        </div>
        <div className="lesson_description">
          Python Coding - Create Your Own Video Game! - (Twice a Week, 8 Weeks)
        </div>
        <div className="lesson_instructor">
          <img src={image} alt="" />
          <span>Jhon Doe</span>
        </div>
        <div className="infos">
          <div className="info">
            <span>10-15</span>
            <p>Age</p>
          </div>
          <div className="info">
            <span>50</span>
            <p>Min</p>
          </div>
          <div className="info">
            <span>$20</span>
            <p>Per class</p>
          </div>
        </div>
      </div>
    </div>
  );
};
