import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Homeworks.styles.scss";
import { toast } from "react-toastify";

import arrow from "../../../assets/TeacherApp/assets/svgs/Arrow-right.svg";
import ArrowLeft from "../../../assets/TeacherApp/assets/svgs/Arrow-left-blue.svg";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  handleDeleteChapter,
  deleteChapterLesson,
} from "../../../ReduxToolkit/Slices/coursesSlice";
// import manage__currfrom "./Chapter";
import AlertModalDelete from "../../../AdminApp/Components/AlertModalDelete/AlertModalDelete";
import { EditLecutre } from "../../Components/Courses/CurriculumRow/CurriculumRow";
import EditHomework from "../Courses/EditCourse/EditHomework";

import editImage from "../../../assets/SharedApp/svgs/Button Edit.svg";
// import EditCourse from "EditCourse/EditCourse";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";
import HomeworkDisplay from "./HomeworkDisplay";
import {
  createHomework,
  getHomeworkById,
  getTeacherHomeworks,
  getTeacherLessons,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { studentsPostHomeworks } from "../../../ReduxToolkit/Slices/studentSlice";
import moment from "moment";

export default function AddCurriculum({
  given,
  edit,
  lessonTitle,
  student,
  teacherHomeWork,
  setShowHomework,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //   const noTrack = location.pathname.includes("signup");

  const [editIndex, setEditIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const homeworkInitial = {
    title: "Homework title",
    lessonId: "",
    teacherId: "",
    dueDate: "",
    note: "",
    codeExample: "",
    image: null,
    video: null,
  };
  const [homework, setHomework] = useState(homeworkInitial);

  const handleHomeWork = (name, value) => {
    setHomework({ ...homework, [name]: value });
  };
  const resetHomeWork = () => {
    setHomework(homeworkInitial);
  };

  const { id } = useParams();
  useEffect(() => {
    edit &&
      dispatch(getHomeworkById(id)).then(
        (res) => res.payload && setHomework(res.payload)
      );
  }, [refresh]);

  return (
    <div className="add__course__curr teacher_homework_page">
      {/* <h1>here</h1> */}
      <h1 className="dash__header">
        {!edit ? "Add Homework" : "Edit Homework"}
      </h1>
      <Chapter
        onEditTitle={(value) => handleHomeWork("title", value)}
        onHomeworkChange={handleHomeWork}
        setEditIndex={setEditIndex}
        editIndex={editIndex}
        title={homework.title}
        teacherHomeWork={teacherHomeWork}
        homework={homework}
        student={student}
        refresh={refresh}
        setRefresh={setRefresh}
        lessonTitle={lessonTitle}
        resetHomeWork={resetHomeWork}
      />
    </div>
  );
}

function Chapter({
  onHomeworkChange,
  onEditTitle,
  i,
  student,
  lessonTitle,
  homework,
  title,
  teacherHomeWork,
  refresh,
  setRefresh,
  resetHomeWork,
}) {
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [lessons, setLessons] = useState(false);
  const [lessonId, setSelectedLessonId] = useState("");
  const { teacher } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = () => {
    dispatch(handleDeleteChapter({ index: i }));
    dispatch(deleteChapterLesson(id));
    setModal(false);
  };

  useEffect(() => {
    if (teacher.id) {
      dispatch(getTeacherLessons(teacher.id)).then((res) => {
        if (res.payload && res.payload.length) {
          setLessons(res.payload);
          setSelectedLessonId(res.payload[0].id);
        }
      });
    }
  }, [refresh]);
  const onSubmit = () => {
    const { title, note, image, video, dueDate } = homework;
    if (!title) {
      return toast.error("Please add your homework title");
    }
    if (!note && !image && !video) {
      return toast.error("Please add at least a note, an image or a video");
    }
    if (!student && !dueDate) return toast.warning("Please add a due date");

    student
      ? dispatch(
          studentsPostHomeworks({
            homeworkId: teacherHomeWork.id,
            note,
            image: image ? image : { url: "", publicId: "", fileName: "" },
            video: video ? video : { url: "", publicId: "", fileName: "" },
          })
        ).then((res) => {
          setRefresh(!refresh);
          // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          if (res.type.includes("fulfilled")) {
            setIsSubmitted(true);
            toast.success("Homework submitted successfully");
          } else toast.error("Something went wrong");
        })
      : dispatch(
          createHomework({
            title,
            note,
            image: image ? image : { url: "", publicId: "", fileName: "" },
            video: video ? video : { url: "", publicId: "", fileName: "" },
            dueDate,
            lessonId,
            teacherId: teacher.id,
          })
        ).then((res) => {
          if (res.type === "createHomework/fulfilled") {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            setRefresh && setRefresh(!refresh);
            resetHomeWork();
            toast.success("Your homework was submitted!");
          }
        });
  };

  const handleLessonChange = (e) => {
    setSelectedLessonId(e.target.value);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <>
      <div className="cont">
        <div className="cont2">
          {modal && (
            <AlertModalDelete
              type={"Chapter"}
              handleDelete={onDelete}
              setModal={setModal}
            />
          )}

          <div className="manage__curr">
            {!edit ? (
              <h2 className="flex_center">
                {homework.title}
                <img
                  alt=""
                  src={editImage}
                  onClick={() => setEdit(true)}
                  className="pointer"
                />
                <img
                  alt=""
                  src={del}
                  onClick={() => setModal(true)}
                  className="pointer"
                />
              </h2>
            ) : (
              <EditLecutre
                value={title}
                // setValue={setValue}
                setValue={(value) => onEditTitle(value)}
                setEdit={() => setEdit(false)}
              />
            )}
            <div className="choose_lesson_container">
              <div className="choose_lesson">
                <h3>Homework for:</h3>
                {student ? (
                  <span>lessonTitle</span>
                ) : (
                  <select onChange={(e) => handleLessonChange(e)}>
                    {lessons &&
                      lessons.map((lesson, i) => (
                        <>
                          <option key={i} value={lesson.id}>
                            {lesson.title}
                          </option>
                        </>
                      ))}
                  </select>
                )}
              </div>
              {!student && (
                <>
                  <div className="choose_lesson">
                    <h3>Due Date:</h3>
                    <input
                      type="date"
                      name="dueDate"
                      className="inpt_date"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        onHomeworkChange(name, value);
                      }}
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <EditHomework
            index={i}
            student={student}
            onHomeworkChange={onHomeworkChange}
            homework={homework}
            refresh={refresh}
          />
        </div>
        {/* {(homework.video || homework.image || homework.note) && (
          <div className="prerows" />
        )} */}
        {/* <HomeworkDisplayComponent
        homework={homework}
        i={i}
        onHomeworkChange={onHomeworkChange}
        onSubmit={onSubmit}
      /> */}
      </div>
      {!!!isSubmitted && (
        <HomeworkDisplayComponent
          homework={homework}
          i={i}
          onHomeworkChange={onHomeworkChange}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

export const HomeworkDisplayComponent = ({
  homework,
  onHomeworkChange,
  i,
  onSubmit,
  viewOnly,
}) => {
  const allowedToShow =
    homework?.image?.url || homework?.video?.url || homework?.note;
  return (
    <>
      {!!allowedToShow && (
        <div className="custom_hmw_cont">
          {homework.note && (
            <HomeworkDisplay
              index={i}
              viewOnly={viewOnly}
              type="description"
              homework={homework}
              handleHomeWork={onHomeworkChange}
            />
          )}
          <div className="">
            {homework?.video?.url && (
              <HomeworkDisplay
                index={i}
                viewOnly={viewOnly}
                type="video"
                homework={homework}
                handleHomeWork={onHomeworkChange}
                // resetHomeWork={resetHomeWork}
              />
            )}
            {homework?.image?.url && (
              <HomeworkDisplay
                index={i}
                type="image"
                homework={homework}
                viewOnly={viewOnly}
                handleHomeWork={onHomeworkChange}
                // resetHomeWork={resetHomeWork}
              />
            )}
          </div>

          {!viewOnly && (
            <div className="hmw_submit">
              <button class="btn_send" onClick={() => onSubmit()}>
                Submit
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
