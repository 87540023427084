import React from "react";
import note from "../../../../assets/TeacherApp/assets/svgs/note.svg";
import Write from "../../../../assets/TeacherApp/assets/svgs/Write.svg";
import Layout2 from "../../../../assets/TeacherApp/assets/svgs/Layout-left-panel-2.svg";
import Icons from "../../../../assets/TeacherApp/assets/svgs/Icons.svg";
// import vimeo from "../../../../assets/TeacherApp/assets/svgs/vimeo.svg";
// import box4 from "../../../../assets/TeacherApp/assets/svgs/box4.svg";
import { Link, useLocation } from "react-router-dom";
export default function EditPanel({ selected, setSelected }) {
  const Data = [
    {
      name: "Add Video",
      icon: Icons,
      link: "addVideo",
    },

    {
      name: "Add Notes",
      icon: Write,
      link: "addText",
    },
    // {
    //   name: "Add Code Example",
    //   icon: Layout2,
    //   link: "addCode",
    // },
    {
      name: "Add Image",
      icon: note,
      link: "addImage",
    },
  ];

  const location = useLocation();
  let path = location.pathname;

  const className = (el) => (el.name === selected ? "row selected" : "row");
  return (
    <div className="course__edit__panel">
      {/* <h1>Add Lectures</h1> */}
      <div className="action__rows">
        {Data.map((el, i) => (
          <button
            className={className(el)}
            key={i}
            onClick={() => setSelected(el.name)}
          >
            <img src={el.icon} alt="" />
            <span>{el.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
