import React from "react";
import "./OverviewDescription.styles.scss";
export default function OverviewDescription({ description }) {
  return (
    <div className="course__description__container">
      <div className="title">
        <h1>Description</h1>
        <div className="content">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
