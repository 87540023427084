import React, { useEffect, useRef, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch } from "react-redux";
import { getFAQs } from "../../../ReduxToolkit/Slices/adminSlice";
import Pagination from "../../../Components/Pagination/Pagination";
const TeacherSignupFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    dispatch(getFAQs(`userType=TEACHER&limit=100`)).then((data) => {
      if (data.type.includes("fulfilled")) {
        setFaqs(data?.payload?.faqs);
        setPage(data?.payload?.numOfPages);
      }
    });
  }, [currentPage]);

  const cleanSpacesFromAnswer = (answer) => {
    // Remove empty <p> tags
    let cleanedAnswer = answer.replace(
      /<p class="ql-align-justify"><br><\/p>/g,
      ""
    );
    cleanedAnswer = cleanedAnswer.replace(/<p><br><\/p>/g, "");
    // Replace multiple spaces with a single space
    cleanedAnswer = cleanedAnswer.replace(/\s+/g, " ");
    return cleanedAnswer;
  };

  return (
    <div className="teacher_signup_container_faq">
      <div className="faq-container">
        <h1>FAQs</h1>
        <div className="faq">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleAccordion(index)}
            >
              <div className="faq-question">
                <h2>
                  {faq.question}
                  <ChevronRightIcon />
                </h2>
              </div>
              <div
                className="faq-answer"
                style={{ maxHeight: activeIndex === index ? "200px" : "0" }}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: cleanSpacesFromAnswer(faq?.answer),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        {/* <Pagination
          page={2}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        /> */}
      </div>
    </div>
  );
};

export default TeacherSignupFaq;
