import React from "react";

import Header from "../../Sections/Header/Header";
import OurLessons from "../../Sections/OurLessons/OurLessons";
import OurCourses from "../../Sections/OurLessons/OurCourses";
import Categories from "../../Sections/Categories/Categories";
import WhereToLearn from "../../Sections/WhereToLearn/WhereToLearn";
import Newsletter from "../../Sections/NewsLetter/Newsletter";
import RelatedLessons from "../../Sections/RelatedLessons/RelatedLessons";
import Info from "../../Sections/Info/Info";
import { useSelector } from "react-redux";
import ScrollAnimation from "../../SharedApp/Pages/ScrollAnimation/ScrollAnimation";
import Landing from "../../Sections/Landing/Landing";
export default function HomePage() {
  const { showCourses } = useSelector((state) => state.user);

  return (
    <>
      <Header />
      <OurLessons />
      {showCourses && <OurCourses />}
      {/* <Landing /> */}
      <WhereToLearn />
      <Info />
      <Categories />
      {/* <Promo /> */}
      <Newsletter />
    </>
  );
}
