import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategoriesWithSubjects } from "../../../ReduxToolkit/Slices/lessonsSlice";

import "./SyllableList.styles.scss";

export default function SyllableList() {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dispatch(getCategoriesWithSubjects()).then((res) => {
      setCategories(res.payload.data.categories);
    });
  }, []);

  const navigate = useNavigate();

  const navigateCategories = () => {
    navigate("categories");
  };
  return (
    <div className="syllable__list__admin">
      <div className="header">
        <div className="total__cat">
          <h1>Lesson Category</h1>
          <span>Total 382 lesson category</span>
        </div>
        <button onClick={() => navigateCategories()}>View All</button>
      </div>
      <ul>
        {categories.length &&
          categories.slice(0, 5).map((cat, i) => (
            <li key={i}>
              <span>{cat.name}</span>
              {cat.subjects.map((subject, i) => (
                <p key={i}>{subject.name}</p>
              ))}
            </li>
          ))}
      </ul>
      <button className="button2nd" onClick={() => navigateCategories()}>
        <span>Add Categories</span>
      </button>
    </div>
  );
}
