import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { customFetchToken } from "../customFetch/customFetch";

const initialState = {
  courseId: null,
  refreshCart: false,
  cartItems: [],
  course: {
    teacherId: "",
    title: "",
    description: "",
    whatLearn: [""],
    whoFor: [""],
    price: 1,
    announcements: [""],
    categoryNames: [""],
    subjects: [""],
    profileImage: {
      publicId: "",
      url: "",
    },
  },
  chapters: [
    {
      courseId: "",
      title: "Homework Name",
      chapterId: "",
      lessons: [],
    },
  ],
};

export const getAllCourses = createAsyncThunk(
  "getAllCourses",
  async (filters, thunkApi) => {
    let url = `/courses?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getStudentCourses = createAsyncThunk(
  "getStudentCourses",
  async (filters, thunkApi) => {
    let url = `/courses/public?${filters}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentOwnedCourses = createAsyncThunk(
  "getStudentOwnedCourses",
  async (studentId, thunkApi) => {
    let url = `/courses/${studentId}/my`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const reviewCourse = createAsyncThunk(
  "reviewCourse",
  async (item, thunkApi) => {
    let url = `/courses/${item.studentId}/review`;
    try {
      const resp = await customFetchToken.post(url, item.review);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const addCourseToCart = createAsyncThunk(
  "addCourseToCart",
  async (item, thunkApi) => {
    let url = `/cart/${item.studentId}/${item.courseId}`;
    try {
      const resp = await customFetchToken.post(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getCartItems = createAsyncThunk(
  "getCartItems",
  async (studentId, thunkApi) => {
    let url = `/cart/${studentId}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const checkoutCart = createAsyncThunk(
  "checkoutCart",
  async (item, thunkApi) => {
    let url = `/cart/checkout`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteCartItem = createAsyncThunk(
  "deleteCartItem",
  async (item, thunkApi) => {
    let url = `/cart/${item.studentId}/${item.courseId}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const FavouriteCourse = createAsyncThunk(
  "FavouriteCourse",
  async (item, thunkApi) => {
    let url = `/courses/${item.studentId}/favorite/${item.courseId}`;
    try {
      const resp = await customFetchToken.post(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const unFavouriteCourse = createAsyncThunk(
  "unFavouriteCourse",
  async (item, thunkApi) => {
    let url = `/courses/${item.studentId}/favorite/${item.courseId}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentFavCourses = createAsyncThunk(
  "getStudentFavCourses",
  async (id, thunkApi) => {
    let url = `/courses/${id}/favorite`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const applyCoupon = createAsyncThunk(
  "applyCoupon",
  async (item, thunkApi) => {
    let url = `/cart/applyCoupon/${item.studentId}/${item.coupon}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentCoursesById = createAsyncThunk(
  "getStudentCoursesById",
  async (id, thunkApi) => {
    let url = `/courses/public/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getStudentCoursesByInstructor = createAsyncThunk(
  "getStudentCoursesByInstructor",
  async (id, thunkApi) => {
    let url = `/courses/public?teacherId=${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const createCourseCategory = createAsyncThunk(
  "createCourseCategory",
  async (item, thunkApi) => {
    let url = `/courseCategories/`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const createCourseSubject = createAsyncThunk(
  "createCourseCategory",
  async (item, thunkApi) => {
    let url = `/courseCategories/subject`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateCourseSubject = createAsyncThunk(
  "updateCourseSubject",
  async (item, thunkApi) => {
    let url = `/courseCategories/subject/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.subject);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getCourseSubjects = createAsyncThunk(
  "getCourseSubjects",
  async (_, thunkApi) => {
    let url = `/courseCategories/subject`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getCourseSubjectsByCategory = createAsyncThunk(
  "getCourseSubjects",
  async (category, thunkApi) => {
    let url = `/courseCategories/subjectbyCategory/${category}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteCourseSubjects = createAsyncThunk(
  "deleteCourseSubjects",
  async (name, thunkApi) => {
    let url = `/courseCategories/subject/${name}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getCourseSubjectsbyName = createAsyncThunk(
  "getCourseSubjectsbyName",
  async (name, thunkApi) => {
    let url = `/courseCategories/subject/${name}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getCoursesCategoryByName = createAsyncThunk(
  "getCoursesCategoryByName",
  async (name, thunkApi) => {
    let url = `/courseCategories/${name}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteCoursesCategory = createAsyncThunk(
  "getCoursesCategoryByName",
  async (name, thunkApi) => {
    let url = `/courseCategories/${name}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateCourseCategory = createAsyncThunk(
  "updateCourseCategory",
  async (item, thunkApi) => {
    let url = `/courseCategories/${item.name}`;
    try {
      const resp = await customFetchToken.patch(url, item.category);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const studentWatchedChapter = createAsyncThunk(
  "studentWatchedChapter",
  async (item, thunkApi) => {
    let url = `/chapters/studentWatchedChaptersAndLessons/${item.studentId}`;

    try {
      const resp = await customFetchToken.patch(url, {
        chapterLessonId: item.LessonId,
      });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getWatched = createAsyncThunk(
  "studentWatchedChapter",
  async (studentId, thunkApi) => {
    let url = `/chapters/studentWatchedChaptersAndLessons/${studentId}`;

    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getTeacherCourses = createAsyncThunk(
  "getAllCourses",
  async (filters, thunkApi) => {
    let url = `/courses?${filters}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteCourse = createAsyncThunk(
  "getAllCourses",
  async (id, thunkApi) => {
    let url = `/courses/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const ApproveCourse = createAsyncThunk(
  "ApproveCourse",
  async (id, thunkApi) => {
    let url = `/admin/courses/approval/${id}`;
    try {
      const resp = await customFetchToken.post(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const RejectCourse = createAsyncThunk(
  "RejectCourse",
  async (id, thunkApi) => {
    let url = `/admin/courses/approval/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getCourseCategories = createAsyncThunk(
  "getCourseCategories",
  async (params, thunkApi) => {
    let url = `/courseCategories`;
    try {
      const resp = await customFetch.get(url, { params });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getCourseById = createAsyncThunk(
  "getCourseById",
  async (id, thunkApi) => {
    let url = `/courses/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const createACourse = createAsyncThunk(
  "createACourse",
  async (item, thunkApi) => {
    let url = `/courses`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateCourse = createAsyncThunk(
  "updateCourse",
  async (item, thunkApi) => {
    let url = `/courses/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.course);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const createAChapter = createAsyncThunk(
  "createAChapter",
  async (item, thunkApi) => {
    let url = `/chapters`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateChapter = createAsyncThunk(
  "updateChapter",
  async (item, thunkApi) => {
    let url = `/chapters/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.chapter);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateChapterLesson = createAsyncThunk(
  "updateChapterLesson",
  async (item, thunkApi) => {
    let url = `/chapters/chapterLesson/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.chapter);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteChapterLesson = createAsyncThunk(
  "deleteChapterLesson",
  async (id, thunkApi) => {
    let url = `/chapters/chapterLesson/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const addLessons = createAsyncThunk(
  "addLessons",
  async (item, thunkApi) => {
    let url = `/chapters/chapterLesson`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    resetCourseState: () => {
      return initialState;
    },
    handleCoursesStateChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    handleCourseChange: (state, action) => {
      const { name, value } = action.payload;
      state.course = {
        ...state.course,
        [name]: value,
      };
    },
    handleAddChapter: (state, action) => {
      state.chapters = [...state.chapters, action.payload];
    },
    handleAddLesson: (state, action) => {
      const { name, value, index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [...state.chapters[index].lessons, value],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleEditLesson: (state, action) => {
      const { name, value, index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            value,
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleDeleteLesson: (state, action) => {
      const { index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleChapterChange: (state, action) => {
      const { name, value, index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          [name]: value,
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleDeleteChapter: (state, action) => {
      const { index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        ...state.chapters.slice(index + 1),
      ];
    },
    handleLessonsChange: (state, action) => {
      const { name, value, index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            {
              ...state.chapters[index].lessons[subIndex],
              [name]: value,
            },
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
  },
});

export const {
  handleCourseChange,
  handleChapterChange,
  handleAddChapter,
  handleCoursesStateChange,
  handleLessonsChange,
  handleAddLesson,
  handleEditLesson,
  handleDeleteLesson,
  handleDeleteChapter,
  resetCourseState,
} = coursesSlice.actions;

export default coursesSlice.reducer;
