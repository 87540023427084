export const steps = [
  {
    element: ".tutor",
    title: "Find Lesson Tutor",
    intro:
      "Here, you can view a list of tutors, you can also search for a specific lesson or category",
  },
  {
    element: ".tutor__preview__modal",
    title: "Find Lesson Tutor",
    intro: "Here, you can view the teacher's availability",
  },
  {
    element: ".lessontype_filters",
    title: "Lessons Type",
    intro: "These are the different lessons types.",
  },
  {
    element: ".filter_1",
    title: "Private lessons",
    intro: "private lessons are 1 on 1 lessons",
  },
  {
    element: ".filter_2",
    title: "Group lessons",
    intro:
      "Group lessons are lessons where there are multiple students taught by 1 teacher.",
  },
  {
    element: ".filter_3",
    title: "Courses",
    intro: "Courses are pre-recorded lessons.",
  },
  {
    element: ".fitler__btn__mobile",
    title: "Filters",
    intro:
      "By clicking this button you reveal the lessons filters. you can search for a price range, lesson location and more..",
  },
];
