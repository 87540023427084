import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

import tutor from "../../../assets/SharedApp/images/tutor.webp";
import arrr from "../../../assets/AdminApp/svgs/arrr.svg";
import arrt from "../../../assets/AdminApp/svgs/arrt.svg";
import "./LessonAccordion.styles.scss";
import { useEffect } from "react";
import { getVideoIdFromUrl, updateCloudinaryUrl } from "../../../hooks";
import { ExchangeRateHook } from "../../../ExchangeRateHook";
import { useDispatch, useSelector } from "react-redux";
import { getPublicSettings } from "../../../ReduxToolkit/Slices/userSlice";
import {
  setAdminState,
  updateLessonPrices,
} from "../../../ReduxToolkit/Slices/adminSlice";

export default function LessonAccordion({ lesson, number }) {
  const [height, setHeight] = useState(false);
  const [prices, setPrices] = useState({
    onlinePrice: lesson?.onlinePrice ? lesson?.onlinePrice : 0,
    teacherPlacePrice: lesson?.teacherPlacePrice
      ? lesson?.teacherPlacePrice
      : 0,
    studentPlacePrice: lesson?.studentPlacePrice
      ? lesson?.studentPlacePrice
      : 0,
  });
  const { publicSetting } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    !publicSetting?.studentFee &&
      dispatch(getPublicSettings()).then((res) => {
        res?.payload?.adminSetting &&
          dispatch(
            setAdminState({
              name: "publicSetting",
              value: res?.payload?.adminSetting,
            })
          );
      });
  }, [publicSetting]);
  const StudentFeeCalculator = (price) => {
    const priceAfterStudentFee =
      price + (price * publicSetting.studentFee) / 100;
    return priceAfterStudentFee;
  };

  const onSubmitPrices = () => {
    dispatch(updateLessonPrices({ id: lesson.id, prices })).then((res) => {
      if (res.type === "updateLessonPrices/fulfilled") {
        toast.success("Prices updated!");
      } else {
        toast.error("Error updating prices");
      }
    });
  };
  return (
    <div
      className="lesson__accordion"
      style={height ? { height: "auto" } : { height: "30px" }}
    >
      <div className="accordion__row">
        <div className="row">
          <img src={arrr} alt="" />
          <span className="lesson__name">Lesson #{number + 1}</span>
        </div>
        <div className="row">
          <img
            src={arrt}
            alt=""
            className={`${height ? "clicked__arrow" : ""}`}
            onClick={() => setHeight(!height)}
          />
        </div>
      </div>
      {height && (
        <>
          <span className="lesson__name">{lesson.title}</span>

          <div className="acc__content">
            <div className="sub__row">
              <span>Lesson level</span> <p>{lesson.level}</p>
            </div>
            <div className="sub__row">
              <span>Description</span> <p>{lesson.description}</p>
            </div>
            <div className="sub__row">
              <span>Video Intro</span>
              {lesson?.videos?.length &&
                !lesson?.videos[0].url.includes("youtube") && (
                  <video width="320" height="240" controls>
                    <source
                      src={updateCloudinaryUrl(lesson.videos[0].url)}
                      type="video/mp4"
                    />
                  </video>
                )}
              {lesson?.videos[0]?.url.includes("youtube") && (
                <iframe
                  width="320"
                  height="240"
                  src={`https://www.youtube.com/embed/${getVideoIdFromUrl(
                    lesson?.videos[0]?.url
                  )}`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              )}
            </div>
            <div className="sub__row last__row">
              <span>Lesson Photo</span>
              <div className="images">
                {/* <img src={tutor} alt="" />
            <img src={tutor} alt="" />
            <img src={tutor} alt="" /> */}
                {lesson.images.map((image) => (
                  <Tooltip title="Click to preview" arrow placement="top">
                    <a href={image.url} target="_blank">
                      <img src={image.url} alt="certificate" />
                    </a>
                  </Tooltip>
                ))}
              </div>
            </div>
            <div className="submission__details">
              <h3>Lesson Price</h3>
              <div className="inner__details">
                {lesson?.location?.includes("online") && (
                  <div className="sub__row">
                    <div className="price__picker">
                      <h3>Online Lesson Price</h3>

                      <div className="row">
                        <span className="duration">For 30mins</span>
                        <div className="input">
                          <div className="number__input">
                            <span>$</span>
                            <input
                              type="number"
                              placeholder="Amount"
                              onChange={(e) =>
                                setPrices({
                                  ...prices,
                                  onlinePrice: e.target.value * 100,
                                })
                              }
                              value={(prices.onlinePrice / 100).toFixed(2)}
                            />
                          </div>
                        </div>
                        <div className="student__price">
                          <span className="std__price">Student Price</span>
                          <ExchangeRateHook
                            price={StudentFeeCalculator(prices.onlinePrice)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <span className="duration">For 60mins</span>
                        <div className="input">
                          <div className="number__input">
                            <span>$</span>
                            <input
                              type="text"
                              placeholder="Amount"
                              value={((prices.onlinePrice * 2) / 100).toFixed(
                                2
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="student__price">
                          <span className="std__price">Student Price</span>
                          <ExchangeRateHook
                            price={StudentFeeCalculator(prices.onlinePrice * 2)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* online */}
                {lesson?.location?.includes("studentPlace") && (
                  <div className="sub__row">
                    <div className="price__picker">
                      <h3>Student Place Price</h3>

                      <div className="row">
                        <span className="duration">For 30mins</span>
                        <div className="input">
                          <div className="number__input">
                            <span>$</span>
                            <input
                              type="number"
                              placeholder="Amount"
                              value={(
                                prices.studentPlacePrice.toFixed(2) / 100
                              ).toFixed(2)}
                              onChange={(e) =>
                                setPrices({
                                  ...prices,
                                  studentPlacePrice: e.target.value * 100,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="student__price">
                          <span className="std__price">Student Price</span>
                          <ExchangeRateHook
                            price={StudentFeeCalculator(
                              prices.studentPlacePrice
                            )}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <span className="duration">For 60mins</span>
                        <div className="input">
                          <div className="number__input">
                            <span>$</span>
                            <input
                              type="text"
                              placeholder="Amount"
                              value={(
                                (prices.studentPlacePrice * 2) /
                                100
                              ).toFixed(2)}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="student__price">
                          <span className="std__price">Student Price</span>

                          <ExchangeRateHook
                            price={StudentFeeCalculator(
                              prices.studentPlacePrice * 2
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* student */}
                {lesson?.location?.includes("teacherPlace") && (
                  <div className="sub__row">
                    <div className="price__picker">
                      <h3>Teacher Lesson Price</h3>

                      <div className="row">
                        <span className="duration">For 30mins</span>
                        <div className="input">
                          <div className="number__input">
                            <span>$</span>
                            <input
                              type="number"
                              placeholder="Amount"
                              value={(prices.teacherPlacePrice / 100).toFixed(
                                2
                              )}
                              onChange={(e) =>
                                setPrices({
                                  ...prices,
                                  teacherPlacePrice: e.target.value * 100,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="student__price">
                          <span className="std__price">Student Price</span>
                          <ExchangeRateHook
                            price={StudentFeeCalculator(
                              prices.teacherPlacePrice
                            )}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <span className="duration">For 60mins</span>
                        <div className="input">
                          <div className="number__input">
                            <span>$</span>
                            <input
                              type="text"
                              placeholder="Amount"
                              value={(
                                (prices.teacherPlacePrice * 2) /
                                100
                              ).toFixed(2)}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="student__price">
                          <span className="std__price">Student Price</span>

                          <ExchangeRateHook
                            price={StudentFeeCalculator(
                              prices.teacherPlacePrice * 2
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {user.role === "ADMIN" && !user.limited && (
                <button className="primary" onClick={() => onSubmitPrices()}>
                  Update Prices
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
