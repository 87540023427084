import "./button.styles.scss";

/**
 * Button component.
 *
 * @param {object} props
 * @param {('cancel'|'primary'|'secondary')} props.variant - The variant of the button.
 * @param {React.ReactNode} props.children - The content of the button.
 * @param {Function} [props.onClick] - Click handler for the button.
 */

export default function Button({
  onClick,
  className,
  image,
  children,
  type = "submit",
  variant,
  disabled = false,
}) {
  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
  };

  return (
    <button
      disabled={disabled}
      type={type}
      className={`shared_btn ${variant ?? "base_button"} ${className ?? ""}`}
      onClick={handleClick}
    >
      {image && <img src={image} alt="button image" />}
      {children}
    </button>
  );
}
