import React from "react";
import "./TeacherMediaSection.styles.scss";
import { getVideoIdFromUrl, updateCloudinaryUrl } from "../../../hooks";
import { useParams } from "react-router-dom";
export default function TeacherMediaSection({ images, video }) {
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const videoUrl = video?.url;
  const videoId = getVideoIdFromUrl(videoUrl);
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  const { id } = useParams();

  return (
    <div className="tutor__page__media__page" id="media">
      {/* <Slider {...settings} className="tutor__media__slider"> */}
      {/* {[1, 2, 3, 4].map((sl) => ( */}
      {!!images.length ||
        (video?.url && (
          <div className="tutor__page__media" id="description">
            <div className="header">
              <h4>Photos & Videos</h4>
            </div>
            <div className="media__container__page" id="tutor-media" key={id}>
              <div className="media__container__inner">
                {video?.url && (
                  <div className="video_combo">
                    {!video?.url.includes("youtu.be") &&
                    !video?.url.includes("youtube.com") ? (
                      <video
                        src={updateCloudinaryUrl(video?.url)}
                        alt=""
                        className="tall"
                        controls
                      />
                    ) : (
                      <iframe
                        className="tall"
                        src={embedUrl}
                        title="YouTube Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    )}
                  </div>
                )}
                <div className="images_combo">
                  {images &&
                    images.map((img) => (
                      <img src={img?.url} alt="tutor media" />
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      {/* ))} */}
      {/* </Slider> */}
    </div>
  );
}
