import React from "react";
import "./CourseCategorySwitcher.styles.scss";
import points from "../../../../assets/HomeApp/images/png.png";
import { useState } from "react";
import { Link } from "react-router-dom";
export default function CourseCategorySwitcher({
  selected,
  setSelected,
  categories,
  otherCategories,
}) {
  const [showList, setShowList] = useState(false);
  return (
    <div className="category__switcher">
      {categories &&
        categories.length > 0 &&
        categories.map((el, i) => (
          <div
            key={i}
            className={`category__item ${
              selected === el.name ? "selected" : ""
            }`}
            onClick={() => setSelected(el.name)}
          >
            <span>{el.name}</span>
          </div>
        ))}

      <div
        className="absolute__points"
        onMouseEnter={() => setShowList(true)}
        onMouseLeave={() => setShowList(false)}
      >
        <img src={points} alt="" />
      </div>
      <div
        className={`absolute__list ${showList ? "show" : ""}`}
        onMouseEnter={() => setShowList(true)}
        onMouseLeave={() => setShowList(false)}
      >
        {otherCategories &&
          otherCategories.length &&
          otherCategories.map((cat, i) => (
            <Link to={`/courses/categories/${cat.name}`}>
              <span key={i}>{cat.name}</span>
            </Link>
          ))}
      </div>
    </div>
  );
}
