import React from "react";

import "./SocialShare.styles.scss";
import facebook from "../../../assets/SVG/facebooks.svg";
import instagram from "../../../assets/SVG/instagram.svg";
import twitter from "../../../assets/SVG/twitter.svg";
import { useNavigate } from "react-router-dom";
import { social } from "../../../assets/SharedApp/data/Packages";

function SocialShare() {
  return (
    <div className="SocialShare_Container flex_center">
      <div className="sub flex_center">
        {social &&
          social.map((soc, i) => (
            <a key={i} href={soc.link}>
              <img src={soc.image} />
            </a>
          ))}
      </div>
    </div>
  );
}

export default SocialShare;
