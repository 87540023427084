import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, {
  customFetchToken,
  setAuthToken,
} from "../customFetch/customFetch";
import { setAccessToken } from "./userSlice";

const teacher = {
  title: "",
  description: "",
  hourlyRate: 0,
  skills: [],
  location: [],
  experience: {
    title: "",
    qualifications: [],
    certifications: [],
    experienceYears: 0,
  },
  background: {
    status: "UNCHECKED",
    image: {
      url: "",
      publicId: "",
      fileName: "",
    },
  },
  subjectIds: [],
  categoryIds: [],
  categories: [],
  subjects: [],
  languages: [
    {
      name: "",
      proficiency: "ADVANCED",
    },
  ],
  educationLevel: [
    {
      university: "",
      degree: "",
      degreeType: "none",
      startDate: "",
      endDate: "",
      specialization: "",
    },
  ],
  pets: true,
  levels: [],
  images: [],
  videos: [],
};

const lesson = {
  teacherId: "",
  title: "",
  description: "",
  price: 0,
  location: [""],
  level: "",
  images: [],
  videos: [],
  categoryIds: [""],
  subjectIds: [""],
  isGroup: true,
  groupLessonTime: {
    day: "",
    startTime: "",
    endTime: "",
    date: "",
    timeZone: "",
  },
  maxStudents: 0,
};

const initialState = {
  fetchedSubjects: false,
  teachLessons: true,
  teachCourses: false,
  refreshRequests: false,
  showCourses: false,
  submitProfile: false,
  submitDetails: false,
  submitSubjectCategory: false,
  refreshTeacher: false,
  teacherToRegister: {
    name: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    phone: "",
    gender: "",
    isAdult: "",
  },
  availabilitesToAdd: [],
  user: {
    name: "",
    email: "",
    role: "TEACHER",
    password: "",
    // geoLocation: {
    //   latitude: 0,
    //   longitude: 0,
    // },
    firebaseToken: "",
    bySocial: false,
    phone: "",
    nationality: "",
    profileImage: {},
    gender: "",
    lastName: "",
  },
  lessons: [lesson],
  lesson,
  relatedInfo: {
    teacherId: "",
  },
  subjects: [],
  teacherAvailabilites: [],
  teacher: teacher,
  lessonId: null,
  chapters: [
    {
      courseId: "",
      title: "Chapter Name",
      chapterId: "",
      lessons: [],
    },
  ],
  resetLessonIndex: 0,
};
export const registerTeacher = createAsyncThunk(
  "registerTeacher",
  async (teacher, thunkApi) => {
    let url = "/teachers";
    try {
      const resp = await customFetch.post(url, teacher);
      const { accessToken, refreshToken } = resp.data;
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("user", JSON.stringify(resp?.data?.user));
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("teacher", JSON.stringify(resp.data.user.teacher));
      setAuthToken(accessToken);
      thunkApi.dispatch(teacherSlice.actions.handleTeacher(resp.data));
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const getTeacherById = createAsyncThunk(
  "getTeacherById",
  async (id, thunkApi) => {
    let url = `/teachers/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getTeacherPaymentInfo = createAsyncThunk(
  "getTeacherPaymentInfo",
  async (filters, thunkApi) => {
    let url = `/admin/TeacherPaymentInfo`;
    try {
      const resp = await customFetchToken.get(url, {
        params: filters,
      });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getTeacherByIdAdmin = createAsyncThunk(
  "getTeacherByIdAdmin",
  async (id, thunkApi) => {
    let url = `/teachers/admin/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getTeacherByIdWithoutSave = createAsyncThunk(
  "getTeacherByIdWithoutSave",
  async (id, thunkApi) => {
    let url = `/teachers/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherCoupons = createAsyncThunk(
  "getTeacherCoupons",
  async (search, thunkApi) => {
    let url = `/coupons?search=${search}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
const removeEmptyProperties = (obj) => {
  // Check if both minPrice and maxPrice are 0
  const shouldDeletePrice = obj.minPrice === 0 && obj.maxPrice === 0;

  for (const key in obj) {
    // If both minPrice and maxPrice are 0, delete them
    if (shouldDeletePrice && (key === "minPrice" || key === "maxPrice")) {
      delete obj[key];
    } else if (!obj[key] && obj[key] !== 0) {
      // Delete other falsy values except explicit 0
      delete obj[key];
    }
  }
  return obj;
};

export const getAllLessons = createAsyncThunk(
  "getAllLessons",
  async (filters, thunkApi) => {
    let url = `/teachers`;
    const cleanedFilters = removeEmptyProperties(filters);
    try {
      const resp = await customFetch.get(url, {
        params: cleanedFilters,
      });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const createUpdateBGCHECK = createAsyncThunk(
  "createUpdateBGCHECK",
  async (input, thunkApi) => {
    let url = `/teachers/backgroundCheck/`;
    try {
      const resp = await customFetch.post(url, input);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAllGroupLessons = createAsyncThunk(
  "getAllGroupLessons",
  async (filters, thunkApi) => {
    let url = `/lessons/list`;
    const cleanedFilters = removeEmptyProperties(filters);

    try {
      const resp = await customFetch.get(url, {
        params: cleanedFilters,
      });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addTeacherCoupons = createAsyncThunk(
  "addTeacherCoupons",
  async (coupon, thunkApi) => {
    let url = `/coupons`;
    try {
      const resp = await customFetchToken.post(url, coupon);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getQuestionById = createAsyncThunk(
  "getQuestionById",
  async (id, thunkApi) => {
    let url = `/teachers/questions/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const answerQuestion = createAsyncThunk(
  "answerQuestion",
  async (item, thunkApi) => {
    let url = `/teachers/questions/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, { answer: item.answer });
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const toggleAvailable = createAsyncThunk(
  "toggleAvailable",
  async (_, thunkApi) => {
    let url = `/teachers/switch/`;
    try {
      const resp = await customFetchToken.post(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const askForRecommendation = createAsyncThunk(
  "askForRecommendation",
  async (email, thunkApi) => {
    let url = `/teachers/recommendations/`;
    try {
      const resp = await customFetchToken.post(url, email);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const recommendTeacher = createAsyncThunk(
  "recommendTeacher",
  async (item, thunkApi) => {
    let url = `/teachers/recommendations/`;
    try {
      const resp = await customFetch.put(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getTeacherCouponBYID = createAsyncThunk(
  "getTeacherCoupons",
  async (id, thunkApi) => {
    let url = `/coupons/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteTeacherCouponBYID = createAsyncThunk(
  "deleteTeacherCouponBYID",
  async (id, thunkApi) => {
    let url = `/coupons/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const updateTeacherCouponBYID = createAsyncThunk(
  "updateTeacherCouponBYID",
  async (item, thunkApi) => {
    let url = `/coupons/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.coupon);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "uploadImage",
  async (image, thunkApi) => {
    let url = "/images/upload";
    try {
      const resp = await customFetch.post(url, image);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const teacherRequestPayments = createAsyncThunk(
  "teacherRequestPayments",
  async (_, thunkApi) => {
    let url = "/notifications/requestpayment";
    try {
      const resp = await customFetchToken.post(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const uploadDocument = createAsyncThunk(
  "uploadDocument",
  async (image, thunkApi) => {
    let url = "/images/upload-document";
    try {
      const resp = await customFetch.post(url, image);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const uploadImages = createAsyncThunk(
  "uploadImages",
  async (images, thunkApi) => {
    let url = "/images/uploads";
    try {
      const resp = await customFetch.post(url, images);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const uploadVideo = createAsyncThunk(
  "uploadImage",
  async (video, thunkApi) => {
    let url = "/images/upload-video";
    try {
      const resp = await customFetch.post(url, video);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const createLesson = createAsyncThunk(
  "createLesson",
  async (lesson, thunkApi) => {
    let url = "/lessons";
    try {
      const resp = await customFetchToken.post(url, lesson);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createCalendar = createAsyncThunk(
  "createCalendar",
  async (info, thunkApi) => {
    let url = `/teachers/${info.id}/availability`;
    try {
      const resp = await customFetchToken.post(url, info.availabilites);
      thunkApi.dispatch(
        teacherSlice.actions.handleSliceChange({
          name: "teacherAvailabilites",
          value: resp.data,
        })
      );
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getTeacherAvailabilites = createAsyncThunk(
  "getTeacherAvailabilites",
  async (id, thunkApi) => {
    let url = `/teachers/${id}/availability`;
    try {
      const resp = await customFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const teacherPostTestLesson = createAsyncThunk(
  "teacherPostTestLesson",
  async (video, thunkApi) => {
    let url = `/teachers/testVideo`;
    try {
      const resp = await customFetchToken.post(url, video);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const checkUserEmail = createAsyncThunk(
  "checkUserEmail",
  async (email, thunkApi) => {
    let url = `/users/email/${email}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response.data.msg);
    }
  }
);
export const getTeacherLessons = createAsyncThunk(
  "getTeacherLessons",
  async (id, thunkApi) => {
    let url = `/teachers/${id}/lessons`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response.data.msg);
    }
  }
);
export const updateTeacher = createAsyncThunk(
  "updateTeacher",
  async (teacher, thunkApi) => {
    const id = teacher[0].teacherId;
    let url = `/teachers/${id}`;
    let newTeacher = teacher[1];
    try {
      const resp = await customFetch.patch(url, newTeacher);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const updateTeachers = createAsyncThunk(
  "updateTeachers",
  async (teacher, thunkApi) => {
    let url = `/teachers/${teacher.id}`;
    try {
      const resp = await customFetch.patch(url, teacher.teacher);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherNotifications = createAsyncThunk(
  "getTeacherNotifications",
  async (id, thunkApi) => {
    let url = `/notifications/teacher/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getTeacherNotificationsNumber = createAsyncThunk(
  "getTeacherNotificationsNumber",
  async (id, thunkApi) => {
    let url = `/notifications/number/teacher/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const createHomework = createAsyncThunk(
  "createHomework",
  async (item, thunkApi) => {
    let url = `/homework/teachers`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const deleteHomework = createAsyncThunk(
  "deleteHomework",
  async (id, thunkApi) => {
    let url = `/homework/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const getHomeworkById = createAsyncThunk(
  "getHomeworkById",
  async (id, thunkApi) => {
    let url = `/homework/${id}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);
export const reviewHomeworkById = createAsyncThunk(
  "reviewHomeworkById",
  async (item, thunkApi) => {
    let url = `/homework/${item.id}`;
    try {
      const resp = await customFetchToken.post(url, item.review);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getTeacherHomeworks = createAsyncThunk(
  "getTeacherHomeworks",
  async (_, thunkApi) => {
    let url = `/homework/teachers`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addLessons = createAsyncThunk(
  "addLessons",
  async (item, thunkApi) => {
    let url = `/chapters/chapterLesson`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const updateChapterLesson = createAsyncThunk(
  "updateChapterLesson",
  async (item, thunkApi) => {
    let url = `/chapters/chapterLesson/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.chapter);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteChapterLesson = createAsyncThunk(
  "deleteChapterLesson",
  async (id, thunkApi) => {
    let url = `/chapters/chapterLesson/${id}`;
    try {
      const resp = await customFetchToken.delete(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    handleUserChange: (state, action) => {
      const { value, name } = action.payload;
      state.user = { ...state.user, [name]: value };
    },
    handleTeacher: (state, action) => {
      state.teacher = action.payload;
    },
    handleTeacherChange: (state, action) => {
      const { value, name } = action.payload;
      state.teacher = { ...state.teacher, [name]: value };
    },
    handleSliceChange: (state, action) => {
      const { value, name } = action.payload;
      state[name] = value;
    },
    setTeacherEducationLevel: (state, action) => {
      state.teacher.educationLevel = action.payload;
    },
    setUserDescriptions: (state, action) => {
      state.teacher.title = action.payload.title;
      state.teacher.description = action.payload.description;
    },
    setUserSelectedCategories: (state, action) => {
      state.teacher.categoryIds = action.payload.categoryIds;
      state.teacher.subjectIds = action.payload.subjectIds;
      state.teacher.subjects = action.payload.subjects;
      state.teacher.categories = action.payload.categories;
    },
    setUserProfilePicture: (state, action) => {
      state.user.profileImage = action.payload;
    },
    setUserVideo: (state, action) => {
      state.teacher.videos = [action.payload];
    },
    setUserImages: (state, action) => {
      state.teacher.images = action.payload;
    },
    setUserCertifications: (state, action) => {
      state.teacher.experience.certifications = action.payload;
    },
    setTeacherId: (state, action) => {
      state.relatedInfo.teacherId = action.payload;
    },
    setLessonInfo: (state, action) => {
      state.lesson = action.payload;
    },
    setLessonImages: (state, action) => {
      state.lessons[action.payload.index].images = [
        ...state.lesson.images,
        action.payload.images,
      ];
    },
    setLessonVideo: (state, action) => {
      state.lesson.videos = [action.payload];
    },
    setCategories: (state, action) => {
      state.teacher.categories = action.payload;
    },
    setTeacherLessons: (state, action) => {
      state.lessons = action.payload;
    },
    setFetchedSubjects: (state, action) => {
      state.fetchedSubjects = action.payload;
    },
    setTeacherPets: (state, action) => {
      state.teacher.pets = action.payload;
    },
    setTeacherBackground: (state, action) => {
      state.teacher.background = action.payload;
    },
    setTeacherGeoLocation: (state, action) => {
      state.teacher.user.geoLocation = action.payload;
    },
    setTeacherAddressStreet: (state, action) => {
      state.teacher.user.address.street = action.payload;
    },
    resetTeacherState: (state) => {
      return initialState;
    },
    handleChapterChange: (state, action) => {
      const { name, value, index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          [name]: value,
        },
        ...state.chapters.slice(index + 1),
      ];
    },

    handleAddLesson: (state, action) => {
      const { name, value, index } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [...state.chapters[index].lessons, value],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleEditLesson: (state, action) => {
      const { name, value, index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            value,
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },

    handleDeleteLesson: (state, action) => {
      const { index, subIndex } = action.payload;
      state.chapters = [
        ...state.chapters.slice(0, index),
        {
          ...state.chapters[index],
          lessons: [
            ...state.chapters[index].lessons.slice(0, subIndex),
            ...state.chapters[index].lessons.slice(subIndex + 1),
          ],
        },
        ...state.chapters.slice(index + 1),
      ];
    },
    handleResetLessonIndex: (state, action) => {
      state.resetLessonIndex = state.resetLessonIndex - action.payload;
    },
  },
  extraReducers: {
    [getTeacherById.fulfilled]: (state, { payload }) => {
      state.teacher = payload;
    },
  },
});

export const {
  setUserDescriptions,
  setUserSelectedCategories,
  setTeacherAddressStreet,
  handleTeacherChange,
  handleUserChange,
  setUserProfilePicture,
  setUserVideo,
  setUserImages,
  handleTeacher,
  setTeacherId,
  setTeacherGeoLocation,
  setTeacherLessons,
  setLessonImages,
  setLessonVideo,
  handleSliceChange,
  setCategories,
  setFetchedSubjects,
  setUserCertifications,
  setTeacherEducationLevel,
  setTeacherPets,
  resetTeacherState,
  setTeacherBackground,
  handleResetLessonIndex,
  handleChapterChange,
  handleEditLesson,
  handleAddLesson,
  handleDeleteLesson,
} = teacherSlice.actions;

export default teacherSlice.reducer;
