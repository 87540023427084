import React, { useEffect, useState } from "react";
import "./sharedBlogs.styles.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllBlogs } from "../../../ReduxToolkit/Slices/adminSlice";
import { BlogCard } from "./BlogCard";
import Pagination from "../../../Components/Pagination/Pagination";

const SharedBlogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(getAllBlogs({ limit: "10", page: currentPage, search })).then(
      (res) => {
        if (res.type.includes("fulfilled")) {
          setBlogs(res?.payload?.data);
        }
      }
    );
  }, [currentPage, search]);

  const handleBlogClick = (id) => {
    navigate(`/blogs/${id}`);
  };

  return (
    <div className="shared_blogs">
      <div className="title">
        <h2>View our blogs</h2>
      </div>
      <div className="search">
        <input
          type="search"
          placeholder="search blogs..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="all_blogs">
        {blogs?.blogs?.length ? (
          blogs.blogs.map((blog) => (
            <BlogCard key={blog.id} {...blog} onClick={handleBlogClick} />
          ))
        ) : search ? (
          <p>
            We couldn’t find any blogs matching your search criteria. Try
            adjusting your search or check back later for new updates.
          </p>
        ) : (
          <p>No blogs available at the moment.</p>
        )}
      </div>
      <div className="blog_pagination">
        <Pagination
          page={blogs?.numOfPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          dataRendered={blogs?.blogs?.length}
          totalData={blogs?.totalBlogs}
        />
      </div>
    </div>
  );
};

export default SharedBlogs;
