import React, { useEffect, useState } from "react";
// import HorizontalCourseCard from "../../../../StudentApp/Components/Courses/HorizontalCourseCard/HorizontalCourseCard";
import "./Cart.styles.scss";
import CoursesContainer from "../../../Components/Courses/CoursesContainer/CoursesContainer";
import CartPanel from "../../../Components/Courses/CartPanel/CartPanel";
// import Slider from "react-slick";
// import CourseCard from "../../../../StudentApp/Components/Courses/CourseCard/CourseCard";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "../../../../ReduxToolkit/Slices/coursesSlice";
import HorizontalCourseCard from "../../../../StudentApp/Components/Courses/HorizontalCourseCard/HorizontalCourseCard";
import { Link } from "react-router-dom";
import stars from "../../../../assets/TeacherApp/assets/svgs/rating.svg";
import defaultImage from "../../../../assets/AdminApp/images/defautImage.jpg";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";
import { useNavigate } from "react-router-dom";

import {
  handleLessonChange,
  selectLesson,
} from "../../../../ReduxToolkit/Slices/lessonsSlice";
import {
  deleteAvandedCartItem,
  getAvandedCart,
  refreshStudentCart,
  setStudentCart,
  setStudentState,
} from "../../../../ReduxToolkit/Slices/studentSlice";
export default function Cart() {
  const [refresh, setRefresh] = useState(false);
  const [courses, setCourses] = useState([]);
  const [total, setTotal] = useState(undefined);

  const dispatch = useDispatch();
  const { student } = useSelector((state) => state.student);
  // useEffect(() => {
  //   dispatch(getCartItems(student)).then((res) => {
  //     setCourses(res.payload.cartItems);
  //     setTotal(res.payload.total);
  //   });
  // }, [refresh]);

  const responsive = [
    {
      breakpoint: 1285,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  return (
    <div className="courses__cart">
      <div className="cart__container">
        <h1>Cart</h1>
        <div className="sm">
          <div className="sm2">
            {!!courses?.length && (
              <CoursesContainer
                title={"Cart Items"}
                type={"Cart"}
                courses={courses}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )}
            <LessonsContainer />
          </div>
          {total > 0 && <CartPanel total={total} />}
        </div>
        {/* <div className="cart__slider__container"> */}
        {/* <Slider
            centerPadding={50}
            slidesToShow={4}
            slidesToScroll={1}
            className="online__courses__slider"
            infinite={true}
            responsive={responsive}
          > */}
        {/* <CourseCard noShadow />
            <CourseCard noShadow />
            <CourseCard noShadow />
            <CourseCard noShadow />
            <CourseCard noShadow />
            <CourseCard noShadow />
            <CourseCard noShadow /> */}
        {/* </Slider> */}
        {/* </div> */}
      </div>
    </div>
  );
}

function LessonsContainer({ title, type, lesson, refresh, setRefresh }) {
  const cartItems = useSelector((state) => state.student.cartItems);
  const dispatch = useDispatch();
  const { cartItemsRefresh } = useSelector((state) => state.student);
  // useEffect(() => {
  //   dispatch(getAvandedCart()).then((res) => {
  //
  //     res?.payload?.data && setCartItems(res?.payload?.data);
  //     res?.payload?.data && dispatch(setStudentCart(res?.payload?.data));
  //   });
  // }, [cartItemsRefresh]);

  return (
    <div className="bought__together">
      <span>Lessons</span>
      {cartItems.length > 0 &&
        cartItems.map((lesson) => (
          <CartItem
            lesson={lesson?.lesson}
            lessonPackage={lesson.package}
            location={lesson.location}
            id={lesson.id}
            key={lesson.id}
          />
        ))}
    </div>
  );
}
const CartItem = ({ lesson, lessonPackage, location, id }) => {
  const [show, setShow] = useState(true);

  return (
    show && (
      <div className="cart__courses__container">
        <LessonCartCard
          lesson={lesson}
          show={show}
          setShow={setShow}
          lessonPackage={lessonPackage}
          location={location}
          id={id}
        />
      </div>
    )
  );
};
function LessonCartCard({
  lesson,
  lessonPackage,
  location,
  id,
  show,
  setShow,
}) {
  const { cartItemsRefresh } = useSelector((state) => state.student);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onRemoveClick = () => {
    dispatch(deleteAvandedCartItem(id)).then((res) => {
      if (res.type.includes("fulfilled")) {
        dispatch(refreshStudentCart(false));
      }
    });
    setShow(false);
  };
  const onBookingClick = () => {
    dispatch(handleLessonChange({ name: "FlexibleDates", value: [] }));
    dispatch(
      selectLesson({
        lesson: lesson.id,
        lessonDetails: lesson,
        teacher: lesson.teacher,
        lessonName: lesson.title,
        location: [location],
      })
    );
    dispatch(
      setStudentState({
        name: "selectedPackage",
        value: lessonPackage,
      })
    );
    dispatch(
      setStudentState({
        name: "lessonLocation",
        value: location,
      })
    );
    dispatch(
      setStudentState({
        name: "lessonLength",
        value: lessonPackage.lessonLength,
      })
    );
    dispatch(
      handleLessonChange({
        name: "selectedLesson",
        value: lesson,
      })
    );
    navigate("/date");
  };
  const { publicSetting } = useSelector((state) => state.admin);
  const LessonPriceCalculator = (price) => {
    if (!publicSetting.studentFee) return price;
    let priceAfterStudentFee = price + (price * publicSetting.studentFee) / 100;
    return priceAfterStudentFee;
  };
  const prices = [
    { name: "online", value: LessonPriceCalculator(lesson?.onlinePrice / 100) },
    {
      name: "Teacher Place",
      value: LessonPriceCalculator(lesson?.teacherPlacePrice / 100),
    },
    {
      name: "Student Place",
      value: LessonPriceCalculator(lesson?.studentPlacePrice / 100),
    },
  ];
  // const getPricePerLocation = (location) => {
  //   let found = prices.find((price) => price.name === location);
  //   if (found) return found.value;
  // };

  // const TextTruncate = ({ text, limit }) => {
  //   if (text?.length) {
  //     const truncateText = (text, limit) => {
  //       const words = text.split(" ");
  //       if (words.length <= limit) {
  //         return text;
  //       } else {
  //         const truncatedText = words.slice(0, limit).join(" ");
  //         return `${truncatedText}...`;
  //       }
  //     };

  //     const truncatedText = truncateText(text, limit);

  //     return <div>{truncatedText}</div>;
  //   }
  // };

  return (
    <div className="horizontal__card">
      <div className="horizontal__card" onClick={() => onBookingClick()}>
        <div className="col">
          <Link
            className="image"
            // to={`/tutor/${lesson?.teacher?.id}`}
          >
            <img
              src={lesson?.teacher?.user?.profileImage?.url ?? defaultImage}
              alt=""
            />
          </Link>
          <div className="info">
            <Link
            // to={`/tutors/${lesson.id}`}
            >
              <div className="title">
                <span>{lesson?.title}</span>
              </div>
              <div className="author">
                <p>{lesson?.teacher?.user?.name}</p>
              </div>
              {/* <div className="rating">
              <span>{5}</span>
              <img src={stars} alt="" />
            </div> */}
              <div className="material">
                <span>{lesson?.location?.join(", ")}</span>
              </div>
              {prices
                .filter((loc) => loc.value !== 0)
                .map((el) => (
                  <div className="material">
                    <span>
                      {el.name} price:{" "}
                      <ExchangeRateHook price={el.value * 100} />
                    </span>
                  </div>
                ))}
            </Link>
            {/* 
          <div className="coupon">
            <p>Enter Coupon</p>
            <div className="input">
              <input type="text" onChange={(e) => {}} />
              <button onClick={() => {}}> Apply</button>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <div className="price">
        <span>
          {/* <ExchangeRateHook
            price={getPricePerLocation(lessonPackage?.location)}
          /> */}
        </span>
        <div className="actions">
          <button className="rejected" onClick={() => onRemoveClick()}>
            remove
          </button>
        </div>
      </div>
    </div>
  );
}
