import React, { useEffect, useState } from "react";
import "./categories.styles.scss";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { text } from "../../assets/HomeApp/data/Teacher-Lessons";
import { useDispatch } from "react-redux";
import { getAllLessons } from "../../ReduxToolkit/Slices/lessonsSlice";
import FindTeacher from "./FindTeacher";
import { Link } from "react-router-dom";

export default function Categories() {
  const [toggleTeacher, setToggleTeacher] = useState(true);
  const [toggleLesson, setToggleLesson] = useState(false);

  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    dispatch(getAllLessons({ filters: newFilters })) // this should be changed to a new designated route
      .then((res) => {
        let fetchedLessons = res.payload.data.lessons;
        setLocations(
          fetchedLessons.map((lesson) => lesson.teacher.user.address)
        );
      })
      .catch((err) => console.error(err));
  }, [filters]);

  return (
    false && (
      <div className="category__section">
        <div className="headers">
          <h2
            className={toggleTeacher ? "selected" : ""}
            onClick={() => {
              setToggleTeacher(true);
              setToggleLesson(false);
            }}
          >
            {text.header1}
            <KeyboardArrowUpIcon className={`${toggleLesson && "invert"}`} />
          </h2>
          <h2
            className={toggleLesson ? "selected" : ""}
            onClick={() => {
              setToggleTeacher(false);
              setToggleLesson(true);
            }}
          >
            {text.header2}
            <KeyboardArrowUpIcon className={`${toggleTeacher && "invert"}`} />
          </h2>
        </div>

        {toggleTeacher && <FindTeacher locations={locations} />}
        {toggleLesson && <TrendingLessons />}
      </div>
    )
  );
}

const LessonContainer = ({ header, array }) => {
  return (
    <div className="lesson__category">
      <Link to={`/landing/${header}`}>
        <h2 style={{ cursor: "pointer" }}>{header}</h2>
      </Link>
      <div className="details">
        {array &&
          array.map((lesson) => (
            <Link
              to={`/tutors/search/${lesson?.courseName}/${lesson?.category}`}
            >
              <div className="lesson">
                <img src={lesson.image} alt="lesson" />
                <span>{lesson.courseName}</span>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

const TrendingLessons = () => {
  return (
    <div className="privates__lessons ">
      {lessons &&
        lessons.map((lesson) => (
          <LessonContainer header={lesson.title} array={lesson.array} />
        ))}
    </div>
  );
};
