import React from "react";

import warning from "../../../assets/StudentApp/svgs/warning.svg";
import success from "../../../assets/StudentApp/svgs/success.svg";
import error from "../../../assets/StudentApp/svgs/error.svg";
import Alert from "../../Components/Alert/Alert";

export default function AlertModalDelete({
  setModal,
  handleDelete,
  type,
  assign,
  text,
  name,
}) {
  return (
    <div className="alert___modal">
      <Alert
        text={
          text
            ? text
            : assign
            ? `Are you sure you want to assign ${name} as an Admin`
            : `Are you sure you want to delete this ${type}?`
        }
        btn1Text={text ? "Yes" : assign ? "Yes, assign" : "Yes, delete"}
        btn1Color={text ? "37BFF4" : "F64E60"}
        img={warning}
        btn2
        onCancel={() => setModal(false)}
        onConfirm={() => handleDelete()}
      />
    </div>
  );
}

export function AlertModalDeleteLesson({
  setModal,
  handleDelete,
  type,
  assign,
  text,
  name,
}) {
  return (
    <div className="alert___modal">
      <Alert
        text={
          text
            ? text
            : assign
            ? `Are you sure you want to assign ${name} as an Admin`
            : `Are you sure you want to delete this ${type}?`
        }
        btn1Text={text ? "Yes" : assign ? "Yes, assign" : "Yes, delete"}
        btn1Color={text ? "37BFF4" : "F64E60"}
        img={warning}
        btn2
        onCancel={() => setModal(false)}
        onConfirm={() => handleDelete()}
      />
    </div>
  );
}
