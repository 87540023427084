import React from "react";
import chart from "../../../assets/SharedApp/images/Chart.webp";

export default function Analytic({ positive, header, bg }) {
  return (
    <div className="analytic" style={{ backgroundColor: `${bg}` }}>
      <div className="header">
        <span>{header}</span>
        {/* <div className="teacher__images">
          {images.map((img) => (
            <SmallImage img={img} />
          ))}
        </div> */}
      </div>
      <img src={chart} alt="" className="analytic__chart" />

      <div className="data">
        <span>+{positive}</span>
        {/* <p>
          {negative} active {header}
        </p> */}
      </div>
    </div>
  );
}
const SmallImage = ({ img }) => {
  return <img src={img} alt="" className="teacher__small__img" />;
};
