import React, { useEffect, useState } from "react";
import "./recommend_teacher.styles.scss";
import Rating from "@mui/material/Rating";
import Review from "../../Components/Review/Review";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getTeacherById,
  getTeacherByIdWithoutSave,
  recommendTeacher,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import ProfileDetails from "../../../TeacherApp/Components/ProfileDetails/ProfileDetails";
import { toast } from "react-toastify";

import defaultImage from "../../../assets/SharedApp/images/default.webp";

export default function RecommendTeacher() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [prevRecommends, setPrevRecommends] = useState([]);
  const [teacher, setTeacher] = useState({});
  const [showFullText, setShowFullText] = useState(false);
  const [personalInfoShow, setShowPersonalInfo] = useState(false);
  const userInitial = {
    email: "",
    name: "",
    lastName: "",
    role: "STUDENT",
    password: "",
    address: {
      street: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
    phone: "",
    // profileImage: {
    //   publicId: "",
    //   url: "",
    // },
    gender: "MALE",
    bySocial: false,
    nationality: "",
  };

  const [user, setUser] = useState(userInitial);
  const [profileImage, setProfileImage] = useState({
    publicId: "",
    url: "",
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getTeacherByIdWithoutSave(id)).then((res) => {
      setTeacher(res.payload);
      setLoading(false);
      res?.payload?.recommendations &&
        setPrevRecommends(res.payload.recommendations);
    });
  }, [id]);

  function limitWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }

  const handleRecommendClick = () => {
    if (!user.name || !user.lastName || !profileImage?.url) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setShowPersonalInfo(true);
    } else return;
  };

  const navigate = useNavigate();

  const onSubmitRecommendation = () => {
    dispatch(
      recommendTeacher({
        name: user.name,
        lastName: user.lastName,
        profileImage: profileImage,
        rating: Number(rating),
        teacherId: id,
        feedback,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled")) {
        setFeedback("");
        navigate("/");
        return toast.success("Thank you for your submission!");
      }
    });
    // submit
  };
  useEffect(() => {}, [personalInfoShow]);
  if (loading)
    return (
      <div className="big_empty_div">
        <p>Loading</p>
      </div>
    );
  if (!teacher?.id && !personalInfoShow && !loading)
    return (
      <div className="teacher_not_found">
        <h1>Teacher was not found</h1>
      </div>
    );
  return !teacher?.id ? null : personalInfoShow ? (
    <div className="inner_max">
      <div className="personalContainer_container">
        <ProfileDetails
          recommend
          setMiniUser={(e) => setUser(e)}
          miniUser={user}
          onCancelClick={() => setShowPersonalInfo(false)}
          className={"custom_recommend_profile"}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
        />
      </div>
    </div>
  ) : (
    <div className="recommend_teacher">
      <div className="inner_max">
        <div className="page_max">
          <header>
            <div className="page_intro">
              <div className="col1">
                <div className="image_container">
                  <img
                    src={profileImage?.url}
                    alt="teacher"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = defaultImage;
                    }}
                  />
                </div>
                <Rating
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                />
              </div>
              <div className="col2">
                <div className="teacher_title">
                  <h1>
                    Hello, it's{" "}
                    <span className="colored">
                      {teacher?.user?.name} {teacher?.user?.lastName}
                    </span>
                  </h1>

                  <p>
                    {showFullText
                      ? teacher?.description
                      : limitWords(teacher?.description, 80)}
                  </p>
                  {!showFullText ? (
                    <span
                      className="showMore"
                      onClick={() => setShowFullText(true)}
                    >
                      View more <ExpandMoreIcon />
                    </span>
                  ) : (
                    <span
                      className="showMore"
                      onClick={() => setShowFullText(false)}
                    >
                      View less
                      <ExpandLessIcon />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </header>
          <article>
            <h1>Write a recommendation</h1>
            <h2>
              for {teacher.user.name} to help instill trust with their students
            </h2>
          </article>
          <main>
            <div className="col1">
              <div className="text">
                <p>
                  Educify is based on confidence and reputation. By writing this
                  recommendation, you are recommending Bow Lake to other members
                  of the Educify community. Provide only recommendations for
                  people you know well.
                </p>
              </div>
              <span>Tips for a helpful recommendation:</span>
              <div className="list">
                <ul>
                  <li>How do you know each other?</li>
                  <li>What style of teacher is this person?</li>
                  <li>Tell an experience you've lived together.</li>
                  <li>
                    Why do you recommend this person to the other Educify
                    members?
                  </li>
                </ul>
              </div>
            </div>
            <div className="col2">
              <div className="text">
                <p>
                  Explain why you think Bow Lake is trustworthy, and would make
                  a great teacher:
                </p>
              </div>
              <div className="recommend_area">
                <textarea
                  placeholder="Your recommendation..."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  onClick={() => handleRecommendClick()}
                />
              </div>
            </div>
          </main>
          <div className="recommend_btn_container">
            <button
              className="btn__primary"
              onClick={() => onSubmitRecommendation()}
            >
              Send
            </button>
          </div>
          {!!prevRecommends.length && (
            <section>
              <div className="latest_recommendation">
                <h1>The latest recommendations</h1>
                <div className="teacher_reviews">
                  {prevRecommends &&
                    prevRecommends.map((el, i) => (
                      <Review
                        key={i}
                        name={el.name}
                        lastName={el.lastName}
                        description={el.feedback}
                        image={el.profileImage.url}
                        rate={el.rating}
                        className={"teacher_recommend_review"}
                      />
                    ))}
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}
