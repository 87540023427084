import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import arrowLeft from "../../../assets/StudentApp/svgs/Arrow-left.svg";
import arrowDark from "../../../assets/StudentApp/svgs/Arrow-right-dark.svg";
import {
  registerTeacher,
  setTeacherId,
  setTeacherLessons,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import { createLesson } from "../../../ReduxToolkit/Slices/teacherSlice";
import { updateLesson } from "../../../ReduxToolkit/Slices/lessonsSlice";
import BgCheck from "./BgCheck";
import LessonPrice from "./LessonPrice";
import Lesson from "./Lesson";
import {
  convertTimezoneTwentyFour,
  convertTimezoneDateTime,
  convertTimezoneWithDate,
} from "../../../SharedApp/Components/Timezone";
import moment from "moment";
import { validateYoutubeUrl } from "../../../hooks";
import { login, setUser } from "../../../ReduxToolkit/Slices/userSlice";

export default function LessonInput({
  handleLessonChange,
  lessons,
  index,
  selected,
  setIndex,
  loc,
  customLinks,
  setRefresh,
  refresh,
}) {
  const dispatch = useDispatch();
  const { teacher } = useSelector((state) => state.teacher);
  const teacherId = teacher.id;
  const { lessonToSetup } = useSelector((state) => state.lessons);
  const { publicSetting } = useSelector((state) => state.admin);

  const { user } = useSelector((state) => state.user);
  const lesson = lessonToSetup;
  const isNotEmpty = (obj) => {
    if (
      obj?.location?.includes("online") &&
      (!obj?.onlinePrice || obj.onlinePrice < 1)
    ) {
      return "Online Price";
    }
    if (
      obj?.location?.includes("studentPlace") &&
      (!obj?.studentPlacePrice || obj.studentPlacePrice < 1)
    ) {
      return "Student Place Price";
    }
    if (
      obj?.location?.includes("studentPlace") &&
      (!obj.travelCharge || !obj.travelDistance)
    ) {
      return "Travel Charge/ travel distance";
    }
    if (
      obj?.location?.includes("teacherPlace") &&
      (!obj?.teacherPlacePrice || obj.teacherPlacePrice < 1)
    ) {
      return "Teacher Place Price";
    }
    if (
      lesson.location.includes("teacherPlace") ||
      lesson.location.includes("studentPlace")
    ) {
      if (!teacher?.background?.image?.url) {
        return "background check";
      }
    }
    if (
      lesson.location.includes("teacherPlace") &&
      !teacher?.user?.geoLocation?.address
    ) {
      return "Studio location ";
    }
    let newObj = {
      categoryIds: obj.categoryIds,
      subjectIds: obj.subjectIds,
      description: obj.description,
      images: obj.images,
      videos: obj.videos,
      level: obj.level,
      maxStudents: obj.maxStudents,
      rating: obj.rating,
      title: obj.title,
      location: obj.location,
    };

    return Object.keys(newObj).every((val) => {
      if ((obj.isGroup && val === "videos") || val === "images") {
        if (newObj?.val?.length < 1) {
          return val;
        } else if (
          !newObj[val][0]?.publicId ||
          newObj[val][0]?.publicId === "" ||
          !newObj[val][0]?.url ||
          newObj[val][0]?.url === ""
        ) {
          return val;
        } else return undefined;
      }
      if (Array.isArray(newObj[val])) {
        if (newObj[val].length > 0 && newObj[val][0] !== "") {
          return undefined;
        } else {
          return val;
        }
      } else {
        if (newObj[val] && newObj[val] !== "") {
          return undefined;
        } else {
          return val;
        }
      }
    });
  };

  function validateLesson(obj) {
    // Check online price condition
    if (
      obj.location.includes("online") &&
      (!obj.onlinePrice || obj.onlinePrice < 1)
    ) {
      return "Online Price must be greater than 0.";
    }

    // Check student place price condition
    if (
      obj.location.includes("studentPlace") &&
      (!obj.studentPlacePrice || obj.studentPlacePrice < 1)
    ) {
      return "Student Place Price must be greater than 0.";
    }

    // Check travel charge and distance for lessons at the student's place
    if (
      obj.location.includes("studentPlace") &&
      (!obj.travelCharge || !obj.travelDistance)
    ) {
      return "Travel Charge and Travel Distance must be provided for lessons at the student's place.";
    }

    // Check teacher place price condition
    if (
      obj.location.includes("teacherPlace") &&
      (!obj.teacherPlacePrice || obj.teacherPlacePrice < 1)
    ) {
      return "Teacher Place Price must be greater than 0.";
    }

    // Check for teacher's background image URL
    if (
      (obj.location.includes("teacherPlace") ||
        obj.location.includes("studentPlace")) &&
      !teacher?.background?.image?.url
    ) {
      return "Background check image URL must be provided.";
    }

    // Validate images and videos are either not empty or have valid entries
    if (obj.isGroup && (!obj.images || obj.images.length === 0)) {
      return "Images are required for group lessons.";
    }

    if (obj.isGroup && (!obj.videos || obj.videos.length === 0)) {
      return "Videos are required for group lessons.";
    }

    // Check if description is required for group lessons
    if (obj.isGroup && !obj.description) {
      return "Description is required for group lessons.";
    }

    // Check for required fields being populated
    const requiredFields = [
      "categoryIds",
      "subjectIds",
      "level",
      "maxStudents",
      // "rating",
      "title",
      "location",
    ];
    for (let field of requiredFields) {
      if (
        !obj[field] ||
        (Array.isArray(obj[field]) && obj[field].length === 0) ||
        obj[field] === ""
      ) {
        return `${field} is required and cannot be empty.`;
      }
    }

    // If all checks pass
    return null;
  }

  const { timezone } = useSelector((state) => state.user);
  const { teacherToRegister } = useSelector((state) => state.teacher);

  const registerTeacherBeforeLesson = async (newLesson) => {
    const teacherRg = await dispatch(registerTeacher(teacherToRegister)).then(
      (res) => {
        if (res.type === "registerTeacher/rejected") {
          return toast.error("Error registering teacher");
        }
        dispatch(setTeacherId(res.payload.id));
        dispatch(setUser(res.payload.user));
        if (!user.bySocial) {
          dispatch(
            login({
              email: user.email,
              password: user.password,
            })
          ).then((res) => {
            !user.bySocial &&
              firebase
                .auth()
                .createUserWithEmailAndPassword(user.email, user.password);
            // if (teachCourses) {
            //   toast.success(
            //     "Your profile was successfully! All you have to do now is add your courses"
            //   );
            //   return navigate("/teacher/signup/course", { replace: true });
            // }

            // toast.success(
            //   "Your profile was successfully! All you have to do now is add your availabilities and lessons"
            // );
            // return navigate("/teacher/signup/availability", {
            //   replace: true,
            // });
          });
        }
        dispatch(createLesson(newLesson)).then((res) => {
          if (res.type === "createLesson/fulfilled") {
            // dispatch(setTeacherLessons(newLesson));
            setRefresh(!refresh);
            toast.success("Your lesson was successfully created");
            return setIndex(undefined);
            // customLinks
            //   ? navigate("/teachers/subjects/2")
            //   : navigate("/teacher/signup/completed");
          } else {
            toast.error("Error creating lesson");
            // toast.error(res?.payload?.response?.data?.message);
            // toast.error(res?.payload?.response?.data?.error);
          }
        });
      }
    );
    return teacherRg;
  };
  const handleCreateLesson = async () => {
    let newLesson = {
      teacherId: teacherId,
      travelDistance: lesson.travelDistance ? lesson.travelDistance : 0,
      travelCharge: lesson.travelCharge ? lesson.travelCharge : 0,
      title: lesson.title,
      description: lesson.description,
      fromAge: lesson?.fromAge,
      toAge: lesson?.toAge,
      onlinePrice: lesson.onlinePrice ? lesson.onlinePrice : 0,
      teacherPlacePrice: lesson.teacherPlacePrice
        ? lesson.teacherPlacePrice
        : 0,
      studentPlacePrice: lesson.studentPlacePrice
        ? lesson.studentPlacePrice
        : 0,
      location: lesson.location,
      level: lesson.level,
      images: lesson.images,
      videos: lesson.videos,
      subjectIds: lesson.subjectIds,
      categoryIds: lesson.categoryIds,
      headLine: lesson.headLine,
      summary: lesson.summary,
      isGroup: false,
      timeCommitment: lesson.timeCommitment,
      materialsNeeded: lesson.materialsNeeded,
      learningGoals: lesson.learningGoals,
      maxStudents: lesson.maxStudents,
      discount: Number(lesson.discount),
      discountEndDate: moment(lesson.discountEndDate).format(),
      groupLessonAppointment: lesson.groupLessonAppointment.map((app) => {
        return {
          ...app,
          startTime: convertTimezoneTwentyFour(app.startTime, timezone, "UTC"),
          endTime: convertTimezoneTwentyFour(app.endTime, timezone, "UTC"),
        };
      }),
    };
    newLesson.groupLessonTime && delete newLesson.groupLessonTime;

    if (!lesson.isGroup) {
      newLesson.groupLessonAppointment &&
        delete newLesson.groupLessonAppointment;
      newLesson.summary && delete newLesson.summary;
      newLesson.learningGoals && delete newLesson.learningGoals;
      newLesson.materialsNeeded && delete newLesson.materialsNeeded;
      newLesson.headLine && delete newLesson.headLine;
    }
    delete newLesson.price;
    if (!newLesson.location.includes("studentPlace")) {
      newLesson.studentPlacePrice && delete newLesson.studentPlacePrice;
      newLesson.travelCharge && delete newLesson.travelCharge;
      newLesson.travelDistance && delete newLesson.travelDistance;
    }
    if (!newLesson.location.includes("online")) delete newLesson.onlinePrice;
    if (!newLesson.location.includes("teacherPlace"))
      newLesson.teacherPlacePrice && delete newLesson.teacherPlacePrice;

    if (
      !location.pathname.includes("teachers") &&
      teacherToRegister?.user?.email
    ) {
      await registerTeacherBeforeLesson(newLesson);
    } else
      dispatch(createLesson(newLesson)).then((res) => {
        if (res.type === "createLesson/fulfilled") {
          // dispatch(setTeacherLessons(newLesson));
          setRefresh(!refresh);
          toast.success("Your lesson was successfully created");
          return setIndex(undefined);
          // customLinks
          //   ? navigate("/teachers/subjects/2")
          //   : navigate("/teacher/signup/completed");
        } else {
          toast.error("Error creating lesson");
          // toast.error(res?.payload?.response?.data?.message);
          // toast.error(res?.payload?.response?.data?.error);
        }
      });
  };
  const location = useLocation();
  function hasMoreThan40Words(inputString) {
    // Remove extra white spaces and split the string into words using space as a delimiter
    const words = inputString ? inputString.trim().split(/\s+/) : 0;

    // Check if the number of words is greater than 40
    return words.length > 40;
  }
  const createTeacherLesson = () => {
    if (!lesson.title || lesson.title === "Lesson title")
      return toast.warning("Missing title");
    // if (lesson.isGroup && lesson.description.split(" ").length < 40)
    //   return toast.warning("Lesson description is less than 40 words");
    if (lesson.isGroup && lesson.maxStudents < 2)
      return toast.warning(
        "Maximum students for group lesson should be at least 2"
      );
    if (
      (lesson.isGroup && !lesson.fromAge) ||
      (lesson.isGroup && !lesson.toAge)
    )
      return toast.warning("Please enter both minimum and maximum age");
    if (!lesson.images.length && lesson?.isGroup)
      return toast.warning("Missing lesson images");
    if (!lesson.level || lesson.level === "")
      return toast.warning("Missing lesson level");
    if (!lesson.location.length)
      return toast.warning("Missing lesson location");
    if (!lesson?.categoryIds?.length)
      return toast.warning("Missing lesson subject");
    if (!lesson?.subjectIds?.length)
      return toast.warning("Missing lesson subject");
    if (!lesson.videos.length && lesson?.isGroup)
      return toast.warning("Missing lesson video");
    if (lesson.isGroup == true && lesson.videos[0].url == "")
      return toast.warning("Missing lesson video url");
    if (
      lesson?.isGroup &&
      lesson.videos.length &&
      lesson.videos[0].publicId === lesson.videos[0].url &&
      !validateYoutubeUrl(lesson.videos[0].url)
    )
      return toast.warning("invalid youtube url");
    if (lesson.location.includes("online") && !lesson.onlinePrice)
      return toast.warning("missing online price");
    if (lesson.location.includes("teacherPlace") && !lesson.teacherPlacePrice)
      return toast.warning("missing teacher place price");
    if (lesson.location.includes("studentPlace") && !lesson.studentPlacePrice)
      return toast.warning("missing student place price");
    if (isNotEmpty(lesson)) {
      return toast.error(`Missing field: ${isNotEmpty(lesson)}`);
    }
    if (!hasMoreThan40Words(lesson.description) && lesson?.isGroup) {
      return toast.error("Lesson description should be at least 40 words");
    }
    if (lesson.isGroup) {
      if (!lesson?.groupLessonAppointment?.length)
        return toast.warning("Missing lesson Appointments");
      if (!lesson?.summary) return toast.warning("Missing lesson summary");
      if (!lesson?.timeCommitment)
        return toast.warning("Missing lesson time commitment");
      if (!lesson?.headLine) return toast.warning("Missing lesson headLine");
      if (!lesson?.learningGoals?.length)
        return toast.warning("Missing lesson learning goals");
      if (!lesson?.materialsNeeded?.length)
        return toast.warning("Missing lesson materials needed");
      const firstDaysLength = lesson?.groupLessonAppointment[0]?.days?.length;
      // Check if all days arrays have the same length
      const allDaysHaveSameLength = lesson.groupLessonAppointment.every(
        (appointment) => appointment.days.length === firstDaysLength
      );
      if (!allDaysHaveSameLength) {
        return toast.error(
          "You should have same number of days for all classes"
        );
      }
      if (
        !teacher?.user?.geoLocation?.address &&
        lesson.location.includes("teacherPlace")
      ) {
        toast.warning(
          "Please update your location below before creating your lesson"
        );
      }
    }

    const subjectIds = !lesson?.subjects?.length
      ? undefined
      : lesson.subjects[0].id
      ? [lesson.subjects[0].id]
      : [lesson.subjects[0].value];
    const categoryIds = !lesson?.categories?.length
      ? undefined
      : lesson.categories[0].id
      ? [lesson.categories[0].id]
      : [lesson.categories[0].value];

    if (!subjectIds || !categoryIds) {
      return toast.error("Please enter both categories and subjects");
    }
    if (lesson.id) {
      if (!location.pathname.includes("teachers")) {
        return setIndex(undefined);
      } else
        dispatch(
          updateLesson({
            lesson: {
              title: lesson.title,
              description: lesson.description,
              teacherId: teacherId,
              fromAge: lesson?.fromAge,
              toAge: lesson?.toAge,
              onlinePrice: lesson.onlinePrice ? lesson.onlinePrice : 0,
              teacherPlacePrice: lesson.teacherPlacePrice
                ? lesson.teacherPlacePrice
                : 0,
              studentPlacePrice: lesson.studentPlacePrice
                ? lesson.studentPlacePrice
                : 0,
              travelDistance: lesson.travelDistance,
              travelCharge: lesson.travelCharge,
              location: lesson.location,
              level: lesson.level,
              maxStudents: lesson.maxStudents ? lesson.maxStudents : 1,
              images: lesson.images,
              videos: lesson.videos,
              headLine: lesson.headLine,
              summary: lesson.summary,
              discount: Number(lesson.discount),
              discountEndDate: moment(lesson.discountEndDate).format(),
              timeCommitment: lesson.timeCommitment,
              materialsNeeded: lesson.materialsNeeded,
              learningGoals: lesson.learningGoals,
              maxStudents: lesson.maxStudents,
              groupLessonAppointment: lesson.groupLessonAppointment.map(
                (app) => {
                  return {
                    ...app,
                    startTime: convertTimezoneTwentyFour(
                      app.startTime,
                      timezone,
                      "UTC"
                    ),
                    endTime: convertTimezoneTwentyFour(
                      app.endTime,
                      timezone,
                      "UTC"
                    ),
                  };
                }
              ),
              subjectIds: !lesson?.subjects?.length
                ? [""]
                : lesson.subjects[0].id
                ? [lesson.subjects[0].id]
                : [lesson.subjects[0].value],
              isGroup: false,
              categoryIds: !lesson?.categories?.length
                ? [""]
                : lesson.categories[0].id
                ? [lesson.categories[0].id]
                : [lesson.categories[0].value],
            },
            id: lesson.id,
          })
        )
          .then((res) => {
            if (res.type.includes("fulfilled")) {
              setRefresh(!refresh);
              toast.success("Your lesson was successfully updated");
              return setIndex(undefined);
            } else {
              return toast.error("Error updating lesson");
            }
            // customLinks
            //   ? navigate("/teachers/subjects/2")
            //   : navigate("/teacher/signup/submit");
          })
          .catch((err) => console.log("err", err));
    } else handleCreateLesson();
  };

  let requireCheck =
    lesson?.location?.includes("teacherPlace") ||
    lesson?.location?.includes("studentPlace");

  return (
    <div className="LessonInput_Container">
      <button
        onClick={() => {
          setIndex(undefined);
        }}
        className="reset_lesson_btn"
      >
        Back
      </button>
      <div className="lesson__config">
        <Lesson selected={selected} index={index} />
        {lesson.location !== null && (
          <LessonPrice
            lesson={lessons.length ? lessons[index] : { price: 0 }}
            handleLessonChange={handleLessonChange}
          />
        )}
        {requireCheck && <BgCheck givenTeacher />}
        <div className="setup__lessons__btns" style={{}}>
          {!loc ? (
            <Link to={"/teacher/signup/availability"}>
              <button className="prev">
                <img src={arrowLeft} alt="" />
                Previous
              </button>
            </Link>
          ) : (
            <div />
          )}
          {/* <Link to={"/teacher/signup/submit"}> */}
          <button
            onClick={() => createTeacherLesson(teacherId)}
            className={
              (validateLesson(lesson) === null && lesson.isGroup == false) ||
              (lesson.isGroup == true &&
                hasMoreThan40Words(lesson.description) &&
                lesson?.subjectIds?.length &&
                lesson?.categoryIds?.length &&
                lesson.images.length &&
                lesson?.videos?.length &&
                ((lesson.location.includes("teacherPlace") &&
                  teacher?.user?.geoLocation?.address &&
                  lesson?.teacherPlacePrice > 0) ||
                  (lesson.location.includes("online") &&
                    lesson?.onlinePrice > 0)) &&
                lesson?.videos[0]?.url &&
                lesson?.groupLessonAppointment?.length &&
                lesson?.learningGoals?.length &&
                lesson?.timeCommitment &&
                lesson?.headLine &&
                lesson?.maxStudents >= 2 &&
                lesson?.summary)
                ? "blue"
                : "locked"
            }
          >
            Save Lesson
            <img src={arrowDark} alt="" />
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
