import React from "react";
import "./Reviews.styles.scss";
import img from "../../../assets/SharedApp/images/tutor.webp";
import Review from "../../Components/Review/Review";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import Rating from "@mui/material/Rating";
import { ratingList } from "../../../assets/SharedApp/data/Tutors";
// import { reviews } from "../../../assets/SharedApp/data/Tutors";
import Pagination from "../../../Components/Pagination/Pagination";

export default function Reviews({ reviews, rating }) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#FFC142" : "#308fe8",
    },
  }));

  return (
    <>
      {reviews.length ? (
        <div className="tutor__reviews" id="reviews">
          <div className="header">
            <h4>Rating & Reviews</h4>
            {/* <span>View All</span> */}
          </div>
          <div className="reviews__container">
            <div className="side__panel">
              <div className="reviews">
                <div className="top">
                  <span className="rate">{rating.toFixed(2)}</span>
                  <div className="review__nb">
                    <Rating name="read-only" value={rating} readOnly />{" "}
                    {/* <p>500+ Reviews</p> */}
                  </div>
                </div>
                {/* <div className="rows">
              {ratingList.map((data) => (
                <div className="row">
                  <div className="nb__stars">
                    <span>{data.stars} Stars</span>
                    <p>{data.value}%</p>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={data.value}
                  />
                </div>
              ))}
            </div> */}
              </div>
              {/* <div className="start">
            <h1>Start Learning Today</h1>
            <span>
              Over 500+ already <br /> enrolled
            </span>
            <button>Try For Free</button>
          </div> */}
            </div>
            {
              <div className="main__reviews">
                {reviews &&
                  reviews.map((review, i) => (
                    <Review
                      image={review.student?.user?.profileImage?.url}
                      name={review.student?.user?.name}
                      lastName={review.student?.user?.lastName}
                      description={review.comment}
                      position={review.authorJob}
                      rate={review.rating}
                      key={i}
                    />
                  ))}
              </div>
            }
          </div>
          <div className="all__reviews">
            <button className="all__reviews">View All Reviews</button>
          </div>
          <Pagination />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
