import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Note from "../Note/Note";
import { toast } from "react-toastify";
import { StudentFeeCalculator, TeacherFeeCalculator } from "../../../hooks";
import { useEffect } from "react";
import { handleSetupLessonChange } from "../../../ReduxToolkit/Slices/lessonsSlice";

export default function LessonPrice() {
  const { publicSetting } = useSelector((state) => state.admin);
  const { lessonToSetup } = useSelector((state) => state.lessons);
  const lesson = lessonToSetup;
  const [currentLocation, setCurrentLocation] = useState(
    `${lesson.location[0]}Price`
  );
  const dispatch = useDispatch();

  const handleLessonChange = (name, value) => {
    dispatch(handleSetupLessonChange({ name, value }));
  };
  const handlePriceChange = (name, value) => {
    if (
      publicSetting.travelChargeLimit &&
      value > publicSetting.travelChargeLimit &&
      name === "travelCharge"
    ) {
      return toast.error(
        `Travel Charge Per KM Should Be Less Then ${publicSetting.travelChargeLimit} USD`
      );
    } else if (
      publicSetting.priceLimitLessons &&
      value > publicSetting.priceLimitLessons / 2
    ) {
      return toast.error(
        `60 mins lessons should be less then ${publicSetting.priceLimitLessons} USD`
      );
    } else {
      handleLessonChange(name, parseInt(Number(value) * 100));
    }
  };

  const studentPayment = TeacherFeeCalculator(lesson[currentLocation]);
  const usdPrice = (price) => price / 100;
  // const [locations, setLocation] = useState();

  useEffect(() => {
    setCurrentLocation(`${lesson.location[0]}Price`);
  }, [lesson?.location[0]]);

  return (
    <>
      {lesson.location !== null && lesson?.location?.length ? (
        <div className="lesson__price__container">
          <Note
            title={"We need your attention!"}
            p1="We put a limit for market purposes"
            p2={
              "Please choose a reasonable rate that can command attention in the market."
            }
            p3={
              "We have put an upper limit on the rates to ensure people don't just put arbitrary rates on their profile."
            }
          />
          <h2 className="loc_h2">Location Prices</h2>
          <div className="location_picker">
            {lesson.location &&
              lesson.location.map((el, i) => (
                <span
                  key={i}
                  className={`pointer text-center ${
                    currentLocation === `${el}Price` ? "current_location" : ""
                  }`}
                  onClick={() => setCurrentLocation(`${el}Price`)}
                >
                  {el === "studentPlace"
                    ? "Student place price"
                    : el === "teacherPlace"
                    ? "Teacher place price"
                    : "Online price"}
                </span>
              ))}
          </div>
          {currentLocation.length && !lesson.isGroup ? (
            <div className="price__picker">
              <h3>Lesson Price</h3>
              <div className="prices_rows_containers">
                <div className="row col_row">
                  <span className="duration">For 60mins</span>
                  <div className="input">
                    <div className="number__input">
                      <span>$</span>
                      <input
                        type="text"
                        placeholder="Amount"
                        value={
                          lesson[currentLocation]
                            ? usdPrice(lesson[currentLocation]) * 2
                            : 0
                        }
                        onChange={(e) =>
                          handlePriceChange(currentLocation, e.target.value / 2)
                        }
                      />
                    </div>
                  </div>
                  <div className="student__price">
                    <span className="std__price">What you will get</span>
                    <p className="price">
                      $
                      {studentPayment ? Math.ceil(studentPayment / 100) * 2 : 0}
                    </p>
                  </div>
                </div>
                <div className="row col_row">
                  <div className="row_header">
                    <span className="duration">For 30mins</span>
                    <div className="input">
                      <div className="number__input">
                        <span>$</span>
                        <input
                          type="number"
                          placeholder="Amount"
                          step={0}
                          value={
                            lesson[currentLocation]
                              ? usdPrice(lesson[currentLocation])
                              : 0
                          }
                          readOnly
                        />
                      </div>
                      {/* <p>lMaximum Price Limit $1</p> */}
                    </div>
                  </div>
                  <div className="student__price">
                    <span className="std__price">What you will get</span>
                    <p className="price">
                      ${studentPayment ? Math.ceil(studentPayment / 100) : 0}
                    </p>
                  </div>
                </div>
                <div className="row col_row">
                  <span className="duration">For 45mins</span>
                  <div className="input">
                    <div className="number__input">
                      <span>$</span>
                      <input
                        type="text"
                        placeholder="Amount"
                        value={
                          lesson[currentLocation]
                            ? usdPrice(lesson[currentLocation]) * 1.5
                            : 0
                        }
                        readOnly
                      />
                    </div>
                    {/* <p>lMaximum Price Limit $1</p> */}
                  </div>
                  <div className="student__price">
                    <span className="std__price">What you will get</span>
                    <p className="price">
                      $
                      {studentPayment
                        ? Math.ceil(studentPayment / 100) * 1.5
                        : 0}
                    </p>
                  </div>
                </div>

                <div className="row col_row">
                  <span className="duration">For 90mins</span>
                  <div className="input">
                    <div className="number__input">
                      <span>$</span>
                      <input
                        type="text"
                        placeholder="Amount"
                        value={
                          lesson[currentLocation]
                            ? usdPrice(lesson[currentLocation]) * 3
                            : 0
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="student__price">
                    <span className="std__price">What you will get</span>
                    <p className="price">
                      $
                      {studentPayment ? Math.ceil(studentPayment / 100) * 3 : 0}
                    </p>
                  </div>
                </div>
              </div>

              {currentLocation === "studentPlacePrice" && (
                <>
                  <div className="row col_row">
                    <span className="duration" style={{ maxWidth: "100px" }}>
                      Max distance
                    </span>
                    <div className="input">
                      <div className="number__input">
                        <span>Km</span>
                        <input
                          type="number"
                          placeholder="Amount"
                          value={
                            lesson?.travelDistance ? lesson?.travelDistance : 0
                          }
                          onChange={(e) =>
                            handleLessonChange(
                              "travelDistance",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row col_row">
                    <span className="duration">Price Per KM</span>
                    <div className="input">
                      <div className="number__input">
                        <span>$</span>
                        <input
                          type="number"
                          placeholder="Amount"
                          value={
                            lesson?.travelCharge ? lesson.travelCharge / 100 : 0
                          }
                          onChange={(e) =>
                            handlePriceChange("travelCharge", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="student__price">
                      <span className="std__price">What you will get</span>
                      <p className="price">
                        $
                        {Math.ceil(lesson.travelDistance * lesson.travelCharge)}
                      </p>
                    </div> */}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="price__picker">
              <h3>Lesson Price</h3>
              <div className="row ">
                <span className="duration">For one week</span>
                <div className="input">
                  <div className="number__input">
                    <span>$</span>
                    <input
                      type="number"
                      placeholder="Amount"
                      step={0}
                      value={
                        lesson[currentLocation]
                          ? usdPrice(lesson[currentLocation])
                          : 0
                      }
                      onChange={(e) =>
                        handlePriceChange(
                          currentLocation,
                          e.target.valueAsNumber.toFixed(2)
                        )
                      }
                    />
                  </div>
                  {/* <p>lMaximum Price Limit $1</p> */}
                </div>
                <div className="student__price">
                  <span className="std__price">What you will get</span>
                  <p className="price">
                    ${studentPayment ? Math.ceil(studentPayment / 100) : 0}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
        // <h2>Pick a lesson location first</h2>
      )}
    </>
  );
}
