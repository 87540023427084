import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";

import hide from "../../../assets/TeacherApp/assets/svgs/hide.svg";
import viewImage from "../../../assets/AdminApp/svgs/view.svg";
import del from "../../../assets/SharedApp/svgs/Button Trash.svg";
import { useDispatch } from "react-redux";

import {
  ImageComponent,
  Code,
  Video,
} from "../../Components/Courses/EditorPageRow/EditorPageRowComponents";

export default function HomeworkDisplay({
  homework,
  type,
  handleHomeWork,
  viewOnly,
  // resetHomeWork,
}) {
  const [view, setView] = useState(true);
  const dispatch = useDispatch();
  const sanitizedContent = (text) => DOMPurify.sanitize(text);
  const onDelete = () => {
    type === "image"
      ? handleHomeWork("image", { url: "", publicId: "", fileName: "" })
      : type === "image"
      ? handleHomeWork("video", { url: "", publicId: "", fileName: "" })
      : handleHomeWork("note", "");
  };
  const ref = useRef(null);
  useEffect(() => {
    // Scroll to the component when it is mounted
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return (
    <div className="container_container">
      <div className="editor__row__container">
        {view && (
          <div>
            {type === "video" ? (
              <Video
                item={homework.video.url}
                del={del}
                onDelete={onDelete}
                viewOnly={viewOnly}
              />
            ) : type === "image" ? (
              <ImageComponent
                image={homework.image.url}
                viewOnly={viewOnly}
                del={del}
                onDelete={onDelete}
              />
            ) : type === "code" ? (
              <Code code={newCode(homework.codeExample)} />
            ) : type === "description" ? (
              <div className="edit__text">
                <h4 className="flex_row_center">
                  Homework note
                  {!viewOnly && (
                    <img
                      src={del}
                      alt=""
                      onClick={() => onDelete()}
                      className="delete_image"
                    />
                  )}
                </h4>

                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(homework.note),
                  }}
                />
              </div>
            ) : type === "file" ? (
              // <a href={lesson.videos[0].url} target="blank">
              //   link
              // </a>
              <h1>this is a file</h1>
            ) : null}
          </div>
        )}
        <div className="scroll_to" ref={ref} />
      </div>
    </div>
  );
}
