import React from "react";
import moment from "moment";

import "./CompletedLessons.styles.scss";
import defaultImage from "../../../assets/SharedApp/images/default.webp";
import dots from "../../../assets/AdminApp/svgs/Dots.svg";
import right from "../../../assets/SharedApp/svgs/right.svg";
import { useNavigate } from "react-router-dom";

export default function CompletedLessons({ prevBookings }) {
  const navigate = useNavigate();
  const onBookingClick = (id) => {
    navigate(`/admin/booking/${id}`);
  };
  return (
    <div className="teacher__completed__lessons">
      <div className="header">
        <h1>Completed Lessons</h1>
        <img src={dots} alt="" />
      </div>
      {/* replace here */}
      {prevBookings &&
        prevBookings.map((el) => (
          <div className="student__row">
            <div className="std__col">
              <img
                src={el?.booking?.student?.user?.profileImage?.url}
                alt=""
                className="std__img pointer"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = defaultImage;
                }}
                onClick={() =>
                  navigate(`/admin/students/${el?.booking?.student?.user?.id}/`)
                }
              />
              <div className="name">
                <span>{el?.title}</span>
                <p>
                  {`${el?.booking?.student?.user?.name}
                   ${el?.booking?.student?.user?.name}`}
                </p>
                <p>{moment(el?.booking?.date).format("DD/MM/YYYY")}</p>
              </div>
            </div>
            <img
              src={right}
              alt=""
              onClick={() => onBookingClick(el.booking.id)}
            />
          </div>
        ))}

      {/* end replace */}
    </div>
  );
}

export function StudentCompletedLessons({ prevBookings }) {
  const navigate = useNavigate();
  const onBookingClick = (id) => {
    navigate(`/admin/booking/${id}`);
  };
  return (
    <div className="teacher__completed__lessons">
      <div className="header">
        <h1>Completed Lessons</h1>
        <img src={dots} alt="" />
      </div>
      {/* replace here */}
      {prevBookings &&
        prevBookings.map((el) => (
          <div className="student__row">
            <div className="std__col">
              <img
                src={el?.teacher?.user?.profileImage?.url}
                alt=""
                className="std__img"
              />
              <div className="name">
                <span>{el?.title}</span>
                <p>{moment(el?.date).format("DD/MM/YYYY")}</p>
              </div>
            </div>
          </div>
        ))}
      {/* end replace */}
    </div>
  );
}
