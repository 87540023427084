import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import {
  CreatePromoCodes,
  getPromoCodeById,
  updatePromoCode,
} from "../../../ReduxToolkit/Slices/adminSlice";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getTeacherCouponBYID,
  updateTeacherCouponBYID,
} from "../../../ReduxToolkit/Slices/teacherSlice";
export default function CreatePromo({ teacher }) {
  const [data, setData] = useState([
    {
      name: "Title",
      type: "text",
      objName: "title",
    },
    {
      name: "Percentage",
      type: "number",
      objName: "percentage",
    },
    {
      name: "Number of users",
      type: "number",
      objName: "numOfUsers",
    },
    {
      name: "Max usage",
      type: "number",
      objName: "maxUsage",
    },
    {
      name: "Type",
      type: "select",
      objName: "type",
      options: ["GENERAL", "REGISTRATION", "INVITATION"],
    },
    {
      name: "Status",
      type: "select",
      objName: "status",
      options: ["ACTIVE", "INACTIVE"],
    },
    {
      name: "Start Date",
      type: "date",
      objName: "startDate",
    },
    {
      name: "End Date",
      type: "date",
      objName: "endDate",
    },
  ]);
  const [promo, setPromo] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    teacher
      ? dispatch(getTeacherCouponBYID(id)).then((res) => setPromo(res.payload))
      : dispatch(getPromoCodeById(id)).then((res) => setPromo(res.payload));

    teacher && setData(data.filter((item) => item.objName !== "type"));
  }, []);

  const handleUpdate = () => {
    if (promo.percentage >= 100) {
      toast.warning("Invalid Percentage");
    } else if (
      promo.title &&
      promo.percentage &&
      promo.numOfUsers &&
      promo.status &&
      promo.startDate &&
      promo.endDate &&
      promo.details &&
      promo.terms
    ) {
      teacher
        ? dispatch(
            updateTeacherCouponBYID({
              coupon: promo,
              id,
            })
          ).then((res) => {
            if (res.type === "updateTeacherCouponBYID/fulfilled") {
              toast.success("Coupon updated");
              return navigate(-1);
            } else {
              toast.error("Error updating coupon");
            }
          })
        : dispatch(updatePromoCode(promo)).then((res) => {
            if (res.type === "updatePromoCode/fulfilled") {
              toast.success("Promo code updated");
              return navigate(-1);
            } else {
              toast.error("Error updating promo code");
            }
          });
    } else {
      toast.warning("Missing Fields");
    }
  };
  return (
    <div className="create__promo">
      {teacher ? <h1>Setup Your Coupon</h1> : <h1>Setup Your Promo Code</h1>}
      <div className="promo__inputs__container">
        {data.map((el) => (
          <PromoInput
            text={el.name}
            type={el.type}
            setPromo={setPromo}
            promo={promo}
            name={el.objName}
            options={el.options}
          />
        ))}
      </div>
      <div className="promo__text">
        <div className="textarea">
          {teacher ? <span>Coupon Terms</span> : <span>Promo Code Terms</span>}
          {promo && (
            <textarea
              defaultValue={promo["terms"]}
              onChange={(e) =>
                setPromo({
                  ...promo,
                  terms: e.target.value,
                })
              }
            />
          )}
          {teacher ? (
            <p>Please enter you coupon terms</p>
          ) : (
            <p>Please enter promo code terms</p>
          )}
        </div>
        <div className="textarea">
          {teacher ? (
            <span>Coupon Details</span>
          ) : (
            <span>Promo Code Details</span>
          )}

          {promo && (
            <textarea
              defaultValue={promo["details"]}
              onChange={(e) =>
                setPromo({
                  ...promo,
                  details: e.target.value,
                })
              }
            />
          )}
          {teacher ? (
            <p>Please enter you coupon details</p>
          ) : (
            <p>Please enter promo code details</p>
          )}
        </div>
      </div>
      <div className="promo__btns">
        <Link to={teacher ? "/teachers/coupon" : "/admin/promo"}>
          <button className="discard">Discard</button>
        </Link>
        <button className="save" onClick={() => handleUpdate()}>
          Save
        </button>
      </div>
    </div>
  );
}

const PromoInput = ({ text, type, setPromo, promo, name, options }) => {
  const formatDate = (date) => {
    return moment(date).toISOString();
  };

  return (
    <div className="input">
      <span>{text}</span>
      {promo && type === "select" ? (
        <select
          defaultValue={promo[name]}
          onChange={(e) => {
            setPromo({
              ...promo,
              [name]: e.target.value,
            });
          }}
        >
          {options &&
            options.map((op) => <option value={op}>{op.toLowerCase()}</option>)}
        </select>
      ) : (
        promo && (
          <input
            defaultValue={promo[name]}
            type={type}
            placeholder={`Please enter ${text}`}
            onChange={(e) => {
              if (name === "endDate" || name === "startDate") {
                setPromo({
                  ...promo,
                  [name]: formatDate(e.target.value),
                });
              } else if (type === "number") {
                setPromo({
                  ...promo,
                  [name]: parseInt(e.target.value),
                });
              } else
                setPromo({
                  ...promo,
                  [name]: e.target.value,
                });
            }}
          />
        )
      )}
      <p>Please enter {text}</p>
    </div>
  );
};
