import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getStudentsNotes } from "../../../ReduxToolkit/Slices/studentSlice";
import NotesListing from "./NotesListing";
import { getTeacherNotes } from "../../../ReduxToolkit/Slices/lessonsSlice";

const NotesListingSection = ({}) => {
  const [showStudents, setShowStudents] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [notes, setNotes] = useState([]);
  const [note, setChoseNote] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    user.role === "STUDENT"
      ? dispatch(getStudentsNotes()).then((Res) => setNotes(Res.payload))
      : dispatch(getTeacherNotes()).then((Res) => {
          setNotes(Res.payload.data);
        });
  }, [refresh]);

  return (
    <div className="student_notes">
      <NotesListing
        showStudents={showStudents}
        notes={notes}
        setShowStudents={setChoseNote}
        setRefresh={setRefresh}
      />
      {note && <NoteSection note={note} />}
    </div>
  );
};

export default NotesListingSection;

const NoteSection = ({ note }) => {
  const sanitizedContent = note ? DOMPurify.sanitize(note.note) : "";
  const ref = useRef();
  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [note]);
  return (
    <div className="student_note_container">
      <h2>Teacher Note</h2>
      <div className="note_container">
        {note.title && <h3>title: {note.title}</h3>}
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          className="note_desc"
          ref={ref}
        />
      </div>
    </div>
  );
};
