import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getBlockedUsers,
  getDeletedUsers,
} from "../../../ReduxToolkit/Slices/adminSlice";
import TeachersTable from "../../Components/TeachersTable/TeachersTable";
import StudentsRow from "../../Components/ManageStudents/StudentsRow";
import "../../Components/ManageStudents/ManageStudents.styles.scss";
import Pagination from "../../../Components/Pagination/Pagination";

function Users({ deleted }) {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [refresh, setRefersh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
  });

  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + "limit=10" + `&page=${currentPage}`;
    deleted
      ? dispatch(getDeletedUsers(allFilters)).then((Res) => {
          setUsers(Res.payload.users);
          setTotalUsers(Res.payload.totalUsers);
          setPage(Res.payload.numOfPages);
        })
      : dispatch(getBlockedUsers(allFilters)).then((Res) => {
          setUsers(Res.payload.users);
          setTotalUsers(
            Res.payload.totalUsers?.length ? Res.payload.totalUsers?.length : 0
          );
          setPage(Res.payload.numOfPages);
        });
  }, [refresh, filters, currentPage]);

  return (
    <div className="manage__students">
      <div className="header">
        <div className="header__text">
          {deleted ? <span>Deleted Account</span> : <span>Blocked Users</span>}
        </div>
        <div className="students__actions">
          <div className="search__students">
            <input
              type="text"
              placeholder="Search For Users"
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>
                <span>User Name</span>
              </th>
              <th>
                <span>Email</span>
              </th>
              <th>
                <span>Role</span>
              </th>
              <th>
                <span>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((row, i) => (
                <StudentsRow
                  row={{ user: row }}
                  key={i}
                  i={i}
                  deleted={deleted}
                  users
                  tip="Delete Profile"
                  refresh={refresh}
                  setRefresh={setRefersh}
                />
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        dataRendered={users?.length ? users.length : 0}
        totalData={totalUsers}
      />
    </div>
  );
}

export default Users;
