export const HeaderData = {
  header: {
    phrase: "Education Is The Bedrock Of Success Today, I Am Going To Learn",
    highlight: "Success",
  },
  paragraph: {
    phrase:
      "Are you ready to start your education path? EDUCIFY is here for you",
    highlight: "EDUCIFY",
  },
  placeholder: "What do you want to learn ?",
};
