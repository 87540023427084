import React, { useState } from "react";
import "./EditContent.styles.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function EditContent() {
  const [text, setText] = useState("");
  return (
    <div className="edit__content">
      <span>Title</span>
      <input type="text" placeholder="About us" />
      <div className="text__actions">
        <ReactQuill
          className="rich__text"
          theme="snow"
          value={text}
          onChange={setText}
        />
      </div>
      <div className="content__btn">
        <button>Update</button>
      </div>
    </div>
  );
}
