import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./rangeSlider.styles.scss";

const MultiRangeSlider = ({
  min,
  max,
  onChange,
  symbol,
  setMinVal,
  minVal,
  setMaxVal,
  maxVal,
  setDragMax,
  setDragMin,
  SetActivateSlider,
  activateSlider,
}) => {
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );
  const usdPrice = (price) =>
    symbol === "$" ? Math.round(price / 100) : price;

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <div className="container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(isNaN(value) ? 0 : value);
          onChange({ min: isNaN(minVal) ? 0 : minVal });

          minValRef.current = value;
        }}
        onBlur={() => {
          setDragMin(true);
          SetActivateSlider(true);
          onChange({ min: isNaN(minVal) ? 0 : minVal });
          SetActivateSlider(false);
          setDragMin(false);
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(isNaN(value) ? 0 : value);
          onChange({ max: isNaN(maxVal) ? 0 : maxVal });

          maxValRef.current = value;
        }}
        className="thumb thumb--right"
        onBlur={() => {
          SetActivateSlider(true);
          setDragMax(true);
          onChange({ max: isNaN(maxVal) ? 0 : maxVal });
          setDragMax(false);
          SetActivateSlider(false);
        }}
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">
          {symbol === "$" ? "$" : ""} {usdPrice(minVal)}{" "}
          {symbol !== "$" ? `${(" ", symbol)}` : ""}
        </div>
        <div className="slider__right-value">
          {symbol === "$" ? "$" : ""} {usdPrice(maxVal)}{" "}
          {symbol !== "$" ? `${symbol}` : ""}
        </div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
