import React from "react";
import "./LearnAnywhere.styles.scss";
import searchGold from "../../../../assets/SharedApp/svgs/searchGold.svg";
import girl from "../../../../assets/SharedApp/images/footerimg.webp";
import HeaderPhraseComponent from "../HeaderPhraseComponent/HeaderPhraseComponent";
export default function LearnAnywhere() {
  return (
    <div className="learn__anywhere__section">
      <div className="section__container">
        {/* <div className="header__phrase__component">
          <h1>
            Learn from anywhere,
            <span> anytime</span>
          </h1>
          <p>
            Get unlimited access to 19,000+ of Educify top courses. Learn and
            improve skills across business, tech, design, and more
          </p>
          <div className="input">
            <input type="text" placeholder="What do you want to learn?" />
            <img src={searchGold} alt="" />
          </div>
        </div> */}
        <HeaderPhraseComponent />
        <div className="image">
          <img src={girl} alt="" />
        </div>
      </div>
    </div>
  );
}

// const HeaderPhraseComponent = () => {
//   return (
//     <div className="header__phrase__component">
//       <h1>
//         Learn from anywhere,
//         <span> anytime</span>
//       </h1>
//       <p>
//         Get unlimited access to 19,000+ of Educify top courses. Learn and
//         improve skills across business, tech, design, and more
//       </p>
//       <div className="input">
//         <input type="text" placeholder="What do you want to learn?" />
//         <img src={searchGold} alt="" />
//       </div>
//     </div>
//   );
// };
