import React, { useEffect, useState } from "react";
import "./ourLessons.styles.scss";
import Lesson from "../../Components/Lesson/Lesson";

import { text } from "../../assets/HomeApp/data/courses";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ScrollAnimation from "../../SharedApp/Pages/ScrollAnimation/ScrollAnimation";
import { useDispatch } from "react-redux";
import {
  getCourseCategories,
  getStudentCourses,
} from "../../ReduxToolkit/Slices/coursesSlice";
import CourseCard from "../../StudentApp/Components/Courses/CourseCard/CourseCard";
export default function OurCourses() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    dispatch(getStudentCourses(`limit=100`)).then((res) => {
      res?.payload?.courses && setCourses(res.payload.courses);
    });
  }, []);

  return (
    <div className="lessons__section">
      <ScrollAnimation
        initialY={100}
        animateY={0}
        initialX={0}
        animateX={0}
        transition={1}
        delay={0}
      >
        <h2>Our Courses</h2>
      </ScrollAnimation>
      <ScrollAnimation
        initialY={100}
        animateY={0}
        initialX={0}
        animateX={0}
        transition={1}
        delay={0.5}
      >
        <p>
          Our courses are tailored to help you acquire the skills and knowledge
          needed for your long-term goals and a brighter future.
        </p>
      </ScrollAnimation>
      {/* <ScrollAnimation
        initialY={100}
        animateY={0}
        initialX={0}
        animateX={0}
        transition={1}
        delay={0.6}
      > */}
      <div className="carousel home__carousel">
        <ScrollAnimation
          initialY={100}
          animateY={0}
          initialX={0}
          animateX={0}
          transition={1}
          delay={0.3}
        >
          <div className="inner__carousel">
            <div className="lesson__header">
              <h2 />
              <span>
                <Link to={`/courses`}>View All</Link>
              </span>
            </div>
            {
              <div className="conts">
                <Slider
                  slidesToShow={4}
                  className="home__courses__slider"
                  infinite={true}
                  responsive={[
                    {
                      breakpoint: 1330,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                >
                  {courses &&
                    courses.length > 0 &&
                    courses.map((course, i) => (
                      <CourseCard
                        key={course.id}
                        title={course.title}
                        price={course.price}
                        rating={course.rating}
                        image={course?.profileImage?.url}
                        id={course.id}
                      />
                    ))}{" "}
                  {courses.length > 0 &&
                    courses.map((course, i) => (
                      <CourseCard
                        key={course.id}
                        title={course.title}
                        price={course.price}
                        rating={course.rating}
                        image={course?.profileImage?.url}
                        id={course.id}
                      />
                    ))}{" "}
                  {courses.length > 0 &&
                    courses.map((course, i) => (
                      <CourseCard
                        key={course.id}
                        title={course.title}
                        price={course.price}
                        rating={course.rating}
                        image={course?.profileImage?.url}
                        id={course.id}
                      />
                    ))}
                </Slider>
              </div>
            }
          </div>
        </ScrollAnimation>
      </div>
      {/* </ScrollAnimation> */}
    </div>
  );
}
{
  /* <div className="conts">
<Slider slidesToShow={4} className="test__slider" centerPadding="0px">
  {courses.length > 0 &&
    courses.map((course, i) => (
      <CourseCard
        key={course.id}
        title={course.title}
        price={course.price}
        rating={course.rating}
        image={course?.profileImage?.url}
        id={course.id}
      />
    ))}{" "}
  {courses.length > 0 &&
    courses.map((course, i) => (
      <CourseCard
        key={course.id}
        title={course.title}
        price={course.price}
        rating={course.rating}
        image={course?.profileImage?.url}
        id={course.id}
      />
    ))}{" "}
  {courses.length > 0 &&
    courses.map((course, i) => (
      <CourseCard
        key={course.id}
        title={course.title}
        price={course.price}
        rating={course.rating}
        image={course?.profileImage?.url}
        id={course.id}
      />
    ))}
</Slider>
</div> */
}
