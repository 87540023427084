import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createSeoMetaTag,
  getSeoByPageName,
  updateSeoMetaTag,
} from "../../../ReduxToolkit/Slices/adminSlice";
import Note from "../../../TeacherApp/Components/Note/Note";

export default function CreateAdPage({ edit, view }) {
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("");
  const [descriptions, setDescriptions] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [description, setDescription] = useState("");
  const [keyword, setKeyword] = useState("");

  const handleCreateTag = () => {
    dispatch(createSeoMetaTag({ keywords, descriptions, pageName }))
      .then((res) => {
        if (typeof res.payload === "string") {
          return toast.error(res.payload);
        }
        if (res.type.includes("fulfilled")) {
          toast.success("Meta Tag created Succesffully");
          navigate(-1);
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((err) => console.log("eror", err));
  };
  useEffect(() => {
    if (edit || view)
      dispatch(getSeoByPageName(id)).then((res) => {
        if (res.type.includes("fulfilled"))
          setDescriptions(res.payload.data.descriptions);
        setKeywords(res.payload.data.keywords);
        setPageName(res.payload.data.pageName);
      });
  }, [pathname]);
  const handleUpdateSeo = () => {
    dispatch(updateSeoMetaTag({ keywords, descriptions, pageName })).then(
      (res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Meta Tag updated Succesffully");
          navigate(-1);
        } else {
          toast.error("something went wrong");
        }
      }
    );
  };
  return (
    <div className="">
      <Note
        p1={"Please select the page you want to add the MetaTag to."}
        p2={"Please fill the keywords then click the blue + to add a keyword"}
        p3={
          "Please fill the description then click the blue + to add a description"
        }
        p4={
          "Please remember that you can only add 1 set of keywords and descriptions per 1 page, if you try to add metatags to a page that already have metatags you will get an error."
        }
      />

      <div className="create__category">
        <div className="header">
          <h1>Create Seo Meta Tag</h1>
        </div>
        <div className="create__category__row">
          <span>Page name</span>

          <select
            className="tag_select"
            onChange={(e) => setPageName(e.target.value)}
            disabled={view}
          >
            <option selected disabled>
              Select Page Name
            </option>

            {["HOMEPAGE", "COURSESPAGE", "GROUPLESSONPAGE"].map((el) => (
              <option selected={pageName === el} key={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
        {!view && (
          <div className="create__category__row">
            <span style={{ alignSelf: "flex-start" }}>Tag</span>
            <div className="all_tags">
              <div className="multiple">
                <div className="col">
                  <span>Keywords</span>
                  <div className="tag_row">
                    <input
                      type="text"
                      placeholder={"Tag Keyword"}
                      onChange={(e) => setKeyword(e.target.value)}
                      value={keyword}
                    />

                    <button
                      className="create_button"
                      onClick={() => {
                        if (keywords.find((item) => item === keyword))
                          return toast.warning(`${keyword} already exists`);
                        else {
                          setKeyword("");
                          setKeywords([...keywords, keyword]);
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="multiple">
                <div className="col">
                  <span>Descriptions</span>
                  <div className="tag_row">
                    <input
                      type="text"
                      placeholder={"Tag Description"}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />

                    <button
                      className="create_button"
                      onClick={() => {
                        if (descriptions.find((item) => item === description))
                          return toast.warning(`${description} already exists`);
                        else {
                          setDescription("");
                          setDescriptions([...descriptions, description]);
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="map_container">
          <div className="mapped">
            <h3>Description</h3>
            <div className="descriptions">
              {descriptions &&
                descriptions.map((el) => (
                  <div className="desc_row">
                    <span>{el}</span>
                    {!view && (
                      <button
                        onClick={() => {
                          setDescriptions(
                            descriptions.filter((kwrd) => kwrd !== el)
                          );
                        }}
                        className="delete"
                      >
                        -
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className="mapped">
            <h3>Keywords</h3>

            <div className="descriptions">
              {keywords &&
                keywords.map((el) => (
                  <div className="desc_row">
                    <span>{el}</span>
                    {!view && (
                      <button
                        className="delete"
                        onClick={() => {
                          setKeywords(keywords.filter((kwrd) => kwrd !== el));
                        }}
                      >
                        -
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="create__cat__btns">
          <div className="cat__buttons">
            {/* <button className="discard">Discard</button> */}
            {!view && (
              <button
                className="save__btn"
                onClick={() => (edit ? handleUpdateSeo() : handleCreateTag())}
              >
                {edit ? "Update" : "Create"}
              </button>
            )}
            {view && (
              <button className="save__btn" onClick={() => navigate("edit")}>
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
