import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "./DraftCourses.styles.scss";

import plus from "../../../../assets/AdminApp/svgs/plus.svg";
import DraftRow from "../../../Components/Courses/DraftRow/DraftRow";
import {
  deleteCourse,
  getCourseById,
  getTeacherCourses,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import AlertModalDelete from "../../../../AdminApp/Components/AlertModalDelete/AlertModalDelete";
export default function DraftCourses({ title, isDraft }) {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);

  const { teacher } = useSelector((state) => state.teacher);

  useEffect(() => {
    dispatch(getTeacherCourses(`teacherId=${teacher.id}&limit=${100}`)).then(
      (res) => {
        setCourses(res.payload.coursesWithPrice);
      }
    );
  }, [refresh]);

  const onDelete = (id) => {
    setId(id);
    setModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteCourse(id)).then((res) => {
      setRefresh(!refresh);
    });
    setModal(false);
  };
  return (
    <div className="manage__teachers__page">
      {modal && (
        <AlertModalDelete
          type="course"
          setModal={setModal}
          handleDelete={handleDelete}
        />
      )}
      <div className="header">
        <div className="header__text">
          <span>{isDraft ? "Draft" : "Published"} courses</span>
          <p>You have {courses?.length} published courses</p>
        </div>
        <div className="teachers__actions">
          <Link className="add__new__draft" to="/teachers/courses/create">
            <img src={plus} alt="" />
            <span>Add New Course</span>
          </Link>
        </div>
      </div>
      {courses.length > 0 && (
        <div className="students__table responsive_table">
          <table>
            <thead>
              <tr>
                <th>COURSES</th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    padding: "0 10px",
                  }}
                >
                  COURSE PLAN
                </th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, i) => {
                const { status, price, title, description, id, profileImage } =
                  course;
                return (
                  <DraftRow
                    key={i}
                    status={status}
                    price={price}
                    id={id}
                    title={title}
                    profileImage={profileImage}
                    description={description}
                    onDelete={onDelete}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
