import React from "react";

export default function CategoryTrack({
  selected,
  setSelected,
  categories,
  color,
  noborder,
}) {
  return (
    <div className={`cateogry__track ${noborder ? "noborder" : ""}`}>
      {categories.map((el) => (
        <span
          className={`${el === selected ? `selected-${color}` : ""}`}
          onClick={() => setSelected(el)}
        >
          {el}
        </span>
      ))}
    </div>
  );
}
