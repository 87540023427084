import React from "react";
import "./PackageFooter.styles.scss";
import { social } from "../../../assets/SharedApp/data/Packages";
import { useNavigate } from "react-router-dom";
export default function PackageFooter() {
  const navigate = useNavigate();
  return (
    <div className="packages__footer">
      <div className="socials">
        {social &&
          social.map((soc, i) => (
            <a key={i} href={soc.link}>
              <img src={soc.image} />
            </a>
          ))}
      </div>
    </div>
  );
}
