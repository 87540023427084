import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  getBookings,
  getFilteredBookings,
  setAdminState,
} from "../../../ReduxToolkit/Slices/adminSlice";

import "./Booking.styles.scss";

import notfound from "../../../assets/SharedApp/images/default.webp";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import right from "../../../assets/SharedApp/svgs/right.svg";
import Pagination from "../../../Components/Pagination/Pagination";

export default function Booking() {
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetched, setFetched] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
  });
  const dispatch = useDispatch();

  const setBooking = (booking) => {
    dispatch(setAdminState({ name: "booking", value: booking }));
  };

  useEffect(() => {
    let newFilters = "";
    Object.keys(filters).map((keyName, i) => {
      let value = filters[keyName];
      if (value === "") return;
      newFilters = newFilters + `${keyName}=${value}&`;
    });
    let allFilters = newFilters + `page=${currentPage}&`;
    dispatch(getFilteredBookings(allFilters)).then((res) => {
      if (res.type.includes("fulfilled")) setBookings(res.payload.bookings);
      setPage(res.payload.numOfPages);
      setFetched(true);
    });
  }, [currentPage, filters]);

  return (
    <div className="booking__list">
      <div className="booking__table">
        <Table
          bookings={bookings}
          page={page}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setBooking={setBooking}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
}

const Table = ({
  bookings,
  page,
  setCurrentPage,
  currentPage,
  setBooking,
  filters,
  setFilters,
}) => {
  const newDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  return (
    <div className="manage__booking__page">
      <div className="header">
        <div className="header__text">
          <span>Booking Lists</span>
          {/* <p>More than 400+ members</p> */}
        </div>
        <div className="teachers__actions">
          <div className="search__teachers">
            <select
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
              className="select_status"
            >
              <option disabled>Select Booking Status</option>
              <option value={""}>All</option>
              <option value={"ACCEPTED"}>Accepted</option>
              <option value={"COMPLETED"}>Completed</option>
              <option value={"REASSIGNED"}>Reassigned</option>
              <option value={"PENDING"}>Pending</option>
              <option value={"REJECTED"}>Rejected</option>
              <option value={"CANCELLED"}>Cancelled</option>
            </select>
            <input
              type="text"
              placeholder="Search students"
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <tr>
            <th>STUDENTS</th>
            <th>LESSONS</th>
            <th>CREATED AT</th>
            <th>TEACHER</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
            <th></th>
          </tr>
          {bookings &&
            bookings.length > 0 &&
            bookings.map((booking) => (
              <tr>
                <td>
                  <div className="student__cell">
                    <img
                      src={
                        booking.student.user?.profileImage?.url
                          ? booking.student.user?.profileImage?.url
                          : notfound
                      }
                      alt=""
                    />
                    <div className="name">
                      <span>
                        {booking?.student?.user?.name}{" "}
                        {booking?.student?.user?.lastName}
                      </span>
                      <p>{booking?.student?.user?.address?.street}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="manag e__std__lesson">
                    <div className="lesson__date">
                      <span>
                        {booking?.lesson?.isGroup ? "Group" : "Private"} Lesson
                      </span>
                      <p>{booking?.lesson?.title}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <span>{newDate(booking.createdAt)} </span>
                  <p>Date</p>
                </td>
                <td>
                  <span>
                    {booking?.lesson?.teacher?.user?.name}
                    {booking?.lesson?.teacher?.user?.lastName}
                  </span>
                  {/* change bellow */}
                  <p>Virtual</p>
                </td>
                <td>
                  <div className="student__cell">
                    <div className="name">
                      <span>{booking.lesson.hourlyRate}</span>
                      <p>per hour</p>
                    </div>
                  </div>
                </td>
                <td>
                  <button className={booking.status.toLowerCase()}>
                    {booking.status.toLowerCase()}
                  </button>
                </td>
                <td>
                  <Link
                    onClick={() => setBooking(booking)}
                    to={`${booking.id}`}
                  >
                    <img src={right} alt="" />
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      </div>
      <Pagination
        page={page}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};
