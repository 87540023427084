import React, { useEffect } from "react";
import "./TeacherRequest.styles.scss";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptBooking,
  getLessonById,
  rejectBooking,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import { useState } from "react";
import { getStudentById } from "../../../ReduxToolkit/Slices/studentSlice";
import { convertTimezone } from "../../../SharedApp/Components/Timezone";
import { Tooltip } from "@mui/material";

import notfound from "../../../assets/SharedApp/images/default.webp";
import { handleSliceChange } from "../../../ReduxToolkit/Slices/teacherSlice";
export default function TeacherRequest({
  location,
  id,
  Appointments,
  type,
  student,
  title,
  setFetch,
  fetch,
  images,
}) {
  const gethour = (dates) => dates.getHours();
  const { timezone } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { teacher, refreshRequests } = useSelector((state) => state.teacher);

  const [busy, setBusy] = useState(false);

  const onRejectClick = () => {
    setBusy(true);
    dispatch(
      rejectBooking({
        teacherId: teacher.id,
        bookingId: id,
      })
    )
      .then((res) => {
        setFetch(!fetch);
        setBusy(false);
        toast.success("Booking was Rejected, thank you!");
      })
      .catch((err) => setBusy(false));
  };

  const viewLocation = () => {
    const { street, city, state, country, zipCode } = student?.user?.address;
    const address = `${street}, ${city}, ${state}, ${country}, ${zipCode}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };
  const onAcceptClick = () => {
    setBusy(true);
    dispatch(
      acceptBooking({
        teacherId: teacher.id,
        bookingId: id,
      })
    )
      .then((res) => {
        setFetch(!fetch);
        setBusy(false);
        dispatch(
          handleSliceChange({
            name: "refreshRequests",
            value: !refreshRequests,
          })
        );
        toast.success("Booking was accepted, thank you!");
      })
      .catch(() => setBusy(false));
  };
  return (
    <div className="teacher__request__container">
      {images[0]?.url && (
        <div className="image">
          <img src={images[0]?.url} alt="" />
        </div>
      )}
      <div className="request__info">
        <h1>{title}</h1>
        <div className="student__info">
          <img
            src={
              student && student.user?.profileImage?.url
                ? student.user?.profileImage?.url
                : notfound
            }
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = notfound;
            }}
          />
          <div className="student__name">
            <span>Student</span>
            <p>{student && student.name}</p>
          </div>
        </div>
        <div className="rows">
          <div className="row">
            <span className="row__title">Dates</span>
            <span className="datesC">
              {Appointments &&
                Appointments.length &&
                Appointments.map((dat, i) => (
                  <>
                    <span key={i}>{moment(dat.date).format("DD/MM/YYYY")}</span>
                    <span>
                      {convertTimezone(
                        moment.utc(dat.startTime).format("HH:mm"),
                        "UTC",
                        timezone
                      )}{" "}
                      -{" "}
                      {convertTimezone(
                        moment.utc(dat.endTime).format("HH:mm"),
                        "UTC",
                        timezone
                      )}
                    </span>
                  </>
                ))}
            </span>
          </div>
          <div className="row">
            <span className="row__title">Type</span>
            <span>{type.toLowerCase()}</span>
          </div>
          <Tooltip
            arrow
            title={location === "studentPlace" ? "View student's location" : ""}
          >
            <div
              className={location === "studentPlace" ? "row pointer" : "row"}
              onClick={() => viewLocation()}
            >
              <span className="row__title">Venue</span>
              <span>{location}</span>
            </div>
          </Tooltip>
        </div>
        <div className="request__btns">
          <button
            className={busy ? "accept fetching" : "accept"}
            onClick={() => onAcceptClick()}
          >
            Accept
          </button>
          <button className="reject" onClick={() => onRejectClick()}>
            Reject
          </button>
        </div>
      </div>
    </div>
  );
}
