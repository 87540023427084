import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const SubjectRow = ({
  selected = false,
  setShowAfterSelect,
  name,
  onClick,
  id,
}) => {
  return (
    <div
      key={id}
      className={`subject_row ${selected ? "selected" : ""}`}
      onClick={() => {
        // setShowAfterSelect && setShowAfterSelect((prev) => !prev);
        onClick && onClick();
      }}
    >
      <span>{name}</span>
      {selected ? (
        <input type="checkbox" checked className="sub_row_checkbox" />
      ) : (
        <ChevronRightIcon />
      )}
    </div>
  );
};
