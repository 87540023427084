import React, { useEffect, useState } from "react";
import "./ourLessons.styles.scss";
import Lesson from "../../Components/Lesson/Lesson";

import { text } from "../../assets/HomeApp/data/courses";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCategoriesWithSubjects } from "../../ReduxToolkit/Slices/lessonsSlice";
import ScrollAnimation from "../../SharedApp/Pages/ScrollAnimation/ScrollAnimation";
export default function Lessons() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({ limit: "3" });
  useEffect(() => {
    dispatch(getCategoriesWithSubjects(filters)).then((res) => {
      setCategories(res?.payload?.data?.categories);
    });
  }, []);
  return (
    <div className="lessons__section">
      <ScrollAnimation
        initialY={50}
        animateY={0}
        initialX={0}
        animateX={0}
        transition={1}
        delay={0}
      >
        <h2 style={{ textAlign: "center" }}>{text.header}</h2>
      </ScrollAnimation>
      <ScrollAnimation
        initialY={50}
        animateY={0}
        initialX={0}
        animateX={0}
        transition={1}
        delay={0}
      >
        <p>{text.p}</p>
      </ScrollAnimation>
      <div className="carousel home__carousel">
        {categories &&
          categories.map((section, i) => {
            return (
              section.subjects.length > 3 && (
                <ScrollAnimation
                  initialY={100}
                  animateY={0}
                  initialX={0}
                  animateX={0}
                  transition={1}
                  delay={0}
                >
                  <div className="inner__carousel" key={i}>
                    <div className="lesson__header">
                      <h3>{section.name}</h3>
                      <span>
                        <Link to={`/landing/${section.name}`}>View All</Link>
                      </span>
                    </div>
                    <div className="conts">
                      <Slider
                        slidesToShow={4}
                        className="home__courses__slider home__lessons__slider"
                        infinite={true}
                        responsive={[
                          {
                            breakpoint: 1330,
                            settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1,
                            },
                          },
                        ]}
                      >
                        {section.subjects.slice(0, 10).map((lesson, i) => (
                          <Lesson
                            key={i}
                            name={lesson.name}
                            category={section.name}
                            image={lesson.images[0]?.url}
                            rating={5}
                          />
                        ))}
                        {section.subjects.slice(0, 10).map((lesson, i) => (
                          <Lesson
                            key={i}
                            name={lesson.name}
                            category={section.name}
                            image={lesson.images[0]?.url}
                            rating={5}
                          />
                        ))}
                        {section.subjects.slice(0, 10).map((lesson, i) => (
                          <Lesson
                            key={i}
                            name={lesson.name}
                            category={section.name}
                            image={lesson.images[0]?.url}
                            rating={5}
                          />
                        ))}
                      </Slider>
                    </div>
                  </div>
                </ScrollAnimation>
              )
            );
          })}
      </div>
    </div>
  );
}
