import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Track from "../../../Components/Track/Track";
import { toast } from "react-toastify";

import "./AddCurriculum.styles.scss";
import arrow from "../../../../assets/TeacherApp/assets/svgs/Arrow-right.svg";
import ArrowLeft from "../../../../assets/TeacherApp/assets/svgs/Arrow-left-blue.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  createAChapter,
  handleAddChapter,
  handleChapterChange,
  addLessons,
  updateChapter,
  updateChapterLesson,
  handleCoursesStateChange,
} from "../../../../ReduxToolkit/Slices/coursesSlice";
import Chapter from "./Chapter";

export default function AddCurriculum({ given, edit }) {
  const dispatch = useDispatch();
  const { chapters, courseId } = useSelector((state) => state.courses);
  const navigate = useNavigate();
  const location = useLocation();

  const noTrack = location.pathname.includes("signup");

  const [editIndex, setEditIndex] = useState(0);

  const isNotEmpty = (chapter) => {
    return chapter.every((obj) => {
      let newObj = {
        title: obj.title,
      };
      return Object.values(newObj).every((val) => {
        if (Array.isArray(val)) {
          return val.length > 0;
        } else return val !== "";
      });
    });
  };

  async function addLesson(chapterId, lesson) {
    return dispatch(
      addLessons({
        chapterId,
        videos: lesson.videos,
        title: lesson.title,
        description: lesson.description,
      })
    );
  }
  async function updateLesson(chapterId, lesson) {
    return dispatch(
      updateChapterLesson({
        id: lesson.id,
        chapter: {
          chapterId,
          title: lesson.title,
          description: lesson.description,

          videos: lesson.videos,
        },
      })
    );
  }
  const onEndSubmit = () => {
    toast.success("Courses chapter has been submitted. Thank you!");
    noTrack
      ? navigate("/teacher/signup/completed")
      : navigate("/teachers/courses/mycourses");
  };
  const handleSubmit = async () => {
    if (chapters.length < 2) {
      return toast.error("You need to have at least 2 chapters in each course");
    }
    let array = [];
    chapters.map((chapter) => {
      if (!chapter.title) {
        return array.push(false);
      }
      array.push(isNotEmpty(chapter.lessons));
    });
    if (array.includes(false)) {
      return toast.error("You must fill in all values");
    } else {
      for (const chapter of chapters) {
        if (chapter.id) {
          const res = await dispatch(
            updateChapter({
              id: chapter.id,
              chapter: {
                title: chapter.title,
              },
            })
          );
          if (res.type === "updateChapter/fulfilled") {
            for (const lesson of chapter.lessons) {
              if (lesson.id) {
                await updateLesson(chapter.id, lesson);
              } else {
                await addLesson(res.payload.data.id, lesson);
              }
            }
          }
        } else {
          const res = await dispatch(
            createAChapter({
              courseId,
              title: chapter.title,
            })
          );
          for (const lesson of chapter.lessons) {
            await addLesson(res.payload.data.id, lesson);
          }
        }
      }
      onEndSubmit();
    }
  };
  let chapterInitial = {
    title: "Chapter Title",
    courseId,
    lessons: [],
  };

  const addNew = () => {
    dispatch(handleAddChapter(chapterInitial));
  };

  const onEditTitle = (name, index, value) => {
    dispatch(
      handleChapterChange({
        name,
        value,
        index,
      })
    );
  };
  return (
    <div className="add__course__curr">
      {!noTrack && <Track step={2} givenData={given} />}
      <h1 className="dash__header">Add Curriculum</h1>
      {chapters.map((chapter, i) => (
        <Chapter
          key={i}
          onEditTitle={onEditTitle}
          chapter={chapter}
          i={i}
          setEditIndex={setEditIndex}
          editIndex={editIndex}
        />
      ))}
      <div className="new__section" onClick={() => addNew()}>
        <span className="pointer">Add New Chapter</span>
      </div>
      <div className="curric__btns">
        <Link to={"/teachers/courses/create/"}>
          <button className="prev__btn">
            <img src={ArrowLeft} alt="" /> Previous
          </button>
        </Link>
        {/* <Link to={"/teachers/courses/create/3"}> */}
        <button className="next__btn" onClick={() => handleSubmit()}>
          Submit <img src={arrow} alt="" />
        </button>

        {/* </Link> */}
      </div>
    </div>
  );
}
