import React, { useEffect, useState } from "react";
import "./LoginMethod.styles.scss";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { updateUserPassword } from "../../../ReduxToolkit/Slices/userSlice";
import { validatePassword } from "../../../hooks";

export default function LoginMethod() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [fetching, setFetching] = useState(false);
  const [confirmPass, setConfirm] = useState("");
  const notify = (message) => toast.success(message);
  const sendError = (message) => toast.error(message);

  const updatePassword = () => {
    if (!oldPass || !newPass || !confirmPass) {
      return sendError("Please fill in all inputs");
    }
    if (oldPass === newPass) {
      return sendError("Please enter a different password");
    }
    if (confirmPass !== newPass) {
      return sendError("Passwords dont match");
    }
    if (!validatePassword(newPass)) {
      return toast.error(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one number, and one symbol (e.g. !@#$%^&*)"
      );
    }

    setFetching(true);
    dispatch(
      updateUserPassword({
        id: user.id,
        passwords: {
          oldPassword: oldPass,
          newPassword: newPass,
        },
      })
    ).then((res) => {
      setFetching(false);
      setOldPass("");
      setNewPass("");
      setConfirm("");
      if (res.payload.data?.message === "Invalid credentials") {
        return sendError("Invalid credentials");
      }
      if (res.type === "updateUserPassword/fulfilled") {
        return notify("Thank you, password updated!");
      } else {
        return sendError("Error updating password, please try again later");
      }
    });
  };
  return (
    <div className="login__method">
      <div className="header">
        <h2>Sign-in Method</h2>
      </div>
      <div className="login__method__row">
        <div className="login__emails">
          <div className="input">
            <span>Current Password</span>
            <input
              type="password"
              placeholder="Current Password"
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
            />
            <p>Password must be at least 8 character and contain symbols</p>
          </div>
          <div className="input">
            <span>New Password</span>
            <input
              type="password"
              placeholder="New Password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
            />
          </div>
          <div className="input">
            <span>Confirm Password</span>
            <input
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirm(e.target.value)}
              value={confirmPass}
            />
          </div>
        </div>
        <div className="email__btns">
          <button
            onClick={() => updatePassword()}
            className={fetching ? "UpdateEmail fetching" : "UpdateEmail"}
          >
            Update Password
          </button>
        </div>
      </div>
      {/* <AuthNote text={text} link="#" /> */}
    </div>
  );
}
