import React from "react";

import "./CourseReview.styles.scss";

import stars from "../../../../assets/TeacherApp/assets/svgs/rating.svg";
import star from "../../../../assets/TeacherApp/assets/svgs/rating2.svg";

export default function CourseReview({ name, comment, rating, lastName }) {
  return (
    <div className="course__review__multi">
      <div className="author">
        <span>{name + " " + lastName}</span>
      </div>
      <div className="rating">
        {Array.from(Array(rating)).map((el) => (
          <img src={star} alt="" />
        ))}

        {/* <span>Yestarday at 5:06 PM</span> */}
      </div>
      <div className="description">
        <p>{comment}</p>
      </div>
      {/* <div className="helpful"></div> */}
    </div>
  );
}
