import React from "react";
import moment from "moment";
import { toast } from "react-toastify";

import "./TransactionTable.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { payStudent } from "../../../ReduxToolkit/Slices/adminSlice";

export default function TransactionTable({
  data,
  name,
  teacher,
  studentId,
  bookings,
  studentUserId,
}) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const payment = (transactionId) => {
    return {
      currency: "USD",
      description: "Refunding student",
      type: "REFUND",
      paymentMethod: "WALLET",
      senderId: user.id,
      receiverId: studentUserId,
      status: "PENDING",
      studentId,
      transactionId,
    };
  };
  const onRefund = (transactionId) => {
    dispatch(payStudent(payment(transactionId))).then((res) => {
      if (res.type === "payStudent/rejected") {
        toast.error(res.payload);
      } else {
        toast.success("Refund request sent");
      }
    });
  };
  return (
    <div className="teacher_payments">
      <div className="header">
        <h1>
          <span>{name}</span>{" "}
          {bookings ? <span>'s bookings</span> : <span>'s transactions</span>}
        </h1>
      </div>
      <div className="payments__table">
        <div className="students__table">
          <table>
            <thead>
              <tr>
                <th>NO</th>
                <th>Status</th>
                <th>DESCRIPTION</th>
                <th>AMOUNT</th>
                <th>PAYMENT METHOD</th>
                <th>CREATED AT</th>
                {!teacher && <th>ACTIONS</th>}
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => (
                <tr key={i}>
                  <td>{i}</td>
                  <td>
                    <p>{row.status}</p>
                  </td>
                  <td>
                    <p>{row.description}</p>
                  </td>
                  <td>
                    <p className="text__success">
                      {row.currency === "USD" ? "$" : row.currency}
                      {Math.round(row.amount) / 100}
                    </p>
                  </td>
                  <td>
                    <p>{row.transactionId ? "Refund" : row.paymentMethod}</p>
                  </td>
                  <td>
                    <span>
                      {moment(row.createdAt).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </td>
                  {!teacher && !row.transactionId && (
                    <td>
                      <button className="pay" onClick={() => onRefund(row.id)}>
                        Refund
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
