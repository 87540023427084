import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadScript from "load-script";
import { toast } from "react-toastify";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import {
  getTeacherById,
  uploadImage,
} from "../../../ReduxToolkit/Slices/teacherSlice";

import "./ProfileDetails.styles.scss";

import edit from "../../../assets/SharedApp/svgs/edit.svg";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import google from "../../../assets/TeacherApp/assets/svgs/google-icon 1.svg";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import {
  handleUserChange,
  setUserChange,
  updateUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";

export default function ProfileDetails({
  missing,
  setRefresh,
  refresh,
  onCancelClick,
  setMiniUser,
  recommend,
  miniUser,
  className,
  profileImage,
  setProfileImage,
  role,
}) {
  const dispatch = useDispatch();
  const userInitial = {
    user: {
      email: "",
      name: "",
      lastName: "",
      role: "STUDENT",
      password: "",
      address: {
        street: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
      phone: "",
      profileImage: {
        publicId: "",
        url: "",
      },
      gender: "MALE",
      bySocial: false,
      nationality: "",
    },
  };
  const stateUser = useSelector((state) => state.user.user);

  const [user, setUsers] = useState(recommend ? miniUser : stateUser);

  const [fetching, setFetching] = useState(false);

  const sendToast = (text) => toast.error(text);

  const setUser = (value) => {
    setUsers(value);
    recommend && setMiniUser(value);
    dispatch(setUserChange(value));
  };

  const updateUsers = () => {
    setFetching(true);
    dispatch(
      updateUser({
        user: {
          name: user?.name,
          lastName: user?.lastName,
          email: user?.email,
          phone: user?.phone,
          helpGuide: user.helpGuide ? user.helpGuide : false,
          description: user?.description,
          address: {
            state: user?.address?.state ? user?.address?.state : "state",
            country: user?.address?.country
              ? user?.address?.country
              : "country",
            city: user?.address?.city ? user?.address?.city : "city",
            street: user?.address?.street ? user?.address?.street : "street",
            zipCode: user?.address?.zipCode
              ? user?.address?.zipCode
              : "zipCode",
          },
          profileImage: {
            url: user?.profileImage?.url ? user?.profileImage?.url : "",
            publicId: user?.profileImage?.publicId
              ? user?.profileImage?.publicId
              : "",
            id: user?.profileImage?.id ? user?.profileImage?.id : "",
          },
          nationality: user.nationality,
        },
        id: user.id,
      })
    )
      .then((res) => {
        setFetching(false);

        if (res.type === "updateUser/fulfilled") {
          toast.success("Thank you, your profile was updated!");
          return dispatch(
            handleUserChange({
              name: "refreshUser",
              value: !stateUser.refreshUser,
            })
          );
        } else {
          toast.error(res.payload);
        }
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const onSubmit = () => {
    if (recommend) {
      return onCancelClick();
    } else {
      if (isPossiblePhoneNumber(user.phone)) {
        updateUsers();
        missing && setRefresh(!refresh);
      } else {
        toast.error("Phone number not valid");
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "helpGuide") {
      return setUser({ ...user, helpGuide: !user.helpGuide });
    }
    setUser({ ...user, [name]: value });
  };

  const onSelectFile = (value) => {
    if (recommend) setProfileImage(value);
    else setUser({ ...user, profileImage: value });
  };

  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  return (
    (user?.name || recommend) && (
      <div
        className={
          missing
            ? "account__settings missing"
            : `account__settings ${className ?? ""}`
        }
      >
        <div className="settings__container">
          {!recommend ? (
            <div className="header">
              {!missing ? (
                <h3>Profile Details</h3>
              ) : (
                <h3>
                  Please Update Your Address Before You Book A Lesson At Your
                  Place
                </h3>
              )}
            </div>
          ) : (
            <div className="header">
              <h3>Please add your info before submitting a recomendation.</h3>
            </div>
          )}
          <div className="account__rows">
            {!recommend && (
              <div className="account__row">
                <span className="title">Tour Guide</span>
                <div>
                  <input
                    type="checkbox"
                    value={user.helpGuide}
                    checked={user.helpGuide}
                    name="helpGuide"
                    onChange={(e) => onChange(e)}
                  />
                  <label>Enable Tour Guide</label>
                </div>
              </div>
            )}

            <div className="account__row">
              <span className="title">Profile Photo</span>
              <div className="image">
                <div className="image__selector">
                  {/* <h1>Image uploader</h1> */}
                  <SharedSingleImageUploader
                    onSelectFile={onSelectFile}
                    detectFace
                    preview={recommend ? profileImage : user.profileImage}
                    // tip={`Image size must be  less then ${MAX_SIZE / (1024 * 1024)} MB.`}
                    MAX_SIZE={MAX_SIZE}
                  />
                </div>
              </div>
            </div>

            <div className="account__row">
              <span className="title">Full Name</span>
              <div className="inputs__multiple">
                <input
                  type="text"
                  placeholder="First Name"
                  value={user.name}
                  name="name"
                  onChange={(e) => onChange(e)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={user.lastName}
                  name="lastName"
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            {!user.bySocial ||
              (recommend && (
                <div className="account__row">
                  <span className="title">Email Address</span>
                  <input
                    type="text"
                    placeholder="jhonDoe@example.com"
                    value={user.email}
                    name="email"
                    onChange={(e) => onChange(e)}
                  />
                </div>
              ))}
            {!recommend && (
              <>
                <div className="account__row">
                  <span className="title">Phone Number</span>
                  <input
                    type="text"
                    placeholder="phone number"
                    value={user.phone}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        phone: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
            {/* <div className="account__row">
              <span className="title">Nationality</span>
              <input
                type="text"
                placeholder="Nationality"
                value={user.nationality}
                onChange={(e) =>
                  setUser({
                    ...user,
                    nationality: e.target.value,
                  })
                }
              />
            </div> */}
            {/* <div className="account__row">
              <span className="title">Background Check</span>
              <span>image here</span>
            </div> */}
            {/* <div className="account__row">
              <span className="title">Description</span>
              <textarea
                type="text"
                placeholder="Description"
                defaultValue={newTeacher?.description}
                // value={newTeacher?.description}
                onChange={(e) =>
                  setUser({
                    ...user,
                    description: e.target.value,
                  })
                }
              />
            </div> */}
            {/* <div className="account__row">
              <span className="title">education level</span>
              <span>edu level</span>
            </div>
            <div className="account__row">
              <span className="title">Images</span>
              <span>edu level</span>
            </div>
            <div className="account__row">
              <span className="title">levels</span>
              <span>edu level</span>
            </div>
            <div className="account__row">
              <span className="title">Location</span>
              <span>edu level</span>
            </div>
            <div className="account__row">
              <span className="title">pets</span>
              <span>edu level</span>
            </div> */}
            {/* <div className="account__row">
              <span className="title">Time Zone</span>
              <Select className="account__country" />
            </div> */}
            {/* <div className="account__row">
              <span className="title">Notifications</span>
              <div className="options">
                <input type="checkbox" name="notification" id="" />
                <span>Email</span>
                <input type="checkbox" name="notification" id="" />
                <span>Phone</span>
              </div>
            </div> */}
          </div>

          <div className="account__Settings__btns">
            {!recommend ? (
              <button />
            ) : (
              <button className="delete" onClick={() => onCancelClick()}>
                Cancel
              </button>
            )}

            <button
              className={
                fetching ? "account__submit fetching" : "account__submit"
              }
              onClick={() => onSubmit()}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    )
  );
}
