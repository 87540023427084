import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./TeacherDetails.styles.scss";

export default function TeacherDetails({ dashboard }) {
  const params = useParams();
  const { id } = params;

  return (
    <div className="teacher__details__section">
      <div className="teacher__detail">
        <div className="header">
          <span>Earnings</span>
          <Link to={`/admin/teachers/${id}/payment`} className="flex_center">
            Make Payment
          </Link>
        </div>
        <div className="data">
          <div className="text">
            <p>${(dashboard.teacherEarning / 100).toFixed(2)}</p>
            {/* <span>-$120</span> */}
          </div>
        </div>
      </div>
      <div className="teacher__detail">
        <div className="header">
          <span>Number of students</span>
          {/* <button>View History</button> */}
        </div>
        <div className="data">
          <div className="text">
            <p>{dashboard.numOfStudents}</p>
          </div>
        </div>
      </div>
      <div className="teacher__detail">
        <div className="header">
          <span>Hours Spent</span>
          <Link
            to={`/admin/teachers/${id}/submission`}
            className="flex_center button"
          >
            Submissions
          </Link>
        </div>
        <div className="data">
          <div className="text">
            <p>{dashboard.teacherHours}hrs</p>
          </div>
        </div>
      </div>
    </div>
  );
}
