import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  login,
  logout,
  resetUserState,
} from "../../../ReduxToolkit/Slices/userSlice";
import "./ConfirmPassword.styles.scss";
import {
  deleteStudent,
  resetStudentState,
} from "../../../ReduxToolkit/Slices/studentSlice";
import { setAuthToken } from "../../../ReduxToolkit/customFetch/customFetch";
import { useNavigate } from "react-router-dom";
import { resetTeacherState } from "../../../ReduxToolkit/Slices/teacherSlice";

export default function ConfirmPassword({ setModal }) {
  const dispatch = useDispatch();

  const notify = (message) => toast(message);
  const sendError = (message) => toast.error(message);
  const { student } = useSelector((state) => state.student);

  const { user } = useSelector((state) => state.user);

  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    setAuthToken("delete");
  };

  const handleSignOut = () => {
    logoutUser();
    dispatch(logout());
    dispatch(resetTeacherState());
    dispatch(resetStudentState());
    dispatch(resetUserState());
    navigate("/");
  };

  const confirm = () => {
    if (!password) {
      return sendError("Please enter your password before you continue");
    }
    dispatch(
      login({
        password,
        email: user.email,
      })
    ).then((res) => {
      if (res.type === "login/fulfilled") {
        if (user.role === "TEACHER") {
        } else if (user.role === "STUDENT") {
          dispatch(deleteStudent(student)).then((res) => {
            if (res.type === "deleteStudent/fulfilled") {
              handleSignOut();
            }
          });
        } else {
          return sendError("Error deleting account");
        }
      } else {
        sendError("Incorrect password. For security reasons we signed you out");
        return handleSignOut();
      }
    });
  };

  return (
    <div className="confirm__password">
      <div className="header">
        <h2>Enter your password to deactivate your account</h2>
      </div>
      <div className="input">
        <span>Please enter your password</span>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="confirm__btns">
        <button className="cancel__btn" onClick={() => setModal(false)}>
          Cancel
        </button>
        <button onClick={() => confirm()}>Continue</button>
      </div>
    </div>
  );
}
