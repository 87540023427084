import React from "react";
import suit from "../../../assets/TeacherApp/assets/images/suit.png";
import suit2 from "../../../assets/TeacherApp/assets/images/suit2.png";
import suit3 from "../../../assets/TeacherApp/assets/images/suit3.png";
const WhoIsEducify = () => {
  const cards = [
    {
      title: "Want to increase your income as a tutor",
      text: "Educify is for you if you are looking to increase your income or maximize your earning as a tutor, by opening you up to our global pool of students",
      image: suit,
    },
    {
      title: "Are looking for a community of passionate teachers",
      text: "Meet with academic enthusiasts like yourself passionate about the future of online learning",
      image: suit2,
    },
  ];
  return (
    <div className="who_is_educify">
      <div className="who_is_educify_container">
        <header>
          <h1>
            Who is <span className="gold_text">Educify</span> for?
          </h1>
          <span>Educify is for you, if you;</span>
        </header>
        <div className="big_info_card">
          <div className="col">
            <div className="texts">
              <div className="title">
                <span>Embrace the future of online learning</span>
                <p>
                  If you are passionate about shaping the future of online
                  learning, We provide the perfect opportunity
                </p>
              </div>
            </div>

            <div className="texts texts_sm">
              <div className="title">
                <span>Desire Flexibility</span>
                <p>
                  If you want flexibility, here you have the freedom to set your
                  own work hours and pay rate, decide how many students you want
                  to teach
                </p>
              </div>
            </div>
          </div>
          <img src={suit3} alt="" />
          <div className="texts texts_lg">
            <div className="title">
              <span>Desire Flexibility</span>
              <p>
                If you want flexibility, here you have the freedom to set your
                own work hours and pay rate, decide how many students you want
                to teach
              </p>
            </div>
          </div>
        </div>
        <div className="info_cards">
          {cards.map((el, i) => (
            <InfoCard {...el} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhoIsEducify;

const InfoCard = ({ image, title, text }) => {
  return (
    <div className="info_card">
      <div className="card_title">
        <span>{title}</span>
      </div>
      <div className="text">
        <p>{text}</p>
      </div>
      <img src={image} alt="" />
    </div>
  );
};
