import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { teacherDeleteNotes } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { toast } from "react-toastify";
const NotesListing = ({ showStudents, setShowStudents, notes, setRefresh }) => {
  return (
    <div className={`manage__students homeworks_table`}>
      <div className="header">
        <div className="header__text">
          <span>Notes</span>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Teacher</th>
              {/* <th>Submitted</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {notes?.length > 0 ? (
              notes.map((row, i) => (
                <StudentsNotesRow
                  setShowStudents={setShowStudents}
                  showStudents={showStudents}
                  row={row}
                  key={i}
                  i={i}
                  setRefresh={setRefresh}
                />
              ))
            ) : (
              <p>No notes yet!</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StudentsNotesRow = ({ row, setShowStudents, i, setRefresh }) => {
  const dispatch = useDispatch();
  const handleDeleteNote = (id) => {
    dispatch(teacherDeleteNotes(id)).then((res) => {
      if (res.type.includes("fulfilled")) {
        toast.success("Note was deleted successfully!");
        setRefresh((prev) => !prev);
      } else toast.error("Something went wrong");
    });
  };
  const { user } = useSelector((state) => state.user);

  return (
    <tr key={i}>
      <td>
        <span>{row?.title}</span>
      </td>
      <td>
        <span>{row?.teacher?.user?.name}</span>
      </td>
      <td>
        <div className="gap">
          <button className="accepted" onClick={() => setShowStudents(row)}>
            View Note
          </button>
          {!user?.student?.id && (
            <button
              className="rejected"
              onClick={() => {
                let confirm = window.confirm(
                  "Are you sure you want to delete this note?"
                );
                if (confirm) handleDeleteNote(row.id);
              }}
            >
              Delete
            </button>
          )}
        </div>
      </td>
      {/* <td>
        <div className="action__btns">
          <Link to={`/teachers/homework/edit/${row.id}`}>
            <img src={edit} alt="" />
          </Link>
          <img
            src={del}
            alt=""
            onClick={() => {
              onDelete();
              setIsModalOpen(true);
            }}
          />
        </div>
      </td> */}
    </tr>
  );
};

export default NotesListing;
