import React from "react";
import "./CourseFor.styles.scss";
export default function CourseFor({ target }) {
  return (
    <div className="course__description__container">
      <div className="title">
        <h1>Who this course is for:</h1>
        <div className="content">
          <ul>
            {target.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
