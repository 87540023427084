import React from "react";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import playvid from "../../../../assets/SharedApp/svgs/playvid.svg";
import fileIcon from "../../../../assets/SharedApp/images/file.png";
import "./ContentAccordion.styles.scss";
export default function ContentAccordion({
  title,
  nbLectures,
  rows,
  i,
  setShowModal,
}) {
  const [show, setShow] = useState(false);

  return (
    <div className="content__accordion__container">
      <div className="content__accordion">
        <div
          className="cols"
          style={i === 0 ? { borderRadius: "12px 12px 0px 0px" } : {}}
        >
          <div className="col">
            {show ? (
              <KeyboardArrowUpIcon
                onClick={() => {
                  setShow(!show);
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                onClick={() => {
                  setShow(!show);
                }}
              />
            )}
            <span
              onClick={() => {
                window.scrollTo(0, 0);

                setShowModal(true);
              }}
            >
              {title}
            </span>
          </div>
          <div className="col">
            <ul className="ul__course">
              <li>{nbLectures} lectures</li>
            </ul>
          </div>
        </div>
        <div className={`content ${show ? "show" : ""}`}>
          {!rows && (
            <h3 className="noprev">
              No Free Preview for this chapter, Pruchase to view
            </h3>
          )}
          {rows &&
            rows.map((row, i) => (
              <div
                className="row"
                key={i}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowModal(true);
                }}
              >
                <div className="col">
                  <img
                    src={
                      row.videos.length &&
                      row.videos[0].publicId.includes("videos")
                        ? playvid
                        : fileIcon
                    }
                    alt=""
                  />
                  <span>{row.title}</span>
                </div>
                <div className="col">
                  <span>Preview</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
