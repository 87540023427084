import React from "react";

import "./notifications.styles.scss";

import Pagination from "../../../../Components/Pagination/Pagination";
import NotificationRow from "../../../Components/NotificationRow/NotificationRow";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherNotifications } from "../../../../ReduxToolkit/Slices/teacherSlice";
import { useState } from "react";
import { getStudentNotifications } from "../../../../ReduxToolkit/Slices/studentSlice";
import { useNavigate } from "react-router-dom";
import { getAdminNotifications } from "../../../../ReduxToolkit/Slices/adminSlice";
import Note from "../../../../TeacherApp/Components/Note/Note";

export default function Notifications({ type }) {
  const [notifications, setNotifications] = useState([]);

  const { user } = useSelector((state) => state.user);
  const { student } = useSelector((state) => state.student);
  const { teacher } = useSelector((state) => state.teacher);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === "STUDENT") {
      dispatch(getStudentNotifications(student)).then((res) => {
        setNotifications(res.payload);
      });
    }
    if (user.role === "TEACHER") {
      dispatch(getTeacherNotifications(teacher.id)).then((res) => {
        setNotifications(res.payload);
      });
    }
    if (user.role === "ADMIN") {
      dispatch(getAdminNotifications()).then((res) => {
        setNotifications(res.payload);
      });
    }
  }, []);

  const action = (type) => {
    if (user.role === "TEACHER") {
      if (type === "BOOKING_REQUEST") {
        navigate("/teachers");
      }
    }
  };

  return (
    <div className="notifications__page__outer">
      <Note
        p1={"You can see all your notifications in this section."}
        p2={"you can see the number of notices will be shown to the left."}
      />
      <div className="notifications__page">
        <h1>Notifications</h1>
        <div className="notifications__container">
          {notifications.length > 0 ? (
            notifications.map((row) => (
              <NotificationRow
                type={row.type}
                text={row.message}
                title={row.title}
                time={row.createdAt}
                admin={user.role === "ADMIN"}
                action={action}
              />
            ))
          ) : (
            <p>No notifications at the moment</p>
          )}
        </div>
        {/* <Pagination page={3} /> */}
      </div>
    </div>
  );
}
