import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import verified from "../../../assets/SharedApp/svgs/verified.svg";
import {
  handleSliceChange,
  teacherPostTestLesson,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import VerifyTeacher from "../../Pages/Complete/VerifyTeacher";
const VerifyTeacherAccount = ({ teacher }) => {
  const { refreshTeacher } = useSelector((state) => state.teacher);
  const [video, setVideo] = useState(
    teacher?.testRecord
      ? {
          url: teacher?.testRecord?.url,
          publicId: teacher?.testRecord?.publicId,
        }
      : { url: "", publicId: "" }
  );
  const dispatch = useDispatch();
  const onSubmitLesson = () => {
    if (!video.url) {
      toast.error("Please record a video first!");
    }
    dispatch(teacherPostTestLesson(video)).then((res) => {
      if (res.type === "teacherPostTestLesson/fulfilled") {
        toast.success("Test lesson was submitted, thank you!");
        dispatch(
          handleSliceChange({ name: "refreshTeacher", value: !refreshTeacher })
        );
        setShowUploadVideo(false);
        setVideo({ url: "", publicId: "" });
      }
    });
  };
  const [showUploadVideo, setShowUploadVideo] = useState(false);
  return (
    <div className="verify_teacher_account login__method">
      {!teacher.checkMark ? (
        <>
          <div className="header gap">
            <h2>Get a verified Badge</h2> <img src={verified} alt="" />
          </div>
          <VerifyTeacher
            video={video}
            onSubmitLesson={onSubmitLesson}
            setVideo={setVideo}
          />
        </>
      ) : (
        <div className="header gap">
          <h2>You are verified!</h2> <img src={verified} alt="" />
        </div>
      )}
    </div>
  );
};
export default VerifyTeacherAccount;
