import React from "react";
import Pagination from "../../../../Components/Pagination/Pagination";
import Reviews from "../../Reviews/Reviews";

export default function OnlineCourseReviews({ course }) {
  return (
    <div className="online__course__reviews">
      <Reviews reviews={course.reviews} rating={course.rating} />
    </div>
  );
}
