import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import forgetpassword from "../../../assets/HomeApp/images/forgetpassword.webp";
import { Welcome } from "../../Components/Welcome/Welcome";

import { Forgot_password_text } from "../../../assets/SharedApp/data/Auth";

import { useDispatch, useSelector } from "react-redux";
import {
  login,
  loginUser,
  loginWithCode,
  redirectLink,
  resendCode,
  setUser,
  verifyUserCode,
} from "../../../ReduxToolkit/Slices/userSlice";
import { setStudent } from "../../../ReduxToolkit/Slices/studentSlice";
import {
  logout,
  setAuthToken,
} from "../../../ReduxToolkit/customFetch/customFetch";
import { getTeacherById } from "../../../ReduxToolkit/Slices/teacherSlice";

export default function ForgotPassword({ reset, otp }) {
  return (
    <div className="reset__container">
      <Welcome
        text={
          reset
            ? "Reset Your Password"
            : otp
            ? "Please enter the OTP sent to your email address"
            : Forgot_password_text.welcome
        }
        img={forgetpassword}
      />

      <PasswordContainer reset={reset} otp={otp} />
    </div>
  );
}

const PasswordContainer = ({ reset, otp }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const redirect = useSelector((state) => state.user.redirectLink);
  const [agreed, setAgreed] = useState(false);

  const [code, setCode] = useState("");
  const [busy, setBusy] = useState(false);

  const onSubmit = () => {
    if (!code) {
      return toast.error("Please enter your OTP");
    }
    setBusy(true);
    loginDispatch(user);
  };
  const logoutUser = () => {
    localStorage.clear();
    setAuthToken("delete");
  };

  const handleSignOut = () => {
    logoutUser();
    dispatch(logout());
    navigate("/");
  };

  const loginDispatch = (users) => {
    setBusy(true);
    dispatch(
      loginWithCode({
        loginInput: users,
        verifyCodeInput: {
          email: user.email,
          code,
          recognized: agreed,
        },
      })
    )
      .then((res) => {
        setBusy(false);
        if (typeof res?.payload?.data?.message === "string") {
          return toast.error(res.payload.data.message);
        }
        if (res.payload.data === "Please authenticate") {
          dispatch(setUser(users));
          toast.error("Please enter the code that you've received by mail");
          return navigate("/authenticate");
        }
        if (
          res.payload.data ===
          "Please verify your email address before logging in."
        ) {
          toast.error(res.payload.data);
          setBusy(false);
          dispatch(setUser(users));
          return navigate("/otp");
        }
        if (
          res?.payload?.data?.message ===
          "Your account has been temporarly blocked"
        ) {
          setBusy(false);
          return toast.error("Your account has been temporarly blocked");
        }
        if (res?.payload?.data?.message === "User is not verified") {
          toast.error("Your email address is not verified yet");
          dispatch(setUser({ email: users.email }));
          setBusy(false);
          return navigate("/otp");
        }
        if (res.type.includes("reject")) {
          setBusy(false);
          return toast.error("Invalid credentials");
        }
        if (res.type === "login/fulfilled") {
          if (res.payload.user.role === "ADMIN") {
            dispatch(loginUser());
            dispatch(setUser(res.payload.user));
            setBusy(false);
            return navigate("/admin", { replace: true });
          }
          if (res.payload.user.role === "STUDENT") {
            dispatch(setStudent(res.payload.user.student.id));
            dispatch(setUser(res.payload.user));
            dispatch(loginUser());
            if (redirect) {
              dispatch(redirectLink(""));
              setBusy(false);
              return navigate(redirect, { replace: true });
            } else {
              navigate("/students", { replace: true });
            }
          } else if (res.payload.user.role === "TEACHER") {
            if (!res.payload.user.teacher?.id) {
              handleSignOut();
              return toast.error("Your account has been suspended");
            }
            dispatch(setUser(res.payload.user));
            dispatch(loginUser());

            dispatch(getTeacherById(res.payload.user.teacher?.id))
              .then((res) => {
                setBusy(false);
                navigate("/teachers", { replace: true });
              })
              .catch((err) => {});
          }
        }
        //  else sendToast(res.payload);
      })
      .catch((err) => console.log("error logging in", err));
  };

  const onResend = () => {
    dispatch(resendCode({ email: user.email })).then((Res) =>
      toast.success(Res?.payload?.data)
    );
  };
  return (
    <div className="reset__password">
      <div className="inputs">
        <div>
          <h1>Please Verify your Login</h1>
          {!otp && (
            <p>
              <span>{Forgot_password_text.subHeader[0]}</span>
              <span className="blues">
                <Link to="/login"> {Forgot_password_text.subHeader[1]}</Link>
              </span>
            </p>
          )}
        </div>
        <div className="email">
          <h4>Code</h4>
          <input
            type="text"
            name="email"
            value={code}
            placeholder={"Enter the code that was sent to your email"}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div className="agreement">
          <input
            type="checkbox"
            onChange={(e) => setAgreed(!agreed)}
            value={agreed}
            checked={agreed}
          />
          <span>Mark this device as recognized for future logins</span>
        </div>
        <div onClick={() => onSubmit()} className="signin__btn">
          <button className={busy ? "fetching " : ""}>Submit</button>
        </div>
        <span className="resend">
          <span>Didn't receive a code?</span>
          {
            <span
              className={busy ? "fetching blues pointer" : "blues pointer"}
              onClick={() => onResend()}
            >
              {" "}
              Resend
            </span>
          }
        </span>
      </div>
    </div>
  );
};
