import React from "react";

import moment from "moment";

import "./LessonTable.styles.scss";

import xbtn from "../../../assets/AdminApp/svgs/xbtn.svg";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import { Link } from "react-router-dom";

export default function LessonTable({ lessons }) {
  const dateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  return (
    lessons && (
      <div className={`manage__lessons__table`}>
        <div className="header">
          <div className="header__text">
            <span>Lessons</span>
            {/* <p>More than 10+ lessons</p> */}
          </div>
          {/* <select>
            <option value="">Sort</option>
            <option value="">Sort</option>
            <option value="">Sort</option>
          </select> */}
        </div>
        <div className="students__table">
          <table>
            <tr>
              <th>LESSON</th>
              <th>STATUS</th>
              <th>TEACHER</th>
              {/* <th>ACTION</th> */}
            </tr>
            {lessons.map((lesson) =>
              lesson?.bookings.map((row, j) => (
                // <p>{j}</p>
                <tr key={j}>
                  <td>
                    <div className="student__cell">
                      <div className="name">
                        <span>{lesson.title}</span>
                        {/* {row?.date.map((date, i) => (
                          <p key={i}>{dateFormat(date)}</p>
                        ))} */}
                        <p>hard code time</p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <button className={row.status?.toLowerCase()}>
                      {row?.status?.toLowerCase()}
                    </button>
                  </td>
                  <td>
                    <Link
                      className="teacher__row__cell"
                      to={`/admin/teachers/${lesson?.teacher?.id}`}
                    >
                      <img
                        src={lesson?.teacher?.user?.profileImage?.url}
                        alt=""
                      />
                      <div className="text">
                        <span>
                          {lesson?.teacher?.user?.name}{" "}
                          {lesson?.teacher?.user?.lastName}
                        </span>
                        <p>Teacher</p>
                      </div>
                    </Link>
                  </td>
                  <td>
                    {/* <div className="action__btns">
                      {row.status === "PENDING" || row.status === "REJECTED" ? (
                        <button className="assign">Reassign</button>
                      ) : (
                        <button className="accepted"> Assigned </button>
                      )}
                    </div> */}
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>
      </div>
    )
  );
}
