import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "./FAQ.styles.scss";

import search from "../../assets/Search.svg";
import FAQSection from "../../Components/FAQSection.js/FAQSection";
import SocialShare from "../../../SharedApp/Components/SocialShare/SocialShare";
import { getFAQs } from "../../../ReduxToolkit/Slices/adminSlice";
import { useLocation } from "react-router-dom";

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();

  let locationTeacher = location.pathname.includes("teachers") ? true : false;

  useEffect(() => {
    dispatch(
      getFAQs(locationTeacher ? "userType=TEACHER" : "userType=STUDENT")
    ).then((data) => {
      setFaqs(data.payload.faqs);
    });
  }, []);

  return (
    <div className="FAQ_Container">
      {/* <div className="Banner">
        <h3>How Can We Help You?</h3>
        <div className="searchbox flex_center">
          <img className="search" src={search} />
          <input type="text" placeholder="ask a question" />
        </div>
      </div> */}

      <div className="FAQSection_Container">
        <div className="faqs__header">
          <h4>Frequently Asked Questions</h4>
          <p>
            First, the process of using Educify is quite very easy, that’s why
            we’ve taken time to sieve out prevalant occuring questions to guide
            you on your track
          </p>
        </div>
        <div className="faqs">
          {faqs &&
            faqs.map((faq) => (
              <FAQSection
                title={"Registering for Lessons"}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
        </div>
      </div>
      <SocialShare />
    </div>
  );
};
export default FAQ;
