import React from "react";
import playvid from "../../../../assets/SharedApp/svgs/playvid.svg";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { formatTime } from "../../../../hooks";

export default function LessonRow({
  setSelected,
  selected,
  title,
  sec,
  watched,
}) {
  let type =
    sec?.videos.length && sec.videos[0].url.includes("pdf")
      ? "file"
      : sec?.videos.length && sec.videos[0].publicId.includes("videos")
      ? "video"
      : sec?.videos.length && sec.videos[0].publicId.includes("images")
      ? "image"
      : sec.description && !sec.description.includes("codeHereToBeAdded")
      ? "code"
      : "description";
  return (
    <div
      className={`row ${selected.id === sec.id ? "selected" : ""}`}
      onClick={() => setSelected(sec)}
    >
      <ol>
        <li>
          <div className="sub">
            <div className="flex_center">
              {type === "video" && <img src={playvid} alt="" />}
              <p>{title}</p>
            </div>
            <div className="flex_space">
              {type === "video" && (
                <p>({formatTime(sec?.videos[0]?.videoLength)})</p>
              )}
              {watched && (
                <CheckCircleOutlineIcon
                  sx={{ color: "green", fontSize: 20, marginLeft: "20px" }}
                />
              )}
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
}
