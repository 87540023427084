import React, { useState } from "react";
import "./MailTemplate.styles.scss";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function EditFaq() {
  const [text, setText] = useState("");
  return (
    <div className="mail__template">
      <div className="multi__input">
        <div className="faq__title">
          <span>Title</span>
          <select name="" id="">
            <option value="">Question</option>
            <option value="">Question</option>
            <option value="">Question</option>
            <option value="">Question</option>
          </select>
        </div>
        <div className="faq__user__type">
          <span>User Type</span>
          <select name="" id="">
            <option value="">Teacher</option>
            <option value="">Teacher</option>
            <option value="">Teacher</option>
            <option value="">Teacher</option>
          </select>
        </div>
      </div>
      <div className="text__actions">
        <ReactQuill
          className="rich__text"
          theme="snow"
          value={text}
          onChange={setText}
          placeholder="Please Enter The Template Here..."
        />
      </div>
      <div className="content__btn">
        <button className="cancel">Discard</button>
        <button className="update">Update</button>
      </div>
    </div>
  );
}
