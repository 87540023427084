import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import redheart from "../../../../assets/TeacherApp/assets/svgs/redheart.svg";
import heart from "../../../../assets/TeacherApp/assets/svgs/Shape.svg";
import img1 from "../../../../assets/TeacherApp/assets/svgs/warningg.svg";
import img2 from "../../../../assets/TeacherApp/assets/svgs/world.svg";
import stars from "../../../../assets/TeacherApp/assets/svgs/rating.svg";
import star from "../../../../assets/TeacherApp/assets/svgs/rating2.svg";
// import PreviewModal from "../PreviewModal/PreviewModal";
// import StudentsBought from "../StudentsBought/StudentsBought";
import { useDispatch, useSelector } from "react-redux";
import {
  addCourseToCart,
  handleCoursesStateChange,
  FavouriteCourse,
  getStudentFavCourses,
  unFavouriteCourse,
} from "../../../../ReduxToolkit/Slices/coursesSlice";

import { toast } from "react-toastify";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";

export default function OverViewHeader({ course, setShowModal, showModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { student } = useSelector((state) => state.student);
  const { logout, user } = useSelector((state) => state.user);
  const { cartItems, refreshCart } = useSelector((state) => state.courses);

  let copied =
    cartItems.length &&
    cartItems.filter((item) => item.course.id === course.id);

  const addToCart = () => {
    if (logout) {
      return navigate("/login");
    } else {
      !copied.length &&
        dispatch(
          addCourseToCart({
            studentId: student,
            courseId: course.id,
          })
        ).then(() => handleClick());
    }
  };

  const handleClick = () => {
    dispatch(
      handleCoursesStateChange({ name: "refreshCart", value: !refreshCart })
    );
  };

  const onBuyNow = () => {
    if (logout) {
      return navigate("/login");
    } else {
      navigate(`/courses/${id}/buy`);
    }
  };

  const [fav, setfav] = useState(false);

  const addToFav = () => {
    if (logout) {
      return navigate("/login");
    }
    dispatch(
      FavouriteCourse({
        studentId: student,
        courseId: course.id,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled")) {
        setfav(true);
        toast.success("Course Added To Favourites");
      } else {
        toast.error("Something Went Wrong.");
        setfav(false);
      }
    });
  };
  const unFavCourse = () => {
    dispatch(
      unFavouriteCourse({
        studentId: student,
        courseId: course.id,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled")) {
        setfav(false);
        toast.success("Course Removed From Favourites");
      } else {
        setfav(true);
        toast.error("Something Went Wrong.");
      }
    });
  };

  useEffect(() => {
    !logout &&
      user.role === "STUDENT" &&
      dispatch(getStudentFavCourses(student)).then((res) => {
        const found = res.payload.find((crs) => crs.id === course.id);
        if (found) setfav(true);
        else setfav(false);
      });
  }, [fav, course]);

  return (
    <>
      <div className="overview__header__outer">
        <div className="overview__header">
          <div className="col1">
            <div className="page__location">
              <p>Home</p>
              <span>/ Course Overview</span>
            </div>
            <div className="course__video__mobile view__mobile">
              <div
                className="preview__image"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowModal(true);
                }}
              >
                <img src={course?.profileImage?.url} alt="" />
              </div>
            </div>
            <div className="title">
              <h2>{course?.title}</h2>
            </div>
            <div className="price">
              <ExchangeRateHook
                price={
                  course.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                }
              />
            </div>
            <div className="subtitle">
              <p>{course?.description}</p>
            </div>
            <div className="rating">
              <div className="stars">
                <div className="star__rate">
                  {course.id &&
                    Array.from(Array(parseInt(course?.rating))).map((el) => (
                      <img src={star} alt="" />
                    ))}
                </div>
                <p>Best Rated</p>
              </div>
              {/* <div className="nb__rating">
              <span>(120,00 rating)</span>
            </div> */}
              {course?.students?.length > 0 && (
                <div className="nb__students">
                  <span>{course?.students?.length} Students</span>
                </div>
              )}
            </div>
            <div className="author">
              <p>Created by </p>
              <Link to={`/courses/instructor/${course?.teacher?.id}`}>
                <span>{course?.teacher?.user?.name}</span>
              </Link>
            </div>
            <div className="extra__info">
              <div className="update">
                <img src={img1} alt="" />
                {course.updatedAt && (
                  <span>
                    Last Updated {moment(course.updatedAt).format("DD/MM/YYYY")}
                  </span>
                )}
              </div>
            </div>
            <div className="card__buttons show__sm__only">
              <div className="row1">
                <button
                  onClick={() => addToCart()}
                  className="animatedCopy flex_center"
                >
                  {copied && copied.length > 0 ? (
                    <span>Added to cart</span>
                  ) : (
                    <span>Add to cart</span>
                  )}
                  {copied && copied.length > 0 ? (
                    <div class="wrapper">
                      {" "}
                      <svg class="animated-check" viewBox="0 0 24 24">
                        <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
                      </svg>
                    </div>
                  ) : null}
                </button>
                <div
                  className="fav__btn"
                  onClick={() => {
                    if (fav) {
                      unFavCourse();
                      // setRefresh(!refresh);
                    } else {
                      setfav(true);
                      addToFav();
                      // setRefresh(!refresh);
                    }
                  }}
                >
                  <img src={fav ? redheart : heart} alt="" />
                </div>
              </div>
              {copied?.length > 0 && (
                <div className="row2">
                  <button onClick={() => onBuyNow()}>Buy Now</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
