import React, { useState } from "react";
import "./ManageStudents.styles.scss";
import tutor from "../../../assets/SharedApp/images/tutor.webp";

import Pagination from "../../../Components/Pagination/Pagination";
import StudentsRow from "./StudentsRow";
import { Link, useLocation } from "react-router-dom";

export default function ManageStudents({
  className,
  pagination,
  students,
  filters,
  setFilters,
  viewAll,
  studentsCount,
  dashboard,
  page,
  currentPagination,
  setCurrentPagination,
  totalData,
  dataRendered,
}) {
  const [current, setCurrent] = useState(1);
  // const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const handleCurrentClick = (i) => {
    setCurrent(i);
    setCurrentPage(i);
  };

  const location = useLocation();

  let assign = location.pathname.includes("assign") ? true : false;
  return (
    <div className={`manage__students ${className ? className : ""}`}>
      <div className="header">
        <div className="header__text">
          <span>Students</span>
          <p>
            More than <span>{dashboard}</span> students
          </p>
        </div>
        <div className="students__actions">
          {viewAll && (
            <Link className="select flex_center" to="/admin/students">
              View All
            </Link>
          )}
          <div className="search__students">
            <input
              type="text"
              placeholder="Search students"
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>
                <span>Students</span>
              </th>
              <th>{assign ? "Assign" : "Lessons"}</th>
              {!assign && <th>STATUS</th>}
              {!assign && <th>ACTION</th>}
            </tr>
          </thead>
          <tbody>
            {students &&
              students.length &&
              students.map((row, i) => (
                <StudentsRow row={row} key={i} i={i} assign={assign} />
              ))}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination
          page={page}
          setCurrentPage={setCurrentPagination}
          currentPage={currentPagination}
          totalData={totalData}
          dataRendered={dataRendered}
        />
      )}
    </div>
  );
}
