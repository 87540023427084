import React from "react";
import "./TeacherStudents.styles.scss";
import dots from "../../../assets/AdminApp/svgs/Dots.svg";
import tutor from "../../../assets/SharedApp/images/default.webp";
import { useNavigate } from "react-router-dom";

export default function TeacherStudents({ contacts }) {
  const navigate = useNavigate();
  const uniqueIds = new Set();

  const uniqueArray =
    contacts.length > 1
      ? contacts.filter((obj) => {
          const isUnique = !uniqueIds.has(obj.id);
          uniqueIds.add(obj.id);
          return isUnique;
        })
      : contacts;
  return (
    <div className="teacher__students">
      <div className="header">
        <h1>Students</h1>
        <select>
          <option value="">Sort</option>
        </select>
      </div>
      {contacts.length > 0 &&
        uniqueArray.map((contact, i) => (
          <div
            className="student__row"
            key={i}
            onClick={() => navigate(`/admin/students/${contact.id}`)}
          >
            <div className="std__col">
              <img
                src={
                  contact.user.profileImage?.url
                    ? contact.user.profileImage?.url
                    : tutor
                }
                alt=""
                className="std__img"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = tutor;
                }}
              />
              <div className="name">
                <span>
                  {contact.user.name} {contact.user.lastName}
                </span>
                <p>{contact.user.address.street}</p>
              </div>
            </div>
            <img src={dots} alt="" />
          </div>
        ))}
    </div>
  );
}
