import React, { useState } from "react";
import "./CourseCard.styles.scss";
import courseimg from "../../../../assets/TeacherApp/assets/images/courseimg.webp";
// import stars from "../../../../assets/TeacherApp/assets/svgs/rating.svg";
import stars from "../../../../assets/TeacherApp/assets/svgs/Star.svg";
import emptyStar from "../../../../assets/TeacherApp/assets/svgs/emptyStar.svg";
import { LinearProgress, Rating } from "@mui/material";
import trash from "../../../../assets/SharedApp/svgs/trash-2.svg";
import edit from "../../../../assets/SharedApp/svgs/edit-list.svg";
import threePoints from "../../../../assets/SharedApp/svgs/3points.svg";
import bestseller from "../../../../assets/SharedApp/svgs/bestseller.svg";
import notfave from "../../../../assets/SharedApp/svgs/notfave.svg";
import favcourse from "../../../../assets/SharedApp/svgs/favcourse.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  addCourseToFavorites,
  RemoveCourseFromFav,
} from "../../../../ReduxToolkit/Slices/studentSlice";
import { useDispatch, useSelector } from "react-redux";

import notfoundimg from "../../../../assets/AdminApp/images/404img.webp";
import { toast } from "react-toastify";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";

export default function CourseCard({
  noShadow,
  isBought,
  editList,
  favorited,
  editable,
  isBestSeller,
  percentage,
  price,
  title,
  rating,
  image,
  name,
  id,
  mylist,
}) {
  const student = useSelector((state) => state.user);

  // const studentId = student.user.student.id;

  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const navigateCourse = () => {
    mylist ? navigate(`/courses/${id}/start`) : navigate(`/courses/${id}`);
  };

  const dispatch = useDispatch();
  const FavCourse = () => {
    const ids = {
      course: id,
      student: "cle4ib8d7006xigeg7s9moeoo",
    };
    dispatch(addCourseToFavorites(ids)).then((res) => {
      res.type.includes("fulfilled")
        ? toast.success("Added to favorites!")
        : toast.error("something went wrong");
    });
  };
  const unFavCourse = () => {
    const ids = {
      course: id,
      student: student.user.student.id,
    };
    dispatch(RemoveCourseFromFav(ids)).then((res) =>
      res.type.includes("fulfilled")
        ? toast.success("Removed from favorites!")
        : toast.error("something went wrong")
    );
  };
  return (
    <div
      className={`course__card__base ${editList ? "container__padding" : ""}`}
      style={noShadow && { "box-shadow": "none" }}
    >
      {editList && (
        <div className="edit__course__list">
          <div className="name">
            <span>Name Your List</span>
            <div className="actions">
              <img src={trash} alt="" />
              <img src={edit} alt="" />
            </div>
          </div>
          <p>Why Creating this list eg to start</p>
        </div>
      )}
      <div className="image">
        {/* <img
          src={favorited ? favcourse : editable ? threePoints : notfave}
          alt=""
          className="img__action"
          onClick={() => (!favorited ? FavCourse() : unFavCourse())}
        /> */}
        {isBestSeller && (
          <img
            src={bestseller}
            alt=""
            className="img__action__bestSeller"
            onClick={() => navigateCourse()}
          />
        )}
        <img
          src={image ? image : notfoundimg}
          alt=""
          className="profileImage"
          onClick={() => navigateCourse()}
        />
      </div>
      <div className="course__info" onClick={() => navigateCourse()}>
        <div className="title">
          <span>{title}</span>
        </div>
        <div className="subtitle">
          <span>{name}</span>
        </div>
        <>
          <div className="rating">
            <div className="col">
              <span className="rating__nb">{rating.toFixed(1)}</span>
            </div>
            <div className="col">
              {rating > 0
                ? Array.from(Array(parseInt(rating))).map((el) => (
                    <img src={stars} alt="" />
                  ))
                : Array.from(Array(5)).map((el) => (
                    <img src={emptyStar} alt="" />
                  ))}
            </div>
            {/* <div className="col">
                <span className="total__rating">({rating})</span>
              </div> */}
          </div>
          <div className="buy">
            {price && (
              <div className="price">
                <ExchangeRateHook price={price} />
              </div>
            )}
            <Link to={`/courses/${id}`}>
              <button>View</button>
            </Link>
          </div>
        </>
        {mylist ? (
          <div className="is__bought__container">
            <div className="prog__bar">
              <LinearProgress variant="determinate" value={percentage} />
            </div>
            <div className="is__bought">
              <div className="percentage__complete">
                <span>{percentage}% Complete</span>
              </div>
              <div className="rate__course">
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
                <p>Leave Rating</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
