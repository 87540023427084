import React, { useState } from "react";
import "./Installation.styles.scss";
import ios from "../../../assets/AdminApp/svgs/ios.svg";
import phone from "../../../assets/AdminApp/svgs/phone.svg";
import android from "../../../assets/AdminApp/svgs/android.svg";
import mail from "../../../assets/AdminApp/svgs/mail.svg";
import InstallationAccordion from "../../Components/InstallationAccordion/InstallationAccordion";
import DangerNote from "../../Components/DangerNote/DangerNote";
export default function Installation() {
  const data = [
    {
      title: "SMS Configuration",
      input1Ttitle: "Twilio Account SID",
      input2Ttitle: "Twilio Auth Token",
      input3Ttitle: "Twilio Number",
      icon: phone,
    },
    {
      title: "MaliGun Configuration",
      input1Ttitle: "Mail Gun ApiKey",
      input2Ttitle: "Mail Gun Domail",
      input3Ttitle: "MailGun From Id",
      icon: mail,
    },
    {
      title: "Android App URL",
      input1Ttitle: "Android Student App URL",
      input2Ttitle: "Twilio Auth Token",
      icon: android,
    },
    {
      title: "IOS pp URL",
      input1Ttitle: "IOS Student App URL",
      input2Ttitle: "IOS Teacher App URL",
      icon: ios,
    },
  ];
  return (
    <div className="admin__installation__container">
      <DangerNote />
      <div className="admin__installation">
        <div className="header">
          <h1>Installation Settings</h1>
        </div>
        {data &&
          data.map((section) => <InstallationAccordion section={section} />)}
      </div>
    </div>
  );
}
