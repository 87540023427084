import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, {
  customFetchToken,
  wiseFetch,
} from "../customFetch/customFetch";

const initialState = {
  lessonId: "00000",
};

export const getLessonPackages = createAsyncThunk(
  "getLessonPackages",
  async (item, thunkApi) => {
    let url = `/payments/packages/${item.id}/${item.selectedPlace}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getRequirements = createAsyncThunk(
  "getRequirements",
  async (item, thunkApi) => {
    let url = `/account-requirements?source=CAD&target=${item}&sourceAmount=1000`;
    try {
      const resp = await wiseFetch.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addToWallet = createAsyncThunk(
  "addToWallet",
  async (item, thunkApi) => {
    let url = `/payments/wallet/`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setLessonId: (state, action) => {
      state.lessonId = action.payload;
    },
  },
});
export const { setLessonId } = paymentSlice.actions;

export default paymentSlice.reducer;
