import React, { useEffect, useState } from "react";
import "../../../StudentApp/Components/PaymentAccordion/Accordion.styles.scss";

import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";

import "./TeacherPayment.styles.scss";

import circle from "../../../assets/StudentApp/svgs/circle.svg";
import empty from "../../../assets/StudentApp/svgs/empty.svg";
import Note from "../../Components/Note/Note";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentMethod,
  setPaymentMethod,
  updatePaymentMethod,
} from "../../../ReduxToolkit/Slices/userSlice";
import BankTransfer from "../../../SharedApp/Components/BankTransfer/BankTransfer";
import { toast } from "react-toastify";

export default function Payments() {
  const { user } = useSelector((state) => state.user);

  const { teacher } = useSelector((state) => state.teacher);

  const [showGuide, setShowGuide] = useState(true);
  const [hasPayment, setHasPayment] = useState(false);
  const [method, setMethod] = useState({
    paymentMethod: "PAYPAL",
    receiveMethod: "",
    cardId: "",
    accountHolderName: "",
    paypalGmail: "",
    userId: user.id,
    currency: "USD",
    type: "",
    last4: "",
    bankDetails: {
      legalType: null,
      accountNumber: null,
      sortCode: null,
      abartn: null,
      accountType: null,
      bankgiroNumber: null,
      ifscCode: null,
      bsbCode: null,
      institutionNumber: null,
      transitNumber: null,
      phoneNumber: null,
      bankCode: null,
      russiaRegion: null,
      routingNumber: null,
      branchCode: null,
      cpf: null,
      cardNumber: null,
      idType: null,
      idNumber: null,
      idCountryIso3: null,
      idValidFrom: null,
      idValidTo: null,
      clabe: null,
      swiftCode: null,
      dateOfBirth: null,
      clearingNumber: null,
      bankName: null,
      branchName: null,
      businessNumber: null,
      province: null,
      city: null,
      rut: null,
      token: null,
      cnpj: null,
      payinReference: null,
      pspReference: null,
      orderId: null,
      idDocumentType: null,
      idDocumentNumber: null,
      targetProfile: null,
      taxId: null,
      iban: null,
      bic: null,
      IBAN: null,
      BIC: null,
      interacAccount: null,
      address: {
        country: null,
        countryCode: null,
        firstLine: null,
        postCode: null,
        city: null,
        state: null,
      },
    },
  });

  const dispatch = useDispatch();

  const handleMethodChange = (e) => {
    const { name, value, refreshRequirementsOnChange } = e.target;
    if (name === "receiveMethod" && method[name] === value) {
      return setMethod({
        ...method,
        [name]: "",
      });
    }
    setMethod({
      ...method,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getPaymentMethod()).then((res) => {
      if (res.payload.length) {
        setMethod(res.payload[0]);
        setHasPayment(true);
      } else {
        setHasPayment(false);
      }
    });
  }, []);

  const onSubmit = (e) => {
    let object = method;
    delete object.wiseId;
    if (hasPayment) {
      dispatch(updatePaymentMethod(object)).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Payment method updated successfully");
        } else toast.error("Error updating Payment");
      });
    } else {
      dispatch(setPaymentMethod(object)).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Payment method updated successfully");
        } else toast.error("Error updating Payment");
      });
    }
  };

  const steps = [
    {
      element: ".payments_guide_container",
      title: "Payment Methods",
      intro: `Here you can view and change the way you get paid, either by a Bank transfer or by Paypal`,
    },
    {
      element: ".bank_acc",
      title: "Bank transfer",
      intro: `you should enter your bank details`,
    },
    {
      element: ".expand",
      title: "Expand",
      intro: `Click the expand button to reveal more information`,
    },
    {
      element: ".paypal_acc",
      title: "Paypal",
      intro: `you should enter your paypal details`,
    },
  ];

  return (
    <div className="teacher__payment">
      <Note
        title={"We need your attention!"}
        p1={"To start receiving your payment , please add a payment method"}
      />
      {showGuide && (
        <Steps
          enabled={user.helpGuide}
          steps={steps}
          initialStep={0}
          onExit={() => setShowGuide(false)}
          options={{ exitOnOverlayClick: false }}
        />
      )}
      <div className="container payments_guide_container">
        <div className="title">
          <h3>Payment Methods</h3>
        </div>
        <div className="inner__container">
          <Accordion
            name="BANK_TRANSFER"
            method={method}
            isBank={method.receiveMethod === "BANK_TRANSFER"}
            handleMethodChange={handleMethodChange}
            setMethod={setMethod}
            onSubmit={onSubmit}
          />
          <Accordion
            isBank={method.receiveMethod === "BANK_TRANSFER"}
            method={method}
            handleMethodChange={handleMethodChange}
            onSubmit={onSubmit}
            setMethod={setMethod}
            name="PAYPAL"
          />
        </div>
      </div>
    </div>
  );
}

const Accordion = ({
  name,
  isBank,
  handleMethodChange,
  method,
  onSubmit,
  setMethod,
}) => {
  let toggle = name === method.receiveMethod;

  return (
    <div className="accordion">
      <div
        className={`item ${
          name === "BANK_TRANSFER" ? "bank_acc" : "paypal_acc"
        }`}
      >
        <div
          className="accordion__title"
          onClick={() =>
            handleMethodChange({
              target: {
                name: "receiveMethod",
                value: name,
              },
            })
          }
        >
          <div className="col1">
            <div className="expand">
              <span> {toggle ? "-" : "+"}</span>
            </div>
            <span className="teacherPaymentName">{name}</span>
          </div>
          {toggle ? <img src={circle} alt="" /> : <img src={empty} alt="" />}
        </div>

        {!isBank ? (
          <div className={`content ${toggle ? "show" : ""}`}>
            <div className="inputs">
              {/* <div className="input">
                <h4>Name</h4>
                <input type="text" placeholder="Enter Your Full Name" />
              </div> */}
              <div className="input">
                <h4>Name</h4>
                <input
                  type="text"
                  placeholder="Enter your full name"
                  name="accountHolderName"
                  value={method.accountHolderName}
                  onChange={(e) => handleMethodChange(e)}
                />
              </div>
              <div className="input paypal_settings">
                <h4>Email Address</h4>
                <input
                  type="text"
                  placeholder="Enter your email address"
                  name="paypalGmail"
                  value={method.paypalGmail}
                  onChange={(e) => handleMethodChange(e)}
                />
              </div>
              <button className="btn__primary" onClick={() => onSubmit()}>
                Submit
              </button>
            </div>
          </div>
        ) : (
          <BankTransfer
            handleMethodChange={handleMethodChange}
            toggle={toggle}
            method={method}
            setMethod={setMethod}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
};
