import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cours from "../../../../assets/SharedApp/images/cour.webp";
import defaultImage from "../../../../assets/AdminApp/images/defautImage.jpg";
import { getStudentCoursesById } from "../../../../ReduxToolkit/Slices/coursesSlice";
import "./HoverCart.styles.scss";
import { ExchangeRateHook } from "../../../../ExchangeRateHook";
export default function HoverCart({ onMouseOut, courses, total }) {
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.student);

  return (
    <div className="hover__cart" onMouseOut={() => onMouseOut()}>
      <div className="faved__courses">
        {/* <p className="empty">Your cart is empty</p> */}
        {courses &&
          courses.length > 0 &&
          courses.map((course, i) => (
            <CartCourse
              onMouseOut={onMouseOut}
              key={i}
              course={course.course}
            />
          ))}

        {cartItems &&
          cartItems.length > 0 &&
          cartItems.map((lesson, i) => (
            <CartCourse
              onMouseOut={onMouseOut}
              key={i}
              course={lesson.lesson}
              lesson={lesson.lesson}
              isLesson
            />
          ))}
      </div>
      <div className="cart__btn">
        {/* <ExchangeRateHook price={total} /> */}
        <button className="gold__lg" onClick={() => navigate("/cart")}>
          Go To Cart
        </button>
        {/* <button className="empty__lg">Explore Courses</button> */}
      </div>
    </div>
  );
}

const CartCourse = ({ course, onMouseOut, isLesson, lesson }) => {
  const navigate = useNavigate();

  const courseDiscount =
    !isLesson &&
    course?.initialPriceAfterFeeAfterAdminDiscountAfterCoupon &&
    course?.initialPriceAfterFeeAfterAdminDiscountAfterCoupon !==
      course?.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon;

  let lessonImage = lesson?.images?.length
    ? lesson.images[0].url
    : defaultImage;
  return (
    (course?.id || lesson?.id) && (
      <div
        className="faved__course"
        onClick={() => {
          navigate(
            isLesson ? `/tutor/${lesson?.teacher?.id}` : `/courses/${course.id}`
          );
          onMouseOut();
        }}
      >
        <img src={isLesson ? lessonImage : course?.profileImage?.url} alt="" />
        <div className="course__details">
          <h4>{isLesson ? lesson?.title : course.title} </h4>
          <p>{course?.teacher?.user?.name}</p>
          {/* {!isLesson && courseDiscount ? (
            <div>
              <span className="oldprice">
                <ExchangeRateHook
                  price={
                    course.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                  }
                />
              </span>
              <span>
                <ExchangeRateHook
                  price={
                    course.initialPriceAfterFeeAfterAdminDiscountAfterCoupon
                  }
                />
              </span>
            </div>
          ) : (
            <span>
              <ExchangeRateHook
                price={
                  isLesson
                    ? 123
                    : course.initialPriceAfterFeeBeforeAdminDiscountBeforeCoupon
                }
              />
            </span>
          )} */}
        </div>
      </div>
    )
  );
};
