import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherById } from "../../../ReduxToolkit/Slices/teacherSlice";
import { updateTeacher } from "../../../ReduxToolkit/Slices/adminSlice";
import { toast } from "react-toastify";
import { options } from "../../Pages/SignupDetails/languages";

import Select from "react-select";
import SharedMultipleFileUploader from "../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import SharedVideoUploader from "../../../Components/SharedVideoUploader/SharedVideoUploader";
import Toggle from "react-toggle";

import "./updateTeacher.styles.scss";
import BgCheck from "../LessonInput/BgCheck";
import SharedSingleImageUploader, {
  GoodToKnow,
} from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import Note from "../Note/Note";

export default function ProfileDetails({ missing }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [fetching, setFetching] = useState(false);
  const [busy, setBusy] = useState(false);
  const [isYoutube, setIsYoutube] = useState(false);
  const [initialVideo, setInitialVideo] = useState([]);
  const teachers = useSelector((state) => state.teacher);
  const [teacher, setTeacher] = useState(teachers.teacher);

  const [subjects, setSubjects] = useState([]);
  const [search, setSearch] = useState("");
  const fileTypes = ["JPEG", "webp", "JPG", "PNG"];
  const fileTypes2 = ["JPEG", "webp", "JPG", "PDF", "PNG"];
  const location = teacher?.location;
  const levels = teacher?.levels;
  const languages = teacher?.languages;

  // const selectedSubjects = !teacher.subjects
  //   ? []
  //   : false
  //   ? teacher.subjects.map((sub) => (sub?.name ? sub.name : sub))
  //   : teacher.subjects;
  // const navigate = useNavigate()
  useEffect(() => {
    dispatch(getTeacherById(teacher?.id)).then((res) => {
      setTeacher(res.payload);
      setInitialVideo([res.payload?.videos[res.payload?.videos.length - 1]]);
    });
  }, []);

  const checkLanguage = (element) =>
    languages.filter((language) => {
      if (language.name === element.value) {
        return element;
      } else return;
    });

  const languageValue = options.filter((element) => {
    if (
      languages &&
      languages.length &&
      checkLanguage(element).length &&
      checkLanguage(element)[0].name === element.value
    )
      return element;
  });
  const uniqueLanguages = languageValue.filter(
    (obj, index, self) => index === self.findIndex((t) => t.value === obj.value)
  );
  const updateTeachers = () => {
    setBusy(true);

    dispatch(
      updateTeacher({
        teacher: {
          title: teacher.title,
          description: teacher.description,
          hourlyRate: teacher.hourlyRate,
          skills: ["teacher.skills"],
          experience: {
            title: teacher?.experience?.title ? teacher?.experience?.title : "",
            qualifications: teacher?.experience?.qualifications
              ? teacher?.experience?.qualifications
              : ["none"],
            certifications: teacher?.experience?.certifications
              ? teacher?.experience?.certifications
              : [{ url: "", publicId: "", fileName: "" }],
            experienceYears: teacher?.experience?.experienceYears
              ? teacher?.experience?.experienceYears
              : 0,
          },
          pets: teacher?.pets,
          background: {
            status: teacher.background?.status
              ? teacher.background?.status
              : "UNCHECKED",
            image: teacher.background?.image
              ? teacher.background?.image
              : { url: "", publicId: "" },
          },
          educationLevel: teacher?.educationLevel,
          images: teacher.images.map((image) => {
            return {
              url: image?.url ? image?.url : "",
              publicId: image?.publicId ? image.publicId : "",
              id: image?.id ? image.id : "",
            };
          }),
          videos: teacher.videos.map((image) => {
            return {
              url: image?.url ? image?.url : "",
              publicId: image?.publicId ? image.publicId : "",
              id: image?.id ? image.id : "",
            };
          }),
          user: {
            name: teacher?.user?.name,
            email: teacher?.user?.email,
            profileImage: {
              publicId: teacher?.user?.profileImage?.publicId,
              url: teacher?.user?.profileImage?.url,
              fileName: "",
            },
            address: {
              city: teacher?.user?.address?.city
                ? teacher?.user?.address?.city
                : "city",
              country: teacher?.user?.address?.country
                ? teacher?.user?.address?.country
                : "country",
              state: teacher?.user?.address?.state
                ? teacher?.user?.address?.state
                : "state",
              zipCode: teacher?.user?.address?.zipCode
                ? teacher?.user?.address?.zipCode
                : "1",
            },
          },
          languages: teacher.languages,
        },
        id: teacher.id,
      })
    ).then((res) => {
      setBusy(false);
      if (res.type.includes("fulfilled"))
        toast.success("Teacher updated successfully");
      else toast.error("something went wrong");
    });
  };

  const handleChange = (e) => {
    let newLanguages = e.map(({ value }) => ({
      name: value,
      proficiency: "ADVANCED",
    }));
    setTeacher({ ...teacher, languages: newLanguages });
  };

  const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_DURATION = 300;

  useEffect(() => {
    if (teacher?.id)
      if (initialVideo[0]?.url.includes("cloudinary")) setIsYoutube(false);
      else setIsYoutube(true);
  }, [teacher]);

  const handleLocation = (e) => {
    if (e.target.checked && !location.includes(e.target.value)) {
      setTeacher({ ...teacher, location: [...location, e.target.value] });
    } else {
      setTeacher({
        ...teacher,
        location: location.filter((item) => item !== e.target.value),
      });
    }
  };

  const handleLevel = (e) => {
    if (e.target.checked && !levels.includes(e.target.value)) {
      setTeacher({ ...teacher, levels: [...levels, e.target.value] });
    } else {
      setTeacher({
        ...teacher,
        levels: levels.filter((item) => item !== e.target.value),
      });
    }
  };
  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)

  const education = teacher?.educationLevel;
  const setEducation = (item) => {
    setTeacher({ ...teacher, educationLevel: item });
  };
  const handleStudioUpload = (value) => {
    setTeacher({ ...teacher, images: value });
  };
  const handleCertChange = (value) => {
    setTeacher({
      ...teacher,
      experience: {
        ...teacher.experience,
        certifications: value,
      },
    });
  };

  return (
    teacher?.id && (
      <div
        className={missing ? "account__settings missing" : "account__settings"}
      >
        <div className="settings__container update_teacher_container">
          <div className="header">
            <h3>Teacher Details</h3>
          </div>
          <div className="account__rows">
            <div className="account__row">
              <span className="title">Title</span>
              <input
                type="text"
                placeholder="title..."
                value={teacher?.title}
                onChange={(e) => {
                  setTeacher({ ...teacher, title: e.target.value });
                }}
              />
            </div>
            <div className="account__row">
              <span className="title">Description</span>
              <textarea
                className="edu_textarea"
                type="text"
                placeholder="description..."
                value={teacher?.description}
                onChange={(e) => {
                  setTeacher({ ...teacher, description: e.target.value });
                }}
              />
            </div>

            {/* <div className="account__row">
              <span className="title">Hourly Rate</span>
              <input
                type="number"
                placeholder="title..."
                className="edit_pf_input"
                value={teacher?.hourlyRate}
                onChange={(e) => {
                  setTeacher({
                    ...teacher,
                    hourlyRate: e.target.valueAsNumber,
                  });
                }}
              />
            </div> */}
            <div className="account__row">
              <span className="title">Languages</span>
              <Select
                isMulti
                name="nationality"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                // styles={customStyles}
                isSearchable={true}
                onChange={handleChange}
                value={uniqueLanguages}
              />
            </div>
            <Note
              title={"We need your attention!"}
              switches
              p1={
                "You can switch between uploading a video or submitting a youtube URL"
              }
              p2={"Try it out!"}
            />
            <div className="account__row">
              <span className="title">Video Type</span>
              <Toggle
                defaultChecked={isYoutube} // initial state of the switch button
                onChange={() => setIsYoutube(!isYoutube)}
                icons={false} // hide the default icons to use custom ones
              />
            </div>

            <div className="account__row">
              <span className="title">Video Intro</span>
              {/* {teacher?.videos[0]?.publicId === teacher?.videos[0]?.url && ( */}
              {isYoutube ? (
                <input
                  type="text"
                  placeholder="video url..."
                  value={
                    teacher?.videos?.length
                      ? teacher?.videos[teacher?.videos?.length - 1]?.url
                        ? teacher?.videos[teacher?.videos?.length - 1]?.url
                        : ""
                      : ""
                  }
                  onChange={(e) => {
                    setTeacher({
                      ...teacher,
                      videos: [
                        {
                          ...teacher?.videos[
                            teacher.videos[teacher?.videos?.length - 1]
                          ],
                          url: e.target.value,
                        },
                      ],
                    });
                  }}
                />
              ) : (
                <SharedVideoUploader
                  index={"file-video"}
                  onVideoChange={(value) => {
                    setTeacher({
                      ...teacher,
                      videos: [value],
                    });
                    setInitialVideo([value]);
                  }}
                  newDelete={() => {
                    setTeacher({ ...teacher, videos: [] });
                    setInitialVideo([{ url: "", publicId: "" }]);
                  }}
                  video={initialVideo[0]}
                  MAX_VIDEO_SIZE={MAX_VIDEO_SIZE}
                  MAX_VIDEO_DURATION={MAX_VIDEO_DURATION}
                />
              )}
              {/* )} */}
            </div>
            <div className="account__row">
              {/* <span className="title">Studio Images</span> */}
              <div className="row">
                <span>Studio/Lesson Photos</span>
                <SharedMultipleFileUploader
                  fileTypes={fileTypes}
                  setImages={handleStudioUpload}
                  images={teacher?.images}
                  multiple={true}
                />
              </div>
            </div>

            <div className="account__row">
              {/* <span className="title">Studio Images</span> */}
              <div className="row">
                <span>Certifications (optional)</span>
                <SharedMultipleFileUploader
                  fileTypes={fileTypes2}
                  setImages={handleCertChange}
                  images={
                    teacher?.experience?.certifications
                      ? teacher?.experience?.certifications
                      : []
                  }
                  multiple={true}
                />
              </div>
            </div>
            <div className="account__row">
              <div className="col_row">
                {education.map((educ, i) => (
                  <AddEducation
                    key={i}
                    edu={educ}
                    education={education}
                    setEducation={setEducation}
                    i={i}
                    maxlength={education.length - 1}
                  />
                ))}
                <div className="row">
                  <span />
                  <div className="input rowDisplay">
                    <span>Add Education</span>
                    <button
                      className="add_button"
                      onClick={() => {
                        setEducation([
                          ...education,
                          {
                            university: "",
                            degree: "",
                            specialization: "",
                            startDate: "",
                            endDate: "",
                            degreeType: "none",
                          },
                        ]);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="account__row">
              <span className="title">Do you have a pet?</span>
              <div className="checbox">
                <div className="option">
                  <input
                    type="radio"
                    name="pets"
                    onChange={() => {
                      setTeacher({ ...teacher, pets: true });
                    }}
                    checked={teacher.pets}
                  />
                  <span>Yes</span>
                </div>
                <div className="option">
                  <input
                    type="radio"
                    name="pets"
                    onChange={() => {
                      setTeacher({ ...teacher, pets: false });
                    }}
                    checked={!teacher.pets}
                  />
                  <span>No</span>
                </div>
              </div>
            </div>
            <div className="account__row">
              <BgCheck />
            </div>
          </div>
          <div className="account__Settings__btns">
            <button
              className={busy ? "account__submit fetching" : "account__submit"}
              onClick={() => {
                updateTeachers();
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    )
  );
}

const AddEducation = ({ i, setEducation, education, edu }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducation([
      ...education.slice(0, i),
      { ...education[i], [name]: value },
      ...education.slice(i + 1),
    ]);
  };
  const remove = () => {
    setEducation(education.filter((_, index) => index !== i));
  };
  return (
    <div className="row Education__row">
      <span>Education</span>
      <div className="row__inputs">
        <div className="input">
          <span>university/ college</span>
          <input
            type="text"
            placeholder="university"
            value={edu?.university}
            name="university"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div className="input">
          <span>degree</span>
          <input
            type="text"
            value={edu?.degree}
            placeholder="degree"
            name="degree"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="input">
          <span>specialization</span>
          <input
            type="text"
            placeholder="specialization"
            name="specialization"
            value={edu?.specialization}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div className="input">
          <span>Start Date</span>
          <input
            type="date"
            name="startDate"
            value={edu?.startDate}
            placeholder="Start Date"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div className="input">
          <span>End Date</span>
          <input
            type="date"
            placeholder="End Date"
            value={edu?.endDate}
            name="endDate"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        {education.length > 1 && (
          <button className="remove_edu_btn" onClick={() => remove()}>
            Remove
          </button>
        )}
      </div>
    </div>
  );
};

// const Subject = ({
//   text,
//   selectedSubjects,
//   setSelectedSubjects,
//   id,
//   setSelectedIDs,
//   selectedIds,
// }) => {
//   const [selected, setSelected] = useState(false);
//   return (
//     <span
//       className={`${selectedSubjects.includes(text) ? "selected" : ""}`}
//       onClick={() => {
//         setSelected(!selected);
//         if (!selectedSubjects.includes(text)) {
//           setSelectedSubjects([...selectedSubjects, text], "add");
//           setSelectedIDs([...selectedIds, id], "add");
//         } else {
//           setSelectedSubjects(
//             selectedSubjects.filter((sub) => sub !== text),
//             "remove"
//           );
//           setSelectedIDs(
//             selectedIds.filter((sub) => sub !== id),
//             "remove"
//           );
//         }
//       }}
//     >
//       {text}
//     </span>
//   );
// };
