import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "./teacherNotes.styles.scss";
import ReactQuill from "react-quill";
import NotePage from "../../../Components/TeacherNote/TeacherNote";
import { getTeacherLessons } from "../../../ReduxToolkit/Slices/teacherSlice";
import { toast } from "react-toastify";
import {
  getTeacherAppointmentsByStatus,
  getTeacherBookingsByStatus,
  teacherAddNotes,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import NotesListingSection from "../../../StudentApp/Pages/StudentNotes/StudentNotes";
const TeacherNotes = () => {
  const { teacher } = useSelector((state) => state.teacher);
  const [showStudents, setShowStudents] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [lessons, setLessons] = useState([]);
  const dispatch = useDispatch();
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [selectedLesson, setSelectedLesson] = useState(null);
  useEffect(() => {
    if (teacher.id) {
      dispatch(
        getTeacherBookingsByStatus({
          teacherId: teacher.id,
          status: "ACCEPTED",
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) {
          res?.payload?.data && setLessons(res.payload.data);
        }
      });
    }
  }, [teacher, refresh]);

  const onAddNotes = (value) => {
    const { title, note } = value;
    dispatch(
      teacherAddNotes({
        title,
        note,
        studentId: selectedStudent,
        teacherId: teacher.id,
      })
    ).then((res) => {
      if (res.type.includes("fulfilled"))
        toast.success("Note was added successfully!");
      setSelectedStudent("");
    });
  };
  return (
    <div className="teacher_notes">
      {/* <NotesListingSection /> */}
      <LessonsTable setSelectedLesson={setSelectedLesson} lessons={lessons} />
      {selectedLesson?.id && selectedLesson?.bookings?.length ? (
        <StudentsTable
          setShowStudents={setShowStudents}
          lesson={selectedLesson}
          setSelectedStudent={setSelectedStudent}
        />
      ) : selectedLesson?.id ? (
        <h2>No bookings for {selectedLesson?.title} lesson</h2>
      ) : (
        <h2>Choose a lesson to start adding notes!</h2>
      )}
      {selectedStudent && (
        <NotePage
          onAdd={(value) => {
            onAddNotes(value);
          }}
        />
      )}
    </div>
  );
};

export default TeacherNotes;

const LessonsTable = ({ lessons, setSelectedLesson }) => {
  return (
    <div className={`manage__students homeworks_table`}>
      <div className="header">
        <div className="header__text">
          <span>Ongoing Lessons</span>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>Lesson</th>
              {/* <th>Submitted</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {lessons &&
              lessons.map((row, i) => (
                <StudentsNotesRow
                  setShowStudents={() => setSelectedLesson(row)}
                  row={row}
                  key={i}
                  i={i}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StudentsNotesRow = ({ row, setShowStudents, i }) => {
  const dispatch = useDispatch();
  return (
    <tr key={i}>
      <td>
        <span>{row?.title}</span>
      </td>

      <td>
        <div className="">
          <button className="accepted" onClick={() => setShowStudents()}>
            Select Lesson
          </button>
        </div>
      </td>
      {/* <td>
        <div className="action__btns">
          <Link to={`/teachers/homework/edit/${row.id}`}>
            <img src={edit} alt="" />
          </Link>
          <img
            src={del}
            alt=""
            onClick={() => {
              onDelete();
              setIsModalOpen(true);
            }}
          />
        </div>
      </td> */}
    </tr>
  );
};

const StudentsTable = ({ setShowStudents, setSelectedStudent, lesson }) => {
  const [showNote, setShowNote] = useState(false);

  const ref = useRef();
  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [lesson]);
  return (
    <div className={`manage__students homeworks_table`} ref={ref}>
      <div className="header">
        <div className="header__text">
          <span>Students</span>
        </div>
      </div>
      <div className="students__table">
        <table>
          <thead>
            <tr>
              <th>Lesson</th>
              <th>Subject</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {lesson?.bookings?.length > 0 &&
              lesson.bookings.map((lesson, i) => (
                <StudentRow
                  setShowStudents={setShowStudents}
                  showStudents={showNote}
                  setSelectedStudent={setSelectedStudent}
                  row={lesson}
                  key={i}
                  i={i}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StudentRow = ({ row, setSelectedStudent, i }) => {
  const dispatch = useDispatch();

  return (
    <tr key={i}>
      <td>
        <span>
          {row?.student?.user?.name} {row?.student?.user?.lastName}
        </span>
      </td>
      <td>
        <span>{row?.lesson?.title}</span>
      </td>

      {/* <td>
        <button className="accepted">Available</button>
      </td> */}

      <td>
        <div className="">
          <button
            className="accepted"
            onClick={() => row?.id && setSelectedStudent(row?.student?.id)}
          >
            Add Note
          </button>
        </div>
      </td>
    </tr>
  );
};
