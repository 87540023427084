import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  getAdminSettings,
  getReferalSettings,
  setAdminSettings,
  setReferalSettings,
} from "../../../ReduxToolkit/Slices/adminSlice";

export default function Fees() {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState({
    studentFee: "",
    teacherCommission: "",
    courseDiscount: "",
    priceLimitLessons: "",
    adminTravelCommision: "",
    travelChargeLimit: "",
  });

  useEffect(() => {
    dispatch(getAdminSettings()).then((res) => setSettings(res.payload));
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: Number(value),
    });
  };

  const onSave = () => {
    dispatch(setAdminSettings(settings)).then((res) => {
      if (res.type.includes("fulfilled"))
        toast.success("Settings saved successfully");
      else {
        toast.error("Save Failed");
      }
    });
  };

  return (
    <div className="basic__settings">
      <h1>Fees</h1>
      <div className="inputs">
        <div className="input">
          <span>Teacher Fees (%)</span>
          <input
            type={"number"}
            value={settings?.teacherCommission}
            name="teacherCommission"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Student Additional Fees (%)</span>
          <input
            type={"text"}
            value={settings?.studentFee}
            name="studentFee"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Admin Fees (%)</span>
          <input
            type={"text"}
            value={
              100 +
              +Number(settings?.studentFee) -
              Number(settings?.teacherCommission)
            }
            name="adminFee"
          />
        </div>
        <div className="input">
          <span>Course Discount (%)</span>
          <input
            type={"text"}
            value={settings?.courseDiscount}
            name="courseDiscount"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Lesson Discount (%)</span>
          <input
            type={"text"}
            value={settings?.lessonDiscount}
            name="lessonDiscount"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Commission on teacher travel fees (%)</span>
          <input
            type={"text"}
            value={settings?.adminTravelCommision}
            name="adminTravelCommision"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Hourly Lesson Price Limit (USD)</span>
          <input
            type={"text"}
            value={settings?.priceLimitLessons}
            name="priceLimitLessons"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Travel Charge Limit (USD per KM)</span>
          <input
            type={"text"}
            value={settings?.travelChargeLimit}
            name="travelChargeLimit"
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>

      <div className="basic__btns">
        <button className="save__btn" onClick={() => onSave()}>
          Save Changes
        </button>
      </div>
    </div>
  );
}
