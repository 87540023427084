import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  getReferalSettings,
  getRegisterSettings,
  setReferalSettings,
  setRegisterSettings,
} from "../../../ReduxToolkit/Slices/adminSlice";

export default function Referal({ register }) {
  const dispatch = useDispatch();

  const [referal, setReferal] = useState({
    percentage: "",
    maxUsage: "",
    duration: "",
    numOfUsers: "",
    terms: "",
    details: "",
    title: "",
  });
  useEffect(() => {
    register
      ? dispatch(getRegisterSettings()).then((res) => {
          setReferal(res.payload);
        })
      : dispatch(getReferalSettings()).then((res) => {
          setReferal(res.payload);
        });
  }, []);

  const onChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "number") {
      return setReferal({
        ...referal,
        [name]: Number(value),
      });
    } else {
      setReferal({
        ...referal,
        [name]: value,
      });
    }
  };

  const onSave = () => {
    register
      ? dispatch(setRegisterSettings(referal)).then((res) => {
          setReferal(res.payload);
          if (res.type.includes("fulfilled"))
            toast.success("Settings saved successfully");
          else {
            toast.error("Save Failed");
          }
        })
      : dispatch(setReferalSettings(referal)).then((res) => {
          setReferal(res.payload);
          if (res.type.includes("fulfilled"))
            toast.success("Settings saved successfully");
          else {
            toast.error("Save Failed");
          }
        });
  };

  return (
    <div className="basic__settings">
      {register ? (
        <h1>Register Promocode Settings</h1>
      ) : (
        <h1>Referal Settings</h1>
      )}
      <div className="inputs">
        <div className="input">
          <span>Discount (%)</span>
          <input
            type={"number"}
            value={referal?.percentage}
            name="percentage"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Title</span>
          <input
            type={"text"}
            value={referal?.title}
            name="title"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Max number of usage for each student</span>
          <input
            type={"number"}
            value={referal?.maxUsage}
            name="maxUsage"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Max duration in days</span>
          <input
            type={"number"}
            value={referal?.duration}
            name="duration"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Details</span>
          <input
            type={"text"}
            value={referal?.details}
            name="details"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Max number of students for each referal code</span>
          <input
            type={"number"}
            value={referal?.numOfUsers}
            name="numOfUsers"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="input">
          <span>Terms of usage</span>
          <input
            type={"text"}
            value={referal?.terms}
            name="terms"
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>

      <div className="basic__btns">
        <button className="save__btn" onClick={() => onSave()}>
          Save Changes
        </button>
      </div>
    </div>
  );
}
