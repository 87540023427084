import React from "react";
import { useState } from "react";
import "./Lesson.styles.scss";
import fav from "../../assets/HomeApp/svgs/heart.svg";

import Rating from "@mui/material/Rating";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useNavigate } from "react-router-dom";

export default function Course({ image, category, name, rating }) {
  const [isFav, setIsFav] = useState(false);

  const navigate = useNavigate();

  const navigateTutors = () => navigate(`/tutors/search/${name}/${category}`);
  return (
    <div className="lesson__container">
      <img alt="course image" src={image} onClick={() => navigateTutors()} />
      {/* <div className="heart">
        <FavoriteIcon
          style={
            !isFav
              ? { color: "#D6D6E0", cursor: "pointer" }
              : { color: "rgb(252, 56, 56)", cursor: "pointer" }
          }
          onClick={() => setIsFav(!isFav)}
        />
      </div> */}
      <div className="lesson__info" onClick={() => navigateTutors()}>
        <div className="lesson__title">
          <span>{name}</span>
        </div>
        <div className="lesson__rating">
          <p>from 10$/h</p>
        </div>
      </div>
      <button onClick={() => navigateTutors()}>
        Start Learning Today
        <ArrowRightAltIcon />
      </button>
    </div>
  );
}
