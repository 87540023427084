import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import SharedLayout from "../Components/SharedDashboardLayout/SharedLayout/SharedLayout";
import AdminPage from "./Pages/AdminPage/AdminPage";
import Settings from "./Pages/Settings/Settings";
import TeacherPayments from "./Pages/TeacherPayments/TeacherPayments";
import Teachers from "./Pages/Teachers/Teachers";
import ViewTeacher from "./Pages/ViewTeacher/ViewTeacher";
import TeacherRequest from "./Pages/TeacherRequest/TeacherRequest";
import EditTeacher from "./Pages/EditTeacher/EditTeacher";
import TeacherSubmission from "./Pages/TeacherSubmission/TeacherSubmission";
import ManageStudentsPage from "./Pages/ManageStudents/ManageStudentsPage";
import ViewStudent from "./Pages/ViewStudent/ViewStudent";
import Booking from "./Pages/Booking/Booking";
import BookingDetails from "./Components/BookingDetails/BookingDetails";
import Payments from "./Pages/Payments/Payments";
import NotFound from "../SharedApp/Pages/404/NotFound";
import Categories from "./Pages/Categories/Categories";
import NewCategory from "./Pages/NewCategory/NewCategory";
import ViewCategory from "./Pages/ViewCategory/ViewCategory";
import EditCategory from "./Pages/EditCategory/EditCategory";
import Subjects from "./Pages/Subjects/Subjects";
import NewSubject from "./Pages/NewSubject/NewSubject";
import EditSubject from "./Pages/EditSubject/EditSubject";
import ViewSubject from "./Pages/ViewSubject/ViewSubject";
import Promo from "./Pages/Promo/Promo";
import CreatePromo from "./Pages/CreatePromo/CreatePromo";
import ViewPromo from "./Pages/ViewPromo/ViewPromo";
import ContentPage from "./Pages/ContentPage/ContentPage";
import EditContent from "./Pages/EditContent/EditContent";
import Faq from "./Pages/Faq/Faq";
// import Notifications from "./Pages/Notification/Notification";
import EditFaq from "./Pages/EditFaq/EditFaq";
import AdminSettings from "./Pages/AdminSettings/AdminSettings";
import CreateAdmin from "./Pages/CreateAdmin/CreateAdmin";
import MailTemplate from "./Pages/MailTemplate/MailTemplate";
import SmsTemplate from "./Pages/SmsTemplate/SmsTemplate";
import Installation from "./Pages/Installation/Installation";
import BasicSettings from "./Pages/BasicSettings/BasicSettings";
import ViewPackage from "./Pages/ViewPackage/ViewPackage";
import NewPackage from "./Pages/NewPackage/NewPackage";
import EditPromo from "./Pages/EditPromo/EditPromo";
import { CheckLoggedIn } from "../hooks";
import AssignAdmin from "./Pages/AssignAdmin/AssignAdmin";
import Courses from "./Pages/Courses/Courses";
import StartCourse from "../SharedApp/Pages/Courses/StartCourse/StartCourse";
import Users from "./Pages/Users/Users";
import MetaTags from "./Pages/MetaTags/MetaTags";
import CreateMetaTag from "./Pages/CreateMetaTag/CreateMetaTag";
import Analytics from "./Pages/Analytics/Analytics";
import Notifications from "../StudentApp/Pages/Courses/Notifications/Notifications";
import CreateBlogs from "./Pages/Blogs/CreateBlogs";
import ViewBlogs from "./Pages/ViewBlogs/ViewBlogs";
import { useSelector } from "react-redux";
import ManagePackages from "./Pages/ManagePackages/ManagePackages";

function AdminApp() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const role_Location = location.pathname.split("/")[1];
  useEffect(() => {
    if (!CheckLoggedIn()) {
      navigate("/login/sessionExpired");
    }
  }, [location]);

  // useEffect(() => {
  //   if (user?.role === "BLOGADMIN" && role_Location !== "blogadmin") {
  //     navigate("/blogadmin");
  //   }
  // }, [location, user]);
  return (
    <div>
      {user?.role === "ADMIN" || user?.role === "BLOGADMIN" ? (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<SharedLayout />}>
            <Route path="/" element={<AdminPage />} />
            <Route path="/dashboard" element={<AdminPage />} />
            {/*  */}
            <Route path="/seo" element={<MetaTags />} />
            <Route path="/seo/add" element={<CreateMetaTag />} />
            <Route path="/seo/:id" element={<CreateMetaTag view />} />
            <Route path="/seo/:id/edit" element={<CreateMetaTag edit />} />
            {/*  */}

            {/*  */}
            <Route path="/teachers" element={<Teachers />} />
            <Route path="/teachers/:id" element={<ViewTeacher />} />
            <Route path="/teachers/:id/payment" element={<TeacherPayments />} />
            <Route path="/students/:id/payment" element={<TeacherPayments />} />
            <Route path="/teachers/:id/request" element={<TeacherRequest />} />
            <Route
              path="/teachers/:id/submission"
              element={<TeacherSubmission />}
            />
            <Route path="/teachers/:id/edit" element={<EditTeacher />} />
            {/*  */}
            <Route path="/students/:id/edit" element={<EditTeacher />} />
            <Route path="/students" element={<ManageStudentsPage />} />
            <Route path="/students/:id" element={<ViewStudent />} />
            {/*  */}
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/new" element={<NewCategory />} />
            <Route path="/categories/:id" element={<ViewCategory />} />
            <Route path="/categories/:id/edit" element={<EditCategory />} />
            <Route path="/subjects" element={<Subjects />} />
            <Route path="/subjects/:id" element={<ViewSubject />} />
            <Route path="/subjects/new" element={<EditSubject createNew />} />
            <Route path="/subjects/:id/edit" element={<EditSubject />} />
            {/*  */}
            <Route path="/faq" element={<Faq />} />
            <Route path="/faq/addNew" element={<EditFaq />} />
            <Route path="/faq/:id/edit" element={<EditFaq />} />

            {/* start Blogs */}
            <Route path="/blogs" element={<ViewBlogs />} />
            <Route path="/blogs/create" element={<CreateBlogs />} />
            <Route path="/blogs/:id" element={<CreateBlogs edit={true} />} />
            {/*  */}
            {/*  */}
            <Route path="/courses" element={<Courses />} />
            <Route path="/courses/subjects" element={<Subjects courses />} />
            <Route
              path="/courses/subjects/:id"
              element={<ViewSubject courses />}
            />
            <Route
              path="/courses/subjects/new"
              element={<EditSubject createNew courses />}
            />
            <Route
              path="/courses/subjects/:id/edit"
              element={<EditSubject courses />}
            />
            <Route path="/courses/viewCourse/:id" element={<StartCourse />} />
            <Route path="/courses/categories" element={<Categories />} />
            <Route path="/courses/categories/new" element={<NewCategory />} />
            <Route path="/courses/categories/:id" element={<ViewCategory />} />
            <Route
              path="/courses/categories/:id/edit"
              element={<EditCategory />}
            />
            <Route
              path="/notification"
              element={<Notifications type={"admin"} />}
            />
            {/*  */}
            {user?.role === "ADMIN" && (
              <>
                <Route path="/profile" element={<Settings />} />
                <Route
                  path="/packages/:name/:duration"
                  element={<ViewPackage />}
                />
                <Route path="/packages/new" element={<NewPackage />} />
                <Route
                  path="/packages/:name/:duration/edit"
                  element={<NewPackage update />}
                />

                <Route path="/booking" element={<Booking />} />

                <Route path="/booking/:id" element={<BookingDetails />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/packages" element={<ManagePackages />} />

                <Route path="/promo" element={<Promo />} />
                <Route path="/promo/new" element={<CreatePromo />} />
                <Route path="/promo/:id" element={<ViewPromo />} />
                <Route path="/promo/:id/edit" element={<EditPromo />} />
                {/* <Route path="/content" element={<ContentPage />} /> */}
                <Route path="/content/:id" element={<EditContent />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/users" element={<Users />} />
                <Route path="/deleted accounts" element={<Users deleted />} />
                <Route path="/settings" element={<AdminSettings />} />
                <Route path="/settings/admin" element={<CreateAdmin />} />
                <Route path="/settings/assign" element={<AssignAdmin />} />
                <Route path="/settings/assign/:id" element={<AssignAdmin />} />
                <Route path="/settings/mail" element={<MailTemplate />} />
                <Route path="/settings/sms" element={<SmsTemplate />} />
                <Route
                  path="/settings/installation"
                  element={<Installation />}
                />
                <Route path="/settings/basic" element={<BasicSettings />} />
              </>
            )}
          </Route>
        </Routes>
      ) : (
        <div className="">404</div>
      )}
    </div>
  );
}
export default AdminApp;
