import React, { useState } from "react";
import CourseCard from "../../../../StudentApp/Components/Courses/CourseCard/CourseCard";
import CategoryTrack from "../../../Components/Courses/CategoryTrack/CategoryTrack";
import FilteredCourses from "../../../Components/Courses/FilteredCourses/FilteredCourses";
import Reminder from "../../../Components/Courses/Reminder/Reminder";
import "./MyLearning.styles.scss";
export default function MyLearning() {
  const [selected, setSelected] = useState("All Courses");
  const data = ["All Courses", "My List", "Wishlist"];
  return (
    <div className="mylearning__page">
      <div className="page__header">
        <div className="subcontainer">
          <h1>My learning</h1>
          <CategoryTrack
            categories={data}
            selected={selected}
            setSelected={setSelected}
            color={"white"}
            noborder
          />
        </div>
      </div>
      <div className="page__body">
        {/* <Reminder /> */}
        {/* <FilteredCourses /> */}
        {/* <EditList /> */}
      </div>
    </div>
  );
}

const EditList = () => {
  return (
    <div className="edit__list">
      <div className="title">
        <h3>Edit your list</h3>
        <span>X</span>
      </div>
      <div className="input">
        <span>Issue Type</span>
        <input type="text" placeholder="Name your list e.g. HTML skills" />
      </div>
      <div className="input">
        <span>Issue details</span>
        <textarea placeholder="Why are you creating this list? eg. To start a new business, to get a new job, To become a web developer" />
      </div>
      <div className="list__btns">
        <button className="dismiss">Cancel</button>
        <button className="second">Save</button>
      </div>
    </div>
  );
};
