import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import CourseCard from "../../../../StudentApp/Components/Courses/CourseCard/CourseCard";
import "./HomepageCourseCarousel.styles.scss";

export default function HomepageCourseCarousel({ title, courses, selected }) {
  const navigate = useNavigate();
  const responsive = [
    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 810,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  return (
    <div className="hompeage__carousel">
      <div className="home__title">
        <h1>{title}</h1>
        <span onClick={() => navigate(`/courses/categories/${selected}`)}>
          View All Courses
        </span>
      </div>
      {courses && courses.length > 4 ? (
        <div className="courses__container">
          <Slider
            dots={false}
            slidesToShow={4}
            slidesToScroll={1}
            responsive={responsive}
          >
            {courses &&
              courses.length > 0 &&
              courses.map((course, i) => (
                <CourseCard
                  noShadow
                  key={i}
                  title={course.title}
                  price={course.price}
                  rating={course.rating}
                  id={course.id}
                  image={course.profileImage.url}
                />
              ))}
          </Slider>
        </div>
      ) : (
        <div className="sm__courses">
          {courses &&
            courses.length > 0 &&
            courses.map((course, i) => (
              <CourseCard
                noShadow
                key={i}
                title={course.title}
                price={course.price}
                rating={course.rating}
                id={course.id}
                image={course.profileImage.url}
              />
            ))}
        </div>
      )}
    </div>
  );
}
