import React, { useEffect, useState } from "react";
import moment from "moment";
// import TransactionTable from "../../Components/TransactionTable/TransactionTable";
import "./Payments.styles.scss";
// import tutor from "../../../assets/SharedApp/images/tutor.webp";
import Pagination from "../../../Components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import { getTransactions } from "../../../ReduxToolkit/Slices/adminSlice";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

export default function Payments() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const [filters, setFilters] = useState({
    type: "PAYMENT",
    order: "",
    limit: 10,
    sortField: "createdAt",
    sortOrder: "desc",
  });

  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handleFilterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    //  let allFilters = newFilters + `page=${currentPage}&`;
    dispatch(getTransactions({ ...filters, page: currentPage })).then((res) => {
      setPage(res.payload.numOfPages);
      setPayments(res.payload.transactions);
      setTotal(res.payload.totalTransactions);
    });
  }, [filters, currentPage]);

  return (
    <div className="admin__payments__page">
      <div className="payments__switch">
        <div
          className={`switch ${filters.type === "PAYMENT" ? "selected" : ""}`}
          onClick={() => handleFilterChange("type", "PAYMENT")}
        >
          PAYMENTS
        </div>
        <div
          className={`switch ${filters.type === "WITHDRAW" ? "selected" : ""}`}
          onClick={() => handleFilterChange("type", "WITHDRAW")}
        >
          WITHDRAWALS
        </div>
        <div
          className={`switch ${filters.type === "REFUND" ? "selected" : ""}`}
          onClick={() => handleFilterChange("type", "REFUND")}
        >
          REFUNDS
        </div>
        <div
          className={`switch ${filters.type === "DEPOSIT" ? "selected" : ""}`}
          onClick={() => handleFilterChange("type", "DEPOSIT")}
        >
          DEPOSITS
        </div>
      </div>

      <div className="transaction__table">
        {payments?.length > 0 ? (
          <AdminPaymentTable data={payments} />
        ) : (
          <p>No current {filters.type}</p>
        )}
        {payments?.length > 0 ? (
          <Pagination
            page={page}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalData={total}
            dataRendered={payments.length}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const AdminPaymentTable = ({ data }) => {
  const urlTo = (user) => {
    const { role } = user;
    if (role === "ADMIN") {
      return "/admin/settings";
    } else if (role === "TEACHER") {
      return `/admin/teachers/${user?.techer?.id}`;
    } else if (role === "STUDENT") {
      return `/admin/students/${user?.student?.id}`;
    } else {
      return;
    }
  };
  return (
    <div className="teacher_payments">
      <div className="header">
        <h1>Payments</h1>
      </div>
      <div className="payments__table">
        <div className="students__table">
          <table>
            <tr>
              <th>S.NO</th>
              <th>Status</th>
              <th>Method</th>
              <th>Sender</th>
              <th>Receiever</th>
              <th>AMOUNT</th>
              <th>DATE</th>
            </tr>
            {data.map((row, i) => (
              <tr key={i}>
                <td>{i}</td>
                <td>
                  <div className="manage__std__lesson">
                    <div className="lesson__date">
                      <p>{row.status}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p>{row.paymentMethod}</p>
                </td>
                <td>
                  <Tooltip title="Click to navigate to user's profile" arrow>
                    <Link to={urlTo(row.sender)}>{row.sender?.name}</Link>
                  </Tooltip>

                  <p className="span">({row.sender?.role})</p>
                </td>
                <td>
                  <Tooltip title="Click to navigate to user's profile" arrow>
                    <Link to={urlTo(row.receiver)}>{row.receiver?.name}</Link>
                  </Tooltip>
                  <p className="span">({row.receiver?.role})</p>
                </td>
                <td>
                  <p className="text__success">${row.amount / 100}</p>
                </td>
                <td>
                  <p>{moment(row.createdAt).format("DD/MM/YYYY")}</p>
                  <p className="span blueText">
                    {moment(row.createdAt).format("HH:mm")}
                  </p>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};
