import React, { useEffect, useRef, useState } from "react";
import { JaaSMeeting } from "@jitsi/react-sdk";

import "./GlobalStyles/app.styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import JitsiMeet from "./MeetingRoomNews";
import { useSelector } from "react-redux";

function MeetingRoomContainer() {
  const { lesson, userId, teacherId } = useParams();

  const { user } = useSelector((state) => state.user);

  return (
    <div className="MeetingRoom_Container">
      <div className="meeting">
        <JitsiMeet roomName={lesson ? lesson : "lesson"} name={user.name} />
      </div>
    </div>
  );
}

export default MeetingRoomContainer;
