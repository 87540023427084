import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Tooltip } from "@mui/material";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // import the styles

import "./SetupProfile.styles.scss";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setTeacherId,
  setUserVideo,
  setUserProfilePicture,
  registerTeacher,
  uploadVideo,
  setUserCertifications,
  setTeacherEducationLevel,
  setTeacherPets,
  handleTeacherChange,
} from "../../../ReduxToolkit/Slices/teacherSlice";

import Note from "../../Components/Note/Note";

import arrowRight from "../../../assets/StudentApp/svgs/Arrow-right-dark.svg";
import arrowLeft from "../../../assets/StudentApp/svgs/Arrow-left.svg";
import Track from "../../Components/Track/Track";
import {
  addGeoLocation,
  login,
  setUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import VideoUploaderComponent from "../../Components/VideoUploaderComponent/VideoUploaderComponent";
import SharedMultipleFileUploader from "../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import SharedVideoUploader from "../../../Components/SharedVideoUploader/SharedVideoUploader";
import { options } from "../SignupDetails/languages";
import { customStyles } from "../SignupDetails/customStyles";
import Select from "react-select";

import eximg1 from "../../../assets/AdminApp/images/eximg1.webp";
import eximg2 from "../../../assets/AdminApp/images/eximg2.webp";
import eximg3 from "../../../assets/AdminApp/images/eximg3.webp";
import eximg4 from "../../../assets/AdminApp/images/eximg4.webp";
import smile from "../../../assets/AdminApp/svgs/smile.svg";
import tick from "../../../assets/AdminApp/svgs/tick.svg";
import x from "../../../assets/AdminApp/svgs/x.svg";
export default function SetupProfile() {
  const dispatch = useDispatch();
  const sendToast = (text) => toast.error(text);
  const navigate = useNavigate();

  const { teacher, user } = useSelector((state) => state.teacher);
  const { geolocation } = useSelector((state) => state.user);
  const { teachCourses } = useSelector((state) => state.teacher);
  const { languages } = teacher;
  const [busy, setBusy] = useState(false);

  let yout =
    teacher?.videos.length &&
    teacher.videos[0] &&
    teacher.videos[0].url === teacher.videos[0].publicId
      ? true
      : false;
  const [previewVideo, setPreviewVideo] = useState(
    teacher?.videos.length && teacher.videos[0] && teacher.videos[0].url
  );
  const [incomplete, setIncomplete] = useState(true);
  // const [youtube, setYoutube] = useState(!yout);
  const [youtube, setYoutube] = useState(!yout);

  const { title, description, images, experience } = teacher;
  const certifications = experience.certifications;
  const education = teacher.educationLevel;

  const fileTypes = ["JPEG", "webp", "JPG", "PNG"];
  const fileTypes2 = ["JPEG", "webp", "JPG", "PDF", "PNG"];

  useEffect(() => {
    if (
      user?.profileImage?.url &&
      description.split(" ").length > 39 &&
      title
    ) {
      incomplete && setIncomplete(false);
    } else {
      !incomplete && setIncomplete(true);
    }

    setPreviewVideo(teacher?.videos[0]?.url ? teacher.videos[0].url : "");
  }, [
    user?.profileImage,
    description,
    title,
    previewVideo,
    images,
    education,
    teacher?.videos[0]?.url,
  ]);

  const MAX_SIZE = 4 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // Maximum allowed file size in bytes (2MB)
  const MAX_VIDEO_DURATION = 300;

  const handleStudioUpload = (value) => {
    dispatch(
      handleTeacherChange({
        name: "images",
        value: value,
      })
    );
  };
  const setProfileImage = (image) => {
    dispatch(setUserProfilePicture(image));
  };

  const setEducation = (item) => {
    dispatch(setTeacherEducationLevel(item));
  };

  const handleCertChange = (value) => {
    dispatch(setUserCertifications(value));
  };
  function isYouTubeVideo(url) {
    // Regular expression for various YouTube video URLs
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    // Test the URL against the regular expression
    return youtubeRegex.test(url);
  }

  const handleSubmit = () => {
    if (
      teacher?.videos[0]?.url &&
      teacher?.videos[0]?.url === teacher?.videos[0]?.publicId &&
      !isYouTubeVideo(teacher?.videos[0]?.url)
    ) {
      return sendToast("Invalid Youtube Video");
    }
    if (description.split(" ").length < 40) {
      return sendToast("Profile description must be at least 40 words");
    }
    if (title.split(" ").length < 12) {
      return sendToast("Profile Title must be at least 12 words");
    }
    if (incomplete) {
      if (!user?.profileImage?.url) {
        return toast.error("Please upload a profile image!");
      }
    }
    if (!user.email || !user.name || !user.lastName) {
      sendToast("Missing details please go back and fill them");
      return navigate("teacher-signup");
    }

    if (!incomplete) {
      setBusy(true);
      let initialTeacher = {
        user,
        title,
        description: description,
        hourlyRate: 0,
        skills: [],
        location: teacher.location,
        experience: {
          title: "",
          qualifications: [],
          certifications,
          experienceYears: 0,
        },
        background: {
          status: "UNCHECKED",
          image: {
            url: teacher?.background?.image?.url
              ? teacher?.background?.image.url
              : "",
            publicId: teacher?.background?.image?.publicId
              ? teacher?.background?.image.publicId
              : "",
            fileName: teacher?.background?.image?.fileName
              ? teacher?.background?.image.fileName
              : "",
          },
        },
        languages: teacher.languages,
        images: teacher.images,
        videos: teacher.videos,
        educationLevel: education,
        pets: teacher.pets,
      };
      if (initialTeacher?.user?.bySocial)
        initialTeacher = {
          // confirmPassword: "PA$$WORD###",
          user,
          title,
          description: description,
          hourlyRate: 0,
          skills: [],
          location: teacher.location,
          experience: {
            title: "",
            qualifications: [],
            certifications,
            experienceYears: 0,
          },
          background: {
            status: "UNCHECKED",
            image: {
              url: teacher?.background?.image?.url
                ? teacher?.background?.image.url
                : "",
              publicId: teacher?.background?.image?.publicId
                ? teacher?.background?.image.publicId
                : "",
              fileName: teacher?.background?.image?.fileName
                ? teacher?.background?.image.fileName
                : "",
            },
          },
          languages: teacher.languages,
          images: teacher.images,
          videos: teacher.videos,
          educationLevel: education,
          pets: teacher.pets,
        };
      dispatch(registerTeacher(initialTeacher))
        .then((res) => {
          setBusy(false);
          if (res.type === "registerTeacher/rejected") {
            return sendToast("Error registering teacher, please try again");
          }
          dispatch(setTeacherId(res.payload.id));
          dispatch(setUser(res.payload.user));
          if (!user.bySocial) {
            dispatch(
              login({
                email: user.email,
                password: user.password,
              })
            ).then((res) => {
              !user.bySocial &&
                firebase
                  .auth()
                  .createUserWithEmailAndPassword(user.email, user.password)
                  .catch((error) => {});
              // if (teachCourses) {
              //   toast.success(
              //     "Your profile was successfully! All you have to do now is add your courses"
              //   );
              //   return navigate("/teacher/signup/course", { replace: true });
              // }

              return navigate("/teacher/signup/availability", {
                replace: true,
              });
            });
          } else {
            return navigate("/teacher/signup/availability", {
              replace: true,
            });
          }
        })
        .catch((err) => {
          setBusy(false);
        });
    }
  };

  const handleYoutube = (e) => {
    const { value } = e.target;

    dispatch(
      setUserVideo({
        url: value,
        publicId: value,
      })
    );
  };

  const toggleYoutube = () => {
    setYoutube(!youtube);
    dispatch(
      setUserVideo({
        url: "",
        publicId: "",
      })
    );
    setPreviewVideo("");
  };

  const handleTeachChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      handleTeacherChange({
        name,
        value,
      })
    );
  };

  const handleChange = (e) => {
    // handle language change
    let newLanguages = e.map(({ value }) => ({
      name: value,
      proficiency: "ADVANCED",
    }));
    handleTeach("languages", newLanguages);
  };
  const handleTeach = (name, value) => {
    dispatch(handleTeacherChange({ name, value }));
  };
  const checkLanguage = (element) =>
    languages.filter((language) => {
      if (language.name === element.value) {
        return element;
      } else return;
    });
  const languageValue = options.filter((element) => {
    if (
      languages &&
      languages.length &&
      checkLanguage(element).length &&
      checkLanguage(element)[0].name === element.value
    )
      return element;
  });

  return (
    <div className="setup__profile__page">
      <Track step={4} />
      <div className="setup__profile__container">
        <div className="inner">
          <div className="heading">
            <Note
              p1={
                "Please add a very compelling Title for your profile. Remember your qualification, experience and what sets you apart."
              }
              title="Take Note"
            />
            <div className="header">
              <h1>Profile Category</h1>

              <p>Profile Settings</p>
            </div>
          </div>
          <div className="note_container_2">
            <GoodToKnow />
          </div>
          <div className="settings">
            <div className="row">
              <span>Profile Photo</span>
              <div className="col2 col2prime">
                <SharedSingleImageUploader
                  onSelectFile={(value) => setProfileImage(value)}
                  preview={user?.profileImage}
                  id={user?.profileImage}
                  example
                  tip={`Image size must be  less than ${
                    MAX_SIZE / (1024 * 1024)
                  } MB.`}
                  MAX_SIZE={MAX_SIZE}
                  detectFace={false}
                />
              </div>
            </div>
            <div className="row">
              <span className="outer_word">
                Profile Title
                {title?.length < 1 ? (
                  <span className="inner_word">{`(min 12 words)`}</span>
                ) : (
                  <span className="inner_word">{`(${
                    title.split(" ").length
                  } / 12 words)`}</span>
                )}
              </span>

              <div className="col2">
                <input
                  type="text"
                  placeholder="Your profile title is more than just a mere description – it's a glimpse into your passion and teaching philosophy."
                  name="title"
                  onChange={(e) => handleTeachChange(e)}
                  value={title}
                />
              </div>
            </div>
            <Tooltip
              title={
                description.split(" ").length < 40 &&
                "Profile description must be at least 40 words"
              }
              placement="top"
              arrow
            >
              <div className="row">
                <span className="outer_word">
                  Profile Description
                  {description?.length < 1 ? (
                    <span className="inner_word">{`(min 40 words)`}</span>
                  ) : (
                    <span className="inner_word">{`(${
                      description.split(" ").length
                    } / 40 words)`}</span>
                  )}
                </span>
                <div className="col2">
                  <textarea
                    type="text"
                    placeholder="Tell us about yourself and your experience. What you can offer the students that is unique and the levels you can teach. Please include your qualifications, Certification and experience"
                    className="area"
                    name="description"
                    onChange={(e) => handleTeachChange(e)}
                    value={description}
                  />
                </div>
              </div>
            </Tooltip>

            <div className="row">
              <span>
                Studio/Lesson Photos{" "}
                <span className="inner_word">(optional)</span>
              </span>
              <div className="col2">
                <SharedMultipleFileUploader
                  fileTypes={fileTypes}
                  setImages={handleStudioUpload}
                  images={images}
                  multiple={true}
                />
              </div>
            </div>
            <Note
              title={"We need your attention!"}
              switches
              p1={
                "You can switch between uploading a video or submitting a youtube URL"
              }
              p2={"Try it out!"}
            />
            <div className="row">
              <span>
                Video Intro
                <span className="inner_word">(optional)</span>
              </span>

              <div className="col2">
                <div>
                  <div className="">
                    <p
                      style={{
                        margin: "0",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <span>
                        {!youtube ? "Upload a video" : "Add a youtube link"}
                      </span>
                      <Toggle
                        defaultChecked={youtube} // initial state of the switch button
                        onChange={() => toggleYoutube()}
                        icons={false} // hide the default icons to use custom ones
                      />
                    </p>

                    {/* <p>
                      {youtube ? "Or upload a video" : "Or add a youtube link"}
                    </p> */}
                  </div>
                  {!youtube ? (
                    <SharedVideoUploader
                      index={"file-video"}
                      onVideoChange={(value) => dispatch(setUserVideo(value))}
                      video={
                        teacher?.videos?.length
                          ? teacher.videos[0]
                          : [{ url: "", publicId: "" }]
                      }
                      MAX_VIDEO_SIZE={MAX_VIDEO_SIZE}
                      MAX_VIDEO_DURATION={MAX_VIDEO_DURATION}
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Youtube video link"
                      onChange={(value) => handleYoutube(value)}
                      value={teacher?.videos[0]?.url}
                    />
                  )}
                </div>
              </div>
            </div>

            {/*  */}

            {/*  */}
            <div className="row">
              <span>
                Certifications <span className="inner_word">(optional)</span>
              </span>
              <div className="col2">
                <SharedMultipleFileUploader
                  fileTypes={fileTypes2}
                  setImages={handleCertChange}
                  images={certifications}
                  multiple={true}
                />
              </div>
            </div>
            <div className="row">
              <Tooltip
                title="Select as many as you speak"
                placement="top"
                arrow
              >
                <span>
                  Languages Spoken{" "}
                  <span className="inner_word">(optional)</span>
                </span>
              </Tooltip>
              <div className="col2">
                <Tooltip
                  title="Your profile will be by default in English language but you can present in other languages and your profile will be translated."
                  placement="top"
                  arrow
                >
                  <div className="">
                    <Select
                      isMulti
                      name="languages"
                      options={options}
                      className="custom_languages_select"
                      classNamePrefix="select"
                      styles={customStyles}
                      isSearchable={true}
                      onChange={handleChange}
                      value={languageValue}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
            {education.map((educ, i) => (
              <AddEducation
                key={i}
                edu={educ}
                education={education}
                setEducation={setEducation}
                i={i}
                maxlength={education.length - 1}
              />
            ))}
            <div className="row">
              <span />
              <div className="col2">
                <div className="input rowDisplay">
                  <span>Add Education</span>
                  <button
                    className="add_button"
                    onClick={() => {
                      setEducation([
                        ...education,
                        {
                          university: "",
                          degree: "",
                          specialization: "",
                          startDate: "",
                          endDate: "",
                          degreeType: "none",
                        },
                      ]);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <span>Add Another Education</span>
              <div className="input">
                <button className={"show__btn"}>+</button>
              </div>
            </div> */}
            <div className="gender">
              <div className="label">
                <span>Do you have a pet</span>
              </div>
              <div className="checbox">
                <div className="option">
                  <input
                    type="radio"
                    name="pets"
                    onChange={() => dispatch(setTeacherPets(true))}
                    checked={teacher.pets}
                  />
                  <span>Yes</span>
                </div>
                <div className="option">
                  <input
                    type="radio"
                    name="pets"
                    onChange={() => dispatch(setTeacherPets(false))}
                    checked={!teacher.pets}
                  />
                  <span>No</span>
                </div>
              </div>
            </div>
          </div>
          <div className="signup__buttons">
            <Link to={"/teacher-signup"}>
              <button className="btn__previous">
                <img src={arrowLeft} alt="" />
                Previous
              </button>
            </Link>
            {/* <Link to={"/teacher/signup/availability"}> */}
            <button
              className={incomplete || busy ? "btn__locked" : "btn__next"}
              onClick={() => handleSubmit()}
            >
              Next
              {busy && <div className="spinner" />}
              <img src={arrowRight} alt="" />
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
const AddEducation = ({ i, setEducation, education, edu }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEducation([
      ...education.slice(0, i),
      { ...education[i], [name]: value },
      ...education.slice(i + 1),
    ]);
  };
  const remove = () => {
    setEducation(education.filter((_, index) => index !== i));
  };
  return (
    <div className="row Education__row">
      <span>
        Education <span className="inner_word">(optional)</span>{" "}
      </span>
      <div className="col2">
        <div className="row__inputs">
          <div className="input">
            <span>university / college</span>
            <input
              type="text"
              placeholder="university"
              value={edu?.university}
              name="university"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="input">
            <span>degree</span>
            <input
              type="text"
              value={edu?.degree}
              placeholder="degree"
              name="degree"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="input">
            <span>specialization</span>
            <input
              type="text"
              placeholder="specialization"
              name="specialization"
              value={edu?.specialization}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="input">
            <span>Start Date</span>
            <input
              type="date"
              name="startDate"
              value={edu?.startDate}
              placeholder="Start Date"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="input">
            <span>End Date</span>
            <input
              type="date"
              placeholder="End Date"
              value={edu?.endDate}
              name="endDate"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          {education.length > 1 && (
            <button className="remove_edu_btn" onClick={() => remove()}>
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const GoodToKnow = ({
  notes = [
    "Show yourself",
    "Face front for the camera",
    "Avoid sunglasses, logos, blurry, pixelated or too-dark photos",
    "Min. 600 X 600px, .PNG or .JPG",
  ],
  title = "Attention",
  images = [eximg4, eximg1, eximg2, eximg3],
}) => {
  return (
    <div className="good_to_know_notes">
      <div className="title">
        <div className="icon">
          <img src={smile} alt="" />
        </div>
        <h1>{title}</h1>
      </div>
      <div className="list">
        <ul>
          {notes.map((el, i) => (
            <li key={i}>{el}</li>
          ))}
        </ul>
      </div>
      <div className="ex_images">
        {images.map((img, i) => (
          <div className="ex_image" key={i}>
            <img src={img} alt="example" />
            <div className={`tick ${i === 0 || i === 1 ? "danger" : ""}`}>
              <img src={i === 0 || i === 1 ? x : tick} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
