import React, { useCallback, useEffect, useState } from "react";
import volicity from "../../assets/TeacherApp/assets/svgs/volicity.svg";
import edit from "../../assets/SharedApp/svgs/edit.svg";
import remove from "../../assets/SharedApp/svgs/remove.svg";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { deleteImage } from "../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import Webcam from "react-webcam";
import { uploadVideo } from "../../ReduxToolkit/Slices/teacherSlice";
import Note from "../../TeacherApp/Components/Note/Note";
import { updateCloudinaryUrl } from "../../hooks";

export default function SharedVideoUploader({
  video,
  onVideoChange,
  onVideoDelete,
  index,
  specialDelete,
  MAX_VIDEO_DURATION,
  MAX_VIDEO_SIZE,
  newDelete,
  noToolTip,
}) {
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const sendToast = (m) => toast.error(m);
  const [videoLoading, setLoadingVideo] = useState(false);
  const deleteVideo = () => {
    dispatch(deleteImage(video.publicId));
    specialDelete
      ? onVideoDelete({ url: "", publicId: "" }, 0)
      : onVideoChange({ url: "", publicId: "" }, 0);
  };

  const onSelectVideo = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > MAX_VIDEO_SIZE) {
      // Alert user that the video is too large
      sendToast(
        `Video size must be within ${MAX_VIDEO_SIZE / (1024 * 1024)} MB.`
      );
      return;
    }
    setLoadingVideo(true);
    setShowModal(false);
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      if (video.duration > MAX_VIDEO_DURATION) {
        // Alert user that the video is too long
        sendToast(
          `Video duration must be within ${MAX_VIDEO_DURATION} seconds.`
        );
        return;
      }
      setLoadingVideo(true);
      handleUploadVideo(file);
      const objectUrl2 = URL.createObjectURL(file);
      return () => URL.revokeObjectURL(objectUrl2);
    };
    video.src = URL.createObjectURL(file);
  };

  const handleUploadVideo = (file) => {
    setLoadingVideo(true);
    setShowModal(false);
    let form = new FormData();
    form.append("file", file);
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.onloadedmetadata = () => {
      const lengthInSeconds = Math.floor(video.duration);
      Promise.resolve(dispatch(uploadVideo(form))).then((value) => {
        if (!value.type === "uploadVideo/fulfilled") {
          setLoadingVideo(false);
          return sendToast("Error uploading images, please try again");
        }
        onVideoChange(value.payload, lengthInSeconds);
        setLoadingVideo(false);
      });
    };
  };

  return (
    <>
      {showModal && (
        <UploadModal
          setShowModal={setShowModal}
          handleChange={onSelectVideo}
          handleUploadVideo={handleUploadVideo}
        />
      )}
      <Tooltip
        arrow
        placement="top"
        title={
          !noToolTip &&
          `Video size must be  less than ${
            MAX_VIDEO_SIZE / (1024 * 1024)
          } MB and withing ${MAX_VIDEO_DURATION} seconds.`
        }
      >
        <div className="image__selector" key={video?.publicId}>
          <div className="upload">
            <div
              className="image"
              role="button"
              onClick={() => setShowModal(true)}
              style={{ cursor: "pointer" }}
            >
              <div className="image-upload" style={{ cursor: "pointer" }}>
                <label
                  // htmlFor={index}
                  style={{ cursor: "pointer" }}
                >
                  {videoLoading ? (
                    <div className="spinner" />
                  ) : video?.url ? (
                    <video width="320" height="240" controls key={index}>
                      <source
                        src={updateCloudinaryUrl(video?.url)}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <img src={volicity} className="img__btn" />
                  )}
                </label>
                <input
                  id={index}
                  type="file"
                  onChange={(e) => onSelectVideo(e)}
                  accept="video/mp4"
                  multiple
                  ref={inputRef}
                />
              </div>
              <img
                src={remove}
                alt=""
                className="remove action"
                onClick={() => {
                  newDelete ? newDelete() : deleteVideo();
                }}
              />
            </div>
            <span className="left">Allowed file types: mp4</span>
          </div>
        </div>
      </Tooltip>
    </>
  );
}

const UploadModal = ({ setShowModal, handleChange, handleUploadVideo }) => {
  const inptRef = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const [capImage, setCapImage] = useState("");

  const base64ToFile = (base64Image, fileName) => {
    const base64Data = base64Image.split(",")[1]; // Remove header data
    const type = base64Image.split(",")[0].split(":")[1].split(";")[0]; // Get image type
    const bytes = atob(base64Data); // Convert base64 to binary
    const buffer = new ArrayBuffer(bytes.length);
    const array = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      array[i] = bytes.charCodeAt(i);
    }
    const blob = new Blob([buffer], { type });
    const file = new File([blob], fileName);
    return file;
  };
  const dispatch = useDispatch();
  const handleImageUpload = (file) => {
    handleChange(file);
  };
  useEffect(() => {
    if (capImage) {
      let res = base64ToFile(capImage, "adiz.jpg");
      handleImageUpload(res);
    }
  }, [capImage]);

  return (
    <div className="modal_wrapper">
      <div className="upload_modal">
        <Note
          p1={"1. Upload: Choose an image/video from your device."}
          p2={
            "2. Record a Video: Start and stop recording with the provided buttons."
          }
          p3={
            "3. Upload Video: Once done, hit the 'Upload' button to share your video."
          }
        />
        <h2>Welcome to our Video Uploader! 🎥</h2>
        {/* <h2>Choose an upload method </h2> */}
        {showImage && (
          <WebcamCapture
            setImgSrc={setCapImage}
            setShowImage={setShowImage}
            handleUploadVideo={handleUploadVideo}
          />
        )}
        {!showImage && capImage && <img src={capImage} />}
        <div className="modal_buttons">
          <button
            className="upload_btn"
            onClick={() => {
              inptRef.current.click();
            }}
          >
            <span>Upload File</span>
            <input
              type="file"
              ref={inptRef}
              onChange={(e) => handleChange(e)}
              className="input_file_btn"
              accept="video/*"
            />
          </button>
          {!showImage && (
            <button className="upload_btn" onClick={() => setShowImage(true)}>
              Record a video
            </button>
          )}
          <button onClick={() => setShowModal(false)} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
const WebcamCapture = ({ setImgSrc, setShowImage, handleUploadVideo }) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const handleStartCaptureClick = React.useCallback(() => {
    try {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
      setCapturing(true);
    } catch (e) {
      toast.error("Error starting camera");
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const convertBlobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  };
  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      let videoFile = convertBlobToFile(blob);
      handleUploadVideo(videoFile);
      // const url = URL.createObjectURL(blob);

      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = url;
      // a.download = "react-webcam-stream-capture.webm";
      // a.click();
      // window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);
  return (
    <div className="webcam_container">
      <>
        <Webcam audio={true} ref={webcamRef} muted={true} />
        {capturing ? (
          <button onClick={handleStopCaptureClick}>Stop</button>
        ) : (
          <div className="start_rec_btn_cont">
            <button onClick={handleStartCaptureClick}>Start Recording</button>
            <span>or</span>
          </div>
        )}
        {recordedChunks.length > 0 && (
          <button onClick={handleDownload}>Upload</button>
        )}
      </>
    </div>
  );
};
