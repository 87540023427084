import React from "react";

import "./AdminAnalytics.styles.scss";
import tutor from "../../../assets/SharedApp/images/tutor.webp";
import Analytic from "./Analytic";

export default function AdminAnalytics({ dashboard }) {
  const images = [tutor, tutor, tutor, tutor, tutor, tutor];

  const { totalStudents, totalTeachers, totalLessons, totalBookings } =
    dashboard;
  const data = [
    {
      header: "Students",
      images: images,
      positive: totalStudents,
      negative: -128,
      color: "#CBF0F4",
    },
    {
      header: "Teachers",
      images: images,
      positive: totalTeachers,
      negative: -128,
      color: "#F6D9E3",
    },
    {
      header: "Lessons",
      images: images,
      positive: totalLessons,
      negative: -128,
      color: "#EBE0FF",
    },
  ];
  return (
    <div className="admin__analytics">
      <div className="admin__analytics__container">
        {data.map((el) => (
          <Analytic
            header={el.header}
            negative={el.negative}
            positive={el.positive}
            bg={el.color}
          />
        ))}
      </div>
    </div>
  );
}
