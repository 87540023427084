import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { assignAdmin } from "../../../ReduxToolkit/Slices/adminSlice";
import { useParams } from "react-router-dom";
// import { registerUser } from "../../../ReduxToolkit/Slices/teacherSlice";
import {
  getUserById,
  register,
  registerAdmin,
  updateUser,
} from "../../../ReduxToolkit/Slices/userSlice";
import { toast } from "react-toastify";

import EditForm from "../EditTeacher/EditForm";

function AssignAdmin() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const admin = useSelector((state) => state.user.user);

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    lastName: "",
    password: "",
    nationality: "l",
    role: "ADMIN",
    bySocial: false,
    phone: "",
    gender: "MALE",
    profileImage: {
      publicId: "",
      url: "",
      fileName: "",
    },
    address: {
      street: "street",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
  });

  const notify = (message) => toast.success(message);
  const sendError = (message) => toast.error(message);
  const navigate = useNavigate();
  const onChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id)).then((res) => {
        res?.payload?.data?.id && setUser(res?.payload?.data);
      });
    }
  }, [id]);

  const replaceNullWithEmptyString = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        obj[key] = "";
      } else if (typeof obj[key] === "object") {
        replaceNullWithEmptyString(obj[key]);
      }
    });
    return obj;
  };

  const onSubmit = () => {
    let newUSer = {
      ...user,
      profileImage: {
        url: user?.profileImage?.url ? user?.profileImage?.url : "",
        publicId: user?.profileImage?.publicId
          ? user?.profileImage?.publicId
          : "",
        fileName: user?.profileImage?.fileName
          ? user?.profileImage?.fileName
          : "",
      },
      role: user.role === "MANAGER" ? "ADMIN" : user.role,
      limited: user.role === "ADMIN" ? false : true,
      geoLocation: undefined,
    };

    if (id) {
      dispatch(
        updateUser({
          user: newUSer,
          id,
        })
      )
        .then((res) => {
          if (res.type.includes("fulfilled")) {
            toast.success("Admin was updated!");
          } else {
            if (res.payload === "Email already in use") {
              sendError(res.payload);
            } else {
              sendError("Error updating admin");
            }
          }
        })
        .catch(() => sendError("Error updating user"));
    } else {
      dispatch(registerAdmin(newUSer))
        .then((res) => {
          if (res.type.includes("fulfilled")) {
            toast.success("Added a new admin.");
          } else {
            if (res.payload === "Email already in use") {
              sendError(res.payload);
            } else {
              sendError("Error creating user");
            }
          }
        })
        .catch(() => sendError("Error creating user"));
    }
  };

  useEffect(() => {
    if (admin?.limited) {
      return navigate(-1);
    }
  }, []);
  return (
    !admin.limited && (
      <div>
        <EditForm
          update={id}
          onChange={onChange}
          onSubmit={onSubmit}
          user={user}
          password
        />
      </div>
    )
  );
}

export default AssignAdmin;
