import React from "react";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function AsideItem({
  name,
  type,
  options,
  image,
  onChange,
  title,
  value,
}) {
  const [show, setShow] = useState(true);
  return (
    <div className="course__filter__container">
      <div className="header">
        <h1>{title}</h1>
        {show ? (
          <KeyboardArrowUpIcon onClick={() => setShow(!show)} />
        ) : (
          <KeyboardArrowDownIcon onClick={() => setShow(!show)} />
        )}
      </div>
      <div className={`inputs ${show ? "content-show" : ""}`}>
        {type === "number" || type === "text" ? (
          <input
            type={type}
            onChange={(e) => onChange(e)}
            value={value}
            name={name}
            placeholder={
              name === "search"
                ? "search courses"
                : name === "maxPrice"
                ? "ex: 50$"
                : ""
            }
          />
        ) : type === "select" ? (
          <select onChange={(e) => onChange(e)} value={value} name={name}>
            {name === "category" ? (
              <option value="">Select a category</option>
            ) : (
              <option value="">Select a subject</option>
            )}
            {options.length > 0 &&
              options.map((option, i) => (
                <option value={option.name} key={i}>
                  {option.name}
                </option>
              ))}
          </select>
        ) : (
          options &&
          options.map((el, i) => (
            <div className="input" key={i}>
              <input
                type={type}
                name={name}
                onChange={(e) => onChange(e)}
                value={el}
              />
              {image && <img src={image} alt="" />}
              <span>{el}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
