import React, { useEffect, useState } from "react";
import "./GroupLessonsPage.styles.scss";
import { useDispatch } from "react-redux";

import searchGold from "../../../assets/SharedApp/svgs/searchGold.svg";
import planet from "../../../assets/SharedApp/svgs/planet.svg";
import blop from "../../../assets/SharedApp/images/blop.webp";
import ranking from "../../../assets/SharedApp/images/ranking.webp";
import girl from "../../../assets/SharedApp/images/footerimg.webp";
import points from "../../../assets/HomeApp/images/png.png";

import { getCategoriesWithSubjects } from "../../../ReduxToolkit/Slices/lessonsSlice";

import "../../../Sections/OurLessons/ourLessons.styles.scss";
import Lesson from "../../../Components/Lesson/Lesson";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LessonsCarousel from "../../Pages/LessonsCarousel/LessonsCarousel";
import HomepageCourseCarousel from "../../Components/Courses/HomepageCourseCarousel/HomepageCourseCarousel";
import CourseCategorySwitcher from "../../Components/Courses/CourseCategorySwitcher/CourseCategorySwitcher";
import FeaturedTopics from "../../Components/Courses/FeaturedTopics/FeaturedTopics";
import ReviewCarousel from "../../Components/Courses/ReviewCarousel/ReviewCarousel";
import LearnAnywhere from "../../Components/Courses/LearnAnywhere/LearnAnywhere";
import HeaderPhraseComponent from "../../Components/Courses/HeaderPhraseComponent/HeaderPhraseComponent";
import CoursesPageHeader from "../../Components/Courses/CoursesPageHeader/CoursesPageHeader";
import courseheader from "../../../assets/SharedApp/images/coursesheader.webp";

import {
  getStudentCourses,
  getCourseCategories,
  getAllCourses,
} from "../../../ReduxToolkit/Slices/coursesSlice";
import { Link, useNavigate } from "react-router-dom";
import { getAllGroupLessons } from "../../../ReduxToolkit/Slices/teacherSlice";
import RelatedLessons from "../../../Sections/RelatedLessons/RelatedLessons";
import Info from "../../../Sections/Info/Info";
import Categories from "../../../Sections/Categories/Categories";
import Newsletter from "../../../Sections/NewsLetter/Newsletter";

export default function CoursesHomePage() {
  const [selected, setSelected] = useState("");
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [OtherCategories, setOtherCategories] = useState([]);
  const [groupLessons, setGroupLessons] = useState([]);
  const [groupCategories, setGroupCategories] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseCategories()).then((res) => {
      setCategories(res.payload.data.slice(0, 6));
      setOtherCategories(res.payload.data.slice(6, 16));
      setSelected(res.payload.data[0].name);
    });
  }, []);

  useEffect(() => {
    selected &&
      dispatch(getStudentCourses(`category=${selected}`)).then((res) =>
        setCourses(res.payload.courses)
      );
  }, [selected]);

  useEffect(() => {
    dispatch(getAllGroupLessons()).then((res) => {
      setGroupLessons(res?.payload?.data?.lessons);
    });
  }, []);
  return (
    <div className="courses__homepage">
      <GroupLessonsHeader />
      <MultipleLessonsCarousel />
      <Info />
      <Categories />
      {/* <Promo /> */}
      <Newsletter />
    </div>
  );
}

const GroupLessonsHeader = () => {
  return (
    <div className="courses__home__header">
      <div className="courses__header__container">
        <GroupHeaderPhraseComponent isHeader />
        <div className="image">
          <img src={courseheader} alt="" />
        </div>
      </div>
    </div>
  );
};

function GroupHeaderPhraseComponent({ isHeader }) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  return (
    <div className="header__phrase__component">
      {isHeader && (
        <div className="kickstart__learning">
          <img src={planet} alt="" />
          <span>Kickstart your Learning</span>
        </div>
      )}
      <h1>
        Collaborative Learning for
        <span className="relative">
          {" Personal Growth"}
          {/* {isHeader && <img className="blop" src={blop} alt="" />} */}
        </span>
      </h1>
      <p>
        Elevate Together: Learn, Grow, Connect with Educify's Group Lessons!
        Flexibility meets collaboration - join from anywhere, anytime.
      </p>
      <form
        className="input"
        onSubmit={() => navigate(`/courses/search/${search}`)}
      >
        <input
          type="text"
          placeholder="What do you want to learn?"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit">
          <img src={searchGold} alt="search" />
        </button>
      </form>
      {isHeader && (
        <div className="ranking">
          <img src={ranking} alt="" />
        </div>
      )}
    </div>
  );
}
function GroupLearnAnywhere() {
  return (
    <div className="learn__anywhere__section">
      <div className="section__container">
        <GroupHeaderPhraseComponent />
        <div className="image">
          <img src={girl} alt="" />
        </div>
      </div>
    </div>
  );
}

function GroupLessonCategorySwitcher({
  selected,
  setSelected,
  categories,
  otherCategories,
}) {
  const [showList, setShowList] = useState(false);
  return (
    <div className="category__switcher">
      {categories &&
        categories.length > 0 &&
        categories.map((el, i) => (
          <div
            key={i}
            className={`category__item ${
              selected === el.name ? "selected" : ""
            }`}
            onClick={() => setSelected(el.name)}
          >
            <span>{el.name}</span>
          </div>
        ))}

      <div
        className="absolute__points"
        onMouseEnter={() => setShowList(true)}
        onMouseLeave={() => setShowList(false)}
      >
        <img src={points} alt="" />
      </div>
      <div
        className={`absolute__list ${showList ? "show" : ""}`}
        onMouseEnter={() => setShowList(true)}
        onMouseLeave={() => setShowList(false)}
      >
        {otherCategories &&
          otherCategories.length &&
          otherCategories.map((cat, i) => (
            <Link to={`/courses/categories/${cat.name}`}>
              <span key={i}>{cat.name}</span>
            </Link>
          ))}
      </div>
    </div>
  );
}

const MultipleLessonsCarousel = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    dispatch(getCategoriesWithSubjects()).then((res) => {
      setCategories(res.payload.data.categories);
    });
  }, []);
  return (
    <div className="lessons__section">
      <h2 style={{ textAlign: "center" }}>Group Lessons</h2>
      <p>
        Welcome to Educify's Group Lessons - Where Learning Thrives Together!
        <br />
        At Educify, we believe that education is a journey best traveled in the
        company of others. Our group lessons are designed to bring students of
        all backgrounds and skill levels together, creating a collaborative
        learning experience that is both enriching and rewarding.
      </p>
      <div className="carousel home__carousel">
        {categories.map((section, i) => {
          return (
            section.subjects.length > 3 && (
              <div className="inner__carousel" key={i}>
                <div className="lesson__header">
                  <h1>{section.name}</h1>
                  <span>
                    <Link to={`/landing/${section.name}`}>View All</Link>
                  </span>
                </div>
                <div className="conts">
                  <Slider
                    slidesToShow={4}
                    className="home__courses__slider home__lessons__slider"
                    infinite={true}
                    responsive={[
                      {
                        breakpoint: 1330,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                        },
                      },
                    ]}
                  >
                    {section.subjects.map((lesson, i) => (
                      <Lesson
                        key={i}
                        name={lesson.name}
                        category={section.name}
                        image={lesson.images[0]?.url}
                        rating={5}
                      />
                    ))}
                    {section.subjects.map((lesson, i) => (
                      <Lesson
                        key={i}
                        name={lesson.name}
                        category={section.name}
                        image={lesson.images[0]?.url}
                        rating={5}
                      />
                    ))}
                    {section.subjects.map((lesson, i) => (
                      <Lesson
                        key={i}
                        name={lesson.name}
                        category={section.name}
                        image={lesson.images[0]?.url}
                        rating={5}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
