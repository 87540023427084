import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getTeachersById,
  resetTeacherPass,
  updateTeacher,
} from "../../../ReduxToolkit/Slices/adminSlice";
import {
  getStudentById,
  updateStudent,
} from "../../../ReduxToolkit/Slices/studentSlice";
import EditForm from "./EditForm";
import "./EditTeacher.styles.scss";

export default function EditTeacher() {
  const dispatch = useDispatch();

  const params = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [student, setStudent] = useState({});
  const [password, setPassword] = useState();
  const [teacher, setTeacher] = useState({ subjects: [], categories: [] });

  const { id } = params;

  let role = location.pathname.includes("students") ? "Student" : "Teacher";

  const updateUser = (payloadUser) => {
    setUser({
      password: "",
      name: payloadUser?.name ? payloadUser?.name : "",
      lastName: payloadUser?.lastName ? payloadUser?.lastName : "",
      gender: payloadUser?.gender ? payloadUser?.gender : "MALE",
      email: payloadUser?.email ? payloadUser?.email : "",
      address: {
        country: payloadUser?.address?.country
          ? payloadUser.address.country
          : "",
        state: payloadUser?.address?.state ? payloadUser.address.state : "",
        city: payloadUser?.address?.city ? payloadUser.address.city : "",
        street: payloadUser?.address?.street ? payloadUser.address.street : "",
        zipCode: payloadUser?.address?.zipCode
          ? payloadUser.address.zipCode
          : "",
      },
      profileImage: {
        url: payloadUser?.profileImage?.url
          ? payloadUser?.profileImage?.url
          : "",
        publicId: payloadUser?.publicId?.url ? payloadUser?.publicId?.url : "",
        id: payloadUser?.profileImage?.id ? payloadUser?.profileImage?.id : "",
      },
    });
  };
  useEffect(() => {
    if (role === "Teacher") {
      dispatch(getTeachersById(id)).then((res) => {
        setTeacher(res.payload);
        let payloadUser = res.payload?.user;
        updateUser(payloadUser);
      });
    } else {
      dispatch(getStudentById(id)).then((res) => {
        setStudent(res.payload.data);
        let payloadUser = res.payload?.data?.user;
        updateUser(payloadUser);
      });
    }
  }, []);

  const updateTeachers = () => {
    dispatch(
      updateTeacher({
        teacher: {
          title: teacher.title,
          description: teacher.description,
          hourlyRate: teacher.hourlyRate,
          skills: teacher.skills,
          experience: {
            title: teacher.experience.title,
            qualifications: teacher.experience.qualifications,
            certifications: teacher.experience.certifications,
            experienceYears: teacher.experience.experienceYears,
          },
          background: {
            status: teacher.background?.status
              ? teacher.background?.status
              : "UNCHECKED",
            image: teacher.background?.image
              ? teacher.background?.image
              : { url: "", publicId: "" },
          },
          images: teacher.images.map((image) => {
            return {
              url: image?.url ? image?.url : "",
              publicId: image?.publicId ? image.publicId : "",
              id: image?.id ? image.id : "",
            };
          }),
          videos: teacher.videos.map((image) => {
            return {
              url: image?.url ? image?.url : "",
              publicId: image?.publicId ? image.publicId : "",
              id: image?.id ? image.id : "",
            };
          }),
          user,
          languages: teacher.languages.map((lang) => {
            return {
              name: lang.name,
              proficiency: lang.proficiency,
              id: lang.id,
            };
          }),
          location: teacher.location,
        },
        id: teacher.id,
      })
    ).then((res) => {
      if (res.payload === "Email already in use") {
        alert("Email already in use");
      } else {
        alert("Thank you, teacher was updated!");
        navigate(-1);
      }
    });
  };

  const updateStudents = () => {
    dispatch(
      updateStudent({
        student: {
          paymentMethod: student.paymentMethod,
          user: {
            name: user.name,
            email: user.email,
            phone: user.phone,
            lastName: user.lastName,
            nationality: user.nationality,
            gender: user.gender,
            profileImage: {
              publicId: user.profileImage.publicId,
              url: user?.profileImage?.url,
              // id: user.profileImage.id
            },
            address: {
              street: user.address.street,
              city: user.address.city,
              state: user.address.state,
              country: user.address.country,
              zipCode: user.address.zipCode,
            },
          },
        },
        id,
      })
    ).then((res) => {
      if (res.payload === "Email already in use") {
        alert("Email already in use");
      } else if (res.type.includes("fulfilled")) {
        // alert("Thank you, student was updated!");
        // navigate(-1);
        toast.success("Student Edited Succesfully!");
      } else {
        toast.error("Something went worng");
      }
    });
  };
  const onSubmit = () => {
    if (role === "Teacher") {
      updateTeachers();
    } else {
      updateStudents();
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  return (
    user.name && (
      <EditForm
        onChange={onChange}
        onSubmit={onSubmit}
        role={role}
        user={user}
      />
    )
  );
}
