import React from "react";
import "./content.styles.scss";
export default function BgCheck() {
  const data = [
    {
      p1: `Background checks are important to our business model. We conduct background checks including police reports and vulnerable sector checks on all our teachers and instructors.
 We do this to ensure that students are safe and parents can feel confident when interacting with instructors on the platform.
`,
    },
    {
      title: "Policy brief & purpose",
      p1: "Our employee background check policy refers to our guidelines for checking our job teachers/ instructors’ backgrounds as part of our hiring process. Background checks help us:",
      p2: `
    • Get insight into teachers/ instructors’ background.
    • Ensure we hire reliable employees.
    • Verify teachers/ instructors’ information for truthfulness and accuracy.
    • Screen teachers/ instructors convicted of serious criminal behaviour.`,
    },
    {
      title: "Scope",
      p1: "This employee background check policy applies to teachers/ instructors who go through our company’s hiring process. It may also apply to internal teachers/ instructors who are being considered for a promotion or transfer.",
    },
    {
      title: "Policy elements",
      p1: "Background checks may include:",
      p2: `
    • Criminal records.
    • Credit reports.
    • Drug testing.
    • Verification reports (e.g. identity, previous employment, education, SSN)
    • Driving records.
    • Vulnerable sector check
    • Reference checks.`,
      p3: "All teachers/ instructors are required to pass the type of background check that relates to their profession before our company hires them. Each position has its own requirements. For example, driving records may be relevant to field reliable salespeople and teachers required to drive to people’s homes, but not to hire office managers. The basic background screening includes [verification reports and reference checks.]",
      p4: "Criminal record checks are essential if teachers/ instructors are interviewing for positions where they will:",
      p5: `
    • Represent our company and deal with our clients or stakeholders.
    • Have access to sensitive and confidential information 
    • Handle money and finances 
    • Have any contact with children or the elderly.`,
      p6: "We are committed to abiding by all local laws and regulations.",
    },
    {
      title: "What to make of negative findings",
      p1: "Criminal records don’t automatically disqualify teachers/ instructors from the hiring process, unless they are convicted of serious criminal acts (e.g. sexual assault.) We will not require background check for teachers/ Instructors who teach virtually or online as they do not have direct contact with the students.",
      p2: "Note that HR will consider convictions, not arrests, when reviewing a candidate’s background check.",
      p3: "HR and hiring managers will keep the company’s interests in mind when rating the seriousness of any issues background checks uncover. HR and hiring managers should reject teachers/ instructors convicted of driving while intoxicated within the last two years.",
      p4: "Generally, if we decide a particular candidate would bring high or unreasonable risk to our company, they would be rejected. Because of the nature of business, we currently do not offer employment to people without a clean background check.",
    },
    {
      title: "Procedure",
      p1: "We aim for a transparent hiring process that respects teachers/ instructors’ rights. We advise and follow this procedure for background screening:  ",
      p2: `
    1. Hiring managers and HR discuss whether a background check is needed for a position and what the check will include.
    2. Hiring managers note in the job ad that a background check is required before employment.
    3. When the final teachers/ instructors (one or more finalists) are selected, hiring managers or HR contact the teachers/ instructors to get written permission for background checks and explain the process. This can be done through an Applicant Tracking System (ATS.)
    4. If teachers/ instructors refuse to go through background screening, HR informs them that they won’t be considered for the position. This is especially true if they are teaching from their studio or go to students’ homes.
    5. Once HR receives background check results, staff discusses them with hiring managers to decide next steps for the teachers/ instructors and the position.
    6. Hiring managers inform teachers/ instructors of their decisions and ensure they get copies of their individual background check reports. If there are negative findings, HR/hiring managers must let teachers/ instructors know how to dispute the report.`,
    },
    {
      title: "Phone Verification",
      p1: "In addition to police checks and vulnerable sector verifications, all our teachers are phone verified. When a new teacher or user registers on our platform, they receive a PIN right away. We ensure that we verify the user. Through this process we are able to identify the location and the veracity of the phone number and the network provider.",
    },
  ];

  return (
    <div className="educify__privacy">
      <h1 style={{ textAlign: "center", margin: "30px" }}>Background Check</h1>
      {data.map((section) => (
        <div className="privacy_row">
          <h2>{section.title}</h2>
          <p>{section.p1}</p>
          <p>{section?.p2}</p>
          <p>{section?.p3}</p>
          {section.mail ? (
            <a href={`mailto:${section.mail}`}>{section.mail}</a>
          ) : (
            ""
          )}
          <p>{section?.p4}</p>
          <p>{section?.p5}</p>
          <p>{section?.p5}</p>
        </div>
      ))}
    </div>
  );
}
