import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

import { uploadImages } from "../../ReduxToolkit/Slices/teacherSlice";

import upload from "../../assets/TeacherApp/assets/svgs/upload.svg";
import remove from "../../assets/SharedApp/svgs/remove.svg";

import "./SharedMultipleFileUpload.styles.scss";
import { deleteImage } from "../../ReduxToolkit/Slices/userSlice";

function SharedMultipleFileUploader({
  fileTypes,
  images,
  setImages,
  multiple,
  file,
  left,
}) {
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const sendToast = (message) => toast.error(message);
  useEffect(() => {}, [images]);
  const handleChange = (file) => {
    setUploading(true);
    handleUploadMultiple(file);
  };

  const deleteImages = (publicId, i) => {
    dispatch(deleteImage(publicId));
    setImages(images.filter((_, index) => index !== i));
  };

  async function createFormData(files) {
    const formData = new FormData();
    let totalSize = 0;
    for (const file of files) {
      totalSize += file.size;
      formData.append("files", file);
    }

    return { formData, totalSize };
  }

  const handleUploadMultiple = async (files) => {
    try {
      const { formData, totalSize } = await createFormData(files);
      if (totalSize > 10 * 1024 * 1024) {
        setUploading(false);
        return sendToast("Total size of files exceeded 10 MB limit");
      }
      const value = await dispatch(uploadImages(formData));
      if (value.type !== "uploadImages/fulfilled") {
        setUploading(false);
        return sendToast("Error uploading files, please try again");
      }
      file
        ? setImages(...value.payload)
        : setImages([...images, ...value.payload]);
      setUploading(false);
    } catch (err) {
      console.log("err here", err);
      setUploading(false);
      return sendToast("Error uploading files, please try again");
    }
  };

  const displayFileTypes = fileTypes
    .map((fileType) => fileType.toLowerCase())
    .join(", ");

  return (
    <div className="image__selector file__selector">
      <div className="upload">
        <div className="uploaded">
          <Tooltip
            title="Total upload size must not exceed 10mb"
            arrow
            placement="top"
          >
            <div className="uploader">
              <FileUploader
                multiple={multiple}
                handleChange={(e) => handleChange(e)}
                name="file"
                types={fileTypes}
              />
            </div>
          </Tooltip>
          <div className="upload_btn">
            <img src={upload} alt="" />
            <span>File Uploader</span>

            <p>Drag & Drop files here</p>
            {uploading && (
              <div className="spinner" style={{ position: "absolute" }} />
            )}
          </div>

          {images?.length > 0 &&
            images.map((image, i) => {
              return image?.url?.includes(".pdf") ? (
                <div className="flex_center">
                  <Tooltip title="Click to preview" arrow placement="top">
                    <a href={image.url} target="_blank">
                      {image.fileName}
                      <span>.pdf</span>
                    </a>
                  </Tooltip>

                  <img
                    src={remove}
                    alt=""
                    className="small"
                    onClick={() => deleteImages(image.publicId, i)}
                  />
                </div>
              ) : image.url !== "" && image.url ? (
                <div className="image" key={i}>
                  <img src={image.url} className="displayedImage" />
                  <img
                    src={remove}
                    alt=""
                    className="remove action"
                    onClick={() => deleteImages(image.publicId, i)}
                  />
                </div>
              ) : (
                ""
              );
            })}
        </div>
        <span
          className="left small_grey"
          style={{ textAlign: left ? "left" : "center" }}
        >
          Allowed file types: <span className="span">{displayFileTypes}</span>
        </span>
      </div>
    </div>
  );
}

export default SharedMultipleFileUploader;
