import React, { useEffect, useState } from "react";
import "./ViewSubject.styles.scss";
import edit from "../../../assets/SharedApp/svgs/edit.svg";
import volicity from "../../../assets/TeacherApp/assets/svgs/volicity.svg";
import notfound from "../../../assets/AdminApp/images/404img.webp";
import { useDispatch, useSelector } from "react-redux";
import remove from "../../../assets/SharedApp/svgs/remove.svg";
import {
  setUserProfilePicture,
  uploadImage,
} from "../../../ReduxToolkit/Slices/teacherSlice";
import SubjectDetails from "../../Components/SubjectDetails/SubjectDetails";
import { getSubjectByName } from "../../../ReduxToolkit/Slices/lessonsSlice";
import { getCourseSubjectsbyName } from "../../../ReduxToolkit/Slices/coursesSlice";
import { Link, useParams } from "react-router-dom";
export default function ViewSubject({ courses }) {
  const [imageLoading, setImageLoading] = useState(false);
  const [preview, setPreview] = useState();

  const [selectedProfileFile, setSelectedProfileFile] = useState();
  const dispatch = useDispatch();
  const onSelectFile = (e) => {
    setImageLoading(true);
    setSelectedProfileFile(e.target.files[0]);
    handleImageUpload(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  };
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    Promise.resolve(dispatch(uploadImage(form))).then((value) => {
      setProfileImage(value.payload);
      setImageLoading(false);
    });
  };
  const setProfileImage = (image) => {
    dispatch(setUserProfilePicture(image));
  };
  const { id } = useParams();
  const [subject, setSubject] = useState({});
  useEffect(() => {
    courses
      ? dispatch(getCourseSubjectsbyName(id)).then((res) => {
          setSubject(res.payload);
        })
      : dispatch(getSubjectByName(id)).then((res) => {
          setSubject(res.payload.data);
        });
  }, []);

  return (
    <>
      <div className="edit__category">
        <div className="header">
          <h1>View Subject</h1>
          <p>Setup lesson category</p>
        </div>
        <div className="create__category__row">
          <span>Profile Photo</span>
          <img
            src={
              courses
                ? subject?.image?.url
                : subject.images && subject.images.length
                ? subject.images[0].url
                : notfound
            }
            alt=""
          />
          <div className="edit__cat__btn">
            <Link to={`edit`}>
              <button>Edit</button>
            </Link>
          </div>
        </div>
        <div className="create__category__row">
          <span>Profile Title</span>
          <p>{subject && subject.name}</p>
        </div>
        <div className="create__category__row">
          <span>Status</span>
          <div className="save__card">
            <input
              type="checkbox"
              id="switch"
              class="checkbox"
              checked={
                subject.status && subject.status === "ACTIVE" ? true : false
              }
            />
            <label for="switch" class="toggle"></label>
          </div>
        </div>
        <div className="create__category__row">
          <span>Last Updated</span>
          <p>
            {courses
              ? subject?.image?.updatedAt
              : subject.images && subject.images.length
              ? subject.images[0].updatedAt
              : "no date"}
          </p>
        </div>
      </div>
      {/* <SubjectDetails /> */}
    </>
  );
}

// const SubjectDetails = () => {
//   const data = [
//     { header: "Trial Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "5 Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "10 Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "15 Lessons Pricing", price30: "1$", price60: "1$" },
//     { header: "15 Lessons Pricing", price30: "1$", price60: "1$" },
//   ];
//   return (
//     <div className="subject__details">
//       <div className="header">
//         <h1>Lesson</h1>
//         <p>Your lesson pricing</p>
//       </div>
//       <div className="detail__rows">
//         {data.map((el) => (
//           <div className="subject__detail__row">
//             <h2>{el.header}</h2>
//             <div className="sub__row">
//               <p>
//                 30 Minute Price:<span>$1</span>
//               </p>
//               <p>
//                 60 Minute Price:<span>$1</span>
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
