import React from "react";
import SignOut from "../../SignOut/SignOut";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./Menu.styles.scss";
import default_image from "../../../assets/SharedApp/images/default.webp";
import num30022 from "../../../assets/SVG/num30022.png";
import notfound from "../../../assets/SharedApp/images/default.webp";
export default function DashboardDropDown({
  showDropDown,
  setShowDropDown,
  user,
  customClass,
}) {
  return (
    user?.name && (
      <div
        className={`logged_user_info ${customClass ? customClass : ""}`}
        onMouseEnter={() => setShowDropDown(true)}
        onMouseLeave={() => setShowDropDown(false)}
      >
        <div className="name" onClick={(e) => setShowDropDown(!showDropDown)}>
          <p>Hello</p>
          <span>{user.name}</span>
        </div>
        <img
          onMouseEnter={() => setShowDropDown(true)}
          src={
            user?.profileImage?.url !== "string" && user?.profileImage?.url
              ? user.profileImage.url
              : notfound
          }
          onClick={() => setShowDropDown(!showDropDown)}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = default_image;
          }}
        />
        {showDropDown && (
          <div className="users__dropdown__dash">
            <div className="user__info">
              <div className="image">
                <img
                  src={
                    user?.profileImage?.url !== "string" &&
                    user?.profileImage?.url
                      ? user.profileImage.url
                      : notfound
                  }
                  alt=""
                />
              </div>
              <div className="user__details">
                <span>{user.name + " " + user.lastName}</span>
                <p>{user.email}</p>
              </div>
            </div>
            {user.role !== "ADMIN" && (
              <div className="users__drop__links">
                <span className="drop__link">
                  <Link
                    to={
                      user.role === "TEACHER"
                        ? "/teachers/settings"
                        : "/students/settings"
                    }
                    onClick={() => setShowDropDown(false)}
                  >
                    Account Settings
                  </Link>
                </span>
              </div>
            )}
            <SignOut>
              <div className="users__logout ">
                <span className="drop__link">Sign Out</span>
              </div>
            </SignOut>
          </div>
        )}
      </div>
    )
  );
}
