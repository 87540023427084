import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import searchGold from "../../../../assets/SharedApp/svgs/searchGold.svg";
import planet from "../../../../assets/SharedApp/svgs/planet.svg";
import blop from "../../../../assets/SharedApp/images/blop.webp";
import ranking from "../../../../assets/SharedApp/images/ranking.webp";

export default function HeaderPhraseComponent({ isHeader }) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  return (
    <div className="header__phrase__component">
      {isHeader && (
        <div className="kickstart__learning">
          <img src={planet} alt="" />
          <span>Kickstart your Learning</span>
        </div>
      )}
      <h1>
        Learn from anywhere,
        <span className="relative">
          anytime
          {isHeader && <img className="blop" src={blop} alt="" />}
        </span>
      </h1>
      <p>
        Get unlimited access to 19,000+ of Educify top courses. Learn and
        improve skills across business, tech, design, and more
      </p>
      <form
        className="input"
        onSubmit={() => navigate(`/courses/search/${search}`)}
      >
        <input
          type="text"
          placeholder="What do you want to learn?"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit">
          <img src={searchGold} alt="search" />
        </button>
      </form>
      {isHeader && (
        <div className="ranking">
          <img src={ranking} alt="" />
        </div>
      )}
    </div>
  );
}
