import React from "react";
import "./DangerNote.styles.scss";
import warning from "../../../assets/AdminApp/svgs/Warning.svg";

export default function DangerNote({ title, text }) {
  return (
    <div className="danger__note">
      <img src={warning} alt="" />
      <div className="text">
        <span>Warning</span>
        <p>
          Please avoid updating anything on your own. You can contact our
          development team if you need any changes.
        </p>
      </div>
    </div>
  );
}
