import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import { uploadImage } from "../../../ReduxToolkit/Slices/teacherSlice";
import "./blogs.styles.scss";
import {
  createBlog,
  getBlogById,
  updateBlogById,
} from "../../../ReduxToolkit/Slices/adminSlice";
import SharedMultipleFileUploader from "../../../Components/SharedMultipleFileUploader/SharedMultipleFileUploader";
import { toast } from "react-toastify";
import SharedSingleImageUploader from "../../../Components/SharedSingleImageUploader/SharedSingleImageUploader";
import { useParams } from "react-router-dom";
const CreateBlogs = ({ edit }) => {
  const { id } = useParams();
  const [text, setText] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [images, setImages] = useState({});
  const dispatch = useDispatch();
  const handleImageUpload = (file) => {
    let form = new FormData();
    form.append("file", file);
    setImageLoading(true);

    dispatch(uploadImage(form)).then((value) => {
      setText(
        (currentText) => currentText + `<img src="${value?.payload?.url}" />`
      );
      setImageLoading(false);
    });
  };

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        handleImageUpload(file);
      }
    };
  };
  const handleSubmit = () => {
    if (!title) return toast.warning("Missing Title!");
    if (!images?.url) return toast.warning("Missing Image!");
    if (!text || text === "<p><br></p>")
      return toast.warning("Missing blog description!");

    if (!edit) {
      dispatch(
        createBlog({
          title: title,
          content: text,
          image: images,
          published: true,
        })
      )
        .then((res) => {
          if (res?.payload?.data?.message) {
            return toast.error(res?.payload?.data?.message);
          }
          if (res.type.includes("fulfilled")) {
            toast.success("Blog added successfully!");
            setText("");
            setTitle("");
            setImages({});
          }
        })
        .catch((err) => console.log("err here!", err));
    } else {
      dispatch(
        updateBlogById({
          title: title,
          content: text,
          image: images,
          published: true,
          id,
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) {
          toast.success("Blog updated successfully!");
        }
      });
    }
  };
  const handleSetImages = (value) => {
    setImages(value);
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ color: [] }, { background: [] }], // Allow text color and background color
          [{ align: [] }], // Align options
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const formats = useMemo(
    () => [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "color", // Add color to the formats array
      "background", // Add background color to the formats array
      "align", // Add align to the formats array
    ],
    []
  );
  useEffect(() => {
    if (id && edit) {
      dispatch(getBlogById(id)).then((res) => {
        if (res.type.includes("fulfilled")) {
          setImages(res?.payload?.data?.image);
          setTitle(res?.payload?.data?.title);
          setText(res?.payload?.data?.content);
        }
      });
    }
  }, [id]);

  return (
    <div className="create_blog_container">
      <h1>Add a blog</h1>
      {imageLoading && <h1>Loading...</h1>}
      <div className="row">
        <h3 style={{ margin: "0", marginBottom: "15px" }}>Image</h3>
        <SharedSingleImageUploader
          onSelectFile={handleSetImages}
          preview={images}
        />
      </div>
      <div className="title">
        <h3>Title</h3>
        <input
          type="text"
          placeholder="title..."
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>

      <div className="blog_description">
        <h3>Description</h3>
        <ReactQuill
          // theme="snow"
          value={text}
          onChange={setText}
          modules={modules}
          formats={formats}
          className="rich__text__editor"
          readOnly={imageLoading}
          placeholder="Blog description..."
        />
      </div>
      <button className="secondary" onClick={handleSubmit}>
        {edit ? "Update" : "Submit"}
      </button>
    </div>
  );
};

export default CreateBlogs;
