import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import "./EditCourse.styles.scss";
import "react-quill/dist/quill.snow.css";
import Toggle from "react-toggle";
// import "prismjs/components/prism-clike";
// import "prismjs/components/prism-javascript";
import AddExample from "../AddExample/AddExample";
// import Prism from "prismjs";
import EditPanel from "../../../Components/Courses/EditPanel/EditPanel";
import AddVideo from "../../../Components/Courses/AddVideo/AddVideo";
import AddText from "../../../Components/Courses/AddText/AddText";

import AddQuiz from "../../../Components/Courses/AddQuiz/AddQuiz";
import EditorPage from "../../../Components/Courses/EditorPage/EditorPage";
import AddFiles from "../../../Components/Courses/AddFiles/AddFiles";
import Shared from "./Shared";
import { handleAddLesson } from "../../../../ReduxToolkit/Slices/coursesSlice";

export default function EditHomework({
  index,
  onHomeworkChange,
  homework,
  student,
  refresh,
}) {
  const lessonInitial = {
    title: "",
    videos: [
      {
        url: "",
        publicId: "",
        videoLength: 0,
      },
    ],
    description: "",
  };

  const [selected, setSelected] = useState("Add Video");
  const [lesson, setLesson] = useState(lessonInitial);
  const [note, setNote] = useState(homework.note);
  const [image, setImage] = useState(
    homework.image ? homework.image : { publicId: "", url: "", fileName: "" }
  );
  const [video, setVideo] = useState(
    homework.video ? homework.video : { publicId: "", url: "", fileName: "" }
  );

  const handleLessonChange = (e) => {
    const { name, value } = e.target;
    onHomeworkChange(name, value);
  };

  const handleAddClick = () => {
    let name =
      selected === "Add Video"
        ? "video"
        : selected === "Add Image"
        ? "image"
        : selected === "Add Notes"
        ? "note"
        : null;

    if (!name) {
      return;
    }
    let value =
      name === "note"
        ? note
        : name === "image"
        ? image
        : name === "video"
        ? video
        : homework[name];
    onHomeworkChange(name, value);
  };
  const handleSelected = (value) => {
    if (value !== selected) {
      setSelected(value);
      setLesson({ ...lessonInitial, title: lesson.title });
    }
  };

  const ref = useRef(null);
  useEffect(() => {
    // Scroll to the component when it is mounted
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [refresh]);
  const [isYoutube, setIsYoutube] = useState(false);
  return (
    <div className="Edit__courses__section">
      {/* <Routes>
        <Route path="" element={<Shared />}>
          <Route path="/" element={<div className="border" />} />
          <Route path="/addVideo" element={<AddVideo />} />
          <Route path="/addText" element={<AddText />} />
        </Route>
      </Routes> */}
      <EditPanel setSelected={handleSelected} selected={selected} />
      <div className="Editors" ref={ref}>
        <div className="Custom_Inputs">
          {!student && (
            <div className="input">
              <span>Homework Title</span>
              <input
                type="text"
                placeholder="title"
                name="title"
                value={homework.title}
                onChange={(e) => handleLessonChange(e)}
              />
            </div>
          )}
        </div>
        {/* <div className="video_switch">
          <span>Or enter a youtube link</span>
          <Toggle
            checked={isYoutube}
            onChange={() => setIsYoutube(!isYoutube)}
          />
        </div> */}
        {selected === "Add Video" ? (
          <>
            <AddVideo
              lesson={video}
              setLesson={setLesson}
              index={index}
              handleLessonChange={(e) => setVideo(e)}
            />
          </>
        ) : selected === "Add Notes" ? (
          <AddText lesson={note} setLesson={setNote} homework={true} />
        ) : // ) : selected === "Add Code Example" ? (
        //   <AddExample
        //     title={"Code example"}
        //     handleLessonChange={(e) => handleLessonChange(e)}
        //     lesson={lesson}
        //     subtitle="Use the code example editor to display a properly formatted code example in your lecture."
        //   />
        selected === "Add Image" ? (
          <AddVideo
            item={image}
            image
            index={index}
            handleLessonChange={(e) => setImage(e)}
          />
        ) : selected === "Add File" ? (
          <AddVideo
            lesson={lesson}
            index={index}
            handleLessonChange={(e) => handleLessonChange(e)}
            file
          />
        ) : null}
        {/* {selected === "Editor Page" && <EditorPage />} */}
        {/* {selected === "Add File" && <AddFiles />} */}
        {selected === "Add Quiz" && <AddQuiz />}

        <button
          className="btn_send flex_center"
          onClick={() => handleAddClick()}
        >
          {selected}
        </button>
      </div>
      {/* {selected === "Add Custom Code" && (
        <AddExample
          title={"Custom Code example"}
          subtitle="Use the code example editor to display a properly formatted custom code example in your lecture."
        />
      )} */}
    </div>
  );
}
